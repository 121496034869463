import { WebSocketsState, SocketEventControllerTypeEnum, EventCodeEnum } from '@livestock/web-sockets';
import { Action, createReducer, on } from '@ngrx/store';
import * as webSocketsActions from './web-sockets.actions';

export const initialState: WebSocketsState = {
  events: [],
};

const reducer = createReducer(
  initialState,
  on(webSocketsActions.addWebSocketEvents, (state, { payload }) => {
    let events = [...state.events];

    // removing old events
    payload.forEach((event) => {

      events = events.filter(e => !(e.ec === event.ec
        && e.controllerType === event.controllerType
        && e.controlUnitID === event.controlUnitID
        && e.dataEvent?.number === event.dataEvent.number
        && e.dataEvent?.sectorType === event.dataEvent.sectorType
        && (e.dataEvent.controllerEventType != null && e.dataEvent.controllerEventType === event.dataEvent.controllerEventType
          || e.dataEvent.generalType != null && e.dataEvent.generalType === event.dataEvent.generalType
          || e.dataEvent.elementType != null && e.dataEvent.elementType === event.dataEvent.elementType)
      ));

      events.push(event);
    });

    return {
      ...state,
      events,
    };
  }),
  on(webSocketsActions.filterSyncAndCnfStatusEventsByControllerID, (state, { controllerID, sectorTypes }) => {
    return {
      ...state,
      events: state.events
        .filter(event => !((event.ec === EventCodeEnum.Controller &&
            event.controlUnitID === controllerID &&
            event.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.CnfStatus &&
            sectorTypes.includes(event.dataEvent?.sectorType))
          ||
          (event.ec === EventCodeEnum.Controller &&
            event.controlUnitID === controllerID &&
            event.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.Sync))),
    };
  }),
  on(webSocketsActions.filterCloudConnectionAcceptEventsByControllerID, (state, { controllerID }) => {
    return {
      ...state,
      events: state.events
        .filter(event => !(event.ec === EventCodeEnum.Controller &&
          event.controlUnitID === controllerID &&
          event.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.CloudConnectionAccept)),
    };
  }),
  on(webSocketsActions.filterRefreshPageEvents, (state, { controllerID, page }) => {
    return {
      ...state,
      events: state.events
        .filter(event => !(event.ec === EventCodeEnum.Controller &&
          event.controlUnitID === controllerID &&
          event.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.RefreshPage &&
          event.dataEvent?.pageNumber === page
        )),
    };
  }),
  on(
    webSocketsActions.clearWebSocketEvents, (state) => {
      return {
        ...state,
        ...initialState,
      };
    }),
);

export function webSocketsReducer(state: WebSocketsState | undefined, action: Action): any {
  return reducer(state, action);
}
