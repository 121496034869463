import { Component } from '@angular/core';
import { DialogButtonEnum } from '@livestock/shared/enums';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogButton } from '@livestock/shared/classes';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ISelectDialogOptions, SelectDialogCssClass } from '@livestock/shared/interfaces';
import { CommonModule } from '@angular/common';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { EnumPipe, EnumToArrayPipe, FilterCollectionPipe } from '@livestock/shared/pipes';
import { ButtonComponent } from '../../controls/button/button.component';
import { SearchComponent } from '../../controls/search/search.component';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ButtonComponent,
    SearchComponent,
    EnumToArrayPipe,
    EnumPipe,
    FilterCollectionPipe,
    QaTagsDirective,
    SvgIconComponent,
  ],
  selector: 'ls-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss'],
})
export class SelectDialogComponent {
  form: UntypedFormGroup;
  title: string;
  bindValue = 'id';
  bindLabel = 'name';
  bindSubLabel = 'subLabel';
  options: ISelectDialogOptions;
  buttons: DialogButton[];
  searchValue: string = null;
  dialogCssClass: SelectDialogCssClass;
  translateFieldTitle: boolean;

  constructor(
    public dialogRef: MatDialogRef<SelectDialogComponent>,
    public platformService: PlatformService,
    public languageService: LanguageService,
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  setup(options: ISelectDialogOptions, title: string, buttons: DialogButtonEnum[], dialogCssClass: SelectDialogCssClass): void {
    this.options = options;
    this.bindValue = options.bindValue ?? this.bindValue;
    this.bindLabel = options.bindLabel ?? this.bindLabel;
    this.bindSubLabel = options.bindSubLabel ?? this.bindSubLabel;
    this.title = title;
    this.initForm(options);
    this.buttons = this.getButtons(buttons);
    this.dialogCssClass = dialogCssClass ?? SelectDialogCssClass.DEFAULT;
    this.translateFieldTitle = options?.translateFieldTitle ?? true;
  }

  initForm(options: ISelectDialogOptions): void {
    this.form = this.formBuilder.group({
      field: [
        options.value,
        options.validators,
      ],
    });
  }

  changeValue(event: MatRadioChange | { value: string }): void {
    if (!this.buttons?.length) {
      this.dialogRef.close({
        value: event.value,
      });
    }
  }

  close(ev: DialogButton): void {
    this.dialogRef.close({
      type: ev?.type,
      value: this.form.value.field,
    });
  }

  private getButtons(buttons: DialogButtonEnum[]): DialogButton[] {
    return buttons.map(b => new DialogButton(b));
  }
}
