import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { map } from 'rxjs/operators';
import {
  catchError,
  firstValueFrom,
  lastValueFrom,
  Observable,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ILoginResponseModel } from '../interfaces/login-response.interface';
import { ILoginData } from '../interfaces/login-data.interface';
import { ISignUpReq } from '../interfaces/sign-up.interface';
import { IResetPasswordReq } from '../interfaces/reset-password.interface';
import { IResponse } from '@livestock/shared/interfaces';
import { LocalStorageService } from '@livestock/shared/services';
import { GlobalConstants } from '@livestock/shared/constants';
import { QueryParamsHandling, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getCurrentUser, ICurrentUserView, selectCurrentUser } from '@livestock/current-user';
import { StorageItem } from '@livestock/shared/enums';
import { ControllerLanguageEnum, deleteEditMode, selectActiveControllerPage } from '@livestock/controllers';
import { CurrentUserService } from 'libs/current-user/src/lib/services/current-user.service';
import { FormsService, selectIsFormEditing } from '@livestock/forms';
import { logOut } from '../+state/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private currentUserService: CurrentUserService,
    private formsService: FormsService,
    private dialogRef: MatDialog,
  ) {
    /* refresh token every minute */
    setInterval(async () => {
      try {
        if (!LocalStorageService.getStorageItem(StorageItem.Token)) {
          return;
        }

        if (this.router.url.includes('/auth')) {
          return;
        }

        const refreshTokenRequest = await this.refreshToken();
        const token = await lastValueFrom(refreshTokenRequest);
        LocalStorageService.setStorageItem(StorageItem.Token, token);
      } catch (e) {
        console.log(e);
      }
    }, 1000 * 5);
  }

  goToLogin(queryParamsHandling?: QueryParamsHandling): void {
    this.clearLocalStorage();

    setTimeout(() => {
      this.router.navigate([GlobalConstants.authLoginPage], {
        queryParamsHandling,
      });
    });
  }

  clearLocalStorage(): void {
    LocalStorageService.removeStorageItem(StorageItem.Token);
    LocalStorageService.removeStorageItem(StorageItem.ActiveControllerID);
    LocalStorageService.removeStorageItem(StorageItem.ActiveFarmID);
  }

  onLogout(force?: boolean): Observable<boolean> {
    return this.store.select(selectIsFormEditing).pipe(
      switchMap(isEditing => (isEditing && !force) ? this.formsService.confirmFormLeaving() : of(true)),
      withLatestFrom(this.store.select(selectActiveControllerPage)),
      take(1),
      tap(([confirm, page]) => {
        if (!confirm) {
          return;
        }

        if (page) {
          this.store.dispatch(deleteEditMode({ page }));
        }

        this.dialogRef.closeAll();
        this.store.dispatch(logOut());
      }),
      map(([confirm]) => confirm),
    );
  }

  login(data: ILoginData): Observable<ILoginResponseModel> {
    // double check that language is valid (issue in ios with "null" on lang field)
    const modifiedData: ILoginData = {
      ...data,
      language: Number.isInteger(data.language) ? data.language : ControllerLanguageEnum.EngUS,
    };

    return this.http
      .post<ILoginResponseModel>(
        `${this.baseUrl}${GlobalConstants.authLoginPage}`,
        modifiedData,
      )
      .pipe(
        map((res) => {
          if (!res || !res.token) {
            throw new Error('Token lobby');
          }
          return res;
        }),
      );
  }

  signUp({ ticketID, ...data }: ISignUpReq): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/ticket/${ticketID}/sign-up/execute`,
      data,
    );
  }

  resetPassword({ ticketID, ...data }: IResetPasswordReq): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/ticket/${ticketID}/reset-password/execute`,
      data,
    );
  }

  logout(): Observable<any> {
    return this.http.get<IResponse<any>>(`${this.baseUrl}/auth/logout`);
  }

  async refreshToken(): Promise<Observable<string>> {
    return this.http
      .get<{ token: string }>(`${this.baseUrl}/auth/token/refresh`)
      .pipe(
        map((res: { token: string }) => res?.token),
        catchError((e) => {
          throw new Error(e);
        }),
      );
  }

  async canActivate(): Promise<boolean> {
    const token = LocalStorageService.getStorageItem(StorageItem.Token);
    if (token) {
      const currentUser = await this.getCurrentUser();
      const actualCurrentUser = await firstValueFrom(this.currentUserService.getCurrentUser());
      if (!currentUser || currentUser.userID !== actualCurrentUser.userID) {
        this.store.dispatch(getCurrentUser());
      }
      return true;
    }

    return false;
  }

  async getCurrentUser(): Promise<ICurrentUserView> {
    return await firstValueFrom(this.store.select(selectCurrentUser));
  }
}
