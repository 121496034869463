import { pureDeviceControllerUrl } from '@livestock/controllers';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IElement } from '../interfaces/element.interface';
import { environment } from '@livestock/shared/environments';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { PlatformService } from '@livestock/shared/services';
import {
  ElementEventInfo, IElementSetupRunTestView,
  IGetElements,
  IGetOrUpdateElement,
  RunElementEventResponse,
} from '@livestock/installation/interfaces';
import { IGetElementsSetupList } from '../interfaces/get-elements-setup-list.interface';

@Injectable({
  providedIn: 'root',
})
export class InstallationService {
  private http: HttpClient = inject(HttpClient);
  private platformService: PlatformService = inject(PlatformService);

  getAllElements(controllerID: number): Observable<IElement[]> {
    if (this.platformService.isDeviceApp) {
      return this.http.get<IGetElements>(`${pureDeviceControllerUrl}/cards/element-types`).pipe(
        map((res) => res.items),
      );
    }

    return this.http.get<IGetElements>(`${environment.apiUrl}/controller/${controllerID}/element-types`).pipe(
      map((res) => res.items),
    );
  }

  createElementSetup(
    cardNumber: number,
    connectionNumber: number,
    elementNumber: number,
    elementType: ElementTypesEnum,
  ): Observable<any> {
    const elementTypeToStringApi = ElementTypesEnum.getDeviceElementApiString(elementType);
    const url: string = `card/${cardNumber}/connection/${connectionNumber}/element/${elementNumber}/${elementTypeToStringApi}`;
    return this.http.post<any>(`${pureDeviceControllerUrl}/${url}`, null);
  }

  getElementSetup(
    cardNumber: number,
    connectionNumber: number,
    elementNumber: number,
    elementType: ElementTypesEnum,
    controllerID: number,
  ): Observable<IGetOrUpdateElement> {
    const elementTypeToStringApi = this.platformService.isDeviceApp
      ? ElementTypesEnum.getDeviceElementApiString(elementType)
      : ElementTypesEnum.getWebElementApiString(elementType);

    if (this.platformService.isDeviceApp) {
      const url: string = `card/${cardNumber}/connection/${connectionNumber}/element/${elementNumber}/${elementTypeToStringApi}/setup`;
      return this.http.get<IGetOrUpdateElement>(`${pureDeviceControllerUrl}/${url}`);
    }

    const url: string = `controller/${controllerID}/${elementTypeToStringApi}/setup`;
    return this.http.get<IGetElementsSetupList>(`${environment.apiUrl}/${url}`).pipe(
      map((res) => res.items?.[0]),
    );
  }

  updateElementSetup(
    cardNumber: number,
    connectionNumber: number,
    elementNumber: number,
    elementType: ElementTypesEnum,
    controllerID: number,
    view: IGetOrUpdateElement,
  ): Observable<IGetOrUpdateElement> {
    const elementTypeToStringApi = this.platformService.isDeviceApp
      ? ElementTypesEnum.getDeviceElementApiString(elementType)
      : ElementTypesEnum.getWebElementApiString(elementType);

    if (this.platformService.isDeviceApp) {
      const url: string = `card/${cardNumber}/connection/${connectionNumber}/element/${elementNumber}/${elementTypeToStringApi}/setup`;
      return this.http.put<IGetOrUpdateElement>(`${pureDeviceControllerUrl}/${url}`, { ...view });
    }

    const url: string = `controller/${controllerID}/${elementTypeToStringApi}/${view.elementID}/setup`;
    return this.http.put<IGetOrUpdateElement>(`${environment.apiUrl}/${url}`, { ...view });
  }

  deleteElementSetup(
    cardNumber: number,
    connectionNumber: number,
    elementNumber: number,
    elementType: ElementTypesEnum,
  ): Observable<void> {
    const elementTypeToStringApi = ElementTypesEnum.getDeviceElementApiString(elementType);
    const url: string = `card/${cardNumber}/connection/${connectionNumber}/element/${elementNumber}/${elementTypeToStringApi}`;
    return this.http.delete<void>(`${pureDeviceControllerUrl}/${url}`);
  }

  runElementsViaDevice(
    cardNumber: number,
    connectionNumber: number,
    elementNumber: number,
    elementType: ElementTypesEnum,
  ): Observable<ElementEventInfo> {
    const elementTypeToStringApi = ElementTypesEnum.getDeviceElementApiString(elementType);
    const url: string = `card/${cardNumber}/connection/${connectionNumber}/element/${elementNumber}/${elementTypeToStringApi}/rtv`;
    return this.http.get<ElementEventInfo>(`${pureDeviceControllerUrl}/${url}`);
  }

  runElementEvents(controllerID: number, response: RunElementEventResponse): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/controller/${controllerID}/real-time/run-event`, response);
  }

  runElementTests(
    controllerID: number,
    elementID: number,
    elementType: ElementTypesEnum,
    response: IElementSetupRunTestView,
  ): Observable<boolean> {
    const elementTypeToStringApi = ElementTypesEnum.getWebElementApiString(elementType);
    const url = `${environment.apiUrl}/controller/${controllerID}/${elementTypeToStringApi}/${elementID}/setup/run-test`;
    return this.http.post<boolean>(url, response);
  }
}
