import { CommonModule } from '@angular/common';
import { Component, forwardRef, HostListener, Input } from '@angular/core';
import { FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { errFade } from '@livestock/shared/animations';
import { QaTagsDirective } from '@livestock/shared/directives';
import { LanguageService } from '@livestock/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NativeElementInjectorDirective } from '../native-element.directive';
import { filter, firstValueFrom } from 'rxjs';
import { GlobalConstants } from '@livestock/shared/constants';
import {
  LvInputDateComponent,
  selectVirtualKeyboardAMPMAndUUID,
  selectVirtualKeyboardElementUUID,
  setVirtualKeyboardAMPM,
  setVirtualKeyboardElementUuid,
  setVirtualKeyboardMode,
  VirtualKeyboardModesEnum,
} from '@livestock/ui';
import { AmPmEnum } from '../../../../../controllers/src/lib/enums/date-time/am-pm.enum';
import { EnumPipe } from '@livestock/shared/pipes';
import { TimeUtils } from '@livestock/shared/utils';

@Component({
  selector: 'lv-input-time',
  templateUrl: './lv-input-time.component.html',
  styleUrls: ['./lv-input-time.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    QaTagsDirective,
    TranslateModule,
    NativeElementInjectorDirective,
    EnumPipe,
  ],
  animations: [
    errFade,
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => LvInputTimeComponent),
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => LvInputTimeComponent),
  }],
})
export class LvInputTimeComponent extends LvInputDateComponent {
  @Input() noBorder: boolean;
  @Input() whiteBackground: boolean;
  @Input() mainClassList: string;

  AMPMOption: AmPmEnum;

  override mask = GlobalConstants.Input_time_mask;
  override keyboardMode = VirtualKeyboardModesEnum.AMPM;

  @Input() set AMPM(AMPM: boolean) {
    this._AMPM = AMPM;

    if (AMPM) {
      this.setAMPMOption(this._value);
      if (this.inputRef?.nativeElement && this._value) {
        this.inputRef.nativeElement.value = TimeUtils.TwentyFourHoursFormatToAMPMTime(this._value, this.AMPMOption);
      }
      return;
    }

    if (this.inputRef?.nativeElement && this._value != null) {
      this.inputRef.nativeElement.value = TimeUtils.AMPMTimeTo24hFormat(this._value, this.AMPMOption);
    }
    this.AMPMOption = null;
  }

  @HostListener('focusin', ['$event'])
  override onInputClick(): void {
    this.isTouched = true;

    this.store.dispatch(setVirtualKeyboardElementUuid({
      elementUuid: this.inputRef.nativeElement.getAttribute('uuid'),
    }));

    this.store.dispatch(setVirtualKeyboardMode({ mode: VirtualKeyboardModesEnum.AMPM }));

    if (!this.value) {
      this.inputRef.nativeElement.value = this.mask;
    }
    setTimeout(() => {
      if (!this.value) {
        this.inputRef.nativeElement.selectionStart = 0;
        this.inputRef.nativeElement.selectionEnd = 0;
        return;
      }
      this.inputRef.nativeElement.selectionStart = 999;
      this.inputRef.nativeElement.selectionEnd = 999;
    });
  }

  @HostListener('focusout')
  override async unsetFormControl(): Promise<void> {
    const activeFormControl = await firstValueFrom(this.store.select(selectVirtualKeyboardElementUUID));
    if (activeFormControl === this.inputRef.nativeElement.getAttribute('uuid')) {
      this.saveValue();

      this.store.dispatch(setVirtualKeyboardElementUuid({
        elementUuid: null,
      }));
    }
  }

  private _AMPM: boolean;

  get AMPM(): boolean {
    return this._AMPM;
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    languageService: LanguageService,
    store: Store,
  ) {
    super(languageService, store);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.sub$.add(
      this.store.select(selectVirtualKeyboardAMPMAndUUID).pipe(
        filter(({ elementUuid, AMPM }) => {
          return this.inputRef?.nativeElement.getAttribute('uuid') === elementUuid && AMPM != null;
        }),
      ).subscribe(({ AMPM }) => {
        this.AMPMOption = AMPM;
        this.store.dispatch(setVirtualKeyboardAMPM({ AMPM: null }));
      }),
    );
  }

  override printSymbol(symbol: string): void {
    const { selectionStart, value } = this.inputRef.nativeElement;
    const valueArray: string[] = value.split('');

    if (![2, 5].includes(selectionStart)) {
      valueArray.splice(selectionStart, 1, symbol as string);
    }

    const nextStep = selectionStart === 1 ? 3 : selectionStart + 1;
    const updatedValue = valueArray.join('');
    this.inputRef.nativeElement.value = this.transformTime(updatedValue);
    this.inputRef.nativeElement.selectionStart = nextStep || selectionStart;
    this.inputRef.nativeElement.selectionEnd = nextStep || selectionStart;
  }

  override removeSymbol(): void {
    const { selectionStart, value } = this.inputRef.nativeElement;
    const valueArray: string[] = value.split('');

    if (![3].includes(selectionStart)) {
      valueArray.splice(selectionStart - 1, 1, this.maskSymbol);
    }

    const nextStep = selectionStart === 0 ? 0 : selectionStart - 1;

    const updatedValue = valueArray.join('');
    this.inputRef.nativeElement.value = this.transformTime(updatedValue);
    this.inputRef.nativeElement.selectionStart = nextStep ?? selectionStart;
    this.inputRef.nativeElement.selectionEnd = nextStep ?? selectionStart;
  }

  setAMPMOption(value): void {
    if (!this.AMPM) this.AMPMOption = null;

    const hours = value?.slice(0, 2);
    this.AMPMOption = (+hours >= 0 && +hours < 12) || isNaN(+hours)
      ? AmPmEnum.AM
      : AmPmEnum.PM;
  }

  setShownInputValue(realValue): void {
    if (!this.AMPM) {
      setTimeout(() => {
        this.inputRef.nativeElement.value = realValue;
      });
      return;
    }

    setTimeout(() => {
      this.inputRef.nativeElement.value = TimeUtils.TwentyFourHoursFormatToAMPMTime(realValue, this.AMPMOption);
    });
  }

  transformTime(value: string): string {
    let hours = value.slice(0, 2);
    let minutes = value.slice(3);

    if (!hours.includes(this.maskSymbol)) {
      // 1-12 range for AMPM, 0-23 for 24h
      if (this.AMPM) {
        hours = +hours > 12 ? '12' : hours;
        hours = hours === '00' ? '01' : hours;
      } else {
        hours = +hours > 23 ? '23' : hours;
      }
    }

    if (!minutes.includes(this.maskSymbol)) {
      minutes = +minutes > 59 ? '59' : minutes;
    }

    return `${hours}:${minutes}`;
  }

  override saveValue(): void {
    // reset to default if was not filled properly
    const { value: shownInputValue } = this.inputRef.nativeElement;

    if (!shownInputValue.includes(this.maskSymbol)) {
      this._value = this.AMPM
        ? TimeUtils.AMPMTimeTo24hFormat(shownInputValue, this.AMPMOption)
        : shownInputValue;

      this.propagateChange(this._value);
      this.setShownInputValue(this._value);
      return;
    }

    this.inputRef.nativeElement.value = this.mask;
    this._value = '';
    this.propagateChange('');
    this.onFocusOutWithError.emit();
  }

  override writeValue(value: string): void {
    if (!value) {
      setTimeout(() => {
        this.inputRef.nativeElement.value = GlobalConstants.Input_time_mask;
      });
      return;
    }

    if (this._value === value) return;

    this._value = value;
    this.setAMPMOption(value);
    this.setShownInputValue(value);
  }
}
