export enum ControllerLanguageEnum {
  EngUS = 0,
  Spanish = 1,
  Portuguese = 2,
  EngUK,
  French,
  German,
  Hebrew,
  Italian,
  Turkish,
}

// TODO: remove in future
export enum ControllerLanguageEnumShortList {
  EngUS = 0,
  Spanish = 1,
  Portuguese = 2,
}

export const controllerLanguagesMapped = new Map([
  [ControllerLanguageEnum.EngUS, 'en'],
  [ControllerLanguageEnum.EngUK, 'en-GB'],
  [ControllerLanguageEnum.French, 'fr'],
  [ControllerLanguageEnum.German, 'ge'],
  [ControllerLanguageEnum.Hebrew, 'he'],
  [ControllerLanguageEnum.Italian, 'it'],
  [ControllerLanguageEnum.Portuguese, 'pt'],
  [ControllerLanguageEnum.Spanish, 'es'],
  [ControllerLanguageEnum.Turkish, 'tu'],
]);

export namespace ControllerLanguageEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case ControllerLanguageEnum.EngUS:
        return 'Controller.GeneralSettings.LanguageDialog.LanguageEnum.EnglishUS';
      case ControllerLanguageEnum.EngUK:
        return 'Controller.GeneralSettings.LanguageDialog.LanguageEnum.EnglishUK';
      case ControllerLanguageEnum.French:
        return 'Controller.GeneralSettings.LanguageDialog.LanguageEnum.French';
      case ControllerLanguageEnum.German:
        return 'Controller.GeneralSettings.LanguageDialog.LanguageEnum.German';
      case ControllerLanguageEnum.Hebrew:
        return 'Controller.GeneralSettings.LanguageDialog.LanguageEnum.Hebrew';
      case ControllerLanguageEnum.Italian:
        return 'Controller.GeneralSettings.LanguageDialog.LanguageEnum.Italian';
      case ControllerLanguageEnum.Portuguese:
        return 'Controller.GeneralSettings.LanguageDialog.LanguageEnum.Portuguese';
      case ControllerLanguageEnum.Spanish:
        return 'Controller.GeneralSettings.LanguageDialog.LanguageEnum.Spanish';
      case ControllerLanguageEnum.Turkish:
        return 'Controller.GeneralSettings.LanguageDialog.LanguageEnum.Turkish';
      default:
        return value.toString();
    }
  }
}
