import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IGeneralSettingsView,
  IDateTimeSettingsView,
  INetworkSettingsView,
  IHouseSizesView,
} from '@livestock/controllers/interfaces';
import {
  LengthUnitEnum,
} from '@livestock/shared/enums';
import { QuickStartStatusEnum } from '@livestock/controllers/enums';
import { ConvertHelper } from '@livestock/shared/utils';
import { ITicketQrCodeInfo } from '../interfaces/controller/ticket-qr-code-info.interface';
import {
  defaultControllerID,
  deviceControllerUrl,
  deviceQuickStartUrl,
  deviceUrl,
} from './basic-api-url.constants';

@Injectable({
  providedIn: 'root',
})
export class QuickStartService {
  private http: HttpClient = inject(HttpClient);

  createGeneralSettingsViaDevice(controllerID: number, requestView: IGeneralSettingsView): Observable<void> {
    return this.http.put<void>(`${deviceUrl(controllerID || defaultControllerID)}/general`, requestView);
  }

  createHouseSizesViaDevice(controllerID: number, requestView: IHouseSizesView, lengthUnit: LengthUnitEnum): Observable<void> {
    if (lengthUnit === LengthUnitEnum.Foot) {
      requestView = {
        ...requestView,
        houseHeight: ConvertHelper.feetToMeters(requestView.houseHeight, 1),
        houseLength: ConvertHelper.feetToMeters(requestView.houseLength, 1),
        houseRoofHeight: ConvertHelper.feetToMeters(requestView.houseRoofHeight, 1),
        houseWidth: ConvertHelper.feetToMeters(requestView.houseWidth, 1),
      };
    }
    return this.http.put<void>(`${deviceUrl(controllerID || defaultControllerID)}/house-sizes`, requestView);
  }

  createDateTimeSettingsViaDevice(controllerID: number, requestView: IDateTimeSettingsView): Observable<void> {
    return this.http.put<void>(`${deviceUrl(controllerID)}/date-time`, requestView);
  }

  createNetworkSettingsViaDevice(controllerID: number, requestView: INetworkSettingsView): Observable<void> {
    return this.http.put<void>(`${deviceUrl(controllerID)}/network`, requestView);
  }

  // createFlockSettingsViaDevice(controllerID: number, requestView: IFlockQuickStartView): Observable<void> {
  //   return this.http.put<void>(`${deviceUrl(controllerID)}/flock`, requestView);
  // }
  //
  // getFlockWeightReferenceTable(brand: ChickenBrandWeight, weightUnit: WeightUnitEnum, controllerID: number): Observable<IGetFlockDefaultWeightView> {
  //   return this.http.get<IGetFlockDefaultWeightView>(`${deviceUrl(controllerID)}/weight-reference-table/${brand}/${weightUnit}`);
  // }
  //
  // createHouseModesViaDevice(controllerID: number, requestView: IFlockHouseModeView, temperatureUnit: TemperatureUnitEnum): Observable<void> {
  //   requestView = {
  //     controllerID,
  //     ...requestView,
  //     timeChangeNextMode: Number((requestView.timeChangeNextMode as unknown as string).replace(':', '')),
  //   };
  //
  //   if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
  //     requestView = {
  //       ...requestView,
  //       targetTemperatureCatching: ConvertHelper.fahrenheitToCelsius(requestView.targetTemperatureCatching, 1),
  //       targetTemperatureCleaning: ConvertHelper.fahrenheitToCelsius(requestView.targetTemperatureCleaning, 1),
  //       targetTemperatureEmpty: ConvertHelper.fahrenheitToCelsius(requestView.targetTemperatureEmpty, 1),
  //       targetTemperaturePreheat: ConvertHelper.fahrenheitToCelsius(requestView.targetTemperaturePreheat, 1),
  //     };
  //   }
  //
  //   const {
  //     ventilationCatching,
  //     ventilationCleaning,
  //     ventilationEmpty,
  //     ventilationPreheat,
  //     ...view
  //   } = requestView;
  //
  //   return this.http.put<void>(`${deviceUrlFlock(controllerID)}/house-mode`, {
  //     ...view,
  //     minimumVentilationEmpty: Number(ventilationEmpty.min),
  //     maximumVentilationEmpty: Number(ventilationEmpty.max),
  //     minimumVentilationPreheat: Number(ventilationPreheat.min),
  //     maximumVentilationPreheat: Number(ventilationPreheat.max),
  //     minimumVentilationCleaning: Number(ventilationCleaning.min),
  //     maximumVentilationCleaning: Number(ventilationCleaning.max),
  //     minimumVentilationCatching: Number(ventilationCatching.min),
  //     maximumVentilationCatching: Number(ventilationCatching.max),
  //   });
  // }

  getControllerSerialNumber(controllerID: number): Observable<{ serialNumber: string }> {
    return this.http.get<{ serialNumber: string }>(`${deviceUrl(controllerID)}/serial-number`);
  }

  getControllerQrCodeInfo(): Observable<ITicketQrCodeInfo> {
    return this.http.get<ITicketQrCodeInfo>(`${deviceControllerUrl('')}quickstart/cloud-data`);
  }

  getQuickstartStatus(): Observable<string> {
    return this.http.get<string>(deviceQuickStartUrl(''));
  }

  setQuickstartStatusStep(step: QuickStartStatusEnum): Observable<void> {
    return this.http.put<void>(deviceQuickStartUrl(step), null);
  }
}
