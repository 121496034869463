export interface IMessageCodeModel {
  code: number;
  message: string;
}

export interface IBaseResponse {
  messages: IMessageCodeModel[];
  result: boolean;
}

export interface IResponse<T> extends IBaseResponse {
  body: T;
}
