import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UpsertControllerState } from './upsert-controller.state';
import { UPSERT_CONTROLLER_FEATURE_KEY } from './upsert-controller.reducers';
import { GlobalConstants } from '@livestock/shared/constants';
import { ControllerUnitEnum } from '../../enums/controller/controller-unit.enum';
import { AirPressureUnitEnum, LengthUnitEnum, TemperatureUnitEnum, WeightUnitEnum } from '@livestock/shared/enums';

export const getUpsertControllerState =
  createFeatureSelector<UpsertControllerState>(UPSERT_CONTROLLER_FEATURE_KEY);

export const selectIsLoading = createSelector(
  getUpsertControllerState,
  ({ isLoading }) => isLoading,
);

export const selectCellularIsLoading = createSelector(
  getUpsertControllerState,
  ({ networkSettings: { cellularLoading } }) => cellularLoading,
);

export const selectGeneralSettings = createSelector(
  getUpsertControllerState,
  ({ generalSettings }) => generalSettings,
);

export const selectGeneralSettingsWithoutUnits = createSelector(
  getUpsertControllerState,
  ({ generalSettings }) => {
    const generalSettingsWithoutUnits = { ...generalSettings };
    delete generalSettingsWithoutUnits.unitsDetails;
    delete generalSettingsWithoutUnits.units;
    delete generalSettingsWithoutUnits.actualUnits;
    return generalSettingsWithoutUnits;
  },
);

export const selectDateTimeSettings = createSelector(
  getUpsertControllerState,
  ({ dateTimeSettings }) => dateTimeSettings,
);

export const selectDateTimeSettingsHoursFormat = createSelector(
  selectDateTimeSettings,
  (dateTimeSettings) => dateTimeSettings?.hoursFormat,
);

export const selectNetworkSettings = createSelector(
  getUpsertControllerState,
  ({ networkSettings }) => networkSettings,
);

export const selectNetworkStatus = createSelector(
  getUpsertControllerState,
  ({ networkStatus }) => networkStatus,
);

export const selectTicketQrCodeInfo = createSelector(
  getUpsertControllerState,
  ({ ticketQrCodeInfo }) => ticketQrCodeInfo,
);

export const selectIsControllerAssignedWithFarm = createSelector(
  getUpsertControllerState,
  (store) => store?.isControllerAssignedWithFarm,
);

export const selectUpdateDateTimeSettings = createSelector(
  getUpsertControllerState,
  ({ dateTimeSettings }) => {
    return {
      controllerID: dateTimeSettings.controllerID,
      timeZoneID: dateTimeSettings.timeZoneID,
      hoursFormat: dateTimeSettings.hoursFormat,
      manuallyDateTime: dateTimeSettings.manuallyDateTime?.format(
        GlobalConstants.DateFormat,
      ),
      manuallyDateTimeMoment: dateTimeSettings.manuallyDateTime,
    };
  },
);

export const selectCurrentStep = createSelector(
  getUpsertControllerState,
  ({ currentStep }) => currentStep,
);

export const selectLengthUnit = createSelector(
  selectGeneralSettings,
  (generalSettings) => {
    switch (generalSettings?.units) {
      case ControllerUnitEnum.Metric:
        return LengthUnitEnum.Meter;
      case ControllerUnitEnum.Imperial:
        return LengthUnitEnum.Foot;
      default:
        return generalSettings?.unitsDetails?.length;
    }
  },
);

export const selectLanguage = createSelector(
  selectGeneralSettings,
  (generalSettings) => generalSettings?.language,
);

export const selectTemperatureUnit = createSelector(
  selectGeneralSettings,
  (generalSettings) => {
    switch (generalSettings?.units) {
      case ControllerUnitEnum.Metric:
        return TemperatureUnitEnum.Celsius;
      case ControllerUnitEnum.Imperial:
        return TemperatureUnitEnum.Fahrenheit;
      default:
        return generalSettings?.unitsDetails?.temperature;
    }
  },
);

export const selectAirPressureUnit = createSelector(
  selectGeneralSettings,
  (generalSettings) => {
    switch (generalSettings?.units) {
      case ControllerUnitEnum.Metric:
        return AirPressureUnitEnum.Pascal;
      case ControllerUnitEnum.Imperial:
        return AirPressureUnitEnum.InchesOfWater;
      default:
        return generalSettings?.unitsDetails?.airPressure;
    }
  },
);

export const selectWeightUnit = createSelector(
  selectGeneralSettings,
  (generalSettings) => {
    switch (generalSettings?.units) {
      case ControllerUnitEnum.Metric:
        return WeightUnitEnum.Kilogram;
      case ControllerUnitEnum.Imperial:
        return WeightUnitEnum.Pound;
      default:
        return generalSettings?.unitsDetails?.weight;
    }
  },
);

export const selectDeviceControllerSerialNumber = createSelector(
  getUpsertControllerState,
  ({ deviceControllerSerialNumber }) => deviceControllerSerialNumber,
);

export const selectAssigningToFarmInProgress = createSelector(
  getUpsertControllerState,
  ({ assigningToFarmInProgress }) => assigningToFarmInProgress,
);

export const selectNetworkConnectionInProgress = createSelector(
  getUpsertControllerState,
  ({ networkConnectionInProgress }) => networkConnectionInProgress,
);

export const selectCloudConnectionRequest = createSelector(
  getUpsertControllerState,
  ({ cloudConnectionRequest }) => cloudConnectionRequest,
);

export const selectTicketQrCodeError = createSelector(
  getUpsertControllerState,
  ({ ticketQrCodeError }) => ticketQrCodeError,
);
