<section
  [class.view-mode]="!isEditMode"
  class="d-flex flex-column h-100-percent justify-between">
  <div
    [formGroup]="form"
    class="form">
    <!--flock-settings number-->
    <div class="mb-28">
      <lv-input-integer-with-label
        [fullWidth]="true"
        [fontWeight]="500"
        [textAlign]="'center'"
        [label]="'Flock.FlockNumberHash'"
        [forceDisabled]="true"
        class="field disabled"
        formControlName="flockNumber" />
    </div>

    <!--house mode && growthDay-->
    <div class="mb-28 d-flex gap-8">
      <div class="d-flex">
        <div
          [ngClass]="isGrowingOrPreheat ? 'field' : 'field-house-mode-full'"
          class="ls-input position-relative no-right-border-radius">
          <div class="input-wrapper">
            <span class="color-mono-darkgrey fw-500">
              {{ form.value.houseMode | enum : "house-mode-enum" | translate }}
            </span>

            <label class="input-label input-label_filled">
              {{ "Flock.HouseMode" | translate }}
            </label>
          </div>
        </div>

        <div
          (click)="openChangeHouseModePopup()"
          [ngClass]="isEditMode ? 'pointer' : 'pointer-events-none'"
          class="open-house-mode-popup">
          <ls-svg-icon
            [path]="IconsEnum.ARROWS_UP_DOWN"
            [color]="isEditMode ? ColorsEnum.MainBlue : ColorsEnum.MonoGrey">
          </ls-svg-icon>
        </div>
      </div>

      <div
        *ngIf="isGrowingOrPreheat"
        class="position-relative">
        <lv-input-integer-with-label
          (onFocusIn)="growthDayFocused = true"
          (onFocusOut)="growthDayFocused = false"
          [min]="FlockConstants.GrowthDayMin"
          [max]="FlockConstants.GrowthDayMax"
          [fullWidth]="true"
          [class.disabled]="!isEditMode"
          [textAlign]="'center'"
          [label]="'Flock.GrowthDay'"
          [inputWrapperContentAlign]="'center'"
          [lsInputClassList]="'ls-input__white-bg'"
          class="field-short validate-untouched w-input-45"
          formControlName="growthDay" />
        <div *ngIf="growthDayFocused || form.controls.growthDay.invalid">
          <p
            [class.input-error]="form.controls.growthDay.invalid"
            class="fz-12 position-absolute bottom--14 left-0">
            {{ "Inputs.Range" | translate }} {{ FlockConstants.GrowthDayMin }} +{{ FlockConstants.GrowthDayMax }}
          </p>
        </div>
      </div>
    </div>

    <!---->
    <!---->
    <!--GROWING HOUSE MODE && PREHEAT HOUSE MODE-->
    <!---->
    <!---->
    <ng-container *ngIf="isGrowingOrPreheat">
      <!--grow start time-->
      <div class="mb-28">
        <div class="field-full">
          <div class="ls-input position-relative">
            <div class="input-wrapper">
              <lv-input-time
                [noBorder]="true"
                [class.disabled]="!isEditMode"
                [whiteBackground]="true"
                [mainClassList]="
                  (hoursFormat$ | async) === HoursFormatTypeEnum.AmPm ? 'w-150 m-auto l-10' : 'w-130 m-auto l-10'
                "
                [AMPM]="(hoursFormat$ | async) === HoursFormatTypeEnum.AmPm"
                class="field-full"
                formControlName="growStartDayTime">
                <ls-svg-icon
                  [path]="IconsEnum.CLOCK"
                  class="mr-5"
                  leftIcon></ls-svg-icon>
              </lv-input-time>

              <label class="input-label input-label_filled">
                {{ "Flock.GrowStartDay0" | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <!--number of birds-->
      <div class="d-flex align-center gap-8 mb-28 position-relative">
        <div
          [ngClass]="form.controls.separateMaleAndFemale.value === false ? 'field' : 'field-short'"
          class="ls-input position-relative">
          <div class="input-wrapper">
            <lv-dual-toggle
              [optionsTitlePosition]="form.controls.separateMaleAndFemale.value === false ? 'both' : 'before'"
              [firstOption]="{
                title: 'Common.Yes',
                value: true,
                theme: ThemeEnum.Main,
                toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
              }"
              [secondOption]="{
                title: 'Common.No',
                value: false,
                theme: ThemeEnum.Dark,
                toggleIcon: IconsEnum.CROSS_SMALL_BLACK
              }"
              [class.disabled]="!isEditMode"
              class="m-auto"
              formControlName="separateMaleAndFemale" />

            <label class="input-label input-label_filled">
              {{ "Flock.Separation" | translate }}
            </label>
          </div>
        </div>

        <ng-container *ngIf="form.controls.separateMaleAndFemale.value === false; else separateMaleFemale">
          <lv-input-integer-with-label
            (onFocusIn)="showMaxNumberOfBirdsMessage(true)"
            (onFocusOut)="showMaxNumberOfBirdsMessage(false)"
            [fullWidth]="true"
            [noWrapperPadding]="true"
            [min]="FlockConstants.InitialNumberOfBirdsMin"
            [max]="FlockConstants.InitialNumberOfBirdsMax"
            [label]="'Flock.NumberOfBirds'"
            [textAlign]="'center'"
            [lsInputClassList]="'ls-input__white-bg'"
            [class.disabled]="!isEditMode"
            class="field validate-untouched"
            formControlName="initialNumberOfBirds" />
        </ng-container>

        <ng-template #separateMaleFemale>
          <lv-input-integer-with-label
            (onFocusIn)="showMaxNumberPerGenderMessage(true)"
            (onFocusOut)="showMaxNumberPerGenderMessage(false)"
            [min]="FlockConstants.MaleInitialNumberOfBirdsMin"
            [max]="FlockConstants.MaleInitialNumberOfBirdsMax"
            [fullWidth]="true"
            [fontWeightDependingOnFocus]="true"
            [noPaddings]="true"
            [noWrapperPadding]="true"
            [textAlign]="'center'"
            [labelIcon]="IconsEnum.MALE"
            [label]="'Flock.Male'"
            [lsInputClassList]="'ls-input__white-bg ph-8'"
            [class.disabled]="!isEditMode"
            class="field-short position-relative validate-untouched"
            formControlName="maleInitialNumberOfBirds" />

          <lv-input-integer-with-label
            (onFocusIn)="showMaxNumberPerGenderMessage(true)"
            (onFocusOut)="showMaxNumberPerGenderMessage(false)"
            [min]="FlockConstants.FemaleInitialNumberOfBirdsMin"
            [max]="FlockConstants.FemaleInitialNumberOfBirdsMax"
            [fullWidth]="true"
            [fontWeightDependingOnFocus]="true"
            [noPaddings]="true"
            [noWrapperPadding]="true"
            [textAlign]="'center'"
            [labelIcon]="IconsEnum.FEMALE"
            [label]="'Flock.Female'"
            [lsInputClassList]="'ls-input__white-bg ph-8'"
            [class.disabled]="!isEditMode"
            class="field-short validate-untouched"
            formControlName="femaleInitialNumberOfBirds" />
        </ng-template>

        <div
          *ngIf="maxNumberOfBirdsMessageIsShown"
          class="position-absolute fz-12 bottom--14 right-0">
          <span>{{ "Flock.MaximumNumberOfBirds" | translate }}</span>
        </div>

        <div
          *ngIf="maxNumberPerGenderMessageIsShown"
          class="position-absolute fz-12 bottom--14 right-0">
          <span>{{ "Flock.MaximumNumberOfBirdsPerGender" | translate }}</span>
        </div>
      </div>

      <!--staging-->
      <div class="d-flex align-center gap-8">
        <div class="ls-input position-relative field-full">
          <div class="input-wrapper">
            <lv-dual-toggle
              [firstOption]="{
                title: 'Flock.FullHouse',
                value: StagingEnum.FullHouse
              }"
              [secondOption]="{
                title: 'Flock.Brooding',
                value: StagingEnum.Brooding
              }"
              [class.disabled]="!isEditMode"
              class="m-auto"
              formControlName="staging" />

            <label class="input-label input-label_filled">
              {{ "Flock.Staging" | translate }}
            </label>
          </div>
        </div>
      </div>
    </ng-container>

    <!---->
    <!---->
    <!--CATCHING HOUSE MODE || CLEANING HOUSE MODE-->
    <!---->
    <!---->
    <ng-container *ngIf="isCatchingOrCleaning">
      <div class="d-flex align-center gap-8 mb-28">
        <!--target temperature-->
        <div class="position-relative">
          <lv-input-decimal-with-label
            (onFocusIn)="temperatureFieldFocused = true"
            (onFocusOut)="temperatureFieldFocused = false"
            [accuracy]="1"
            [label]="'Flock.TargetTemperature'"
            [lsInputClassList]="'ls-input__white-bg w-60 justify-center'"
            [inputCssClass]="'text-center'"
            [max]="maxTemperature"
            class="field"
            formControlName="targetTemperature">
            <ng-container
              *ngTemplateOutlet="unit; context: { isValid: form['controls'].targetTemperature.valid }"
              rightIcon>
            </ng-container>
          </lv-input-decimal-with-label>

          <div *ngIf="temperatureFieldFocused || form.controls.targetTemperature.invalid">
            <p
              [class.input-error]="form.controls.targetTemperature.invalid"
              class="fz-12 position-absolute bottom--14 left-0">
              {{ "Inputs.Range" | translate }} {{ FlockConstants.MinTargetTemperature }} +{{ maxTemperature }}
            </p>
          </div>
        </div>

        <!--temperature alarm-->
        <div class="ls-input position-relative field">
          <div class="input-wrapper">
            <lv-dual-toggle
              [firstOption]="{
                title: 'Common.Yes',
                value: true,
                theme: ThemeEnum.Main,
                toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
              }"
              [secondOption]="{
                title: 'Common.No',
                value: false,
                theme: ThemeEnum.Dark,
                toggleIcon: IconsEnum.CROSS_SMALL_BLACK
              }"
              class="m-auto"
              formControlName="enableTemperatureAlarm" />

            <label class="input-label input-label_filled">
              {{ "Flock.TemperatureAlarm" | translate }}
            </label>
          </div>
        </div>
      </div>

      <div
        *ngIf="form.value.houseMode === HouseModeEnum.Cleaning"
        class="mb-28">
        <!--VENTILATION range-->
        <div class="ls-input position-relative field-full field-light">
          <div class="input-wrapper">
            <lv-value-slider
              [step]="10"
              [min]="FlockConstants.MinVentilation"
              [max]="FlockConstants.MaxVentilation"
              formControlName="ventilationLevel" />

            <label class="input-label input-label_filled">
              {{ "Flock.Ventilation" | translate }}
            </label>
          </div>
        </div>
      </div>

      <div class="mb-28">
        <!--LIGHT range-->
        <div class="ls-input position-relative field-full field-light">
          <div class="input-wrapper">
            <!--            <lv-value-slider-->
            <!--              [step]="10"-->
            <!--              [tickTheme]="'yellow'"-->
            <!--              [min]="FlockConstants.MinLight"-->
            <!--              [max]="FlockConstants.MaxLight"-->
            <!--              formControlName="light">-->
            <!--              <span-->
            <!--                class="fw-500"-->
            <!--                unit>-->
            <!--                %-->
            <!--              </span>-->
            <!--            </lv-value-slider>-->

            <label class="input-label input-label_filled">
              {{ "Flock.Lights" | translate }}
            </label>
          </div>
        </div>
      </div>

      <!--lights-->
      <div class="ls-input position-relative field">
        <div class="input-wrapper">
          <lv-dual-toggle
            [firstOption]="{
              title: 'Common.On',
              value: true,
              theme: ThemeEnum.Main,
              toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
            }"
            [secondOption]="{
              title: 'Common.Off',
              value: false,
              theme: ThemeEnum.Dark,
              toggleIcon: IconsEnum.CROSS_SMALL_BLACK
            }"
            class="m-auto"
            formControlName="lights" />

          <label class="input-label input-label_filled">
            {{ "Flock.Lights" | translate }}
          </label>
        </div>
      </div>
    </ng-container>

    <!---->
    <!---->
    <!--EMPTY HOUSE-->
    <!---->
    <!---->
    <ng-container *ngIf="form.value.houseMode === HouseModeEnum.Empty">
      <div class="mb-28 position-relative">
        <!--target temperature-->
        <lv-input-decimal-with-label
          (onFocusIn)="temperatureFieldFocused = true"
          (onFocusOut)="temperatureFieldFocused = false"
          [accuracy]="1"
          [label]="'Flock.TargetTemperature'"
          [lsInputClassList]="'ls-input__white-bg w-60 justify-center'"
          [inputCssClass]="'text-center'"
          [class.disabled]="!isEditMode"
          [max]="maxTemperature"
          class="field-full"
          formControlName="targetTemperature">
          <ng-container
            *ngTemplateOutlet="unit; context: { isValid: form['controls'].targetTemperature.valid }"
            rightIcon>
          </ng-container>
        </lv-input-decimal-with-label>

        <div *ngIf="temperatureFieldFocused || form.controls.targetTemperature.invalid">
          <p
            [class.input-error]="form.controls.targetTemperature.invalid"
            class="fz-12 position-absolute bottom--14 left-0">
            {{ "Inputs.Range" | translate }} {{ FlockConstants.MinTargetTemperature }} +{{ maxTemperature }}
          </p>
        </div>
      </div>

      <div class="mb-28">
        <!--VENTILATION range-->
        <div class="ls-input position-relative field-full field-light">
          <div class="input-wrapper">
            <lv-value-slider
              [step]="10"
              [class.disabled]="!isEditMode"
              [min]="FlockConstants.MinVentilation"
              [max]="FlockConstants.MaxVentilation"
              formControlName="ventilationLevel" />

            <label class="input-label input-label_filled">
              {{ "Flock.Ventilation" | translate }}
            </label>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- new flock btn-->
  <lv-button
    (click)="openNewFlockDialog()"
    [class.disabled]="!isEditMode"
    [class.edit-mode]="isEditMode"
    [type]="isEditMode ? ButtonTypeEnum.PRIMARY : ButtonTypeEnum.DISABLED"
    class="new-flock-btn">
    {{ "Flock.NewFlock" | translate }}
  </lv-button>
</section>

<ng-template
  #unit
  let-isValid="isValid">
  <span
    [class.color-error]="!isValid"
    class="mh-5">
    {{ ((temperatureUnit$ | async) === TemperatureUnitEnum.Celsius ? "Celsius" : "Fahrenheit") | translate }}
  </span>
</ng-template>
