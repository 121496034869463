<div
  [class.warning]="!isConfirmed"
  class="popup-wrapper p-28">
  <ng-container *ngIf="!isConfirmed; else newFlockForm">
    <h2 class="fz-24 fw-600 color-dark-red mb-8">{{ "Flock.NewFlock" | translate }}</h2>
    <p class="fw-500 mb-18">
      {{ "Flock.NewFlockDialog.ThisActionWillDeleteExistingFlockAndWillStart" | translate }}
      <span class="fw-700">{{ "Flock.NewFlockDialog.NewFlock" | translate }}</span>
    </p>

    <p class="fw-700 mb-18">
      {{ "Flock.NewFlockDialog.ThisActionIsNotReversible" | translate }}
    </p>

    <div class="d-flex align-center justify-between">
      <lv-button
        (click)="isConfirmed = true"
        [type]="ButtonTypeEnum.PRIMARY"
        class="w-130">
        {{ "Buttons.Continue" | translate }}
      </lv-button>

      <lv-button
        (click)="closePopup()"
        [type]="ButtonTypeEnum.SECONDARY"
        class="w-114">
        {{ "Buttons.Cancel" | translate }}
      </lv-button>
    </div>
  </ng-container>

  <ng-template #newFlockForm>
    <h2 class="fz-24 fw-600 color-dark-red mb-8">{{ "Flock.NewFlock" | translate }}</h2>
    <p class="fw-500 mb-28">
      {{ "Flock.NewFlockDialog.ReviewYouFlockSettingsBeforeStart" | translate }}
    </p>

    <div
      [formGroup]="form"
      class="form">
      <!--flock-settings number-->
      <div class="mb-28">
        <lv-input-integer-with-label
          [fullWidth]="true"
          [fontWeight]="500"
          [textAlign]="'center'"
          [label]="'Flock.FlockNumberHash'"
          class="field-full"
          formControlName="flockNumber" />
      </div>

      <div class="mb-28 d-flex align-center gap-8">
        <div class="ls-input position-relative field-big">
          <div class="input-wrapper">
            <lv-dual-toggle
              [firstOption]="{
                title: 'Flock.HouseModeEnum.Growing',
                value: HouseModeEnum.Growing,
              }"
              [secondOption]="{
                title: 'Flock.HouseModeEnum.Preheat',
                value: HouseModeEnum.PreHeat,
              }"
              class="m-auto"
              formControlName="houseMode" />

            <label class="input-label input-label_filled">
              {{ "Flock.HouseMode" | translate }}
            </label>
          </div>
        </div>

        <div class="position-relative">
          <lv-input-integer-with-label
            (onFocusIn)="growthDayFocused = true"
            (onFocusOut)="growthDayFocused = false"
            [min]="FlockConstants.GrowthDayMin"
            [max]="FlockConstants.GrowthDayMax"
            [fullWidth]="true"
            [textAlign]="'center'"
            [label]="'Flock.GrowthDay'"
            [inputWrapperContentAlign]="'center'"
            [lsInputClassList]="'ls-input__white-bg'"
            class="field-short validate-untouched w-input-45"
            formControlName="growthDay" />
          <div *ngIf="growthDayFocused || form.controls.growthDay.invalid">
            <p
              [class.input-error]="form.controls.growthDay.invalid"
              class="fz-12 position-absolute bottom--14 left-0">
              {{ "Inputs.Range" | translate }} {{ FlockConstants.GrowthDayMin }} +{{ FlockConstants.GrowthDayMax }}
            </p>
          </div>
        </div>
      </div>

      <div class="mb-28">
        <div class="field-full">
          <div class="ls-input position-relative">
            <div class="input-wrapper">
              <lv-input-time
                [noBorder]="true"
                [whiteBackground]="true"
                [mainClassList]="
                  (hoursFormat$ | async) === HoursFormatTypeEnum.AmPm ? 'w-150 m-auto l-10' : 'w-130 m-auto l-10'
                "
                [AMPM]="(hoursFormat$ | async) === HoursFormatTypeEnum.AmPm"
                class="field-full"
                formControlName="growStartDayTime">
                <ls-svg-icon
                  [path]="IconsEnum.CLOCK"
                  class="mr-5"
                  leftIcon></ls-svg-icon>
              </lv-input-time>

              <label class="input-label input-label_filled">
                {{ "Flock.GrowStartDay0" | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-28">
        <div class="d-flex align-center gap-8 mb-28 position-relative">
          <div
            [ngClass]="form.controls.separateMaleAndFemale.value === false ? 'field' : 'field-short'"
            class="ls-input position-relative">
            <div class="input-wrapper">
              <lv-dual-toggle
                [optionsTitlePosition]="form.controls.separateMaleAndFemale.value === false ? 'both' : 'before'"
                [firstOption]="{
                  title: 'Common.Yes',
                  value: true,
                  theme: ThemeEnum.Main,
                  toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
                }"
                [secondOption]="{
                  title: 'Common.No',
                  value: false,
                  theme: ThemeEnum.Dark,
                  toggleIcon: IconsEnum.CROSS_SMALL_BLACK
                }"
                class="m-auto"
                formControlName="separateMaleAndFemale" />

              <label class="input-label input-label_filled">
                {{ "Flock.Separation" | translate }}
              </label>
            </div>
          </div>

          <ng-container *ngIf="form.controls.separateMaleAndFemale.value === false; else separateMaleFemale">
            <lv-input-integer-with-label
              (onFocusIn)="showMaxNumberOfBirdsMessage(true)"
              (onFocusOut)="showMaxNumberOfBirdsMessage(false)"
              [fullWidth]="true"
              [noWrapperPadding]="true"
              [min]="FlockConstants.InitialNumberOfBirdsMin"
              [max]="FlockConstants.InitialNumberOfBirdsMax"
              [label]="'Flock.NumberOfBirds'"
              [textAlign]="'center'"
              [lsInputClassList]="'ls-input__white-bg'"
              class="field validate-untouched"
              formControlName="initialNumberOfBirds" />
          </ng-container>

          <ng-template #separateMaleFemale>
            <lv-input-integer-with-label
              (onFocusIn)="showMaxNumberPerGenderMessage(true)"
              (onFocusOut)="showMaxNumberPerGenderMessage(false)"
              [min]="FlockConstants.MaleInitialNumberOfBirdsMin"
              [max]="FlockConstants.MaleInitialNumberOfBirdsMax"
              [fullWidth]="true"
              [fontWeightDependingOnFocus]="true"
              [noPaddings]="true"
              [noWrapperPadding]="true"
              [textAlign]="'center'"
              [labelIcon]="IconsEnum.MALE"
              [label]="'Flock.Male'"
              [lsInputClassList]="'ls-input__white-bg ph-8'"
              class="field-short position-relative validate-untouched"
              formControlName="maleInitialNumberOfBirds" />

            <lv-input-integer-with-label
              (onFocusIn)="showMaxNumberPerGenderMessage(true)"
              (onFocusOut)="showMaxNumberPerGenderMessage(false)"
              [min]="FlockConstants.FemaleInitialNumberOfBirdsMin"
              [max]="FlockConstants.FemaleInitialNumberOfBirdsMax"
              [fullWidth]="true"
              [fontWeightDependingOnFocus]="true"
              [noPaddings]="true"
              [noWrapperPadding]="true"
              [textAlign]="'center'"
              [labelIcon]="IconsEnum.FEMALE"
              [label]="'Flock.Female'"
              [lsInputClassList]="'ls-input__white-bg ph-8'"
              class="field-short validate-untouched"
              formControlName="femaleInitialNumberOfBirds" />
          </ng-template>

          <div
            *ngIf="maxNumberOfBirdsMessageIsShown"
            class="position-absolute fz-12 bottom--14 right-0">
            <span>{{ "Flock.MaximumNumberOfBirds" | translate }}</span>
          </div>

          <div
            *ngIf="maxNumberPerGenderMessageIsShown"
            class="position-absolute fz-12 bottom--14 right-0">
            <span>{{ "Flock.MaximumNumberOfBirdsPerGender" | translate }}</span>
          </div>
        </div>
      </div>

      <div class="d-flex align-center gap-8 mb-28">
        <div class="ls-input position-relative field-full">
          <div class="input-wrapper">
            <lv-dual-toggle
              [firstOption]="{
                title: 'Flock.FullHouse',
                value: StagingEnum.FullHouse
              }"
              [secondOption]="{
                title: 'Flock.Brooding',
                value: StagingEnum.Brooding
              }"
              class="m-auto"
              formControlName="staging" />

            <label class="input-label input-label_filled">
              {{ "Flock.Staging" | translate }}
            </label>
          </div>
        </div>
      </div>

      <div class="d-flex align-center justify-between">
        <span
          (click)="closePopup()"
          class="text-underline pointer">
          {{ "Buttons.Cancel" | translate }}
        </span>

        <lv-button
          (click)="startNewFlock()"
          [class.disabled]="form.invalid"
          [type]="form.valid ? ButtonTypeEnum.PRIMARY : ButtonTypeEnum.DISABLED"
          class="w-180">
          {{ "Flock.NewFlockDialog.StartNewFlock" | translate }}
        </lv-button>
      </div>
    </div>
  </ng-template>
</div>
