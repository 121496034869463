<div
  #keyboard
  [ngClass]="{
    'keyboard-active': isActive
  }"
  class="keyboard">
  <ng-container *ngIf="ranges?.min && ranges?.max">
    <div class="ranges">
      {{ labelForRanges$ | async | translate : { min: ranges.min, max: ranges.max } }}
    </div>
  </ng-container>

  <ng-container *ngIf="keyboardMode$ | async as keyboardMode">
    <ng-container [ngSwitch]="keyboardMode">
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.Numeric"
        [ngTemplateOutletContext]="{ keyboardMode: keyboardMode }"
        [ngTemplateOutlet]="numericKeyboard"></ng-container>
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.Decimal"
        [ngTemplateOutletContext]="{ keyboardMode: keyboardMode }"
        [ngTemplateOutlet]="numericKeyboard"></ng-container>
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.NumericNegative"
        [ngTemplateOutletContext]="{ keyboardMode: keyboardMode }"
        [ngTemplateOutlet]="numericKeyboard"></ng-container>
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.DecimalNegative"
        [ngTemplateOutletContext]="{ keyboardMode: keyboardMode }"
        [ngTemplateOutlet]="numericKeyboard"></ng-container>
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.Full"
        [ngTemplateOutlet]="fullKeyboard"></ng-container>
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.AMPM"
        [ngTemplateOutletContext]="{ keyboardMode: keyboardMode }"
        [ngTemplateOutlet]="numericKeyboard"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template
  #numericKeyboard
  let-keyboardMode="keyboardMode">
  <div class="keyboard-row mb-6">
    <div
      *ngFor="let integer of VirtualKeyboardConstants.NumericArrayTop"
      (touchstart)="printMultipleSymbols(integer)"
      (touchend)="stopPrintingMultipleSymbols(integer)"
      class="button w-60">
      {{ integer }}
    </div>

    <!--print dot-->
    <div
      *ngIf="keyboardMode === VirtualKeyboardModesEnum.Decimal"
      (touchstart)="printMultipleSymbols(VirtualKeyboardButtonsEnum.DOT)"
      (touchend)="stopPrintingMultipleSymbols(VirtualKeyboardButtonsEnum.DOT)"
      class="button w-60">
      {{ VirtualKeyboardButtonsEnum.DOT }}
    </div>

    <!--print +/- -->
    <ng-container
      *ngIf="
        keyboardMode === VirtualKeyboardModesEnum.NumericNegative ||
        keyboardMode === VirtualKeyboardModesEnum.DecimalNegative
      ">
      <div
        (touchstart)="printMultipleSymbols(VirtualKeyboardButtonsEnum.MINUS)"
        (touchend)="stopPrintingMultipleSymbols(VirtualKeyboardButtonsEnum.MINUS)"
        class="button w-60">
        {{ VirtualKeyboardButtonsEnum.MINUS }}
      </div>

      <div
        (touchstart)="printMultipleSymbols(VirtualKeyboardButtonsEnum.PLUS)"
        (touchend)="stopPrintingMultipleSymbols(VirtualKeyboardButtonsEnum.PLUS)"
        class="button w-60">
        {{ VirtualKeyboardButtonsEnum.PLUS }}
      </div>
    </ng-container>

    <!--print AM/PM-->
    <ng-container *ngIf="keyboardMode === VirtualKeyboardModesEnum.AMPM">
      <div
        (touchend)="setAmPm(AmPmEnum.AM)"
        [class.button-disabled]="AMPMDisabled$ | async"
        class="button w-60">
        {{ "Buttons.AM" | translate }}
      </div>

      <div
        (touchend)="setAmPm(AmPmEnum.PM)"
        [class.button-disabled]="AMPMDisabled$ | async"
        class="button w-60">
        {{ "Buttons.PM" | translate }}
      </div>
    </ng-container>

    <div
      (touchstart)="printMultipleSymbols(VirtualKeyboardButtonsEnum.DEL)"
      (touchend)="stopPrintingMultipleSymbols(VirtualKeyboardButtonsEnum.DEL)"
      class="button button-grey w-88">
      <ls-svg-icon [path]="IconsEnum.BACKSPACE"></ls-svg-icon>
    </div>
  </div>

  <div class="keyboard-row">
    <div
      *ngFor="let integer of VirtualKeyboardConstants.NumericArrayBottom"
      (touchstart)="printMultipleSymbols(integer)"
      (touchend)="stopPrintingMultipleSymbols(integer)"
      class="button w-60">
      {{ integer }}
    </div>

    <!--print dot-->
    <div
      *ngIf="keyboardMode === VirtualKeyboardModesEnum.DecimalNegative"
      (touchstart)="printMultipleSymbols(VirtualKeyboardButtonsEnum.DOT)"
      (touchend)="stopPrintingMultipleSymbols(VirtualKeyboardButtonsEnum.DOT)"
      class="button w-60">
      {{ VirtualKeyboardButtonsEnum.DOT }}
    </div>

    <div
      (touchend)="hideKeyboard()"
      class="button button-save w-88">
      <ls-svg-icon [path]="IconsEnum.CHECKMARK_SMALL"></ls-svg-icon>
    </div>
  </div>
</ng-template>

<!---->
<!---->
<!---->
<!---->
<!---->
<!---->
<ng-template #fullKeyboard>
  <ng-container *ngIf="fullKeyboardMode === FullKeyboardModesEnum.Alphabetical; else fullKeyboardModeNumeric">
    <div class="keyboard-row mb-6">
      <div
        (touchend)="changeLanguage()"
        class="button button-grey w-52">
        <ls-svg-icon [path]="IconsEnum.LANG_CHANGE"></ls-svg-icon>
      </div>

      <div
        *ngFor="let letter of VirtualKeyboardConstants.LettersFirstRow"
        (touchstart)="printMultipleLetters(letter)"
        (touchend)="stopPrintingMultipleSymbols(letter, true)"
        class="button w-48">
        {{ lettersToUppercase ? (letter | uppercase) : letter }}
      </div>

      <div
        (touchstart)="printMultipleSymbols(VirtualKeyboardButtonsEnum.DEL)"
        (touchend)="stopPrintingMultipleSymbols(VirtualKeyboardButtonsEnum.DEL)"
        class="button button-grey w-52">
        <ls-svg-icon [path]="IconsEnum.BACKSPACE"></ls-svg-icon>
      </div>
    </div>

    <div class="keyboard-row mb-6">
      <div
        (touchend)="fullKeyboardToNumericMode()"
        class="button button-grey button-switch-mode w-79">
        123
      </div>

      <div
        *ngFor="let letter of VirtualKeyboardConstants.LettersSecondRow"
        (touchstart)="printMultipleLetters(letter)"
        (touchend)="stopPrintingMultipleSymbols(letter, true)"
        class="button w-48">
        {{ lettersToUppercase ? (letter | uppercase) : letter }}
      </div>

      <div
        (touchend)="toggleLettersUppercase()"
        class="button button-grey w-79">
        <ls-svg-icon [path]="IconsEnum.LETTER_TO_UPPERCASE"></ls-svg-icon>
      </div>
    </div>

    <div class="keyboard-row">
      <div
        (touchstart)="printMultipleSymbols(VirtualKeyboardButtonsEnum.SPACE)"
        (touchend)="stopPrintingMultipleSymbols(VirtualKeyboardButtonsEnum.SPACE)"
        class="button w-133">
        <ls-svg-icon [path]="IconsEnum.KEYBOARD_SPACE"></ls-svg-icon>
      </div>

      <div
        *ngFor="let letter of VirtualKeyboardConstants.LettersThirdRow"
        (touchstart)="printMultipleLetters(letter)"
        (touchend)="stopPrintingMultipleSymbols(letter, true)"
        class="button w-48">
        {{ lettersToUppercase ? (letter | uppercase) : letter }}
      </div>

      <div
        (touchend)="hideKeyboard()"
        class="button button-save w-133">
        <ls-svg-icon [path]="IconsEnum.CHECKMARK_SMALL"></ls-svg-icon>
      </div>
    </div>
  </ng-container>

  <!---->
  <!--fullKeyboardModeNumeric-->
  <!---->
  <ng-template #fullKeyboardModeNumeric>
    <div class="keyboard-row mb-6">
      <div
        *ngFor="let symbol of VirtualKeyboardConstants.NumbersFirstRow"
        (touchstart)="printMultipleSymbols(symbol)"
        (touchend)="stopPrintingMultipleSymbols(symbol)"
        class="button w-48">
        {{ symbol }}
      </div>

      <div
        (touchstart)="printMultipleSymbols(VirtualKeyboardButtonsEnum.DEL)"
        (touchend)="stopPrintingMultipleSymbols(VirtualKeyboardButtonsEnum.DEL)"
        class="button button-grey w-110">
        <ls-svg-icon [path]="IconsEnum.BACKSPACE"></ls-svg-icon>
      </div>
    </div>

    <div class="keyboard-row mb-6">
      <div
        (touchend)="fullKeyboardToAlphabeticalMode()"
        class="button button-grey button-switch-mode w-110">
        ABC
      </div>

      <div
        *ngFor="let symbol of VirtualKeyboardConstants.SymbolsSecondRow"
        (touchstart)="printMultipleSymbols(symbol)"
        (touchend)="stopPrintingMultipleSymbols(symbol)"
        class="button w-48">
        {{ symbol }}
      </div>
    </div>

    <div class="keyboard-row">
      <div
        (touchstart)="printMultipleSymbols(VirtualKeyboardButtonsEnum.SPACE)"
        (touchend)="stopPrintingMultipleSymbols(VirtualKeyboardButtonsEnum.SPACE)"
        class="button w-133">
        <ls-svg-icon [path]="IconsEnum.KEYBOARD_SPACE"></ls-svg-icon>
      </div>

      <div
        *ngFor="let symbol of VirtualKeyboardConstants.SymbolsThirdRow"
        (touchstart)="printMultipleSymbols(symbol)"
        (touchend)="stopPrintingMultipleSymbols(symbol)"
        class="button w-48">
        {{ symbol }}
      </div>

      <div
        (touchend)="hideKeyboard()"
        class="button button-save w-133">
        <ls-svg-icon [path]="IconsEnum.CHECKMARK_SMALL"></ls-svg-icon>
      </div>
    </div>
  </ng-template>
</ng-template>
