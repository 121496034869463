import { Moment } from 'moment';
import { HoursFormatTypeEnum } from '../../enums/date-time/hours-format-type.enum';
import { AutoDateTimeTypeEnum } from '../../enums/date-time/auto-date-time-type.enum';
import { TimeZonesEnum } from '@livestock/shared/constants';

export interface IDateTimeSettingsView {
  hoursFormat: HoursFormatTypeEnum;
  autoDateTimeType?: AutoDateTimeTypeEnum;
  date?: string;
  time?: string;
  controllerID?: number;
  timeZoneID?: TimeZonesEnum;
  manuallyDateTime?: Moment;
}
