import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FarmsListComponent } from '../../../../farms/src/lib/components/farms-list/farms-list.component';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { SvgIconComponent } from '@livestock/ui';
import {
  ControllerMenuComponent,
  ControllerMenuMobileComponent,
  selectIsMenuOpened,
  toggleMenuIsOpened,
  TopMenuComponent,
} from '@livestock/menu';
import { firstValueFrom, Observable, startWith } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthService } from '@livestock/auth';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRouteService, MenuService, PlatformService } from '@livestock/shared/services';
import { CurrentUserInfoComponent } from '@livestock/current-user';
import {
  LayoutComponent,
  MobileViewModeEnum,
  selectIsDialogOpened,
  selectMobileViewMode,
  setMobileViewMode,
} from '@livestock/layout';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { ControllerRoutes, IControllerView, selectControllerInfo } from '@livestock/controllers';
import {
  ControllerIndicatorsComponent,
} from '../../../../controllers/src/lib/components/controller-indicators/controller-indicators.component';

@Component({
  selector: 'lv-layout-mobile',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FarmsListComponent,
    MemoizeFuncPipe,
    RouterOutlet,
    SvgIconComponent,
    TopMenuComponent,
    CurrentUserInfoComponent,
    ControllerMenuComponent,
    ControllerMenuMobileComponent,
    ControllerIndicatorsComponent,
  ],
  templateUrl: './layout-mobile.component.html',
  styleUrls: ['./layout-mobile.component.scss'],
})
export class LayoutMobileComponent extends LayoutComponent implements AfterViewInit {
  menuIsOpened$: Observable<boolean> = this.store.select(selectIsMenuOpened);
  controllerInfo$: Observable<IControllerView> = this.store.select(selectControllerInfo);
  isDialogOpened$: Observable<boolean> = this.store.select(selectIsDialogOpened);

  mode: MobileViewModeEnum = MobileViewModeEnum.Farms;
  isEditFarmMode = false;
  showControllerIndicators: boolean = true;
  MobileViewModeEnum = MobileViewModeEnum;

  constructor(
    router: Router,
    menuService: MenuService,
    activatedRoute: ActivatedRoute,
    store: Store,
    authService: AuthService,
    activatedRouteService: ActivatedRouteService,
    platformService: PlatformService,
    private cdr: ChangeDetectorRef,
  ) {
    super(router, menuService, activatedRoute, store, authService, activatedRouteService, platformService);

    this.sub$.add(
      this.store.select(selectMobileViewMode).pipe(
        wasChanged(),
      ).subscribe((mobileViewMode) => {
        this.mode = mobileViewMode;
      }),
    );

    this.sub$.add(
      this.router.events
        .pipe(startWith(this.router))
        .subscribe((event) => {
          // show controller page (e.g. settings) after reload
          if (event instanceof Router && event['url'].includes('controller')) {
            this.store.dispatch(setMobileViewMode({ mobileViewMode: MobileViewModeEnum.Lobby }));
          }

          if (event instanceof NavigationEnd) {
            this.cdr.detectChanges();
          } else if (event instanceof NavigationStart) {
            this.showControllerIndicators = !this.activatedRouteService.onFarmSettings(event['url']);
          }
        }),
    );
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  async changeViewMode(mode: MobileViewModeEnum, goToLobbyPage: boolean = true): Promise<void> {
    this.store.dispatch(setMobileViewMode({ mobileViewMode: mode }));

    if (mode === MobileViewModeEnum.Menu) {
      this.store.dispatch(toggleMenuIsOpened({ menuIsOpened: true }));
      return;
    }

    this.store.dispatch(toggleMenuIsOpened({ menuIsOpened: false }));

    if (mode === MobileViewModeEnum.Lobby && goToLobbyPage) {
      const activeControllerID = await firstValueFrom(this.activeControllerID$);
      this.router.navigate([`controller/${activeControllerID}/${ControllerRoutes.Lobby}`]);
    }
  }

  showPage(): void {
    // to make it instantly
    this.mode = MobileViewModeEnum.Lobby;
    this.store.dispatch(setMobileViewMode({ mobileViewMode: this.mode }));
  }

  setIsEditFarmMode(isEditFarmMode: boolean): void {
    this.isEditFarmMode = isEditFarmMode;
  }

  override goToMainPage(): void {
    this.store.dispatch(setMobileViewMode({ mobileViewMode: MobileViewModeEnum.Farms }));
    this.router.navigate(['/']);
  }
}
