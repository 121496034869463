<div class="button button-{{ type }}">
  <ls-svg-icon
    *ngIf="iconPath"
    [path]="iconPath"
    class="widget">
  </ls-svg-icon>

  <ls-svg-icon
    *ngIf="type === ButtonWizardTypeEnum.ERROR"
    [path]="IconsEnum.EXCLAMATION">
  </ls-svg-icon>
</div>
