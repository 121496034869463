import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  IconsEnum,
  TemperatureUnitEnum,
  ButtonTypeEnum,
  ColorsEnum, SensorTypeEnum,
} from '@livestock/shared/enums';
import {
  DualToggleWithLabelComponent,
  LVInputComponent,
  LVInputDecimalWithLabelComponent,
  SvgIconComponent,
  ThemeEnum,
  SimpleDropdownComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, Validators, ControlContainer, FormGroup, FormControl } from '@angular/forms';
import { InOutdoorTemperatureStatusEnum } from '@livestock/installation/enums';
import { BasicElementComponent, IGetOrUpdateElement, InstallationConstants } from '@livestock/installation';
import { ElementEventInfo } from '@livestock/installation/interfaces';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SvgIconComponent,
    LVInputComponent,
    DualToggleWithLabelComponent,
    LVInputDecimalWithLabelComponent,
    SimpleDropdownComponent,
  ],
  selector: 'lv-co2-setup',
  templateUrl: './co2.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class Co2Component extends BasicElementComponent implements OnInit {
  @Input() elementEvent: ElementEventInfo;
  @Output() delete: EventEmitter<void> = new EventEmitter();

  // vars
  emptyValueStatuses = InOutdoorTemperatureStatusEnum.getEmptyValueStatuses();

  // enums
  IconsEnum: typeof IconsEnum = IconsEnum;
  TemperatureUnitEnum = TemperatureUnitEnum;
  ThemeEnum = ThemeEnum;
  SensorTypeEnum = SensorTypeEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  ColorsEnum = ColorsEnum;
  InOutdoorTemperatureStatusEnum = InOutdoorTemperatureStatusEnum;
  InstallationConstants = InstallationConstants;

  override ngOnInit(): void {
    const disabled = !this.isEditMode;
    this.parentFormGroup.addControl(this.formGroupName,
      new FormGroup({
        elementID: new FormControl<number>({ value: null, disabled }),
        number: new FormControl<number>({ value: 1, disabled }),
        sensorName: new FormControl<string>({ value: 'CO2 - 01', disabled }, [Validators.required]),
        sensorOn: new FormControl<boolean>({ value: true, disabled }),
        offset: new FormControl<number>({ value: 0, disabled }, [
          Validators.min(InstallationConstants.MinCO2Offset),
          Validators.max(InstallationConstants.MaxCO2Offset),
        ]),
        sensorType: new FormControl<SensorTypeEnum>({ value: SensorTypeEnum.V_0_10, disabled }),
      }));
    super.ngOnInit();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      elementID: setupData.elementID,
      sensorName: setupData.sensorName,
      sensorOn: setupData.sensorOn,
      offset: setupData.offset,
      sensorType: setupData.sensorType,
    });
    this.parentFormGroup.updateValueAndValidity();
  }

  uninstall(): void {
    this.delete.emit();
  }
}
