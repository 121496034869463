export enum HoursFormatTypeEnum {
  AmPm,
  HoursFormat24,
}

export namespace HoursFormatTypeEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case HoursFormatTypeEnum.AmPm:
        return 'Controller.DateTimeSettings.HoursFormatTypeEnum.AMPM';
      case HoursFormatTypeEnum.HoursFormat24:
        return 'Controller.DateTimeSettings.HoursFormatTypeEnum.24h';
      default:
        return value.toString();
    }
  }
}
