import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VirtualKeyboardModesEnum } from './virtual-keyboard-modes.enum';
import { CommonModule } from '@angular/common';
import { VirtualKeyboardConstants } from './virtual-keyboard.constants';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectVirtualKeyboardAMPMDisabled,
  selectVirtualKeyboardElementUUID,
  selectVirtualKeyboardLabelForRanges,
  selectVirtualKeyboardMode,
  selectVirtualKeyboardRanges,
  setVirtualKeyboardAMPM,
  VirtualKeyboardButtonsEnum,
} from '@livestock/ui';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { setVirtualKeyboardElementUuid, setVirtualKeyboardSymbol } from './+state/virtual-keyboard.actions';
import { IMinMax } from '@livestock/shared/interfaces';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { IconsEnum } from '@livestock/shared/enums';
import { AmPmEnum } from '../../../../../controllers/src/lib/enums/date-time/am-pm.enum';
import { FullKeyboardModesEnum } from './full-keyboard-modes.enum';

@Component({
  selector: 'lv-virtual-keyboard',
  templateUrl: 'virtual-keyboard.component.html',
  styleUrls: ['virtual-keyboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
  ],
})
export class VirtualKeyboardComponent implements OnInit, OnDestroy {
  @ViewChild('keyboard') keyboard: ElementRef;

  // subs
  sub$ = new Subscription();
  keyboardMode$: Observable<VirtualKeyboardModesEnum> = this.store.select(selectVirtualKeyboardMode);
  AMPMDisabled$: Observable<boolean> = this.store.select(selectVirtualKeyboardAMPMDisabled);
  labelForRanges$: Observable<string> = this.store.select(selectVirtualKeyboardLabelForRanges);

  //vars
  isActive: boolean;
  ranges: IMinMax;
  lettersToUppercase: boolean;
  fullKeyboardMode: FullKeyboardModesEnum = FullKeyboardModesEnum.Alphabetical;
  multiplePrintingTimeout: ReturnType<typeof setTimeout>;
  multiplePrintingInterval: ReturnType<typeof setInterval>;

  // constants
  VirtualKeyboardModesEnum = VirtualKeyboardModesEnum;
  VirtualKeyboardConstants = VirtualKeyboardConstants;
  VirtualKeyboardButtonsEnum = VirtualKeyboardButtonsEnum;
  FullKeyboardModesEnum = FullKeyboardModesEnum;
  AmPmEnum = AmPmEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  MULTIPLE_PRINTING_TIMEOUT = 700;
  MULTIPLE_PRINTING_INTERVAL = 100;

  /* to prevent input focusout if clicked on the keyboard component */
  @HostListener('mousedown', ['$event'])
  onClick(): boolean {
    return false;
  }

  @HostListener('touchstart', ['$event'])
  onTouch(): boolean {
    return false;
  }

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectVirtualKeyboardElementUUID).pipe(
        wasChanged(),
      ).subscribe((uuid: string) => {
        this.isActive = !!uuid;
      }),
    );

    this.sub$.add(
      this.store.select(selectVirtualKeyboardRanges).pipe(
        wasChanged(),
      ).subscribe((ranges: IMinMax) => {
        this.ranges = ranges;
      }),
    );
  }

  printSymbol(symbol: number | string): void {
    this.store.dispatch(setVirtualKeyboardSymbol({ symbol }));
  }

  printLetter(letter: string): void {
    const modifiedLetter = this.lettersToUppercase
      ? letter.toUpperCase()
      : letter;
    this.store.dispatch(setVirtualKeyboardSymbol({ symbol: modifiedLetter }));
  }

  fullKeyboardToNumericMode(): void {
    this.fullKeyboardMode = FullKeyboardModesEnum.NumericAndSymbols;
  }

  fullKeyboardToAlphabeticalMode(): void {
    this.fullKeyboardMode = FullKeyboardModesEnum.Alphabetical;
  }

  toggleLettersUppercase(): void {
    this.lettersToUppercase = !this.lettersToUppercase;
  }

  setAmPm(AMPM: AmPmEnum): void {
    this.store.dispatch(setVirtualKeyboardAMPM({ AMPM }));
  }

  hideKeyboard(): void {
    this.store.dispatch(setVirtualKeyboardElementUuid({ elementUuid: null }));
  }

  printMultipleSymbols(symbol: number | string): void {
    this.multiplePrintingTimeout = setTimeout(() => {
      this.multiplePrintingInterval = setInterval(() => {
        this.printSymbol(symbol);
      }, this.MULTIPLE_PRINTING_INTERVAL);
    }, this.MULTIPLE_PRINTING_TIMEOUT);
  }

  printMultipleLetters(letter: string): void {
    this.multiplePrintingTimeout = setTimeout(() => {
      this.multiplePrintingInterval = setInterval(() => {
        this.printLetter(letter);
      }, this.MULTIPLE_PRINTING_INTERVAL);
    }, this.MULTIPLE_PRINTING_TIMEOUT);
  }

  stopPrintingMultipleSymbols(symbol: number | string, isLetter: boolean = false): void {
    if (!this.multiplePrintingInterval) {
      if (isLetter) this.printLetter(symbol as string);
      if (!isLetter) this.printSymbol(symbol);
    }

    clearTimeout(this.multiplePrintingTimeout);
    clearInterval(this.multiplePrintingInterval);
    this.multiplePrintingTimeout = null;
    this.multiplePrintingInterval = null;
  }

  // TODO: implement in future
  changeLanguage(): void {
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
