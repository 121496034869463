import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  LoadingGalconComponent,
  SlimButtonComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';

import { QaTagsDirective } from '@livestock/shared/directives';
import { fadeInOut } from '@livestock/shared/animations';
import { ICloudConnectionRequest } from '@livestock/controllers/interfaces';
import { GlobalConstants } from '@livestock/shared/constants';
import { ButtonTypeEnum, IconsEnum } from '@livestock/shared/enums';
import { ITicketQrCodeInfo } from '../../interfaces/controller/ticket-qr-code-info.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    LoadingGalconComponent,
    QRCodeModule,
    QaTagsDirective,
    SvgIconComponent,
    SlimButtonComponent,
  ],
  selector: 'lv-controller-cloud-connection-form',
  templateUrl: './cloud-connection-form.component.html',
  styleUrls: ['./cloud-connection-form.component.scss'],
  animations: [fadeInOut],
})
export class CloudConnectionForm {

  @Input() ticketQrCodeInfo: ITicketQrCodeInfo;
  @Input() cloudConnectionRequest: ICloudConnectionRequest;
  @Input() ticketQrCodeError: boolean;
  @Input() isLoading: boolean;
  @Input() canDisconnect: boolean = false;

  @Output() onDisconnect: EventEmitter<void> = new EventEmitter<void>();
  @Output() getTicketQrCodeInfoFromDevice: EventEmitter<void> = new EventEmitter<void>();

  readonly QR_CODE_DIMENSION: number = 240;

  GlobalConstants = GlobalConstants;
  IconsEnum = IconsEnum;
  ButtonTypeEnum = ButtonTypeEnum;

  disconnect(): void {
    this.onDisconnect.emit();
  }

  tryAgain(): void {
    this.getTicketQrCodeInfoFromDevice.emit();
  }
}
