import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FormsState } from './forms.state';

export const FORMS_FEATURE_KEY = 'forms';

export const getFormsState =
  createFeatureSelector<FormsState>(FORMS_FEATURE_KEY);

export const selectIsFormEditing = createSelector(
  getFormsState,
  ({ isEditing }) => isEditing,
);
