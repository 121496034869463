import { INameId } from '../interfaces/id-name.interface';

export enum TimeZonesEnum {
  Dateline_Standard_Time = 103,
  UTC_Minus_11 = 104,
  Hawaiian_Standard_Time = 105,
  Alaskan_Standard_Time = 106,
  Pacific_Standard_Time_Mexico = 107,
  Pacific_Standard_Time = 108,
  US_Mountain_Standard_Time = 109,
  Mountain_Standard_Time_Mexico = 110,
  Mountain_Standard_Time = 111,
  Central_America_Standard_Time = 112,
  Central_Standard_Time = 113,
  Central_Standard_Time_Mexico = 114,
  Canada_Central_Standard_Time = 115,
  SA_Pacific_Standard_Time = 116,
  Eastern_Standard_Time = 117,
  US_Eastern_Standard_Time = 118,
  Venezuela_Standard_Time = 119,
  Paraguay_Standard_Time = 120,
  Atlantic_Standard_Time = 121,
  Central_Brazilian_Standard_Time = 122,
  SA_Western_Standard_Time = 123,
  Pacific_SA_Standard_Time = 124,
  Newfoundland_Standard_Time = 125,
  E_South_America_Standard_Time = 126,
  Argentina_Standard_Time = 127,
  SA_Eastern_Standard_Time = 128,
  Greenland_Standard_Time = 129,
  Montevideo_Standard_Time = 130,
  Bahia_Standard_Time = 131,
  UTC_Minus_02 = 132,
  Mid_Atlantic_Standard_Time = 133,
  Azores_Standard_Time = 134,
  Cape_Verde_Standard_Time = 135,
  Morocco_Standard_Time = 136,
  UTC = 137,
  GMT_Standard_Time = 138,
  Greenwich_Standard_Time = 139,
  W_Europe_Standard_Time = 140,
  Central_Europe_Standard_Time = 141,
  Romance_Standard_Time = 142,
  Central_European_Standard_Time = 143,
  W_Central_Africa_Standard_Time = 144,
  Namibia_Standard_Time = 145,
  Jordan_Standard_Time = 146,
  GTB_Standard_Time = 147,
  Middle_East_Standard_Time = 148,
  Egypt_Standard_Time = 149,
  Syria_Standard_Time = 150,
  South_Africa_Standard_Time = 151,
  FLE_Standard_Time = 152,
  Turkey_Standard_Time = 153,
  Israel_Standard_Time = 154,
  E_Europe_Standard_Time = 155,
  Arabic_Standard_Time = 156,
  Kaliningrad_Standard_Time = 157,
  Arab_Standard_Time = 158,
  E_Africa_Standard_Time = 159,
  Iran_Standard_Time = 160,
  Arabian_Standard_Time = 161,
  Azerbaijan_Standard_Time = 162,
  Russian_Standard_Time = 163,
  Mauritius_Standard_Time = 164,
  Georgian_Standard_Time = 165,
  Caucasus_Standard_Time = 166,
  Afghanistan_Standard_Time = 167,
  Pakistan_Standard_Time = 168,
  West_Asia_Standard_Time = 169,
  India_Standard_Time = 170,
  Sri_Lanka_Standard_Time = 171,
  Nepal_Standard_Time = 172,
  Central_Asia_Standard_Time = 173,
  Bangladesh_Standard_Time = 174,
  Ekaterinburg_Standard_Time = 175,
  Myanmar_Standard_Time = 176,
  SE_Asia_Standard_Time = 177,
  N_Central_Asia_Standard_Time = 178,
  China_Standard_Time = 179,
  North_Asia_Standard_Time = 180,
  Singapore_Standard_Time = 181,
  W_Australia_Standard_Time = 182,
  Taipei_Standard_Time = 183,
  Ulaanbaatar_Standard_Time = 184,
  North_Asia_East_Standard_Time = 185,
  Tokyo_Standard_Time = 186,
  Korea_Standard_Time = 187,
  Cen_Australia_Standard_Time = 188,
  AUS_Central_Standard_Time = 189,
  E_Australia_Standard_Time = 190,
  AUS_Eastern_Standard_Time = 191,
  West_Pacific_Standard_Time = 192,
  Tasmania_Standard_Time = 193,
  Yakutsk_Standard_Time = 194,
  Central_Pacific_Standard_Time = 195,
  Vladivostok_Standard_Time = 196,
  New_Zealand_Standard_Time = 197,
  UTC_Plus_12 = 198,
  Fiji_Standard_Time = 199,
  Magadan_Standard_Time = 200,
  Kamchatka_Standard_Time = 201,
  Tonga_Standard_Time = 202,
  Samoa_Standard_Time = 203,
  Mexico_Standard_Time_2 = 204,
  Mexico_Standard_Time = 205,
  Armenian_Standard_Time = 206
}

export namespace TimeZonesEnum {
  export function toTranslateKey(value: TimeZonesEnum): string {
    return timeZonesTranslations[value];
  }

  export function toList(): INameId[] {
    const items = Object.keys(TimeZonesEnum)
      .filter(key => !isNaN(Number(key)))
      .map(key => {
        return {
          id: +key,
          name: TimeZonesEnum.toTranslateKey(+key),
        };
      });

    return items;
  }

  export function fromTranslationKey(translation: string): TimeZonesEnum | null {
    const foundEntry = Object.entries(timeZonesTranslations).find(
      ([, value]) => value === translation,
    );

    return foundEntry ? Number(foundEntry[0]) as TimeZonesEnum : null;
  }
}

const timeZonesTranslations = {
  [TimeZonesEnum.Dateline_Standard_Time]: 'Dateline Standard Time',
  [TimeZonesEnum.UTC_Minus_11]: 'UTC-11',
  [TimeZonesEnum.Hawaiian_Standard_Time]: 'Hawaiian Standard Time',
  [TimeZonesEnum.Alaskan_Standard_Time]: 'Alaskan Standard Time',
  [TimeZonesEnum.Pacific_Standard_Time_Mexico]: 'Pacific Standard Time (Mexico)',
  [TimeZonesEnum.Pacific_Standard_Time]: 'Pacific Standard Time',
  [TimeZonesEnum.US_Mountain_Standard_Time]: 'US Mountain Standard Time',
  [TimeZonesEnum.Mountain_Standard_Time_Mexico]: 'Mountain Standard Time (Mexico)',
  [TimeZonesEnum.Mountain_Standard_Time]: 'Mountain Standard Time',
  [TimeZonesEnum.Central_America_Standard_Time]: 'Central America Standard Time',
  [TimeZonesEnum.Central_Standard_Time]: 'Central Standard Time',
  [TimeZonesEnum.Central_Standard_Time_Mexico]: 'Central Standard Time (Mexico)',
  [TimeZonesEnum.Canada_Central_Standard_Time]: 'Canada Central Standard Time',
  [TimeZonesEnum.SA_Pacific_Standard_Time]: 'SA Pacific Standard Time',
  [TimeZonesEnum.Eastern_Standard_Time]: 'Eastern Standard Time',
  [TimeZonesEnum.US_Eastern_Standard_Time]: 'US Eastern Standard Time',
  [TimeZonesEnum.Venezuela_Standard_Time]: 'Venezuela Standard Time',
  [TimeZonesEnum.Paraguay_Standard_Time]: 'Paraguay Standard Time',
  [TimeZonesEnum.Atlantic_Standard_Time]: 'Atlantic Standard Time',
  [TimeZonesEnum.Central_Brazilian_Standard_Time]: 'Central Brazilian Standard Time',
  [TimeZonesEnum.SA_Western_Standard_Time]: 'SA Western Standard Time',
  [TimeZonesEnum.Pacific_SA_Standard_Time]: 'Pacific SA Standard Time',
  [TimeZonesEnum.Newfoundland_Standard_Time]: 'Newfoundland Standard Time',
  [TimeZonesEnum.E_South_America_Standard_Time]: 'E. South America Standard Time',
  [TimeZonesEnum.Argentina_Standard_Time]: 'Argentina Standard Time',
  [TimeZonesEnum.SA_Eastern_Standard_Time]: 'SA Eastern Standard Time',
  [TimeZonesEnum.Greenland_Standard_Time]: 'Greenland Standard Time',
  [TimeZonesEnum.Montevideo_Standard_Time]: 'Montevideo Standard Time',
  [TimeZonesEnum.Bahia_Standard_Time]: 'Bahia Standard Time',
  [TimeZonesEnum.UTC_Minus_02]: 'UTC-02',
  [TimeZonesEnum.Mid_Atlantic_Standard_Time]: 'Mid-Atlantic Standard Time',
  [TimeZonesEnum.Azores_Standard_Time]: 'Azores Standard Time',
  [TimeZonesEnum.Cape_Verde_Standard_Time]: 'Cape Verde Standard Time',
  [TimeZonesEnum.Morocco_Standard_Time]: 'Morocco Standard Time',
  [TimeZonesEnum.UTC]: 'UTC',
  [TimeZonesEnum.GMT_Standard_Time]: 'GMT Standard Time',
  [TimeZonesEnum.Greenwich_Standard_Time]: 'Greenwich Standard Time',
  [TimeZonesEnum.W_Europe_Standard_Time]: 'W. Europe Standard Time',
  [TimeZonesEnum.Central_Europe_Standard_Time]: 'Central Europe Standard Time',
  [TimeZonesEnum.Romance_Standard_Time]: 'Romance Standard Time',
  [TimeZonesEnum.Central_European_Standard_Time]: 'Central European Standard Time',
  [TimeZonesEnum.W_Central_Africa_Standard_Time]: 'W. Central Africa Standard Time',
  [TimeZonesEnum.Namibia_Standard_Time]: 'Namibia Standard Time',
  [TimeZonesEnum.Jordan_Standard_Time]: 'Jordan Standard Time',
  [TimeZonesEnum.GTB_Standard_Time]: 'GTB Standard Time',
  [TimeZonesEnum.Middle_East_Standard_Time]: 'Middle East Standard Time',
  [TimeZonesEnum.Egypt_Standard_Time]: 'Egypt Standard Time',
  [TimeZonesEnum.Syria_Standard_Time]: 'Syria Standard Time',
  [TimeZonesEnum.South_Africa_Standard_Time]: 'South Africa Standard Time',
  [TimeZonesEnum.FLE_Standard_Time]: 'FLE Standard Time',
  [TimeZonesEnum.Turkey_Standard_Time]: 'Turkey Standard Time',
  [TimeZonesEnum.Israel_Standard_Time]: 'Israel Standard Time',
  [TimeZonesEnum.E_Europe_Standard_Time]: 'E. Europe Standard Time',
  [TimeZonesEnum.Arabic_Standard_Time]: 'Arabic Standard Time',
  [TimeZonesEnum.Kaliningrad_Standard_Time]: 'Kaliningrad Standard Time',
  [TimeZonesEnum.Arab_Standard_Time]: 'Arab Standard Time',
  [TimeZonesEnum.E_Africa_Standard_Time]: 'E. Africa Standard Time',
  [TimeZonesEnum.Iran_Standard_Time]: 'Iran Standard Time',
  [TimeZonesEnum.Arabian_Standard_Time]: 'Arabian Standard Time',
  [TimeZonesEnum.Azerbaijan_Standard_Time]: 'Azerbaijan Standard Time',
  [TimeZonesEnum.Russian_Standard_Time]: 'Russian Standard Time',
  [TimeZonesEnum.Mauritius_Standard_Time]: 'Mauritius Standard Time',
  [TimeZonesEnum.Georgian_Standard_Time]: 'Georgian Standard Time',
  [TimeZonesEnum.Caucasus_Standard_Time]: 'Caucasus Standard Time',
  [TimeZonesEnum.Afghanistan_Standard_Time]: 'Afghanistan Standard Time',
  [TimeZonesEnum.Pakistan_Standard_Time]: 'Pakistan Standard Time',
  [TimeZonesEnum.West_Asia_Standard_Time]: 'West Asia Standard Time',
  [TimeZonesEnum.India_Standard_Time]: 'India Standard Time',
  [TimeZonesEnum.Sri_Lanka_Standard_Time]: 'Sri Lanka Standard Time',
  [TimeZonesEnum.Nepal_Standard_Time]: 'Nepal Standard Time',
  [TimeZonesEnum.Central_Asia_Standard_Time]: 'Central Asia Standard Time',
  [TimeZonesEnum.Bangladesh_Standard_Time]: 'Bangladesh Standard Time',
  [TimeZonesEnum.Ekaterinburg_Standard_Time]: 'Ekaterinburg Standard Time',
  [TimeZonesEnum.Myanmar_Standard_Time]: 'Myanmar Standard Time',
  [TimeZonesEnum.SE_Asia_Standard_Time]: 'SE Asia Standard Time',
  [TimeZonesEnum.N_Central_Asia_Standard_Time]: 'N. Central Asia Standard Time',
  [TimeZonesEnum.China_Standard_Time]: 'China Standard Time',
  [TimeZonesEnum.North_Asia_Standard_Time]: 'North Asia Standard Time',
  [TimeZonesEnum.Singapore_Standard_Time]: 'Singapore Standard Time',
  [TimeZonesEnum.W_Australia_Standard_Time]: 'W. Australia Standard Time',
  [TimeZonesEnum.Taipei_Standard_Time]: 'Taipei Standard Time',
  [TimeZonesEnum.Ulaanbaatar_Standard_Time]: 'Ulaanbaatar Standard Time',
  [TimeZonesEnum.North_Asia_East_Standard_Time]: 'North Asia East Standard Time',
  [TimeZonesEnum.Tokyo_Standard_Time]: 'Tokyo Standard Time',
  [TimeZonesEnum.Korea_Standard_Time]: 'Korea Standard Time',
  [TimeZonesEnum.Cen_Australia_Standard_Time]: 'Cen. Australia Standard Time',
  [TimeZonesEnum.AUS_Central_Standard_Time]: 'AUS Central Standard Time',
  [TimeZonesEnum.E_Australia_Standard_Time]: 'E. Australia Standard Time',
  [TimeZonesEnum.AUS_Eastern_Standard_Time]: 'AUS Eastern Standard Time',
  [TimeZonesEnum.West_Pacific_Standard_Time]: 'West Pacific Standard Time',
  [TimeZonesEnum.Tasmania_Standard_Time]: 'Tasmania Standard Time',
  [TimeZonesEnum.Yakutsk_Standard_Time]: 'Yakutsk Standard Time',
  [TimeZonesEnum.Central_Pacific_Standard_Time]: 'Central Pacific Standard Time',
  [TimeZonesEnum.Vladivostok_Standard_Time]: 'Vladivostok Standard Time',
  [TimeZonesEnum.New_Zealand_Standard_Time]: 'New Zealand Standard Time',
  [TimeZonesEnum.UTC_Plus_12]: 'UTC+12',
  [TimeZonesEnum.Fiji_Standard_Time]: 'Fiji Standard Time',
  [TimeZonesEnum.Magadan_Standard_Time]: 'Magadan Standard Time',
  [TimeZonesEnum.Kamchatka_Standard_Time]: 'Kamchatka Standard Time',
  [TimeZonesEnum.Tonga_Standard_Time]: 'Tonga Standard Time',
  [TimeZonesEnum.Samoa_Standard_Time]: 'Samoa Standard Time',
  [TimeZonesEnum.Mexico_Standard_Time_2]: 'Mexico Standard Time 2',
  [TimeZonesEnum.Mexico_Standard_Time]: 'Mexico Standard Time',
  [TimeZonesEnum.Armenian_Standard_Time]: 'Armenian Standard Time',
};
