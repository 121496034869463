import { FarmUserRolesEnum } from '@livestock/shared/enums';

export interface IFarm {
  farmID: number;
  name: string;
  address: string;
  existingHouseNumbers: number[];
  ownerUserID?: number;
  roleType?: FarmUserRolesEnum;
  controllersCount?: number;
  usersCount?: number;
}
