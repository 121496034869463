export enum IconsConnectionEnum {
  CELL_ERROR = 'connection/cell-error',
  CELL_FULL = 'connection/cell-full',
  CELL_HIGH = 'connection/cell-high',
  CELL_MID = 'connection/cell-mid',
  CELL_LOW = 'connection/cell-low',
  CHECK = 'connection/check',
  CONTROLLER = 'connection/controller',
  CROSS = 'connection/cross',
  HAS_NETWORK = 'connection/has-network',
  INTERNET_CONNECTED = 'connection/internet-connected',
  FAILED_CONNECTION = 'connection/failed-connection',
  INTERNET_ERROR = 'connection/internet-error',
  LAN_CONNECTED = 'connection/lan-connected',
  LAN_ERROR = 'connection/lan-error',
  NO_INTERNET = 'connection/no-internet',
  NO_NETWORK = 'connection/no-network',
  WIFI_ERROR = 'connection/wifi-error',
  WIFI_HIGH = 'connection/wifi-high',
  WIFI_MID = 'connection/wifi-mid',
  WIFI_LOW = 'connection/wifi-low',
  WIFI_RESTRICTED = 'connection/wifi-restricted',
  WIFI_LOWEST = 'connection/wifi-lowest',
}
