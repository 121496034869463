import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';
import { NativeElementInjectorDirective } from '../native-element.directive';
import { SvgIconComponent } from '@livestock/ui';
import { ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    QaTagsDirective,
    NativeElementInjectorDirective,
    SvgIconComponent,
  ],
  selector: 'ls-integer-incr-decr',
  templateUrl: 'integer-incr-decr.component.html',
  styleUrls: ['integer-incr-decr.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntegerIncrDecrComponent),
      multi: true,
    },
  ],
})

export class IntegerIncrDecrComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() min = 0;
  @Input() max = 100;
  value: number;

  IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;

  increment(): void {
    if (this.value === this.max) return;

    this.value += 1;
    this.valueChange(this.value);
  }

  decrement(): void {
    if (this.value === this.min) return;

    this.value -= 1;
    this.valueChange(this.value);
  }

  valueChange(value?): void {
    this.value = value;
    this.onChange(this.value);
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(
    onChange: (value: number | string) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  private onTouched = (): void => {
  };
  private onChange: (
    value: number | string,
  ) => void = () => {
  };
}

