import { Action, createReducer, on } from '@ngrx/store';
import * as currentUserActions from './current-user.actions';
import { CurrentUserState } from './current-user.state';

export const CURRENT_USER_FEATURE_KEY = 'currentUser';

export const initialState: CurrentUserState = {
  isLoading: false,
  currentUser: null,
  userMenuIsActive: false,
};

const reducer = createReducer(
  initialState,
  on(
    currentUserActions.getCurrentUser,
    currentUserActions.updateCurrentUser,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    currentUserActions.getCurrentUserError,
    currentUserActions.updateCurrentUserError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(currentUserActions.getCurrentUserSuccess, (state, { currentUser }) => {
    return {
      ...state,
      currentUser,
      isLoading: false,
    };
  }),
  on(currentUserActions.updateCurrentUserSuccess, (state, { currentUser }) => {
    return {
      ...state,
      currentUser,
      isLoading: false,
    };
  }),
  on(currentUserActions.toggleUserMenu, (state, { userMenuIsActive }) => {
    return {
      ...state,
      userMenuIsActive,
    };
  }),
);

export function currentUserReducer(state: CurrentUserState | undefined, action: Action): any {
  return reducer(state, action);
}
