export enum VentilationWorkingModeEnum {
  Basic = 0,
  Advance = 1,
}

export namespace VentilationWorkingModeEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case VentilationWorkingModeEnum.Basic:
        return 'Flock.VentilationWorkingMode.Basic';
      case VentilationWorkingModeEnum.Advance:
        return 'Flock.VentilationWorkingMode.Advanced';
      default:
        return value.toString();
    }
    ;
  }
}
