import { Action, createReducer, on } from '@ngrx/store';

import * as formsActions from './forms.actions';
import { FormsState } from './forms.state';

export const initialState: FormsState = {
  isEditing: false,
};

const reducer = createReducer(
  initialState,
  on(
    formsActions.setIsFormEditing,
    (state, { isEditing }) => {
      return {
        ...state,
        isEditing,
      };
    },
  ),
);

export function formsReducer(state: FormsState | undefined, action: Action): any {
  return reducer(state, action);
}
