<div class="d-flex w-100-percent align-center justify-between ph-18">
  <div class="d-flex flex-row align-center gap-28">
    <div class="logo">
      <img src="/assets/images/plascon-logo.png" />
    </div>

    <div
      (click)="toggleMenuIsOpened()"
      [class.menu-icon__active]="controllerMenuIsOpened$ | async"
      [qaTags]="'qa-menu-controllerTop'"
      class="menu-container__menu-circle pointer">
      {{ "ControllerMenu.Menu" | translate }}
    </div>
  </div>

  <div class="d-flex align-center justify-center">
    <div class="d-flex flex-column align-center justify-center house-number">
      <ls-svg-icon
        [path]="IconsEnum.FARM"
        class="mb-8">
      </ls-svg-icon>

      <span>3</span>
    </div>

    <div class="mh-18 d-flex justify-center align-center notifications">
      <div class="d-flex justify-center align-center">
        <span>28</span>
      </div>
      <ls-svg-icon [path]="IconsEnum.BELL"> </ls-svg-icon>
    </div>

    <div class="d-flex flex-column justify-center mr-8 pb-4 day-number">
      <span class="day-number__title">{{ "DeviceHeader.Day" | translate }}</span>

      <span class="day-number__value">24</span>
    </div>

    <div class="d-flex flex-column justify-center full-house">
      <span class="full-house__title">{{ "DeviceHeader.FullHouse" | translate }}</span>

      <span class="full-house__value">{{ "DeviceHeader.Growing" | translate }}</span>
    </div>
  </div>

  <div class="d-flex align-center justify-end">
    <div class="d-flex flex-column justify-center aver-temp">
      <span class="aver-temp__title mb-2">{{ "DeviceHeader.AverageTemp" | translate }}</span>

      <div class="d-flex align-end">
        <span class="aver-temp__value">24.8</span><span class="aver-temp__unit">°C</span>
      </div>
    </div>

    <div class="d-flex flex-column justify-center ml-18 target">
      <span class="target__title mb-4">{{ "DeviceHeader.Target" | translate }}</span>

      <span class="target__value">25.5</span>
    </div>

    <div
      [qaTags]="'qa-lbl-cloudConnect'"
      class="d-flex align-center justify-center mh-18 controller-online">
      <ls-svg-icon [path]="IconsEnum.CLOUD_CONNECT"> </ls-svg-icon>
    </div>

    <div
      [qaTags]="'qa-lbl-clocks'"
      class="d-flex flex-column justify-center date-time">
      <span class="date-time__date mb-4"> March 5, 24 </span>

      <span class="date-time__time"> 20:36 </span>
    </div>
  </div>
</div>

<lv-controller-menu
  *ngIf="controllerMenuIsOpened$ | async"
  [@fadeInOutFast]
  class="ph-18"></lv-controller-menu>
