import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MissingTranslationService, HttpTranslateLoaderFactory, PlatformService } from '@livestock/shared/services';
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  UpsertControllerEffects,
  upsertControllerReducer,
  UPSERT_CONTROLLER_FEATURE_KEY,
  currentControllerReducer,
  CURRENT_CONTROLLER_FEATURE_KEY,
  ControllerInfoEffects,
  SettingsEffects, EditModeEffects,
} from '@livestock/controllers';
import { VIRTUAL_KEYBOARD_KEY, virtualKeyboardReducer } from '@livestock/ui';
import { AuthHeaderInterceptor } from '@livestock/auth';
import { HttpErrorsInterceptor, SvgCacheInterceptor } from '@livestock/shared/interceptors';
import { FLASH_MESSAGE_FEATURE_KEY, flashMessageReducer } from '@livestock/notifications';
import { DISPLAY_FEATURE_KEY, StateSyncService, displayReducer } from '@livestock/appdisplay';
import { CONTROLLER_MENU_KEY, controllerMenuReducer } from '@livestock/menu';
import { FORMS_FEATURE_KEY, formsReducer } from '@livestock/forms';
import { WEB_SOCKETS_FEATURE_KEY, webSocketsReducer } from '@livestock/web-sockets';
import {
  INSTALLATION_FEATURE_KEY,
  installationReducer,
  InstallationEffects,
} from '@livestock/installation';
import { FLOCK_FEATURE_KEY, flockReducer } from '@livestock/flock';

function setAppValues(service: PlatformService) {
  return () => {
    const isMobileApp = false;
    const isDeviceApp = true;
    const isDesktopApp = false;
    return service.setAppValues(isMobileApp, isDeviceApp, isDesktopApp);
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideEffects(),
    provideStore(),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),

    provideState(UPSERT_CONTROLLER_FEATURE_KEY, upsertControllerReducer),
    provideState(CURRENT_CONTROLLER_FEATURE_KEY, currentControllerReducer),
    provideState(VIRTUAL_KEYBOARD_KEY, virtualKeyboardReducer),
    provideState(FORMS_FEATURE_KEY, formsReducer),
    provideState(WEB_SOCKETS_FEATURE_KEY, webSocketsReducer),
    provideState(CONTROLLER_MENU_KEY, controllerMenuReducer),
    provideState(INSTALLATION_FEATURE_KEY, installationReducer),
    provideState(FLOCK_FEATURE_KEY, flockReducer),

    //remove old keyboard in future
    provideState(FLASH_MESSAGE_FEATURE_KEY, flashMessageReducer),

    provideEffects(UpsertControllerEffects),
    provideEffects(ControllerInfoEffects),
    provideEffects(SettingsEffects),
    provideEffects(EditModeEffects),
    provideEffects(InstallationEffects),

    provideState(DISPLAY_FEATURE_KEY, displayReducer),

    provideStoreDevtools(),
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: setAppValues,
      deps: [PlatformService, StateSyncService],
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SvgCacheInterceptor,
      multi: true,
    },
    importProvidersFrom(
      HttpClientModule,
      MatDialogModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpTranslateLoaderFactory,
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MissingTranslationService,
        },
        useDefaultLang: false,
      }),
    ),
  ],
};
