<div
  [class.disabled]="disabled"
  class="ls-input position-relative">
  <div class="input-wrapper">
    <div
      [class.rtl]="languageService.isRtl"
      [ngClass]="{
        'justify-center gap-11': optionsTitlePosition === 'both',
        'gap-8': optionsTitlePosition === 'before'
      }"
      class="dual-toggle d-flex flex-row h-100-percent align-center user-select-none m-auto toggle-size-{{ size }}">
      <span
        *ngIf="optionsTitlePosition === 'before'"
        (click)="toggleOption()"
        [style.min-width]="MIN_OPTION_WIDTH"
        class="option fw-500 option-position-before {{ isChecked ? secondOption?.cssClass : firstOption?.cssClass }}">
        {{ isChecked | memoizeFunc : getSelectedOptionTitle.bind(this) | translate }}
      </span>

      <span
        *ngIf="firstOption?.title && optionsTitlePosition === 'both'"
        (click)="toggleOption()"
        [ngClass]="{ checked: !isChecked }"
        class="option fw-400 {{ firstOption?.cssClass }}">
        {{ firstOption.title | translate }}
      </span>

      <ng-content select="[firstOption]"></ng-content>

      <label
        (click)="toggleOption()"
        class="{{ isChecked | memoizeFunc : getTheme.bind(this) }}"
        [ngClass]="{ checked: isChecked, disabled: disabled }">
        <span
          [class.p-6]="!firstOption.toggleIcon && !secondOption.toggleIcon"
          class="toggle">
          <ls-svg-icon
            *ngIf="(isChecked | memoizeFunc : getIconPath.bind(this)) != null"
            [fullHeight]="true"
            [path]="isChecked | memoizeFunc : getIconPath.bind(this)"
            [color]="getIconColorByTheme(isChecked | memoizeFunc : getTheme.bind(this))" />
        </span>
      </label>

      <span
        *ngIf="optionsTitlePosition === 'after'"
        (click)="toggleOption()"
        [style.min-width]="MIN_OPTION_WIDTH"
        class="option fw-500 option-position-after ml-10">
        {{ isChecked | memoizeFunc : getSelectedOptionTitle.bind(this) | translate }}
      </span>

      <span
        *ngIf="secondOption?.title && optionsTitlePosition === 'both'"
        (click)="toggleOption()"
        [ngClass]="{ checked: isChecked }"
        class="option fw-400 {{ secondOption?.cssClass }}">
        {{ secondOption.title | translate }}
      </span>

      <ng-content select="[secondOption]"></ng-content>
    </div>

    <label class="input-label input-label_filled">
      {{ label | translate }}
    </label>
  </div>
</div>
