import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCollection',
  standalone: true,
})
export class FilterCollectionPipe implements PipeTransform {
  transform(
    items: any[],
    searchValue: string | any,
    prop = 'name',
    strict = false,
  ): any[] {
    if (searchValue) {
      return items.filter((x) =>
        strict
          ? x[prop] === searchValue
          : x[prop].toLowerCase().includes(searchValue.toLowerCase().trim()),
      );
    }
    return items;
  }
}
