import {
  ControllerUnitEnum,
  VentilationWorkingModeEnum,
} from '@livestock/controllers/enums';
import { ChickenBrandWeight, WeightUnitEnum } from '@livestock/shared/enums';
import { IFlockDefaultWeightView, StagingEnum, WeightMethodEnum, HouseModeEnum } from '@livestock/flock/models';

export class FlockConstants {
  static MinTargetTemperature = -40;
  static MaxTargetTemperatureCelsius = 90;
  static MaxTargetTemperatureFahrenheit = 194;
  // TODO: fix to correct values after they are defined.
  static MaxVentilationBasic = 10;
  static MaxVentilationAdvance = 10;

  static MaxWeightKg = 30;
  static MaxWeightGram = 30000;
  static MaxWeightPound = 66.08;

  static MinCurveOffsetKg = -5;
  static MinCurveOffsetGram = -5000;
  static MinCurveOffsetPound = -11.01;
  static MaxCurveOffsetKg = 5;
  static MaxCurveOffsetGram = 5000;
  static MaxCurveOffsetPound = 11.01;

  static MinCurveOffsetRaw = -15;
  static MaxCurveOffsetRaw = 15;

  static MinBirdWeightDay = 0;
  static MaxBirdWeightDay = 99;

  static GrowthDayMin = -4;
  static GrowthDayMax = 99;

  static FlockNumberMin = 1;
  static FlockNumberMax = 999;

  static InitialNumberOfBirdsMin = 0;
  static InitialNumberOfBirdsMax = 200000;

  static FemaleInitialNumberOfBirdsMin = 0;
  static FemaleInitialNumberOfBirdsMax = 100000;

  static MaleInitialNumberOfBirdsMin = 0;
  static MaleInitialNumberOfBirdsMax = 100000;

  static CurveOffsetMin = -15;
  static CurveOffsetMax = 15;

  static FirstDay = 0;
  static LastDay = 99;

  static WeightMin = 0.000;
  static WeightMaxKg = 10.000;
  static WeightMaxPound = 22.046;

  static MinVentilation = 0;
  static MaxVentilation = 100;
  static MinLight = 0;
  static MaxLight = 100;

  static getMaxWeightByUnit(weightUnit: WeightUnitEnum): number {
    switch (weightUnit) {
      case WeightUnitEnum.Kilogram:
        return FlockConstants.MaxWeightKg;
      case WeightUnitEnum.Pound:
        return FlockConstants.MaxWeightPound;
      default:
        return FlockConstants.MaxWeightGram;
    }
  }

  static getMinCurveOffsetByUnit(weightUnit: WeightUnitEnum): number {
    switch (weightUnit) {
      case WeightUnitEnum.Kilogram:
        return FlockConstants.MinCurveOffsetKg;
      case WeightUnitEnum.Pound:
        return FlockConstants.MinCurveOffsetGram;
      default:
        return FlockConstants.MinCurveOffsetPound;
    }
  }

  static getMaxCurveOffsetByUnit(weightUnit: WeightUnitEnum): number {
    switch (weightUnit) {
      case WeightUnitEnum.Kilogram:
        return FlockConstants.MaxCurveOffsetKg;
      case WeightUnitEnum.Pound:
        return FlockConstants.MaxCurveOffsetGram;
      default:
        return FlockConstants.MaxCurveOffsetPound;
    }
  }

  static NewFlockState = {
    settings: {
      growthDay: 1,
      flockNumber: 1,
      separateMaleAndFemale: false,
      initialNumberOfBirds: 0,
      femaleInitialNumberOfBirds: 0,
      maleInitialNumberOfBirds: 0,
      houseMode: HouseModeEnum.Growing,
      ventilationWorkingMode: VentilationWorkingModeEnum.Basic,
      staging: StagingEnum.FullHouse,
      units: ControllerUnitEnum.Metric,
      unitsDetails: null,
      timeNextStage: 0,
      creationDate: new Date().toISOString(),
      availableHouseModes: [],
      defaultWeightBrandID: ChickenBrandWeight.COBB_500,
    },
    houseMode: {
      targetTemperatureEmpty: 0,
      targetTemperaturePreheat: 0,
      targetTemperatureCleaning: 0,
      targetTemperatureCatching: 0,
      enableTemperatureAlarmEmpty: false,
      enableTemperatureAlarmPreheat: false,
      enableTemperatureAlarmCleaning: false,
      enableTemperatureAlarmCatching: false,
      maximumVentilationEmpty: 0,
      maximumVentilationPreheat: 0,
      maximumVentilationCleaning: 0,
      maximumVentilationCatching: 0,
      minimumVentilationEmpty: 0,
      minimumVentilationPreheat: 0,
      minimumVentilationCleaning: 0,
      minimumVentilationCatching: 0,
    },
    birdWeightSettings: {
      weightMethod: WeightMethodEnum.Predefined,
      curveOffset: 0,
      defaultWeightBrandID: ChickenBrandWeight.COBB_500,
    },
  };
}

export const DEFAULT_COBB_500: IFlockDefaultWeightView[] = [
  { day: 0, weight: 0.042, femaleWeight: 0.042, maleWeight: 0.042 },
  { day: 1, weight: 0.054, femaleWeight: 0.056, maleWeight: 0.055 },
  { day: 2, weight: 0.070, femaleWeight: 0.072, maleWeight: 0.071 },
  { day: 3, weight: 0.088, femaleWeight: 0.092, maleWeight: 0.090 },
  { day: 4, weight: 0.110, femaleWeight: 0.114, maleWeight: 0.112 },
  { day: 5, weight: 0.135, femaleWeight: 0.141, maleWeight: 0.138 },
  { day: 6, weight: 0.165, femaleWeight: 0.171, maleWeight: 0.168 },
  { day: 7, weight: 0.199, femaleWeight: 0.205, maleWeight: 0.202 },
  { day: 8, weight: 0.236, femaleWeight: 0.244, maleWeight: 0.240 },
  { day: 9, weight: 0.276, femaleWeight: 0.289, maleWeight: 0.283 },
  { day: 10, weight: 0.320, femaleWeight: 0.339, maleWeight: 0.330 },
  { day: 11, weight: 0.369, femaleWeight: 0.395, maleWeight: 0.382 },
  { day: 12, weight: 0.421, femaleWeight: 0.457, maleWeight: 0.440 },
  { day: 13, weight: 0.478, femaleWeight: 0.525, maleWeight: 0.503 },
  { day: 14, weight: 0.537, femaleWeight: 0.603, maleWeight: 0.570 },
  { day: 15, weight: 0.601, femaleWeight: 0.677, maleWeight: 0.639 },
  { day: 16, weight: 0.667, femaleWeight: 0.754, maleWeight: 0.711 },
  { day: 17, weight: 0.737, femaleWeight: 0.834, maleWeight: 0.786 },
  { day: 18, weight: 0.810, femaleWeight: 0.918, maleWeight: 0.864 },
  { day: 19, weight: 0.885, femaleWeight: 1.005, maleWeight: 0.945 },
  { day: 20, weight: 0.963, femaleWeight: 1.095, maleWeight: 1.029 },
  { day: 21, weight: 1.043, femaleWeight: 1.188, maleWeight: 1.116 },
  { day: 22, weight: 1.126, femaleWeight: 1.284, maleWeight: 1.205 },
  { day: 23, weight: 1.210, femaleWeight: 1.382, maleWeight: 1.296 },
  { day: 24, weight: 1.297, femaleWeight: 1.482, maleWeight: 1.390 },
  { day: 25, weight: 1.386, femaleWeight: 1.585, maleWeight: 1.486 },
  { day: 26, weight: 1.477, femaleWeight: 1.690, maleWeight: 1.533 },
  { day: 27, weight: 1.569, femaleWeight: 1.796, maleWeight: 1.569 },
  { day: 28, weight: 1.662, femaleWeight: 1.904, maleWeight: 1.662 },
  { day: 29, weight: 1.757, femaleWeight: 2.014, maleWeight: 1.757 },
  { day: 30, weight: 1.853, femaleWeight: 2.125, maleWeight: 1.853 },
  { day: 31, weight: 1.951, femaleWeight: 2.237, maleWeight: 1.951 },
  { day: 32, weight: 2.049, femaleWeight: 2.350, maleWeight: 2.049 },
  { day: 33, weight: 2.148, femaleWeight: 2.464, maleWeight: 2.148 },
  { day: 34, weight: 2.248, femaleWeight: 2.579, maleWeight: 2.248 },
  { day: 35, weight: 2.348, femaleWeight: 2.694, maleWeight: 2.348 },
  { day: 36, weight: 2.448, femaleWeight: 2.810, maleWeight: 2.448 },
  { day: 37, weight: 2.549, femaleWeight: 2.926, maleWeight: 2.549 },
  { day: 38, weight: 2.650, femaleWeight: 3.042, maleWeight: 2.650 },
  { day: 39, weight: 2.751, femaleWeight: 3.158, maleWeight: 2.751 },
  { day: 40, weight: 2.852, femaleWeight: 3.274, maleWeight: 2.852 },
  { day: 41, weight: 2.952, femaleWeight: 3.389, maleWeight: 2.952 },
  { day: 42, weight: 3.052, femaleWeight: 3.503, maleWeight: 3.052 },
  { day: 43, weight: 3.151, femaleWeight: 3.617, maleWeight: 3.151 },
  { day: 44, weight: 3.250, femaleWeight: 3.730, maleWeight: 3.250 },
  { day: 45, weight: 3.348, femaleWeight: 3.842, maleWeight: 3.348 },
  { day: 46, weight: 3.445, femaleWeight: 3.952, maleWeight: 3.445 },
  { day: 47, weight: 3.540, femaleWeight: 4.062, maleWeight: 3.540 },
  { day: 48, weight: 3.635, femaleWeight: 4.169, maleWeight: 3.635 },
  { day: 49, weight: 3.728, femaleWeight: 4.275, maleWeight: 3.728 },
  { day: 50, weight: 3.819, femaleWeight: 4.379, maleWeight: 3.819 },
  { day: 51, weight: 3.909, femaleWeight: 4.481, maleWeight: 3.909 },
  { day: 52, weight: 3.997, femaleWeight: 4.580, maleWeight: 3.997 },
  { day: 53, weight: 4.083, femaleWeight: 4.677, maleWeight: 4.083 },
  { day: 54, weight: 4.167, femaleWeight: 4.772, maleWeight: 4.167 },
  { day: 55, weight: 4.249, femaleWeight: 4.864, maleWeight: 4.249 },
  { day: 56, weight: 4.329, femaleWeight: 4.953, maleWeight: 4.329 },
];

//mockup
export const DEFAULT_COBB_500_METRIC: IFlockDefaultWeightView[] = [
  { day: 0, weight: 0.021, femaleWeight: 0.021, maleWeight: 0.021 },
  { day: 1, weight: 0.022, femaleWeight: 0.022, maleWeight: 0.022 },
  { day: 2, weight: 0.022, femaleWeight: 0.022, maleWeight: 0.022 },
  { day: 3, weight: 0.022, femaleWeight: 0.022, maleWeight: 0.022 },
  { day: 4, weight: 0.022, femaleWeight: 0.022, maleWeight: 0.022 },
  { day: 5, weight: 0.022, femaleWeight: 0.022, maleWeight: 0.022 },
  { day: 6, weight: 0.022, femaleWeight: 0.022, maleWeight: 0.022 },
  { day: 7, weight: 0.199, femaleWeight: 0.205, maleWeight: 0.202 },
  { day: 8, weight: 0.236, femaleWeight: 0.244, maleWeight: 0.240 },
  { day: 9, weight: 0.276, femaleWeight: 0.289, maleWeight: 0.283 },
  { day: 10, weight: 0.320, femaleWeight: 0.339, maleWeight: 0.330 },
  { day: 11, weight: 0.369, femaleWeight: 0.395, maleWeight: 0.382 },
  { day: 12, weight: 0.421, femaleWeight: 0.457, maleWeight: 0.440 },
  { day: 13, weight: 0.478, femaleWeight: 0.525, maleWeight: 0.503 },
  { day: 14, weight: 0.537, femaleWeight: 0.603, maleWeight: 0.570 },
  { day: 15, weight: 0.601, femaleWeight: 0.677, maleWeight: 0.639 },
  { day: 16, weight: 0.667, femaleWeight: 0.754, maleWeight: 0.711 },
  { day: 17, weight: 0.737, femaleWeight: 0.834, maleWeight: 0.786 },
  { day: 18, weight: 0.810, femaleWeight: 0.918, maleWeight: 0.864 },
  { day: 19, weight: 0.885, femaleWeight: 1.005, maleWeight: 0.945 },
  { day: 20, weight: 0.963, femaleWeight: 1.095, maleWeight: 1.029 },
  { day: 21, weight: 1.043, femaleWeight: 1.188, maleWeight: 1.116 },
  { day: 22, weight: 1.126, femaleWeight: 1.284, maleWeight: 1.205 },
  { day: 23, weight: 1.210, femaleWeight: 1.382, maleWeight: 1.296 },
  { day: 24, weight: 1.297, femaleWeight: 1.482, maleWeight: 1.390 },
  { day: 25, weight: 1.386, femaleWeight: 1.585, maleWeight: 1.486 },
  { day: 26, weight: 1.477, femaleWeight: 1.690, maleWeight: 1.533 },
  { day: 27, weight: 1.569, femaleWeight: 1.796, maleWeight: 1.569 },
  { day: 28, weight: 1.662, femaleWeight: 1.904, maleWeight: 1.662 },
  { day: 29, weight: 1.757, femaleWeight: 2.014, maleWeight: 1.757 },
  { day: 30, weight: 1.853, femaleWeight: 2.125, maleWeight: 1.853 },
  { day: 31, weight: 1.951, femaleWeight: 2.237, maleWeight: 1.951 },
  { day: 32, weight: 2.049, femaleWeight: 2.350, maleWeight: 2.049 },
  { day: 33, weight: 2.148, femaleWeight: 2.464, maleWeight: 2.148 },
  { day: 34, weight: 2.248, femaleWeight: 2.579, maleWeight: 2.248 },
  { day: 35, weight: 2.348, femaleWeight: 2.694, maleWeight: 2.348 },
  { day: 36, weight: 2.448, femaleWeight: 2.810, maleWeight: 2.448 },
  { day: 37, weight: 2.549, femaleWeight: 2.926, maleWeight: 2.549 },
  { day: 38, weight: 2.650, femaleWeight: 3.042, maleWeight: 2.650 },
  { day: 39, weight: 2.751, femaleWeight: 3.158, maleWeight: 2.751 },
  { day: 40, weight: 2.852, femaleWeight: 3.274, maleWeight: 2.852 },
  { day: 41, weight: 2.952, femaleWeight: 3.389, maleWeight: 2.952 },
  { day: 42, weight: 3.052, femaleWeight: 3.503, maleWeight: 3.052 },
  { day: 43, weight: 3.151, femaleWeight: 3.617, maleWeight: 3.151 },
  { day: 44, weight: 3.250, femaleWeight: 3.730, maleWeight: 3.250 },
  { day: 45, weight: 3.348, femaleWeight: 3.842, maleWeight: 3.348 },
  { day: 46, weight: 3.445, femaleWeight: 3.952, maleWeight: 3.445 },
  { day: 47, weight: 3.540, femaleWeight: 4.062, maleWeight: 3.540 },
  { day: 48, weight: 3.635, femaleWeight: 4.169, maleWeight: 3.635 },
  { day: 49, weight: 3.728, femaleWeight: 4.275, maleWeight: 3.728 },
  { day: 50, weight: 3.819, femaleWeight: 4.379, maleWeight: 3.819 },
  { day: 51, weight: 3.909, femaleWeight: 4.481, maleWeight: 3.909 },
  { day: 52, weight: 3.997, femaleWeight: 4.580, maleWeight: 3.997 },
  { day: 53, weight: 4.083, femaleWeight: 4.677, maleWeight: 4.083 },
  { day: 54, weight: 4.167, femaleWeight: 4.772, maleWeight: 4.167 },
  { day: 55, weight: 4.249, femaleWeight: 4.864, maleWeight: 4.249 },
  { day: 56, weight: 4.329, femaleWeight: 4.953, maleWeight: 4.329 },
];

export const DEFAULT_ROSS_308: IFlockDefaultWeightView[] = [
  { day: 0, weight: 0.044, femaleWeight: 0.044, maleWeight: 0.044 },
  { day: 1, weight: 0.063, femaleWeight: 0.063, maleWeight: 0.062 },
  { day: 2, weight: 0.081, femaleWeight: 0.081, maleWeight: 0.080 },
  { day: 3, weight: 0.103, femaleWeight: 0.103, maleWeight: 0.101 },
  { day: 4, weight: 0.126, femaleWeight: 0.126, maleWeight: 0.124 },
  { day: 5, weight: 0.152, femaleWeight: 0.152, maleWeight: 0.150 },
  { day: 6, weight: 0.182, femaleWeight: 0.182, maleWeight: 0.180 },
  { day: 7, weight: 0.214, femaleWeight: 0.214, maleWeight: 0.213 },
  { day: 8, weight: 0.249, femaleWeight: 0.249, maleWeight: 0.249 },
  { day: 9, weight: 0.287, femaleWeight: 0.287, maleWeight: 0.287 },
  { day: 10, weight: 0.328, femaleWeight: 0.328, maleWeight: 0.332 },
  { day: 11, weight: 0.373, femaleWeight: 0.373, maleWeight: 0.379 },
  { day: 12, weight: 0.421, femaleWeight: 0.421, maleWeight: 0.429 },
  { day: 13, weight: 0.471, femaleWeight: 0.471, maleWeight: 0.483 },
  { day: 14, weight: 0.525, femaleWeight: 0.525, maleWeight: 0.541 },
  { day: 15, weight: 0.581, femaleWeight: 0.581, maleWeight: 0.603 },
  { day: 16, weight: 0.641, femaleWeight: 0.641, maleWeight: 0.668 },
  { day: 17, weight: 0.703, femaleWeight: 0.703, maleWeight: 0.737 },
  { day: 18, weight: 0.768, femaleWeight: 0.768, maleWeight: 0.809 },
  { day: 19, weight: 0.836, femaleWeight: 0.836, maleWeight: 0.885 },
  { day: 20, weight: 0.906, femaleWeight: 0.906, maleWeight: 0.964 },
  { day: 21, weight: 0.978, femaleWeight: 0.978, maleWeight: 1.046 },
  { day: 22, weight: 1.052, femaleWeight: 1.052, maleWeight: 1.131 },
  { day: 23, weight: 1.129, femaleWeight: 1.129, maleWeight: 1.219 },
  { day: 24, weight: 1.207, femaleWeight: 1.207, maleWeight: 1.310 },
  { day: 25, weight: 1.287, femaleWeight: 1.287, maleWeight: 1.403 },
  { day: 26, weight: 1.369, femaleWeight: 1.369, maleWeight: 1.499 },
  { day: 27, weight: 1.452, femaleWeight: 1.452, maleWeight: 1.597 },
  { day: 28, weight: 1.536, femaleWeight: 1.536, maleWeight: 1.697 },
  { day: 29, weight: 1.622, femaleWeight: 1.622, maleWeight: 1.799 },
  { day: 30, weight: 1.708, femaleWeight: 1.708, maleWeight: 1.902 },
  { day: 31, weight: 1.795, femaleWeight: 1.795, maleWeight: 2.008 },
  { day: 32, weight: 1.883, femaleWeight: 1.883, maleWeight: 2.114 },
  { day: 33, weight: 1.972, femaleWeight: 1.972, maleWeight: 2.222 },
  { day: 34, weight: 2.061, femaleWeight: 2.061, maleWeight: 2.331 },
  { day: 35, weight: 2.150, femaleWeight: 2.150, maleWeight: 2.441 },
  { day: 36, weight: 2.240, femaleWeight: 2.240, maleWeight: 2.552 },
  { day: 37, weight: 2.329, femaleWeight: 2.329, maleWeight: 2.663 },
  { day: 38, weight: 2.419, femaleWeight: 2.419, maleWeight: 2.774 },
  { day: 39, weight: 2.508, femaleWeight: 2.508, maleWeight: 2.886 },
  { day: 40, weight: 2.597, femaleWeight: 2.597, maleWeight: 2.998 },
  { day: 41, weight: 2.686, femaleWeight: 2.686, maleWeight: 3.110 },
  { day: 42, weight: 2.774, femaleWeight: 2.774, maleWeight: 3.222 },
  { day: 43, weight: 2.862, femaleWeight: 2.862, maleWeight: 3.333 },
  { day: 44, weight: 2.949, femaleWeight: 2.949, maleWeight: 3.445 },
  { day: 45, weight: 3.035, femaleWeight: 3.035, maleWeight: 3.465 },
  { day: 46, weight: 3.121, femaleWeight: 3.121, maleWeight: 3.471 },
  { day: 47, weight: 3.205, femaleWeight: 3.205, maleWeight: 3.485 },
  { day: 48, weight: 3.289, femaleWeight: 3.289, maleWeight: 3.489 },
  { day: 49, weight: 3.372, femaleWeight: 3.372, maleWeight: 3.772 },
  { day: 50, weight: 3.454, femaleWeight: 4.098, maleWeight: 4.098 },
  { day: 51, weight: 3.535, femaleWeight: 4.203, maleWeight: 4.203 },
  { day: 52, weight: 3.614, femaleWeight: 4.308, maleWeight: 4.308 },
  { day: 53, weight: 3.693, femaleWeight: 4.411, maleWeight: 4.411 },
  { day: 54, weight: 3.770, femaleWeight: 4.513, maleWeight: 4.513 },
  { day: 55, weight: 3.847, femaleWeight: 4.614, maleWeight: 4.614 },
  { day: 56, weight: 3.922, femaleWeight: 4.714, maleWeight: 4.714 },
];

//mockup
export const DEFAULT_ROSS_308_METRIC: IFlockDefaultWeightView[] = [
  { day: 0, weight: 0.011, femaleWeight: 0.011, maleWeight: 0.011 },
  { day: 1, weight: 0.012, femaleWeight: 0.012, maleWeight: 0.012 },
  { day: 2, weight: 0.012, femaleWeight: 0.012, maleWeight: 0.012 },
  { day: 3, weight: 0.012, femaleWeight: 0.012, maleWeight: 0.012 },
  { day: 4, weight: 0.012, femaleWeight: 0.012, maleWeight: 0.012 },
  { day: 5, weight: 0.012, femaleWeight: 0.012, maleWeight: 0.012 },
  { day: 6, weight: 0.012, femaleWeight: 0.012, maleWeight: 0.012 },
  { day: 7, weight: 0.214, femaleWeight: 0.214, maleWeight: 0.213 },
  { day: 8, weight: 0.249, femaleWeight: 0.249, maleWeight: 0.249 },
  { day: 9, weight: 0.287, femaleWeight: 0.287, maleWeight: 0.287 },
  { day: 10, weight: 0.328, femaleWeight: 0.328, maleWeight: 0.332 },
  { day: 11, weight: 0.373, femaleWeight: 0.373, maleWeight: 0.379 },
  { day: 12, weight: 0.421, femaleWeight: 0.421, maleWeight: 0.429 },
  { day: 13, weight: 0.471, femaleWeight: 0.471, maleWeight: 0.483 },
  { day: 14, weight: 0.525, femaleWeight: 0.525, maleWeight: 0.541 },
  { day: 15, weight: 0.581, femaleWeight: 0.581, maleWeight: 0.603 },
  { day: 16, weight: 0.641, femaleWeight: 0.641, maleWeight: 0.668 },
  { day: 17, weight: 0.703, femaleWeight: 0.703, maleWeight: 0.737 },
  { day: 18, weight: 0.768, femaleWeight: 0.768, maleWeight: 0.809 },
  { day: 19, weight: 0.836, femaleWeight: 0.836, maleWeight: 0.885 },
  { day: 20, weight: 0.906, femaleWeight: 0.906, maleWeight: 0.964 },
  { day: 21, weight: 0.978, femaleWeight: 0.978, maleWeight: 1.046 },
  { day: 22, weight: 1.052, femaleWeight: 1.052, maleWeight: 1.131 },
  { day: 23, weight: 1.129, femaleWeight: 1.129, maleWeight: 1.219 },
  { day: 24, weight: 1.207, femaleWeight: 1.207, maleWeight: 1.310 },
  { day: 25, weight: 1.287, femaleWeight: 1.287, maleWeight: 1.403 },
  { day: 26, weight: 1.369, femaleWeight: 1.369, maleWeight: 1.499 },
  { day: 27, weight: 1.452, femaleWeight: 1.452, maleWeight: 1.597 },
  { day: 28, weight: 1.536, femaleWeight: 1.536, maleWeight: 1.697 },
  { day: 29, weight: 1.622, femaleWeight: 1.622, maleWeight: 1.799 },
  { day: 30, weight: 1.708, femaleWeight: 1.708, maleWeight: 1.902 },
  { day: 31, weight: 1.795, femaleWeight: 1.795, maleWeight: 2.008 },
  { day: 32, weight: 1.883, femaleWeight: 1.883, maleWeight: 2.114 },
  { day: 33, weight: 1.972, femaleWeight: 1.972, maleWeight: 2.222 },
  { day: 34, weight: 2.061, femaleWeight: 2.061, maleWeight: 2.331 },
  { day: 35, weight: 2.150, femaleWeight: 2.150, maleWeight: 2.441 },
  { day: 36, weight: 2.240, femaleWeight: 2.240, maleWeight: 2.552 },
  { day: 37, weight: 2.329, femaleWeight: 2.329, maleWeight: 2.663 },
  { day: 38, weight: 2.419, femaleWeight: 2.419, maleWeight: 2.774 },
  { day: 39, weight: 2.508, femaleWeight: 2.508, maleWeight: 2.886 },
  { day: 40, weight: 2.597, femaleWeight: 2.597, maleWeight: 2.998 },
  { day: 41, weight: 2.686, femaleWeight: 2.686, maleWeight: 3.110 },
  { day: 42, weight: 2.774, femaleWeight: 2.774, maleWeight: 3.222 },
  { day: 43, weight: 2.862, femaleWeight: 2.862, maleWeight: 3.333 },
  { day: 44, weight: 2.949, femaleWeight: 2.949, maleWeight: 3.445 },
  { day: 45, weight: 3.035, femaleWeight: 3.035, maleWeight: 3.465 },
  { day: 46, weight: 3.121, femaleWeight: 3.121, maleWeight: 3.471 },
  { day: 47, weight: 3.205, femaleWeight: 3.205, maleWeight: 3.485 },
  { day: 48, weight: 3.289, femaleWeight: 3.289, maleWeight: 3.489 },
  { day: 49, weight: 3.372, femaleWeight: 3.372, maleWeight: 3.772 },
  { day: 50, weight: 3.454, femaleWeight: 4.098, maleWeight: 4.098 },
  { day: 51, weight: 3.535, femaleWeight: 4.203, maleWeight: 4.203 },
  { day: 52, weight: 3.614, femaleWeight: 4.308, maleWeight: 4.308 },
  { day: 53, weight: 3.693, femaleWeight: 4.411, maleWeight: 4.411 },
  { day: 54, weight: 3.770, femaleWeight: 4.513, maleWeight: 4.513 },
  { day: 55, weight: 3.847, femaleWeight: 4.614, maleWeight: 4.614 },
  { day: 56, weight: 3.922, femaleWeight: 4.714, maleWeight: 4.714 },
];
