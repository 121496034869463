import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CURRENT_USER_FEATURE_KEY } from './current-user.reducer';
import { CurrentUserState } from './current-user.state';
import { ICurrentUserView } from '@livestock/current-user';
import { StringUtils } from '@livestock/shared/utils';

export const getCurrentUserState =
  createFeatureSelector<CurrentUserState>(CURRENT_USER_FEATURE_KEY);

export const selectCurrentUser = createSelector(
  getCurrentUserState,
  (currentUser) => currentUser?.currentUser,
);

export const selectCurrentUserIsLoading = createSelector(
  getCurrentUserState,
  ({ isLoading }) => isLoading,
);

export const selectCurrentUserFullName = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser?.fullName,
);

export const selectCurrentUserInitials = createSelector(
  selectCurrentUser,
  (currentUser: ICurrentUserView) => {
    if (!currentUser) {
      return '';
    }

    return StringUtils.getInitials(currentUser.fullName);
  },
);

export const selectCurrentUserID = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser?.userID,
);

export const selectUserMenuIsActive = createSelector(
  getCurrentUserState,
  ({ userMenuIsActive }) => userMenuIsActive,
);
