import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, from, lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class SvgCacheInterceptor implements HttpInterceptor {
  private readonly svgCache: Record<string, string> = {};
  private readonly pendingRequestsCache: Record<string, Promise<string>> = {};

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('.svg')) {
      const cachedSvg: string = this.svgCache[req.url];

      if (cachedSvg) {
        return of(this.createSvgResponse(cachedSvg));
      }

      const pendingRequest: Promise<string> = this.pendingRequestsCache[req.url];

      if (pendingRequest) {
        return from(pendingRequest.then((svg: string) => this.createSvgResponse(svg)));
      } else {
        const requestPromise: Promise<string> = lastValueFrom(
          next.handle(req).pipe(
            tap((event: HttpEvent<string>) => {
              if (event instanceof HttpResponse && event.status === 200) {
                this.svgCache[req.url] = event.body;
                this.pendingRequestsCache[req.url] = null;
              }
            }),
          ),
        ).then((response: HttpResponse<any>) => response.body);
        this.pendingRequestsCache[req.url] = requestPromise;

        return from(requestPromise.then((svg: string) => this.createSvgResponse(svg)));
      }
    }

    return next.handle(req);
  }

  private createSvgResponse(svg: string): HttpResponse<string> {
    return new HttpResponse({ body: svg, status: 200 });
  }
}
