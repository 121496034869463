import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ICurrentUserView } from '../interfaces/current-user-view';
import { IUpdateCurrentUser } from '../interfaces/update-current-user.interface';

export const getCurrentUser = createAction(
  '[CURRENT USER] Get Current User [...]',
);

export const getCurrentUserSuccess = createAction(
  '[CURRENT USER] Get Current User [SUCCESS]',
  props<{ currentUser: ICurrentUserView; }>(),
);

export const getCurrentUserError = createAction(
  '[CURRENT USER] Get Current User [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const updateCurrentUser = createAction(
  '[CURRENT USER] Update Current User [...]',
  props<{ currentUser: IUpdateCurrentUser; }>(),
);

export const updateCurrentUserSuccess = createAction(
  '[CURRENT USER] Update Current User [SUCCESS]',
  props<{ currentUser: ICurrentUserView; }>(),
);

export const updateCurrentUserError = createAction(
  '[CURRENT USER] Update Current User [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const updateCurrentUserPassword = createAction(
  '[CURRENT USER] Update Current User Password [...]',
  props<{ password: string; }>(),
);

export const updateCurrentUserPasswordSuccess = createAction(
  '[CURRENT USER] Update Current User Password [SUCCESS]',
);

export const updateCurrentUserPasswordError = createAction(
  '[CURRENT USER] Update Current User Password [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const toggleUserMenu = createAction(
  '[CURRENT USER] Set user menu is active',
  props<{ userMenuIsActive: boolean; }>(),
);
