<ls-svg-icon
  (click)="close()"
  [path]="IconsEnum.CLOSE_TEST"
  class="close pointer">
</ls-svg-icon>
<div
  [formGroup]="form"
  class="testing-dialog">
  <ng-container [ngSwitch]="elementType">
    <ng-container *ngSwitchCase="ElementTypesEnum.Cooling">
      <h2 class="fz-24 fw-600">
        {{ "Installation.TestingDialog.RunTest" | translate }}
      </h2>
      <ng-container [ngTemplateOutlet]="testTimeTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ElementTypesEnum.LightDO">
      <h2 class="fz-24 fw-600">
        {{ "Installation.TestingDialog.RunTest" | translate }}
      </h2>
      <ng-container [ngTemplateOutlet]="testTimeTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ElementTypesEnum.AsRelay">
      <h2 class="fz-24 fw-600">
        {{ "Installation.TestingDialog.RunTest" | translate }}
      </h2>
      <ng-container [ngTemplateOutlet]="testTimeTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="ElementTypesEnum.LightAO">
      <div class="d-flex gap-8 align-center">
        <h2 class="fz-24 fw-600 flex-60">
          {{ "Installation.TestingDialog.RunTest" | translate }}
        </h2>
        <div class="d-flex align-center flex-40">
          <lv-input-integer-with-label
            (onFocusIn)="voltage['focused'] = true"
            (onFocusOut)="voltage['focused'] = false"
            #voltage
            [value]="form['controls']['volt'].value"
            [min]="InstallationConstants.MinTestTime"
            [max]="InstallationConstants.MaxTestTime"
            [noWrapperPadding]="true"
            [inputMinWidth]="'45px'"
            formControlName="volt"
            class="h-48 align-center w-60-forced label-bg-white bg-white full-size-input fz-24 fw-600" />
          <span
            [class.color-error]="form['controls']['volt'].invalid"
            [class.color-grey]="!voltage['focused']"
            class="fz-12 fw-500 mh-5">
            {{ "Installation.TestingDialog.Volt" | translate }}
          </span>
        </div>
      </div>
      <div class="pt-28">
        <lv-slider
          (changed)="onSliderChange($event)"
          [value]="form['controls']['volt'].value"
          [min]="InstallationConstants.VoltMin"
          [max]="InstallationConstants.VoltMax"
          [sliderWidth]="SLIDER_WIDTH_PX"
          formControlName="volt" />
      </div>
      <ng-container [ngTemplateOutlet]="testTimeTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault> Run test dialog is not implemented </ng-container>
  </ng-container>

  <ng-template #testTimeTemplate>
    <div class="d-flex justify-between mt-28">
      <lv-input-integer-with-label
        (onFocusIn)="time['focused'] = true"
        (onFocusOut)="time['focused'] = false"
        #time
        [min]="InstallationConstants.MinTestTime"
        [max]="InstallationConstants.MaxTestTime"
        [noWrapperPadding]="true"
        [inputMinWidth]="'45px'"
        [label]="'Installation.TestingDialog.TestTime'"
        class="h-48 w-106-forced"
        formControlName="testTime">
        <span
          [class.color-error]="form['controls']['testTime'].invalid"
          [class.color-grey]="!time['focused']"
          class="fz-12 fw-500 mh-5"
          rightIcon>
          {{ "Installation.TestingDialog.Sec" | translate }}
        </span>
      </lv-input-integer-with-label>
      <lv-button
        (click)="start()"
        [type]="form['controls']['testTime'].invalid ? ButtonTypeEnum.DISABLED : ButtonTypeEnum.PRIMARY"
        [class.pointer-events-none]="form['controls']['testTime'].invalid"
        [height]="48"
        class="w-96">
        <span>{{ "Installation.TestingDialog.Start" | translate }}</span>
      </lv-button>
    </div>
    <span class="fz-12 color-60">{{ "Installation.TestingDialog.SecRangeMessage" | translate }}</span>
  </ng-template>
</div>
