<div
  *ngIf="parentFormGroup.get(formGroupName) as form"
  [formGroupName]="formGroupName">
  <div
    [ngClass]="platformService.isDeviceApp ? 'justify-between' : 'justify-end'"
    class="d-flex align-center w-100-percent h-28">
    <lv-uninstall-element
      *ngIf="platformService.isDeviceApp"
      (uninstall)="uninstall()"
      [isEditMode]="isEditMode" />

    <div class="d-flex align-center">
      <ng-container *ngIf="form['controls'].sensorOn.value">
        <div class="mh-8">
          <ls-svg-icon
            [path]="IconsEnum.INDOOR_TEMPERATURE_STATUS"
            [color]="
              elementEvent?.status !== InOutdoorTemperatureStatusEnum.SensorOK
                ? ColorsEnum.MainDarkRed
                : ColorsEnum.MonoDark
            ">
          </ls-svg-icon>
        </div>

        <div class="d-flex">
          <p
            [ngClass]="
              elementEvent?.status !== InOutdoorTemperatureStatusEnum.SensorOK ? 'main-darkred' : 'green-status'
            "
            class="fz-28 fw-800 green-status">
            {{
              !elementEvent?.status || emptyValueStatuses.includes(elementEvent?.status)
                ? "--"
                : (elementEvent?.value | number : "1.1-1")
            }}
          </p>

          <div
            *ngIf="
              elementEvent?.status &&
              !emptyValueStatuses.includes(elementEvent?.status) &&
              form['controls'].offset.value
            "
            class="d-flex flex-column text-center mh-3">
            <ls-svg-icon [path]="IconsEnum.OFFSET"></ls-svg-icon>
            <span class="fz-12 fw-800 mt-2">
              {{ form["controls"].offset.value | number : "1.1-1" }}
            </span>
          </div>

          <p
            [ngClass]="
              elementEvent?.status !== InOutdoorTemperatureStatusEnum.SensorOK ? 'main-darkred' : 'green-status'
            "
            class="fz-12 fw-900 mh-2 pt-13">
            {{ ((temperatureUnit$ | async) === TemperatureUnitEnum.Celsius ? "Celsius" : "Fahrenheit") | translate }}
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="!form['controls'].sensorOn.value">
        <div class="mh-8">
          <ls-svg-icon [path]="IconsEnum.INDOOR_TEMPERATURE_STATUS"> </ls-svg-icon>
        </div>

        <div class="d-flex align-end">
          <span class="fz-16 fw-800">{{ "Installation.Off" | translate | uppercase }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <!--FORM-->
  <lv-input
    [label]="'Installation.IndoorTemperature.SensorName'"
    [qaTags]="'qa-txt-sensor-name'"
    [autocomplete]="'nope'"
    formControlName="sensorName"
    type="text"
    class="full-width mt-28 label-bg-white">
  </lv-input>

  <div class="d-flex justify-between mt-18">
    <lv-dual-toggle-with-label
      [optionsTitlePosition]="form['controls'].sensorOn.value ? 'before' : 'after'"
      [firstOption]="{
        title: 'Buttons.Yes',
        value: true,
        theme: ThemeEnum.Main,
        toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
      }"
      [secondOption]="{
        title: 'Installation.Off',
        value: false,
        theme: ThemeEnum.Dark,
        toggleIcon: IconsEnum.CROSS_SMALL_BLACK
      }"
      [label]="'Installation.IndoorTemperature.SensorOn'"
      class="flex-1 label-bg-white"
      formControlName="sensorOn">
    </lv-dual-toggle-with-label>

    <lv-input-decimal-with-label
      (onFocusIn)="offset['focused'] = true"
      (onFocusOut)="offset['focused'] = false"
      #offset
      [accuracy]="1"
      [min]="offsetMin"
      [max]="offsetMax"
      [label]="'Installation.IndoorTemperature.Offset'"
      class="flex-1 ml-8 label-bg-white"
      formControlName="offset">
      <ls-svg-icon
        *ngIf="!offset['focused']"
        [path]="IconsEnum.PLUS_MINUS"
        class="mr-5"
        leftIcon>
      </ls-svg-icon>

      <ng-container
        *ngTemplateOutlet="unit; context: { isValid: form['controls'].offset.valid, isFocused: offset['focused'] }"
        rightIcon>
      </ng-container>
    </lv-input-decimal-with-label>
  </div>

  <lv-simple-dropdown
    [label]="'Installation.IndoorTemperature.Location' | translate"
    [enumString]="'element-location-type'"
    [enum]="ElementLocationTypeEnum"
    [size]="'medium'"
    [class.disabled]="!isEditMode || !platformService.isDeviceApp"
    class="d-block mt-18 label-bg-white"
    formControlName="location">
  </lv-simple-dropdown>
</div>

<ng-template
  #unit
  let-isValid="isValid"
  let-isFocused="isFocused">
  <span
    [class.color-error]="!platformService.isMobileVersion && !isValid"
    [class.color-grey]="!isFocused"
    class="fw-500 mh-5">
    {{ ((temperatureUnit$ | async) === TemperatureUnitEnum.Celsius ? "Celsius" : "Fahrenheit") | translate }}
  </span>
</ng-template>
