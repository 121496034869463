import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { selectCurrentControllerTemperatureUnit } from '@livestock/controllers';
import { IconsEnum, TemperatureUnitEnum, ButtonTypeEnum, ColorsEnum } from '@livestock/shared/enums';
import {
  DualToggleWithLabelComponent,
  LVInputComponent,
  LVInputDecimalWithLabelComponent,
  SvgIconComponent,
  ThemeEnum,
  SimpleDropdownComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, Validators, ControlContainer, FormGroup, FormControl } from '@angular/forms';
import { InOutdoorTemperatureStatusEnum } from '@livestock/installation/enums';
import {
  InstallationConstants,
  BasicElementComponent,
  IGetOrUpdateElement,
  UninstallElementComponent,
} from '@livestock/installation';
import { firstValueFrom } from 'rxjs';
import { ElementEventInfo } from '@livestock/installation/interfaces';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SvgIconComponent,
    LVInputComponent,
    DualToggleWithLabelComponent,
    LVInputDecimalWithLabelComponent,
    SimpleDropdownComponent,
    UninstallElementComponent,
  ],
  selector: 'lv-outdoor-temperature-setup',
  templateUrl: './outdoor-temperature.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class OutdoorTemperatureSetupComponent extends BasicElementComponent implements OnInit {
  @Input() elementEvent: ElementEventInfo;
  @Output() delete: EventEmitter<void> = new EventEmitter();

  // subs
  temperatureUnit$ = this.store.select(selectCurrentControllerTemperatureUnit);

  // vars
  offsetMin: number;
  offsetMax: number;
  emptyValueStatuses = InOutdoorTemperatureStatusEnum.getEmptyValueStatuses();

  // enums
  IconsEnum = IconsEnum;
  TemperatureUnitEnum = TemperatureUnitEnum;
  ThemeEnum = ThemeEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  ColorsEnum = ColorsEnum;
  InOutdoorTemperatureStatusEnum = InOutdoorTemperatureStatusEnum;

  override ngOnInit(): void {
    const disabled = !this.isEditMode;
    this.parentFormGroup.addControl(this.formGroupName,
      new FormGroup({
        elementID: new FormControl<number>({ value: null, disabled }),
        number: new FormControl<number>({ value: 1, disabled }),
        sensorName: new FormControl<string>({ value: '', disabled }, [Validators.required]),
        sensorOn: new FormControl<boolean>({ value: true, disabled }),
        offset: new FormControl<number>({ value: 0, disabled }, [Validators.required]),
      }));
    super.ngOnInit();
    this.applyValidators();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      elementID: setupData.elementID,
      sensorName: setupData.sensorName,
      sensorOn: setupData.sensorOn,
      offset: setupData.offset,
    });
    this.parentFormGroup.updateValueAndValidity();
  }

  private async applyValidators(): Promise<void> {
    const isCelsius = (await firstValueFrom(this.temperatureUnit$)) === TemperatureUnitEnum.Celsius;
    this.offsetMin = isCelsius
      ? InstallationConstants.MinOutIndoorTempOffsetCelsius
      : InstallationConstants.MinOutIndoorTempOffsetFahrenheit;
    this.offsetMax = isCelsius
      ? InstallationConstants.MaxOutIndoorTempOffsetCelsius
      : InstallationConstants.MaxOutIndoorTempOffsetFahrenheit;
    this.parentFormGroup
      .get(this.formGroupName + '.offset')
      .addValidators([Validators.min(this.offsetMin), Validators.max(this.offsetMax)]);
  }

  uninstall(): void {
    this.delete.emit();
  }
}
