<section class="page-content page-404 ltr">
  <div class="info p-15">
    <h1 class="mb-20 fw-700 fz-30 fz-20-device">{{ "Page404.SorryThisPageWasNotFound" | translate }}</h1>
    <ls-button
      [routerLink]="'/farms'"
      [type]="'danger'"
      class="pointer">
      {{ "Page404.GoToMainPage" | translate }}
    </ls-button>
  </div>
</section>
