import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IAllStatuses,
  IInternetConnectionStatus,
  ILanSettings,
  IModemCredentials,
  IWifiConnection,
  IWifiConnectionList,
  IControllerView,
  IGeneralSettingsView,
  IDateTimeSettingsView,
  ICreateControllerView,
  IHouseSizesView,
  IGeneralSettingsViewNoUnits,
} from '@livestock/controllers/interfaces';

import { QuickStartStepsEnum } from '@livestock/controllers/enums';

export * from './upsert-controller-device.actions';

export const getGeneralSettings = createAction(
  '[UPSERT CONTROLLER] Get General Settings [...]',
  props<{ controllerID: number }>(),
);

export const getGeneralSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Get General Settings [SUCCESS]',
  props<{ generalSettings: IGeneralSettingsView }>(),
);

export const getGeneralSettingsError = createAction(
  '[UPSERT CONTROLLER] Get General Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setGeneralSettings = createAction(
  '[UPSERT CONTROLLER] Set General Settings [...]',
  props<{ generalSettings: IGeneralSettingsView }>(),
);

export const getDateTimeSettings = createAction(
  '[UPSERT CONTROLLER] Get Date Time Settings [...]',
);

export const getDateTimeSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Get Date Time Settings [SUCCESS]',
  props<{ dateTimeSettings: IDateTimeSettingsView }>(),
);

export const getDateTimeSettingsError = createAction(
  '[UPSERT CONTROLLER] Get Date Time Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setDateTimeSettings = createAction(
  '[UPSERT CONTROLLER] Set Date Time Settings [...]',
  props<{ dateTimeSettings: IDateTimeSettingsView }>(),
);

export const getLanSettings = createAction(
  '[UPSERT CONTROLLER] Get Lan Settings [...]',
  props<{ lan: number; }>(),
);

export const getLanSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Get Lan Settings [SUCCESS]',
  props<ILanSettings>(),
);

export const getLanSettingsError = createAction(
  '[UPSERT CONTROLLER] Get Lan Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateLanSettings = createAction(
  '[UPSERT CONTROLLER] Update Lan Settings [...]',
  props<{ lan: number; }>(),
);

export const updateLanSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Update Lan Settings [SUCCESS]',
  props<ILanSettings>(),
);

export const updateLanSettingsError = createAction(
  '[UPSERT CONTROLLER] Update Lan Settings [ERROR]',
  props<{ payload: HttpErrorResponse, lan: number; }>(),
);

export const getWifiList = createAction(
  '[UPSERT CONTROLLER] Get WiFi List [...]',
);

export const getWifiListSuccess = createAction(
  '[UPSERT CONTROLLER] Get Wifi List [SUCCESS]',
  props<IWifiConnectionList>(),
);

export const getWifiListError = createAction(
  '[UPSERT CONTROLLER] Get Wifi List [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const clearWifiList = createAction(
  '[UPSERT CONTROLLER] Clear Wifi List',
);

export const connectWifi = createAction(
  '[UPSERT CONTROLLER] Connect Wifi [...]',
  props<{ wifiData: IWifiConnection }>(),
);

export const connectWifiSuccess = createAction(
  '[UPSERT CONTROLLER] Connect Wifi [SUCCESS]',
  props<{ wifiConnectionResponse: IInternetConnectionStatus; wifiNetwork: IWifiConnection; name: string; }>(),
);

export const connectWifiError = createAction(
  '[UPSERT CONTROLLER] Connect Wifi [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const disconnectWifi = createAction(
  '[UPSERT CONTROLLER] Disconnect Wifi [...]',
);

export const disconnectWifiSuccess = createAction(
  '[UPSERT CONTROLLER] Disconnect Wifi [SUCCESS]',
);

export const disconnectWifiError = createAction(
  '[UPSERT CONTROLLER] Disconnect Wifi [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setModemState = createAction(
  '[UPSERT CONTROLLER] Set Modem State',
  props<{ state: number }>(),
);

export const connectModem = createAction(
  '[UPSERT CONTROLLER] Connect Modem [...]',
  props<{ modemData: IModemCredentials; }>(),
);

export const connectModemSuccess = createAction(
  '[UPSERT CONTROLLER] Connect Modem [SUCCESS]',
  props<{ modemConnectionResponse: IInternetConnectionStatus; }>(),
);

export const connectModemError = createAction(
  '[UPSERT CONTROLLER] Connect Modem [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const disconnectModem = createAction(
  '[UPSERT CONTROLLER] Disconnect Modem [...]',
);

export const disconnectModemSuccess = createAction(
  '[UPSERT CONTROLLER] Disconnect Modem [SUCCESS]',
);

export const disconnectModemError = createAction(
  '[UPSERT CONTROLLER] Disconnect Modem [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const getNetworkStatus = createAction(
  '[UPSERT CONTROLLER] Get Network Status [...]',
  props<{ overrideSettingsState?: boolean; }>(),
);

export const getNetworkStatusSuccess = createAction(
  '[UPSERT CONTROLLER] Get Network Status [SUCCESS]',
  props<{ networkStatus: IAllStatuses; overrideSettingsState?: boolean; }>(),
);

export const getNetworkStatusError = createAction(
  '[UPSERT CONTROLLER] Get Network Status [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setHouseSizesSettings = createAction(
  '[UPSERT CONTROLLER] Set House Sizes Settings [...]',
  props<{ houseSizesSettings: IHouseSizesView }>(),
);

export const setFlockCurveOffsetWasChanged = createAction(
  '[UPSERT CONTROLLER] Set Flock Curve Offset Was Changed',
  props<{ curveOffsetWasChanged: boolean }>(),
);

// Create, Update, Delete Actions
export const createController = createAction(
  '[UPSERT CONTROLLER] Create Controller [...]',
  props<{ controller: ICreateControllerView }>(),
);

export const createControllerSuccess = createAction(
  '[UPSERT CONTROLLER] Create Controller [SUCCESS]',
  props<{ controllerID: number }>(),
);

export const createControllerError = createAction(
  '[UPSERT CONTROLLER] Create Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateController = createAction(
  '[UPSERT CONTROLLER] Update Controller [...]',
  props<{ requestView: IControllerView }>(),
);

export const updateControllerSuccess = createAction(
  '[UPSERT CONTROLLER] Update Controller [SUCCESS]',
);

export const updateControllerError = createAction(
  '[UPSERT CONTROLLER] Update Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateGeneralSettings = createAction(
  '[UPSERT CONTROLLER] Update General Settings [...]',
);

export const updateGeneralSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Update General Settings [SUCCESS]',
  props<{ generalSettings: IGeneralSettingsViewNoUnits }>(),
);

export const updateGeneralSettingsError = createAction(
  '[UPSERT CONTROLLER] Update General Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateHouseSizesSettings = createAction(
  '[UPSERT CONTROLLER] Update House Sizes Settings [...]',
);

export const updateHouseSizesSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Update House Sizes Settings [SUCCESS]',
);

export const updateHouseSizesSettingsError = createAction(
  '[UPSERT CONTROLLER] Update House Sizes Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const deleteController = createAction(
  '[UPSERT CONTROLLER] Delete Controller [...]',
  props<{ controllerID: number }>(),
);

export const deleteControllerSuccess = createAction(
  '[UPSERT CONTROLLER] Delete Controller [SUCCESS]',
);

export const deleteControllerError = createAction(
  '[UPSERT CONTROLLER] Delete Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// Ticket Controller
export const checkIfControllerIsAssignedWithFarm = createAction(
  '[UPSERT CONTROLLER] Check If Controller Is Assigned With Farm  [...]',
);

export const setIsControllerAssignedWithFarm = createAction(
  '[UPSERT CONTROLLER] Set Is Controller Assigned With Farm [...]',
  props<{ isControllerAssignedWithFarm: boolean }>(),
);

export const clearUpsertControllerState = createAction(
  '[UPSERT CONTROLLER] Clear Upsert Controller State [...]',
);

export const goToNextStep = createAction(
  '[UPSERT CONTROLLER] Go To Next Step [...]',
);

export const goToStep = createAction(
  '[UPSERT CONTROLLER] Go Step [...]',
  props<{ payload: QuickStartStepsEnum }>(),
);

export const goToPrevStep = createAction(
  '[UPSERT CONTROLLER] Go To Prev Step [...]',
);

export const goToDateTimeSettingsStep = createAction(
  '[UPSERT CONTROLLER] Go To Date Time Settings Step [...]',
);
