<div class="h-100-percent d-flex flex-row position-relative">
  <ul #usersList>
    <li
      *ngFor="let user of users; trackBy: trackBy"
      (click)="onSelect(user)"
      [class.selected]="user.userID ? user.userID === selectedUser?.userID : user.email === selectedUser?.email"
      class="pointer">
      <div class="user-header position-relative d-flex justify-between align-center">
        <div class="d-flex align-center">
          <ng-container *ngIf="user | memoizeFunc : isPendingInvitation; else farmUserInfo">
            <div class="user-initials bg-mono-grey">
              <ls-svg-icon
                [path]="IconsEnum.USER_INVITATION"
                width="18px"
                height="18px">
              </ls-svg-icon>
            </div>
            <div class="fz-14 fw-500">
              <div
                [class.ellipsis]="!platformService.isMobileApp"
                class="user-email color-darkgrey">
                {{ user.email }}
              </div>
              <div class="mt-4">
                <span class="color-grey">{{ user.accessesRole | enum : "farm-user-roles" | translate }}</span>
                <span class="color-darkblue"
                  >({{
                    ((user | memoizeFunc : FarmUserHelper.isUserInvitationExpired)
                      ? "Farms.FarmUpdate.InvitationExpired"
                      : "Farms.FarmUpdate.InvitationSent"
                    ) | translate
                  }})</span
                >
              </div>
            </div>
          </ng-container>
          <ng-template #farmUserInfo>
            <div
              [style.background-color]="user | memoizeFunc : FarmUserHelper.getUserInitialsColor"
              class="user-initials">
              {{ user | memoizeFunc : getUserInitials }}
            </div>
            <div class="fz-14 fw-500">
              <div class="user-email">{{ user.email }}</div>
              <div class="color-darkblue mt-4">{{ user.accessesRole | enum : "farm-user-roles" | translate }}</div>
            </div>
          </ng-template>
        </div>
        <ls-svg-icon
          *ngIf="user | memoizeFunc : isUserEditingAvailable"
          [ngClass]="{
            'selected': (user.userID ? user.userID === selectedUser?.userID : user.email === selectedUser?.email),
            'adjust-right-position': userListHasScroll,
          }"
          [color]="
            (user.userID ? user.userID === selectedUser?.userID : user.email === selectedUser?.email)
              ? ColorsEnum.MainBlue
              : ColorsEnum.MonoGrey
          "
          [path]="
            (user.userID ? user.userID === selectedUser?.userID : user.email === selectedUser?.email)
              ? IconsEnum.EXPAND_IN_CIRCLE
              : IconsEnum.VERTICAL_DOTS
          "
          class="pointer user-selection-icon ml-12">
        </ls-svg-icon>
      </div>
    </li>
  </ul>
  <div
    *ngIf="selectedUser"
    [ngClass]="{ 'no-left-bottom-radius': userListHasScroll }"
    class="user-details pt-10 ph-28 pb-28">
    <lv-farm-user-details
      [user]="selectedUser"
      [currentUserRole]="currentUser.role"
      [currenUserAccessRole]="currentUserAccessRole"
      [mode]="userDetailsMode"
      class="limited-height">
    </lv-farm-user-details>
  </div>
</div>
