import { distinctUntilChanged, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export function wasChangedAndNotEmpty() {
  return function (source: Observable<any>): Observable<any> {
    return new Observable(subscriber => {
      return source.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        filter(val => val?.length > 0),
      ).subscribe({
        next(value) {
          subscriber.next(value);
        },
        error(error) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        },
      });
    });
  };
}
