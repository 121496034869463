<input
  #input
  [ngClass]="{
    'no-border': noBorder,
    rtl: languageService.isRtl,
    'no-max-width': noMaxWidth,
    'error-border': control?.invalid
  }"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [qaTags]="qaTags"
  [readonly]="readonly"
  [style.text-align]="inputTextAlign"
  [style.width.px]="inputWidthPx"
  [style.max-width.px]="inputWidthPx"
  [style.background-color]="inputBgColor"
  [style.font-weight]="fontWeight"
  type="text" />

<ng-content select="[description]"></ng-content>

<span
  #content
  [class.long-unit]="valueLengthPx > maxLengthForUnitMobile"
  [ngClass]="{ icon__rtl: isRtl, icon: isContent }"
  [style.left.px]="languageService.isRtl ? 0 : valueLengthPx + inputPadding + extraPadding"
  [style.right.px]="!languageService.isRtl ? 0 : valueLengthPx + inputPadding + extraPadding"
  class="position-absolute w-mc pointer-events-none">
  <ng-content select="[suffix]"></ng-content>
</span>

<!--for value length calculation-->
<span
  *ngIf="isContent && viewInitiated"
  #valueLengthSpan
  class="position-absolute value-raw">
  {{ inputRef.nativeElement.value || placeholder }}
</span>
