<div
  (mousemove)="onMouseMove()"
  class="d-flex flex-column h-100-percent">
  <lv-settings-header
    (toggleDisabled)="toggleDisabled.emit()"
    (back)="back.emit()"
    [page]="page"
    [section]="section"
    [title]="title"
    [isEditMode]="isEditMode">
  </lv-settings-header>

  <div
    [class.content-wrapper__installation]="page === PageTypeEnum.Installation"
    class="flex-grow-1 content-wrapper">
    <ng-content></ng-content>
  </div>

  <div
    *ngIf="page !== PageTypeEnum.Installation"
    class="d-flex justify-end align-center h-42">
    <!--Cnf in progress-->
    <lv-arrows-connect
      *ngIf="cnfStatus === SetupUpdateStatusTypeEnum.InProgress"
      class="mh-10">
    </lv-arrows-connect>
    <!--Cnf is failed-->
    <div
      *ngIf="cnfStatus === SetupUpdateStatusTypeEnum.Failed"
      class="mh-10 d-flex flex-column align-end">
      <p class="color-error fw-500 mb-12">
        {{ "ControllerSettings.DataDidntSendToController" | translate }}
      </p>
      <lv-button
        (click)="resendControllerPageCnf()"
        [paddingH]="28"
        [type]="ButtonTypeEnum.PRIMARY">
        <lv-arrows-connect [arrowsType]="ArrowsConnectEnum.Default"></lv-arrows-connect>
        <span>{{ "ControllerSettings.Send" | translate }}</span>
      </lv-button>
    </div>

    <ng-container *ngIf="isEditMode">
      <lv-button
        *ngIf="cnfStatus === SetupUpdateStatusTypeEnum.Failed"
        (click)="resendControllerPageCnf()"
        [type]="ButtonTypeEnum.PRIMARY"
        class="mh-10">
        <lv-arrows-connect [arrowsType]="ArrowsConnectEnum.Default"></lv-arrows-connect>
        <span>{{ "ControllerSettings.Send" | translate }}</span>
      </lv-button>

      <lv-button
        *ngIf="!isSuccess && (platformService.isDeviceApp || cnfStatus === SetupUpdateStatusTypeEnum.Done)"
        (click)="onSave()"
        [paddingH]="28"
        [type]="isChanged && isValid ? ButtonTypeEnum.PRIMARY : ButtonTypeEnum.DISABLED"
        [class.pointer-events-none]="!isValid || !isChanged"
        [qaTags]="'qa-btn-save'"
        cssClass="border-none">
        {{ "Buttons.Save" | translate }}
      </lv-button>

      <div
        *ngIf="isSuccess"
        @slideIn
        class="success-message d-flex align-center ph-28 pv-18 fw-500">
        <ls-svg-icon
          [path]="IconsEnum.CHECKMARK_GREEN"
          class="mr-8"></ls-svg-icon>
        {{ "Buttons.Saved" | translate }}
      </div>
    </ng-container>
  </div>
</div>
