import { Action, createReducer, on } from '@ngrx/store';
import * as authActions from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  result: any;
  isLoginProcess: boolean;
  isLoginSuccess: boolean;
  isLoginFailure: boolean;
  token: string | null;
  wasLoggedIn?: boolean;
}

export const initialState: AuthState = {
  result: '',
  isLoginProcess: false,
  isLoginSuccess: false,
  isLoginFailure: false,
  token: null,
};

const reducer = createReducer(
  initialState,
  on(authActions.login, (state) => {
    return {
      ...state,
      isLoginProcess: true,
    };
  }),
  on(authActions.loginSuccess, (state) => {
    return {
      ...state,
      isLoginProcess: false,
    };
  }),
  on(authActions.logOutSuccess, authActions.logOutError, (state) => {
    return {
      ...state,
      user: undefined,
      result: '',
      isLoginProcess: false,
      isLoginSuccess: false,
      isLoginFailure: false,
      token: null,
      wasLoggedIn: true,
    };
  }),
  on(authActions.resetLoginProcess, (state) => {
    return {
      ...state,
      isLoginProcess: false,
    };
  }),
);

export function authReducer(
  state: AuthState | undefined,
  action: Action,
): AuthState {
  return reducer(state, action);
}
