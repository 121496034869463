import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { ICurrentUserView } from '../interfaces/current-user-view';
import { IUpdateCurrentUser } from '@livestock/current-user';

const baseUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  constructor(private http: HttpClient) {
  }

  getCurrentUser(): Observable<ICurrentUserView> {
    return this.http.get<ICurrentUserView>(`${baseUrl}/user/current`);
  }

  updateCurrentUser(currentUser: IUpdateCurrentUser): Observable<ICurrentUserView> {
    return this.http.put<ICurrentUserView>(`${baseUrl}/user/current`, { ...currentUser });
  }

  updateCurrentUserPassword(password: string): Observable<ICurrentUserView> {
    return this.http.put<ICurrentUserView>(`${baseUrl}/user/current/password`, { password });
  }
}
