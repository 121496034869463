import { FarmUserRolesEnum } from '@livestock/shared/enums';
import { UserStatusesEnum } from '../../enums/users/user-statuses.enum';

export interface IUser {
  accessesRole: FarmUserRolesEnum;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  mobile: string;
  userID: number;
  status?: UserStatusesEnum;
  farmID?: number;
  tableID?: number;
  resendDate?: string;
  ticketID?: string;
  invitationSentDate?: string;
}
