import { AirPressureUnitEnum, WeightUnitEnum } from '../enums';

export class ConvertHelper {
  static metersToFeet(value: number, accuracy: number): number {
    return +(value * 3.281).toFixed(accuracy);
  }

  static feetToMeters(value: number, accuracy: number): number {
    return +(value / 3.281).toFixed(accuracy);
  }

  static gramToKilogram(value: number, accuracy: number): number {
    return +(value / 1000).toFixed(accuracy);
  }

  static kilogramToGram(value: number, accuracy: number): number {
    return +(value * 1000).toFixed(accuracy);
  }

  static gramToPound(value: number, accuracy: number): number {
    return +(value * 0.00220462).toFixed(accuracy);
  }

  static poundToGram(value: number, accuracy: number): number {
    return +(value * 453.592).toFixed(accuracy);
  }

  static kilogramToPound(value: number, accuracy: number): number {
    return +(value * 2.20462).toFixed(accuracy);
  }

  static poundToKilogram(value: number, accuracy: number): number {
    return +(value * 0.453592).toFixed(accuracy);
  }

  static celsiusToFahrenheit(value: number, accuracy: number): number {
    return +(value * 9 / 5 + 32).toFixed(accuracy);
  }

  static fahrenheitToCelsius(value: number, accuracy: number): number {
    return +((value - 32) * 5 / 9).toFixed(accuracy);
  }

  static inchesOfWaterToPascal(value: number, accuracy: number): number {
    return +(value * 248.84).toFixed(accuracy);
  }

  static pascalToInchesOfWater(value: number, accuracy: number): number {
    return +(value / 248.84).toFixed(accuracy);
  }

  static mmOfWaterToPascal(value: number, accuracy: number): number {
    return +(value * 9.80665).toFixed(accuracy);
  }

  static pascalToMmOfWater(value: number, accuracy: number): number {
    return +(value / 9.80665).toFixed(accuracy);
  }

  static litreToGallon(value: number, accuracy: number): number {
    return +(value * 0.26417).toFixed(accuracy);
  }

  static gallonToLitre(value: number, accuracy: number): number {
    return +(value / 0.26417).toFixed(accuracy);
  }

  static getWeightConversionMethod(
    from: WeightUnitEnum,
    to: WeightUnitEnum,
  ): (value: number, accuracy: number) => number {
    const conversionMap: { [key: string]: (value: number, accuracy: number) => number } = {
      [`${WeightUnitEnum.Kilogram}-${WeightUnitEnum.Pound}`]: ConvertHelper.kilogramToPound,
      [`${WeightUnitEnum.Pound}-${WeightUnitEnum.Kilogram}`]: ConvertHelper.poundToKilogram,
    };

    const functionKey = `${from}-${to}`;
    let conversionFunction = conversionMap[functionKey];
    if (conversionFunction == null) {
      console.error(
        `${ConvertHelper.name}.${ConvertHelper.getWeightConversionMethod.name}: Cannot get valid conversion function for key ${functionKey}. Falling back to original value.`,
      );
      conversionFunction = (value: number, _accuracy: number) => value;
    }
    return conversionFunction;
  }

  static getAirConversionMethod(from: AirPressureUnitEnum, to: AirPressureUnitEnum): (value: number, accuracy: number) => number {
    const conversionMap: { [key: string]: (value: number, accuracy: number) => number } = {
      [`${AirPressureUnitEnum.InchesOfWater}-${AirPressureUnitEnum.Pascal}`]: ConvertHelper.inchesOfWaterToPascal,
      [`${AirPressureUnitEnum.MmOfWater}-${AirPressureUnitEnum.Pascal}`]: ConvertHelper.mmOfWaterToPascal,
      [`${AirPressureUnitEnum.Pascal}-${AirPressureUnitEnum.InchesOfWater}`]: ConvertHelper.pascalToInchesOfWater,
      [`${AirPressureUnitEnum.Pascal}-${AirPressureUnitEnum.MmOfWater}`]: ConvertHelper.pascalToMmOfWater,
    };

    const functionKey = `${from}-${to}`;
    let conversionFunction = conversionMap[functionKey];
    if (!conversionFunction) {
      console.error(`${ConvertHelper.name}.${ConvertHelper.getAirConversionMethod.name}: Cannot get valid conversion function for key ${functionKey}. Falling back to original value.`);
      conversionFunction = (value: number, _accuracy: number) => value;
    }
    return conversionFunction;
  };
}
