import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { LVInputIntegerWithLabelComponent, RangeComponent, SliderComponent, SlimButtonComponent, SvgIconComponent } from '@livestock/ui';
import { ButtonTypeEnum, ElementTypesEnum, IconsEnum } from '@livestock/shared/enums';
import { InstallationConstants } from '@livestock/installation';
import { ITestingDialogData } from '@livestock/installation/interfaces';

@Component({
  selector: 'lv-testing-element-dialog',
  templateUrl: './testing-dialog.component.html',
  styleUrls: ['./testing-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    TranslateModule,
    SlimButtonComponent,
    LVInputIntegerWithLabelComponent,
    RangeComponent,
    SvgIconComponent,
    SliderComponent,
  ],
})
export class TestingElementDialogComponent {
  @ViewChild(LVInputIntegerWithLabelComponent) voltage: LVInputIntegerWithLabelComponent;

  // vars
  elementType: ElementTypesEnum;
  form: FormGroup;

  // enums
  IconsEnum = IconsEnum;
  ElementTypesEnum = ElementTypesEnum;
  ButtonTypeEnum: typeof ButtonTypeEnum = ButtonTypeEnum;
  InstallationConstants = InstallationConstants;

  readonly SLIDER_WIDTH_PX: number = 200;

  constructor(
    public dialogRef: MatDialogRef<TestingElementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITestingDialogData,
    private formBuilder: FormBuilder,

  ) {
    this.elementType = data.elementType;

    switch(this.elementType) {
      case ElementTypesEnum.Cooling:
      case ElementTypesEnum.LightDO:
      case ElementTypesEnum.AsRelay:
        this.form = this.formBuilder.group({
          testTime: [
            30,
            [
              Validators.required,
              Validators.min(InstallationConstants.MinTestTime),
              Validators.max(InstallationConstants.MaxTestTime),
            ],
          ],
        });
        break;
      case ElementTypesEnum.LightAO:
        this.form = this.formBuilder.group({
          testTime: [
            30,
            [
              Validators.required,
              Validators.min(InstallationConstants.MinTestTime),
              Validators.max(InstallationConstants.MaxTestTime),
            ],
          ],
          volt: [
            InstallationConstants.VoltMax,
            [
              Validators.required,
              Validators.min(InstallationConstants.VoltMin),
              Validators.max(InstallationConstants.VoltMax),
            ],
          ],
        });
        break;
      default:
        break;
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  start(): void {
    const data = this.prepareData();
    this.dialogRef.close(data);
  }

  onSliderChange(value: number): void {
    this.voltage.inputRef.nativeElement.value = value;
  }

  private prepareData(): any {
    switch(this.elementType) {
      case ElementTypesEnum.Cooling:
      case ElementTypesEnum.LightDO:
      case ElementTypesEnum.AsRelay:
        return {
          testTime: this.form.controls['testTime'].value,
        };
      case ElementTypesEnum.LightAO:
        return {
          testTime: this.form.controls['testTime'].value,
          volt: this.form.controls['voltage'].value,
        };
      default:
        return null;
    }
  }
}
