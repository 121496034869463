<div
  [class.rtl]="languageService.isRtl"
  [ngClass]="'toggle-size-' + size"
  class="toggle-switch d-flex flex-row align-center gap-8">
  <span
    *ngIf="(isChecked ? onTitle : offTitle)?.length"
    [ngClass]="{ checked: isChecked }"
    class="title fw-600">
    {{ (isChecked ? onTitle : offTitle) | translate | lowercase }}
  </span>
  <label
    (click)="toggleChecked()"
    [ngClass]="{ checked: isChecked, disabled: disabled }">
    <span class="toggle">
      <ls-svg-icon
        [fullHeight]="true"
        [path]="isChecked ? IconsEnum.CHECKMARK : IconsEnum.CROSS_WHITE" />
    </span>
  </label>
</div>
