import { Injectable } from '@angular/core';
import { BiometricAuthError, BiometricOptions, BiometryType, Credentials, NativeBiometric } from 'capacitor-native-biometric';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';

import { DialogsService, LocalStorageService } from '@livestock/shared/services';
import { DialogButtonEnum, StorageItem } from '@livestock/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class BiometryService {

  private readonly MAX_ATTEMPTS: number = 3;

  constructor(private translateService: TranslateService, private dialogsService: DialogsService) {}

  async isBiometryAvailable(): Promise<boolean> {
    try {
      if (!Capacitor?.isNativePlatform()) return false;

      const biometryStatus = await NativeBiometric?.isAvailable();
      return (
        biometryStatus.isAvailable &&
        [BiometryType.FACE_ID, BiometryType.FACE_AUTHENTICATION, BiometryType.FINGERPRINT, BiometryType.MULTIPLE].includes(
          biometryStatus.biometryType,
        )
      );
    } catch (error) {
      console.error(`${BiometryService.name}.${this.isBiometryAvailable.name}: ${JSON.stringify(error)}`);
      return false;
    }
  }

  private async verifyBiometricIdentity(options: BiometricOptions): Promise<boolean> {
    try {
      await NativeBiometric.verifyIdentity({
        reason: this.translateService.instant(options.reason),
        title: this.translateService.instant(options.title),
        subtitle: options.subtitle ? this.translateService.instant(options.subtitle) : undefined,
        description: options.description ? this.translateService.instant(options.description) : undefined,
        useFallback: true,
      });
      return true;
    } catch (error) {
      console.error(`${BiometryService.name}.${this.verifyBiometricIdentity.name}: ${JSON.stringify(error)}`);
      throw error;
    }
  }

  async setCredentialsWithBiometry(username: string, password: string): Promise<boolean> {
    if (!(await this.isBiometryAvailable())) return false;

    if (Capacitor.getPlatform() === 'ios') {
      const selectedOption = await this.dialogsService.question('Auth.Biometry.DescriptionEnable', 'Auth.Biometry.Login', [DialogButtonEnum.YES, DialogButtonEnum.NO]);
      if (selectedOption !== DialogButtonEnum.YES) return false;
    }

    let isUserVerified: boolean = true;
    try {
      await this.verifyBiometricIdentity({
        reason: 'Auth.Biometry.Login',
        title: 'Auth.Biometry.Login',
        description: 'Auth.Biometry.DescriptionEnable',
        useFallback: true,
      });
    } catch (error) {
      isUserVerified = false;
    }

    if (!isUserVerified) return false;

    await NativeBiometric.deleteCredentials({
      server: 'livestock.galkon.com',
    });

    await NativeBiometric.setCredentials({
      server: 'livestock.galkon.com',
      username,
      password,
    });

    LocalStorageService.setStorageItem(StorageItem.IsBiometrySetup, true);
    return true;
  }

  async getCredentialsWithBiometry(): Promise<Credentials | null> {
    if (!(await this.isBiometryAvailable())) return null;

    if (Capacitor.getPlatform() === 'ios') {
      const selectedOption = await this.dialogsService.question('Auth.Biometry.DescriptionGet', 'Auth.Biometry.Login', [DialogButtonEnum.YES, DialogButtonEnum.NO]);
      if (selectedOption !== DialogButtonEnum.YES) throw { code: BiometricAuthError.USER_CANCEL };
    }

    let isUserVerified: boolean;

    try {
      isUserVerified = await this.verifyBiometricIdentity({
        reason: 'Auth.Biometry.Login',
        title: 'Auth.Biometry.Login',
        description: 'Auth.Biometry.DescriptionGet',
        useFallback: true,
      });
      LocalStorageService.setStorageItem(StorageItem.FailedBiometricAttempts, 0);
    } catch(error) {
      if (error?.['code'] == BiometricAuthError.AUTHENTICATION_FAILED) {
        if (Number(LocalStorageService.getStorageItem(StorageItem.FailedBiometricAttempts)) === this.MAX_ATTEMPTS) {
          LocalStorageService.setStorageItem(StorageItem.FailedBiometricAttempts, 0);
          return null;
        }
        LocalStorageService.setStorageItem(StorageItem.FailedBiometricAttempts, Number(LocalStorageService.getStorageItem(StorageItem.FailedBiometricAttempts)) + 1);
        return this.getCredentialsWithBiometry();
      }
    }

    if (!isUserVerified) return null;
    LocalStorageService.setStorageItem(StorageItem.FailedBiometricAttempts, 0);
    return await NativeBiometric.getCredentials({
      server: 'livestock.galkon.com',
    });
  }

  async isBiometrySetup(): Promise<boolean> {
    return LocalStorageService.getStorageItem(StorageItem.IsBiometrySetup) && (await this.isBiometryAvailable());
  }
}
