<section
  [class.rtl]="languageService.isRtl"
  class="main-container">
  <ls-flash-message></ls-flash-message>

  <ng-container *ngIf="router.url === '/'; else displayLayout">
    <ls-display-top-menu-quick-start></ls-display-top-menu-quick-start>
    <router-outlet></router-outlet>
  </ng-container>

  <ng-container *ngIf="this.buildInfoService.json$ | async as data">
    <div class="version fz-12">
      v{{ data.version }}{{ this.buildInfoService.environmentPrefix }} {{ data.release }} {{ data.branch }}#{{
        data.buildNumber
      }}
      <div class="statuses">
        <span
          [class.active]="selectIsSSEConnected$ | async"
          title="display-backend"
          >B</span
        >
        <span
          [class.active]="selectIsNetworkManagerConnected$ | async"
          title="network-manager"
          >N</span
        >
      </div>
      <span
        *ngIf="selectControllerVersion$ | async as data"
        class="firmware">
        FW: v.{{ data.releaseVersion }} {{ data.dateVersion }}#{{ data.buildNumber }}
      </span>
    </div>
  </ng-container>

  <div class="touchdebug">{{ touchDebug }}</div>

  <lv-virtual-keyboard></lv-virtual-keyboard>
</section>

<ng-template #displayLayout>
  <lv-layout-display></lv-layout-display>
</ng-template>
