<div
  [class.wrapper-active]="inputIsFocused"
  [ngClass]="{
    'wrapper-active': inputIsFocused,
    'wrapper-disabled': isDisabled
  }"
  class="wrapper d-flex align-center justify-between">
  <ng-content select="[leftIcon]"></ng-content>

  <div
    (clickOutside)="restoreInitValue()"
    class="d-flex align-center w-100-percent">
    <input
      (ngModelChange)="onModelChange($event)"
      (focusin)="focusInput()"
      #input
      [class.pointer-events-none]="!inputIsFocused"
      [ngModel]="value"
      [placeholder]="placeholder | translate"
      type="{{ type }}"
      maxlength="50"
      class="ml-8 input" />

    <ng-container *ngIf="!isDisabled">
      <ls-svg-icon
        *ngIf="!inputIsFocused; else saveBtn"
        (click)="focusInput()"
        [path]="IconsEnum.FIELD_EDIT"
        [color]="ColorsEnum.MainDarkBlue"
        class="pointer ml-8"></ls-svg-icon>

      <ng-template #saveBtn>
        <button
          *ngIf="inputIsFocused"
          (click)="saveValue()"
          [ngClass]="control.valid ? 'save-btn__active' : 'save-btn__disabled'"
          class="save-btn ml-8">
          <ls-svg-icon
            [path]="IconsEnum.CHECKMARK_SMALL"
            [color]="control.valid ? ColorsEnum.MonoDark : ColorsEnum.GreyDisabled"></ls-svg-icon>
        </button>
      </ng-template>
    </ng-container>
  </div>
</div>
