<div class="verification-code-container">
  <input
    (input)="setCode($event, 1)"
    #input1
    type="text"
    inputmode="numeric"
    class="verification-code-input"
    maxlength="1" />
  <input
    (input)="setCode($event, 2)"
    #input2
    type="text"
    inputmode="numeric"
    class="verification-code-input"
    maxlength="1" />
  <input
    (input)="setCode($event, 3)"
    #input3
    type="text"
    inputmode="numeric"
    class="verification-code-input"
    maxlength="1" />
  <input
    (input)="setCode($event, 4)"
    (keydown.enter)="sendCode()"
    #input4
    type="text"
    inputmode="numeric"
    class="verification-code-input"
    maxlength="1" />
</div>
