import { IEditUnitModeObj } from '../../interfaces';

export enum WeightUnitEnum {
  Kilogram,
  Pound,
}

export namespace WeightUnitEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case WeightUnitEnum.Kilogram:
        return 'Controller.GeneralSettings.CustomUnitsDialog.WeightEnum.Kilogram';
      case WeightUnitEnum.Pound:
        return 'Controller.GeneralSettings.CustomUnitsDialog.WeightEnum.Pound';
      default:
        return value.toString();
    }
  }

  export function toShortTranslateKey(value: any): string {
    switch (value) {
      case WeightUnitEnum.Kilogram:
        return 'Controller.GeneralSettings.CustomUnitsDialog.WeightEnum.KilogramShort';
      case WeightUnitEnum.Pound:
        return 'Controller.GeneralSettings.CustomUnitsDialog.WeightEnum.PoundShort';
      default:
        return value.toString();
    }
  }

  export function isMetricUnit(value: WeightUnitEnum): boolean {
    return value !== WeightUnitEnum.Pound;
  }

  export function toEditUnitModeObj(): IEditUnitModeObj {
    return {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.Weight',
      controlName: 'weight',
      enumName: WeightUnitEnum,
      enumStr: 'weight-unit-enum',
    };
  }
}
