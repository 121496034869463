import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  IconsEnum,
  ButtonTypeEnum,
  ColorsEnum,
  OffOnEnum,
  VolumeUnitEnum,
} from '@livestock/shared/enums';
import {
  DualToggleWithLabelComponent,
  LVInputComponent,
  LVInputDecimalWithLabelComponent,
  SvgIconComponent,
  ThemeEnum,
  SimpleDropdownComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, Validators, ControlContainer, FormGroup, FormControl } from '@angular/forms';
import {
  BasicElementComponent,
  IGetOrUpdateElement,
  InstallationConstants,
  UninstallElementComponent,
} from '@livestock/installation';
import { GlobalConstants } from '../../../../../../shared/constants/global-constants';
import { ElementEventInfo } from '@livestock/installation/interfaces';
import { EnumPipe } from '@livestock/shared/pipes';
import { selectCurrentControllerVolumeUnit } from '@livestock/controllers';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    EnumPipe,
    SvgIconComponent,
    LVInputComponent,
    DualToggleWithLabelComponent,
    LVInputDecimalWithLabelComponent,
    SimpleDropdownComponent,
    UninstallElementComponent,
  ],
  selector: 'lv-water-meter-setup',
  templateUrl: './water-meter.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class WaterMeterSetupComponent extends BasicElementComponent implements OnInit {
  @Input() elementEvent: ElementEventInfo;
  @Output() delete: EventEmitter<void> = new EventEmitter();
  volumeUnit$ = this.store.select(selectCurrentControllerVolumeUnit);

  // enums
  OffOnEnum = OffOnEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  VolumeUnitEnum = VolumeUnitEnum;
  ThemeEnum = ThemeEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  ColorsEnum = ColorsEnum;
  InstallationConstants = InstallationConstants;
  GlobalConstants = GlobalConstants;

  override ngOnInit(): void {
    const disabled = !this.isEditMode;
    this.parentFormGroup.addControl(this.formGroupName,
      new FormGroup({
        elementID: new FormControl<number>({ value: null, disabled }),
        number: new FormControl<number>({ value: 1, disabled }),
        sensorName: new FormControl<string>({ value: 'Water Meter - 01', disabled }, [Validators.required]),
        sensorOn: new FormControl<boolean>({ value: true, disabled }),
        quantity: new FormControl<number>({ value: 0, disabled }, [
          Validators.min(InstallationConstants.MinWaterMeterQuantity),
          Validators.max(InstallationConstants.MaxWaterMeterQuantity),
        ]),
        waterFor: new FormControl<boolean>({ value: false, disabled }),
      }));
    super.ngOnInit();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      elementID: setupData.elementID,
      sensorName: setupData.sensorName,
      sensorOn: setupData.sensorOn,
      quantity: setupData.quantity,
      waterFor: setupData.waterFor,
    });
    this.parentFormGroup.updateValueAndValidity();
  }

  uninstall(): void {
    this.delete.emit();
  }
}
