<div
  *ngIf="value"
  class="d-flex align-center">
  <div class="value-min mr-8 fz-12 fw-400">
    {{ "MinWithDot" | translate }}
    <span class="fw-500 value fz-16">{{ value.min }}</span>
  </div>

  <div
    [style.width.px]="sliderWidth"
    class="slider mr-15">
    <div
      *ngFor="let tick of [].constructor(TICKS_COUNT); index as index"
      [class.tick-active]="[value, index] | memoizeFunc : tickIsInRange : this"
      [style.left.px]="TICK_MARGIN_PX + TICKS_GAP_PX * index"
      class="tick"></div>

    <mat-slider
      [disableRipple]="true"
      [min]="min"
      [max]="max">
      <input
        #rangeMin
        [value]="value.min"
        matSliderStartThumb />
      <input
        #rangeMax
        [value]="value.max"
        matSliderEndThumb />
    </mat-slider>
  </div>

  <div class="value-max fz-12 fw-400">
    {{ "MaxWithDot" | translate }}
    <span class="fw-500 value fz-16">{{ value.max }}</span>
  </div>
</div>
