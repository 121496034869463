<div class="d-flex flex-column cloud-connection">
  <div
    *ngIf="ticketQrCodeInfo?.qrCodeData && !cloudConnectionRequest?.isConnected && !isLoading"
    [@fadeInOut]
    class="d-flex flex-row mx-auto gap-28">
    <div class="d-flex flex-column gap-28 justify-center section">
      <h3>{{ "Controller.QuickStart.FirstTimeConnection" | translate }}</h3>
      <h2>{{ "Controller.QuickStart.ScanQrToaddDevice" | translate }}</h2>
      <h2>
        {{ "Controller.QuickStart.YourCodeForSetup" | translate }}
        <span class="bolded">{{ ticketQrCodeInfo.qrCodeData.connectionNumber }}</span>
      </h2>
    </div>
    <div class="d-flex flex-column gap-18 align-center section">
      <qrcode
        [colorLight]="'#ebeff5'"
        [errorCorrectionLevel]="'M'"
        [margin]="0"
        [qaTags]="'qa-img-QR'"
        [qrdata]="
          GlobalConstants.getCreateControllerUrl(
            ticketQrCodeInfo.qrCodeData.ticketID,
            ticketQrCodeInfo.qrCodeData.connectionNumber,
            false
          )
        "
        [width]="QR_CODE_DIMENSION" />
      <h1>{{ "Controller.QuickStart.or" | translate }}</h1>
      <h1>{{ "Controller.QuickStart.SetupFarmFromWebsite" | translate }}</h1>
    </div>
  </div>

  <div
    *ngIf="cloudConnectionRequest?.isConnected && !isLoading && !ticketQrCodeError"
    class="d-flex flex-row mx-auto gap-56 connected">
    <div class="d-flex flex-column gap-18 justify-center section">
      <h3 class="fw-600">{{ "Controller.QuickStart.Congratulation" | translate }}</h3>
      <h2 class="mt-10">
        {{ "Controller.QuickStart.ThisDeviceWasSuccessfullyConnected" | translate }}
        <span class="bolded">{{ cloudConnectionRequest?.farm }}</span>
      </h2>
      <h2 class="mb-10">
        {{ "Controller.QuickStart.by" | translate }}
        <span class="bolded">{{ cloudConnectionRequest?.email }}</span>
      </h2>
      <lv-button
        *ngIf="canDisconnect"
        (click)="disconnect()"
        [type]="ButtonTypeEnum.SECONDARY"
        [qaTags]="'qa-btn-disconnect'">
        {{ "Buttons.Disconnect" | translate }}
      </lv-button>
    </div>
    <div class="d-flex flex-column gap-18 section">
      <ls-svg-icon [path]="IconsEnum.NETWORK_CLOUD_WITH_LINE" />
      <ls-svg-icon [path]="IconsEnum.NETWORK_HOUSES" />
    </div>
  </div>

  <div
    *ngIf="ticketQrCodeError"
    class="d-flex flex-row mx-auto gap-56 connection-error">
    <div class="d-flex flex-column gap-28 justify-center section">
      <h2 class="fw-600 fz-32">{{ "Controller.QuickStart.Oups" | translate }}</h2>
      <h3>
        {{ "Controller.QuickStart.SomethingWentWrongTheConnectionFailed" | translate }}
      </h3>
      <lv-button
        (click)="tryAgain()"
        [type]="ButtonTypeEnum.PRIMARY">
        {{ "Buttons.TryAgain" | translate }}
      </lv-button>
    </div>
    <div class="d-flex flex-column connection-error__icon">
      <ls-svg-icon [path]="IconsEnum.FAILED_CONNECTION" />
    </div>
  </div>

  <div
    *ngIf="isLoading"
    [@fadeInOut]
    class="loading">
    <div class="loading__background"></div>
    <lv-loading-galcon />
    <span class="mt-18 connection-message">
      {{
        (cloudConnectionRequest?.disconnectInProgress
          ? "Controller.QuickStart.DisconnectingFromCloud"
          : "Controller.QuickStart.ConnectingToCloud"
        ) | translate
      }}
    </span>
  </div>
</div>
