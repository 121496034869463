import { IEditUnitModeObj } from '../../interfaces';

export enum LengthUnitEnum {
  Meter,
  Foot,
}

export namespace LengthUnitEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case LengthUnitEnum.Meter:
        return 'Controller.GeneralSettings.CustomUnitsDialog.LengthEnum.Meter';
      case LengthUnitEnum.Foot:
        return 'Controller.GeneralSettings.CustomUnitsDialog.LengthEnum.Foot';
      default:
        return value.toString();
    }
  }

  export function isMetricUnit(value: LengthUnitEnum): boolean {
    return value === LengthUnitEnum.Meter;
  }

  export function toEditUnitModeObj(): IEditUnitModeObj {
    return {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.Length',
      controlName: 'length',
      enumName: LengthUnitEnum,
      enumStr: 'length-unit-enum',
    };
  }
}
