import { IEditUnitModeObj } from '../../interfaces';

export enum TemperatureUnitEnum {
  Celsius,
  Fahrenheit,
}

export namespace TemperatureUnitEnum {
  export function toTranslateKey(value: any, short: boolean = false): string {
    switch (value) {
      case TemperatureUnitEnum.Celsius:
        return short ? 'Celsius' : 'Controller.GeneralSettings.CustomUnitsDialog.TemperatureEnum.Celsius';
      case TemperatureUnitEnum.Fahrenheit:
        return short ? 'Fahrenheit' : 'Controller.GeneralSettings.CustomUnitsDialog.TemperatureEnum.Fahrenheit';
      default:
        return value?.toString();
    }
  }

  export function toShortTranslateKey(value: any): string {
    switch (value) {
      case TemperatureUnitEnum.Celsius:
        return 'Controller.GeneralSettings.CustomUnitsDialog.TemperatureEnum.CelsiusShort';
      case TemperatureUnitEnum.Fahrenheit:
        return 'Controller.GeneralSettings.CustomUnitsDialog.TemperatureEnum.FahrenheitShort';
      default:
        return value?.toString();
    }
  }

  export function isMetricUnit(value: TemperatureUnitEnum): boolean {
    return value === TemperatureUnitEnum.Celsius;
  }

  export function toEditUnitModeObj(): IEditUnitModeObj {
    return {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.Temperature',
      controlName: 'temperature',
      enumName: TemperatureUnitEnum,
      enumStr: 'temperature-unit-enum',
    };
  }
}
