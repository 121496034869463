export enum ElementLocationTypeEnum {
  Front = 0,
  Center,
  Back,
}

export namespace ElementLocationTypeEnum {
  export function toTranslateKey(value: ElementLocationTypeEnum): string {
    switch (value) {
      case ElementLocationTypeEnum.Front:
        return 'Installation.IndoorTemperature.LocationEnum.Front';
      case ElementLocationTypeEnum.Center:
        return 'Installation.IndoorTemperature.LocationEnum.Center';
      case ElementLocationTypeEnum.Back:
        return 'Installation.IndoorTemperature.LocationEnum.Back';
      default:
        return (value as unknown).toString();
    }
  }
}
