import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IControllerView } from '@livestock/controllers/interfaces';
import { ControllerLanguageEnum } from '@livestock/controllers/enums';
import { ITicketQrCodeInfo } from '../../interfaces/controller/ticket-qr-code-info.interface';

export * from './settings/settings.actions';
export * from './edit-mode/edit-mode.actions';

export const getControllerInfo = createAction(
  '[CURRENT CONTROLLER] Get Controller Info [...]',
  props<{ controllerID: number }>(),
);

export const getControllerInfoSuccess = createAction(
  '[CURRENT CONTROLLER] Get Controller Info [SUCCESS]',
  props<{ controllerInfo: IControllerView }>(),
);

export const getControllerInfoError = createAction(
  '[CURRENT CONTROLLER] Get Controller Info [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setActiveControllerID = createAction(
  '[CURRENT CONTROLLER] Set Active Controller ID',
  props<{ controllerID: number }>(),
);

export const setControllerLanguage = createAction(
  '[CURRENT CONTROLLER] Set Controller Language',
  props<{ controllerLanguage: ControllerLanguageEnum }>(),
);

export const clearControllerInfo = createAction(
  '[CURRENT CONTROLLER] Clear Controller Info',
);

export const disconnectController = createAction(
  '[CURRENT CONTROLLER] Disconnect Controller [...]',
);

export const disconnectControllerSuccess = createAction(
  '[CURRENT CONTROLLER] Disconnect Controller [SUCCESS]',
);

export const disconnectControllerError = createAction(
  '[CURRENT CONTROLLER] Disconnect Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const reconnectController = createAction(
  '[CURRENT CONTROLLER] Reconnect Controller [...]',
);

export const reconnectControllerSuccess = createAction(
  '[CURRENT CONTROLLER] Reconnect Controller [SUCCESS]',
  props<{ ticketQrCodeInfo: ITicketQrCodeInfo }>(),
);

export const reconnectControllerError = createAction(
  '[CURRENT CONTROLLER] Reconnect Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);
