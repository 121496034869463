<div class="user-invitation">
  <div class="main-content">
    <div
      [ngClass]="mode !== UserInvitationModeEnum.NewInvitation ? 'mt-18' : ''"
      class="d-flex align-center mb-18 justify-between">
      <div
        *ngIf="platformService.isMobileVersion"
        class="d-flex align-center gap-8">
        <ls-svg-icon
          (click)="goBack()"
          [path]="IconsEnum.GO_BACK"
          class="pointer"></ls-svg-icon>
        <div class="text-mono-dark">
          <p class="mb-4 fz-10 fw-500">
            <span class="farm-name"> {{ activeFarm?.name }}</span>
          </p>
          <h2 class="fz-21 fw-600">
            {{
              (mode === UserInvitationModeEnum.NewInvitation
                ? "Farms.UserDetailsInvitation.UserInvitation"
                : "Farms.UserDetailsInvitation.UserDetails"
              ) | translate
            }}
          </h2>
        </div>
      </div>
      <div *ngIf="!platformService.isMobileVersion">
        <h2 class="fz-21 fw-600">
          {{
            (mode === UserInvitationModeEnum.NewInvitation
              ? "Farms.UserDetailsInvitation.UserInvitation"
              : "Farms.UserDetailsInvitation.UserDetails"
            ) | translate
          }}
        </h2>
      </div>
      <ls-svg-icon
        *ngIf="mode !== UserInvitationModeEnum.NewInvitation"
        (click)="onRemove()"
        [path]="IconsEnum.BASKET_IN_CIRCLE"
        class="pointer"></ls-svg-icon>
    </div>
    <div
      *ngIf="mode === UserInvitationModeEnum.Resend"
      class="user-invitation__info">
      <div
        [ngClass]="platformService.isMobileVersion ? 'h-30' : 'h-54'"
        class="user-invitation__info-date d-flex align-center justify-between ph-18">
        <span
          [ngClass]="platformService.isMobileVersion ? 'fz-14' : 'fz-18'"
          class="fw-500">
          {{
            ((user | memoizeFunc : FarmUserHelper.isUserInvitationExpired)
              ? "Farms.FarmUpdate.InvitationExpired"
              : "Farms.UserDetailsInvitation.InvitationSent"
            ) | translate
          }}
        </span>
        <span
          [ngClass]="platformService.isMobileVersion ? 'fz-12' : 'fz-16'"
          class="fw-500">
          {{ user.invitationSentDate | date : invitationDateFormat }}
        </span>
      </div>
      <div
        *ngIf="platformService.isMobileVersion"
        class="user-invitation__info-email d-flex align-center h-56">
        <ls-svg-icon [path]="IconsEnum.RESEND_USER"></ls-svg-icon>
        <div class="d-flex flex-column mh-8">
          <span class="user-email fz-14 text-mono-dark">{{ user.email }}</span>
          <span class="fz-12 fw-500 color-darkblue">
            {{ user.accessesRole | enum : "farm-user-roles" | translate }}
          </span>
        </div>
      </div>
    </div>
    <div
      [formGroup]="form"
      [class.mt-36]="mode === UserInvitationModeEnum.NewInvitation"
      class="d-flex flex-column mt-18 w-100-percent user-details">
      <div
        *ngIf="platformService.isMobileVersion && mode === UserInvitationModeEnum.EditUser"
        class="user-invitation__info-email d-flex align-center pb-12 mb-18">
        <div
          [style.background-color]="user | memoizeFunc : FarmUserHelper.getUserInitialsColor"
          class="user-initials">
          {{ user.fullName | memoizeFunc : StringUtils.getInitials }}
        </div>
        <div class="fz-14 fw-500">
          <div class="user-email">{{ user.email }}</div>
          <div class="color-darkblue mt-4">{{ user.accessesRole | enum : "farm-user-roles" | translate }}</div>
        </div>
      </div>

      <lv-input
        [label]="'Farms.UserDetailsInvitation.Email'"
        [qaTags]="'qa-txt-email'"
        [autocomplete]="'nope'"
        [class.ng-invalid]="form.get('email').invalid"
        [ngClass]="platformService.isMobileVersion ? 'h-48' : 'h-74'"
        formControlName="email"
        class="d-block__forced w-100-percent"
        type="email">
      </lv-input>
      <lv-simple-dropdown
        #rolesDropDown
        [label]="'Farms.UserDetailsInvitation.Role' | translate"
        [enumString]="'farm-user-roles'"
        [enum]="FarmUserRolesEnum"
        [sortEnum]="['Viewer', 'Editor', 'Admin', 'Owner']"
        [filterEnumFn]="getFilteredRoles"
        [size]="platformService.isMobileVersion ? 'small' : 'large'"
        [showTick]="true"
        [editMode]="mode === UserInvitationModeEnum.NewInvitation"
        class="mt-18"
        formControlName="role">
      </lv-simple-dropdown>
      <ng-container *ngIf="mode === UserInvitationModeEnum.EditUser">
        <lv-input
          [label]="'Farms.UserDetailsInvitation.FullName'"
          [qaTags]="'qa-txt-full-name'"
          [autocomplete]="'nope'"
          [class.ng-invalid]="form.get('fullName').invalid"
          [ngClass]="platformService.isMobileVersion ? 'h-48' : 'h-74'"
          formControlName="fullName"
          class="d-block__forced w-100-percent mt-18"
          type="text">
        </lv-input>
        <lv-input
          *ngIf="mode === UserInvitationModeEnum.EditUser ? user.mobile?.length : true"
          [label]="'Farms.UserDetailsInvitation.PhoneNumber'"
          [qaTags]="'qa-txt-phone-number'"
          [autocomplete]="'nope'"
          [class.ng-invalid]="form.get('mobile').invalid"
          [ngClass]="platformService.isMobileVersion ? 'h-48' : 'h-74'"
          formControlName="mobile"
          class="d-block__forced w-100-percent mt-18"
          type="text">
        </lv-input>
      </ng-container>
    </div>
    <div
      [ngClass]="platformService.isMobileVersion ? 'pb-8' : 'pb-18'"
      class="user-permissions">
      <div class="d-flex align-center mt-18 user-permissions-header">
        <ls-svg-icon [path]="IconsEnum.USER_PERMISSIONS"></ls-svg-icon>
        <span
          [ngClass]="platformService.isMobileVersion ? 'fz-14' : 'fz-18'"
          class="mh-10 fw-500">
          {{ "Farms.UserDetailsInvitation.UserPermissions" | translate }}
        </span>
      </div>
      <div
        *ngFor="let permission of rolePermissionsMap[form.value.role]"
        [ngClass]="platformService.isMobileVersion ? 'mt-8' : 'mt-18'"
        class="d-flex align-center">
        <ls-svg-icon [path]="IconsEnum.TICK_IN_CIRCLE"></ls-svg-icon>
        <span class="mh-10 fz-14 fw-500 color-darkblue">
          {{ "Farms.UserDetailsInvitation." + permission | translate }}
        </span>
      </div>
    </div>
  </div>

  <div
    [class.justify-evenly]="platformService.isMobileVersion"
    class="actions d-flex align-center justify-between mt-28">
    <ng-container *ngIf="mode === UserInvitationModeEnum.NewInvitation">
      <span
        (click)="cancel()"
        class="fz-16 fw-500 text-mono-dark text-underline pointer">
        {{ "Farms.Cancel" | translate }}
      </span>
      <lv-button
        (click)="sendInvitation()"
        [type]="form.valid ? ButtonTypeEnum.PRIMARY : ButtonTypeEnum.DISABLED"
        [class.pointer-events-none]="form.invalid"
        [height]="48"
        [ngClass]="platformService.isMobileVersion ? 'w-192' : 'w-310'">
        <span>{{ "Farms.UserDetailsInvitation.SendInvitation" | translate }}</span>
      </lv-button>
    </ng-container>
    <ng-container *ngIf="mode === UserInvitationModeEnum.Resend">
      <lv-button
        (click)="resendInvitation()"
        [type]="form.valid ? ButtonTypeEnum.SECONDARY : ButtonTypeEnum.DISABLED"
        [class.pointer-events-none]="form.invalid"
        [height]="48"
        class="w-100-percent">
        <span>{{ "Farms.UserDetailsInvitation.ResendInvitation" | translate }}</span>
      </lv-button>
    </ng-container>
    <ng-container
      *ngIf="mode === UserInvitationModeEnum.EditUser && (form | memoizeFunc : isUserFormChanged.bind(this))">
      <span
        (click)="cancel()"
        class="fz-16 fw-500 text-mono-dark text-underline pointer">
        {{ (platformService.isMobileApp ? "Buttons.Cancel" : "Farms.UserDetailsInvitation.CancelChanges") | translate }}
      </span>
      <lv-button
        (click)="updateUser()"
        [type]="ButtonTypeEnum.PRIMARY"
        [height]="48"
        class="w-192">
        <span>{{ "Farms.UserDetailsInvitation.UpdateUser" | translate }}</span>
      </lv-button>
    </ng-container>
  </div>
</div>
