<div
  *ngIf="parentFormGroup.get(formGroupName) as form"
  [formGroupName]="formGroupName">
  <div
    [ngClass]="platformService.isDeviceApp ? 'justify-between' : 'justify-end'"
    class="d-flex align-center w-100-percent h-28">
    <lv-uninstall-element
      *ngIf="platformService.isDeviceApp"
      (uninstall)="uninstall()"
      [isEditMode]="isEditMode" />

    <div class="d-flex align-center">
      <ng-container *ngIf="form['controls'].sensorOn.value">
        <div class="mh-8">
          <ls-svg-icon
            [path]="IconsEnum.WATER_METER"
            [color]="elementEvent?.status === OffOnEnum.Off ? ColorsEnum.MainDarkRed : ColorsEnum.MainDarkBlue">
          </ls-svg-icon>
        </div>

        <div class="d-flex">
          <span
            [ngClass]="elementEvent?.status === OffOnEnum.Off ? 'main-darkred' : 'color-darkblue'"
            class="fz-16 fw-600">
            {{ elementEvent?.status | enum : "off-on-enum" | translate | uppercase }}
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="!form['controls'].sensorOn.value">
        <div class="mh-8">
          <ls-svg-icon [path]="IconsEnum.WATER_METER"></ls-svg-icon>
        </div>

        <div class="d-flex align-end">
          <span class="fz-16 fw-800">{{ "Installation.Off" | translate | uppercase }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <!--FORM-->
  <lv-input
    [label]="'Installation.WaterMeter.SensorName'"
    [qaTags]="'qa-txt-sensor-name'"
    [autocomplete]="'nope'"
    formControlName="sensorName"
    type="text"
    class="full-width mt-28 label-bg-white">
  </lv-input>

  <div class="d-flex justify-between mt-18">
    <lv-dual-toggle-with-label
      [optionsTitlePosition]="form['controls'].sensorOn.value ? 'before' : 'after'"
      [firstOption]="{
        title: 'Buttons.Yes',
        value: true,
        theme: ThemeEnum.Main,
        toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
      }"
      [secondOption]="{
        title: 'Installation.Off',
        value: false,
        theme: ThemeEnum.Dark,
        toggleIcon: IconsEnum.CROSS_SMALL_BLACK
      }"
      [label]="'Installation.WaterMeter.SensorOn'"
      class="flex-1 label-bg-white"
      formControlName="sensorOn">
    </lv-dual-toggle-with-label>

    <lv-input-decimal-with-label
      (onFocusIn)="quantity['focused'] = true"
      (onFocusOut)="quantity['focused'] = false"
      #quantity
      [accuracy]="1"
      [min]="InstallationConstants.MinWaterMeterQuantity"
      [max]="InstallationConstants.MaxWaterMeterQuantity"
      [label]="'Installation.WaterMeter.QTYPulse'"
      class="flex-1 ml-8"
      formControlName="quantity">
      <span
        [class.color-error]="!platformService.isMobileVersion && form['controls'].quantity.invalid"
        [class.color-grey]="!quantity['focused']"
        class="fw-500 mh-5 label-bg-white"
        rightIcon>
        {{ ((volumeUnit$ | async) === VolumeUnitEnum.Litre ? "L" : "gal") | translate }}
      </span>
    </lv-input-decimal-with-label>
  </div>

  <lv-dual-toggle-with-label
    [firstOption]="{
      title: 'Installation.WaterMeter.WaterForValues.Drinking',
      value: false,
      theme: ThemeEnum.Main,
      toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
    }"
    [secondOption]="{
      title: 'Installation.WaterMeter.WaterForValues.Cooling',
      value: true,
      theme: ThemeEnum.Main,
      toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
    }"
    [label]="'Installation.WaterMeter.WaterFor'"
    class="flex-1 mt-18 label-bg-white"
    formControlName="waterFor">
  </lv-dual-toggle-with-label>
</div>
