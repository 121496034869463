import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { loadingSvgPaths } from './loading-galcon.constants';
import { ColorsEnum } from '@livestock/shared/enums';

@Component({
  selector: 'lv-loading-galcon',
  templateUrl: './loading-galcon.component.html',
  styleUrls: ['./loading-galcon.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
  ],
})
export class LoadingGalconComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() shouldBeTranslated: boolean;
  @Input() sectionsColor: ColorsEnum = ColorsEnum.MonoDark;
  @Input() size: number = 200;

  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  svgPaths: string[] = loadingSvgPaths;
  activeIndex = 0;
  loaderAnimation: ReturnType<typeof setInterval>;

  ColorsEnum = ColorsEnum;

  ngOnInit(): void {
    this.loaderAnimation = setInterval(() => {
      if (this.activeIndex === this.svgPaths.length - 1) {
        this.activeIndex = 0;
        this.cdr.detectChanges();
        return;
      }

      this.activeIndex += 1;
      this.cdr.detectChanges();
    }, 300);
  }

  ngOnDestroy(): void {
    clearInterval(this.loaderAnimation);
  }
}
