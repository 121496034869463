<div class="dialog p-28">
  <h2 class="fw-600 fz-24">{{ "Controller.HeyAnybodyThere" | translate }}</h2>
  <div class="pt-8">{{ "Controller.ThePageWillGoOutOfTheEditingModeIn" | translate }}:</div>
  <div class="pt-8 text-center fz-40 fw-700 color-error">{{ timer$ | async }}</div>
  <div class="pt-8 fz-18 fw-600">{{ "Controller.AllProgressOnThisPageWillBeLost" | translate }}</div>
  <div class="d-flex justify-center pt-18">
    <lv-button
      (click)="continueSession()"
      [type]="ButtonTypeEnum.PRIMARY"
      cssClass="confirm-button text-uppercase">
      {{ "Controller.ImHere" | translate }}
    </lv-button>
  </div>
</div>
