import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewChild,
  AfterViewChecked,
  Input,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { map, startWith, tap } from 'rxjs/operators';
import { combineLatest, Observable, Subscription, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';

import { PlatformService, MenuService, LanguageService, DialogsService } from '@livestock/shared/services';
import { SearchHighlightPipe, EnumPipe } from '@livestock/shared/pipes';
import { ButtonComponent, SearchComponent, SlimButtonComponent, SvgIconComponent } from '@livestock/ui';
import { QaTagsDirective } from '@livestock/shared/directives';
import { selectCurrentUserID } from '@livestock/current-user';
import {
  ColorsEnum,
  CommonUserRolesEnum,
  FarmUserRolesEnum,
  IconsEnum,
  DialogButtonEnum,
} from '@livestock/shared/enums';
import {
  selectIsLoading,
  selectFarms,
  selectActiveFarm,
} from '../../+state/farms.selectors';
import { IFarmInfo } from '../../interfaces/farm-info.interface';
import { IFarm } from '../../interfaces/farm.interface';
import { wasChangedAndNotEmpty, wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';
import {
  getFarms,
  resetActiveFarm,
  softDeleteControllerFromTheFarm,
} from '../../+state/farms.actions';
import { UpdateFarmComponent } from '../update-farm/update-farm.component';
import { isClickOnElement } from '@livestock/shared/utils';
import { FarmControllersListComponent } from '../farm-controllers-list/farm-controllers-list.component';
import { AppRoutes } from '@livestock/shared/routes';
import { setMobileViewMode, MobileViewModeEnum } from '@livestock/layout';
import { selectDisconnectedController } from '../../+state/farms.selectors';
import { selectActiveControllerID } from '@livestock/controllers';
import { IController } from '@livestock/farms';
import { filterCloudConnectionAcceptEventsByControllerID } from '@livestock/web-sockets';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SearchComponent,
    SearchHighlightPipe,
    ButtonComponent,
    RouterLink,
    TranslateModule,
    QaTagsDirective,
    SvgIconComponent,
    EnumPipe,
    SlimButtonComponent,
    UpdateFarmComponent,
    NgClickOutsideDirective,
    FarmControllersListComponent,
  ],
  selector: 'ls-farms-list',
  templateUrl: './farms-list.component.html',
  styleUrls: ['./farms-list.component.scss'],
})
export class FarmsListComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChildren('myFarms') myFarms: QueryList<ElementRef>;
  @ViewChild('farmListContainer') farmListContainer: ElementRef<HTMLElement>;

  @Input() isFarmHomepage: boolean;

  @Output() goToLobby = new EventEmitter();
  @Output() setIsEditFarmMode = new EventEmitter<boolean>();

  /*observables*/
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  currentUserRole$: Observable<any>;

  /*variables*/
  extended = true;
  searchResult = new FormControl(null);
  myFarmsFiltered$ = combineLatest([
    this.searchResult.valueChanges.pipe(startWith(this.searchResult.value)),
    this.store.select(selectFarms),
    this.store.select(selectCurrentUserID),
  ]).pipe(
    map(([search, farms, currentUserID]: [string | null, IFarm[], number]) => {
      return farms?.filter(farm => farm.ownerUserID === currentUserID &&
        (!search || farm.name.toLowerCase().includes(search?.toLowerCase())));
    }),
  );

  otherFarmsFiltered$ = combineLatest([
    this.searchResult.valueChanges.pipe(startWith(this.searchResult.value)),
    this.store.select(selectFarms),
    this.store.select(selectCurrentUserID),
  ]).pipe(
    map(([search, farms, currentUserID]: [string | null, IFarm[], number]) => {
      return farms?.filter(farm => farm.ownerUserID !== currentUserID &&
        (!search || farm.name.toLowerCase().includes(search?.toLowerCase())));
    }),
  );

  farms: IFarm[];
  activeFarm: IFarmInfo = null;
  farmsLabelKey = 'Farms.FarmsList.MyFarms';
  farmBlockHeight = 56;
  farmCaptionHeight = 60;
  farmWasCreated: boolean;
  isEditMode = false;
  isScrollableFarmListContainer: boolean;
  controllerListVisible = true;

  /*constants*/
  CommonUserRolesEnum = CommonUserRolesEnum;
  FarmUserRolesEnum = FarmUserRolesEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;
  AppRoutes = AppRoutes;

  constructor(
    public platformService: PlatformService,
    public languageService: LanguageService,
    private router: Router,
    private store: Store,
    private menuService: MenuService,
    private activatedRoute: ActivatedRoute,
    private dialogsService: DialogsService,
    private translateService: TranslateService,
  ) {
  }

  get isRtl(): boolean {
    return this.languageService.isRtl;
  }

  ngOnInit(): void {
    this.store.dispatch(getFarms());

    this.sub$.add(
      this.activatedRoute.queryParams.pipe(
        tap(() => this.farmWasCreated = false),
      ).subscribe((res) => {
        this.farmWasCreated = !!res['farmWasCreated'];
      }),
    );

    this.sub$.add(
      this.store.select(selectFarms).pipe(wasChangedAndNotEmpty())
        .subscribe(farms => {
          this.farms = farms;
        }),
    );

    this.sub$.add(
      this.store.select(selectActiveFarm).pipe(wasChangedAndNotNull())
        .subscribe(activeFarm => {
          this.activeFarm = activeFarm;
          this.controllerListVisible = true;
          this.checkIfScrollableFarmListContainer();
        }),
    );

    // TODO Will be used in future
    // this.sub$.add(
    //   this.store.select(selectAcceptedController).pipe(wasChangedAndNotNull())
    //     .subscribe(acceptedController => {
    //       this.store.dispatch(
    //         setFlashMessage({
    //           flashMessage: {
    //             flashType: FlashMessageTypeEnum.Success,
    //             message: 'FlashMessages.AcceptedControllerMessage',
    //             additionalInfo: {
    //               houseNumber: acceptedController.houseNumber,
    //               farmName: this.activeFarm.name,
    //               serialNumber: acceptedController.sn,
    //             },
    //           },
    //         }),
    //       );
    //       this.store.dispatch(setControllerAsAccepted({controllerID: acceptedController.controllerID}));
    //     }),
    // );

    // this.sub$.add(
    //   this.store.select(selectRejectedController).pipe(wasChangedAndNotNull())
    //     .subscribe(rejectedController => {
    //       this.store.dispatch(
    //         setFlashMessage({
    //           flashMessage: {
    //             flashType: FlashMessageTypeEnum.Error,
    //             message: 'FlashMessages.RejectedControllerMessage',
    //             additionalInfo: {
    //               houseNumber: rejectedController.houseNumber,
    //               serialNumber: rejectedController.sn,
    //             },
    //           },
    //         }),
    //       );
    //       this.store.dispatch(softDeleteControllerFromTheFarm({controllerID: rejectedController.controllerID}));
    //     }),
    // );

    this.sub$.add(
      this.store.select(selectDisconnectedController).pipe(
        wasChangedAndNotNull(),
        withLatestFrom(this.store.select(selectActiveControllerID)),
      ).subscribe(async ([disconnectedController, activeControllerID]: [IController, number]) => {
        if (disconnectedController.controllerID === activeControllerID) {
          const errorTitle = 'Farms.FarmsList.ControllerWasDisconnected';
          const errorMessage = this.translateService.instant(
            'Farms.FarmsList.DisconnectedControllerMessage',
            {
              serialNumber: disconnectedController.sn,
            });
          await this.dialogsService.error(errorMessage, errorTitle, null, [DialogButtonEnum.OK], true, false);
          this.router.navigate([AppRoutes.FARMS]);
        }
        this.store.dispatch(softDeleteControllerFromTheFarm({ controllerID: disconnectedController.controllerID }));
        this.store.dispatch(filterCloudConnectionAcceptEventsByControllerID({ controllerID: disconnectedController.controllerID }));
      }),
    );
  }

  ngAfterViewChecked(): void {
    if (this.isScrollableFarmListContainer == null) {
      this.checkIfScrollableFarmListContainer();
    }
  }

  addController(farmID: number): void {
    this.store.dispatch(setMobileViewMode({ mobileViewMode: MobileViewModeEnum.Lobby }));
    this.router.navigate([AppRoutes.FARM, farmID, AppRoutes.CREATE_CONTROLLER]);
  }

  toggleControllerList(farmID: number): void {
    if (this.activeFarm?.farmID === farmID) {
      this.controllerListVisible = !this.controllerListVisible;
    }
  }

  checkIfScrollableFarmListContainer(): void {
    if (this.farmListContainer?.nativeElement && this.farms) {
      const captionsHeight = this.activeFarm
        ? this.farmCaptionHeight * 2
        : this.farmCaptionHeight;
      setTimeout(() => {
        this.isScrollableFarmListContainer =
          this.farmListContainer.nativeElement.offsetHeight < (this.farms.length * this.farmBlockHeight + captionsHeight);
      });
    }
  }

  scrollFarms(scrollEvent: any): void {
    // TODO: remove if header will not be fixed
    this.farmsLabelKey = scrollEvent.target.scrollTop >= ((this.myFarms.length + 1) * this.farmBlockHeight)
      ? 'Farms.FarmsList.OtherFarms'
      : 'Farms.FarmsList.MyFarms';
  }

  createFarm(isFirstFarm: boolean = false): void {
    this.menuService.toggleMenu(false);
    this.store.dispatch(resetActiveFarm());
    this.router.navigate([AppRoutes.CREATE_FARM], { queryParams: { isFirstFarm } });
  }

  createController(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.menuService.toggleMenu(false);
    this.goToLobby.emit();
    this.router.navigate([`${AppRoutes.FARM}/${this.activeFarm.farmID}/${AppRoutes.CREATE_CONTROLLER}`]);
  }

  setIsEditMode(isEditMode: boolean): void {
    this.isEditMode = isEditMode;
    this.setIsEditFarmMode.emit(isEditMode);
  }

  editActiveFarm(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.goToLobby.emit();
    this.router.navigate([AppRoutes.FARM, this.activeFarm.farmID, AppRoutes.FARM_SETTINGS]);
  }

  onEditClose(farm: IFarm, event?: Event): void {
    if (this.isEditMode
      && this.activeFarm.farmID === farm.farmID
      && !isClickOnElement('cdk-overlay-container', event)
        && !isClickOnElement('option', event)
    ) {
      this.setIsEditMode(false);
    }
  }

  onDelete(): void {
    this.setIsEditMode(false);
    this.router.navigate([`/farms`]);
  }

  trackBy(index: number): number {
    return index;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
