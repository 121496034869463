<lv-settings-wrapper
  (toggleDisabled)="toggleDisabled($event)"
  (save)="onSave()"
  (onReload)="onReload()"
  section="{{ 'ControllerMenu.Sections.BasicSettings' | translate }}"
  title="{{ 'Controller.QuickStart.House' | translate }}"
  [page]="PageTypeEnum.HouseSizesSettings"
  [isSuccess]="isSuccess"
  [isValid]="formComponent?.form?.valid"
  [isEditMode]="isEditMode"
  [isChanged]="isChanged">
  <lv-house-sizes-settings-form
    *ngIf="houseSettings$ | async as houseSettings; else loading"
    (changed)="onChange($event)"
    [isDisabled]="!isEditMode"
    [houseSizesSettings]="houseSettings"
    [lengthUnit]="lengthUnit$ | async"></lv-house-sizes-settings-form>

  <ng-template #loading>
    <div class="h-100-percent w-100-percent d-flex align-center justify-center">
      <lv-loading-galcon></lv-loading-galcon>
    </div>
  </ng-template>
</lv-settings-wrapper>
