import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import {
  ControllerHouseSizesSettingsFormComponent,
  getCurrentControllerFlockSettings,
  SettingsWrapperComponent, updateCurrentControllerFlockSettings, updateCurrentControllerFlockSettingsSuccess,
} from '@livestock/controllers';
import { Store } from '@ngrx/store';
import { LoadingGalconComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FlockSettingsFormComponent } from '../../ui/flock-settings-form/flock-settings-form.component';
import { Actions, ofType } from '@ngrx/effects';
import { FormsService, setIsFormEditing } from '@livestock/forms';
import { PageTypeEnum } from '@livestock/shared/enums';
import { selectIsLoading } from '../../+state/flock.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lv-flock-settings',
  standalone: true,
  imports: [CommonModule, ControllerHouseSizesSettingsFormComponent, LoadingGalconComponent, SettingsWrapperComponent, TranslateModule, FlockSettingsFormComponent],
  templateUrl: './flock-settings.component.html',
  styleUrls: ['./flock-settings.component.scss'],
})
export class FlockSettingsComponent implements OnInit {
  @ViewChild(FlockSettingsFormComponent) formComponent: FlockSettingsFormComponent;

  private store: Store = inject(Store);
  private actions$: Actions = inject(Actions);
  private formsService: FormsService = inject(FormsService);
  private destroyRef: DestroyRef = inject(DestroyRef);

  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);

  // vars
  isEditMode: boolean = false;
  isSuccess: boolean = false;
  isChanged: boolean = false;
  initialHouseNumber: number;

  PageTypeEnum = PageTypeEnum;

  constructor() {
    this.actions$.pipe(ofType(updateCurrentControllerFlockSettingsSuccess), takeUntilDestroyed()).subscribe(() => {
      this.isSuccess = true;
      setTimeout(() => {
        this.formComponent.initialFormValue = this.formComponent.form.getRawValue();
        this.isSuccess = false;
        this.isChanged = false;
        this.isEditMode = false;
        this.store.dispatch(setIsFormEditing({ isEditing: false }));
      }, 2000);
    });
  }

  ngOnInit(): void {
    this.store.dispatch(getCurrentControllerFlockSettings());
  }

  toggleDisabled(isForceToggle?: boolean): void {
    if (isForceToggle) {
      this.isEditMode = !this.isEditMode;
      this.resetFormEditing();
    } else if (this.isEditMode && this.isChanged) {
      this.formsService.confirmFormLeaving()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(confirm => {
          if (confirm) {
            this.isEditMode = false;
            this.resetFormEditing();
          }
        });
    } else {
      this.isEditMode = !this.isEditMode;
    }
  }

  onSave(): void {
    this.store.dispatch(updateCurrentControllerFlockSettings({
      flockSettings: this.formComponent.form.getRawValue(),
    }));
  }

  onReload(): void {
    this.resetFormEditing();
    this.store.dispatch(getCurrentControllerFlockSettings());
    this.isEditMode = false;
  }

  onChange(data: { isChanged: boolean }): void {
    this.isChanged = data.isChanged;
    this.store.dispatch(setIsFormEditing({ isEditing: this.isChanged }));
  }

  resetFormEditing(): void {
    this.store.dispatch(setIsFormEditing({ isEditing: false }));
    this.formComponent.form.patchValue(this.formComponent.initialFormValue);
  }
}
