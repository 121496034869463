import { FlashMessageTypeEnum } from '@livestock/notifications/enums';
import { SseEventTypeEnum } from '@livestock/shared/enums';
import { DisplayStateBackend, DisplayStateController } from 'libs/appdisplay/src/lib/+state/display.state';

type ConnectionRequestData = {
  email: string;
  farm: string;
};

export type SSEEventConnectionRequest = {
  type: SseEventTypeEnum.CONNECTION_REQUEST;
  data: ConnectionRequestData;
};

export type SSEEventRPCUpdateRequired = {
  type: SseEventTypeEnum.RPC_TAB_RELOAD;
};

export type SSEEventRPCNewVersionAvailable = {
  type: SseEventTypeEnum.RPC_UPDATE_AVAILABLE;
  data: {
    version: string;
  };
};

export type SSEEventRPCNewFirmwareAvailable = {
  type: SseEventTypeEnum.RPC_FIRMWARE_AVAILABLE;
  data: {
    version: string;
  };
};

export type SSEEventBackendState = {
  type: SseEventTypeEnum.BACKEND_STATE;
  data: DisplayStateBackend;
};

export type SSEEventControllerState = {
  type: SseEventTypeEnum.CONTROLLER_VERSION;
  data: DisplayStateController;
};

export type SSEEventRPCNotification = {
  type: SseEventTypeEnum.RPC_NOTIFICATION;
  data: {
    msg: string;
    type: FlashMessageTypeEnum
  };
};

export type SSEEvent =
  | SSEEventConnectionRequest
  | SSEEventRPCUpdateRequired
  | SSEEventRPCNewVersionAvailable
  | SSEEventRPCNewFirmwareAvailable
  | SSEEventBackendState
  | SSEEventControllerState
  | SSEEventRPCNotification;
