import { Action, createReducer } from '@ngrx/store';
import { CurrentControllerState } from './current-controller.state';
import { controllerInfoReducers } from './controller-info/controller-info.reducers';
import { settingsReducers } from './settings/settings.reducers';
import { initialState } from './common/initial-state';
import { editModeReducers } from './edit-mode/edit-mode.reducers';

export const CURRENT_CONTROLLER_FEATURE_KEY = 'currentController';

const reducer = createReducer(
  initialState,
  ...controllerInfoReducers,
  ...settingsReducers,
  ...editModeReducers,
);

export function currentControllerReducer(state: CurrentControllerState | undefined, action: Action): any {
  return reducer(state, action);
}
