<div class="d-flex align-center">
  <div
    [style.width.px]="sliderWidth"
    class="slider mr-15 flex-shrink-0 position-relative">
    <div
      *ngFor="let tick of [].constructor(TICKS_COUNT); index as index"
      [class.tick-active]="value > index * step"
      [style.left.px]="TICK_MARGIN_PX + TICKS_GAP_PX * index"
      class="tick tick-{{ tickTheme }}"></div>

    <div class="min-value">{{ min }}</div>
    <div class="max-value">{{ max }}</div>

    <mat-slider
      [class.transform-10]="value < min + step / 2"
      [class.transform-190]="value >= max - step / 2"
      min="{{ min }}"
      max="{{ max }}"
      step="{{ step }}"
      class="{{ tickTheme }}">
      <input
        #valueTick
        [value]="value"
        matSliderThumb />
    </mat-slider>
  </div>

  <div [formGroup]="form">
    <ls-input-integer
      (onFocusIn)="inputInFocus = true"
      (onFocusOut)="inputInFocus = false"
      [min]="min"
      [max]="max"
      [formControlName]="'value'"
      [inputClassList]="'value-slider'"></ls-input-integer>
  </div>

  <div class="ml-4">
    <ng-content select="[unit]"></ng-content>
  </div>
</div>
