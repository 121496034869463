import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AsyncPipe, NgForOf } from '@angular/common';
import { Observable, of, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';

import { setActiveControllerID } from '@livestock/controllers';
import { ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { resetMenuItems } from '@livestock/menu';
import { FormsService, selectIsFormEditing, setIsFormEditing } from '@livestock/forms';
import { selectControllers } from '../../+state/farms.selectors';
import { IController } from '../../interfaces/controller.interface';
import {
  ControllerIndicatorsComponent,
} from '../../../../../controllers/src/lib/components/controller-indicators/controller-indicators.component';

@Component({
  selector: 'lv-farm-controllers-list',
  standalone: true,
  imports: [NgForOf, AsyncPipe, ControllerIndicatorsComponent],
  templateUrl: './farm-controllers-list.component.html',
  styleUrls: ['./farm-controllers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FarmControllersListComponent implements AfterViewInit {
  @Input() farmList: HTMLUListElement;
  @Input() farmListItem: HTMLLIElement;
  @Input() farmID: number;

  private store: Store = inject(Store);
  private router: Router = inject(Router);
  private formsService: FormsService = inject(FormsService);
  private destroyRef: DestroyRef = inject(DestroyRef);

  controllers$: Observable<IController[]> = this.store.select(selectControllers);

  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum: typeof ColorsEnum = ColorsEnum;

  ngAfterViewInit(): void {
    this.scrollListIntoViewport();
  }

  onSelectController(controller: IController): void {
    this.store.select(selectIsFormEditing).pipe(
      takeUntilDestroyed(this.destroyRef),
      take(1),
      switchMap(isFormEditing => isFormEditing ? this.formsService.confirmFormLeaving() : of(true)),
    ).subscribe(confirm => {
      if (confirm) {
        this.store.dispatch(setIsFormEditing({ isEditing: false }));
        this.store.dispatch(setActiveControllerID({ controllerID: controller.controllerID }));
        this.store.dispatch(resetMenuItems());
        this.router.navigate(['farm/' + this.farmID]);
      }
    });
  }

  trackBy(_: number, controller: IController): number {
    return controller.controllerID;
  }

  private scrollListIntoViewport(): void {
    const ulRect: DOMRect = this.farmList.getBoundingClientRect();
    const liRect: DOMRect = this.farmListItem.getBoundingClientRect();

    if (liRect.bottom > ulRect.bottom) {
      this.farmList.scrollTop += liRect.bottom - ulRect.bottom;
    }
  }
}
