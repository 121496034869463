import { ConnectionTypeEnum } from './connection-type.enum';
export enum CardTypeEnum {
    Unknown = 0,
    Analog = 31,
    Relay,
    DigitalInput,
    Scale,
    // Power,
    // SOM
}

export namespace CardTypeEnum {
    export function mapConnections(cardType: CardTypeEnum): { connectionType: ConnectionTypeEnum; connectionNumber: number }[] {
        switch (cardType) {
            case CardTypeEnum.Analog:
                return [
                    ...Array.from({ length: 12 }, (_, i) => ({ connectionType: ConnectionTypeEnum.AnalogInput , connectionNumber: i + 1 })),
                    ...Array.from({ length: 8 }, (_, i) => ({ connectionType: ConnectionTypeEnum.AnalogOutput , connectionNumber: i + 1 })),
                ];

            default:
                return [];
        }
    }
}