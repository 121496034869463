import { AbstractControl, ValidationErrors, FormArray, FormGroup, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { ErrorFieldEnum } from '../enums';

export const AtLeastOneElementSelected = ({ value }: AbstractControl): ValidationErrors => {
  return !(value.element1Connected || value.element2Connected || value.element3Connected) ?
    { elementReq: true } : null;
};

export const StartTimeAfterEndTime = ({ value }: AbstractControl): ValidationErrors => {
  return (moment(value.startTime).isSameOrAfter(moment(value.endTime), 'm')) ?
    { [ErrorFieldEnum.InnerOverlap]: true } : null;
};

export const StartTimeAfterEndTimeOfPrevProg = (control: AbstractControl): ValidationErrors => {
  const { value } = control;
  const parent = (control?.parent as FormArray)?.controls;

  if (!parent) return null;

  const index = parent.indexOf(control);

  if (!index) return null;

  return moment(value.startTime).isSameOrBefore(moment(parent[index - 1]?.value.endTime), 'm')
    ?
    { [ErrorFieldEnum.OuterOverlap]: true } : null;
};

export const StartTimeAfterStartTimeOfPrevProg = (control: AbstractControl): ValidationErrors => {
  const { value } = control;
  const parent = (control?.parent as FormArray)?.controls;

  if (!parent) return null;

  const index = parent.indexOf(control);

  if (!index) return null;

  return moment(value.startTime).isSameOrBefore(moment(parent[index - 1]?.value.startTime), 'm')
    ?
    { [ErrorFieldEnum.OuterOverlap]: true } : null;
};

export const NoDuplicateDaysProg = (days: number[]): any => (control: FormGroup): ValidationErrors => {
  const { value } = control;

  return days.includes(value)
    ?
    { [ErrorFieldEnum.DaysDupl]: true } : null;
};

export const EnableValidatorIfControlTouched = (validator: ValidatorFn): any => (control: AbstractControl): ValidationErrors => {
  return control.touched ? validator(control) : null;
};

export const EnableValidatorsIfControlTouched = (validators: ValidatorFn[]): any[] => {
  return validators.map((validator: ValidatorFn) => {
    return EnableValidatorIfControlTouched(validator);
  });
};