import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GlobalConstants } from '@livestock/shared/constants';
import { NumberUtils, SleepUtils, StringUtils } from '@livestock/shared/utils';
import { QaTagsDirective } from '@livestock/shared/directives';
import { Store } from '@ngrx/store';

import { filter, firstValueFrom, Subscription } from 'rxjs';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { NativeElementInjectorDirective } from '../native-element.directive';
import {
  selectVirtualKeyboardElementUUID,
  selectVirtualKeyboardSymbolAndUUID,
  setVirtualKeyboardElementUuid,
  setVirtualKeyboardMode,
  clearVirtualKeyboardElementUuid,
  setVirtualKeyboardSymbol,
  VirtualKeyboardModesEnum,
  VirtualKeyboardButtonsEnum, setVirtualKeyboardRanges,
} from '@livestock/ui';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { ColorsEnum } from '@livestock/shared/enums';
import { VirtualKeyboardConstants } from '../virtual-keyboard/virtual-keyboard.constants';

@Component({
  selector: 'ls-input-decimal',
  standalone: true,
  imports: [
    CommonModule,
    QaTagsDirective,
    NativeElementInjectorDirective,
  ],
  templateUrl: './input-decimal.component.html',
  styleUrls: [
    '../input-integer/input-integer.component.scss',
    './input-decimal.component.scss',
  ],
})
export class InputDecimalComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('input') inputRef: ElementRef;
  @ViewChild('valueLengthSpan') valueLengthSpan: ElementRef;
  @ViewChild('content', { static: false }) content: ElementRef = new ElementRef(null);
  @ViewChild('leftIcon', { static: false }) leftIcon: ElementRef = new ElementRef(null);
  @Input() min: number = GlobalConstants.DECIMAL_MIN;
  @Input() max: number = GlobalConstants.DECIMAL_MAX;
  @Input() accuracy: number = 3;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() qaTags: string;
  @Input() inputWidthPx: number = 100;
  @Input() inputTextAlign: 'left' | 'right' | 'center' = 'left';
  @Input() blueBorder: boolean;
  @Input() fontWeight: number = 400;

  //TODO: remove in future
  @Input() fieldWithKeyboard: boolean;

  @Input() formControlName: string;
  @Input() showRangesOnKeyboard: boolean;
  @Input() labelForRanges: string = VirtualKeyboardConstants.DefaultRangeLabel;
  @Input() noMaxWidth = false;
  @Input() placeholder = '';
  @Input() enterKeyHint: string;
  @Input() noBorder: boolean;
  @Input() inputBgColor: string = ColorsEnum.White;

  @Output() change: EventEmitter<void> = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();
  @Output() onFocusIn = new EventEmitter();

  value: number | string;
  clickedByUser: boolean;
  isContent = false;
  maxCountBeforeDot: number;
  ColorsEnum = ColorsEnum;

  sub$ = new Subscription();

  /*for units positioning*/
  valueLengthPx: number; //length of current value in input in px
  inputPadding: number; //padding left/right of padding
  extraPadding = 25; // extra padding so unit will not stick with value
  leftIconPadding = 0;
  viewInitiated: boolean; // flag when all element refs will be initiated
  textPositioning: string; // input text positioning
  maxLengthForUnitMobile = 30;

  constructor(
    @Optional()
    public control: NgControl,
    public languageService: LanguageService,
    private store: Store,
    private platformService: PlatformService,
  ) {
    control.valueAccessor = this;
  }

  @HostListener('focusin', ['$event'])
  onInputClick(): void {
    this.onFocusIn.emit();

    if (!this.platformService.isDeviceApp) return;
    this.clickedByUser = true;

    this.store.dispatch(setVirtualKeyboardElementUuid({
      elementUuid: this.inputRef.nativeElement.getAttribute('uuid'),
      labelForRanges: this.labelForRanges,
      ranges: {
        min: this.showRangesOnKeyboard ? this.min : null,
        max: this.showRangesOnKeyboard ? this.max : null,
      },
    }));

    this.store.dispatch(setVirtualKeyboardMode({ mode: VirtualKeyboardModesEnum.Decimal }));

    setTimeout(() => {
      if (!this.value && this.value !== 0) {
        this.inputRef.nativeElement.selectionStart = 0;
        this.inputRef.nativeElement.selectionEnd = 0;
        return;
      }
      this.inputRef.nativeElement.selectionStart = 999;
      this.inputRef.nativeElement.selectionEnd = 999;
    });
  }

  // hide keyboard if clicked outside input
  @HostListener('focusout')
  async unsetFormControl(): Promise<void> {
    if (this.value === VirtualKeyboardButtonsEnum.MINUS) {
      this.valueChange(0);
      this.updateInputValue();
    }

    this.onFocusOut.emit();
    if (!this.platformService.isDeviceApp) return;

    await SleepUtils.sleep(100);
    const activeFormControl = await firstValueFrom(this.store.select(selectVirtualKeyboardElementUUID));
    if (activeFormControl === this.inputRef.nativeElement.getAttribute('uuid')) {
      this.store.dispatch(clearVirtualKeyboardElementUuid());
    }

    this.onFocusOut.emit();
  }

  @HostListener('paste', ['$event'])
  @HostListener('cut', ['$event'])
  @HostListener('delete', ['$event'])
  onPaste(event): void {
    event.stopPropagation();
    event.preventDefault();
    if (event.type === 'paste') {
      const { selectionStart, selectionEnd, value } = event.target;
      const clipboardData: string = parseFloat(event.clipboardData?.getData('text'))?.toFixed(this.accuracy);
      const newVal = StringUtils.insertAt(value, selectionStart, clipboardData, selectionEnd - selectionStart);
      if (NumberUtils.isDecimalValid(parseFloat(newVal), this.accuracy, this.max) === false) return;
      this.valueChange(newVal);
      this.updateInputValue();
    }
  }

  @HostListener('keydown.backspace', ['$event'])
  onKeyDownBack(event): void {
    let { selectionStart, selectionEnd, value } = this.inputRef.nativeElement;

    if (selectionStart === selectionEnd) {
      const countOfCommasBeforeSelection = (value.substring(0, selectionEnd).match(/,/g) || []).length;
      selectionStart = selectionStart - countOfCommasBeforeSelection;
      selectionEnd = selectionEnd - countOfCommasBeforeSelection;
    } else {
      const countOfCommasBeforeSelectionStart = (value.substring(0, selectionStart).match(/,/g) || []).length;
      const countOfCommasBeforeSelectionEnd = (value.substring(0, selectionEnd).match(/,/g) || []).length;
      selectionStart = selectionStart - countOfCommasBeforeSelectionStart;
      selectionEnd = selectionEnd - countOfCommasBeforeSelectionEnd;
    }

    // replace commas
    value = value.replace(/,/g, '');

    const selectionLength = selectionEnd - selectionStart;
    const pointerIdx = value.indexOf(VirtualKeyboardButtonsEnum.DOT);

    event.stopPropagation();
    event.preventDefault();
    if (selectionStart === 0 && selectionLength === 0) return;
    if (selectionStart - 1 === pointerIdx && selectionLength === 0) {
      const valLengthBeforeDot = value
        ?.split(VirtualKeyboardButtonsEnum.DOT)[0]
        .toString()
        .replace(VirtualKeyboardButtonsEnum.MINUS, '')
        .length;
      const countOfCommas = Math.trunc((valLengthBeforeDot - 1) / 3);
      this.inputRef.nativeElement.selectionStart = selectionStart - 1 + countOfCommas;
      this.inputRef.nativeElement.selectionEnd = selectionStart - 1 + countOfCommas;
      return;
    }

    if (pointerIdx < 0) {
      const insertPos = selectionLength === 0 ? selectionStart - 1 : selectionStart;
      const tmpVal = StringUtils.insertAt(value, insertPos, '', selectionLength ? selectionLength : selectionLength + 1);
      const newVal = [VirtualKeyboardButtonsEnum.MINUS, ''].includes(tmpVal) ? 0 : tmpVal;
      this.valueChange(newVal);
      this.updateInputValue();

      const newValCountOfCommas = Math.trunc((newVal?.toString().length - 1) / 3);
      this.inputRef.nativeElement.selectionStart = insertPos + newValCountOfCommas;
      this.inputRef.nativeElement.selectionEnd = insertPos + newValCountOfCommas;
    }

    if (pointerIdx >= 0 && selectionStart <= pointerIdx) {
      const insertPos = selectionLength === 0 ? selectionStart - 1 : selectionStart;
      const tmpVal = StringUtils.insertAt(value, insertPos, '', selectionLength ? selectionLength : selectionLength + 1);
      const intVal = tmpVal.split(VirtualKeyboardButtonsEnum.DOT)[0];

      let newVal = tmpVal;
      if (!intVal) {
        newVal = StringUtils.insertAt(value, 0, '0.', selectionLength + 1);
      }

      if (newVal.includes('..')) {
        newVal = newVal.replace('..', VirtualKeyboardButtonsEnum.DOT);
      }

      this.valueChange(newVal);
      this.updateInputValue();

      const newValCountOfCommasBeforeInsertPos = Math.trunc((insertPos) / 3);
      const newValCountOfCommasBeforeSelection =
        (this.numberWithCommas(newVal).substring(0, insertPos + newValCountOfCommasBeforeInsertPos).match(/,/g) || []).length;

      this.inputRef.nativeElement.selectionStart = insertPos + newValCountOfCommasBeforeSelection;
      this.inputRef.nativeElement.selectionEnd = insertPos + newValCountOfCommasBeforeSelection;
    }

    if (pointerIdx >= 0 && selectionStart > pointerIdx) {
      const insertPos = selectionLength === 0 ? selectionStart - 1 : selectionStart;
      const insertValue = selectionLength === 0 ? '0' : '0'.repeat(selectionLength);
      const newVal = StringUtils.insertAt(value, insertPos, insertValue, selectionLength || 1);
      this.valueChange(newVal);
      this.updateInputValue();

      const newValLengthBeforeDot = newVal
        ?.split(VirtualKeyboardButtonsEnum.DOT)[0]
        .toString()
        .replace(VirtualKeyboardButtonsEnum.MINUS, '')
        .length;
      const newValCountOfCommas = Math.trunc((newValLengthBeforeDot - 1) / 3);

      this.inputRef.nativeElement.selectionStart = insertPos + newValCountOfCommas;
      this.inputRef.nativeElement.selectionEnd = insertPos + newValCountOfCommas;
    }
  }

  @HostListener('keydown.delete', ['$event'])
  onKeyDown(event): void {
    let { selectionStart, selectionEnd, value } = event.target;

    if (selectionStart === selectionEnd) {
      const countOfCommasBeforeSelection = (value.substring(0, selectionEnd).match(/,/g) || []).length;
      selectionStart = selectionStart - countOfCommasBeforeSelection;
      selectionEnd = selectionEnd - countOfCommasBeforeSelection;
    } else {
      const countOfCommasBeforeSelectionStart = (value.substring(0, selectionStart).match(/,/g) || []).length;
      const countOfCommasBeforeSelectionEnd = (value.substring(0, selectionEnd).match(/,/g) || []).length;
      selectionStart = selectionStart - countOfCommasBeforeSelectionStart;
      selectionEnd = selectionEnd - countOfCommasBeforeSelectionEnd;
    }

    // replace commas
    value = value.replace(/,/g, '');

    const selectionLength = selectionEnd - selectionStart;
    const pointerIdx = value.indexOf(VirtualKeyboardButtonsEnum.DOT);

    event.stopPropagation();
    event.preventDefault();
    // When we trying to delete the dot
    if (selectionStart === pointerIdx) {
      const countOfCommas = Math.trunc((value?.split(VirtualKeyboardButtonsEnum.DOT)[0].toString().length - 1) / 3);
      event.target.selectionStart = selectionStart + countOfCommas + 1;
      event.target.selectionEnd = selectionStart + countOfCommas + 1;
      return;
    }
    if (pointerIdx < 0) {
      const tmpVal = StringUtils.insertAt(value, selectionStart, '', selectionLength || 1);
      const newVal = [VirtualKeyboardButtonsEnum.MINUS, ''].includes(tmpVal) ? 0 : tmpVal;
      this.valueChange(newVal);
      this.updateInputValue();
      event.target.selectionStart = selectionStart;
      event.target.selectionEnd = selectionStart;
    }
    /* before dot*/
    if (selectionStart < pointerIdx) {
      const tmpVal = StringUtils.insertAt(value, selectionStart, '', selectionLength || 1);
      const intVal = tmpVal.split(VirtualKeyboardButtonsEnum.DOT)[0];

      let newVal = tmpVal;
      if (!intVal) {
        newVal = StringUtils.insertAt(value, 0, '0.', selectionLength + 1);
      } else if (+intVal <= this.min) {
        newVal = StringUtils.insertAt(value, 0, `${this.min}`, selectionLength + 1);
      }

      if (newVal.includes('..')) {
        newVal = newVal.replace('..', VirtualKeyboardButtonsEnum.DOT);
      }

      this.valueChange(newVal);
      this.updateInputValue();

      const newValCountOfCommasBeforeInsertPos = Math.trunc((selectionStart) / 3);
      const newValCountOfCommasBeforeSelection =
        (this.numberWithCommas(newVal).substring(0, selectionStart + newValCountOfCommasBeforeInsertPos).match(/,/g) || []).length;
      event.target.selectionStart = selectionStart + newValCountOfCommasBeforeSelection;
      event.target.selectionEnd = selectionStart + newValCountOfCommasBeforeSelection;
    }
    /* after dot*/
    if (selectionStart >= pointerIdx) {
      if ((pointerIdx + this.accuracy) < selectionStart) return;
      const insertValue = selectionLength === 0 ? '0' : '0'.repeat(selectionLength);
      const newVal = StringUtils.insertAt(value, selectionStart, insertValue, selectionLength || 1);
      this.valueChange(newVal);
      this.updateInputValue();
      const countOfCommas = Math.trunc((newVal?.split(VirtualKeyboardButtonsEnum.DOT)[0].toString().length - 1) / 3);
      event.target.selectionStart = (selectionLength ? selectionStart : selectionStart + 1) + countOfCommas;
      event.target.selectionEnd = (selectionLength ? selectionStart : selectionStart + 1) + countOfCommas;
    }
  }

  dropInputRefValue(selectionStart: number, selectionEnd: number): void {
    this.inputRef.nativeElement.value = this.value === VirtualKeyboardButtonsEnum.MINUS
      ? VirtualKeyboardButtonsEnum.MINUS
      : this.isNegativeDecPartValue(this.value)
        ? this.numberWithCommas(this.value)
        : (this.numberWithCommas(Number(this.value).toFixed(this.accuracy)) || null);
    this.inputRef.nativeElement.selectionStart = selectionStart;
    this.inputRef.nativeElement.selectionEnd = selectionEnd;
  }

  @HostListener('input', ['$event'])
  onInput(event): void {
    const { data: symbol, inputType } = event;
    const { selectionStart, selectionEnd } = event.target;
    let { value } = event.target;

    // replace commas
    value = value.replace(/,/g, '');

    // Android keyboard backspace
    if (inputType === 'deleteContentBackward') {
      event.preventDefault();
      event.stopPropagation();
      let val = value;
      const selectionStart = this.inputRef.nativeElement.selectionStart;
      if (val.indexOf(VirtualKeyboardButtonsEnum.DOT) < 0) {
        const part1 = val.slice(0, selectionStart);
        let part2 = val.slice(selectionStart);
        if (part2.length < this.accuracy) {
          part2 = part2.padEnd(this.accuracy, '0');
        }
        val = `${part1}.${part2}`;
      }
      this.valueChange(val);
      this.updateInputValue();
      return;
    }

    /* only numbers, dot and hyphen allowed */
    if (/[0-9]/.test(symbol) === false && symbol !== VirtualKeyboardButtonsEnum.MINUS && symbol !== VirtualKeyboardButtonsEnum.DOT) {
      this.dropInputRefValue(selectionStart - 1, selectionStart - 1);
      return;
    }

    if (symbol == VirtualKeyboardButtonsEnum.MINUS) {
      /* do nothing if min is above zero */
      if (this.min >= 0) {
        this.dropInputRefValue(selectionStart - 1, selectionStart - 1);
        return;
      }

      if (this.value === VirtualKeyboardButtonsEnum.MINUS) {
        this.valueChange(0);
        this.updateInputValue();
        return;
      }

      if (+value === 0 || isNaN(+value.replaceAll(VirtualKeyboardButtonsEnum.MINUS, ''))) {
        this.valueChange(VirtualKeyboardButtonsEnum.MINUS);
        this.updateInputValue();
        return;
      }

      const newVal = +this.value < 0 ? Math.abs(+this.value) : -Math.abs(+this.value);

      this.valueChange(newVal);
      this.updateInputValue();
      return;
    }

    if (value === '-.') {
      this.valueChange(`-0.${'0'.repeat(this.accuracy)}`);
      this.updateInputValue();
      this.inputRef.nativeElement.selectionStart = 3;
      this.inputRef.nativeElement.selectionEnd = 3;
      return;
    }

    /*'print dot': move cursor after dot if selectionStart is before dot index.ts*/
    const pointerIdxIncludeInteger = this.isNegativeDecPartValue(this.value)
      ? this.value.toString().indexOf(VirtualKeyboardButtonsEnum.DOT)
      : (+(this.value || '')).toFixed(this.accuracy).indexOf(VirtualKeyboardButtonsEnum.DOT);

    const countOfCommas = Math.trunc((this.value.toString().split(VirtualKeyboardButtonsEnum.DOT)[0].toString().length - 1) / 3);
    if (symbol === VirtualKeyboardButtonsEnum.DOT && selectionStart - 1 <= pointerIdxIncludeInteger + countOfCommas) {
      this.dropInputRefValue(pointerIdxIncludeInteger + countOfCommas + 1, pointerIdxIncludeInteger + countOfCommas + 1);
      return;
    }

    const pointerIdx = (this.value || '').toString().indexOf(VirtualKeyboardButtonsEnum.DOT);

    /*'print dot': do nothing if selectionStart is after dot index.ts*/
    if (symbol === VirtualKeyboardButtonsEnum.DOT && selectionStart - 1 > pointerIdx) {
      this.dropInputRefValue(selectionStart - 1, selectionStart - 1);
      return;
    }

    /*do nothing if trying to input more symbols after dot than accuracy allows (if dot/accuracy exist)*/
    if (pointerIdx >= 0 && (pointerIdx + this.accuracy + countOfCommas) < selectionStart - 1) {
      this.dropInputRefValue(selectionStart - 1, selectionStart - 1);
      return;
    }

    /*input digits before dot*/
    if (pointerIdx >= selectionStart - 1 || pointerIdx < 0) {
      const newVal = this.value?.toString().startsWith('0') && selectionStart - 1 === 0
        ? `${symbol}${this.value.toString().slice(1)}`
        : this.isNegativeDecPartValue(value) && selectionStart - 1 === 1
          ? `-${Number(value).toFixed(this.accuracy)}`
          : Number(value).toFixed(this.accuracy);

      /*do nothing if trying to input more symbols before dot than max/min value allows*/
      if (!newVal.startsWith(VirtualKeyboardButtonsEnum.MINUS) && newVal.split(VirtualKeyboardButtonsEnum.DOT)[0].length > this.maxCountBeforeDot) {
        this.dropInputRefValue(selectionStart - 1, selectionStart - 1);
        return;
      }

      if (newVal.startsWith(VirtualKeyboardButtonsEnum.MINUS) && newVal.split(VirtualKeyboardButtonsEnum.DOT)[0].length > this.maxCountBeforeDot + 1) {
        this.dropInputRefValue(selectionStart - 1, selectionStart - 1);
        return;
      }

      const prevValCountOfCommas = Math.trunc((this.value?.toString().split(VirtualKeyboardButtonsEnum.DOT)[0].toString().length - 1) / 3);

      this.valueChange(newVal);
      this.updateInputValue();

      // If a comma is added when adding a character, then we must increase selectionStart and selectionEnd
      const newValCountOfCommas = Math.trunc((newVal?.split(VirtualKeyboardButtonsEnum.DOT)[0].toString().length - 1) / 3);
      if (newValCountOfCommas > prevValCountOfCommas) {
        this.inputRef.nativeElement.selectionStart = selectionStart + 1;
        this.inputRef.nativeElement.selectionEnd = selectionEnd + 1;
      } else {
        this.inputRef.nativeElement.selectionStart = selectionStart;
        this.inputRef.nativeElement.selectionEnd = selectionEnd;
      }

      return;
    }

    /*input digits after dot*/
    if (pointerIdx < selectionStart - 1) {
      const countOfCommas = Math.trunc((this.value?.toString().split(VirtualKeyboardButtonsEnum.DOT)[0].toString().length - 1) / 3);
      const newVal = StringUtils.insertAt(this.value.toString(), selectionStart - countOfCommas - 1, symbol);
      this.valueChange(newVal);
      this.updateInputValue();
      this.inputRef.nativeElement.selectionStart = selectionStart;
      this.inputRef.nativeElement.selectionEnd = selectionEnd;
      return;
    }

    /*restore prev value for other cases*/
    this.dropInputRefValue(selectionStart - 1, selectionStart - 1);
  }

  onKeyPress(event): void {
    event.stopPropagation();
    event.preventDefault();
    let { value, selectionStart, selectionEnd } = this.inputRef.nativeElement;
    let countOfCommasBeforeSelection = 0;

    if (selectionStart === selectionEnd) {
      countOfCommasBeforeSelection = (value.substring(0, selectionEnd).match(/,/g) || []).length;
      selectionStart = selectionStart - countOfCommasBeforeSelection;
      selectionEnd = selectionEnd - countOfCommasBeforeSelection;
    } else {
      const countOfCommasBeforeSelectionStart = (value.substring(0, selectionStart).match(/,/g) || []).length;
      const countOfCommasBeforeSelectionEnd = (value.substring(0, selectionEnd).match(/,/g) || []).length;
      selectionStart = selectionStart - countOfCommasBeforeSelectionStart;
      selectionEnd = selectionEnd - countOfCommasBeforeSelectionEnd;
    }
    // replace commas
    value = value.replace(/,/g, '');
    const pointerIdx = value.indexOf(VirtualKeyboardButtonsEnum.DOT);

    if (event.key === VirtualKeyboardButtonsEnum.PLUS) {
      if (this.inputRef.nativeElement.value === VirtualKeyboardButtonsEnum.MINUS) {
        this.inputRef.nativeElement.value = 0;
        return;
      }
      const newVal = Math.abs(+this.value);
      this.valueChange(newVal);
      this.updateInputValue();
      return;
    }

    if (event.key === VirtualKeyboardButtonsEnum.MINUS) {
      if (this.min >= 0) return;
      if (+this.value === 0) {
        this.inputRef.nativeElement.value = VirtualKeyboardButtonsEnum.MINUS;
        return;
      }
      const newVal = +this.value < 0 ? Math.abs(+this.value) : -Math.abs(+this.value);
      this.valueChange(newVal);
      this.updateInputValue();
      return;
    }

    if (event.key === VirtualKeyboardButtonsEnum.DOT && value === VirtualKeyboardButtonsEnum.MINUS) {
      this.valueChange(`-0.${'0'.repeat(this.accuracy)}`);
      this.updateInputValue();
      this.inputRef.nativeElement.selectionStart = 3;
      this.inputRef.nativeElement.selectionEnd = 3;
      return;
    }

    if (event.key === VirtualKeyboardButtonsEnum.DOT && selectionStart <= pointerIdx) {
      this.inputRef.nativeElement.selectionStart = pointerIdx + 1 + countOfCommasBeforeSelection;
      this.inputRef.nativeElement.selectionEnd = pointerIdx + 1 + countOfCommasBeforeSelection;
      return;
    }

    if (/[0-9]/.test(event.key) === false) return;

    if (pointerIdx >= 0 && (pointerIdx + this.accuracy) < selectionStart) return;

    if (pointerIdx < 0 || selectionStart <= pointerIdx) {
      /* maxLength for input in view is not working in this implementation, so this is possible workaround */
      if (selectionEnd === selectionStart) {
        if (value.includes(VirtualKeyboardButtonsEnum.MINUS) && value.length > GlobalConstants.DECIMAL_MAX.toString().length + 1) return;
        if (!value.includes(VirtualKeyboardButtonsEnum.MINUS) && value.length > GlobalConstants.DECIMAL_MAX.toString().length) return;
      }

      const newVal = value.startsWith('0')
        ? StringUtils.insertAt(value, 0, event.key, 1)
        : StringUtils.insertAt(value, selectionStart, event.key, selectionEnd - selectionStart);

      /*do nothing if trying to input more symbols before dot than max/min value allows*/
      if (!newVal.startsWith(VirtualKeyboardButtonsEnum.MINUS) && newVal.split(VirtualKeyboardButtonsEnum.DOT)[0].length > this.maxCountBeforeDot) {
        return;
      }

      if (newVal.startsWith(VirtualKeyboardButtonsEnum.MINUS) && newVal.split(VirtualKeyboardButtonsEnum.DOT)[0].length > this.maxCountBeforeDot + 1) {
        return;
      }

      this.valueChange(newVal);
      this.updateInputValue();
      const selectionStartEnd = value.startsWith('0') && selectionStart == 1 ? selectionStart : selectionStart + 1;
      const newValCountOfCommasBeforeSelection =
        (this.numberWithCommas(newVal).substring(0, selectionStartEnd).match(/,/g) || []).length;
      this.inputRef.nativeElement.selectionStart = selectionStartEnd + newValCountOfCommasBeforeSelection;
      this.inputRef.nativeElement.selectionEnd = selectionStartEnd + newValCountOfCommasBeforeSelection;
    }

    if (pointerIdx >= 0 && selectionStart > pointerIdx) {
      if (selectionStart - pointerIdx > this.accuracy) return; // out of range
      const newVal = StringUtils.insertAt(value, selectionStart, event.key);
      this.valueChange(newVal);
      this.updateInputValue();
      const newValCountOfCommas = Math.trunc((newVal?.split(VirtualKeyboardButtonsEnum.DOT)[0].toString().length - 1) / 3);
      this.inputRef.nativeElement.selectionStart = selectionStart + newValCountOfCommas + 1;
      this.inputRef.nativeElement.selectionEnd = selectionStart + newValCountOfCommas + 1;
    }
  }

  ngOnInit(): void {
    if (this.platformService.isDeviceApp) {
      this.sub$.add(
        this.store.select(selectVirtualKeyboardElementUUID).pipe(
          wasChanged(),
          filter(res => res == null),
        ).subscribe(() => {
          this.inputRef?.nativeElement.blur();
        }),
      );

      this.sub$.add(
        this.store.select(selectVirtualKeyboardElementUUID).pipe(
          wasChanged(),
          filter((uuid) => uuid === this.inputRef?.nativeElement.getAttribute('uuid')),
        ).subscribe(() => {
          setTimeout(() => {
            // do not set selection start/end and focus if user clicked input by himself
            if (!this.clickedByUser) {
              this.inputRef.nativeElement.focus();
              this.inputRef.nativeElement.selectionStart = 0;
              this.inputRef.nativeElement.selectionEnd = 0;
            }
            this.clickedByUser = false;

            const mode = this.min < 0
              ? VirtualKeyboardModesEnum.DecimalNegative
              : VirtualKeyboardModesEnum.Decimal;
            this.store.dispatch(setVirtualKeyboardMode({ mode }));

            if (this.showRangesOnKeyboard) {
              this.store.dispatch(setVirtualKeyboardRanges({
                labelForRanges: this.labelForRanges,
                ranges: {
                  min: this.min.toFixed(this.accuracy),
                  max: this.max.toFixed(this.accuracy),
                },
              }));
            }
          });
        }),
      );

      this.sub$.add(
        this.store.select(selectVirtualKeyboardSymbolAndUUID)
          .pipe(
            wasChanged(),
            filter(({ symbol, elementUuid }) => {
              return symbol != null && elementUuid === this.inputRef?.nativeElement.getAttribute('uuid');
            }),
          ).subscribe(({ symbol }) => {
          if (symbol === VirtualKeyboardButtonsEnum.DEL) {
            this.onKeyDownBack(new KeyboardEvent('keydown'));
            this.store.dispatch(setVirtualKeyboardSymbol({ symbol: null }));
            return;
          }

          this.onKeyPress(new KeyboardEvent('keydown', { key: symbol as string }));
          this.store.dispatch(setVirtualKeyboardSymbol({ symbol: null }));
        }),
      );
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInitiated = true;
      this.isContent = this.content.nativeElement?.children?.length > 0;
    });

    this.setInputPadding();

    //done to place suffix correctly on resize
    const observer = new ResizeObserver(() => {
      this.setInputPadding();
    });

    observer.observe(this.inputRef.nativeElement);
  }

  async setInputPadding(): Promise<void> {
    // calculating input padding
    const computedStyle = window.getComputedStyle(this.inputRef.nativeElement, null);
    this.textPositioning = computedStyle.getPropertyValue('text-align');

    // for text-align center we calculate HALF of input width + HALF of value width,
    // for left: input padding + value width
    this.inputPadding = this.textPositioning === 'center'
      ? +computedStyle.getPropertyValue('width').replace('px', '') / 2
      : +computedStyle.getPropertyValue('padding-left').replace('px', '');

    this.leftIconPadding = this.leftIcon?.nativeElement?.children?.length > 0
      ? 10
      : 0;

    this.updateInputValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.min != null && this.max != null) {
      this.maxCountBeforeDot = Math.max(
        this.min?.toString().replace(VirtualKeyboardButtonsEnum.MINUS, '').split(VirtualKeyboardButtonsEnum.DOT)[0].length,
        this.max?.toString().replace(VirtualKeyboardButtonsEnum.MINUS, '').split(VirtualKeyboardButtonsEnum.DOT)[0].length,
      );
    }

    if (this.value == null) return;
    const { accuracy } = changes;
    if (accuracy && accuracy.currentValue !== accuracy.previousValue) {
      setTimeout(() => {
        this.value = Number((this.value as number).toFixed(this.accuracy));
        this.onChange(this.value);
        this.updateInputValue();
      });
    }
  }

  get isRtl(): boolean {
    return this.languageService.isRtl;
  }

  updateInputValue(): void {
    if (!this.inputRef || this.value == null) return;

    setTimeout(() => {
      this.valueLengthPx = this.textPositioning === 'center'
        ? this.valueLengthSpan?.nativeElement.offsetWidth / 2
        : this.valueLengthSpan?.nativeElement.offsetWidth;
    });

    if (typeof this.value === 'string') {
      this.inputRef.nativeElement.value = this.numberWithCommas(this.value);
      return;
    }
    this.inputRef.nativeElement.value = this.numberWithCommas(this.value?.toFixed(this.accuracy));
  }

  numberWithCommas(x: string | number): string {
    const parts = x.toString().split(VirtualKeyboardButtonsEnum.DOT);
    // replaces all groups of three consecutive digits with the same group of digits separated by commas, within a string
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join(VirtualKeyboardButtonsEnum.DOT);
  }

  valueChange(value): void {
    if (value === VirtualKeyboardButtonsEnum.MINUS) {
      this.value = value;
      return;
    }

    if (value.toString().startsWith('-0.0')) {
      this.value = value;
      return;
    }

    const isNegativeZero: boolean = 1 / value === -Infinity;
    if (isNegativeZero) {
      this.value = VirtualKeyboardButtonsEnum.MINUS;
      return;
    }

    if (this.isNegativeDecPartValue(value)) {
      this.value = `-${Number(value).toFixed(this.accuracy)}`;
      return;
    }

    this.value = Number(Number(value).toFixed(this.accuracy));
    this.onChange(this.value);
    this.change.emit();
  }

  writeValue(value: number): void {
    if (this.value === value) return;
    this.value = value;
    this.updateInputValue();
  }

  registerOnChange(
    onChange: (value: number | string) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  isNegativeDecPartValue(value): boolean {
    // check on a negative decimal part value like: -0.5
    return value?.toString().startsWith(VirtualKeyboardButtonsEnum.MINUS) && value.toString()?.length > 1 && Number(Number(value).toFixed(this.accuracy)) === 0;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onTouched = (): void => {
  };
  private onChange: (
    value: number | string,
  ) => void = () => {
  };
}

