import { on } from '@ngrx/store';
import * as currentControllerActions from '../current-controller.actions';
import { CurrentControllerState } from '../current-controller.state';

export const editModeReducers = [
  on(currentControllerActions.setLastEditedSuccess, (state: CurrentControllerState, { lastEdited }) => {
    return {
      ...state,
      lastEditedInfo: lastEdited,
    };
  }),
];
