import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { QaTagsDirective } from '@livestock/shared/directives';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { NativeElementInjectorDirective } from '../native-element.directive';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';

@Component({
  selector: 'lv-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  standalone: true,
  imports: [
    QaTagsDirective,
    CommonModule,
    SvgIconComponent,
    MatSliderModule,
    TranslateModule,
    NativeElementInjectorDirective,
    MemoizeFuncPipe,
  ],
})

export class SliderComponent implements ControlValueAccessor, OnChanges {
  @ViewChild('thumb') thumb: ElementRef;
  @Input() value: number;
  @Input() min: number = 0;
  @Input() max: number = 10;
  @Input() sliderWidth: number = 169;
  @Output() changed = new EventEmitter<number>();

  TICKS_COUNT = 11;
  TICKS_GAP_PX = 20;
  TICK_MARGIN_PX = -2;

  constructor(@Optional() private control: NgControl) {
    control.valueAccessor = this;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['min']?.currentValue != null && changes['max']?.currentValue != null) {
      this.TICKS_COUNT = this.max - this.min;
      this.TICKS_GAP_PX = this.sliderWidth / this.TICKS_COUNT;
    }
  }

  @HostListener('input', ['$event'])
  onRangeChanged(): void {
    const value = this.thumb.nativeElement.value;
    this.valueChange(value);
  }

  tickIsInRange(value: number): boolean {
    return value >= this.min && value <= this.max;
  }

  valueChange(value: number): void {
    this.value = value;
    this.onChange(value);
    this.changed.emit(value);
  }

  writeValue(value: number): void {
    if (this.value === value) return;
    this.value = value;
  }

  registerOnChange(
    onChange: (value: number) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  private onTouched = (): void => {
  };

  private onChange: (
    value: number,
  ) => void = () => {
  };
}
