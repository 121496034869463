export enum InstallationElementGroupsEnum {
  Devices,
  Sensors,
  Alarms,
  Scales,
}

export namespace InstallationElementGroupsEnum {
  export function toTranslateKey(value: InstallationElementGroupsEnum): string {
    switch (value) {
      case InstallationElementGroupsEnum.Devices:
        return 'Controller.Installation.Devices';
      case InstallationElementGroupsEnum.Sensors:
        return 'Controller.Installation.Sensors';
      case InstallationElementGroupsEnum.Alarms:
        return 'Controller.Installation.Alarms';
      case InstallationElementGroupsEnum.Scales:
        return 'Controller.Installation.Scales';
      default:
        return (value as unknown).toString();
    }
  }
}
