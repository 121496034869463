export enum ErrorFieldEnum {
  Required = 'required',
  Pattern = 'pattern',
  InvalidPhoneNumber = 'invalidPhoneNumber',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Min = 'min',
  Max = 'max',
  DuplicatedDay = 'duplicatedDay',
  InvalidWeight = 'invalidWeight',
  WeightShouldBeMoreThan = 'weightShouldBeMoreThan',
  DaysDupl = 'daysDupl',
  OuterOverlap = 'outerOverlap',
  InnerOverlap = 'innerOverlap',
  GreaterThan = 'gt',
  LessThan = 'lt',
  Email = 'email',
}
