import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@livestock/shared/environments';
import { Store } from '@ngrx/store';
import { displayActions } from '../+state/display.actions';
import { selectBackendConnection } from '../+state/display.selectors';
import { DisplayStateBackend } from '../+state/display.state';

@Injectable({
  providedIn: 'root',
})
export class AppDisplayClientSseService {
  eventSource: EventSource | null;
  sseEvents$ = new BehaviorSubject(null);
  backendConnection$ = this.store.select(selectBackendConnection);
  private _backendConnection: null | DisplayStateBackend;

  constructor(private readonly _zone: NgZone, private store: Store) {
    this.initSseConnection(`${environment.deviceApiUrl}/sse`);
    this.backendConnection$.subscribe(x => this._backendConnection = x);
  }

  initSseConnection(url: string): void {
    this.eventSource = new EventSource(url);
    this.eventSource.onopen = () => {
      console.log('SSE connection open', url);
    };
    this.eventSource.onmessage = (event: MessageEvent) => {
      this._zone.run(() => {
        this.sseEvents$.next(event.data);
      });
    };
    this.eventSource.onerror = error => {
      console.error('SSE Error', error);
      if (this._backendConnection !== null) {
        this._zone.run(() => {
          this.store.dispatch(displayActions.backendConnection(null));

        });
      }
    };
  }
}
