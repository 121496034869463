import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  delay,
  EMPTY,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { Store } from '@ngrx/store';
import * as upsertControllerActions from './upsert-controller.actions';
import * as upsertControllerSelectors from './upsert-controller.selectors';
import { IDateTimeSettingsView,
} from '@livestock/controllers/interfaces';
import { TicketControllersService } from '../../services/ticket-controller.service';
import {
  selectActiveControllerID,
} from '../current-controller/current-controller.selectors';
import {
  selectDateTimeSettings,
  selectGeneralSettings,
  selectNetworkSettings,
  selectLanguage,
} from './upsert-controller.selectors';
import { DialogsService } from '@livestock/shared/services';
import { TimeUtils } from '@livestock/shared/utils';
import { ITicketQrCodeInfo } from '../../interfaces/controller/ticket-qr-code-info.interface';
import { QuickStartService } from '../../services/quick-start.service';
import { NetworkService } from '../../services/network.service';
import { ControllerSettingsService } from '../../services/controller-settings.service';
import { ControllerLanguageEnum } from '@livestock/controllers/enums';

@Injectable()
export class UpsertControllerEffects {

  checkIfControllerIsAssignedWithFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.checkIfControllerIsAssignedWithFarm),
      withLatestFrom(
        this.store.select(upsertControllerSelectors.selectTicketQrCodeInfo),
      ),
      switchMap(([_, ticketQrCodeInfo]: [any, ITicketQrCodeInfo]) => {
        return this.ticketControllersService.getAddControllerByTicketID(ticketQrCodeInfo.qrCodeData.ticketID).pipe(
          map(() => {
            return upsertControllerActions.setIsControllerAssignedWithFarm({
              isControllerAssignedWithFarm: false,
            });
          }),
          catchError(() => {
            return of(upsertControllerActions.setIsControllerAssignedWithFarm({
              isControllerAssignedWithFarm: true,
            }));
          }),
        );
      }),
    ),
  );

  createGeneralSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createGeneralSettingsViaDevice),
      withLatestFrom(
        this.store.select(selectGeneralSettings),
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([_action, generalSettings, controllerID]) => {
        return this.quickStartService.createGeneralSettingsViaDevice(controllerID, generalSettings).pipe(
          map(() => upsertControllerActions.createGeneralSettingsViaDeviceSuccess()),
          catchError((error) => of(upsertControllerActions.createGeneralSettingsViaDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  createDateTimeSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createDateTimeSettingsViaDevice),
      withLatestFrom(
        this.store.select(selectDateTimeSettings),
        this.store.select(selectActiveControllerID),
        this.store.select(selectLanguage),
      ),
      switchMap(([action, dateTimeSettings, controllerID, language]) => {
        const date = language === ControllerLanguageEnum.EngUS
          ? dateTimeSettings.date
          : TimeUtils.usualDateFormatToUSADateFormat(dateTimeSettings.date);

        const updatedDateTimeSettings = {
          ...dateTimeSettings,
          date,
        };

        return this.quickStartService.createDateTimeSettingsViaDevice(controllerID, updatedDateTimeSettings).pipe(
          map(() => upsertControllerActions.createDateTimeSettingsViaDeviceSuccess({ isFinish: action?.isFinish })),
          catchError((error) => of(upsertControllerActions.createDateTimeSettingsViaDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  createNetworkSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createNetworkSettingsViaDevice),
      withLatestFrom(
        this.store.select(selectNetworkSettings),
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([_action, networkSettings, controllerID]) => {
        return this.quickStartService.createNetworkSettingsViaDevice(controllerID, networkSettings).pipe(
          map(() => upsertControllerActions.createNetworkSettingsViaDeviceSuccess()),
          catchError((error) => of(upsertControllerActions.createNetworkSettingsViaDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  getLanSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getLanSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([_action, controllerID]) => {
        return this.networkService.getLanSettings(controllerID).pipe(
          map((settings) => upsertControllerActions.getLanSettingsSuccess(settings)),
          catchError((error) => of(upsertControllerActions.getLanSettingsError({ payload: error }))),
        );
      }),
    ));

  updateLanSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.updateLanSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([{ lan: isActive }, controllerID]) => {
        return this.networkService.updateLanSettings(controllerID, Boolean(isActive)).pipe(
          map((value) => upsertControllerActions.updateLanSettingsSuccess(value)),
          catchError((error) => of(upsertControllerActions.updateLanSettingsError({ payload: error, lan: isActive }))),
        );
      }),
    ),
  );

  getWifiListViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getWifiList),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) => {
        return this.networkService.getWifiList(controllerID).pipe(
          map((connections) => upsertControllerActions.getWifiListSuccess(connections)),
          catchError((error) => of(upsertControllerActions.getWifiListError({ payload: error }))),
        );
      }),
    ));

  connectWifiViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.connectWifi),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ wifiData }, controllerID]) => {
        return this.networkService.connectWifi(controllerID, wifiData).pipe(
          map((wifiConnectionResponse) => upsertControllerActions.connectWifiSuccess({
            wifiConnectionResponse,
            wifiNetwork: wifiData,
            name: wifiData.name,
          })),
          catchError((error) => of(upsertControllerActions.connectWifiError({ payload: error }))),
        );
      }),
    ),
  );

  disconnectWifiViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.disconnectWifi),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) => {
        return this.networkService.disconnectWifi(controllerID).pipe(
          map(() => upsertControllerActions.disconnectWifiSuccess()),
          catchError((error) => of(upsertControllerActions.disconnectWifiError(error))),
        );
      }),
    ),
  );

  connectCellularModem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.connectModem),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ modemData }, controllerID]) => {
        return this.networkService.connectModem(controllerID, modemData).pipe(
          map((modemConnectionResponse) => upsertControllerActions.connectModemSuccess({ modemConnectionResponse })),
          catchError((error) => of(upsertControllerActions.connectModemError({ payload: error }))),
        );
      }),
    ),
  );

  disconnectCellularModem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.disconnectModem),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) => {
        return this.networkService.disconnectModem(controllerID).pipe(
          map(() => upsertControllerActions.disconnectModemSuccess()),
          catchError((error) => of(upsertControllerActions.disconnectModemError({ payload: error }))),
        );
      }),
    ),
  );

  getNetworkStatusViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getNetworkStatus),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ overrideSettingsState }, controllerID]) =>
        this.networkService.getNetworkStatus(controllerID).pipe(
          mergeMap((networkStatus) => {
            const actions: any[] = [
              upsertControllerActions.getNetworkStatusSuccess({ networkStatus, overrideSettingsState }),
            ];

            if (overrideSettingsState && networkStatus.connectionSettings?.wifi?.isActive) {
              actions.push(upsertControllerActions.getWifiList());
            }

            return actions;
          }),
          catchError((error) => of(upsertControllerActions.getNetworkStatusError({ payload: error }))),
        ),
      ),
    ),
  );

  getQuickstartStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getQuickstartStatus),
      switchMap(() => {
        return this.quickStartService.getQuickstartStatus().pipe(
          map((_status: string) => upsertControllerActions.getQuickstartStatusSuccess()),
          catchError((error) => of(upsertControllerActions.getQuickstartStatusError({ payload: error }))),
        );
      }),
    ),
  );

  setQuickstartStatusStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.setQuickstartStatusStep),
      switchMap(({ step }) => {
        return this.quickStartService.setQuickstartStatusStep(step).pipe(
          map(() => upsertControllerActions.setQuickstartStatusStepSuccess()),
          catchError((error) => of(upsertControllerActions.setQuickstartStatusStepError({ payload: error }))),
        );
      }),
    ),
  );

  getControllerSerialNumberDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        upsertControllerActions.getControllerSerialNumberDevice,
        upsertControllerActions.createDateTimeSettingsViaDeviceSuccess,
      ),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap((_action, controllerID) => {
        return this.quickStartService.getControllerSerialNumber(controllerID).pipe(
          map(({ serialNumber }: { serialNumber: string }) => {
            return upsertControllerActions.getControllerSerialNumberDeviceSuccess({
              serialNumber,
            });
          }),
          catchError((error) => of(upsertControllerActions.getControllerSerialNumberDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  getTicketInfoFromDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getTicketQrCodeInfoFromDevice),
      switchMap(() => {
        return this.quickStartService.getControllerQrCodeInfo().pipe(
          map((ticketQrCodeInfo: ITicketQrCodeInfo) => {
            return upsertControllerActions.getTicketQrCodeInfoFromDeviceSuccess({
              ticketQrCodeInfo,
            });
          }),
          catchError((error) => {
            return of(upsertControllerActions.getTicketQrCodeInfoFromDeviceError({ payload: error }));
          }),
        );
      }),
    ),
  );

  onCloudConnectionRequest$ = createEffect(() => this.actions$.pipe(
    ofType(upsertControllerActions.setCloudConnectionRequest),
    switchMap(({ farm, email, showPopup }) => {
      if (showPopup) {
        return this.dialogService.showCloudConnectionRequest(farm, email).pipe(
          map(result => {
            return result ? upsertControllerActions.approveCloudConnectionRequest() : upsertControllerActions.rejectCloudConnectionRequest();
          }),
        );
      }

      return EMPTY;
    }),
  ));

  onApproveCloudConnectionRequest$ = createEffect(() => this.actions$.pipe(
    ofType(upsertControllerActions.approveCloudConnectionRequest),
    withLatestFrom(this.store.select(selectActiveControllerID)),
    switchMap(([_action]) => { // controllerID
      // TODO: Request will be used in future
      return of(upsertControllerActions.approveCloudConnectionRequestSuccess());
      // return this.controllersService.approveCloudConnection(controllerID).pipe(
      //   map(() => upsertControllerActions.approveCloudConnectionRequestSuccess()),
      //   catchError(error => of(upsertControllerActions.approveCloudConnectionRequestError(error))),
      // );
    }),
  ));

  onRejectCloudConnectionRequest$ = createEffect(() => this.actions$.pipe(
    ofType(upsertControllerActions.rejectCloudConnectionRequest),
    withLatestFrom(this.store.select(selectActiveControllerID)),
    switchMap(([_action, controllerID]) => {
      return this.networkService.rejectCloudConnection(controllerID).pipe(
        map(() => upsertControllerActions.rejectCloudConnectionRequestSuccess()),
        catchError(() => of(upsertControllerActions.rejectCloudConnectionRequestError())),
      );
    }),
  ));

  onDisconnectCloud$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.disconnectCloud),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) =>
        this.networkService.disconnectCloudConnection(controllerID).pipe(
          delay(15000), // A very optimistic delay to wait for the communication between controller and VCT to complete...
          concatMap(() => [
            upsertControllerActions.disconnectCloudSuccess(),
            upsertControllerActions.getTicketQrCodeInfoFromDevice(),
          ]),
          catchError(() => of(upsertControllerActions.disconnectCloudError())),
        ),
      ),
    ),
  );

  getDateTimeSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        upsertControllerActions.getDateTimeSettings,
        upsertControllerActions.approveCloudConnectionRequestSuccess,
        upsertControllerActions.disconnectCloudSuccess,
      ),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) =>
        this.controllerSettingsService.getDateTimeSettings(controllerID).pipe(
          map((dateTimeSettings: IDateTimeSettingsView) => upsertControllerActions.getDateTimeSettingsSuccess({ dateTimeSettings }),
            catchError(() => of(upsertControllerActions.disconnectCloudError())),
          ),
        ),
      ),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private quickStartService: QuickStartService,
    private networkService: NetworkService,
    private controllerSettingsService: ControllerSettingsService,
    private ticketControllersService: TicketControllersService,
    private dialogService: DialogsService,
  ) {
  }
}
