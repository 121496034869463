import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChangeHouseModeStepsEnum, HouseModeEnum } from '../../enums';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleDropdownComponent, SlimButtonComponent } from '@livestock/ui';
import { ButtonTypeEnum } from '@livestock/shared/enums';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NewFlockDialogComponent } from '../new-flock-dialog/new-flock-dialog.component';

@Component({
  selector: 'lv-change-house-mode-dialog',
  templateUrl: './change-house-mode-dialog.component.html',
  styleUrls: ['./change-house-mode-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, SlimButtonComponent, SimpleDropdownComponent, ReactiveFormsModule],
})
export class ChangeHouseModeDialogComponent {
  form = new FormGroup({
    houseMode: new FormControl<HouseModeEnum>(null),
  });
  currentStep: ChangeHouseModeStepsEnum = ChangeHouseModeStepsEnum.Warning;

  ChangeHouseModeStepsEnum = ChangeHouseModeStepsEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  HouseModeEnum = HouseModeEnum;

  constructor(
    private dialogRef: MatDialogRef<ChangeHouseModeDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { currentHouseMode: HouseModeEnum },
  ) {
    this.form.controls.houseMode.setValue(data.currentHouseMode);
  }

  handleChangeHouseModeStep(currentHouseMode: HouseModeEnum, newHouseMode: HouseModeEnum): void {
    if (currentHouseMode === newHouseMode) return;

    if (currentHouseMode === HouseModeEnum.Growing && newHouseMode === HouseModeEnum.PreHeat) {
      this.currentStep = ChangeHouseModeStepsEnum.ChangeFlock;
      return;
    }

    if (currentHouseMode === HouseModeEnum.PreHeat && newHouseMode === HouseModeEnum.Growing) {
      this.currentStep = ChangeHouseModeStepsEnum.ChangeFlock;
      return;
    }

    if (currentHouseMode === HouseModeEnum.Empty && (newHouseMode === HouseModeEnum.Growing || newHouseMode === HouseModeEnum.PreHeat)) {
      this.currentStep = ChangeHouseModeStepsEnum.ChangeFlock;
      return;
    }

    if (currentHouseMode === HouseModeEnum.Cleaning && (newHouseMode === HouseModeEnum.Growing || newHouseMode === HouseModeEnum.PreHeat)) {
      this.currentStep = ChangeHouseModeStepsEnum.ChangeFlock;
      return;
    }

    if (currentHouseMode === HouseModeEnum.Catching && newHouseMode === HouseModeEnum.Growing) {
      this.currentStep = ChangeHouseModeStepsEnum.ChangeFlock;
      return;
    }

    this.dialogRef.close({ houseMode: newHouseMode });
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  closeWithValue(newHouseMode): void {
    this.dialogRef.close({ houseMode: newHouseMode });
  }

  openNewFlockDialog(): void {
    this.dialog.open(NewFlockDialogComponent, {
      disableClose: true,
      panelClass: 'transparent-bg',
      backdropClass: 'blurred-background',
    });

    this.closePopup();
  }
}
