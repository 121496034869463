import { NetworkConnectionTypesEnum } from '@livestock/controllers/enums';
import { IInternetConnectionStatus } from './internet-connection-status.interface';
import { IWifiConnection } from './wifi-connection-view.interface';

export interface IAllStatuses {
  connectionSettings: {
    lan: IInternetConnectionStatus;
    wifi: IInternetConnectionStatus;
    modem: IInternetConnectionStatus;
  },
  currentConnection: NetworkConnectionTypesEnum,
  wifi?: IWifiConnection,
  modem?: {
    name: string;
    signal: number;
  },
  isOnline?: boolean,
}
