export enum HouseModeEnum {
  Empty,
  PreHeat,
  Cleaning,
  Growing,
  Catching,
}

export namespace HouseModeEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case HouseModeEnum.Growing:
        return 'Flock.HouseModeEnum.Growing';
      case HouseModeEnum.Empty:
        return 'Flock.HouseModeEnum.Empty';
      case HouseModeEnum.PreHeat:
        return 'Flock.HouseModeEnum.Preheat';
      case HouseModeEnum.Cleaning:
        return 'Flock.HouseModeEnum.Cleaning';
      case HouseModeEnum.Catching:
        return 'Flock.HouseModeEnum.Catching';
      default:
        return value.toString();
    }
  }
}
