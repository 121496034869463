import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ControllerLanguageEnum, ControllerLanguageEnumShortList } from '@livestock/controllers';
import { filter, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectCurrentUser,
  selectCurrentUserFullName,
  selectCurrentUserInitials, selectCurrentUserIsLoading,
} from '../../+state/current-user.selectors';
import {
  LoadingGalconComponent,
  LVInputComponent,
  LVInputIntegerWithLabelComponent, LvInputWithSaveBtnComponent,
  SimpleDropdownComponent, SlimButtonComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { ButtonTypeEnum, ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ICurrentUserView } from '../../interfaces/current-user-view';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@livestock/auth';
import { PasswordValidator, PhoneNumberValidator, RequiredTrimValidator } from '@livestock/shared/validators';
import { GlobalConstants } from '@livestock/shared/constants';
import { Clipboard } from '@angular/cdk/clipboard';
import { PasswordGeneratorUtils } from '@livestock/shared/utils';
import { updateCurrentUser, updateCurrentUserPassword } from '../../+state/current-user.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lv-current-user-info',
  templateUrl: 'current-user-info.component.html',
  styleUrls: ['current-user-info.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LVInputIntegerWithLabelComponent,
    ReactiveFormsModule,
    SvgIconComponent,
    AsyncPipe,
    SimpleDropdownComponent,
    TranslateModule,
    LVInputComponent,
    LvInputWithSaveBtnComponent,
    SlimButtonComponent,
    LoadingGalconComponent,
  ],
})
export class CurrentUserInfoComponent implements OnInit, OnDestroy {
  @ViewChild('password') passwordRef: ElementRef;

  @Output() closeUserPopup = new EventEmitter();

  // inject
  private store: Store = inject(Store);
  private clipboard: Clipboard = inject(Clipboard);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private authService: AuthService = inject(AuthService);

  // subs
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectCurrentUserIsLoading);
  currentUserInitials$: Observable<string> = this.store.select(selectCurrentUserInitials);
  currentUserFullName$: Observable<string> = this.store.select(selectCurrentUserFullName);

  // vars
  form = new FormGroup({
    email: new FormControl<string>({ value: '', disabled: true }),
    fullName: new FormControl<string>('', [RequiredTrimValidator, Validators.maxLength(GlobalConstants.MaxLength)]),
    mobile: new FormControl<string>('', PhoneNumberValidator),
    language: new FormControl<ControllerLanguageEnum>(ControllerLanguageEnum.EngUS),
  });
  passwordForm = new FormGroup({
    password: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(8),
      PasswordValidator,
    ]),
  });
  initialFormValue: Partial<ICurrentUserView>;
  resetPasswordMode: boolean;

  // enums
  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;
  ControllerLanguageEnum = ControllerLanguageEnum;
  ControllerLanguageEnumShortList = ControllerLanguageEnumShortList;
  GlobalConstants = GlobalConstants;
  ButtonTypeEnum = ButtonTypeEnum;

  get formWasChanged(): boolean {
    return JSON.stringify(this.initialFormValue) !== JSON.stringify(this.form.getRawValue());
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectCurrentUser).pipe(
        filter(res => res != null),
      ).subscribe(currentUser => {
        this.initialFormValue = {
          email: currentUser.email,
          fullName: currentUser.fullName,
          mobile: currentUser.mobile || '',
          language: currentUser.language,
        };

        this.form.patchValue(this.initialFormValue);
      }),
    );
  }

  toggleResetPasswordMode(): void {
    this.resetPasswordMode = !this.resetPasswordMode;
  }

  generatePassword(event: Event): void {
    event.stopPropagation();
    const password = PasswordGeneratorUtils.generatePassword(GlobalConstants.STRONG_PASSWORD_LENGTH);
    this.passwordForm.controls.password.setValue(password);
    this.passwordForm.controls.password.markAsDirty();
    this.passwordForm.controls.password.updateValueAndValidity();
  }

  copyPassword(event: Event): void {
    event.stopPropagation();
    this.clipboard.copy(this.passwordForm.controls.password.value);
  }

  logOut(): void {
    this.authService.onLogout()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  updateCurrentUser(): void {
    this.store.dispatch(updateCurrentUser({
      currentUser: {
        fullName: this.form.value.fullName,
        mobile: this.form.value.mobile,
        language: this.form.value.language,
      },
    }));
  }

  saveNewPassword(): void {
    this.store.dispatch(updateCurrentUserPassword({ password: this.passwordForm.value.password }));
    this.toggleResetPasswordMode();
    this.passwordForm.controls.password.setValue('');
    this.passwordForm.controls.password.markAsPristine();
    this.passwordForm.updateValueAndValidity();
  }

  closeUserPopupEmit(): void {
    this.closeUserPopup.emit();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
