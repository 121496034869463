import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  inject,
  OnInit,
  ChangeDetectorRef, DestroyRef,
} from '@angular/core';
import { ButtonTypeEnum, ElementTypesEnum, IconsEnum } from '@livestock/shared/enums';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  AsRelayDigitalOutputSetupComponent,
  AuxiliaryInputSetupComponent,
  Co2Component,
  CoolingSetupComponent,
  deleteElementSetup,
  ElementEventInfo,
  ElementWrapperHelper,
  IndoorHumidityComponent,
  IndoorTemperatureSetupComponent,
  LightAnalogOutputSetupComponent,
  OutdoorHumidityComponent,
  OutdoorTemperatureSetupComponent,
  selectElementEventInfo,
  selectIsLoading,
  StaticPressureSetupComponent,
  updateElementSetup, updateElementSetupSuccess,
  WaterMeterSetupComponent,
} from '@livestock/installation';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingGalconComponent, SlimButtonComponent, SvgIconComponent } from '@livestock/ui';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { FormsService, setIsFormEditing } from '@livestock/forms';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LightDIgitalOutputSetupComponent } from '../elements/light-do/light-do.component';
import { PlatformService } from '@livestock/shared/services';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SlimButtonComponent,
    IndoorTemperatureSetupComponent,
    OutdoorTemperatureSetupComponent,
    Co2Component,
    IndoorHumidityComponent,
    OutdoorHumidityComponent,
    StaticPressureSetupComponent,
    WaterMeterSetupComponent,
    AuxiliaryInputSetupComponent,
    CoolingSetupComponent,
    LoadingGalconComponent,
    MemoizeFuncPipe,
    SvgIconComponent,
    LightDIgitalOutputSetupComponent,
    LightAnalogOutputSetupComponent,
    AsRelayDigitalOutputSetupComponent,
  ],
  selector: 'lv-element-wrapper',
  templateUrl: './element-wrapper.component.html',
  styleUrls: ['./element-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementWrapperComponent implements OnInit {
  @Input() isEditMode: boolean = false;
  @Output() isChangedEvent = new EventEmitter<boolean>();
  @Output() unsetEditMode = new EventEmitter();

  private store: Store = inject(Store);
  private actions$: Actions = inject(Actions);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private formsService: FormsService = inject(FormsService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  protected platformService: PlatformService = inject(PlatformService);

  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  elementEvent$: Observable<(elementType: ElementTypesEnum, elementNumber: number, isDeviceApp: boolean) => ElementEventInfo> =
    this.store.select(selectElementEventInfo);

  isFormChanged: boolean;
  isFormValid: boolean;
  isTestMode: boolean;
  initialFormValue: object;
  isSavedSuccessfully: boolean;
  private _currentElementType: ElementTypesEnum;

  get currentElementType(): ElementTypesEnum {
    return this._currentElementType;
  }

  @Input() set currentElementType(value: ElementTypesEnum) {
    this._currentElementType = value;
    this.childGroupName = this.helper.childFormGroupNameByType(value);
  }

  childGroupName: string;
  parentForm: FormGroup = new FormGroup({});
  helper = ElementWrapperHelper;

  // enums
  ButtonTypeEnum = ButtonTypeEnum;
  ElementTypesEnum = ElementTypesEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;

  ngOnInit(): void {
    this.actions$.pipe(
      ofType(updateElementSetupSuccess),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.isSavedSuccessfully = true;
      this.unsetEditMode.emit();
      this.store.dispatch(setIsFormEditing({ isEditing: false }));

      setTimeout(() => {
        this.isSavedSuccessfully = false;
        this.cdr.detectChanges();
      }, 2000);
    });
  }

  isChanged(isChanged: boolean): void {
    this.isFormChanged = isChanged;
    this.isChangedEvent.emit(isChanged);
  }

  isValid(isValid: boolean): void {
    this.isFormValid = isValid;
  }

  disableSaveButton([isFormValid, isFormChanged, isEditMode, isTestMode, isLoading]): boolean {
    return !isFormValid || !isFormChanged || !isEditMode || isTestMode || isLoading;
  }

  async save(): Promise<void> {
    if (this.parentForm.invalid) {
      return;
    }

    const elementSetup = this.parentForm.getRawValue()[this.childGroupName];
    this.store.dispatch(updateElementSetup({ elementSetup }));
  }

  async delete(): Promise<void> {
    // TODO: only sensor element popup for now
    const canDeleteElement = await firstValueFrom(this.formsService.confirmDeletingSensorElement());
    if (!canDeleteElement) {
      return;
    }

    this.store.dispatch(deleteElementSetup());
  }

  changeTestMode(isTestMode: boolean): void {
    this.isTestMode = isTestMode;
  }
}
