export enum IconsInstallationEnum {
  AMMONIA = 'installation/ammonia',
  CO2 = 'installation/co2',
  COOLING = 'installation/cooling',
  DEVICE_IN = 'installation/device-in',
  DEVICE_OUT = 'installation/device-out',
  FAN = 'installation/fan',
  HEATING = 'installation/heating',
  INDOOR_HUMIDITY = 'installation/indoor-humidity',
  INDOOR_TEMPERATURE = 'installation/indoor-temperature',
  INLETS = 'installation/inlets',
  LIGHTS = 'installation/lights',
  NIPPLE_FLUSHING = 'installation/nipple-flushing',
  OUTDOOR_HUMIDITY = 'installation/outdoor-humidity',
  OUTDOOR_TEMPERATURE = 'installation/outdoor-temperature',
  PRESSURE = 'installation/pressure',
  SPRINKLES = 'installation/sprinkles',
  STIR_FANS = 'installation/stir-fans',
  TIMERS = 'installation/timers',
  TUNNEL_DOORS = 'installation/tunnel-doors',
  WATER_MAIN = 'installation/water-main',
  WATER_METER = 'installation/water-meter',
  ADD_ELEMENT_PLUS = 'installation/add-element-plus',
  UNINSTALL_ELEMENT = 'installation/uninstall-element',
  INDOOR_TEMPERATURE_STATUS = 'installation/indoor-temperature-status',
  OUTDOOR_TEMPERATURE_STATUS = 'installation/outdoor-temperature-status',
  OFFSET = 'installation/offset',
  OUTDOOR_HUMIDITY_STATUS = 'installation/outdoor-humidity-status',
  INDOOR_HUMIDITY_STATUS = 'installation/indoor-humidity-status',
  AUXILIARY_INPUT = 'installation/auxiliary-input',
  CLOSE_TEST = 'installation/close-test',
  TEST_STATUS = 'installation/test-status',
}
