import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, forkJoin, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as authActions from './auth.actions';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LocalStorageService, MenuService, PlatformService } from '@livestock/shared/services';
import { ILoginResponseModel } from '../interfaces/login-response.interface';
import { ILoginData } from '../interfaces/login-data.interface';
import { Store } from '@ngrx/store';
import { selectTicketId } from '@livestock/parring-process';
import { AppRoutes } from '@livestock/shared/routes';
import { StorageItem } from '@livestock/shared/enums';
import { BiometryService } from '../services/biometry.service';
import { setFlashMessage } from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';
import { WebSocketsService } from '@livestock/web-sockets';
import { setMobileViewMode, MobileViewModeEnum } from '@livestock/layout';
import { acceptExistingUserToFarm, selectAddUserToFarmTicketView, setAddUserToFarmTicketView } from '@livestock/farms';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      map((action) => action.payload),
      switchMap((loginData) => {
        return forkJoin([
          this.authService.login(loginData),
          of(loginData),
        ]).pipe(
          switchMap(
            async ([loginResponseModel, credentials]: [ILoginResponseModel, ILoginData]) => {

              try {
                if (this.platformService.isNative && !(await this.biometryService.isBiometrySetup())) {
                  if (await this.biometryService.setCredentialsWithBiometry(credentials.login, credentials.password)) {
                    this.store.dispatch(
                      setFlashMessage({
                        flashMessage: {
                          flashType: FlashMessageTypeEnum.Success,
                          message: 'FlashMessages.BiometrySetupSuccess',
                        },
                      }),
                    );
                  }
                }
              } catch (error) {
                console.error(error);
                this.store.dispatch(
                  setFlashMessage({
                    flashMessage: {
                      flashType: FlashMessageTypeEnum.Error,
                      message: 'FlashMessages.BiometrySetupFailure',
                    },
                  }),
                );
              }

              return authActions.loginSuccess({
                token: loginResponseModel.token,
                credentials,
              });
            },
          ),
          catchError((error) => of(authActions.loginError({ payload: error }))),
        );
      }),
    ),
  );

  onLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginSuccess),
      tap((action) => {
        if (!action.token) {
          return;
        }
        LocalStorageService.setStorageItem(StorageItem.Token, action.token);
      }),
      withLatestFrom(
        this.store.select(selectTicketId),
        this.store.select(selectAddUserToFarmTicketView),
      ),
      switchMap(([action, parringControllerTicketId, addUserToFarmTicketView]) => {
        let route = AppRoutes.FARMS;
        if (parringControllerTicketId) {
          this.store.dispatch(setMobileViewMode({ mobileViewMode: MobileViewModeEnum.Lobby }));
          route = AppRoutes.SCANNED_LOGIN;
        }
        if (action.credentials.login === addUserToFarmTicketView?.email) {
          this.store.dispatch(acceptExistingUserToFarm({ ticketID: addUserToFarmTicketView.ticketID }));
        } else if (addUserToFarmTicketView) {
          this.store.dispatch(setAddUserToFarmTicketView({ addUserToFarmTicketView: null }));
        }

        this.router.navigate([route]);
        return EMPTY;
      }),
    ), { dispatch: false },
  );

  onLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logOut),
        switchMap(({ paramsHandling, cancelRedirectToLogin }) => {
          return this.authService.logout().pipe(
            map(() => {
              this.menuService.clear();

              if (!cancelRedirectToLogin) {
                this.authService.goToLogin(paramsHandling);
              } else {
                this.authService.clearLocalStorage();
              }

              this.webSocketService.roomsLeaveAll();
              return authActions.logOutSuccess();
            }),
            catchError((e) => {
              this.menuService.clear();
              this.authService.goToLogin(paramsHandling);
              this.webSocketService.roomsLeaveAll();
              console.error(e);
              return of(authActions.logOutError({ payload: e }));
            }),
          );
        }),
      ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private authService: AuthService,
    private biometryService: BiometryService,
    private router: Router,
    private menuService: MenuService,
    private platformService: PlatformService,
    private webSocketService: WebSocketsService,
  ) {
  }
}
