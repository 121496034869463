import { IModemSettings } from './modem-settings.interface';
import { IWifiSettings } from './wifi-settings.interface';
import { IWifiConnection } from './wifi-connection-view.interface';
import { ILanSettings } from './lan-settings.interface';

export interface INetworkSettingsView extends IModemSettings, IWifiSettings, ILanSettings {
  controllerID?: number;
  wifiConnectionsList?: IWifiConnection[];
  wifiLoading?: boolean;
  cellularLoading?: boolean;
  connectionInProgress?: boolean;
  modemSignal?: number;
}
