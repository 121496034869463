import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as installationActions from './installation.actions';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { InstallationService } from '../services/installation.service';
import { IElement, IGetOrUpdateElement } from '../interfaces';
import { Store } from '@ngrx/store';
import { getCardsSuccess, selectActiveControllerID, selectControllerInfo } from '@livestock/controllers';
import { PlatformService } from '@livestock/shared/services';
import { selectCurrentElementType } from './installation.selectors';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { UnitsHelper } from '@livestock/shared/utils';
import { addWebSocketEvents, EventCodeEnum } from '@livestock/web-sockets';
import { setFlashMessage } from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';

@Injectable()
export class InstallationEffects {
  getAllElements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        installationActions.getAllElements,
        getCardsSuccess,
      ),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_, controllerID]) => this.installationService.getAllElements(controllerID).pipe(
        map((elements: IElement[]) => installationActions.getAllElementsSuccess({ elements })),
        catchError((e) => of(installationActions.getAllElementsError(e))),
      )),
    ),
  );

  getElementSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.getElementSetup),
      withLatestFrom(
        this.store.select(selectCurrentElementType),
        this.store.select(selectActiveControllerID),
        this.store.select(selectControllerInfo),
      ),
      switchMap(([_, elementType, controllerID, controllerInfo]) => {
        const connectionNumber = ElementTypesEnum.getConnectionNumber(elementType);

        return this.installationService.getElementSetup(1, connectionNumber, 1, elementType, controllerID).pipe(
          map((elementSetup: IGetOrUpdateElement) => {

            elementSetup = UnitsHelper.recalculateElementSetupToImperial(elementSetup, elementType, controllerInfo);
            return installationActions.getElementSetupSuccess({ elementSetup });
          }),
          catchError((e) => of(installationActions.getElementSetupError(e))),
        );
      }),
    ),
  );

  updateElementSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.updateElementSetup),
      withLatestFrom(
        this.store.select(selectCurrentElementType),
        this.store.select(selectActiveControllerID),
        this.store.select(selectControllerInfo),
      ),
      switchMap(([{ elementSetup }, elementType, controllerID, controllerInfo]) => {
        const connectionNumber = ElementTypesEnum.getConnectionNumber(elementType);
        const metricElementSetup = UnitsHelper.recalculateElementSetupToMetric(elementSetup, elementType, controllerInfo);

        return this.installationService.updateElementSetup(1, connectionNumber, 1, elementType, controllerID, metricElementSetup).pipe(
          switchMap(() => [
            installationActions.updateElementSetupSuccess({ elementSetup }),
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'FlashMessages.ElementWasSuccessfullyUpdated',
              },
            }),
          ]),
          catchError((e) => of(installationActions.updateElementSetupError(e))),
        );
      }),
    ),
  );

  runElementEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.runElementEvents),
      withLatestFrom(
        this.store.select(selectCurrentElementType),
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([{ numbers }, elementType, controllerID]) => {
        const connectionNumber = ElementTypesEnum.getConnectionNumber(elementType);

        if (this.platformService.isDeviceApp) {
          return this.installationService.runElementsViaDevice(1, connectionNumber, 1, elementType).pipe(
            switchMap((elementInfo) => {
              console.log('elementInfo: ', elementInfo);
              return [
                installationActions.runElementEventsViaDeviceSuccess({ elementInfo, elementType }),
                addWebSocketEvents({
                  payload: [{
                    ec: EventCodeEnum.Element,
                    controlUnitID: controllerID,
                    controllerType: 1,
                    sn: '',
                    dataEvent: {
                      number: 1,
                      elementType,
                      status: elementInfo.status,
                      value: elementInfo.value,
                    },
                  }],
                }),
              ];
            }),
            catchError((e) => of(installationActions.runElementEventsError(e))),
          );
        }

        return this.installationService.runElementEvents(controllerID, {
          elementType,
          numbers,
        }).pipe(
          map(() => installationActions.runElementEventsSuccess()),
          catchError((e) => of(installationActions.runElementEventsError(e))),
        );
      }),
    ),
  );

  runElementTests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.runElementTests),
      withLatestFrom(
        this.store.select(selectCurrentElementType),
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([{ elementID, response }, elementType, controllerID]) => {
        return this.installationService.runElementTests(controllerID, elementID, elementType, response).pipe(
          map(() => installationActions.runElementTestsSuccess()),
          catchError((e) => of(installationActions.runElementTestsError(e))),
        );
      }),
    ),
  );

  // device only
  createElementSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.createElementSetup),
      withLatestFrom(
        this.store.select(selectControllerInfo),
      ),
      switchMap(([{ cardNumber, connectionNumber, elementNumber, elementType }, controllerInfo]) => {
        return this.installationService.createElementSetup(
          cardNumber,
          connectionNumber,
          elementNumber,
          elementType,
        ).pipe(
          switchMap((elementSetup: any) => {
            elementSetup = UnitsHelper.recalculateElementSetupToImperial(elementSetup, elementType, controllerInfo);
            return [
              installationActions.createElementSetupSuccess({ elementSetup, elementType }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.ElementWasSuccessfullyCreated',
                },
              }),
            ];
          }),
          catchError((e) => of(installationActions.createElementSetupError(e))),
        );
      }),
    ),
  );

  // device only
  deleteElementSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.deleteElementSetup),
      withLatestFrom(this.store.select(selectCurrentElementType)),
      switchMap(([_, elementType]) => {
        const connectionNumber = ElementTypesEnum.getConnectionNumber(elementType);

        return this.installationService.deleteElementSetup(1, connectionNumber, 1, elementType).pipe(
          switchMap(() => [
            installationActions.deleteElementSetupSuccess({ elementType }),
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'FlashMessages.ElementWasSuccessfullyDeleted',
              },
            }),
          ]),
          catchError((e) => of(installationActions.deleteElementSetupError(e))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private platformService: PlatformService,
    private installationService: InstallationService,
  ) {
  }
}
