import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import {
  IControllerView,
  ICreateControllerView,
  ICreateAddControllerTicketView,
} from '@livestock/controllers/interfaces';
import {
  ElementTypesEnum, PageTypeEnum,
} from '@livestock/shared/enums';
import {
  IReconnectController,
} from '@livestock/controllers/interfaces';
import { PlatformService } from '@livestock/shared/services';
import { baseUrl, deviceControllerUrl } from './basic-api-url.constants';

@Injectable({
  providedIn: 'root',
})
export class ControllerInfoService {
  private http: HttpClient = inject(HttpClient);
  private platformService: PlatformService = inject(PlatformService);

  createController(controller: ICreateControllerView): Observable<void> {
    return this.http.post<void>(baseUrl, controller);
  }

  updateController(controllerID: number, requestView: IControllerView): Observable<void> {
    return this.http.put<void>(`${baseUrl}/${controllerID}`, requestView);
  }

  getController(controllerID: number): Observable<IControllerView> {
    return this.platformService.isDeviceApp
      ? this.http.get<IControllerView>(`${deviceControllerUrl(controllerID)}`)
      : this.http.get<IControllerView>(`${baseUrl}/${controllerID}`);
  }

  deleteController(controllerID: number): Observable<void> {
    return this.http.delete<void>(`${baseUrl}/${controllerID}`);
  }

  getControllerElements(controllerID: number, elementType: ElementTypesEnum): Observable<{ items: any[] }> {
    return this.http.get<any>(`${baseUrl}/${controllerID}/element-type/${elementType}/elements`);
  }

  disconnectController(controllerID: number): Observable<void> {
    return this.http.post<void>(`${baseUrl}/${controllerID}/disconnect`, null);
  }

  reconnectController(controllerID: number, view: IReconnectController): Observable<ICreateAddControllerTicketView> {
    if (this.platformService.isDeviceApp) {
      return this.http.post<ICreateAddControllerTicketView>(`${baseUrl}/${controllerID}/reconnect-controller`, view);
    }

    return this.http.post<ICreateAddControllerTicketView>(`${environment.apiUrl}/ticket/reconnect-controller`, view);
  }

  resendControllerPageCnf(controllerID: number, page: PageTypeEnum): Observable<void> {
    return this.http.get<void>(`${baseUrl}/${controllerID}/real-time/page/${page}/resend-cnf`);
  }
}
