import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, MessageDialogComponent, SelectDialogComponent } from '@livestock/ui';
import { DialogButtonEnum, AlignmentEnum } from '@livestock/shared/enums';
import { ISelectDialogOptions, SelectDialogCssClass, IConfirmDialogData } from '@livestock/shared/interfaces';
import { Observable, lastValueFrom, tap } from 'rxjs';
import { ConnectionRequestDialogComponent } from '@livestock/controllers';
import { Store } from '@ngrx/store';
import * as layoutActions from '../../layout/src/lib/+state/layout.actions';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(
    private dialog: MatDialog,
    private store: Store,
  ) {
  }

  async error(
    errorKey: string,
    errorTitle: string | null = null,
    iconPath: string = null,
    buttons: DialogButtonEnum[] = [DialogButtonEnum.OK],
    showCloseIcon = true,
    isMessageShouldBeTranslated = true,
  ): Promise<void> {
    await this.showDialog(
      errorKey,
      errorTitle,
      buttons,
      iconPath,
      isMessageShouldBeTranslated,
      true,
      true,
      AlignmentEnum.CENTER,
      AlignmentEnum.CENTER,
      showCloseIcon,
    );
  }

  async ok(
    messageKey: string,
    titleKey = null,
    iconPath: string = null,
    disableClose = true,
  ): Promise<DialogButtonEnum> {
    return await this.showDialog(messageKey, titleKey, [DialogButtonEnum.OK], iconPath, disableClose);
  }

  async question(
    messageKey: string,
    titleKey: string,
    buttons: DialogButtonEnum[],
    iconPath: string = null,
    isMessageShouldBeTranslated = true,
    isTitleShouldBeTranslated = true,
    alignText = AlignmentEnum.CENTER,
    alignButtons = AlignmentEnum.CENTER,
    showCloseIcon = true,
  ): Promise<DialogButtonEnum> {
    return await this.showDialog(messageKey,
      titleKey,
      buttons,
      iconPath,
      isMessageShouldBeTranslated,
      isTitleShouldBeTranslated,
      true,
      alignText,
      alignButtons,
      showCloseIcon,
    );
  }

  async select(options: ISelectDialogOptions, titleKey: string, buttons: DialogButtonEnum[], panelClass: string = 'popup-full__mobile', dialogStyle: SelectDialogCssClass = SelectDialogCssClass.DEFAULT): Promise<any> {
    return await this.showSelectDialog(options, titleKey, buttons, panelClass, dialogStyle);
  }

  private async showSelectDialog(options: ISelectDialogOptions, titleKey: string, buttons: DialogButtonEnum[], panelClass: string, dialogStyle: SelectDialogCssClass): Promise<any> {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      autoFocus: false,
      panelClass,
      disableClose: true,
      enterAnimationDuration: 0,
    });
    const dialog = dialogRef.componentInstance;
    dialog.setup(options, titleKey || 'Livestock', buttons, dialogStyle);
    return await lastValueFrom(dialogRef.afterClosed());
  }

  private async showDialog(
    messageKey: string,
    titleKey: string,
    buttons: DialogButtonEnum[],
    iconPath: string = null,
    isMessageShouldBeTranslated = true,
    isTitleShouldBeTranslated = true,
    disableClose = true,
    alignText = AlignmentEnum.CENTER,
    alignButtons = AlignmentEnum.CENTER,
    showCloseIcon = true,
  ): Promise<DialogButtonEnum> {
    const dialogRef = this.dialog.open(MessageDialogComponent, { disableClose });
    const dialog = <MessageDialogComponent>dialogRef.componentInstance;
    dialog.setup(
      titleKey || 'Livestock',
      messageKey,
      buttons,
      iconPath,
      isMessageShouldBeTranslated,
      isTitleShouldBeTranslated,
      alignText,
      alignButtons,
      showCloseIcon,
    );
    return await lastValueFrom(dialogRef.afterClosed());
  }

  async canContinueAction(): Promise<boolean> {
    const title = 'Livestock.UnsavedChanges';
    const message = 'Livestock.UnsavedChangesMessage';
    const result = await this.question(
      message, title, [DialogButtonEnum.CANCEL, DialogButtonEnum.CONTINUE], 'flash/warning',
    );

    if (!result || result === DialogButtonEnum.CANCEL) {
      return false;
    }

    return true;
  }

  showCloudConnectionRequest(farm: string, email: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConnectionRequestDialogComponent, {
      disableClose: true, data: {
        farm,
        email,
      },
    });
    return dialogRef.afterClosed();
  }

  confirm(data: IConfirmDialogData): Observable<boolean> {
    this.store.dispatch(layoutActions.setIsDialogOpened({ isDialogOpened: true }));

    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      data,
      disableClose: true,
      panelClass: data.panelClass
        ? [`confirm-dialog__panel--${data.type}`, ...data.panelClass]
        : `confirm-dialog__panel--${data.type}`,
      backdropClass: data.backdropClass
        ? ['confirm-dialog__backdrop', ...data.backdropClass]
        : 'confirm-dialog__backdrop',
    });

    return dialogRef.afterClosed().pipe(
      tap(() => {
        this.store.dispatch(layoutActions.setIsDialogOpened({ isDialogOpened: false }));
      }),
    );
  }
}
