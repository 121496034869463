import { Action, createReducer, on } from '@ngrx/store';
import * as flashMessagesActions from './flash-message.actions';
import * as authActions from '@livestock/auth';
import { IFlashMessage } from '../interfaces/flash-message.interface';

export const FLASH_MESSAGE_FEATURE_KEY = 'flashMessage';

export interface FlashMessageState {
  flashMessage: IFlashMessage | null;
}

export const initialState: FlashMessageState = {
  flashMessage: null,
};

const reducer = createReducer(
  initialState,
  on(flashMessagesActions.setFlashMessage, (state, { flashMessage }) => {
    return {
      ...state,
      flashMessage,
    };
  }),
  on(
    flashMessagesActions.removeFlashMessage,
    authActions.login,
    authActions.logOutSuccess,
    (state) => {
      return {
        ...state,
        flashMessage: null,
      };
    }),
);

export function flashMessageReducer(state: FlashMessageState | undefined, action: Action): any {
  return reducer(state, action);
}
