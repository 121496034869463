import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import {
  ISecurityTicketRequest,
  ISecurityTicketRes,
  IVerfifyCodeRequest,
} from '../interfaces/security-ticket.interface';
import { LocalStorageService } from '@livestock/shared/services';
import { StorageItem } from '@livestock/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class SecurityTicketService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getTicket(data: ISecurityTicketRequest): Observable<ISecurityTicketRes> {
    return this.http.post<ISecurityTicketRes>(
      `${this.baseUrl}/security-ticket`,
      data,
    );
  }

  verifyTicket(data: IVerfifyCodeRequest): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/security-ticket/verify-code`,
      data,
    );
  }

  signUp(data: ISecurityTicketRequest): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/ticket/sign-up`, data);
  }

  verifySighUp({ code, ticketID }: IVerfifyCodeRequest): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/ticket/${ticketID}/sign-up/verify-code`,
      { code },
    );
  }

  forgotPassword(data: ISecurityTicketRequest): Observable<any> {
    LocalStorageService.removeStorageItem(StorageItem.IsBiometrySetup);
    return this.http.post<any>(`${this.baseUrl}/ticket/reset-password`, data);
  }

  verifyForgotPassword({
    code,
    ticketID,
  }: IVerfifyCodeRequest): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/ticket/${ticketID}/reset-password/verify-code`,
      { code },
    );
  }
}
