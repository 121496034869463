export enum ChickenBrandWeight {
    COBB_500,
    ROSS_308,
}

export namespace ChickenBrandWeight {
    export function toTranslateKey(value: any): string {
        switch (value) {
            case ChickenBrandWeight.COBB_500:
                return 'Flock.Brands.COBB_500';
            case ChickenBrandWeight.ROSS_308:
                return 'Flock.Brands.ROSS_308';
            default:
                return value.toString();
        };
    }
}