import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { IAddNewUserTicketRequestView, IAddUserToFarmResponseView, IGetAddUserToFarmTicketView } from '@livestock/farms';

const baseUrl = environment.apiUrl;
const ticketUrl = (ticketID: string): string => `${baseUrl}/ticket/${ticketID}/add-user-to-farm`;

@Injectable({
    providedIn: 'root',
})
export class TicketFarmsService {
    constructor(private http: HttpClient) { }

    addUserToFarm(ticketID: string): Observable<IGetAddUserToFarmTicketView> {
        return this.http.get<IGetAddUserToFarmTicketView>(`${ticketUrl(ticketID)}`);
    }

    acceptExistingUserToFarm(ticketID: string): Observable<boolean> {
        return this.http.post<boolean>(`${ticketUrl(ticketID)}/accept`, null);
    }

    createUserViaInvitation(ticketID: string, userFromInvite: IAddNewUserTicketRequestView): Observable<IAddUserToFarmResponseView> {
        return this.http.post<IAddUserToFarmResponseView>(`${ticketUrl(ticketID)}/sign-up`, userFromInvite);
    }
}