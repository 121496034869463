import { Action, createReducer, on } from '@ngrx/store';
import { InstallationState } from './installation.state';
import * as installationActions from './installation.actions';

export const INSTALLATION_FEATURE_KEY = 'installation';

const initialState: InstallationState = {
  isLoading: false,
  elements: [],
  cards: [],
  currentElementSetup: null,
  currentElementType: null,
};

const reducer = createReducer(
  initialState,
  on(
    installationActions.getAllElements,
    installationActions.getElementSetup,
    installationActions.createElementSetup,
    installationActions.updateElementSetup,
    installationActions.deleteElementSetup,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    }),
  on(installationActions.getAllElementsSuccess, (state, { elements }) => {
    return {
      ...state,
      elements,
      isLoading: false,
    };
  }),
  on(
    installationActions.getAllElementsError,
    installationActions.getElementSetupError,
    installationActions.createElementSetupError,
    installationActions.updateElementSetupError,
    installationActions.deleteElementSetupError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    }),
  on(installationActions.getElementSetupSuccess, (state, { elementSetup }) => {
    return {
      ...state,
      currentElementSetup: elementSetup,
      isLoading: false,
    };
  }),
  on(installationActions.createElementSetupSuccess, (state, { elementSetup, elementType }) => {
    const elements = [...state.elements];
    const currentElementIndex = elements.findIndex(el => el.elementType === elementType);
    if (currentElementIndex >= 0) {
      elements[currentElementIndex] = {
        ...elements[currentElementIndex],
        connectedCount: elements[currentElementIndex].connectedCount + 1,
      };
    }

    return {
      ...state,
      elements,
      currentElementSetup: elementSetup,
      isLoading: false,
    };
  }),
  on(installationActions.updateElementSetupSuccess, (state, { elementSetup }) => {
    return {
      ...state,
      currentElementSetup: elementSetup,
      isLoading: false,
    };
  }),
  on(installationActions.deleteElementSetupSuccess, (state, { elementType }) => {
    const elements = [...state.elements];
    const elementIndex = elements.findIndex(el => el.elementType === elementType);
    if (elementIndex >= 0) {
      elements[elementIndex] = {
        ...elements[elementIndex],
        connectedCount: elements[elementIndex].connectedCount - 1,
      };
    }

    return {
      ...state,
      elements,
      currentElementSetup: null,
      isLoading: false,
    };
  }),
  on(installationActions.setActiveElementType, (state, { elementType }) => {
    return {
      ...state,
      currentElementType: elementType,
    };
  }),
);

export function installationReducer(state: InstallationState | undefined, action: Action): any {
  return reducer(state, action);
}
