import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'searchHighlight',
})
export class SearchHighlightPipe implements PipeTransform {
  transform(text: string, result: string): string {
    const start = text?.toLowerCase().indexOf(result?.toLowerCase());

    if (text && result && start !== -1) {
      const end = start + result.length;
      return `${text.slice(0, start)}<span class="highlighted">${text.slice(start, end)}</span>${text.slice(end)}`;
    }

    return text;
  }
}
