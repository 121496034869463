import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { StringUtils } from '@livestock/shared/utils';
import { ColorsEnum, IconsEnum, PageTypeEnum } from '@livestock/shared/enums';
import { SvgIconComponent } from '@livestock/ui';
import { Store } from '@ngrx/store';
import { combineLatest, interval, Subscription, tap } from 'rxjs';
import {
  createEditMode,
  deleteEditMode,
  HoursFormatTypeEnum,
  selectCnfStatus,
  selectCurrentControllerHoursFormat,
  selectEditModeInfo,
  selectLastEditedInfo,
  updateEditMode,
} from '@livestock/controllers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { SetupUpdateStatusTypeEnum } from '@livestock/controllers/enums';
import { ActivatedRouteService, PlatformService } from '@livestock/shared/services';
import { GlobalConstants } from '@livestock/shared/constants';
import { QaTagsDirective } from '@livestock/shared/directives';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
    MemoizeFuncPipe,
    QaTagsDirective,
  ],
  selector: 'lv-settings-header',
  templateUrl: './settings-header.component.html',
  styleUrls: ['./settings-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isEditMode: boolean;
  @Input() page: PageTypeEnum;
  @Input() section: string;
  @Input() title: string;
  @Input() isEditable: boolean = true;

  @Output() toggleDisabled: EventEmitter<void> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();

  platformService: PlatformService = inject(PlatformService);
  router: Router = inject(Router);
  activatedRouteService: ActivatedRouteService = inject(ActivatedRouteService);
  private store: Store = inject(Store);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private translateService: TranslateService = inject(TranslateService);

  isEditModeDisable: boolean;
  editBy: string;
  editDate: string;
  dateTimeFormat: string;

  // enums
  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;
  PageTypeEnum = PageTypeEnum;

  // subs
  editModeSub$: Subscription;

  ngOnInit(): void {
    // TODO: refactor - should be same for device and web (getting hoursFormat)
    combineLatest([
      this.store.select(selectLastEditedInfo),
      this.store.select(selectEditModeInfo),
      this.store.select(selectCnfStatus),
      this.store.select(selectCurrentControllerHoursFormat),
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(([lastEdited, editMode, cnfStatus, hoursFormat]) => {
      this.isEditModeDisable = editMode?.isEditModeDisable || cnfStatus !== SetupUpdateStatusTypeEnum.Done;
      this.editBy = this.platformService.isDeviceApp
        ? lastEdited?.fullName
        : this.isEditModeDisable
          ? editMode?.user?.fullName
          : lastEdited?.fullName || (lastEdited?.date && this.translateService.instant('ControllerMenu.Device'));
      this.editDate = this.platformService.isDeviceApp
        ? lastEdited?.date
        : this.isEditModeDisable
          ? null
          : lastEdited?.date;
      this.dateTimeFormat = hoursFormat === HoursFormatTypeEnum.HoursFormat24
        ? GlobalConstants.Last_edit_time_format_24Hours
        : GlobalConstants.Last_edit_time_format_AMPM;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['isEditMode'] || changes['isEditMode'].isFirstChange() || this.platformService.isDeviceApp) {
      return;
    }

    if (this.isEditMode) {
      this.startEditMode();
    } else {
      this.stopEditMode();
    }
  }

  goBack(): void {
    //TODO: not implemented yet
    //this.location.back();
    this.back.emit();
  }

  startEditMode(): void {
    this.page !== PageTypeEnum.Installation && this.store.dispatch(createEditMode({ page: this.page }));
    this.editModeSub$ = new Subscription();
    this.editModeSub$.add(interval(5000).pipe(tap(() => {
      this.page !== PageTypeEnum.Installation && this.store.dispatch(updateEditMode({ page: this.page }));
    })).subscribe());
  }

  stopEditMode(): void {
    this.store.dispatch(deleteEditMode({ page: this.page }));
    this.editModeSub$.unsubscribe();
  }

  onToggleEditMode(): void {
    if (this.platformService.isDeviceApp || !this.isEditModeDisable) {
      this.toggleDisabled.emit();
    }
  }

  getInitials(fullName?: string): string {
    return StringUtils.getInitials(fullName);
  }

  ngOnDestroy(): void {
    this.editModeSub$?.unsubscribe();
  }
}
