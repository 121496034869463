export enum IconsHouseEnum {
  // web + device
  HOUSE_DEVICE = 'house/house-device',
  ARROW_RIGHT = 'house/arrow-right',
  ARROW_RIGHT_TOP = 'house/arrow-right-top',
  ARROW_TOP = 'house/arrow-top',
  ARROW_TOP_SMALL = 'house/arrow-top-small',
  ARROW_LEFT = 'house/arrow-left',
  ARROW_LEFT_BOTTOM = 'house/arrow-left-bottom',
  ARROW_BOTTOM = 'house/arrow-bottom',
  ARROW_BOTTOM_SMALL = 'house/arrow-bottom-small',
}
