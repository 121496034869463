import { ConnectionTypeEnum, ElementTypesEnum } from '@livestock/shared/enums';

export interface IElement {
  elementType: ElementTypesEnum;
  name: string,
  maxConnectionCount: number,
  connectedCount: number;
  availableElementNumbers: number[];
  connectionType: ConnectionTypeEnum;
}
