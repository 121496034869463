<h2 class="d-flex justify-between mb-30">
  <div class="wrapper d-flex align-center">
    <div
      (click)="goBack()"
      class="mr-18 h-100-percent pointer">
      <ls-svg-icon
        [fullHeight]="true"
        [path]="IconsEnum.CONTROLLER_SETTINGS_BACK" />
    </div>

    <div class="d-flex align-center details">
      <div class="fw-500 d-flex flex-column pt-1">
        <span class="{{ platformService.isMobileVersion ? 'fz-10 mb-3' : 'fz-14 mb-5' }} section color-darkgrey">
          {{ section }} /
        </span>

        <span class="{{ platformService.isMobileVersion ? 'fz-21 d-block' : 'fz-28' }} title fw-600">
          {{ title }}
        </span>
      </div>

      <ng-container *ngIf="platformService.isMobileVersion">
        <ng-container *ngTemplateOutlet="editInfo"></ng-container>
      </ng-container>
    </div>
  </div>

  <div class="d-flex align-center">
    <div
      *ngIf="router.url | memoizeFunc : activatedRouteService.isFlockPage"
      class="mr-60 d-flex align-center gap-8">
      <div
        *ngIf="page !== PageTypeEnum.FlockSettings"
        class="d-flex align-center justify-center gap-8 flock-btn pointer">
        <ls-svg-icon [path]="IconsEnum.FLOCK_SETTINGS"></ls-svg-icon>
        <span>{{ "Flock.FlockSettings" | translate }}</span>
      </div>

      <div
        *ngIf="page !== PageTypeEnum.FlockWeightsSettings"
        class="d-flex align-center justify-center gap-8 flock-btn pointer">
        <ls-svg-icon [path]="IconsEnum.FLOCK_WEIGHTS"></ls-svg-icon>
        <span>{{ "Flock.GrowthTable" | translate }}</span>
      </div>

      <div class="d-flex align-center justify-center gap-8 flock-btn pointer">
        <ls-svg-icon [path]="IconsEnum.FLOCK_GRAPH"></ls-svg-icon>
        <span>{{ "Flock.WeightChart" | translate }}</span>
      </div>
    </div>

    <div
      *ngIf="isEditable"
      class="d-flex align-center">
      <ng-container *ngIf="!platformService.isMobileVersion">
        <ng-container *ngTemplateOutlet="editInfo"></ng-container>
      </ng-container>

      <ls-svg-icon
        (click)="onToggleEditMode()"
        [path]="isEditMode ? IconsEnum.CONTROLLER_SETTINGS_EDITABLE : IconsEnum.CONTROLLER_SETTINGS_LOCKED"
        [color]="!platformService.isDeviceApp && isEditModeDisable ? ColorsEnum.GreyDisabled : ColorsEnum.MonoDark"
        [ngClass]="{ pointer: !isEditModeDisable }"
        [qaTags]="'qa-btn-editMode'"
        [class.ml-18]="editDate || editBy">
      </ls-svg-icon>
    </div>
  </div>
</h2>

<ng-template #editInfo>
  <div
    *ngIf="platformService.isDeviceApp ? editDate : editDate || editBy"
    [ngClass]="{ 'd-flex': platformService.isMobileVersion }">
    <div
      [ngClass]="{ 'mb-6 mr-4': !platformService.isMobileVersion }"
      class="fz-12">
      <span *ngIf="!platformService.isMobileVersion && !isEditModeDisable">
        {{ "ControllerMenu.LastEdited" | translate }}:
      </span>
      <span
        *ngIf="isEditModeDisable"
        [ngClass]="{ 'color-red fw-500': !platformService.isDeviceApp }">
        {{ (platformService.isDeviceApp ? "ControllerMenu.LastEdited" : "ControllerMenu.EditedBy") | translate }}:
      </span>
    </div>

    <div class="d-flex align-center edit-info">
      <div
        *ngIf="editBy"
        class="profile mr-4 fz-{{ platformService.isMobileVersion ? '8' : '10' }}">
        {{ editBy | memoizeFunc : getInitials }}
      </div>
      <div
        *ngIf="editDate"
        class="fw-500  fz-{{ platformService.isMobileVersion ? '10' : '12' }}">
        {{ editDate | date : dateTimeFormat }}
      </div>
    </div>
  </div>
</ng-template>
