import { on } from '@ngrx/store';
import * as currentControllerActions from '../current-controller.actions';
import { CurrentControllerState } from '../current-controller.state';
import { LocalStorageService } from '@livestock/shared/services';
import { StorageItem } from '@livestock/shared/enums';
import {
  clearControllerInfo,
  setControllerLanguage,
} from '../current-controller.actions';
import { GlobalConstants } from '@livestock/shared/constants';
import { ControllerLanguageEnum } from '@livestock/controllers/enums';
import { initialState } from '../common/initial-state';
import { logOutError, logOutSuccess } from '@livestock/auth';

/* controller info and controller ID */
export const controllerInfoReducers = [
  on(currentControllerActions.setCnfStatus, (state: CurrentControllerState, { status }) => {
    return {
      ...state,
      cnfStatus: {
        status,
      },
    };
  }),
  on(currentControllerActions.getControllerInfoSuccess, (state: CurrentControllerState, { controllerInfo }) => {
    LocalStorageService.setStorageItem(StorageItem.ActiveControllerID, controllerInfo.controllerID);
    return {
      ...state,
      controllerInfo,
      activeControllerID: controllerInfo?.controllerID,
    };
  }),
  on(currentControllerActions.setActiveControllerID, (state: CurrentControllerState, { controllerID }) => {
    if (controllerID) {
      LocalStorageService.setStorageItem(StorageItem.ActiveControllerID, controllerID);
    } else {
      LocalStorageService.removeStorageItem(StorageItem.ActiveControllerID);
    }

    return {
      ...state,
      activeControllerID: controllerID,
    };
  }),
  on(setControllerLanguage, (state: CurrentControllerState, { controllerLanguage }) => {
    LocalStorageService.setStorageItem(StorageItem.CurrentLanguage, controllerLanguage);

    // use en.json as default for not supported languages
    return {
      ...state,
      controllerLanguage: GlobalConstants.usedLanguages.includes(controllerLanguage)
        ? controllerLanguage
        : ControllerLanguageEnum.EngUS,
    };
  }),
  on(currentControllerActions.updateControllerDateTimeSettingsSuccess, (state: CurrentControllerState, { dateTimeSettings }) => {
    return {
      ...state,
      controllerInfo: {
        ...state.controllerInfo,
        hoursFormat: dateTimeSettings?.hoursFormat,
      },
    };
  }),
  on(currentControllerActions.updateControllerHouseSizesSettingsSuccess, (state: CurrentControllerState, { houseSettings }) => {
    return {
      ...state,
      controllerInfo: {
        ...state.controllerInfo,
        houseNumber: houseSettings.houseNumber,
      },
    };
  }),

  // logout
  on(clearControllerInfo, logOutSuccess, logOutError, () => {
    LocalStorageService.removeStorageItem(StorageItem.ActiveControllerID);
    return initialState;
  }),
];
