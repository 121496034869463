<div
  [class.ph-18]="platformService.isDesktopApp"
  class="d-flex gap-18 flex-wrap wrapper">
  <ng-container *ngFor="let section of controllerMenuSections">
    <div
      (click)="changeMenuSection(section.id)"
      [ngClass]="{
        'menu-section__disabled': disabledMenuSections.includes(section.id),
        'menu-section__active': (activeMenuSection$ | async) === section.id
      }"
      [qaTags]="section.qaTags"
      class="menu-section p-28 d-flex align-center">
      <ls-svg-icon [path]="section.icon"></ls-svg-icon>
      <span class="ml-8">{{ section.title | translate }}</span>
    </div>
  </ng-container>
</div>

<div
  *ngIf="sectionChildren[activeMenuSection$ | async] as children"
  (wheel)="onScrollSections($event)"
  [@fadeInOutFast]
  [class.ph-18]="platformService.isDesktopApp"
  class="menu-pages pt-18 pb-28 d-flex gap-18">
  <div
    *ngFor="let page of children"
    (click)="goToPage(page.route)"
    [ngClass]="{
      'menu-page__active': (activePage$ | async) === page.id
    }"
    [qaTags]="page.qaTags"
    class="menu-page d-flex flex-column justify-center align-center">
    <ls-svg-icon [path]="page.icon"></ls-svg-icon>
    <span class="mt-8 text-center fz-14">{{ page.title | translate }}</span>
  </div>
</div>
