export enum OffOnEnum {
    Off = 0,
    On = 1,
}

export namespace OffOnEnum {
    export function toTranslateKey(value: OffOnEnum): string {
      switch (value) {
        case OffOnEnum.Off:
          return 'Installation.OffOnEnum.Off';
        case OffOnEnum.On:
          return 'Installation.OffOnEnum.On';
        default:
          return (value as unknown).toString();
      }
    }
  }
