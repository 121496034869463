export enum IconsCommonEnum {
  CROSS = 'common/cross',
  CROSS_WHITE = 'common/cross-white',
  LEFT_ARROW = 'common/left-arrow',
  LEFT_ARROW_CIRCLE = 'common/left-arrow-circle',
  RIGHT_ARROW = 'common/right-arrow',
  MINUS_IN_SQUARE = 'common/minus-in-square',
  PLUS_IN_SQUARE = 'common/plus-in-square',
  CHECKMARK = 'common/checkmark',
  CHECKMARK_GREEN = 'common/checkmark-green',
  INPUT_ICON = 'common/input-icon',
  ARROW_DOWN = 'common/arrow-down',
  ARROW_DOWN_WHITE = 'common/arrow-down-white',
  DELETE = 'common/delete',
  MALE = 'common/male',
  EXCLAMATION = 'common/exclamation',
  CHECKBOX = 'common/checkbox',
  CHECKBOX_CHECKED = 'common/checkbox-checked',
  EXPAND_IN_CIRCLE = 'common/expand-in-circle',
  DATE_ICON = 'common/date-icon',
  TIME_ICON = 'common/time-icon',
  SMALL_CHECKMARK_BLUE = 'common/checkmark-small-blue',
  CROSS_SMALL_BLACK = 'common/cross-small-black',
  CONTROLLER_ICON = 'common/controller-icon',
  CONTROLLER_ICON_SMALL = 'common/controller-icon-small',
  CONTROLLER_ADD = 'common/controller-add',
  CONTROLLER_SETTINGS_EDITABLE = 'common/controller-settings-editable',
  CONTROLLER_SETTINGS_LOCKED = 'common/controller-settings-locked',
  CONTROLLER_SETTINGS_BACK = 'common/controller-settings-back',
  CONTROLLER_SETTINGS_BACK_WHITE = 'common/controller-settings-back-white',
  FARM_SETTINGS = 'common/farm-settings',
  FARM_SEARCH = 'common/farm-search',
  CONTROLLER_ADD_MOBILE = 'common/controller-add-mobile',
  FARM_SETTINGS_MOBILE = 'common/farm-settings-mobile',
  FARM_SETTINGS_MOBILE_BLACK = 'common/farm-settings-mobile-black',
  FARM_SEARCH_MOBILE = 'common/farm-search-mobile',
  FARM_SEARCH_WHITE = 'common/farm-search-white',
  ALARM = 'common/alarm',
  CLOUD = 'common/cloud',
  LOGOUT = 'common/logout',
  DEVICE = 'common/device',
  GO_BACK_ARROW = 'common/go-back-arrow',
  FUNNY_SMILE = 'common/funny-smile',
  SAD_SMILE = 'common/sad-smile',
  CONTROLLER_NUMBER = 'common/controller-number',
  TIMEZONE = 'common/timezone',
  FAIL_CIRCLE = 'common/fail-circle',
  LARGE_TICK = 'common/large-tick',
  FLASH_SUCCESS = 'common/flash-success',
  SEARCH_DARK = 'common/search-dark',
  WARNING = 'common/warning',
  RESET = 'common/reset',
  LANGUAGE = 'common/language',
  RADIO = 'common/radio',
  RADIO_CHECKED = 'common/radio-checked',
  VERTICAL_DOTS = 'common/vertical-dots',
  DROP_DOWN_CIRCLE = 'common/drop-down-circle',
  DROP_DOWN_TICK = 'common/drop-down-tick',
  PLUS_MINUS = 'common/plus-minus',
  EDIT_IN_CIRCLE = 'common/edit-circle',
  CLOCK = 'common/clock',
}
