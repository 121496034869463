import { Injectable } from '@angular/core';
import { AppRoutes } from '../routes';
import { ControllerRoutes } from '../../controllers/src';

@Injectable({
  providedIn: 'root',
})
export class ActivatedRouteService {
  shouldShowTopMenu(url: string): boolean {
    return !(url.includes(AppRoutes.FARM) ||
      url.includes(AppRoutes.QUICK_START) ||
      url.includes(AppRoutes.LANDING)
    );
  }

  shouldShowDisplayMenu(url: string): boolean {
    return url !== '/' && !(url.includes(AppRoutes.FARM) ||
      url.includes(AppRoutes.QUICK_START) ||
      url.includes(AppRoutes.LANDING)
    );
  }

  isFarmsHomepage(url: string): boolean {
    const farmsPageUrlPattern = new RegExp(`^\/${AppRoutes.FARM}\/\\d+$`);
    return farmsPageUrlPattern.test(url);
  }

  isFarmsEmptyHomepage(url: string): boolean {
    return url?.includes(AppRoutes.FARMS);
  }

  isQuickStart(url: string): boolean {
    return url.includes(AppRoutes.QUICK_START);
  }

  isAuthPage(url: string): boolean {
    return url.includes(AppRoutes.AUTH) ||
      url.includes(AppRoutes.VALIDATE_INVITE);
  }

  isSignUpPage(url: string): boolean {
    return url.includes(`${AppRoutes.AUTH}/sign-up`);
  }

  isFarmCreationPage(url: string): boolean {
    return url.includes(AppRoutes.CREATE_FARM);
  }

  isFarmSettingsPage(url: string): boolean {
    return url.includes(AppRoutes.FARM_SETTINGS);
  }

  isCreateFarmOrScannedLoginPage(url: string): boolean {
    return url.includes(AppRoutes.CREATE_FARM) || url.includes(AppRoutes.SCANNED_LOGIN);
  }

  isForgotPasswordPage(url: string): boolean {
    return url.includes(AppRoutes.FORGOT_PASSWORD);
  }

  isLobby(url: string): boolean {
    return url.includes('/lobby');
  }

  // do not show farms list in "create-farm" page
  canShowFarmsList(url: string): boolean {
    return !url.includes(AppRoutes.CREATE_FARM) &&
      !url.includes(AppRoutes.FARM_SETTINGS) &&
      !url.includes(AppRoutes.SCANNED_LOGIN);
  }

  // do not show lobby in "create-farm" page
  canShowLobby(url: string): boolean {
    return !url.includes(AppRoutes.CREATE_FARM) &&
      !url.includes(AppRoutes.FARMS) &&
      !url.includes(AppRoutes.FARM_SETTINGS) &&
      !url.includes(AppRoutes.SCANNED_LOGIN) &&
      !url.includes(AppRoutes.LOGIN);
  }

  showTransparentLobby(url: string): boolean {
    return url.includes(AppRoutes.CREATE_CONTROLLER);
  }

  showTransparentMobileLobby(url: string): boolean {
    return url.includes(AppRoutes.CREATE_CONTROLLER) ||
      url.includes(AppRoutes.CREATE_FARM) ||
      url.includes(AppRoutes.SCANNED_LOGIN);
  }

  onFarmSettings(url: string): boolean {
    return url.includes(AppRoutes.FARM_SETTINGS);
  }

  isFlockPage(url: string): boolean {
    return url.includes(ControllerRoutes.Flock);
  }
}
