<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 201 200"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <ng-container *ngFor="let path of svgPaths; index as index">
    <path
      [attr.d]="path"
      [style.fill]="sectionsColor"
      [class.active]="index === activeIndex"
      fill="red" />
  </ng-container>
</svg>

<h4
  *ngIf="title"
  class="mt-10 text-center fz-20">
  {{ shouldBeTranslated ? (title | translate) : title }}
</h4>
