<div class="popup text-left">
  <div class="mb-20">
    <h4 class="fz-20 mb-2 fw-500 d-flex align-center justify-between pb-5 pb-10-mobile mb-20">
      <span class="title pr-16">{{ isTitleShouldBeTranslated ? (title | translate) : title }}</span>

      <img
        *ngIf="showCloseIcon"
        (click)="dialogRef.close()"
        alt="cross"
        class="pointer close-cross"
        src="/assets/icons/common/cross.svg" />
    </h4>

    <div
      *ngIf="iconPath"
      class="mx-auto mb-10 popup-message__icon">
      <ls-svg-icon
        [fullHeight]="true"
        [hasMargin]="true"
        [path]="iconPath"
        class="d-block w-100-percent h-100-percent"></ls-svg-icon>
    </div>
    <p
      [innerHTML]="isMessageShouldBeTranslated ? (message | translate) : (message | memoizeFunc : getInnerHTML : this)"
      [ngClass]="{
        'text-center': alignText === AlignmentEnum.CENTER,
        'text-right': alignText === AlignmentEnum.END
      }"
      [qaTags]="'qa-inp-message'"
      class="fz-18 message pt-5 pb-20 pt-10-mobile pb-0-mobile"></p>
  </div>

  <div
    [ngClass]="{
      'justify-start': alignButtons === AlignmentEnum.START,
      'justify-center': alignButtons === AlignmentEnum.CENTER,
      'justify-between': alignButtons === AlignmentEnum.BETWEEN,
      'justify-end': alignButtons === AlignmentEnum.END
    }"
    class="buttons d-flex align-center">
    <ng-container *ngFor="let button of buttons">
      <ls-button
        (click)="close(button)"
        [class]="button.class"
        [qaTags]="button.qaClass"
        [type]="button.lsButtonType"
        class="mh-5">
        {{ button.text | translate }}
      </ls-button>
    </ng-container>
  </div>
</div>
