import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsEnum } from '@livestock/shared/enums';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { SvgIconComponent } from '@livestock/ui';
import { TopMenuComponent } from '@livestock/menu';
import { firstValueFrom, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectUserMenuIsActive,
  toggleUserMenu,
} from '@livestock/current-user';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayTopMenuComponent } from '@livestock/appdisplay';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'lv-layout-display',
  standalone: true,
  imports: [
    CommonModule,
    MemoizeFuncPipe,
    SvgIconComponent,
    TopMenuComponent,
    TranslateModule,
    DisplayTopMenuComponent,
    RouterOutlet,
  ],
  templateUrl: './layout-display.component.html',
  styleUrls: ['./layout-display.component.scss'],
})
export class LayoutDisplayComponent {
  private store: Store = inject(Store);

  userMenuIsActive$: Observable<boolean> = this.store.select(selectUserMenuIsActive);
  IconsEnum: typeof IconsEnum = IconsEnum;

  async toggleUserMenu(): Promise<void> {
    const userMenuIsActive = await firstValueFrom(this.userMenuIsActive$);
    this.store.dispatch(toggleUserMenu({ userMenuIsActive: !userMenuIsActive }));
  }
}
