<input
  (input)="setValue($event)"
  #input
  [type]="inputType"
  [disabled]="disabled"
  [maxLength]="maxLength"
  [minLength]="minLength"
  [placeholder]="placeholder"
  [class.centered]="centered"
  [readonly]="readonly"
  type="text" />
