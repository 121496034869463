import { Injectable } from '@angular/core';
import { environment } from '@livestock/shared/environments';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IResponse } from '@livestock/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiClientService {

  constructor(
    private http: HttpClient,
  ) {
  }

  get apiUrl(): string {
    return environment.apiUrl;
  }

  // get<T>(path: string, key: string = 'body'): Observable<T> {
  //     const url = `${this.apiUrl}/${path}`;

  //     return this.http.get<IResponse<T>>(url).pipe(
  //         map(res => res[key]),
  //     );
  // }

  post<T>(path: string, content: any): Observable<T> {
    const url = `${this.apiUrl}/${path}`;

    return this.http.post<IResponse<T>>(url, content).pipe(
      map(res => res.body),
    );
  }

  put<T>(path: string, content: any): Observable<T> {
    const url = `${this.apiUrl}/${path}`;

    return this.http.put<IResponse<T>>(url, content).pipe(
      map(res => res.body),
    );
  }

  delete<T>(path: string): Observable<T> {
    const url = `${this.apiUrl}/${path}`;

    return this.http.delete<IResponse<T>>(url).pipe(
      map(res => res.body),
    );
  }
}
