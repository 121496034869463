import { on } from '@ngrx/store';
import * as currentControllerActions from '../current-controller.actions';
import { CurrentControllerState } from '../current-controller.state';
import { PageTypeEnum, StorageItem } from '@livestock/shared/enums';
import { LocalStorageService } from '@livestock/shared/services';
import { ControllerLanguageEnum, SetupUpdateStatusTypeEnum } from '@livestock/controllers';
import { TimeUtils } from '@livestock/shared/utils';

export const settingsReducers = [
  on(
    currentControllerActions.getControllerGeneralSettingsOnly,
    currentControllerActions.getControllerDateTimeSettingsOnly,
    currentControllerActions.updateControllerGeneralSettings,
    currentControllerActions.updateControllerDateTimeSettings,
    currentControllerActions.updateControllerHouseSizesSettings,
    currentControllerActions.getCards,
    (state: CurrentControllerState) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    currentControllerActions.getControllerGeneralSettingsError,
    currentControllerActions.getControllerGeneralSettingsOnlyError,
    currentControllerActions.getControllerDateTimeSettingsOnlyError,
    currentControllerActions.updateControllerGeneralSettingsSuccess,
    currentControllerActions.updateControllerGeneralSettingsError,
    currentControllerActions.getControllerDateTimeSettingsError,
    currentControllerActions.updateControllerDateTimeSettingsSuccess,
    currentControllerActions.updateControllerDateTimeSettingsError,
    currentControllerActions.getControllerHouseSizesSettingsError,
    currentControllerActions.updateControllerHouseSizesSettingsSuccess,
    currentControllerActions.updateControllerHouseSizesSettingsError,
    currentControllerActions.getCurrentControllerNetworkSettingsError,
    currentControllerActions.getCardsError,
    (state: CurrentControllerState) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(currentControllerActions.getControllerGeneralSettings,
    currentControllerActions.getControllerDateTimeSettings,
    currentControllerActions.getControllerHouseSizesSettings,
    currentControllerActions.getCurrentControllerNetworkSettings,
    (state: CurrentControllerState) => {
      return {
        ...state,
        lastEditedInfo: null,
        editModeInfo: null,
        cnfStatus: null,
        isLoading: true,
      };
    }),
  on(currentControllerActions.getControllerGeneralSettings,
    (state: CurrentControllerState) => {
      return {
        ...state,
        generalSettings: null,
        isLoading: true,
      };
    }),
  on(currentControllerActions.getControllerDateTimeSettings,
    (state: CurrentControllerState) => {
      return {
        ...state,
        dateTimeSettings: null,
        isLoading: true,
      };
    }),
  on(currentControllerActions.updateControllerHouseSizesSettings,
    (state: CurrentControllerState, { houseSettings }) => {
      return {
        ...state,
        houseSettings,
      };
    }),
  on(currentControllerActions.updateControllerDateTimeSettingsSuccess,
    (state: CurrentControllerState, { dateTimeSettings }) => {
      return {
        ...state,
        dateTimeSettings,
      };
    }),
  on(currentControllerActions.updateControllerGeneralSettingsSuccess,
    (state: CurrentControllerState, { generalSettings, unitsDetails }) => {
      return {
        ...state,
        generalSettings: {
          ...generalSettings,
          unitsDetails,
        },
        controllerInfo: {
          ...state.controllerInfo,
          units: generalSettings.units,
          unitsDetails,
        },
      };
    }),
  on(currentControllerActions.getControllerDateTimeSettings,
    (state: CurrentControllerState) => {
      return {
        ...state,
        dateTimeSettings: null,
      };
    }),
  on(currentControllerActions.getControllerHouseSizesSettings,
    (state: CurrentControllerState) => {
      return {
        ...state,
        houseSettings: null,
      };
    }),
  on(currentControllerActions.getControllerGeneralSettingsOnlySuccess, (state: CurrentControllerState, { generalSettings }) => {
    return {
      ...state,
      generalSettings,
      isLoading: false,
    };
  }),
  on(currentControllerActions.getControllerDateTimeSettingsOnlySuccess, (state: CurrentControllerState, { dateTimeSettings }) => {
    return {
      ...state,
      dateTimeSettings,
      isLoading: false,
    };
  }),
  on(currentControllerActions.getControllerGeneralSettingsSuccess, (state: CurrentControllerState, {
    generalSettings,
    lastEdited,
    editMode,
    cnfStatus,
  }) => {
    return {
      ...state,
      generalSettings,
      lastEditedInfo: lastEdited,
      editModeInfo: editMode,
      cnfStatus,
      isLoading: false,
      page: PageTypeEnum.UnitsSettings,
    };
  }),
  on(currentControllerActions.getControllerHouseSizesSettingsSuccess, (state: CurrentControllerState, {
    houseSettings,
    lastEdited,
    editMode,
    cnfStatus,
  }) => {
    return {
      ...state,
      houseSettings,
      lastEditedInfo: lastEdited,
      editModeInfo: editMode,
      cnfStatus,
      isLoading: false,
      page: PageTypeEnum.HouseSizesSettings,
    };
  }),
  on(currentControllerActions.getControllerDateTimeSettingsSuccess, (state: CurrentControllerState, {
    dateTimeSettings,
    lastEdited,
    editMode,
    cnfStatus,
  }) => {
    const date = +LocalStorageService.getStorageItem(StorageItem.CurrentLanguage) !== ControllerLanguageEnum.EngUS
      ? TimeUtils.usaDateFormatToUsualDateFormat(dateTimeSettings.date)
      : dateTimeSettings.date;

    return {
      ...state,
      dateTimeSettings: {
        ...dateTimeSettings,
        date,
      },
      lastEditedInfo: lastEdited,
      editModeInfo: editMode,
      cnfStatus,
      isLoading: false,
      page: PageTypeEnum.DateTimeSettings,
    };
  }),
  on(currentControllerActions.getCardsSuccess, (state: CurrentControllerState, {
    cards,
    lastEdited,
    editMode,
  }) => {
    return {
      ...state,
      cards,
      lastEditedInfo: lastEdited,
      editModeInfo: editMode,
      cnfStatus: { status: SetupUpdateStatusTypeEnum.Done },
      isLoading: false,
      page: PageTypeEnum.Installation,
    };
  }),
  on(
    currentControllerActions.getCurrentControllerNetworkSettingsSuccess,
    (state: CurrentControllerState, { networkSettings }) => {
      return {
        ...state,
        networkSettings,
        isLoading: false,
        page: PageTypeEnum.NetworkSettings,
      };
    },
  ),
  on(
    currentControllerActions.getLastEditedSettingsInfoSuccess,
    (state: CurrentControllerState, { lastEdited }) => {
      return {
        ...state,
        lastEditedInfo: lastEdited,
      };
    },
  ),
  on(currentControllerActions.getCurrentControllerFlockSettingsSuccess, (state: CurrentControllerState, {
    lastEdited,
    editMode,
  }) => {
    return {
      ...state,
      lastEditedInfo: lastEdited,
      editModeInfo: editMode,
      cnfStatus: { status: 2 },
      isLoading: false,
      page: PageTypeEnum.FlockSettings,
    };
  }),
];
