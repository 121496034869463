import { DialogButtonEnum } from '@livestock/shared/enums';

export class DialogButton {
  class: string;
  text: string;
  lsButtonType: 'default' | 'primary' | 'secondary' | 'alert' | 'danger' = 'default';
  type: DialogButtonEnum;
  qaClass: string;

  constructor(button: DialogButtonEnum) {
    this.type = button;
    this.class = '';
    switch (button) {
      //TODO: change qa class when will be created
      case DialogButtonEnum.YES:
        this.text = 'Buttons.Yes';
        this.qaClass = 'qa-btn-yes';
        break;
      case DialogButtonEnum.NO:
        this.text = 'Buttons.No';
        this.lsButtonType = 'secondary';
        this.qaClass = 'qa-btn-no';
        break;
      case DialogButtonEnum.OK:
        this.text = 'Buttons.Ok';
        break;
      case DialogButtonEnum.CANCEL:
        this.lsButtonType = 'secondary';
        this.text = 'Buttons.Cancel';
        this.qaClass = 'qa-btn-cancel qa-btn-changes-cancel';
        break;
      case DialogButtonEnum.DELETE:
        this.text = 'Buttons.Delete';
        this.qaClass = 'qa-btn-delete';
        break;
      case DialogButtonEnum.YES_CLOSE_POPUP:
        this.text = 'Buttons.YesClosePopup';
        this.qaClass = 'qa-btn-yes-close-popup';
        break;
      case DialogButtonEnum.CLOSE:
        this.lsButtonType = 'secondary';
        this.text = 'Buttons.Close';
        this.qaClass = 'qa-btn-close';
        break;
      case DialogButtonEnum.CONTINUE:
        this.lsButtonType = 'primary';
        this.text = 'Buttons.Continue';
        this.qaClass = 'qa-btn-continue';
        break;
      case DialogButtonEnum.MOVE:
        this.lsButtonType = 'primary';
        this.text = 'Buttons.Move';
        this.qaClass = 'qa-btn-move';
        break;
      case DialogButtonEnum.SET_DATE_AND_TIME:
        this.lsButtonType = 'primary';
        this.text = 'Buttons.SetDateAndTime';
        this.qaClass = 'qa-btn-date-and-time';
        break;
      case DialogButtonEnum.DISCARD:
        this.lsButtonType = 'secondary';
        this.text = 'Buttons.Discard';
        this.qaClass = 'qa-btn-discard';
        break;
      case DialogButtonEnum.TRY_AGAIN:
        this.lsButtonType = 'primary';
        this.text = 'Buttons.TryAgain';
        this.qaClass = 'qa-btn-try-again';
        break;
      case DialogButtonEnum.DISCONNECT:
        this.lsButtonType = 'danger';
        this.text = 'Buttons.Disconnect';
        this.qaClass = 'qa-btn-disconnect';
        break;
      case DialogButtonEnum.RECONNECT:
        this.lsButtonType = 'primary';
        this.text = 'Buttons.Reconnect';
        this.qaClass = 'qa-btn-reconnect';
        break;
      default:
        throw new Error('Not Implemented');
    }
  }
}
