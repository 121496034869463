<aside
  *ngIf="extended"
  [class.farms-list--mobile]="platformService.isMobileVersion"
  [class.rtl]="languageService.isRtl"
  class="farms-list">
  <div class="content">
    <ng-container *ngIf="!activeFarm && farms?.length > 0">
      <ng-container *ngTemplateOutlet="myFarmsTemplate; context: { isActiveFarmExist: false, length: farms?.length }">
      </ng-container>
    </ng-container>
    <nav
      #farmListContainer
      [class.justify-center]="!(myFarmsFiltered$ | async)?.length && !(otherFarmsFiltered$ | async)?.length"
      [class.farms-list__list-no-scrollable]="!isScrollableFarmListContainer"
      [class.farms-list__list-no-active-farm]="!activeFarm"
      [class.farms-list__list-with-margin]="!isEditMode && !platformService.isMobileVersion"
      class="farms-list__list">
      <ul
        *ngIf="(myFarmsFiltered$ | async)?.length || (otherFarmsFiltered$ | async)?.length; else noData"
        (scroll)="scrollFarms($event)"
        #farmsList
        [class.editable]="isEditMode"
        class="overflow-auto">
        <ng-container *ngIf="!!activeFarm">
          <ng-container
            *ngTemplateOutlet="myFarmsTemplate; context: { isActiveFarmExist: true, length: farms?.length }">
          </ng-container>
        </ng-container>

        <ng-container *ngIf="(myFarmsFiltered$ | async)?.length; else noMyOrOtherFarms">
          <li
            *ngFor="let farm of myFarmsFiltered$ | async; let i = index; trackBy: trackBy"
            (clickOutside)="onEditClose(farm, $event)"
            #myFarms
            [class.farms-list__farm--editable]="activeFarm?.farmID === farm.farmID && isEditMode">
            <a
              (click)="toggleControllerList(farm.farmID)"
              [routerLink]="
                farm.existingHouseNumbers.length === 0 && !platformService.isMobileVersion
                  ? [AppRoutes.FARM, farm.farmID, AppRoutes.CREATE_CONTROLLER]
                  : [AppRoutes.FARM, farm.farmID]
              "
              [class.farms-list__farm--selected]="activeFarm?.farmID === farm.farmID"
              [class.farms-list__farm--selected__empty]="
                activeFarm?.farmID === farm.farmID && farm.existingHouseNumbers.length === 0
              "
              [qaTags]="'qa-lnk-farm'"
              class="farms-list__farm">
              <ng-container
                *ngTemplateOutlet="
                  farmNameTemplate;
                  context: {
                    farm: farm,
                    activeFarm: activeFarm,
                    showRole: false
                  }
                ">
              </ng-container>
            </a>
            <ng-container
              *ngTemplateOutlet="
              addDeviceButtonTemplate;
              context: {
                farm: farm,
                activeFarm: activeFarm,
              }">
            </ng-container>
            <lv-farm-controllers-list
              *ngIf="activeFarm?.farmID === farm.farmID && !isEditMode"
              [class.d-none]="!controllerListVisible"
              [farmID]="activeFarm?.farmID"
              [farmList]="farmsList"
              [farmListItem]="myFarms">
            </lv-farm-controllers-list>
            <lv-update-farm
              *ngIf="activeFarm?.farmID === farm.farmID && isEditMode"
              (deleted)="onDelete()"
              [farm]="activeFarm">
            </lv-update-farm>
          </li>
        </ng-container>

        <ng-container *ngIf="(otherFarmsFiltered$ | async)?.length">
          <div class="farms-list__other-farms">
            {{ "Farms.FarmsList.OtherFarms" | translate }}
          </div>
          <li
            *ngFor="let farm of otherFarmsFiltered$ | async; let i = index; trackBy: trackBy"
            (clickOutside)="onEditClose(farm, $event)"
            #otherFarms
            [class.farms-list__farm--editable]="activeFarm?.farmID === farm.farmID && isEditMode">
            <a
              (click)="toggleControllerList(farm.farmID)"
              [routerLink]="
                farm.existingHouseNumbers.length === 0 && !platformService.isMobileVersion
                  ? [AppRoutes.FARM, farm.farmID, AppRoutes.CREATE_CONTROLLER]
                  : [AppRoutes.FARM, farm.farmID]
              "
              [class.farms-list__farm--selected]="activeFarm?.farmID === farm.farmID"
              [class.farms-list__farm--selected__empty]="
                activeFarm?.farmID === farm.farmID && farm.existingHouseNumbers.length === 0
              "
              [qaTags]="'qa-lnk-farm'"
              class="farms-list__farm">
              <ng-container
                *ngTemplateOutlet="
                  farmNameTemplate;
                  context: {
                    farm: farm,
                    activeFarm: activeFarm,
                    showRole: true
                  }
                ">
              </ng-container>
            </a>
            <ng-container
              *ngTemplateOutlet="
              addDeviceButtonTemplate;
              context: {
                farm: farm,
                activeFarm: activeFarm,
              }">
            </ng-container>
            <lv-farm-controllers-list
              *ngIf="activeFarm?.farmID === farm.farmID && !isEditMode"
              [class.d-none]="!controllerListVisible"
              [farmID]="activeFarm?.farmID"
              [farmList]="farmsList"
              [farmListItem]="otherFarms">
            </lv-farm-controllers-list>
            <lv-update-farm
              *ngIf="activeFarm?.farmID === farm.farmID && isEditMode"
              (deleted)="onDelete()"
              [farm]="activeFarm">
            </lv-update-farm>
          </li>
        </ng-container>
      </ul>

      <p
        *ngIf="
          searchResult.value && (myFarmsFiltered$ | async).length === 0 && (otherFarmsFiltered$ | async).length === 0
        "
        class="grey fz-18 farms-list__no-results">
        <ls-svg-icon [path]="IconsEnum.SEARCH_DARK"></ls-svg-icon>
        {{ "Farms.FarmsList.NoResults" | translate }}
        <br />
        for “{{ searchResult.value }}”
      </p>

      <!-- show if first farm was created -->
      <ng-container *ngIf="farmWasCreated && farms?.length === 1">
        <div class="first-farm">
          <h2 class="fz-45 line-height-12">
            {{ "Farms.FirstFarm.GreatJob" | translate }}
          </h2>

          <h3 class="fz-32 line-height-12 mb-56">
            {{ "Farms.FirstFarm.YouHaveJustCreatedYourFirstFarm" | translate }}
          </h3>

          <h4 class="fz-24 mb-18 line-height-12">
            {{ "Farms.FirstFarm.LetsAddYourController" | translate }}
          </h4>

          <ls-button
            (click)="addController(farms[0].farmID)"
            [fontSize]="18"
            [type]="'primary'"
            class="d-block mb-18 add-controller">
            <div class="d-flex align-center">
              <span class="mr-10">{{ "Farms.FirstFarm.AddController" | translate }}</span>
              <ls-svg-icon [path]="IconsEnum.ADD_CONTROLLER"></ls-svg-icon>
            </div>
          </ls-button>

          <span class="fz-14 line-height-11">
            {{ "Farms.FirstFarm.MakeSureToConnectYourControllerToInternetNetworkAndFinishQuickSetupSteps" | translate }}
          </span>
        </div>
      </ng-container>
    </nav>

    <div
      *ngIf="!platformService.isMobileVersion"
      class="farms-list__footer">
      {{ "Livestock.PoweredBy" | translate | lowercase }}
      <img src="/assets/images/galcon-full-logo.png" />
    </div>
  </div>
</aside>

<ng-template #noData>
  <div
    *ngIf="!searchResult.value && !(isLoading$ | async)"
    class="empty-mode d-flex flex-column align-center">
    <div
      [ngClass]="platformService.isMobileVersion ? 'w-100-percent' : 'w-324'"
      class="mb-28">
      {{ "Farms.ThereAreNoFarmsYet" | translate }} <br />
      {{ "Farms.LetsCreateYourFirstFarm" | translate }}
    </div>
    <ls-button
      (click)="createFarm(true)"
      [qaTags]="'qa-btn-start'"
      [fontSize]="18"
      [ngClass]="platformService.isMobileVersion ? 'w-100-percent' : 'w-324'"
      class="d-block h-48"
      type="primary">
      {{ "Farms.Start" | translate }}
    </ls-button>
  </div>
</ng-template>

<ng-template #noMyOrOtherFarms>
  <div class="empty-mode">
    <div class="fz-14 mb-28">
      {{ "Farms.ThereAreNoFarmsYet" | translate }}
    </div>
  </div>
</ng-template>

<ng-template
  #myFarmsTemplate
  let-isActiveFarmExist="isActiveFarmExist"
  let-length="length">
  <div
    [class.no-active-farm]="!isActiveFarmExist"
    class="farms-list__search">
    <div class="d-flex flex-row align-center">
      <span class="farms-list__my-farms">
        {{ farmsLabelKey | translate }}
      </span>
      <ls-svg-icon
        *ngIf="length >= 12"
        [path]="IconsEnum.FARM_SEARCH_WHITE"
        class="mh-18" />
    </div>

    <div
      (click)="createFarm()"
      class="farms-list__add-farm-btn pointer">
      {{ "Farms.FarmsList.AddFarm" | translate }} +
    </div>
  </div>
</ng-template>

<ng-template
  #farmNameTemplate
  let-farm="farm"
  let-activeFarm="activeFarm"
  let-showRole="showRole">
  <div
    [class.farms-list__farm-name-wrapper__empty]="farm.existingHouseNumbers.length === 0"
    class="farms-list__farm-name-wrapper">
    <div
      [class.farms-list__farm-name__rtl]="isRtl"
      class="farms-list__farm-name fz-14">
      <div
        [innerHTML]="farm.name | searchHighlight : searchResult.value"
        class="farms-list__farm-name__title"></div>
      <div
        *ngIf="showRole"
        class="farms-list__farm-user-role mt-4 fz-12">
        <ng-container *ngIf="farm.roleType != null; else currentUserRole">
          {{ farm.roleType | enum : "farm-user-roles" | translate }}
        </ng-container>
        <ng-template #currentUserRole>
          {{ currentUserRole$ | async | enum : "common-user-roles" | translate }}
        </ng-template>
      </div>
    </div>

    <div
      *ngIf="activeFarm?.farmID !== farm.farmID"
      class="farms-list__farm-controller-count">
      <div class="mh-5">{{ farm.existingHouseNumbers.length }}</div>
      <ls-svg-icon [path]="IconsEnum.CONTROLLER_ICON_SMALL" />
    </div>
    <div
      *ngIf="activeFarm?.farmID === farm.farmID"
      class="farms-list__farm-actions">
      <ls-svg-icon
        *ngIf="!isEditMode"
        [path]="platformService.isMobileVersion ? IconsEnum.FARM_SEARCH_MOBILE : IconsEnum.FARM_SEARCH"
        [width]="platformService.isMobileVersion ? '16px' : '20px'"
        [height]="platformService.isMobileVersion ? '16px' : '20px'"
        class="pointer" />
      <ls-svg-icon
        (click)="editActiveFarm($event)"
        [path]="platformService.isMobileVersion ? IconsEnum.FARM_SETTINGS_MOBILE_BLACK : IconsEnum.FARM_SETTINGS"
        [color]="isEditMode ? ColorsEnum.MainBlue : ColorsEnum.Black"
        [width]="platformService.isMobileVersion ? '17px' : '21px'"
        [height]="platformService.isMobileVersion ? '16px' : '20px'"
        class="mh-18 pointer" />
      <ls-svg-icon
        (click)="createController($event)"
        [path]="platformService.isMobileVersion ? IconsEnum.CONTROLLER_ADD_MOBILE : IconsEnum.CONTROLLER_ADD"
        [width]="platformService.isMobileVersion ? '19px' : '24px'"
        [height]="platformService.isMobileVersion ? '16px' : '20px'"
        class="pointer" />
    </div>
  </div>
</ng-template>

<ng-template
  #addDeviceButtonTemplate
  let-farm="farm"
  let-activeFarm="activeFarm">
  <ls-button
    *ngIf="
      activeFarm?.farmID === farm.farmID &&
      !isEditMode &&
      farm.existingHouseNumbers.length === 0 &&
      platformService.isMobileVersion
    "
    (click)="addController(farm.farmID)"
    class="mt-28 mb-28 h-48 d-flex justify-center">
    {{ "Farms.FarmsList.AddDevice" | translate }}
    <ls-svg-icon
      [path]="IconsEnum.CONTROLLER_ADD"
      [width]="'22px'"
      [height]="'18px'"
      class="d-flex align-center mh-10" />
  </ls-button>
</ng-template>
