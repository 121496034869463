import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@livestock/shared/environments';
import { DisplayState } from '../+state/display.state';
import { Observable, tap, lastValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectFeatDisplay } from '../+state/display.selectors';

@Injectable({
  providedIn: 'root',
})
export class StateSyncService {
  sync$ = this.store.select(selectFeatDisplay).pipe(tap(async (state): Promise<void> => {
    await lastValueFrom(this.sendState(state));
  }));

  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ){
    console.log('constructor', 'StateSyncService');
    this.sync$.subscribe();
  }

  sendState(data: DisplayState): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.deviceApiUrl}/display-state/frontend`,
      data,
    );
  };

}
