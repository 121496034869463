import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Router,
  RouterModule,
} from '@angular/router';
import { firstValueFrom, Observable, Subscription } from 'rxjs';

import {
  PlatformService,
  MenuService,
  SearchMobileService,
  LanguageService,
  ActivatedRouteService,
} from '@livestock/shared/services';
import { SearchComponent, SvgIconComponent } from '@livestock/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { selectCurrentUserInitials } from '@livestock/current-user';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import {
  selectedControllerIsActive,
  ControllerLanguageEnum,
  selectCurrentControllerHouseNumber,
} from '@livestock/controllers';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { ControllerMenuComponent } from '../controller-menu/controller-menu.component';
import { selectIsMenuOpened } from '../../+state/controller-menu.selectors';
import { toggleMenuIsOpened } from '../../+state/controller-menu.actions';
import { fadeInOutFast } from '@livestock/shared/animations';
import { ControllerMenuMobileComponent } from '../controller-menu-mobile/controller-menu-mobile.component';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    SearchComponent,
    ReactiveFormsModule,
    TranslateModule,
    QaTagsDirective,
    MemoizeFuncPipe,
    ControllerMenuComponent,
    ControllerMenuMobileComponent,
  ],
  selector: 'ls-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  animations: [fadeInOutFast],
})
export class TopMenuComponent implements OnInit, OnDestroy {
  @Input() isOnline: boolean = false;

  // injects
  languageService: LanguageService = inject(LanguageService);
  menuService: MenuService = inject(MenuService);
  platformService: PlatformService = inject(PlatformService);
  router: Router = inject(Router);
  activatedRouteService: ActivatedRouteService = inject(ActivatedRouteService);
  searchMobileService: SearchMobileService = inject(SearchMobileService);
  private store: Store = inject(Store);

  //subs
  sub$ = new Subscription();
  selectedControllerIsActive$: Observable<boolean> = this.store.select(selectedControllerIsActive);
  activeHouseNumber$: Observable<number> = this.store.select(selectCurrentControllerHouseNumber);
  currentUserInitials$: Observable<string> = this.store.select(selectCurrentUserInitials);
  controllerMenuIsOpened$: Observable<boolean> = this.store.select(selectIsMenuOpened);

  // enums
  ColorsEnum = ColorsEnum;
  ControllerLanguageEnum = ControllerLanguageEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;

  ngOnInit(): void {
    this.sub$.add(
      this.selectedControllerIsActive$.pipe(
        wasChanged(),
      ).subscribe(() => {
        this.store.dispatch(toggleMenuIsOpened({ menuIsOpened: false }));
      }),
    );
  }

  closeSearch(): void {
    this.searchMobileService.isSearchOpened$.next(false);
  }

  openSearch(): void {
    this.searchMobileService.isSearchOpened$.next(true);
  }

  async toggleMenuIsOpened(): Promise<void> {
    const menuIsOpened = await firstValueFrom(this.store.select(selectIsMenuOpened));
    this.store.dispatch(toggleMenuIsOpened({ menuIsOpened: !menuIsOpened }));
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
