import { Component, Input, forwardRef } from '@angular/core';
import { SvgIconComponent, DualToggleComponent } from '@livestock/ui';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        MatButtonToggleModule,
        SvgIconComponent,
        MemoizeFuncPipe,
    ],
    selector: 'lv-dual-toggle-with-label',
    templateUrl: 'dual-toggle-with-label.component.html',
    styleUrls: [
        '../dual-toggle.component.scss',
        'dual-toggle-with-label.component.scss',
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DualToggleWithLabelComponent),
            multi: true,
        },
    ],
})
export class DualToggleWithLabelComponent extends DualToggleComponent {
    @Input() label = '';
}