import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonComponent, LVInputComponent, SvgIconComponent, VerificationCodeComponent } from '@livestock/ui';
import { SecurityTicketService } from '../../services/security-ticket.service';
import { AuthService } from '../../services/auth.service';
import { Store } from '@ngrx/store';
import {
  EmailValidator,
  PasswordValidator,
  PhoneNumberValidator,
  RequiredTrimValidator,
} from '@livestock/shared/validators';
import { Subscription, take } from 'rxjs';
import { setFlashMessage } from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import {
  selectAddUserToFarmTicketView,
} from '@livestock/farms';
import { GlobalConstants } from '@livestock/shared/constants';
import { ColorsEnum, IconsEnum, StorageItem } from '@livestock/shared/enums';
import { LocalStorageService, PlatformService } from '@livestock/shared/services';
import { PasswordGeneratorUtils } from '@livestock/shared/utils';
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { AppRoutes } from '@livestock/shared/routes';
import { ControllerLanguageEnum } from '@livestock/controllers';
import { IGetAddUserToFarmTicketView, TicketFarmsService, setAddUserToFarmTicketView } from '@livestock/farms';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ClipboardModule,
    ButtonComponent,
    RouterModule,
    SvgIconComponent,
    TranslateModule,
    QaTagsDirective,
    LVInputComponent,
    VerificationCodeComponent,
  ],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  @ViewChild('phone') phoneRef: ElementRef;
  @ViewChild('password') passwordRef: ElementRef;
  sub$ = new Subscription();

  currStep = 1;
  isLoading = false;
  isDisabledResendCode = false;
  disableResendCodeTimeMs = 30 * 1000;
  inviteFarmUserTicketID: string;
  addUserToFarmTicketView: IGetAddUserToFarmTicketView;
  validateForm = false;

  form = new FormGroup(
    {
      email: new FormControl('', [Validators.required, EmailValidator]),
      verificationCode: new FormControl('', [RequiredTrimValidator]),
      fullName: new FormControl('', [RequiredTrimValidator]),
      mobile: new FormControl('', [PhoneNumberValidator]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        PasswordValidator,
      ]),
      ticketID: new FormControl('', [Validators.required]),
    },
  );

  IconsEnum: typeof IconsEnum = IconsEnum;
  GlobalConstants = GlobalConstants;
  ColorsEnum = ColorsEnum;
  AppRoutes = AppRoutes;

  constructor(
    public platformService: PlatformService,
    private readonly securityTicketService: SecurityTicketService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private clipboard: Clipboard,
    private readonly ticketFarmsService: TicketFarmsService,
  ) {
  }

  ngOnInit(): void {
    const { inviteFarmUserTicketID } = this.activatedRoute.snapshot.queryParams;
    if (inviteFarmUserTicketID) {
      this.inviteFarmUserTicketID = inviteFarmUserTicketID;
    }

    this.sub$.add(
      this.store
        .select(selectAddUserToFarmTicketView)
        .pipe(
          wasChangedAndNotNull(),
        )
        .subscribe((addUserToFarmTicketView) => {
          this.addUserToFarmTicketView = addUserToFarmTicketView;
          this.form.get('email')?.setValue(addUserToFarmTicketView.email);
        }),
    );

    this.sub$.add(
      this.form.valueChanges.subscribe(() => {
        this.validateForm = false;
      }),
    );
  }

  setStepToTheRoute(currStep: number): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        step: currStep,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true,
    });
  }

  navigateTo(route: string): void {
    this.router.navigate([`auth/${route}`]);
  }

  onSendVerificationCode(): void {
    if (this.form.get('email')?.invalid || this.isLoading) {
      this.validateForm = true;
      return;
    }
    ;

    this.isLoading = true;
    this.securityTicketService
      .signUp({
        email: this.form.value.email as string,
      })
      .subscribe({
        next: (res) => {
          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'FlashMessages.VerificationCodeSentNow',
              },
            }),
          );
          this.form.get('ticketID')?.setValue(res.ticketID);
          this.isLoading = false;
          this.currStep += 1;
          this.setStepToTheRoute(this.currStep);
        },
        error: (error) => {
          if (error?.error === 'UserAlreadyExist')
            this.form.get('email').setErrors({ email: true });

          this.isLoading = false;
        },
      });
  }

  onSendNewCode(): void {
    if (this.form.get('email')?.invalid) return;
    this.isLoading = true;
    this.isDisabledResendCode = true;
    this.securityTicketService
      .signUp({
        email: this.form.value.email as string,
      })
      .subscribe({
        next: (res) => {
          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'FlashMessages.VerificationCodeSentNow',
              },
            }),
          );
          this.form.get('ticketID')?.setValue(res.ticketID);
          this.isLoading = false;
          setTimeout(() => {
            this.isDisabledResendCode = false;
          }, this.disableResendCodeTimeMs);
        },
        error: () => {
          this.isLoading = false;
          this.isDisabledResendCode = false;
        },
      });
  }

  onVerificationCode(): void {
    if (!this.form.get('verificationCode').value) {
      return;
    }
    this.isLoading = true;
    this.securityTicketService
      .verifySighUp({
        ticketID: this.form.value.ticketID || '',
        code: this.form.value.verificationCode.trim() || '',
      })
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.currStep += 1;
          this.setStepToTheRoute(this.currStep);
        },
        error: (error) => {
          this.isLoading = false;
          this.form.controls.verificationCode.setValue('');
          if (error?.error?.trim() == GlobalConstants.TICKET_EXPIRED_ERROR) {
            this.form.controls.verificationCode.setErrors({ expired: true });
          } else {
            this.form.controls.verificationCode.setErrors({ wrong: true });
          }

          this.form.valueChanges.pipe(take(1)).subscribe(() => {
            this.form.controls.verificationCode.setErrors(null);
          });
        },
      });
  }

  onCreate(): void {
    if (this.form.invalid) return;
    this.isLoading = true;
    const { fullName, password, mobile, ticketID } = this.form.value;
    if (
      fullName == null ||
      password == null ||
      mobile == null ||
      ticketID == null
    )
      return;

    if (this.inviteFarmUserTicketID &&
      this.addUserToFarmTicketView.email === this.form.value.email
    ) {
      this.ticketFarmsService
        .createUserViaInvitation(
          this.inviteFarmUserTicketID,
          {
            fullName,
            mobile,
            password,
            language: LocalStorageService.getStorageItem(StorageItem.CurrentLanguage) != null
              ? +LocalStorageService.getStorageItem(StorageItem.CurrentLanguage)
              : ControllerLanguageEnum.EngUS,
          })
        .subscribe(() => {
          this.isLoading = false;
          this.store.dispatch(setAddUserToFarmTicketView({ addUserToFarmTicketView: null }));
          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                message: 'Auth.SignUp.AccountCreatedAndFarmAssigned',
                flashType: FlashMessageTypeEnum.Success,
                additionalInfo: {
                  farmName: this.addUserToFarmTicketView.farmName,
                },
              },
            }),
          );
          this.router.navigate(['/']);
        });

      return;
    }

    this.authService
      .signUp({
        fullName,
        mobile,
        password,
        ticketID,
        language: LocalStorageService.getStorageItem(StorageItem.CurrentLanguage) != null
          ? +LocalStorageService.getStorageItem(StorageItem.CurrentLanguage)
          : ControllerLanguageEnum.EngUS,
      })
      .subscribe(() => {
        this.isLoading = false;
        this.store.dispatch(
          setFlashMessage({
            flashMessage: {
              message: 'Auth.SignUp.AccountCreated',
              flashType: FlashMessageTypeEnum.Success,
            },
          }),
        );
        this.router.navigate(['/']);
      });
  }

  generatePassword(event: Event): void {
    event.stopPropagation();
    const password = PasswordGeneratorUtils.generatePassword(GlobalConstants.STRONG_PASSWORD_LENGTH);
    this.form.controls.password.setValue(password);
    this.form.controls.password.markAsDirty();
    this.form.controls.password.updateValueAndValidity();
  }

  copyPassword(event: Event): void {
    event.stopPropagation();
    this.clipboard.copy(this.form.controls.password.value);
  }

  focusPhone(): void {
    this.phoneRef['inputRef'].nativeElement.focus();
  }

  focusPassword(): void {
    this.passwordRef['inputRef'].nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
