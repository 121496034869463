import { createSelector } from '@ngrx/store';
import { LocalStorageService } from '@livestock/shared/services';
import { ControllerUnitEnum } from '@livestock/controllers/enums';
import {
  LengthUnitEnum,
  SectorTypeEnum,
  StorageItem,
} from '@livestock/shared/enums';
// TODO: Fix direct importing files from libs;
import { selectControllers } from '../../../../../../farms/src/lib/+state/farms.selectors';
import { FarmControllerStatusEnum } from '../../../../../../farms/src/lib/enum/farm-controller-status.enum';
import { EventCodeEnum, SocketEventControllerTypeEnum, selectWebSocketEvents } from '@livestock/web-sockets';
import { getCurrentControllerState } from '../common/current-controller-main.selector';
import { selectControllerGeneralSettings } from '../settings/settings.selectors';
import { PageTypeEnum } from '@livestock/shared/enums';
import { UnitsHelper } from '@livestock/shared/utils';

export const selectControllerInfo = createSelector(
  getCurrentControllerState,
  ({ controllerInfo }) => controllerInfo && {
    ...controllerInfo,
    // Mocked data
    day: 4,
    temp: 28.4,
    humidity: 71,
    score: 5.1,
    name: `House ${controllerInfo.houseNumber}`,
    status: FarmControllerStatusEnum.FullHouse,
    connected: true,
  },
);

export const selectActiveControllerID = createSelector(
  getCurrentControllerState,
  ({ activeControllerID }) => {
    return activeControllerID ||
      (LocalStorageService.getStorageItem(StorageItem.ActiveControllerID)
        ? Number(LocalStorageService.getStorageItem(StorageItem.ActiveControllerID))
        : null);
  },
);

export const selectActiveControllerPage = createSelector(
  getCurrentControllerState,
  ({ page }) => page,
);

export const selectedControllerIsActive = createSelector(
  getCurrentControllerState,
  selectControllers,
  ({ activeControllerID }, controllers) => {
    const currentControllerID = activeControllerID
      || (LocalStorageService.getStorageItem(StorageItem.ActiveControllerID)
        ? Number(LocalStorageService.getStorageItem(StorageItem.ActiveControllerID))
        : null);
    if (!currentControllerID) return null;

    const controller = controllers?.find(controller => controller.controllerID === currentControllerID);
    if (!controller) return null;

    return controller.status === FarmControllerStatusEnum.FullHouse;
  },
);

export const selectCurrentControllerLengthUnit = createSelector(
  selectControllerGeneralSettings,
  (generalSettings) => {
    switch (generalSettings?.units) {
      case ControllerUnitEnum.Metric:
        return LengthUnitEnum.Meter;
      case ControllerUnitEnum.Imperial:
        return LengthUnitEnum.Foot;
      default:
        return generalSettings?.unitsDetails?.length;
    }
  },
);

export const selectCurrentControllerTemperatureUnit = createSelector(
  selectControllerInfo,
  (controllerInfo) => {
    return UnitsHelper.getTemperatureUnit(controllerInfo);
  },
);

export const selectCurrentControllerWeightUnit = createSelector(
  selectControllerInfo,
  (controllerInfo) => {
    return UnitsHelper.getWeightUnit(controllerInfo);
  },
);

export const selectCurrentControllerPressureUnit = createSelector(
  selectControllerInfo,
  (controllerInfo) => {
    return UnitsHelper.getPressureUnit(controllerInfo);
  },
);

export const selectCurrentControllerVolumeUnit = createSelector(
  selectControllerInfo,
  (controllerInfo) => {
    return UnitsHelper.getVolumeUnit(controllerInfo);
  },
);

export const selectControllerLanguage = createSelector(
  getCurrentControllerState,
  ({ controllerLanguage }) => controllerLanguage,
);

export const selectCurrentControllerHouseNumber = createSelector(
  getCurrentControllerState,
  ({ controllerInfo }) => controllerInfo?.houseNumber,
);

export const selectCurrentControllerIsLoading = createSelector(
  getCurrentControllerState,
  ({ isLoading }) => isLoading,
);

export const selectCurrentControllerIsDisconnected = createSelector(
  getCurrentControllerState,
  ({ isDisconnected }) => isDisconnected,
);

export const selectCnfStatus = createSelector(
  getCurrentControllerState,
  ({ cnfStatus }) => cnfStatus?.status,
);

export const selectCurrentControllerHoursFormat = createSelector(
  getCurrentControllerState,
  ({ controllerInfo }) => controllerInfo?.hoursFormat,
);

export const selectCnfStatusControllerEvents = (sectorTypes: SectorTypeEnum[]): any => createSelector(
  selectWebSocketEvents,
  selectActiveControllerID,
  ({ events }: any, activeControllerID: number | null): any => {
    const syncEvents = events
      .filter((e) => e.ec === EventCodeEnum.Controller
        && e.controlUnitID === activeControllerID
        && e.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.Sync);
    const lastSyncEvent = syncEvents[syncEvents.length - 1]?.dataEvent;

    if (lastSyncEvent?.isSync === false) {
      const cnfStatusControllerEvents = events
        .filter((e) => e.ec === EventCodeEnum.Controller
          && e.controlUnitID === activeControllerID
          && e.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.CnfStatus
          && sectorTypes.includes(e.dataEvent?.sectorType));
      if (cnfStatusControllerEvents?.length === 0) {
        return [];
      }
      return cnfStatusControllerEvents.map(x => x.dataEvent);
    }

    return null;
  });

export const selectRefreshPageControllerEvent = (page: PageTypeEnum): any => createSelector(
  selectWebSocketEvents,
  selectActiveControllerID,
  ({ events }: any, activeControllerID: number | null): any => {
    const refreshPageControllerEvents = events
      .filter((e) => e.ec === EventCodeEnum.Controller
        && e.controlUnitID === activeControllerID
        && e.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.RefreshPage
        && e.dataEvent?.pageNumber === page);
    if (refreshPageControllerEvents?.length === 0) {
      return null;
    }
    return refreshPageControllerEvents[refreshPageControllerEvents.length - 1];
  });
