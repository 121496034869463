import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DialogsService } from '@livestock/shared/services';
import { ButtonTypeEnum } from '@livestock/shared/enums';
import { IConfirmDialogData } from '@livestock/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  private dialogsService: DialogsService = inject(DialogsService);
  private translateService: TranslateService = inject(TranslateService);

  confirmFormLeaving(confirmData?: Partial<IConfirmDialogData>): Observable<boolean> {
    return this.dialogsService.confirm({
      title: this.translateService.instant(confirmData?.title || 'Dialogs.SaveChangesBeforeLeaving'),
      description: this.translateService.instant(confirmData?.description || 'Dialogs.IfYouLeaveNowAllChangesOnThisPageWillBeLost'),
      type: confirmData?.type || 'danger',
      confirmLabelKey: confirmData?.confirmLabelKey || 'Buttons.Leave',
      confirmButtonType: confirmData?.confirmButtonType || ButtonTypeEnum.ALERT,
      cancelLabelKey: confirmData?.cancelLabelKey || 'Buttons.Cancel',
      cancelButtonType: confirmData?.cancelButtonType || ButtonTypeEnum.SECONDARY,
      panelClass: confirmData?.panelClass || ['confirm-dialog__panel-with-margins'],
      backdropClass: confirmData?.backdropClass || ['confirm-dialog__backdrop-with-paddings'],
      reverseOrder: confirmData?.reverseOrder,
    });
  }

  confirmDeletingSensorElement(): Observable<boolean> {
    return this.dialogsService.confirm({
      title: this.translateService.instant('Dialogs.UninstallSensor'),
      description: this.translateService.instant('Dialogs.ThisActionWillDeleteThisSensorAndAllItsSettings'),
      type: 'danger',
      confirmButtonType: ButtonTypeEnum.PRIMARY,
      confirmLabelKey: 'Buttons.Continue',
    });
  }
}
