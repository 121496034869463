import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonTypeEnum, ColorsEnum, IconsEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import {
  FlockConstants,
  HouseModeEnum,
  IFlockSettingsView,
  StagingEnum,
} from '@livestock/flock/models';
import {
  DualToggleComponent,
  LVInputDecimalWithLabelComponent,
  LVInputIntegerWithLabelComponent,
  LvInputTimeComponent,
  RangeComponent,
  SlimButtonComponent,
  SvgIconComponent,
  ThemeEnum,
  ValueSliderComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {
  HoursFormatTypeEnum,
  selectCurrentControllerHoursFormat,
  selectCurrentControllerTemperatureUnit,
} from '@livestock/controllers';
import { first, lastValueFrom, Observable, Subscription } from 'rxjs';
import { EnumPipe } from '@livestock/shared/pipes';
import { MatDialog } from '@angular/material/dialog';
import { ChangeHouseModeDialogComponent } from '../change-house-mode-dialog/change-house-mode-dialog.component';
import { NewFlockDialogComponent } from '../new-flock-dialog/new-flock-dialog.component';
import { selectFlockSettings } from '../../+state/flock.selectors';
import { wasChanged, wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'lv-flock-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    DualToggleComponent,
    FormsModule,
    LVInputIntegerWithLabelComponent,
    ReactiveFormsModule,
    SvgIconComponent,
    TranslateModule,
    SlimButtonComponent,
    LvInputTimeComponent,
    EnumPipe,
    LVInputDecimalWithLabelComponent,
    RangeComponent,
    ValueSliderComponent,
  ],
  templateUrl: './flock-settings-form.component.html',
  styleUrls: ['./flock-settings-form.component.scss'],
})
export class FlockSettingsFormComponent implements OnInit, OnDestroy {
  @Input() isEditMode: boolean;
  @Output() changed = new EventEmitter();

  sub$ = new Subscription();

  private store: Store = inject(Store);
  private dialog: MatDialog = inject(MatDialog);

  flockSettings$: Observable<IFlockSettingsView> = this.store.select(selectFlockSettings);
  hoursFormat$: Observable<HoursFormatTypeEnum> = this.store.select(selectCurrentControllerHoursFormat);
  temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(selectCurrentControllerTemperatureUnit);

  form = new FormGroup({
    controllerID: new FormControl<number>(0),
    flockNumber: new FormControl<number>(1),
    separateMaleAndFemale: new FormControl<boolean>(false),
    initialNumberOfBirds: new FormControl<number>(0, [
      Validators.min(FlockConstants.InitialNumberOfBirdsMin),
      Validators.max(FlockConstants.InitialNumberOfBirdsMax),
    ]),
    maleInitialNumberOfBirds: new FormControl<number>(0, [
      Validators.min(FlockConstants.MaleInitialNumberOfBirdsMin),
      Validators.max(FlockConstants.MaleInitialNumberOfBirdsMax),
    ]),
    femaleInitialNumberOfBirds: new FormControl<number>(0, [
      Validators.min(FlockConstants.FemaleInitialNumberOfBirdsMin),
      Validators.max(FlockConstants.FemaleInitialNumberOfBirdsMax),
    ]),
    staging: new FormControl<StagingEnum>(StagingEnum.FullHouse),
    growStartDayTime: new FormControl<number>(0),
    growthDay: new FormControl<number>(-4, [
      Validators.min(FlockConstants.GrowthDayMin),
      Validators.max(FlockConstants.GrowthDayMax),
    ]),
    houseMode: new FormControl<HouseModeEnum>(HouseModeEnum.Empty),
    targetTemperature: new FormControl<number>(0),
    enableTemperatureAlarm: new FormControl<boolean>(true),
    ventilationLevel: new FormControl<number>(1, [
      Validators.min(FlockConstants.MinVentilation),
      Validators.max(FlockConstants.MaxVentilation),
    ]),

    light: new FormControl<number>(0, [
      Validators.min(FlockConstants.MinLight),
      Validators.max(FlockConstants.MaxLight),
    ]), // ???????
    lights: new FormControl<boolean>(true), // ???????
  });
  initialFormValue: IFlockSettingsView;

  //vars
  maxNumberPerGenderMessageIsShown: boolean;
  maxNumberOfBirdsMessageIsShown: boolean;
  growthDayFocused: boolean;
  temperatureFieldFocused: boolean;
  maxTemperature: number;

  //enums
  IconsEnum: typeof IconsEnum = IconsEnum;
  FlockConstants = FlockConstants;
  StagingEnum = StagingEnum;
  ThemeEnum = ThemeEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  HoursFormatTypeEnum = HoursFormatTypeEnum;
  ColorsEnum = ColorsEnum;
  HouseModeEnum = HouseModeEnum;
  TemperatureUnitEnum = TemperatureUnitEnum;

  get isGrowingOrPreheat(): boolean {
    return [HouseModeEnum.Growing, HouseModeEnum.PreHeat].includes(this.form.value.houseMode);
  }

  get isCatchingOrCleaning(): boolean {
    return [HouseModeEnum.Catching, HouseModeEnum.Cleaning].includes(this.form.value.houseMode);
  }

  ngOnInit(): void {
    this.sub$.add(
      this.flockSettings$.pipe(
        wasChangedAndNotNull(),
      ).subscribe(settings => {
        this.form.patchValue(settings);
        this.initialFormValue = this.form.getRawValue();
      }),
    );

    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged())
        .subscribe(() => {
          this.changed.emit({
            isChanged: JSON.stringify(this.initialFormValue) !== JSON.stringify(this.form.value),
          });
        }),
    );

    this.temperatureUnit$.pipe(
      first(),
    ).subscribe(tempUnit => {
      this.maxTemperature = tempUnit === TemperatureUnitEnum.Celsius
        ? FlockConstants.MaxTargetTemperatureCelsius
        : FlockConstants.MaxTargetTemperatureFahrenheit;

      this.form.controls.targetTemperature.setValidators([
        Validators.min(FlockConstants.MinTargetTemperature),
        Validators.max(this.maxTemperature),
      ]);
      this.form.updateValueAndValidity();
    });
  }

  showMaxNumberPerGenderMessage(isShowed: boolean): void {
    this.maxNumberPerGenderMessageIsShown = isShowed;
  }

  showMaxNumberOfBirdsMessage(isShowed: boolean): void {
    this.maxNumberOfBirdsMessageIsShown = isShowed;
  }

  async openChangeHouseModePopup(): Promise<void> {
    if (!this.isEditMode) return;

    const dialogRef = this.dialog.open(ChangeHouseModeDialogComponent, {
      data: { currentHouseMode: this.form.value.houseMode },
      disableClose: true,
      panelClass: 'transparent-bg',
      backdropClass: 'blurred-background',
    });

    const result = await lastValueFrom(dialogRef.afterClosed());
    if (result != null) {
      this.form.controls.houseMode.setValue(result.houseMode);
    }
  }

  openNewFlockDialog(): void {
    this.dialog.open(NewFlockDialogComponent, {
      disableClose: true,
      panelClass: 'transparent-bg',
      backdropClass: 'blurred-background',
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
