import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, ParamMap } from '@angular/router';
import {
  ActivatedRouteService,
  BuildInfoService,
  PlatformService,
  LanguageService,
  LocalStorageService,
} from '@livestock/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe, EnumPipe } from '@livestock/shared/pipes';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SvgIconComponent, RadioListComponent } from '@livestock/ui';
import { IconsEnum, StorageItem } from '@livestock/shared/enums';
import { ControllerLanguageEnum } from '@livestock/controllers';
import { Subscription, map } from 'rxjs';
import { slideInRightWeb } from './animation';
import { AppRoutes } from '@livestock/shared/routes';

@Component({
  standalone: true,
  selector: 'ls-web-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    HttpClientModule,
    QaTagsDirective,
    MemoizeFuncPipe,
    EnumPipe,
    SvgIconComponent,
    RadioListComponent,
  ],
  animations: [slideInRightWeb],
})
export class AuthComponent implements OnInit, OnDestroy {
  @ViewChild('toggleMenuLanguages') toggleMenuLanguagesRef: ElementRef;
  @ViewChild('menuLanguages') menuLanguagesRef: ElementRef;
  showLoginInFooter = false;
  showLanguages = false;
  sub$: Subscription = new Subscription();
  language = LocalStorageService.getStorageItem(StorageItem.CurrentLanguage) != null
    ? +LocalStorageService.getStorageItem(StorageItem.CurrentLanguage)
    : ControllerLanguageEnum.EngUS;
  readonly languagesList = [
    {
      value: ControllerLanguageEnum.EngUS,
      title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.EngUS),
      cssClass: 'fw-600',
    },
    {
      value: ControllerLanguageEnum.Spanish,
      title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.Spanish),
    },
    {
      value: ControllerLanguageEnum.Portuguese,
      title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.Portuguese),
    },
  ];
  IconsEnum = IconsEnum;
  AppRoutes = AppRoutes;

  constructor(
    public platformService: PlatformService,
    public router: Router,
    public activatedRouteService: ActivatedRouteService,
    public buildInfoService: BuildInfoService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.menuLanguagesRef?.nativeElement.contains(event.target) &&
      !this.toggleMenuLanguagesRef?.nativeElement.contains(event.target)) {
      this.closeLanguagesMenu();
    }
  }

  ngOnInit(): void {
    this.sub$.add(
      this.activatedRoute.queryParamMap.pipe(
        map((params: ParamMap) => params.get('step')),
      ).subscribe((step: any) => {
        this.showLoginInFooter = step > 1;
      }),
    );
  }

  navigateTo(route: string): void {
    this.router.navigate([`auth/${route}`]);
  }

  openLanguagesMenu(): void {
    this.showLanguages = true;
  }

  closeLanguagesMenu(): void {
    this.showLanguages = false;
  }

  changeLanguage(language: ControllerLanguageEnum): void {
    this.language = language;
    this.languageService.changeLanguage(language);
    this.languagesList.forEach(languageOption => {
      if (languageOption.value === language) {
        languageOption['cssClass'] = 'fw-600';
      } else {
        languageOption['cssClass'] = undefined;
      }
    });
    this.closeLanguagesMenu();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
