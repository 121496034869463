import { FormGroup } from '@angular/forms';

export const getFormGroupErrors = (formGroup: FormGroup): { [key: string]: any } | null => {
    const errors: { [key: string]: any } = {};
    Object.keys(formGroup.controls).forEach(key => {
      const controlErrors = formGroup?.get(key)?.errors;
      if (controlErrors) {
        errors[key] = controlErrors;
      }
    });
    return Object.keys(errors).length ? errors : null;
  };