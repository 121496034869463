import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { EnumPipe } from '@livestock/shared/pipes';
import {
  DualToggleComponent,
  LoadingGalconComponent,
  LVInputComponent,
  LvInputDateComponent,
  LvInputTimeComponent,
  setVirtualKeyboardAMPMDisabled,
  SvgIconComponent,
  ToggleComponent,
  ToggleNewComponent,
} from '@livestock/ui';
import { IDateTimeSettingsView } from '../../interfaces/date-time/date-time-settings-view.interface';
import { HoursFormatTypeEnum } from '../../enums/date-time/hours-format-type.enum';
import { AutoDateTimeTypeEnum } from '../../enums/date-time/auto-date-time-type.enum';
import { Observable, Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { IconsEnum } from '@livestock/shared/enums';
import { ControllerLanguageEnum } from '@livestock/controllers/enums';
import { selectControllerLanguage } from '../../+state/current-controller/current-controller.selectors';
import { Store } from '@ngrx/store';
import { GlobalConstants } from '@livestock/shared/constants';

@Component({
  selector: 'lv-controller-date-time-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    EnumPipe,
    ToggleComponent,
    QaTagsDirective,
    LVInputComponent,
    ToggleNewComponent,
    DualToggleComponent,
    SvgIconComponent,
    LvInputDateComponent,
    LvInputTimeComponent,
    LoadingGalconComponent,
  ],
  templateUrl: './date-time-settings-form.component.html',
  styleUrls: ['./date-time-settings-form.component.scss'],
})
export class ControllerDateTimeSettingsFormComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean = false;

  @Input() set dateTimeSettings(dateTimeSettings: IDateTimeSettingsView) {
    if (dateTimeSettings && JSON.stringify(dateTimeSettings) !== JSON.stringify(this.dateTimeSettings)) {
      this.updateFormValues(dateTimeSettings);
    }
  }

  @Output() changed = new EventEmitter();
  // subs
  sub$ = new Subscription();
  language$: Observable<ControllerLanguageEnum> = this.store.select(selectControllerLanguage);

  // vars
  form = new FormGroup({
    controllerID: new FormControl<number>(null),
    autoDateTimeType: new FormControl(AutoDateTimeTypeEnum.Manually),
    hoursFormat: new FormControl(HoursFormatTypeEnum.AmPm),
    date: new FormControl<string>('', Validators.required),
    time: new FormControl<string>('', Validators.required),
  });

  // constants
  HoursFormatTypeEnum = HoursFormatTypeEnum;
  AutoDateTimeTypeEnum = AutoDateTimeTypeEnum;
  GlobalConstants = GlobalConstants;
  ControllerLanguageEnum = ControllerLanguageEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;

  constructor(
    public languageService: LanguageService,
    private platformService: PlatformService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.changed.emit({
      formValues: this.form.value,
      isValid: this.form.valid,
      dateTimeIsDirty: true,
    });
    this.disableAMPMOnKeyboard(this.form.value.hoursFormat === HoursFormatTypeEnum.HoursFormat24);

    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged()).subscribe((formValues) => {
        this.disableAMPMOnKeyboard(this.form.value.hoursFormat === HoursFormatTypeEnum.HoursFormat24);

        this.changed.emit({
          formValues,
          isValid: this.form.valid,
          dateTimeIsDirty: !this.form.controls.date.touched && !this.form.controls.time.touched,
        });
      }),
    );
  }

  disableAMPMOnKeyboard(AMPMDisabled: boolean): void {
    if (this.platformService.isDeviceApp) {
      this.store.dispatch(setVirtualKeyboardAMPMDisabled({ AMPMDisabled }));
    }
  }

  hoursFormatChanged(isToggled: boolean): void {
    const hoursFormat = isToggled ? HoursFormatTypeEnum.HoursFormat24 : HoursFormatTypeEnum.AmPm;
    this.form.controls.hoursFormat.setValue(hoursFormat);
  }

  updateFormValues(dateTimeSettings: IDateTimeSettingsView): void {
    this.form.patchValue({
      ...dateTimeSettings,
      autoDateTimeType: dateTimeSettings.autoDateTimeType ?? AutoDateTimeTypeEnum.Manually,
    });
  }

  updateValidity(): void {
    this.changed.emit({
      formValues: this.form.getRawValue(),
      isValid: this.form.valid,
      dateTimeIsDirty: false,
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
