import { Component, HostListener, Inject, Renderer2 } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  AppDisplayClientSseService,
  AppDisplayClientSseServiceHandler,
  displayActions,
  DisplayTopMenuComponent,
  DisplayTopMenuQuickStartComponent,
  selectBackendConnection,
  selectControllerVersion,
  selectIsNetworkManagerConnected,
  selectNewFirmwareAvailable,
  selectNewVersionAvailable,
  selectReloadRequired,
} from '@livestock/appdisplay';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessageComponent } from '@livestock/notifications';
import { delay, filter, forkJoin, mergeMap, of, retryWhen, Subscription, take, tap, throwError } from 'rxjs';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ActivatedRouteService,
  BuildInfoService,
  LanguageService,
  LocalStorageService,
  MenuService,
} from '@livestock/shared/services';
import { Store } from '@ngrx/store';
import {
  ControllerLanguageEnum,
  controllerLanguagesMapped,
  getNetworkStatusSuccess,
  getWifiList,
  selectControllerLanguage,
  setActiveControllerID,
  setControllerLanguage,
} from '@livestock/controllers';
import { GlobalConstants } from '@livestock/shared/constants';
import { StorageItem } from '@livestock/shared/enums';
import { TopMenuComponent } from '@livestock/menu';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { NetworkService } from '../../../../libs/controllers/src/lib/services/network.service';
import { QuickStartService } from '../../../../libs/controllers/src/lib/services/quick-start.service';
import { QuickStartStatusEnum } from '@livestock/controllers/enums';
import { AppRoutes } from '@livestock/shared/routes';
import { LoadingGalconComponent, VirtualKeyboardComponent } from '@livestock/ui';
import { MatSnackBar, MatSnackBarModule, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { LayoutDisplayComponent } from '@livestock/layout';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    DisplayTopMenuComponent,
    DisplayTopMenuQuickStartComponent,
    FlashMessageComponent,
    CommonModule,
    TopMenuComponent,
    MemoizeFuncPipe,
    VirtualKeyboardComponent,
    LoadingGalconComponent,
    MatSnackBarModule,
    LayoutDisplayComponent,
  ],
  selector: 'ls-display-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  sub$ = new Subscription();
  title = 'display';
  touchDebug = 0;
  private _snackbarRefNewVersionAvailable: null | MatSnackBarRef<TextOnlySnackBar> = null;
  private _snackbarRefNewFirmwareAvailable: null | MatSnackBarRef<TextOnlySnackBar> = null;
  private _snackbarRefAppReloadRequired: null | MatSnackBarRef<TextOnlySnackBar> = null;
  private _snackbarRefAppHasBeenUpdated: null | MatSnackBarRef<TextOnlySnackBar> = null;
  private _selectReloadRequired$ = this.store.select(selectReloadRequired).pipe(tap(val => val && this.snackbarAppReloadRequired()));
  private _selectNewVersionAvailable$ = this.store.select(selectNewVersionAvailable).pipe(tap(version => version !== null && this.snackbarNewVersionAvailable(version)));
  private _selectNewFirmwareAvailable$ = this.store.select(selectNewFirmwareAvailable).pipe(tap(version => version !== null && this.snackbarNewFirmwareAvailable(version)));
  selectIsSSEConnected$ = this.store.select(selectBackendConnection);
  selectIsNetworkManagerConnected$ = this.store.select(selectIsNetworkManagerConnected);
  selectControllerVersion$ = this.store.select(selectControllerVersion);

  @HostListener('window:mousedown')
  @HostListener('window:mousemove')
  @HostListener('window:touchstart')
  @HostListener('window:keydown')
  onEvent(): void {
    this.touchDebug += 1;
  }

  constructor(
    public menuService: MenuService,
    public languageService: LanguageService,
    public activatedRouteService: ActivatedRouteService,
    public router: Router,
    public buildInfoService: BuildInfoService,
    private readonly translateService: TranslateService,
    private readonly AppDisplayClientSseService: AppDisplayClientSseService,
    private readonly appDisplayClientSseServiceHandler: AppDisplayClientSseServiceHandler,
    private store: Store,
    private quickStartService: QuickStartService,
    private networkService: NetworkService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private _snackBar: MatSnackBar,
  ) {
    this.translateService.addLangs([
      controllerLanguagesMapped.get(ControllerLanguageEnum.EngUS),
      controllerLanguagesMapped.get(ControllerLanguageEnum.Hebrew),
    ]);
    this.translateService.setDefaultLang(controllerLanguagesMapped.get(ControllerLanguageEnum.EngUS));
    const storedLanguage: ControllerLanguageEnum = +LocalStorageService.getStorageItem(StorageItem.CurrentLanguage);
    this.store.dispatch(setControllerLanguage({ controllerLanguage: storedLanguage ?? ControllerLanguageEnum.EngUS }));

    const lang = controllerLanguagesMapped.get(+storedLanguage) || controllerLanguagesMapped.get(ControllerLanguageEnum.EngUS);
    this.translateService.use(lang);
    document.dir = lang === controllerLanguagesMapped.get(ControllerLanguageEnum.Hebrew) ? 'rtl' : 'ltr';

    // TODO: use actual controllerID
    this.store.dispatch(setActiveControllerID({ controllerID: GlobalConstants.HardCodedControllerIDForDevice }));
  }

  snackbarNewVersionAvailable(version: string): void {
    if (this._snackbarRefNewVersionAvailable != null) return;
    const updatesnackBarRef = this._snackBar.open(`Application update v.${version} is available!`, 'Install', {
      panelClass: 'updater',
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    updatesnackBarRef.onAction().subscribe(() => {
      this._snackbarRefNewVersionAvailable = null;
      this.store.dispatch(displayActions.readyToInstall(true));
    });
  }

  snackbarNewFirmwareAvailable(version: string): void {
    if (this._snackbarRefNewFirmwareAvailable != null) return;
    const updatesnackBarRef = this._snackBar.open(`New firmware v.${version} is available!`, 'Install', {
      panelClass: 'updater',
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    updatesnackBarRef.onAction().subscribe(() => {
      this._snackbarRefNewFirmwareAvailable = null;
      this.store.dispatch(displayActions.readyToFirmware(true));
    });
  }

  snackbarAppReloadRequired(): void {
    if (this._snackbarRefAppReloadRequired != null) return;
    const updatesnackBarRef = this._snackBar.open('Application reload required!', 'Reload now', {
      panelClass: 'updater',
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    updatesnackBarRef.onAction().subscribe(() => {
      this._snackbarRefAppReloadRequired = null;
      location.reload();
    });
  }

  snackbarAppHasBeenUpdated(): void {
    if (this._snackbarRefAppHasBeenUpdated != null) return;
    const updatesnackBarRef = this._snackBar.open('Application has been updated!', 'OK', {
      panelClass: 'updater',
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    updatesnackBarRef.onAction().subscribe(() => {
      this._snackbarRefAppHasBeenUpdated = null;
    });
  }

  ngOnInit(): void {
    this.sub$.add(this._selectNewVersionAvailable$.subscribe());
    this.sub$.add(this._selectNewFirmwareAvailable$.subscribe());
    this.sub$.add(this._selectReloadRequired$.subscribe());
    this.sub$.add(this.AppDisplayClientSseService.sseEvents$.subscribe(
      (ev) => this.appDisplayClientSseServiceHandler.handle(JSON.parse(ev))),
    );

    this.sub$.add(
      this.store.select(selectControllerLanguage).pipe(
        filter(res => res != null),
      ).subscribe(lang => {
        this.translateService.use(controllerLanguagesMapped.get(lang));
        document.dir = lang === ControllerLanguageEnum.Hebrew ? 'rtl' : 'ltr';
      }),
    );

    this.sub$.add(
      this.buildInfoService.json$.pipe(filter((res) => res != null)).subscribe((versionInfo) => {
        this.store.dispatch(displayActions.buildInfo(versionInfo));
        const storedVersion = LocalStorageService.getStorageItem(StorageItem.AppVersion);
        const currentVersion = `${versionInfo.version}#${versionInfo.commit}#${versionInfo.buildNumber}`;

        if (storedVersion !== currentVersion) {
          LocalStorageService.removeAllExcept([StorageItem.Token, StorageItem.CurrentLanguage, StorageItem.ActiveControllerID]);
          LocalStorageService.setStorageItem(StorageItem.AppVersion, currentVersion);
          this.snackbarAppHasBeenUpdated();
        }
      }),
    );

    // Redirect to Dashboard if quick start process was completed
    // Get Current network status before redirecting
    forkJoin({
      networkStatus: this.networkService.getNetworkStatus(GlobalConstants.HardCodedControllerIDForDevice).pipe(
        retryWhen(errors =>
          errors.pipe(
            tap(() => {
              console.log(`retrying networkStatus`);
            }),
            mergeMap(error => {
              // TODO: REMOVE AFTER WEB ENV IS TERMINATED
              if (window.location.host.includes('galconsmart.com')) {
                return throwError(() => new Error('stopAnimation'));
              }
              return of(error).pipe(
                delay(2000),
                take(10),
              ); // Continue retrying
            }),
          ),
        ),
      ),
      quickstartStatus: this.quickStartService.getQuickstartStatus().pipe(
        retryWhen(errors =>
          errors.pipe(
            tap(() => {
              console.log(`retrying quickstartStatus`);
            }),
            mergeMap(error => {
              // TODO: REMOVE AFTER WEB ENV IS TERMINATED
              if (window.location.host.includes('galconsmart.com')) {
                return throwError(() => new Error('stopAnimation'));
              }
              return of(error).pipe(
                delay(2000),
                take(10),
              ); // Continue retrying
            }),
          ),
        ),
        delay(2000),
        take(10),
      ),
    }).subscribe(
      ({ networkStatus, quickstartStatus }) => {
        this.renderer.addClass(this.document.getElementById('preloader-holder'), 'animation-hide');
        window['stopAnimation'] = true;
        this.store.dispatch(getNetworkStatusSuccess({ networkStatus, overrideSettingsState: true }));
        if (networkStatus.connectionSettings?.wifi?.isActive) {
          this.store.dispatch(getWifiList());
        }
        if (QuickStartStatusEnum.Done === Number(quickstartStatus)) {
          this.router.navigate([AppRoutes.DASHBOARD]);
          return;
        } else {
          this.router.navigate(['/']);
        }
      },
      error => {
        if (error?.message === 'stopAnimation') {
          this.renderer.addClass(this.document.getElementById('preloader-holder'), 'animation-hide');
          window['stopAnimation'] = true;
        }
      },
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}

