import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@livestock/ui';
import { IconsEnum } from '@livestock/shared/enums';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'lv-uninstall-element',
  templateUrl: './uninstall-element.component.html',
  imports: [CommonModule, SvgIconComponent, TranslateModule],
  standalone: true,
})
export class UninstallElementComponent {
  @Input() isEditMode: boolean;
  @Output() uninstall = new EventEmitter<void>();

  IconsEnum: typeof IconsEnum = IconsEnum;

  uninstallEmit(): void {
    this.uninstall.emit();
  }
}
