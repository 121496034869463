import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LVInputComponent, SimpleDropdownComponent, SlimButtonComponent, SvgIconComponent } from '@livestock/ui';
import { Store } from '@ngrx/store';
import { DialogsService, PlatformService, LanguageService } from '@livestock/shared/services';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { EmailValidator, RequiredTrimValidator } from '@livestock/shared/validators';
import { ButtonTypeEnum, CommonUserRolesEnum, FarmUserRolesEnum, IconsEnum } from '@livestock/shared/enums';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  deleteFarmUser,
  FarmUserHelper,
  IFarm,
  inviteFarmUser,
  resendInviteFarmUser,
  selectActiveFarm,
  setUserDetailsMode,
  updateUser,
  UserDetailsModeEnum,
} from '@livestock/farms';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QaTagsDirective } from '@livestock/shared/directives';
import { IUser } from '@livestock/shared/interfaces';
import { EnumPipe, MemoizeFuncPipe } from '@livestock/shared/pipes';
import { StringUtils } from '@livestock/shared/utils';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    QaTagsDirective,
    LVInputComponent,
    SvgIconComponent,
    SimpleDropdownComponent,
    SlimButtonComponent,
    EnumPipe,
    MemoizeFuncPipe,
  ],
  selector: 'lv-farm-user-details',
  templateUrl: './farm-user-details.component.html',
  styleUrls: ['./farm-user-details.component.scss'],
})
export class FarmUserDetailsComponent implements OnInit, OnChanges {
  @ViewChild('rolesDropDown') rolesDropDown: SimpleDropdownComponent;

  @Input() mode = UserDetailsModeEnum.EditUser;
  @Input() user: IUser;
  @Input() currentUserRole: CommonUserRolesEnum;
  @Input() currenUserAccessRole: FarmUserRolesEnum;
  @Output() onGoBack: EventEmitter<void> = new EventEmitter<void>();

  public platformService: PlatformService = inject(PlatformService);
  public dialogsService: DialogsService = inject(DialogsService);
  public translateService: TranslateService = inject(TranslateService);
  public languageService: LanguageService = inject(LanguageService);
  private store: Store = inject(Store);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private formBuilder: FormBuilder = inject(FormBuilder);

  readonly rolePermissionsMap: Record<FarmUserRolesEnum, string[]> = {
    [FarmUserRolesEnum.Owner]: ['ManageFarm', 'ManageUsers'],
    [FarmUserRolesEnum.Admin]: ['ManageFarm', 'ManageUsers'],
    [FarmUserRolesEnum.Editor]: ['ManageFarm'],
    [FarmUserRolesEnum.Viewer]: ['ViewFarm'],
  };

  // vars
  form: FormGroup;
  initialFormValue: any;
  activeFarm: IFarm;
  invitationDateFormat = 'dd.MM.yy | HH:mm';

  // enums
  IconsEnum = IconsEnum;
  FarmUserRolesEnum = FarmUserRolesEnum;
  UserInvitationModeEnum = UserDetailsModeEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  FarmUserHelper = FarmUserHelper;
  StringUtils = StringUtils;

  ngOnInit(): void {
    this.form = this.getUserForm(this.user, this.mode);
    this.store.select(selectActiveFarm).pipe(
      wasChangedAndNotNull(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((activeFarm) => {
      this.activeFarm = activeFarm;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (JSON.stringify(changes?.['user']?.currentValue) !== JSON.stringify(changes?.['user']?.previousValue)) {
      this.form = this.getUserForm(this.user, this.mode);
      this.initialFormValue = { ...this.form.value };
      if (this.rolesDropDown) this.rolesDropDown.editMode = false;
    }
  }

  getUserForm = (user: IUser, mode: UserDetailsModeEnum): FormGroup<{ email, role, fullName, mobile }> => {
    return this.formBuilder.group({
      email: [
        { value: user?.email, disabled: mode !== UserDetailsModeEnum.NewInvitation },
        [RequiredTrimValidator, EmailValidator]],
      role: [
        mode === UserDetailsModeEnum.NewInvitation
          ? FarmUserRolesEnum.Viewer
          : user?.accessesRole,
        [Validators.required],
      ],
      fullName: [
        {
          value: user?.fullName,
          disabled: true,
        },
      ],
      mobile: [
        {
          value: user?.mobile,
          disabled: true,
        },
      ],
    });
  };

  getFilteredRoles = (role: number): boolean => {
    switch (this.currenUserAccessRole) {
      case FarmUserRolesEnum.Owner:
        return true;
      case FarmUserRolesEnum.Admin:
        return role !== FarmUserRolesEnum.Owner;
      default:
        return this.currentUserRole === CommonUserRolesEnum.God;
    }
  };

  goBack(): void {
    this.onGoBack.emit();
  }

  onRemove(): void {
    this.dialogsService.confirm({
      title: this.translateService.instant('Farms.FarmUpdate.DeleteUser'),
      description: this.translateService.instant('Farms.FarmUpdate.ThisActionWillDeleteUser', [this.user.email]),
      type: 'danger',
      confirmLabelKey: 'Buttons.Delete',
      panelClass: ['confirm-dialog__panel-with-margins'],
      backdropClass: ['confirm-dialog__backdrop-with-paddings'],
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((confirm: boolean) => confirm && this.deleteUser());
  }

  private deleteUser(): void {
    this.store.dispatch(deleteFarmUser({ farmID: this.activeFarm.farmID, email: this.user.email }));
  }

  cancel(): void {
    this.store.dispatch(setUserDetailsMode({ userDetailsMode: null }));
  }

  sendInvitation(): void {
    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(inviteFarmUser({
      invitedUser: {
        email: this.form.value.email,
        accessesRole: this.form.value.role,
      },
    }));
  }

  resendInvitation(): void {
    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(resendInviteFarmUser({
      ticketID: this.user?.ticketID,
      invitedUser: {
        email: this.form.value.email,
        accessesRole: this.form.value.role,
      },
    }));
  }

  isUserFormChanged(form: FormGroup): boolean {
    return JSON.stringify(form?.value) !== JSON.stringify(this.initialFormValue);
  }

  updateUser(): void {
    this.store.dispatch(updateUser({ user: { ...this.user, accessesRole: this.form.value.role } }));
  }
}
