import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[qaTags]',
  standalone: true,
})
export class QaTagsDirective implements OnChanges {
  @Input() qaTags: string;

  constructor(
    private elementRef: ElementRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentValue, previousValue } = changes['qaTags'];

    if (currentValue !== previousValue) {
      if (previousValue) {
        previousValue.split(' ').forEach(value => {
          this.elementRef.nativeElement.classList.remove(value);
          this.elementRef.nativeElement.removeAttribute('data-testid');
        });
      }

      this.qaTags.split(' ').forEach(tag => {
        this.elementRef.nativeElement.classList.add(tag);
        this.elementRef.nativeElement.setAttribute('data-testid', tag);
      });
    }
  }
}
