import { SectorTypeEnum } from './sector-type.enum';

export enum PageTypeEnum {
  UnitsSettings = 1,
  HouseSizesSettings = 2,
  DateTimeSettings = 3,
  NetworkSettings = 4,
  FlockSettings = 5,
  FlockWeightsSettings = 6,
  Installation = 7,
}

export namespace PageTypeEnum {
  export function toSectorTypes(value: any): SectorTypeEnum[] {
    switch (value) {
      case PageTypeEnum.UnitsSettings:
        return [SectorTypeEnum.QuickStartGeneral, SectorTypeEnum.QuickStartCustomUnits];
      case PageTypeEnum.HouseSizesSettings:
        return [SectorTypeEnum.QuickStartHouseSizes];
      case PageTypeEnum.DateTimeSettings:
        return [SectorTypeEnum.QuickStartDateTime];
      case PageTypeEnum.NetworkSettings:
        return [SectorTypeEnum.QuickStartCloud];
      case PageTypeEnum.FlockSettings:
        return [SectorTypeEnum.FlockSettings];
      case PageTypeEnum.FlockWeightsSettings:
        return [SectorTypeEnum.FlockWeightsSettings, SectorTypeEnum.FlockBirdWeight];
      case PageTypeEnum.Installation:
        return [SectorTypeEnum.Connection];
      default:
        return value.toString();
    }
  }

  export function toTranslateKey(value: any): string {
    switch (value) {
      case PageTypeEnum.UnitsSettings:
        return 'ControllerSettings.PageType.UnitsSettings';
      case PageTypeEnum.HouseSizesSettings:
        return 'ControllerSettings.PageType.HouseSettings';
      case PageTypeEnum.DateTimeSettings:
        return 'ControllerSettings.PageType.DateTimeSettings';
      case PageTypeEnum.NetworkSettings:
        return 'ControllerSettings.PageType.NetworkSettings';
      case PageTypeEnum.FlockSettings:
        return 'ControllerSettings.PageType.FlockSettings';
      case PageTypeEnum.FlockWeightsSettings:
        return 'ControllerSettings.PageType.FlockWeightsSettings';
      case PageTypeEnum.Installation:
        return 'ControllerSettings.PageType.FlockHouseMode';
      default:
        return value.toString();
    }
  }
}
