export enum IconsAuthEnum {
  EARTH = 'auth/earth',
  EARTH_BLACK = 'auth/earth-black',
  PASSWORD = 'auth/password',
  LETTER = 'auth/letter',
  EYE = 'auth/eye',
  EYE_CLOSED = 'auth/eye-closed',
  GENERATE = 'auth/generate',
  USER = 'auth/user',
  PHONE = 'auth/phone',
  FACE_RECOGNITION = 'auth/face-recognition',
  CLOSE = 'auth/close',
  COPY = 'auth/copy',
}
