import { Component, Input, forwardRef } from '@angular/core';
import { RadioListComponent, SvgIconComponent } from '@livestock/ui';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { NativeElementInjectorDirective } from '../../native-element.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        QaTagsDirective,
        NativeElementInjectorDirective,
        SvgIconComponent,
        MatRadioModule,
        TranslateModule,
        RadioListComponent,
    ],
    selector: 'lv-radio-list-with-label',
    templateUrl: 'radio-list-with-label.component.html',
    styleUrls: [
        '../radio-list.component.scss',
        'radio-list-with-label.component.scss',
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioListWithLabelComponent),
            multi: true,
        },
    ],
})

export class RadioListWithLabelComponent extends RadioListComponent {
    @Input() label = '';
}