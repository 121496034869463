import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VirtualKeyboardState } from './virtual-keyboard.state';

export const VIRTUAL_KEYBOARD_KEY = 'virtualKeyboard';
export const getVirtualKeyboardState =
  createFeatureSelector<VirtualKeyboardState>(VIRTUAL_KEYBOARD_KEY);

export const selectVirtualKeyboardElementUUID = createSelector(
  getVirtualKeyboardState,
  (({ elementUuid }) => elementUuid),
);

export const selectVirtualKeyboardIsActive = createSelector(
  getVirtualKeyboardState,
  (({ elementUuid }) => !!elementUuid),
);

export const selectVirtualKeyboardMode = createSelector(
  getVirtualKeyboardState,
  (({ mode }) => mode),
);

export const selectVirtualKeyboardSymbol = createSelector(
  getVirtualKeyboardState,
  (({ symbol }) => symbol),
);

export const selectVirtualKeyboardSymbolAndUUID = createSelector(
  getVirtualKeyboardState,
  (({ symbol, elementUuid }) => {
    return {
      symbol,
      elementUuid,
    };
  }),
);

export const selectVirtualKeyboardRanges = createSelector(
  getVirtualKeyboardState,
  (({ ranges }) => ranges),
);

export const selectVirtualKeyboardLabelForRanges = createSelector(
  getVirtualKeyboardState,
  (({ labelForRanges }) => labelForRanges),
);

export const selectVirtualKeyboardAMPMAndUUID = createSelector(
  getVirtualKeyboardState,
  (({ elementUuid, AMPM }) => {
    return {
      elementUuid,
      AMPM,
    };
  }),
);

export const selectVirtualKeyboardAMPMDisabled = createSelector(
  getVirtualKeyboardState,
  (({ AMPMDisabled }) => AMPMDisabled),
);
