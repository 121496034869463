import { Action, createReducer, on } from '@ngrx/store';
import { FlockState } from './flock.state';
import {
  createNewFlock, createNewFlockError, createNewFlockSuccess,
  getCurrentControllerFlockSettings,
  getCurrentControllerFlockSettingsError,
  getCurrentControllerFlockSettingsSuccess,
  updateCurrentControllerFlockSettings,
  updateCurrentControllerFlockSettingsError, updateCurrentControllerFlockSettingsSuccess,
} from '@livestock/controllers';

export const FLOCK_FEATURE_KEY = 'flock';

export const initialState: FlockState = {
  isLoading: false,
  flockSettings: null,
  flockWeights: null,
  flockWeightsSettings: null,
};

const reducer = createReducer(
  initialState,
  on(
    getCurrentControllerFlockSettings,
    updateCurrentControllerFlockSettings,
    createNewFlock,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    getCurrentControllerFlockSettingsError,
    updateCurrentControllerFlockSettingsError,
    createNewFlockError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(
    getCurrentControllerFlockSettingsSuccess,
    updateCurrentControllerFlockSettingsSuccess,
    createNewFlockSuccess,
    (state, { flockSettings }) => {
      return {
        ...state,
        flockSettings,
        isLoading: false,
      };
    },
  ),
);

export function flockReducer(state: FlockState | undefined, action: Action): any {
  return reducer(state, action);
}
