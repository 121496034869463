import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as currentUserActions from './current-user.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';
import { TranslateService } from '@ngx-translate/core';
import { ControllerLanguageEnum, controllerLanguagesMapped } from '@livestock/controllers';
import { LocalStorageService } from '@livestock/shared/services';
import { StorageItem } from '@livestock/shared/enums';
import { setFlashMessage } from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';

@Injectable()
export class CurrentUserEffects {

  getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.getCurrentUser),
      switchMap(() => {
        return this.currentUserService.getCurrentUser().pipe(
          map((currentUser) => {
            this.updateCurrentLanguage(currentUser.language);
            return currentUserActions.getCurrentUserSuccess({ currentUser });
          }),
          catchError((error) => of(currentUserActions.getCurrentUserError({ payload: error }))),
        );
      }),
    ),
  );

  updateCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.updateCurrentUser),
      switchMap(({ currentUser }) => {
        return this.currentUserService.updateCurrentUser(currentUser).pipe(
          switchMap((currentUser) => {
            this.updateCurrentLanguage(currentUser.language);
            return [
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.UserWasSuccessfullyUpdated',
                },
              }),
              currentUserActions.updateCurrentUserSuccess({ currentUser }),
            ];
          }),
          catchError((error) => of(currentUserActions.updateCurrentUserError({ payload: error }))),
        );
      }),
    ),
  );

  updateCurrentUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.updateCurrentUserPassword),
      switchMap(({ password }) => {
        return this.currentUserService.updateCurrentUserPassword(password).pipe(
          switchMap(() => {
            return [
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.PasswordWasSuccessfullyUpdated',
                },
              }),
              currentUserActions.updateCurrentUserPasswordSuccess(),
            ];
          }),
          catchError((error) => of(currentUserActions.updateCurrentUserPasswordError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private currentUserService: CurrentUserService,
    private translateService: TranslateService,
  ) {
  }

  updateCurrentLanguage(language: ControllerLanguageEnum): void {
    const languageToString = controllerLanguagesMapped.get(language);
    this.translateService.use(languageToString);
    LocalStorageService.setStorageItem(StorageItem.CurrentLanguage, language);
  }
}
