import {
  ControllerMenuPagesEnum,
  IControllerPageItem,
} from '@livestock/menu';
import { ControllerRoutes } from '@livestock/controllers';
import { IconsEnum } from '@livestock/shared/enums';

export const flockSectionPages: IControllerPageItem[] = [
  {
    id: ControllerMenuPagesEnum.FlockSettings,
    title: 'ControllerMenu.Pages.FlockSettings',
    icon: IconsEnum.FLOCK_SETTINGS,
    route: ControllerRoutes.FlockSettings,
    qaTags: 'qa-mnu-flockSettings',
  },
  {
    id: ControllerMenuPagesEnum.GrowthTable,
    title: 'ControllerMenu.Pages.GrowthTable',
    icon: IconsEnum.GROWTH_TABLE,
    route: null,
    qaTags: 'qa-mnu-growthTable',
  },
  {
    id: ControllerMenuPagesEnum.GrowthChart,
    title: 'ControllerMenu.Pages.GrowthChart',
    icon: IconsEnum.GROWTH_CHART,
    route: null,
    qaTags: 'qa-mnu-growthChart',
  },
  {
    id: ControllerMenuPagesEnum.BirdManagement,
    title: 'ControllerMenu.Pages.BirdManagement',
    icon: IconsEnum.BIRD_MANAGEMENT,
    route: null,
    qaTags: 'qa-mnu-birdManagement',
  },
  {
    id: ControllerMenuPagesEnum.SiloManagement,
    title: 'ControllerMenu.Pages.SiloManagement',
    icon: IconsEnum.SILO_MANAGEMENT,
    route: null,
    qaTags: 'qa-mnu-siloManagement',
  },
];
