<div
  [ngClass]="{
        'step-warning': currentStep === ChangeHouseModeStepsEnum.Warning,
        'step-house-mode': currentStep === ChangeHouseModeStepsEnum.ChangeMode,
        'step-flock': currentStep === ChangeHouseModeStepsEnum.ChangeFlock,
        }"
  class="p-28 popup-wrapper">
  <ng-container [ngSwitch]="currentStep">
    <ng-container *ngSwitchCase="ChangeHouseModeStepsEnum.Warning">
      <h2 class="fz-24 fw-600 color-dark-red mb-8">{{ "Flock.ChangeHouseModeDialog.ChangeHouseMode" | translate }}</h2>
      <p class="fw-500 mb-28">{{ "Flock.ChangeHouseModeDialog.ThisActionWillChangeHouseMode" | translate }}</p>

      <div class="d-flex align-center justify-between">
        <span
          (click)="closePopup()"
          class="text-underline pointer">
          {{ "Buttons.Cancel" | translate }}
        </span>

        <lv-button
          (click)="currentStep = ChangeHouseModeStepsEnum.ChangeMode"
          [type]="ButtonTypeEnum.PRIMARY"
          class="pointer">
          {{ "Buttons.Continue" | translate }}
        </lv-button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="ChangeHouseModeStepsEnum.ChangeMode">
      <h2 class="fz-24 fw-600 mb-28">{{ "Flock.ChangeHouseModeDialog.ChangeHouseMode" | translate }}</h2>

      <div
        [formGroup]="form"
        class="mb-28">
        <lv-simple-dropdown
          [enum]="HouseModeEnum"
          [enumString]="'house-mode-enum'"
          [label]="'Flock.HouseMode' | translate"
          formControlName="houseMode"></lv-simple-dropdown>
      </div>

      <div class="d-flex align-center justify-between">
        <span
          (click)="closePopup()"
          class="text-underline pointer">
          {{ "Buttons.Cancel" | translate }}
        </span>

        <lv-button
          (click)="handleChangeHouseModeStep(data.currentHouseMode, form.value.houseMode)"
          [type]="data.currentHouseMode === form.value.houseMode ? ButtonTypeEnum.DISABLED : ButtonTypeEnum.PRIMARY"
          [ngClass]="data.currentHouseMode === form.value.houseMode ? 'pointer-events-none' : 'pointer'">
          {{ "Flock.ChangeHouseModeDialog.ChangeHouseMode" | translate }}
        </lv-button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="ChangeHouseModeStepsEnum.ChangeFlock">
      <h2 class="fz-24 fw-600 mb-8 color-dark-red">
        {{
          "Flock.ChangeHouseModeDialog.ChangeHouseModeToWithValue"
            | translate : { houseMode: HouseModeEnum.toTranslateKey(form.value.houseMode) | translate }
        }}
      </h2>

      <p class="fw-500 mb-18 fz-24">{{ "Flock.ChangeHouseModeDialog.WouldYouLikeToStartANewFlock" | translate }}</p>
      <p class="fw-500 mb-18">
        {{ "Flock.ChangeHouseModeDialog.NewFlockAllowsYouToStart" | translate }}
      </p>

      <div class="d-flex align-center justify-between">
        <span
          (click)="closePopup()"
          class="text-underline pointer">
          {{ "Buttons.Cancel" | translate }}
        </span>

        <div class="d-flex gap-18">
          <lv-button
            (click)="closeWithValue(form.value.houseMode)"
            [type]="ButtonTypeEnum.SECONDARY"
            class="pointer">
            {{ "Flock.ChangeHouseModeDialog.KeepCurrentFlock" | translate }}
          </lv-button>

          <lv-button
            (click)="openNewFlockDialog()"
            [type]="ButtonTypeEnum.PRIMARY"
            class="pointer">
            {{ "Flock.ChangeHouseModeDialog.StartNewFlock" | translate }}
          </lv-button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
