import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ILastEdited } from '@livestock/controllers/interfaces';
import { SetupUpdateStatusTypeEnum } from '@livestock/controllers/enums';
import { PageTypeEnum } from '@livestock/shared/enums';

/* EDIT MODE */
export const getEditMode = createAction(
  '[CURRENT CONTROLLER] Get Edit mode [...]',
  props<{ page: PageTypeEnum }>(),
);

export const getEditModeSuccess = createAction(
  '[CURRENT CONTROLLER] Get Edit mode [SUCCESS]',
  props<{ payload: any }>(),
);

export const getEditModeError = createAction(
  '[CURRENT CONTROLLER] Get Edit mode [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const createEditMode = createAction(
  '[CURRENT CONTROLLER] Create Edit mode [...]',
  props<{ page: PageTypeEnum }>(),
);

export const createEditModeSuccess = createAction(
  '[CURRENT CONTROLLER] Create Edit mode [SUCCESS]',
);

export const createEditModeError = createAction(
  '[CURRENT CONTROLLER] Create Edit mode [ERROR]',
  props<{ payload: HttpErrorResponse, page: PageTypeEnum }>(),
);

export const updateEditMode = createAction(
  '[CURRENT CONTROLLER] Update Edit mode [...]',
  props<{ page: PageTypeEnum }>(),
);

export const updateEditModeSuccess = createAction(
  '[CURRENT CONTROLLER] Update Edit mode [SUCCESS]',
);

export const updateEditModeError = createAction(
  '[CURRENT CONTROLLER] Update Edit mode [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const deleteEditMode = createAction(
  '[CURRENT CONTROLLER] Delete Edit mode [...]',
  props<{ page: PageTypeEnum }>(),
);

export const deleteEditModeSuccess = createAction(
  '[CURRENT CONTROLLER] Delete Edit mode [SUCCESS]',
);

export const deleteEditModeError = createAction(
  '[CURRENT CONTROLLER] Delete Edit mode [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setLastEditedSuccess = createAction(
  '[CURRENT CONTROLLER] Set Last Edited [SUCCESS]',
  props<{ lastEdited: ILastEdited }>(),
);

export const setLastEditedError = createAction(
  '[CURRENT CONTROLLER] Set Last Edited [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const resendControllerPageCnf = createAction(
  '[CURRENT CONTROLLER] Resend Controller Page Cnf [...]',
  props<{ page: PageTypeEnum }>(),
);

export const resendControllerPageCnfSuccess = createAction(
  '[CURRENT CONTROLLER] Resend Controller Page Cnf [SUCCESS]',
);

export const resendControllerPageCnfError = createAction(
  '[CURRENT CONTROLLER] Resend Controller Page Cnf [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setCnfStatus = createAction(
  '[CURRENT CONTROLLER] setCnfStatus [...]',
  props<{ status: SetupUpdateStatusTypeEnum }>(),
);
