<lv-settings-wrapper
  (toggleDisabled)="toggleDisabled($event)"
  (save)="onSave()"
  (onReload)="onReload()"
  section="{{ 'ControllerMenu.Sections.BasicSettings' | translate }}"
  title="{{ 'Controller.QuickStart.Units' | translate }}"
  [page]="PageTypeEnum.UnitsSettings"
  [isSuccess]="isSuccess"
  [isEditMode]="isEditMode"
  [isChanged]="isChanged"
  [isValid]="true">
  <ls-controller-general-settings-form
    *ngIf="generalSettings$ | async as generalSettings; else loading"
    (changed)="onChange($event)"
    [generalSettings]="generalSettings$ | async"
    [isDisabled]="!isEditMode">
  </ls-controller-general-settings-form>

  <ng-template #loading>
    <div class="h-100-percent w-100-percent d-flex align-center justify-center">
      <lv-loading-galcon></lv-loading-galcon>
    </div>
  </ng-template>
</lv-settings-wrapper>
