import { UserStatusesEnum } from '@livestock/shared/enums';
import { IUser } from '@livestock/shared/interfaces';

export class FarmUserHelper {
    static getUserInitialsColor = (user: IUser): string => {
      const colors: string[] = ['#1F7232', '#1C98A9', '#BF4359', '#581F72'];
      const colorIndex: number = (user?.userID || user?.fullName?.length || 0);
      return colors[colorIndex % colors.length];
    };

    static isUserInvitationExpired = (user: IUser): boolean => {
      return user.status === UserStatusesEnum.Expired;
    };
}
