<ng-container *ngIf="controller$ | async as controller; else getControllerTicketErrorTemplate">
  <ng-container *ngIf="!(isLoading$ | async); else loading">
    <main
      *ngIf="user"
      [ngClass]="currStep !== ScannedLoginStepsEnum.ScannedDeviceInfo ? 'justify-start' : 'justify-center'"
      class="scanned-login mx-auto">
      <ng-container [ngSwitch]="currStep">
        <ng-container *ngSwitchCase="ScannedLoginStepsEnum.ScannedDeviceInfo">
          <p class="fz-24 mb-28">
            {{ "LoginScanned.YouVeJustScanned" | translate }}
          </p>
          <ng-container
            *ngTemplateOutlet="
              deviceTemplate;
              context: {
                controller: controller
              }
            ">
          </ng-container>

          <ls-button
            *ngIf="(farms$ | async).length > 0"
            (click)="currStep = currStep + 1"
            [fontSize]="18"
            class="w-100-percent h-48 mt-28 d-flex justify-center">
            {{ "LoginScanned.ChooseFarm" | translate }}
          </ls-button>
          <ls-button
            *ngIf="(farms$ | async).length == 0"
            (click)="currStep = currStep + 1; selectFarmStepMode = 'create'"
            [fontSize]="18"
            class="w-100-percent h-48 mt-28 d-flex justify-center">
            {{ "LoginScanned.CreateYourFirstFarm" | translate }}
          </ls-button>
        </ng-container>

        <ng-container *ngSwitchCase="ScannedLoginStepsEnum.SelectingFarms">
          <ng-container
            *ngIf="selectFarmStepMode === 'select'"
            [ngTemplateOutlet]="selectFarmTemplate"></ng-container>
          <ng-container
            *ngIf="selectFarmStepMode === 'create'"
            [ngTemplateOutlet]="createFarmTemplate"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="ScannedLoginStepsEnum.SelectingTimeZones">
          <div class="d-flex align-center mt-18 mb-56 align-self-baseline gap-8">
            <ls-svg-icon
              (click)="currStep = currStep - 1"
              [path]="IconsEnum.GO_BACK_ARROW"></ls-svg-icon>

            <div class="color-f5">
              <p class="mb-4">
                <span class="color-grey">{{ "LoginScanned.Farms" | translate }}</span> / {{ selectedFarm?.name }}
              </p>

              <h2 class="fz-24">
                {{ "LoginScanned.AddNewHouse" | translate }}
              </h2>
            </div>
          </div>
          <div
            *ngIf="
              selectedFarm?.existingHouseNumbers?.includes(controller.houseNumber);
              else successHouseNumberTemplate
            ">
            <ls-svg-icon [path]="IconsEnum.SAD_SMILE"></ls-svg-icon>
            <div class="mt-16 fz-32 line-height-118 color-mono-bg">
              {{ "LoginScanned.House" | translate }}
              <span class="color-brightorange">{{ controller.houseNumber }}</span>
              {{ "LoginScanned.WrongHouseNumberMessage" | translate : { farmName: selectedFarm?.name } }}
            </div>
            <div class="fz-18 mt-28">{{ "LoginScanned.ChooseAnotherNumber" | translate }}</div>
            <div class="d-flex mt-8">
              <div
                [class.field-invalid]="
                  [houseNumber, selectedFarm?.existingHouseNumbers] | memoizeFunc : isInvalidHouseNumber
                "
                class="field">
                <ls-svg-icon
                  [color]="
                    [houseNumber, houseNumberInputInFocus, selectedFarm?.existingHouseNumbers]
                      | memoizeFunc : getControllerNumberIconColor
                  "
                  [path]="IconsEnum.CONTROLLER_NUMBER"></ls-svg-icon>

                <ls-input-integer
                  (onFocusIn)="onFocusInHouseNumberInput()"
                  (onFocusOut)="onFocusOutHouseNumberInput()"
                  [placeholder]="houseNumberPlaceholder | translate"
                  [class.ng-invalid]="
                    [houseNumber, selectedFarm?.existingHouseNumbers] | memoizeFunc : isInvalidHouseNumber
                  "
                  [min]="GlobalConstants.MIN_HOUSE_NUMBER"
                  [max]="GlobalConstants.MAX_HOUSE_NUMBER"
                  [(ngModel)]="houseNumber"
                  class="field-number">
                </ls-input-integer>
              </div>
              <ls-button
                (click)="saveNewHouseNumber(houseNumber)"
                [class.disabled]="
                  [houseNumber, selectedFarm?.existingHouseNumbers] | memoizeFunc : isInvalidHouseNumber
                "
                class="save-button ml-18 h-56">
                {{ "LoginScanned.Save" | translate }}
              </ls-button>
            </div>
            <div class="fz-14 color-red mt-8">
              <span *ngIf="houseNumber && selectedFarm?.existingHouseNumbers?.includes(houseNumber)">
                {{ "LoginScanned.HouseNumberWasAlreadyTakenWithValue" | translate : { houseNumber: houseNumber } }}
              </span>
              <span *ngIf="houseNumber && houseNumber > GlobalConstants.MAX_HOUSE_NUMBER">
                {{ "LoginScanned.MaximumNumberIs999" | translate }}
              </span>
              <span *ngIf="!houseNumber">
                {{ "LoginScanned.EnterHouseNumber" | translate }}
              </span>
            </div>
          </div>

          <ng-template #successHouseNumberTemplate>
            <ls-svg-icon [path]="IconsEnum.FUNNY_SMILE"></ls-svg-icon>
            <div class="mt-16 fz-32 line-height-118 color-mono-bg">
              {{ "LoginScanned.House" | translate }} <span class="color-brightgreen">{{ controller.houseNumber }}</span>
              {{ "LoginScanned.SuccessHouseNumberMessage" | translate : { farmName: selectedFarm?.name } }}
            </div>
            <ng-container [ngTemplateOutlet]="selectTimeZoneTemplate"></ng-container>
            <ls-button
              *ngIf="timeZoneControl?.value?.id"
              (click)="assign(selectedFarm, controller.houseNumber, timeZoneControl.value.id)"
              class="mt-18 h-48 d-flex justify-center">
              {{ "LoginScanned.AddScannedDevice" | translate }}
              <ls-svg-icon
                [path]="IconsEnum.CONTROLLER_ADD"
                [width]="'22px'"
                [height]="'18px'"
                class="d-flex align-center mh-10" />
            </ls-button>
          </ng-template>
        </ng-container>
      </ng-container>
    </main>
  </ng-container>
</ng-container>

<ng-template #selectFarmTemplate>
  <aside
    *ngIf="!selectedFarm; else selectedFarmTemplate"
    [class.farms-list--mobile]="platformService.isMobileVersion"
    [class.rtl]="languageService.isRtl"
    class="farms-list mt-18">
    <div class="content">
      <nav class="farms-list__list">
        <ul class="overflow-auto">
          <div class="farms-list__search">
            <div class="d-flex flex-row align-center">
              <span class="farms-list__my-farms">
                {{ "Farms.FarmsList.MyFarms" | translate }}
              </span>
            </div>

            <div
              (click)="selectFarmStepMode = 'create'"
              class="farms-list__add-farm-btn pointer">
              {{ "Farms.FarmsList.AddFarm" | translate }} +
            </div>
          </div>
          <ng-container *ngIf="(myFarmsFiltered$ | async)?.length; else noData">
            <li
              *ngFor="let farm of myFarmsFiltered$ | async; let i = index"
              (click)="selectFarmFromList(farm)"
              #myFarms
              [qaTags]="'qa-lnk-farm'"
              class="farms-list__farm">
              <ng-container
                *ngTemplateOutlet="
                  farmNameTemplate;
                  context: {
                    farm: farm,
                    selectedFarm: selectedFarm,
                    showRole: false
                  }
                ">
              </ng-container>
            </li>
          </ng-container>

          <ng-container *ngIf="(otherFarmsFiltered$ | async)?.length">
            <div class="farms-list__other-farms">
              {{ "Farms.FarmsList.OtherFarms" | translate }}
            </div>
            <li
              *ngFor="let farm of otherFarmsFiltered$ | async; let i = index"
              (click)="selectFarmFromList(farm)"
              #otherFarms
              [qaTags]="'qa-lnk-farm'"
              class="farms-list__farm">
              <ng-container
                *ngTemplateOutlet="
                  farmNameTemplate;
                  context: {
                    farm: farm,
                    selectedFarm: selectedFarm,
                    showRole: true
                  }
                ">
              </ng-container>
            </li>
          </ng-container>
        </ul>
      </nav>
      <div class="farms-list__footer">
        <a
          (click)="goToFarms()"
          class="link">
          {{ "LoginScanned.ForgetScannedDevice" | translate }}
        </a>
      </div>
    </div>
  </aside>
</ng-template>

<ng-template #selectedFarmTemplate>
  <div
    [class.farms-list__farm--selected__empty]="!selectedFarm?.existingHouseNumbers?.length"
    [qaTags]="'qa-lnk-farm'"
    class="farms-list__farm farms-list__farm--selected mt-18">
    <ng-container
      *ngTemplateOutlet="
        farmNameTemplate;
        context: {
          farm: selectedFarm,
          selectedFarm: selectedFarm,
          showRole: true
        }
      ">
    </ng-container>
  </div>
  <div class="d-flex flex-column justify-center h-100-percent">
    <ng-container
      *ngTemplateOutlet="
        deviceTemplate;
        context: {
          controller: (controller$ | async)
        }
      ">
    </ng-container>
    <ls-button
      (click)="currStep = currStep + 1"
      [fontSize]="18"
      class="w-100-percent h-48 mt-28 d-flex justify-center">
      {{ "LoginScanned.AddScannedDevice" | translate }}
      <ls-svg-icon
        [path]="IconsEnum.CONTROLLER_ADD"
        [width]="'22px'"
        [height]="'18px'"
        class="d-flex align-center mh-10" />
    </ls-button>
    <a
      *ngIf="(farms$ | async).length > 1 || !afterFarmCreation"
      (click)="selectFarmFromList(null)"
      class="link d-flex justify-center mt-36">
      {{ "LoginScanned.Cancel" | translate }}
    </a>
  </div>
</ng-template>

<ng-template
  #farmNameTemplate
  let-farm="farm"
  let-selectedFarm="selectedFarm"
  let-showRole="showRole">
  <div
    [class.farms-list__farm-name-wrapper__empty]="!farm?.existingHouseNumbers?.length"
    class="farms-list__farm-name-wrapper">
    <div
      [class.farms-list__farm-name__rtl]="isRtl"
      class="farms-list__farm-name fz-14">
      <div class="farms-list__farm-name__title">{{ farm.name }}</div>
      <div
        *ngIf="showRole"
        class="farms-list__farm-user-role mt-4 fz-12">
        <ng-container *ngIf="farm.roleType != null; else currentUserRole">
          {{ farm.roleType | enum : "farm-user-roles" | translate }}
        </ng-container>
        <ng-template #currentUserRole>
          {{ user.role | enum : "common-user-roles" | translate }}
        </ng-template>
      </div>
    </div>
    <div
      *ngIf="selectedFarm?.farmID !== farm.farmID"
      class="farms-list__farm-controller-count">
      <div class="mh-5">{{ farm?.existingHouseNumbers?.length || 0 }}</div>
      <ls-svg-icon [path]="IconsEnum.CONTROLLER_ICON_SMALL" />
    </div>
    <div
      *ngIf="selectedFarm?.farmID === farm.farmID"
      class="farms-list__farm-actions">
      <ls-svg-icon
        [path]="platformService.isMobileVersion ? IconsEnum.FARM_SETTINGS_MOBILE : IconsEnum.FARM_SETTINGS"
        class="mh-18 disabled" />
      <ls-svg-icon
        [path]="platformService.isMobileVersion ? IconsEnum.CONTROLLER_ADD_MOBILE : IconsEnum.CONTROLLER_ADD"
        class="disabled" />
    </div>
  </div>
</ng-template>

<ng-template #noData>
  <div class="empty-mode">
    <div class="mb-28">
      {{ "Farms.ThereAreNoFarmsYet" | translate }}
    </div>
  </div>
</ng-template>

<ng-template #createFarmTemplate>
  <ls-create-farm
    (closeComponent)="farmWasCreated()"
    [creationMode]="FarmCreationMode.Scanned">
  </ls-create-farm>
</ng-template>

<ng-template
  #deviceTemplate
  let-controller="controller">
  <div class="d-flex align-start">
    <ls-svg-icon [path]="IconsEnum.DEVICE"></ls-svg-icon>
    <div class="d-flex flex-column mh-18">
      <div class="d-flex fz-24">
        <ls-svg-icon
          [path]="IconsEnum.CONTROLLER_ICON"
          class="w-24"></ls-svg-icon>
        - {{ controller.houseNumber }}
      </div>
      <h2 class="fz-12 mb-4 mt-7 color-grey">{{ "LoginScanned.ScannedController" | translate }}</h2>
      <h2 class="fz-24">ID: {{ controller.sn }}</h2>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="h-100-percent d-flex align-center justify-center">
    <lv-loading-galcon></lv-loading-galcon>
  </div>
</ng-template>

<ng-template #selectTimeZoneTemplate>
  <div class="fz-18 mt-28">{{ "LoginScanned.ChooseControllersTimezone" | translate }}</div>
  <div class="field mt-8">
    <ls-svg-icon
      [color]="timeZoneInputInFocus ? ColorsEnum.MainDarkBlue : ColorsEnum.MonoDark"
      [path]="IconsEnum.TIMEZONE">
    </ls-svg-icon>

    <input
      (focusin)="onFocusInTimeZoneInput()"
      (focusout)="onFocusOutTimeZoneInput()"
      #timeZoneInput
      [placeholder]="timeZonePlaceholder | translate"
      [class.color-darkblue]="!timeZoneInputInFocus"
      [formControl]="timeZoneControl"
      [matAutocomplete]="auto"
      type="text"
      class="field-timezone" />
  </div>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayTimeZoneFn">
    <mat-option
      *ngFor="let timezone of filteredTimeZones | async"
      [value]="timezone">
      <span
        [innerHTML]="TimeZonesEnum.toTranslateKey(timezone.id) | styleTextPart : toHighlight : 'highlighted'"></span>
    </mat-option>
  </mat-autocomplete>
</ng-template>

<ng-template #getControllerTicketErrorTemplate>
  <div
    *ngIf="!!getControllerTicketError"
    class="d-flex align-center h-100-percent mh-10">
    <ls-svg-icon [path]="IconsEnum.SAD_SMILE"></ls-svg-icon>
    <span class="fz-24 mh-18 color-brightorange">{{
      "LoginScanned.ThisDeviceAlreadyConnectedToFarm" | translate
    }}</span>
  </div>
</ng-template>
