import { MobileViewModeEnum } from '@livestock/layout';
import { LayoutState } from './layout.state';
import { createReducer, on, Action } from '@ngrx/store';
import * as layoutActions from './layout.actions';

export const initialState: LayoutState = {
    mobileViewMode: MobileViewModeEnum.Farms,
    isDialogOpened: false,
};

const reducer = createReducer(
    initialState,
    on(layoutActions.setMobileViewMode, (state, { mobileViewMode }) => {
        return {
            ...state,
            mobileViewMode,
        };
    }),
    on(layoutActions.setIsDialogOpened, (state, { isDialogOpened }) => {
        return {
            ...state,
            isDialogOpened,
        };
    }),
);

export function layoutReducer(state: LayoutState | undefined, action: Action): any {
    return reducer(state, action);
}
