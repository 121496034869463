import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, Validators, ControlContainer, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';

import {
  IconsEnum,
  ButtonTypeEnum,
  ColorsEnum,
  OffOnEnum,
  ElementOperationEnum,
  ElementTypesEnum,
} from '@livestock/shared/enums';
import {
  DualToggleWithLabelComponent,
  LVInputComponent,
  LVInputDecimalWithLabelComponent,
  SvgIconComponent,
  ThemeEnum,
  SimpleDropdownComponent,
  SlimButtonComponent,
  LVInputIntegerWithLabelComponent,
} from '@livestock/ui';
import { BasicElementComponent, IGetOrUpdateElement, InstallationConstants, runElementTests, TestingElementDialogComponent } from '@livestock/installation';
import { ElementEventInfo, IElementSetupRunTestView } from '@livestock/installation/interfaces';
import { EnumPipe } from '@livestock/shared/pipes';
import { getFormGroupErrors, greaterThanValidator, lessThanValidator } from '@livestock/shared/validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    EnumPipe,
    SvgIconComponent,
    LVInputComponent,
    DualToggleWithLabelComponent,
    LVInputDecimalWithLabelComponent,
    SimpleDropdownComponent,
    SlimButtonComponent,
    LVInputIntegerWithLabelComponent,
  ],
  selector: 'lv-light-ao-setup',
  templateUrl: './light-ao.component.html',
  styleUrls: ['./light-ao.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class LightAnalogOutputSetupComponent extends BasicElementComponent implements OnInit {
  @Input() elementEvent: ElementEventInfo;
  @Output() delete: EventEmitter<void> = new EventEmitter();
  @Output() changeTestMode: EventEmitter<boolean> = new EventEmitter();

  // vars
  isTestMode: boolean = false;
  errorMessage: string = null;

  // enums
  OffOnEnum = OffOnEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ThemeEnum = ThemeEnum;
  ElementOperationEnum = ElementOperationEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  ColorsEnum = ColorsEnum;
  InstallationConstants = InstallationConstants;

  override ngOnInit(): void {
    const disabled = !this.isEditMode;
    this.parentFormGroup.addControl(this.formGroupName,
      new FormGroup({
        elementID: new FormControl<number>({ value: null, disabled }),
        number: new FormControl<number>({ value: 1, disabled }),
        deviceName: new FormControl<string>({ value: 'Light - 01', disabled }, [Validators.required]),
        maxVoltage: new FormControl<number>({ value: 0, disabled }, [Validators.min(InstallationConstants.VoltMin), Validators.max(InstallationConstants.VoltMax), greaterThanValidator('minVoltage', 'lt')]),
        minVoltage: new FormControl<number>({ value: 0, disabled }, [Validators.min(InstallationConstants.VoltMin), Validators.max(InstallationConstants.VoltMax), lessThanValidator('maxVoltage', 'gt')]),
      }));
    super.ngOnInit();

    this.parentFormGroup.statusChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => this.handleFormStatusChanges());
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      elementID: setupData.elementID,
      deviceName: setupData.deviceName,
      operation: setupData.operation,
      maxVoltage: setupData.maxVoltage,
      minVoltage: setupData.minVoltage,
    });
    this.parentFormGroup.updateValueAndValidity();
  }

  uninstall(): void {
    this.delete.emit();
  }

  async runTest(): Promise<void> {
    const dialogRef: MatDialogRef<TestingElementDialogComponent> = this.dialog.open(TestingElementDialogComponent, {
      data: {
        elementType: ElementTypesEnum.LightAO,
      },
      disableClose: true,
      panelClass: `testing-element-dialog__panel`,
      backdropClass: 'testing-element-dialog__backdrop',
    });

    const result: IElementSetupRunTestView = await lastValueFrom(dialogRef.afterClosed());
    if (result != null) {
      this.store.dispatch(runElementTests({
        elementID: this.parentFormGroup.get(this.formGroupName).value.elementID,
        response: result,
      }));
      this.isTestMode = true;
      this.changeTestMode.emit(true);
      this.elementEvent = {
        ...this.elementEvent,
        testTime: result.testTime,
      };
      this.cdr.detectChanges();
    }
  }

  stopTest(): void {
    this.store.dispatch(runElementTests({
      elementID: this.parentFormGroup.get(this.formGroupName).value.elementID,
      response: { testTime: 0 },
    }));
    this.isTestMode = false;
    this.changeTestMode.emit(false);
    this.cdr.detectChanges();
  }

  private handleFormStatusChanges(): void {
    const formGroup = this.parentFormGroup.get(this.formGroupName) as FormGroup;
    const errors = getFormGroupErrors(formGroup);

    if (!errors) {
      this.errorMessage = null;
      return;
    }

    const maxVolt = formGroup.controls['maxVoltage'].value;
    const minVolt = formGroup.controls['minVoltage'].value;
    this.errorMessage = this.getErrorMessage(maxVolt, minVolt);
  }

  private getErrorMessage(maxVolt: number, minVolt: number): string {
    if (maxVolt <= minVolt) {
      return this.translateService.instant(
        'FormErrors.FirstShouldBeGreaterThanSecond',
        {
          first: this.translateService.instant('Installation.MaxVolt'),
          second: this.translateService.instant('Installation.MinVolt'),
        },
      );
    }

    if (
      (maxVolt <= InstallationConstants.VoltMin || maxVolt > InstallationConstants.VoltMax) ||
      (minVolt <= InstallationConstants.VoltMin || minVolt > InstallationConstants.VoltMax)
    ) {
      return this.translateService.instant('FormErrors.VoltageRangeIs', {
        min: InstallationConstants.VoltMin,
        max: InstallationConstants.VoltMax,
      });
    }

    return null;
  }
}
