import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { QueryParamsHandling } from '@angular/router';
import { ILoginData } from '../interfaces/login-data.interface';

// LOG IN
export const login = createAction(
  '[AUTH] Log in',
  props<{ payload: ILoginData }>(),
);

export const loginSuccess = createAction(
  '[AUTH] Log in [SUCCESS]',
  props<{ token: string; credentials: ILoginData }>(),
);

export const loginError = createAction(
  '[AUTH] Log in [ERROR]',
  props<{ payload: string }>(),
  // TO DO - Need to change it on the backend side firstly
  // props<{ payload: HttpErrorResponse; }>(),
);

// LOG OUT
export const logOut = createAction(
  '[AUTH] Log out',
  (paramsHandling?: QueryParamsHandling, cancelRedirectToLogin?: boolean) => ({ paramsHandling, cancelRedirectToLogin }),
);

export const logOutSuccess = createAction(
  '[AUTH] Log out [SUCCESS]',
);

export const logOutError = createAction(
  '[AUTH] Log out [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const resetLoginProcess = createAction('[AUTH] Reset login process');

export const clearAllIfUnAuthorised = createAction(
  '[AUTH] Clear All Entities When Unauthorised',
);

//TOKEN
export const refreshToken = createAction('[AUTH] Refresh token');

export const refreshTokenSuccess = createAction(
  '[AUTH] Refresh token [SUCCESS]',
);

export const refreshTokenError = createAction(
  '[AUTH] Refresh token [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);
