<div
  *ngIf="parentFormGroup.get(formGroupName) as form"
  [formGroupName]="formGroupName">
  <div
    [ngClass]="platformService.isDeviceApp ? 'justify-between' : 'justify-end'"
    class="d-flex align-center w-100-percent h-28">
    <lv-uninstall-element
      *ngIf="platformService.isDeviceApp"
      (uninstall)="uninstall()"
      [isEditMode]="isEditMode" />

    <div class="d-flex align-center">
      <ng-container *ngIf="form['controls'].sensorOn.value">
        <div class="mh-8">
          <ls-svg-icon
            [path]="IconsEnum.PRESSURE"
            [color]="
              elementEvent?.status !== InOutdoorTemperatureStatusEnum.SensorOK
                ? ColorsEnum.MainDarkRed
                : ColorsEnum.MonoDark
            ">
          </ls-svg-icon>
        </div>

        <div class="d-flex">
          <span
            [ngClass]="
              elementEvent?.status !== InOutdoorTemperatureStatusEnum.SensorOK ? 'main-darkred' : 'green-status'
            "
            class="fz-28 fw-800">
            {{
              !elementEvent?.status || emptyValueStatuses.includes(elementEvent?.status) ? "--" : elementEvent?.value
            }}
          </span>
          <span
            [ngClass]="
              elementEvent?.status !== InOutdoorTemperatureStatusEnum.SensorOK ? 'main-darkred' : 'green-status'
            "
            class="fz-12 fw-900 mh-2 pt-13">
            <span class="fw-500">
              <ng-container [ngSwitch]="pressureUnit$ | async">
                <ng-container *ngSwitchCase="AirPressureUnitEnum.InchesOfWater">
                  {{ "InWC" | translate }}
                </ng-container>
                <ng-container *ngSwitchCase="AirPressureUnitEnum.MmOfWater">
                  {{ "mmH2O" | translate }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ "Pa" | translate }}
                </ng-container>
              </ng-container>
            </span>
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="!form['controls'].sensorOn.value">
        <div class="mh-8">
          <ls-svg-icon [path]="IconsEnum.PRESSURE"></ls-svg-icon>
        </div>

        <div class="d-flex align-end">
          <span class="fz-16 fw-800">{{ "Installation.Off" | translate | uppercase }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <!--FORM-->
  <lv-input
    [label]="'Installation.StaticPressure.SensorName'"
    [qaTags]="'qa-txt-sensor-name'"
    [autocomplete]="'nope'"
    formControlName="sensorName"
    type="text"
    class="full-width mt-28 label-bg-white">
  </lv-input>

  <div class="d-flex justify-between mt-18">
    <lv-dual-toggle-with-label
      [optionsTitlePosition]="form['controls'].sensorOn.value ? 'before' : 'after'"
      [firstOption]="{
        title: 'Buttons.Yes',
        value: true,
        theme: ThemeEnum.Main,
        toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
      }"
      [secondOption]="{
        title: 'Installation.Off',
        value: false,
        theme: ThemeEnum.Dark,
        toggleIcon: IconsEnum.CROSS_SMALL_BLACK
      }"
      [label]="'Installation.StaticPressure.SensorOn'"
      class="flex-0_5 label-bg-white"
      formControlName="sensorOn">
    </lv-dual-toggle-with-label>
  </div>

  <lv-dual-toggle-with-label
    [firstOption]="{
      title: 'Installation.0-10V',
      value: SensorTypeEnum.V_0_10,
      theme: ThemeEnum.Main,
      toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
    }"
    [secondOption]="{
      title: 'Installation.4-20Ma',
      value: SensorTypeEnum.mA_4_20,
      theme: ThemeEnum.Main,
      toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
    }"
    [label]="'Installation.StaticPressure.SensorType'"
    class="flex-1 mt-18 label-bg-white"
    formControlName="sensorType">
  </lv-dual-toggle-with-label>
</div>
