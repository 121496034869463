const DECIMAL_MULTIPLIERS: number[] = [1, 10, 100, 1000];

export class NumberUtils {
  static isDecimalValid(val: number, accuracy: number, max): boolean {
    return Math.floor(val * DECIMAL_MULTIPLIERS[accuracy]) <= max;
  }

  static toNumberOrNull(value: string): number | null {
    return value != null ? Number(value) : null;
  }

  static roundNumber(num: number, decimalPlaces: number): number {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(num * multiplier) / multiplier;
  }
}
