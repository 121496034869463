import { TemperatureUnitEnum } from '@livestock/shared/enums';
import { VentilationWorkingModeEnum } from '@livestock/controllers/enums';
import { IMinMax } from '@livestock/shared/interfaces';

export interface IFlockHouseModeView {
  controllerID: number;
  targetTemperatureEmpty: number;
  targetTemperaturePreheat: number;
  targetTemperatureCleaning: number;
  targetTemperatureCatching: number;
  enableTemperatureAlarmEmpty: boolean;
  enableTemperatureAlarmPreheat: boolean;
  enableTemperatureAlarmCleaning: boolean;
  enableTemperatureAlarmCatching: boolean;
  ventilationEmpty?: IMinMax;
  ventilationPreheat?: IMinMax;
  ventilationCatching?: IMinMax;
  ventilationCleaning?: IMinMax;
  timeChangeNextMode: string | number;

  // remove in future
  maximumVentilationEmpty?: number;
  maximumVentilationPreheat?: number;
  maximumVentilationCleaning?: number;
  maximumVentilationCatching?: number;
  minimumVentilationEmpty?: number;
  minimumVentilationPreheat?: number;
  minimumVentilationCleaning?: number;
  minimumVentilationCatching?: number;

  // additional fields
  temperatureUnit?: TemperatureUnitEnum;
  ventilationWorkingMode?: VentilationWorkingModeEnum;
}
