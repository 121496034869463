import { ElementTypesEnum } from '@livestock/shared/enums';

export interface ChildElementDefinition {
    type: ElementTypesEnum;
    name: string;
}

export class ElementWrapperHelper {

    static CHILDREN_FORM_GROUP_NAME: ChildElementDefinition[] = [
        {
            type: ElementTypesEnum.IndoorTemperature,
            name: 'indoorTemperature',
        },
        {
            type: ElementTypesEnum.OutdoorTemperature,
            name: 'outdoorTemperature',
        },
        {
            type: ElementTypesEnum.IndoorHumidity,
            name: 'indoorHumidity',
        },
        {
            type: ElementTypesEnum.OutdoorHumidity,
            name: 'outdoorHumidity',
        },
        {
            type: ElementTypesEnum.CO2,
            name: 'co2',
        },
        {
            type: ElementTypesEnum.StaticPressure,
            name: 'staticPressure',
        },
        {
            type: ElementTypesEnum.WaterMeter,
            name: 'waterMeter',
        },
        {
            type: ElementTypesEnum.AuxiliaryInput,
            name: 'auxiliaryInput',
        },
        {
            type: ElementTypesEnum.Cooling,
            name: 'cooling',
        },
    ];

    static childFormGroupNameByType(type: ElementTypesEnum): string {
        return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type).name;
    }
}