<ng-container *ngIf="flashMessage">
  <div
    [class.flash-auth]="router.url | memoizeFunc : activatedRouteService.isAuthPage"
    class="flash d-flex align-center justify-between">
    <div class="flash-indicator flash-indicator__{{ flashMessage.flashType }}"></div>

    <div class="pl-36 pv-18">
      <h2
        *ngIf="flashMessage.title"
        class="fw-700">
        {{ flashMessage.title | translate }}
      </h2>
      <div class="d-flex align-center">
        <div
          [ngClass]="{
            'qa-lbl-message-error': [FlashMessageTypeEnum.Error, FlashMessageTypeEnum.ErrorMessage].includes(
              flashMessage.flashType
            ),
            'qa-lbl-message-success': flashMessage.flashType === FlashMessageTypeEnum.Success
          }"
          class="flash-message qa-lbl-message">
          <p
            [innerHTML]="
              flashMessage.doNotTranslate
                ? flashMessage.message
                : (flashMessage.message | translate : flashMessage.additionalInfo)
            "></p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
