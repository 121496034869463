import { SensorTypeEnum } from '@livestock/shared/enums';

export interface IGetOrUpdateElement {
  // common non-edible fields
  elementID?: number;
  number?: number;

  // common form fields
  sensorName?: string;

  // in/outdoor temperature
  sensorOn?: boolean;
  offset?: number;
  location?: boolean;

  // co2
  sensorType?: SensorTypeEnum;

  // water meter
  waterFor?: boolean;
  quantity?: number;

  // auxilary
  operation?: boolean;

  // cooling, light-do
  deviceName?: string;

  // light-ao
  minVoltage?: number;
  maxVoltage?: number;

  // as-relay - TODO: check value and type, will be defined later
  connectedTo?: any;
}
