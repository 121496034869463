import { Action, createReducer, on } from '@ngrx/store';
import * as parringProcessActions from './parring-process.actions';
import { IGetAddControllerTicketView } from '../interfaces/get-add-controller-ticket-view';
import { IGetAddControllerByConnectionNumberTicketView } from '@livestock/parring-process';

export const PARRING_PROCESS_FEATURE_KEY = 'parringProcess';

export interface ParringProcessState {
  isLoading: boolean;
  ticketId: string | null;
  ticketView: IGetAddControllerTicketView | null;
  isReconnect?: boolean;

  // controller creation, scanning
  controllerTicketByConnectionNumber?: IGetAddControllerByConnectionNumberTicketView;
  getControllerTicketError?: string;
  isControllerTicketExecuted?: boolean;
  creatingControllerError?: string;
}

export const initialState: ParringProcessState = {
  isLoading: false,
  ticketId: null,
  ticketView: null,
};

const reducer = createReducer(
  initialState,
  on(parringProcessActions.getAddControllerTicketView,
    (state, { ticketId, isReconnect }) => {
      return {
        ...state,
        ticketId,
        isReconnect,
      };
    },
  ),
  on(
    parringProcessActions.getAddControllerTicketViewSuccess,
    (state, { ticketView }) => {
      return {
        ...state,
        ticketView,
      };
    },
  ),
  on(
    parringProcessActions.getAddControllerTicketViewError,
    (state, { ticketId, error }) => {
      return {
        ...state,
        ticketId,
        getControllerTicketError: error.error,
        isReconnect: false,
      };
    },
  ),
  on(
    parringProcessActions.setValidControllerHouseNumber,
    (state, { houseNumber }) => {
      return {
        ...state,
        ticketView: {
          ...state.ticketView,
          houseNumber,
        },
      };
    },
  ),
  on(parringProcessActions.getControllerTicketInfoByConnectionNumber, (state) => {
    return {
      ...state,
      getControllerTicketError: null,
      isLoading: true,
    };
  }),
  on(parringProcessActions.getControllerTicketInfoByConnectionNumberSuccess, (state,
    { controllerTicketByConnectionNumber }) => {
    return {
      ...state,
      controllerTicketByConnectionNumber,
      getControllerTicketError: null,
      isLoading: false,
    };
  }),
  on(parringProcessActions.getControllerTicketInfoByConnectionNumberError, (state, { payload }) => {
    return {
      ...state,
      getControllerTicketError: payload.error,
      isLoading: false,
    };
  }),
  on(
    parringProcessActions.setValidControllerTicketHouseNumber,
    (state, { houseNumber }) => {
      return {
        ...state,
        controllerTicketByConnectionNumber: {
          ...state.controllerTicketByConnectionNumber,
          houseNumber,
        },
      };
    },
  ),
  on(parringProcessActions.executeTicketController, (state) => {
    return {
      ...state,
      isControllerTicketExecuted: false,
      creatingControllerError: null,
      isLoading: true,
    };
  }),
  on(parringProcessActions.executeTicketControllerSuccess, (state) => {
    return {
      ...state,
      isControllerTicketExecuted: true,
      creatingControllerError: null,
      isLoading: false,
    };
  }),
  on(parringProcessActions.executeTicketControllerError, (state, { payload }) => {
    return {
      ...state,
      isControllerTicketExecuted: false,
      creatingControllerError: payload.error,
      isLoading: false,
    };
  }),
  on(parringProcessActions.clearIsControllerCreatedOrExecuted, (state) => {
    return {
      ...state,
      controllerTicketByConnectionNumber: null,
      getControllerTicketError: null,
      isControllerTicketExecuted: null,
      creatingControllerError: null,
    };
  }),
);

export function parringProcessReducer(state: ParringProcessState | undefined, action: Action): any {
  return reducer(state, action);
}
