import { createReducer, on } from '@ngrx/store';
import { displayActions } from './display.actions';
import { DisplayState } from './display.state';

export const DISPLAY_FEATURE_KEY = 'display';

export const initialState: DisplayState = {
  newVersionAvailable: null,
  newFirmwareAvailable: null,
  notificationMessage: null,
  reloadRequired: false,
  readyToInstall: false,
  readyToFirmware: false,
  buildInfo: null,
  backendConnection: {
    isNetworkManagerConnected: false,
    isSSEClientConnected: false,
  },
  controllerVersion: null,
};

export const displayReducer = createReducer(
  initialState,
  on(displayActions.backendConnection, (state, { backendConnection }) => {
    return {
      ...state,
      backendConnection,
    };
  }),
  on(displayActions.controllerVersion, (state, { controllerVersion }) => {
    return {
      ...state,
      controllerVersion,
    };
  }),
  on(displayActions.newVersionAvailable, (state, { version }) => {
    return {
      ...state,
      newVersionAvailable: version,
    };
  }),
  on(displayActions.newFirmwareAvailable, (state, { version }) => {
    return {
      ...state,
      newFirmwareAvailable: version,
    };
  }),
  on(displayActions.reloadRequired, (state, { reloadRequired }) => {
    return {
      ...state,
      reloadRequired,
    };
  }),
  on(displayActions.readyToInstall, (state, { readyToInstall }) => {
    return {
      ...state,
      readyToInstall,
    };
  }),
  on(displayActions.readyToFirmware, (state, { readyToFirmware }) => {
    return {
      ...state,
      readyToFirmware,
    };
  }),
  on(displayActions.buildInfo, (state, {buildInfo}) => {
    return {
      ...state,
      buildInfo,
    };
  }),
  on(displayActions.notificationMessage, (state, { notificationMessage }) => {
    return {
      ...state,
      notificationMessage,
    };
  }),
);
