import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { environment } from '@livestock/shared/environments';

export class MissingTranslationService implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    if (environment.production) {
      return params.key;
    }
    // only for dev
    return `WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`;
  }
}
