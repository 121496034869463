import { Injectable, OnDestroy } from '@angular/core';
import { ControllerLanguageEnum, controllerLanguagesMapped, selectControllerLanguage } from '../../controllers/src';
import { filter, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PlatformService } from './platform.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LocalStorageService } from '@livestock/shared/services';
import { StorageItem } from '@livestock/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class LanguageService implements OnDestroy {
  sub$ = new Subscription();
  lang: ControllerLanguageEnum = +LocalStorageService.getStorageItem(StorageItem.CurrentLanguage) || ControllerLanguageEnum.EngUS;
  lang$ = new BehaviorSubject(this.lang);
  isRtl = this.lang === ControllerLanguageEnum.Hebrew;
  direction: 'rtl' | 'ltr' = 'ltr';

  constructor(
    private store: Store,
    private platformService: PlatformService,
    private translateService: TranslateService,
  ) {
    if (this.platformService.isDeviceApp) {
      this.sub$.add(
        this.store.select(selectControllerLanguage).pipe(
          filter(res => res != null),
        ).subscribe(lang => {
          this.lang = lang;
          this.lang$.next(lang);
          this.isRtl = lang === ControllerLanguageEnum.Hebrew;
          this.direction = lang === ControllerLanguageEnum.Hebrew ? 'rtl' : 'ltr';
        }),
      );
    }
  }

  // TODO: change in the future, this is temporary mockup
  changeLanguage(language?: ControllerLanguageEnum): void {
    if (language == null) {
      this.lang = this.lang === ControllerLanguageEnum.EngUS ? ControllerLanguageEnum.Hebrew : ControllerLanguageEnum.EngUS;
    } else {
      this.lang = language;
    }

    this.lang$.next(this.lang);
    LocalStorageService.setStorageItem(StorageItem.CurrentLanguage, this.lang);
    this.translateService.use(controllerLanguagesMapped.get(this.lang));
    this.isRtl = this.lang === ControllerLanguageEnum.Hebrew;
    this.direction = this.lang === ControllerLanguageEnum.Hebrew ? 'rtl' : 'ltr';
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
