import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IEditMode,
  IGetCNFStatusView,
  ILastEdited,
  IDateTimeSettingsView,
  IHouseSizesView,
  IGeneralSettingsView,
  INetworkSettingsView,
  IUnitsDetails,
} from '@livestock/controllers/interfaces';
import { PageTypeEnum } from '@livestock/shared/enums';
import { ICard } from '../../../interfaces/installation/card.interface';
import { IFlockSettingsView } from '@livestock/flock/models';

/* COMMON */
export const getLastEditedSettingsInfo = createAction(
  '[CURRENT CONTROLLER] Get Last Edited Settings Info [...]',
  props<{ pageType: PageTypeEnum }>(),
);

export const getLastEditedSettingsInfoSuccess = createAction(
  '[CURRENT CONTROLLER] Get Last Edited Settings Info [SUCCESS]',
  props<{
    lastEdited: ILastEdited;
  }>(),
);

export const getLastEditedSettingsInfoError = createAction(
  '[CURRENT CONTROLLER] Get Last Edited Settings Info [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

/* BASIC SETTINGS */
export const getControllerGeneralSettings = createAction(
  '[CURRENT CONTROLLER] Get General Settings [...]',
);

export const getControllerGeneralSettingsSuccess = createAction(
  '[CURRENT CONTROLLER] Get General Settings [SUCCESS]',
  props<{
    generalSettings: IGeneralSettingsView;
    lastEdited: ILastEdited;
    editMode: IEditMode;
    cnfStatus: IGetCNFStatusView;
  }>(),
);

export const getControllerGeneralSettingsError = createAction(
  '[CURRENT CONTROLLER] Get General Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateControllerGeneralSettings = createAction(
  '[CURRENT CONTROLLER] Update General Settings [...]',
  props<{ generalSettings: IGeneralSettingsView, unitsDetails: IUnitsDetails }>(),
);

export const updateControllerGeneralSettingsSuccess = createAction(
  '[CURRENT CONTROLLER] Update General Settings [SUCCESS]',
  props<{ pageType: PageTypeEnum, generalSettings: IGeneralSettingsView, unitsDetails: IUnitsDetails, }>(),
);

export const updateControllerGeneralSettingsError = createAction(
  '[CURRENT CONTROLLER] Update General Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const getControllerGeneralSettingsOnly = createAction(
  '[CURRENT CONTROLLER] Get General Settings Only [...]',
);

export const getControllerGeneralSettingsOnlySuccess = createAction(
  '[CURRENT CONTROLLER] Get General Settings Only [SUCCESS]',
  props<{ generalSettings: IGeneralSettingsView }>(),
);

export const getControllerGeneralSettingsOnlyError = createAction(
  '[CURRENT CONTROLLER] Get General Settings Only [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

/* HOUSE SETTINGS */
export const getControllerHouseSizesSettings = createAction(
  '[CURRENT CONTROLLER] Get House Sizes Settings [...]',
);

export const getControllerHouseSizesSettingsSuccess = createAction(
  '[CURRENT CONTROLLER] Get House Sizes Settings [SUCCESS]',
  props<{
    houseSettings: IHouseSizesView;
    lastEdited: ILastEdited;
    editMode: IEditMode;
    cnfStatus: IGetCNFStatusView;
  }>(),
);

export const getControllerHouseSizesSettingsError = createAction(
  '[CURRENT CONTROLLER] Get House Sizes Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateControllerHouseSizesSettings = createAction(
  '[CURRENT CONTROLLER] Update House Sizes Settings [...]',
  props<{ houseSettings: IHouseSizesView, prevHouseNumber: number }>(),
);

export const updateControllerHouseSizesSettingsSuccess = createAction(
  '[CURRENT CONTROLLER] Update House Sizes Settings [SUCCESS]',
  props<{ pageType: PageTypeEnum, houseSettings: IHouseSizesView, prevHouseNumber: number }>(),
);

export const updateControllerHouseSizesSettingsError = createAction(
  '[CURRENT CONTROLLER] Update House Sizes Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

/* DATE TIME SETTINGS */

export const getControllerDateTimeSettingsOnly = createAction(
  '[CURRENT CONTROLLER] Get Date Time Settings Only [...]',
);

export const getControllerDateTimeSettingsOnlySuccess = createAction(
  '[CURRENT CONTROLLER] Get Date Time Settings Only [SUCCESS]',
  props<{ dateTimeSettings: IDateTimeSettingsView }>(),
);

export const getControllerDateTimeSettingsOnlyError = createAction(
  '[CURRENT CONTROLLER] Get Date Time Settings Only [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const getControllerDateTimeSettings = createAction(
  '[CURRENT CONTROLLER] Get Date Time Settings [...]',
);

export const getControllerDateTimeSettingsSuccess = createAction(
  '[CURRENT CONTROLLER] Get Date Time Settings [SUCCESS]',
  props<{
    dateTimeSettings: IDateTimeSettingsView;
    lastEdited: ILastEdited;
    editMode: IEditMode;
    cnfStatus: IGetCNFStatusView;
  }>(),
);

export const getControllerDateTimeSettingsError = createAction(
  '[CURRENT CONTROLLER] Get Date Time Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateControllerDateTimeSettings = createAction(
  '[CURRENT CONTROLLER] Update Date Time Settings [...]',
  props<{ dateTimeSettings: IDateTimeSettingsView }>(),
);

export const updateControllerDateTimeSettingsSuccess = createAction(
  '[CURRENT CONTROLLER] Update Date Time Settings [SUCCESS]',
  props<{ pageType: PageTypeEnum, dateTimeSettings: IDateTimeSettingsView }>(),
);

export const updateControllerDateTimeSettingsError = createAction(
  '[CURRENT CONTROLLER] Update Date Time Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

/* Network Settings*/
export const getCurrentControllerNetworkSettings = createAction(
  '[CURRENT CONTROLLER] Get Network Settings [...]',
  props<{ controllerID: number }>(),
);

export const getCurrentControllerNetworkSettingsSuccess = createAction(
  '[CURRENT CONTROLLER] Get Network Settings [SUCCESS]',
  props<{ networkSettings: INetworkSettingsView }>(),
);

export const getCurrentControllerNetworkSettingsError = createAction(
  '[CURRENT CONTROLLER] Get Network Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

/*INSTALLATION*/
export const getCards = createAction(
  '[CURRENT CONTROLLER] Get cards [...]',
);

export const getCardsSuccess = createAction(
  '[CURRENT CONTROLLER] Get cards [SUCCESS]',
  props<{
    cards: ICard[],
    lastEdited: ILastEdited;
    editMode: IEditMode;
  }>(),
);

export const getCardsError = createAction(
  '[CURRENT CONTROLLER] Get cards [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

/*FLOCK*/
// TODO: move to flock state after edited-info refactoring
export const getCurrentControllerFlockSettings = createAction(
  '[CURRENT CONTROLLER] Get Flock Settings [...]',
);

export const getCurrentControllerFlockSettingsSuccess = createAction(
  '[CURRENT CONTROLLER] Get Flock Settings [SUCCESS]',
  props<{
    flockSettings: IFlockSettingsView,
    lastEdited: ILastEdited;
    editMode: IEditMode;
    cnfStatus: IGetCNFStatusView;
  }>(),
);

export const getCurrentControllerFlockSettingsError = createAction(
  '[CURRENT CONTROLLER] Get Flock Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateCurrentControllerFlockSettings = createAction(
  '[CURRENT CONTROLLER] Update Flock Settings [...]',
  props<{ flockSettings: IFlockSettingsView }>(),
);

export const updateCurrentControllerFlockSettingsSuccess = createAction(
  '[CURRENT CONTROLLER] Update Flock Settings [SUCCESS]',
  props<{ pageType: PageTypeEnum, flockSettings: IFlockSettingsView }>(),
);

export const updateCurrentControllerFlockSettingsError = createAction(
  '[CURRENT CONTROLLER] Update Flock Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const createNewFlock = createAction(
  '[CURRENT CONTROLLER] Create New Flock [...]',
  props<{ flockSettings: IFlockSettingsView }>(),
);

export const createNewFlockSuccess = createAction(
  '[CURRENT CONTROLLER] Create New Flock [SUCCESS]',
  props<{ pageType: PageTypeEnum, flockSettings: IFlockSettingsView }>(),
);

export const createNewFlockError = createAction(
  '[CURRENT CONTROLLER] Create New Flock [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);
