import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { SimpleDropdownComponent, SvgIconComponent } from '@livestock/ui';
import { IUser } from '@livestock/shared/interfaces';
import {
  deleteFarmUserSuccess,
  FarmUserDetailsComponent,
  FarmUserHelper,
  selectUserDetailsMode,
  setUserDetailsMode,
  UserDetailsModeEnum,
} from '@livestock/farms';
import {
  ColorsEnum,
  CommonUserRolesEnum,
  FarmUserRolesEnum,
  IconsEnum,
  UserStatusesEnum,
} from '@livestock/shared/enums';
import { EnumPipe, MemoizeFuncPipe } from '@livestock/shared/pipes';
import { StringUtils } from '@livestock/shared/utils';
import { ICurrentUserView } from '@livestock/current-user';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { PlatformService } from '@livestock/shared/services';

@Component({
  selector: 'lv-farm-users-list',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, TranslateModule, MemoizeFuncPipe, ReactiveFormsModule, SimpleDropdownComponent, EnumPipe, FarmUserDetailsComponent],
  templateUrl: './farm-users-list.component.html',
  styleUrls: ['./farm-users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FarmUsersListComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('usersList') scrollableUl: ElementRef<HTMLUListElement>;

  @Input() users: IUser[];
  @Input() currentUser: ICurrentUserView;
  @Input() currentUserAccessRole: FarmUserRolesEnum;

  @Output() onUserSelect = new EventEmitter<IUser>();

  private store: Store = inject(Store);
  private actions$: Actions = inject(Actions);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  platformService: PlatformService = inject(PlatformService);

  selectedUser: IUser;
  userDetailsMode: UserDetailsModeEnum;
  userListHasScroll: boolean = false;

  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum: typeof ColorsEnum = ColorsEnum;
  FarmUserRolesEnum: typeof FarmUserRolesEnum = FarmUserRolesEnum;
  UserDetailsModeEnum: typeof UserDetailsModeEnum = UserDetailsModeEnum;
  FarmUserHelper = FarmUserHelper;

  ngOnInit(): void {
    this.actions$.pipe(
      ofType(deleteFarmUserSuccess),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.selectedUser = null;
      this.onUserSelect.emit(this.selectedUser);
      this.cdr.detectChanges();
    });
    this.store.select(selectUserDetailsMode).pipe(
      wasChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((userDetailsMode: UserDetailsModeEnum) => {
      if (userDetailsMode == null || userDetailsMode === UserDetailsModeEnum.NewInvitation) {
        this.selectedUser = null;
        this.onUserSelect.emit(this.selectedUser);
      }
      this.userDetailsMode = userDetailsMode;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.updateScrollableContainerStyle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['users'].currentValue?.length !== changes?.['users'].previousValue?.length) {
      this.updateScrollableContainerStyle();
    }
  }

  updateScrollableContainerStyle(): void {
    const ulElement = this.scrollableUl?.nativeElement;
    if (!ulElement) return;
    const containerElement = ulElement.parentElement;
    setTimeout(() => {
      this.userListHasScroll = ulElement.scrollHeight > containerElement.clientHeight;
      ulElement.classList[
        this.userListHasScroll ? 'add' : 'remove'
      ]('pl-18');
    });
  }

  trackBy(_: number, user: IUser): number {
    return user.userID;
  }

  getUserInitials(user: IUser): string {
    return StringUtils.getInitials(user.fullName);
  }

  onSelect(user: IUser): void {
    if (user.email === this.selectedUser?.email) {
      this.selectedUser = null;
      this.store.dispatch(setUserDetailsMode({ userDetailsMode: null }));
      return;
    }

    this.selectedUser = user;
    const userDetailsMode = user.userID
    ? UserDetailsModeEnum.EditUser
    : UserDetailsModeEnum.Resend;
    this.store.dispatch(setUserDetailsMode({ userDetailsMode }));
    this.onUserSelect.emit(this.selectedUser);
  }

  isPendingInvitation(user: IUser): boolean {
    return user.status !== UserStatusesEnum.Active;
  }

  isUserEditingAvailable = (user: IUser): boolean => {
    if (user.userID === this.currentUser.userID) {
      return false;
    }

    switch (this.currentUserAccessRole) {
      case FarmUserRolesEnum.Owner:
        return true;
      case FarmUserRolesEnum.Admin:
        return [FarmUserRolesEnum.Admin, FarmUserRolesEnum.Editor, FarmUserRolesEnum.Viewer].includes(user.accessesRole);
      case FarmUserRolesEnum.Editor:
      case FarmUserRolesEnum.Viewer:
        return false;
      default:
        return this.currentUser.role === CommonUserRolesEnum.God;
    }
  };
}
