import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SlimButtonComponent } from '@livestock/ui';
import { ButtonTypeEnum } from '@livestock/shared/enums';
import { IConfirmDialogData } from '@livestock/shared/interfaces';

@Component({
  selector: 'lv-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    SlimButtonComponent,
    NgIf,
    TranslateModule,
  ],
})
export class ConfirmDialogComponent {
  ButtonTypeEnum: typeof ButtonTypeEnum = ButtonTypeEnum;

  defaultConfirmButtonLabelKey: string = 'Buttons.Ok';
  defaultCancelButtonLabelKey: string = 'Buttons.Cancel';
  defaultConfirmButtonType: ButtonTypeEnum = ButtonTypeEnum.ALERT;
  defaultCancelButtonType: ButtonTypeEnum = ButtonTypeEnum.SECONDARY;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData,
  ) {
  }

  onClose(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }
}
