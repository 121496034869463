<div class="container">
  <div class="wrapper">
    <div class="lobby-wrapper">
      <ls-display-top-menu></ls-display-top-menu>

      <div class="lobby lobby-device">
        <router-outlet></router-outlet>
      </div>

      <div class="footer-logo">
        <img src="/assets/images/powered-galcon.png" />
      </div>
    </div>
  </div>
</div>
