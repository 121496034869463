import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FarmSettingsComponent, FarmSettingsMobileSections, FarmUserDetailsComponent, IFarmInfo, selectUserDetailsMode, setUserDetailsMode, UserDetailsModeEnum } from '@livestock/farms';
import { ButtonComponent, SlimButtonComponent, SvgIconComponent } from '@livestock/ui';
import { UpdateFarmComponent } from '../../update-farm/update-farm.component';
import { FarmUsersListComponent } from '../../farm-users-list/farm-users-list.component';
import { MobileViewModeEnum, setMobileViewMode } from '@livestock/layout';
import { ColorsEnum } from '@livestock/shared/enums';
import { SettingsHeaderComponent } from '../../../../../../controllers/src/lib/components/settings-header/settings-header.component';
import { PlatformService } from '@livestock/shared/services';
import { IUser } from '@livestock/shared/interfaces';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { AppRoutes } from '@livestock/shared/routes';

@Component({
  selector: 'lv-farm-settings-mobile',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SlimButtonComponent,
    SvgIconComponent,
    TranslateModule,
    ButtonComponent,
    NgClickOutsideDirective,
    UpdateFarmComponent,
    FarmUsersListComponent,
    RouterLink,
    FarmUserDetailsComponent,
    SettingsHeaderComponent,
],
  templateUrl: './farm-settings-mobile.component.html',
  styleUrls: ['./farm-settings-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FarmSettingsMobileComponent extends FarmSettingsComponent implements OnInit {

  @ViewChild(UpdateFarmComponent) updateFarmComponent: UpdateFarmComponent;

  platformService = inject(PlatformService);
  private destroyRef: DestroyRef = inject(DestroyRef);

  activeSettingsSection: FarmSettingsMobileSections = FarmSettingsMobileSections.FarmUsers;
  selectedUser: IUser = null;
  userDetailsMode: UserDetailsModeEnum = null;

  readonly COORDINATES_FORMAT: string = '1.5-5';
  ColorsEnum = ColorsEnum;
  FarmSettingsMobileSections = FarmSettingsMobileSections;

  ngOnInit(): void {
    this.store.select(selectUserDetailsMode).pipe(
      wasChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((userDetailsMode: UserDetailsModeEnum) => {
      this.userDetailsMode = userDetailsMode;
      if (userDetailsMode === null) {
        this.activeSettingsSection = FarmSettingsMobileSections.FarmUsers;
      }
    });
  }

  onUserSelect(user: IUser): void {
    this.selectedUser = user;
    if (this.selectedUser == null) return;
    this.activeSettingsSection = FarmSettingsMobileSections.FarmUserEdit;
  }

  onFarmDelete(): void {
    this.updateFarmComponent.onDeleteFarm();
  }

  onUserDetailsBackPress(): void {
    this.activeSettingsSection = FarmSettingsMobileSections.FarmUsers;
    this.store.dispatch(setUserDetailsMode({ userDetailsMode: null }));
  }

  navigateToFarmsPage(farm: IFarmInfo): void {
    this.store.dispatch(setMobileViewMode({ mobileViewMode: MobileViewModeEnum.Farms }));
    this.router.navigate([AppRoutes.FARM, farm.farmID]);
  }
}
