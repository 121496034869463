import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  OnInit,
  inject,
  DestroyRef,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Actions } from '@ngrx/effects';
import { animate, state, style, transition, trigger } from '@angular/animations';

import {
  ControllerGeneralSettingsFormComponent,
  createEditModeError,
  resendControllerPageCnf,
  selectCnfStatus,
  selectCnfStatusControllerEvents,
  selectRefreshPageControllerEvent,
  setCnfStatus,
} from '@livestock/controllers';
import { ArrowsConnectComponent, ArrowsConnectEnum, SlimButtonComponent, SvgIconComponent } from '@livestock/ui';
import { ButtonTypeEnum, FailedSuccessEnum, IconsEnum, PageTypeEnum } from '@livestock/shared/enums';
import { SettingsHeaderComponent } from '../settings-header/settings-header.component';
import { SetupUpdateStatusTypeEnum } from '@livestock/controllers/enums';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';
import { setFlashMessage } from '@livestock/notifications';
import { filterRefreshPageEvents, filterSyncAndCnfStatusEventsByControllerID } from '@livestock/web-sockets';
import { PlatformService } from '@livestock/shared/services';
import { InactivityWarningModalComponent } from '../inactivity-warning-modal/inactivity-warning-modal.component';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ControllerGeneralSettingsFormComponent,
    SlimButtonComponent,
    SvgIconComponent,
    ArrowsConnectComponent,
    SettingsHeaderComponent,
  ],
  selector: 'lv-settings-wrapper',
  templateUrl: './settings-wrapper.component.html',
  styleUrls: ['./settings-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideIn', [
      state('in', style({ transform: 'translateY(0)' })),
      transition(':enter', [
        style({ transform: 'translateY(50%)' }),
        animate('60ms ease-in'),
      ]),
    ]),
  ],
})
export class SettingsWrapperComponent implements OnChanges, OnInit, OnDestroy {
  private store: Store = inject(Store);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private translateService: TranslateService = inject(TranslateService);
  private dialog: MatDialog = inject(MatDialog);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private actions$: Actions<any> = inject(Actions);

  @Input() section: string;
  @Input() title: string;
  @Input() page: PageTypeEnum;
  @Input() isEditMode: boolean;
  @Input() isChanged: boolean;
  @Input() isValid: boolean;
  @Input() isSuccess: boolean;

  @Output() toggleDisabled: EventEmitter<boolean> = new EventEmitter();
  @Output() save: EventEmitter<void> = new EventEmitter();
  @Output() onReload: EventEmitter<void> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();

  // subs
  cnfEventSub$: Subscription;

  // vars
  cnfStatus: SetupUpdateStatusTypeEnum;
  activeControllerID: number;
  inactivityTimeout: number;

  // enums
  ButtonTypeEnum = ButtonTypeEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  SetupUpdateStatusTypeEnum = SetupUpdateStatusTypeEnum;
  ArrowsConnectEnum = ArrowsConnectEnum;
  PageTypeEnum = PageTypeEnum;

  constructor(public platformService: PlatformService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isEditMode']?.currentValue) {
      this.checkUserActivity();
    } else {
      this.resetUserInactivityCheck();
    }
  }

  ngOnInit(): void {
    this.store.select(selectCnfStatus)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((cnfStatus) => {
        // for running only first time
        if (this.cnfStatus == null && cnfStatus === SetupUpdateStatusTypeEnum.InProgress) {
          this.checkCnfStatusFromEvent(false);
        }
        this.cnfStatus = cnfStatus;
        this.cdr.detectChanges();
      });

    this.activatedRoute.params
      .pipe(
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(({ controllerID }) => {
        this.activeControllerID = +controllerID;
      });

    this.actions$
      .pipe(
        filter(
          (action: ReturnType<typeof createEditModeError>) =>
            action.type === createEditModeError.type && action?.page === this.page,
        ),
      )
      .subscribe(() => {
        this.onReload.emit();
      });

    this.store.select(selectRefreshPageControllerEvent(this.page))
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        wasChangedAndNotNull(),
      )
      .subscribe(() => {
        this.onReload.emit();
        this.store.dispatch(filterRefreshPageEvents({
          controllerID: this.activeControllerID,
          page: this.page,
        }));
      });
  }

  onSave(): void {
    if (this.isChanged) {
      this.checkCnfStatusFromEvent();
      this.save.emit();
    }
  }

  resendControllerPageCnf(): void {
    this.store.dispatch(resendControllerPageCnf({ page: this.page }));
    this.checkCnfStatusFromEvent();
  }

  checkCnfStatusFromEvent(setCnfStatusToInProgress = true): void {
    if (this.platformService.isDeviceApp) return;
    if (setCnfStatusToInProgress) {
      this.store.dispatch(setCnfStatus({ status: SetupUpdateStatusTypeEnum.InProgress }));
    }
    const sectorTypes = PageTypeEnum.toSectorTypes(this.page);
    this.store.dispatch(filterSyncAndCnfStatusEventsByControllerID({
      controllerID: this.activeControllerID,
      sectorTypes: sectorTypes,
    }));

    this.cnfEventSub$?.unsubscribe();
    this.cnfEventSub$ = this.store.select(selectCnfStatusControllerEvents(sectorTypes))
      .pipe(wasChangedAndNotNull())
      .subscribe((cnfStatusEvents: any[]) => {
        if (cnfStatusEvents.length === 0 || cnfStatusEvents.some(x => x.statusCNF === FailedSuccessEnum.Failed)) {
          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Error,
                message: 'ControllerSettings.SavingWrongMessage',
                additionalInfo: {
                  pageType: this.translateService.instant(PageTypeEnum.toTranslateKey(this.page)),
                },
              },
            }),
          );
          this.store.dispatch(setCnfStatus({ status: SetupUpdateStatusTypeEnum.Failed }));
          return;
        }

        if (cnfStatusEvents.every(x => x.statusCNF === FailedSuccessEnum.Success)) {
          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'ControllerSettings.SavingSuccessMessage',
                additionalInfo: {
                  pageType: this.translateService.instant(PageTypeEnum.toTranslateKey(this.page)),
                },
              },
            }),
          );
          this.store.dispatch(setCnfStatus({ status: SetupUpdateStatusTypeEnum.Done }));
        }
      });
  }

  private checkUserActivity(): void {
    this.resetUserInactivityCheck();

    this.inactivityTimeout = setTimeout(() => {
      this.dialog.open(InactivityWarningModalComponent, {
        disableClose: true,
        panelClass: `confirm-dialog__panel--danger`,
        backdropClass: 'confirm-dialog__backdrop-dark',
      }).afterClosed().subscribe(({ isActive }) => {
        if (isActive) {
          this.checkUserActivity();
        } else {
          this.toggleDisabled.emit(true);
        }
      });
    }, 1000 * 60 * 5);
  }

  resetUserInactivityCheck(): void {
    clearTimeout(this.inactivityTimeout);
    this.inactivityTimeout = null;
  }

  ngOnDestroy(): void {
    this.cnfEventSub$?.unsubscribe();
    this.resetUserInactivityCheck();
  }

  onMouseMove(): void {
    if (this.isEditMode) {
      this.checkUserActivity();
    }
  }
}
