<div [class.rtl]="languageService.isRtl">
  <form [formGroup]="form">
    <div
      *ngIf="platformService.isMobileVersion && unitsControl.value === ControllerUnitEnum.Custom"
      [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.Units" | translate }}
      </span>
      <div class="units-controls d-flex flex-row justify-between">
        <lv-radio-list
          [formControl]="unitsControl"
          [list]="unitsList"
          direction="horizontal"
          size="medium" />
      </div>
    </div>

    <div [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.Temperature" | translate }}
      </span>
      <lv-dual-toggle
        [formControlName]="'temperature'"
        [firstOption]="{
          title: null,
          value: TemperatureUnitEnum.Celsius,
        }"
        [secondOption]="{
          title: null,
          value: TemperatureUnitEnum.Fahrenheit,
        }"
        size="small">
        <span
          [ngClass]="form.controls['temperature'].value === TemperatureUnitEnum.Celsius ? 'highlight' : 'mono-grey'"
          firstOption
          class="fz-14">
          {{ TemperatureUnitEnum.toTranslateKey(TemperatureUnitEnum.Celsius) | translate }}
        </span>
        <span
          [class]="MIN_OPTION_WIDTH_CLASS + ' fz-14'"
          [ngClass]="form.controls['temperature'].value === TemperatureUnitEnum.Fahrenheit ? 'highlight' : 'mono-grey'"
          secondOption>
          {{ TemperatureUnitEnum.toTranslateKey(TemperatureUnitEnum.Fahrenheit) | translate }}
        </span>
      </lv-dual-toggle>
    </div>
    <div [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.AirFlow" | translate }}
      </span>
      <lv-dual-toggle
        [formControlName]="'airFlow'"
        [firstOption]="{
          title: null,
          value: AirFlowUnitEnum.CubicMeterPerHour
        }"
        [secondOption]="{
            title: null,
            value: AirFlowUnitEnum.CubicFeetPerHour,
          }"
        size="small">
        <span
          [ngClass]="form.controls['airFlow'].value === AirFlowUnitEnum.CubicMeterPerHour ? 'highlight' : 'mono-grey'"
          [innerHtml]="
            AirFlowUnitEnum.toTranslateKey(AirFlowUnitEnum.CubicMeterPerHour)
              | translate
              | styleTextPart : '3' : 'fz-10 superscript'
          "
          firstOption
          class="fz-14">
        </span>
        <span
          [class]="MIN_OPTION_WIDTH_CLASS + ' fz-14'"
          [ngClass]="form.controls['airFlow'].value === AirFlowUnitEnum.CubicFeetPerHour ? 'highlight' : 'mono-grey'"
          [innerHtml]="
            AirFlowUnitEnum.toTranslateKey(AirFlowUnitEnum.CubicFeetPerHour)
              | translate
              | styleTextPart : '3' : 'fz-10 superscript'
          "
          secondOption>
        </span>
      </lv-dual-toggle>
    </div>
    <div [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.AirSpeed" | translate }}
      </span>
      <lv-dual-toggle
        [formControlName]="'airSpeed'"
        [firstOption]="{
          title: AirSpeedUnitEnum.toTranslateKey(AirSpeedUnitEnum.MeterPerSecond),
          value: AirSpeedUnitEnum.MeterPerSecond
        }"
        [secondOption]="{
          title: AirSpeedUnitEnum.toTranslateKey(AirSpeedUnitEnum.FeetPerMinute),
          value: AirSpeedUnitEnum.FeetPerMinute,
          cssClass: MIN_OPTION_WIDTH_CLASS
        }"
        size="small" />
    </div>
    <div [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{
          (platformService.isMobileVersion
            ? "Controller.GeneralSettings.CustomUnitsDialog.MetricAirPressure"
            : "Controller.GeneralSettings.CustomUnitsDialog.AirPressure"
          ) | translate
        }}
      </span>
      <div class="d-flex flex-row">
        <div class="air-pressure-container">
          <lv-radio-list
            (change)="airPressureUnitChange($event)"
            [value]="form.controls['airPressure'].value"
            [list]="airPressureUnitList"
            size="small"
            direction="horizontal" />
        </div>
        <lv-dual-toggle
          *ngIf="!platformService.isMobileVersion"
          (change)="airPressureUnitChange($event)"
          [formControlName]="'airPressure'"
          [firstOption]="{ title: '', value: null }"
          [secondOption]="{
            title: null,
            value: AirPressureUnitEnum.InchesOfWater,
          }"
          size="small">
          <span
            [ngClass]="
              form.controls['airPressure'].value !== AirPressureUnitEnum.InchesOfWater ? 'highlight' : 'mono-grey'
            "
            firstOption
            class="fz-14">
            {{ " " }}
          </span>
          <span
            [class]="MIN_OPTION_WIDTH_CLASS + ' fz-14'"
            [ngClass]="
              form.controls['airPressure'].value === AirPressureUnitEnum.InchesOfWater ? 'highlight' : 'mono-grey'
            "
            [innerHtml]="
              AirPressureUnitEnum.toTranslateKey(AirPressureUnitEnum.InchesOfWater)
                | translate
                | styleTextPart
                  : ('Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.ofWater' | translate)
                  : 'fz-10'
            "
            secondOption>
          </span>
        </lv-dual-toggle>
      </div>
    </div>
    <div
      *ngIf="platformService.isMobileVersion"
      [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.AirPressure" | translate }}
      </span>
      <lv-dual-toggle
        (change)="airPressureUnitChange($event)"
        [formControlName]="'airPressure'"
        [firstOption]="{ title: '', value: null }"
        [secondOption]="{
            title: null,
            value: AirPressureUnitEnum.InchesOfWater,
          }"
        size="small">
        <span
          [ngClass]="
            form.controls['airPressure'].value !== AirPressureUnitEnum.InchesOfWater ? 'highlight' : 'mono-grey'
          "
          [innerHtml]="
            AirPressureUnitEnum.toTranslateKey(AirPressureUnitEnum.Pascal)
              | translate
              | styleTextPart
                : ('Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.ofWater' | translate)
                : 'fz-10'
          "
          firstOption
          class="fz-14 whitespace-nowrap">
          {{ " " }}
        </span>
        <span
          [class]="MIN_OPTION_WIDTH_CLASS + ' fz-14'"
          [ngClass]="
            form.controls['airPressure'].value === AirPressureUnitEnum.InchesOfWater ? 'highlight' : 'mono-grey'
          "
          [innerHtml]="
            AirPressureUnitEnum.toTranslateKey(AirPressureUnitEnum.InchesOfWater)
              | translate
              | styleTextPart
                : ('Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.ofWater' | translate)
                : 'fz-10'
          "
          secondOption>
        </span>
      </lv-dual-toggle>
    </div>
    <div [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.WaterMainPressure" | translate }}
      </span>
      <lv-dual-toggle
        [formControlName]="'waterMainPressure'"
        [firstOption]="{
          title: WaterMainPressureUnitEnum.toTranslateKey(WaterMainPressureUnitEnum.Bar),
          value: WaterMainPressureUnitEnum.Bar
        }"
        [secondOption]="{
          title: WaterMainPressureUnitEnum.toTranslateKey(WaterMainPressureUnitEnum.PSI),
          value: WaterMainPressureUnitEnum.PSI,
          cssClass: MIN_OPTION_WIDTH_CLASS
        }"
        size="small" />
    </div>
    <div [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.WaterLinePressure" | translate }}
      </span>
      <lv-dual-toggle
        [formControlName]="'waterLinePressure'"
        [firstOption]="{
          title: null,
          value: WaterLinePressureUnitEnum.CmOfWater,
          cssClass:
            form.controls['waterLinePressure'].value === WaterLinePressureUnitEnum.InchesOfWater ? 'mono-grey' : ''
        }"
        [secondOption]="{
          title: null,
          value: WaterLinePressureUnitEnum.InchesOfWater,
          cssClass: form.controls['waterLinePressure'].value === WaterLinePressureUnitEnum.CmOfWater ? 'mono-grey' : ''
        }"
        size="small">
        <span
          [ngClass]="
            form.controls['waterLinePressure'].value === WaterLinePressureUnitEnum.CmOfWater ? 'highlight' : 'mono-grey'
          "
          [innerHtml]="
            WaterLinePressureUnitEnum.toTranslateKey(WaterLinePressureUnitEnum.CmOfWater)
              | translate
              | styleTextPart
                : ('Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.ofWater' | translate)
                : 'fz-10'
          "
          firstOption
          class="fz-14">
        </span>
        <span
          [class]="MIN_OPTION_WIDTH_CLASS + ' fz-14'"
          [ngClass]="
            form.controls['waterLinePressure'].value === WaterLinePressureUnitEnum.InchesOfWater
              ? 'highlight'
              : 'mono-grey'
          "
          [innerHtml]="
            WaterLinePressureUnitEnum.toTranslateKey(WaterLinePressureUnitEnum.InchesOfWater)
              | translate
              | styleTextPart
                : ('Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.ofWater' | translate)
                : 'fz-10'
          "
          secondOption>
        </span>
      </lv-dual-toggle>
    </div>
    <div [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.Weight" | translate }}
      </span>
      <div class="d-flex flex-row">
        <lv-dual-toggle
          [value]="form.controls['weight'].value"
          [formControlName]="'weight'"
          [firstOption]="{
            title: WeightUnitEnum.toTranslateKey(WeightUnitEnum.Kilogram),
            value: WeightUnitEnum.Kilogram,
          }"
          [secondOption]="{
            title: WeightUnitEnum.toTranslateKey(WeightUnitEnum.Pound),
            value: WeightUnitEnum.Pound,
            cssClass: MIN_OPTION_WIDTH_CLASS
          }"
          size="small" />
      </div>
    </div>
    <div [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.Volume" | translate }}
      </span>
      <lv-dual-toggle
        [formControlName]="'volume'"
        [firstOption]="{ title: VolumeUnitEnum.toTranslateKey(VolumeUnitEnum.Litre), value: VolumeUnitEnum.Litre }"
        [secondOption]="{
          title: VolumeUnitEnum.toTranslateKey(VolumeUnitEnum.Gallon),
          value: VolumeUnitEnum.Gallon,
          cssClass: MIN_OPTION_WIDTH_CLASS
        }"
        size="small" />
    </div>
    <div [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.Length" | translate }}
      </span>
      <lv-dual-toggle
        [formControlName]="'length'"
        [firstOption]="{ title: LengthUnitEnum.toTranslateKey(LengthUnitEnum.Meter), value: LengthUnitEnum.Meter }"
        [secondOption]="{
          title: LengthUnitEnum.toTranslateKey(LengthUnitEnum.Foot),
          value: LengthUnitEnum.Foot,
          cssClass: MIN_OPTION_WIDTH_CLASS
        }"
        size="small" />
    </div>
    <div [class]="fieldClass">
      <span [class]="fieldLabelClass">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.Illuminance" | translate }}
      </span>
      <lv-dual-toggle
        [formControlName]="'illuminance'"
        [firstOption]="{
          title: IlluminanceUnitEnum.toTranslateKey(IlluminanceUnitEnum.Luxs),
          value: IlluminanceUnitEnum.Luxs,
          cssClass: form.controls['illuminance'].value === IlluminanceUnitEnum.FootCandle ? 'mono-grey' : ''
        }"
        [secondOption]="{
          title: null,
          value: IlluminanceUnitEnum.FootCandle,
        }"
        size="small">
        <span
          [class]="MIN_OPTION_WIDTH_CLASS + ' fz-14'"
          [ngClass]="form.controls['illuminance'].value === IlluminanceUnitEnum.FootCandle ? 'highlight' : 'mono-grey'"
          secondOption>
          {{ "Controller.GeneralSettings.CustomUnitsDialog.IlluminanceEnum.Foot" | translate }}
          &nbsp;-&nbsp;
          {{ "Controller.GeneralSettings.CustomUnitsDialog.IlluminanceEnum.candle" | translate }}
        </span>
      </lv-dual-toggle>
    </div>
  </form>
</div>
