import {
  ControllerUnitEnum,
  ControllerLanguageEnum,
  HoursFormatTypeEnum,
} from '@livestock/controllers/enums';
import { INetworkSettingsView, IUnitsDetails, IHouseSizesView } from '@livestock/controllers/interfaces';
import { TimeZonesEnum } from '@livestock/shared/constants';
import { BirdTypeEnum } from '@livestock/flock/models';

export interface ICreateAddControllerTicketRequestView {
  language: ControllerLanguageEnum;
  units: ControllerUnitEnum;
  birdType: BirdTypeEnum;
  timeZoneID: TimeZonesEnum;
  hoursFormat: HoursFormatTypeEnum;
  houseSizes: IHouseSizesView;
  manuallyDateTime?: string;
  networkSettings: INetworkSettingsView;
  unitsDetails?: IUnitsDetails | null;
}
