import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription, filter, interval, merge, switchMap } from 'rxjs';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SvgIconComponent } from '@livestock/ui';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { NetworkConnectionStatusEnum, NetworkConnectionTypesEnum } from '@livestock/controllers/enums';
import { IconsEnum } from '@livestock/shared/enums';
import {
  connectModemSuccess,
  connectWifiError,
  connectWifiSuccess,
  disconnectModemError,
  disconnectModemSuccess,
  disconnectWifiError,
  disconnectWifiSuccess,
  getNetworkStatus,
  getWifiListSuccess,
  selectNetworkStatus,
  updateLanSettingsSuccess,
} from '@livestock/controllers';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';
import { IAllStatuses } from '../../../interfaces/network/all-connections.interface';
import { setFlashMessage } from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';

const second_icon_map = {
  [NetworkConnectionTypesEnum.None]: IconsEnum.NO_NETWORK,
  [NetworkConnectionTypesEnum.Wifi]: [
    IconsEnum.WIFI_LOWEST,
    IconsEnum.WIFI_LOW,
    IconsEnum.WIFI_MID,
    IconsEnum.WIFI_HIGH,
    IconsEnum.NETWORK_WIFI_CONNECTED_FULL,
  ],
  [NetworkConnectionTypesEnum.Cellular]: [
    IconsEnum.CELL_LOW,
    IconsEnum.CELL_MID,
    IconsEnum.CELL_HIGH,
    IconsEnum.CELL_FULL,
  ],
  [NetworkConnectionTypesEnum.LAN]: IconsEnum.LAN_CONNECTED,
};

@Component({
  selector: 'lv-current-network-status',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
    QaTagsDirective,
    MemoizeFuncPipe,
  ],
  templateUrl: './current-network-status.component.html',
  styleUrls: ['./current-network-status.component.scss'],
})
export class CurrentNetworkStatusComponent implements OnInit, OnDestroy {
  @Input() networkStatus: {
    controllerName: string,
    networkStatus: NetworkConnectionTypesEnum,
    internetStatus: NetworkConnectionStatusEnum,
  };
  @Input() isLoading: boolean;

  @Output() isOnline = new EventEmitter();

  pollingInterval$ = interval(20000);
  actionsTrigger$ = this.actions$.pipe(
    filter(
      i =>
        i.type === updateLanSettingsSuccess.type ||
        i.type === getWifiListSuccess.type ||
        i.type === disconnectWifiSuccess.type ||
        i.type === connectWifiSuccess.type ||
        i.type === disconnectWifiError.type ||
        i.type === connectWifiError.type ||
        i.type === connectModemSuccess.type ||
        i.type === disconnectModemSuccess.type ||
        i.type === disconnectModemError.type,
    ),
  );

  sub$ = new Subscription();

  secondIcon: string = IconsEnum.NO_NETWORK;
  thirdIcon: string = IconsEnum.NO_INTERNET;
  secondLineError: boolean = true;
  firstLineError: boolean = true;
  secondIconText: string = this.translate.instant('Controller.NetworkSettings.NoNetwork');

  IconsEnum: typeof IconsEnum = IconsEnum;

  constructor(
    private translate: TranslateService,
    private actions$: Actions,
    private store: Store,
  ) {

  }

  ngOnInit(): void {

    this.sub$.add(
      merge(this.actionsTrigger$.pipe(filter(() => !this.isLoading)), this.pollingInterval$.pipe(filter(() => !this.isLoading)))
        .pipe(
          switchMap(() => {
            this.store.dispatch(getNetworkStatus({ overrideSettingsState: false }));
            return [];
          }),
        )
        .subscribe(),
    );

    this.sub$.add(
      this.store.select(selectNetworkStatus).pipe(
        wasChangedAndNotNull(),
      ).subscribe((networkStatus: IAllStatuses) => {
        // temp try/catch solution. Should remove after LV-1888 fixed
        try {
          switch (networkStatus.currentConnection) {
            case NetworkConnectionTypesEnum.Cellular:
              this.secondIcon = second_icon_map[networkStatus.currentConnection][networkStatus.modem.signal];
              break;
            case NetworkConnectionTypesEnum.Wifi:
              this.secondIcon = second_icon_map[networkStatus.currentConnection][networkStatus.wifi.signal];
              break;

            default:
              this.secondIcon = second_icon_map[networkStatus.currentConnection] as string;
              break;
          }

          switch (networkStatus.currentConnection) {
            case NetworkConnectionTypesEnum.LAN:
              this.secondIconText = `IP: ${networkStatus.connectionSettings.lan.ip}`;
              break;
            case NetworkConnectionTypesEnum.Wifi:
              this.secondIconText = networkStatus.wifi.name;
              break;
            case NetworkConnectionTypesEnum.Cellular:
              this.secondIconText = networkStatus.modem.name;
              break;
            case NetworkConnectionTypesEnum.None:
              this.secondIconText = this.translate.instant('Controller.NetworkSettings.NoNetwork');
              break;
            default:
              break;
          }
          this.thirdIcon = networkStatus.currentConnection === NetworkConnectionTypesEnum.None ?
            IconsEnum.NO_INTERNET :
            networkStatus.isOnline ? IconsEnum.INTERNET_CONNECTED : IconsEnum.INTERNET_ERROR;

          this.secondLineError = (this.thirdIcon === IconsEnum.INTERNET_ERROR) || (this.thirdIcon === IconsEnum.NO_INTERNET) ? true : false;
          this.firstLineError = this.secondIcon === IconsEnum.NO_NETWORK ? true : false;
          this.isOnline.emit(networkStatus.isOnline);
        } catch (e) {
          this.secondIcon = IconsEnum.NO_INTERNET;
          this.secondIconText = this.translate.instant('Controller.NetworkSettings.NoNetwork');
          this.thirdIcon = IconsEnum.NO_INTERNET;
          this.secondLineError = (this.thirdIcon === IconsEnum.INTERNET_ERROR) || (this.thirdIcon === IconsEnum.NO_INTERNET) ? true : false;
          this.firstLineError = this.secondIcon === IconsEnum.NO_NETWORK ? true : false;
          const errorMessage: string = 'Cannot parse network status. Fallback to disconected.';
          console.error(`${CurrentNetworkStatusComponent.name}: ${errorMessage}`);
          this.store.dispatch(setFlashMessage({
            flashMessage: {
              flashType: FlashMessageTypeEnum.Error,
              message: errorMessage,
              doNotTranslate: true,
              title: 'network status error',
            },
          }));
          this.isOnline.emit(networkStatus.isOnline);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
