import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'lv-farms-container',
  templateUrl: './farms-container.component.html',
  styleUrls: ['./farms-container.component.scss'],
})
export class FarmsContainerComponent {
}
