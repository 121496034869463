<div class="form-field">
  <div class="form-field__title">{{ label | translate }}</div>
  <div class="control-container d-flex align-center user-select-none">
    <ls-svg-icon
      (click)="decrement()"
      [class.pointer]="value > min"
      [color]="value <= min ? ColorsEnum.DefaultDisabled : ColorsEnum.MonoGrey"
      [path]="IconsEnum.MINUS_IN_SQUARE"></ls-svg-icon>
    <span class="mh-15 value fw-500">{{ value }}</span>
    <ls-svg-icon
      (click)="increment()"
      [class.pointer]="value < max"
      [color]="value >= max ? ColorsEnum.DefaultDisabled : ColorsEnum.MonoGrey"
      [path]="IconsEnum.PLUS_IN_SQUARE"></ls-svg-icon>
  </div>
</div>
<div class="description">
  <ng-content select="[description]"></ng-content>
</div>
