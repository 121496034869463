import { createAction, props } from '@ngrx/store';
import { MobileViewModeEnum } from '@livestock/layout';

export const setMobileViewMode = createAction(
    '[LAYOUT] Set Mobile View Mode [...]',
    props<{ mobileViewMode: MobileViewModeEnum }>(),
);

export const setIsDialogOpened = createAction(
    '[LAYOUT] set Is Dialog Opened [...]',
    props<{ isDialogOpened: boolean }>(),
);