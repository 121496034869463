import { NetworkConnectionStatusEnum } from '@livestock/controllers/enums';
import { IModemCredentials } from '../index';

export interface IModemSettings extends IModemCredentials {
  modem: number;
  modemIp?: string;
  modemMac?: string;
  modemName?: string;
  connectionResult?: NetworkConnectionStatusEnum;
}
