import { HouseModeEnum, StagingEnum } from '../enums';

export interface IFlockSettingsView {
  flockNumber: number;
  houseMode: HouseModeEnum;
  growthDay: number;
  growStartDayTime: number;
  separateMaleAndFemale: boolean;
  initialNumberOfBirds: number;
  femaleInitialNumberOfBirds: number;
  maleInitialNumberOfBirds: number;
  staging: StagingEnum;
  targetTemperature: number;
  enableTemperatureAlarm: boolean;
  ventilationLevel?: number;
  controllerID?: number;
}
