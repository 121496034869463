import { Component, Input, inject, OnInit, OnDestroy, Output, EventEmitter, DestroyRef, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { wasChanged, wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Actions } from '@ngrx/effects';

import { setIsFormEditing } from '@livestock/forms';
import { PlatformService } from '@livestock/shared/services';
import { IGetOrUpdateElement } from '@livestock/installation/interfaces';
import { selectCurrentElementSetup } from '../../../+state/installation.selectors';

@Component({
  template: '',
  standalone: true,
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class BasicElementComponent implements OnInit, OnDestroy {
  @Input({ required: true }) formGroupName: string;
  @Output() isChanged = new EventEmitter<boolean>();
  @Output() isValid = new EventEmitter<boolean>();

  protected parentFormContainer: ControlContainer = inject(ControlContainer);
  protected store: Store = inject(Store);
  protected platformService: PlatformService = inject(PlatformService);
  protected destroyRef: DestroyRef = inject(DestroyRef);
  protected actions$: Actions = inject(Actions);
  protected dialog: MatDialog = inject(MatDialog);
  protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected translateService: TranslateService = inject(TranslateService);

  protected initialValue: object;
  protected _isEditMode: boolean;

  @Input() set isEditMode(isEditMode: boolean) {
    this._isEditMode = isEditMode;
    if (!isEditMode && this.initialValue) {
      this.patchValue(this.initialValue);
    }

    if (isEditMode) {
      this.enableControls();
    } else {
      this.disableControls();
    }
  }

  get parentFormGroup(): FormGroup {
    return this.parentFormContainer.control as FormGroup;
  }

  get isEditMode(): boolean {
    return this._isEditMode;
  }

  ngOnInit(): void {
    this.store.select(selectCurrentElementSetup).pipe(
      wasChangedAndNotNull(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((elementSetup) => {
      this.patchValue(elementSetup);
      this.initialValue = this.parentFormGroup.getRawValue()[this.formGroupName];
      this.isChanged.emit(false);
      this.isValid.emit(this.parentFormGroup.valid);
    });

    this.parentFormGroup.valueChanges.pipe(
      wasChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      const formValues = this.parentFormGroup.getRawValue()[this.formGroupName];
      if (!formValues) return;

      const isChanged = JSON.stringify(this.initialValue) !== JSON.stringify(formValues);
      this.isChanged.emit(isChanged);
      this.isValid.emit(this.parentFormGroup.valid);
      this.store.dispatch(setIsFormEditing({ isEditing: isChanged }));
    });
  }

  patchValue(_setupData: IGetOrUpdateElement): void {
  }

  enableControls(): void {
    const form = this.parentFormGroup.get(this.formGroupName);
    if (form) {
      Object.keys(form['controls'])
        .forEach(key => {
          form['controls'][key].enable();
        });
    }
  }

  disableControls(): void {
    const form = this.parentFormGroup.get(this.formGroupName);

    if (form) {
      Object.keys(form['controls'])
        .forEach(key => {
          form['controls'][key].disable();
        });
    }
  }

  ngOnDestroy(): void {
    this.parentFormGroup.removeControl(this.formGroupName);
    //  this.sub$.unsubscribe();
  }
}
