import {
  IFlockBirdWeightSettingsView,
  IFlockBirdWeightView,
  IFlockHouseModeView,
  IFlockSettingsView,
} from '@livestock/flock/models';

export interface IFlockView {
  flockSettings: IFlockSettingsView;
  flockHouseMode: IFlockHouseModeView;
  flockBirdWeightSettings: IFlockBirdWeightSettingsView;
  flockBirdWeights: IFlockBirdWeightView[];
}
