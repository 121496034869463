import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { GlobalConstants } from '@livestock/shared/constants';
import {
  ICreateAddControllerTicketView,
} from '../../interfaces/controller/create-add-controller-ticket-view.interface';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ButtonComponent, SvgIconComponent } from '@livestock/ui';
import { Router } from '@angular/router';
import { QaTagsDirective } from '@livestock/shared/directives';
import { AppRoutes } from '@livestock/shared/routes';

import { StyleTextPartPipe } from '@livestock/shared/pipes';
import { INetworkSettingsView } from '@livestock/controllers/interfaces';
import { Observable } from 'rxjs';
import {
  selectDeviceControllerSerialNumber,
  selectNetworkSettings,
} from '../../+state/upsert-controller/upsert-controller.selectors';
import { DialogButtonEnum, IconsEnum } from '@livestock/shared/enums';
import { DialogsService } from '@livestock/shared/services';

@Component({
  selector: 'ls-cloud-page',
  standalone: true,
  imports: [
    CommonModule,
    QRCodeModule,
    TranslateModule,
    SvgIconComponent,
    ButtonComponent,
    QaTagsDirective,
    StyleTextPartPipe,
  ],
  templateUrl: './cloud-page.component.html',
  styleUrls: ['./cloud-page.component.scss'],
})
export class CloudPageComponent {
  @Input() set ticketView(ticketView: ICreateAddControllerTicketView) {
    if (ticketView) {
      this.qrCodeData = GlobalConstants.getCreateControllerUrl(
        ticketView.ticketID,
        ticketView.connectionNumber,
      );
      this.connectionNumber = ticketView.connectionNumber;
      this.sn = ticketView.sn;
    }
  }

  @Input() isControllerAssignedWithFarm: boolean;

  networkSettings$: Observable<INetworkSettingsView> = this.store.select(selectNetworkSettings);
  controllerSerialNumber$: Observable<string> = this.store.select(selectDeviceControllerSerialNumber);

  qrCodeData: string;
  connectionNumber: string;
  sn: string;
  updatedHouseNumber: number = 9999;
  farmName: string = 'farmName';
  userEmail: string = 'userEmail@userEmail.com';

  IconsEnum: typeof IconsEnum = IconsEnum;

  constructor(
    private store: Store,
    private router: Router,
    private dialogsService: DialogsService,
    private translateService: TranslateService,
  ) {
  }

  goToDashboard(): void {
    this.router.navigateByUrl(AppRoutes.DASHBOARD);
  }

  goToInstallation(): void {
    this.router.navigateByUrl(AppRoutes.INSTALLATION);
  }

  rejectFarm(): void {

  }

  acceptFarm(): void {

  }

  async openConnectionErrorMessage(): Promise<void> {
    const title = 'Controller.CloudSettings.ConnectionError';
    const message = this.translateService.instant('Controller.CloudSettings.IronNumberCannotConnectToYourAccount', {
      deviceNumber: 1234,
    });
    await this.dialogsService.question(
      message,
      title,
      [DialogButtonEnum.CLOSE],
      'fail-circle',
      false,
    );
  }
}
