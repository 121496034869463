import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as parringProcessActions from './parring-process.actions';
import { TicketControllersService } from 'libs/controllers/src/lib/services/ticket-controller.service';
import { IGetAddControllerTicketView } from '../interfaces/get-add-controller-ticket-view';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IGetAddControllerByConnectionNumberTicketView, isTicketReconnect } from '@livestock/parring-process';

@Injectable()
export class ParringProcessEffects {

  getAddControllerTicketView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parringProcessActions.getAddControllerTicketView),
      switchMap((
        {
          ticketId,
          isReconnect,
        },
      ) => this.ticketControllersService.getAddControllerByTicketID(ticketId, isReconnect)
        .pipe(
          map((ticketView: IGetAddControllerTicketView) => parringProcessActions.getAddControllerTicketViewSuccess({ ticketView })),
          catchError((error) => of(parringProcessActions.getAddControllerTicketViewError({ ticketId, error }))),
        ),
      ),
    ),
  );

  getControllerTicketInfoByConnectionNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parringProcessActions.getControllerTicketInfoByConnectionNumber),
      map(action => action.connectionNumber),
      switchMap((connectionNumber: string) => {
        return this.ticketControllersService.getControllerTicketByConnectionNumber(connectionNumber).pipe(
          map((controllerTicketByConnectionNumber: IGetAddControllerByConnectionNumberTicketView) =>
            parringProcessActions.getControllerTicketInfoByConnectionNumberSuccess({ controllerTicketByConnectionNumber })),
          catchError((error) => of(parringProcessActions.getControllerTicketInfoByConnectionNumberError({ payload: error }))),
        );
      }),
    ),
  );

  executeTicketController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parringProcessActions.executeTicketController),
      withLatestFrom(this.store.select(isTicketReconnect)),
      switchMap(([{ requestedView, ticketID }, isReconnect]) => {
        return this.ticketControllersService.executeAddControllerTicket(ticketID, requestedView, isReconnect).pipe(
          map(() => parringProcessActions.executeTicketControllerSuccess({ requestedView, isReconnect })),
          catchError((error) => of(parringProcessActions.executeTicketControllerError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions,
    private ticketControllersService: TicketControllersService,
  ) {
  }
}

