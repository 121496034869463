export enum DialogButtonEnum {
  YES = 1,
  NO,
  CANCEL,
  OK,
  DELETE,
  YES_CLOSE_POPUP,
  CLOSE,
  CONTINUE,
  MOVE,
  SET_DATE_AND_TIME,
  DISCARD,
  TRY_AGAIN,
  DISCONNECT,
  RECONNECT,
}
