<div
  class="d-flex justify-between p-18 pointer
             {{
    (activeControllerID$ | async) === controller.controllerID || platformService.isMobileVersion ? 'pr-36' : 'mr-18'
  }}
             controller-default"
  [class.controller-success]="controller.status === FarmControllerStatusEnum.FullHouse">
  <div>
    <div class="d-flex align-center">
      <ls-svg-icon
        [path]="IconsEnum.CONTROLLER_ICON_SMALL"
        [color]="ColorsEnum.Black" />
      <span class="pl-4 pr-8 fz-18 fw-600">{{ controller.houseNumber }}</span>
      <ls-svg-icon
        [path]="
          controller?.connectionStatus ? IconsEnum.NETWORK_CLOUD_CONNECTED2 : IconsEnum.NETWORK_CLOUD_NO_CONNECTION2
        "
        [color]="ColorsEnum.MainDarkGreen" />
    </div>
    <span class="fz-14 fw-600">{{ controller.status | enum : "farm-controller-status" | translate }}</span>
  </div>

  <div
    *ngIf="[FarmControllerStatusEnum.FullHouse, FarmControllerStatusEnum.NewHouse].includes(controller.status)"
    class="d-flex">
    <div class="mr-12">
      <div class="fz-10 color-darkgrey">{{ "ControllerMenu.Day" | translate }}</div>
      <div class="fz-32 fw-700">{{ controller.day }}</div>
    </div>
    <div>
      <div class="fz-10 color-darkgrey">{{ "ControllerMenu.AvgTemperature" | translate }}</div>
      <div class="d-flex">
        <div class="fz-32 fw-800 controller__temp">{{ controller.temp }}</div>
        <div class="d-flex mb-2 align-end color-darkgrey">
          <div class="fw-700 ml-2">°C</div>
          <div class="ml-2 fz-24">/</div>
          <div class="fw-700">
            <div class="color-darkred fz-14 text-right">-1.2</div>
            <div>25.0</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
