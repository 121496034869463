<section
  [formGroup]="form"
  class="d-flex flex-column align-center wrapper m-auto">
  <lv-loading-galcon
    *ngIf="showLoading"
    class="pseudo-loading"></lv-loading-galcon>

  <div class="content">
    <ng-container *ngIf="platformService.isMobileVersion; else justTitle">
      <div
        [ngClass]="locationMapIsOpen ? 'mb-28' : 'mb-123'"
        class="d-flex align-center align-self-baseline gap-8">
        <ls-svg-icon
          (click)="goToFarms()"
          [path]="IconsEnum.GO_BACK_ARROW"></ls-svg-icon>

        <div class="color-f5">
          <p class="mb-8"><span class="color-grey">Farms</span> / New Farm</p>

          <h2 class="fz-24">
            {{ "Farms.FarmBasicSettings" | translate }}
          </h2>
        </div>
      </div>
    </ng-container>

    <ng-template #justTitle>
      <h2 class="color-f5 mb-28 fz-24 align-self-baseline">
        {{ "Farms.FarmBasicSettings" | translate }}
      </h2>
    </ng-template>

    <div class="field mb-28">
      <ls-svg-icon
        [color]="fieldNameInputInFocus ? ColorsEnum.MainDarkBlue : ColorsEnum.MonoDark"
        [path]="IconsEnum.FARM_NAME"></ls-svg-icon>

      <input
        (focusin)="onFocusInFarmNameInput()"
        (focusout)="onFocusOutFarmNameInput()"
        #nameInput
        [placeholder]="farmNamePlaceholder | translate"
        [class.color-darkblue]="!fieldNameInputInFocus"
        class="field-name"
        type="text"
        maxlength="50"
        formControlName="name" />
    </div>

    <div class="map position-relative mb-28">
      <div
        [ngClass]="{
          'field-disabled': fieldNameInputInFocus
        }"
        class="field field-map justify-between">
        <ls-svg-icon
          [color]="fieldAddressInputInFocus ? ColorsEnum.MainDarkBlue : ColorsEnum.MonoDark"
          [path]="IconsEnum.FARM_LOCATION"></ls-svg-icon>

        <input
          (focusin)="onFocusInFarmLocationInput()"
          (focusout)="onFocusOutFarmLocationInput()"
          #addressInput
          [placeholder]="farmAddressPlaceholder | translate"
          [class.color-darkblue]="!fieldAddressInputInFocus"
          class="field-location"
          type="text"
          formControlName="address" />

        <ls-svg-icon
          [color]="fieldAddressInputInFocus ? ColorsEnum.MainDarkBlue : ColorsEnum.MonoDark"
          [path]="IconsEnum.LOCATION_MARKER"></ls-svg-icon>
      </div>

      <div
        #map
        [class.hide-map]="!locationMapIsOpen"
        class="map-area"></div>
    </div>

    <div class="w-100-percent save-btn">
      <lv-button
        (click)="saveFarm()"
        [height]="48"
        [type]="form.valid ? ButtonTypeEnum.PRIMARY : ButtonTypeEnum.DISABLED_TRANSPARENT">
        {{ "Farms.SaveFarm" | translate }}
      </lv-button>
    </div>
    <div
      *ngIf="!platformService.isMobileVersion && !form.valid && isCancellable"
      class="d-flex justify-center mt-28">
      <a
        [routerLink]="['/farms']"
        class="cancel-lnk">
        {{ "Farms.Cancel" | translate }}
      </a>
    </div>
  </div>
</section>
