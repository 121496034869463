import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IExecuteControllerByTicketView,
  IGetAddControllerByConnectionNumberTicketView,
  IGetAddControllerTicketView,
} from '@livestock/parring-process';

export const getAddControllerTicketView = createAction(
  '[PARRING_PROCESS] Get Add Controller Ticket View [...]',
  props<{ ticketId: string; isReconnect?: boolean }>(),
);

export const getAddControllerTicketViewSuccess = createAction(
  '[PARRING_PROCESS] Get Add Controller Ticket View [SUCCESS]',
  props<{ ticketView: IGetAddControllerTicketView; }>(),
);

export const getAddControllerTicketViewError = createAction(
  '[PARRING_PROCESS] Get Add Controller Ticket View [ERROR]',
  props<{ ticketId: string; error: HttpErrorResponse; }>(),
);

export const setValidControllerHouseNumber = createAction(
  '[PARRING_PROCESS] Set Valid Controller House Number [...]',
  props<{ houseNumber: number; }>(),
);

export const getControllerTicketInfoByConnectionNumber = createAction(
  '[PARRING_PROCESS CONTROLLER] Get Controller Ticket Info By Connection Number [...]',
  props<{ connectionNumber: string }>(),
);

export const getControllerTicketInfoByConnectionNumberSuccess = createAction(
  '[PARRING_PROCESS] Get Controller Ticket Info By Connection Number [SUCCESS]',
  props<{ controllerTicketByConnectionNumber: IGetAddControllerByConnectionNumberTicketView }>(),
);

export const getControllerTicketInfoByConnectionNumberError = createAction(
  '[PARRING_PROCESS] Get Controller Ticket Info By Connection Number [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setValidControllerTicketHouseNumber = createAction(
  '[PARRING_PROCESS] Set Valid Controller Ticket House Number [...]',
  props<{ houseNumber: number; }>(),
);

export const executeTicketController = createAction(
  '[PARRING_PROCESS] Execute Ticket Controller [...]',
  props<{ requestedView: IExecuteControllerByTicketView, ticketID: string }>(),
);

export const executeTicketControllerSuccess = createAction(
  '[PARRING_PROCESS] Execute Ticket Controller [SUCCESS]',
  props<{ requestedView: IExecuteControllerByTicketView, isReconnect: boolean }>(),
);

export const executeTicketControllerError = createAction(
  '[PARRING_PROCESS] Execute Ticket Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const clearIsControllerCreatedOrExecuted = createAction(
  '[PARRING_PROCESS] Clear Is Controller Created Or Executed [...]',
);