<div
  *ngIf="value != null"
  class="d-flex align-center">
  <div class="value-min mr-8 fz-12 fw-400">
    <span class="fw-500 value fz-16">{{ min }}</span>
  </div>

  <div
    [style.width.px]="sliderWidth"
    class="slider">
    <div
      *ngFor="let tick of [].constructor(TICKS_COUNT); index as index"
      [class.tick-active]="value | memoizeFunc : tickIsInRange : this"
      [style.left.px]="TICK_MARGIN_PX + TICKS_GAP_PX * index"
      class="tick"></div>

    <mat-slider
      [disableRipple]="true"
      [min]="min"
      [max]="max"
      class="large-thumb">
      <input
        #thumb
        [value]="value"
        matSliderThumb />
    </mat-slider>
  </div>

  <div class="value-max fz-12 fw-400">
    <span class="fw-500 value fz-16">{{ max }}</span>
  </div>
</div>
