import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  getControllerInfo,
  setActiveControllerID,
} from 'libs/controllers/src/lib/+state/current-controller/current-controller.actions';
import {
  selectActiveControllerID,
  selectControllerInfo,
} from 'libs/controllers/src/lib/+state/current-controller/current-controller.selectors';
import { filter, firstValueFrom } from 'rxjs';

export const controllerResolver: ResolveFn<boolean> =
  async (route: ActivatedRouteSnapshot) => {
    const store = inject(Store);

    const controllerInfo = await firstValueFrom(store.select(selectControllerInfo));
    const controllerID = route.params['controllerID']
      || await firstValueFrom(store.select(selectActiveControllerID));

    if (!controllerID) {
      return true;
    }

    if (controllerInfo == null || controllerInfo.controllerID !== controllerID) {
      store.dispatch(setActiveControllerID({ controllerID }));
      store.dispatch(getControllerInfo({ controllerID }));

      await firstValueFrom(store.select(selectControllerInfo).pipe(
        filter(info => info != null), // <- waiting until data is present
      ));
    }
    return true;
  };
