import { ConnectionTypeEnum } from './connection-type.enum';
import { IconsEnum } from './icons/icons.enum';
import { InstallationElementGroupsEnum } from './installation-element-groups.enum';

export enum ElementTypesEnum {
  Cooling = 1,
  HeatingDO = 2,
  HeatingAO = 3,
  SprinklersAndFoggers = 4,
  LightDO = 5,
  LightAO = 6,
  LightDI = 7,
  LightingSensor = 8,
  AirSpeed = 9,
  IndoorTemperature = 10,
  OutdoorTemperature = 11,
  IndoorHumidity = 12,
  OutdoorHumidity = 13,
  Ammonia = 14,
  CO2 = 15,
  StaticPressure = 16,
  VentilationDO = 17,
  GasMeter = 18,
  PowerMeter = 19,
  AuxiliaryInput = 20,
  CrossAuger = 21,
  FeederLine = 22,
  WaterMeter = 23,
  VentilationAO = 24,
  WaterPressure = 25,
  MainWaterMeter = 26,
  TunnelDoorDiscrete = 27,
  AsRelay = 32,
}

export namespace ElementTypesEnum {
  export function getElements(elementsGroup: InstallationElementGroupsEnum): ElementTypesEnum[] {
    switch (elementsGroup) {
      case InstallationElementGroupsEnum.Devices:
        return ElementTypesEnum.getDeviceElements();
      case InstallationElementGroupsEnum.Sensors:
        return ElementTypesEnum.getSensorElements();
      case InstallationElementGroupsEnum.Alarms:
        return [];
      case InstallationElementGroupsEnum.Scales:
        return [];
    }
  }

  export function getSensorElements(): ElementTypesEnum[] {
    return [
      ElementTypesEnum.IndoorTemperature,
      ElementTypesEnum.OutdoorTemperature,
      ElementTypesEnum.IndoorHumidity,
      ElementTypesEnum.OutdoorHumidity,
      ElementTypesEnum.CO2,
      ElementTypesEnum.Ammonia,
      ElementTypesEnum.StaticPressure,
      ElementTypesEnum.WaterMeter,
      ElementTypesEnum.AuxiliaryInput,
    ];
  }

  export function getDeviceElements(): ElementTypesEnum[] {
    return [
      ElementTypesEnum.Cooling,
      ElementTypesEnum.LightDO,
      ElementTypesEnum.LightAO,
      ElementTypesEnum.AsRelay,
    ];
  }

  //TODO: temp mockup, remove in future
  export function getConnectionNumber(value: ElementTypesEnum): number {
    switch (value) {
      case ElementTypesEnum.IndoorTemperature:
      case ElementTypesEnum.AuxiliaryInput:
        return 1;
      case ElementTypesEnum.OutdoorTemperature:
      case ElementTypesEnum.AsRelay:
        return 2;
      case ElementTypesEnum.IndoorHumidity:
      case ElementTypesEnum.WaterMeter:
        return 3;
      case ElementTypesEnum.OutdoorHumidity:
        return 4;
      case ElementTypesEnum.CO2:
        return 5;
      case ElementTypesEnum.StaticPressure:
        return 6;
      case ElementTypesEnum.LightDO:
        return 7;
      case ElementTypesEnum.LightAO:
        return 8;
      default:
        return 1;
    }
  }

  //TODO: temp mockup, remove in future
  export function getCardNumber(value: ElementTypesEnum): number {
    switch (value) {
      case ElementTypesEnum.AuxiliaryInput:
      case ElementTypesEnum.AsRelay:
      case ElementTypesEnum.WaterMeter:
        return 6;
      default:
        return 1;
    }
  }

  export function toTranslateKey(value: ElementTypesEnum): string {
    switch (value) {
      case ElementTypesEnum.IndoorTemperature:
        return 'Controller.Installation.SensorsElements.IndoorTemperature';
      case ElementTypesEnum.OutdoorTemperature:
        return 'Controller.Installation.SensorsElements.OutdoorTemperature';
      case ElementTypesEnum.IndoorHumidity:
        return 'Controller.Installation.SensorsElements.IndoorHumidity';
      case ElementTypesEnum.OutdoorHumidity:
        return 'Controller.Installation.SensorsElements.OutdoorHumidity';
      case ElementTypesEnum.CO2:
        return 'Controller.Installation.SensorsElements.CO2';
      case ElementTypesEnum.Ammonia:
        return 'Controller.Installation.SensorsElements.Ammonia';
      case ElementTypesEnum.StaticPressure:
        return 'Controller.Installation.SensorsElements.StaticPressure';
      case ElementTypesEnum.WaterMeter:
        return 'Controller.Installation.SensorsElements.WaterMeter';
      case ElementTypesEnum.AuxiliaryInput:
        return 'Controller.Installation.SensorsElements.AuxInput';
      case ElementTypesEnum.Cooling:
        return 'Controller.Installation.DeviceElements.Cooling';
      case ElementTypesEnum.LightDO:
        return 'Controller.Installation.DeviceElements.LightDO';
      case ElementTypesEnum.LightAO:
        return 'Controller.Installation.DeviceElements.LightAO';
      case ElementTypesEnum.AsRelay:
        return 'Controller.Installation.DeviceElements.AsRelay';
      default:
        return (value as unknown).toString();
    }
  }

  export function getSvgPath(value: ElementTypesEnum): string {
    switch (value) {
      case ElementTypesEnum.IndoorTemperature:
        return IconsEnum.INDOOR_TEMPERATURE;
      case ElementTypesEnum.OutdoorTemperature:
        return IconsEnum.OUTDOOR_TEMPERATURE;
      case ElementTypesEnum.IndoorHumidity:
        return IconsEnum.INDOOR_HUMIDITY;
      case ElementTypesEnum.OutdoorHumidity:
        return IconsEnum.OUTDOOR_HUMIDITY;
      case ElementTypesEnum.CO2:
        return IconsEnum.CO2;
      case ElementTypesEnum.Ammonia:
        return IconsEnum.AMMONIA;
      case ElementTypesEnum.StaticPressure:
        return IconsEnum.PRESSURE;
      case ElementTypesEnum.WaterMeter:
        return IconsEnum.WATER_METER;
      case ElementTypesEnum.AuxiliaryInput:
        return IconsEnum.AUXILIARY_INPUT;
      case ElementTypesEnum.Cooling:
        return IconsEnum.COOLING;
      case ElementTypesEnum.LightDO:
      case ElementTypesEnum.LightAO:
        return IconsEnum.LIGHTS;
      default:
        return (value as unknown).toString();
    }
  }

  export function getMaxConnectionsNumber(elementType: ElementTypesEnum): number {
    switch (elementType) {
      case ElementTypesEnum.AirSpeed:
      case ElementTypesEnum.OutdoorTemperature:
      case ElementTypesEnum.OutdoorHumidity:
      case ElementTypesEnum.Ammonia:
      case ElementTypesEnum.CO2:
      case ElementTypesEnum.StaticPressure:
      case ElementTypesEnum.WaterPressure:
        return 1;
      case ElementTypesEnum.GasMeter:
      case ElementTypesEnum.PowerMeter:
      case ElementTypesEnum.MainWaterMeter:
      case ElementTypesEnum.CrossAuger:
        return 2;
      case ElementTypesEnum.Cooling:
      case ElementTypesEnum.SprinklersAndFoggers:
      case ElementTypesEnum.IndoorHumidity:
        return 3;
      case ElementTypesEnum.LightDI:
      case ElementTypesEnum.LightDO:
      case ElementTypesEnum.LightAO:
      case ElementTypesEnum.LightingSensor:
      case ElementTypesEnum.FeederLine:
      case ElementTypesEnum.WaterMeter:
      case ElementTypesEnum.TunnelDoorDiscrete:
      case ElementTypesEnum.AuxiliaryInput:
        return 4;
      case ElementTypesEnum.HeatingDO:
      case ElementTypesEnum.HeatingAO:
        return 8;
      case ElementTypesEnum.IndoorTemperature:
        return 12;
      case ElementTypesEnum.AsRelay:
        return 20;
      case ElementTypesEnum.VentilationDO:
      case ElementTypesEnum.VentilationAO:
        return 40;
    }
  }

  export function getDeviceElementApiString(elementType: ElementTypesEnum): string {
    switch (elementType) {
      case ElementTypesEnum.IndoorTemperature:
        return 'indoor-temperature';
      case ElementTypesEnum.OutdoorTemperature:
        return 'outdoor-temperature';
      case ElementTypesEnum.IndoorHumidity:
        return 'indoor-humidity';
      case ElementTypesEnum.OutdoorHumidity:
        return 'outdoor-humidity';
      case ElementTypesEnum.CO2:
        return 'co2';
      case ElementTypesEnum.StaticPressure:
        return 'static-pressure';
      case ElementTypesEnum.WaterMeter:
        return 'water-meter';
      case ElementTypesEnum.AuxiliaryInput:
        return 'auxiliary-input';
      case ElementTypesEnum.Cooling:
        return 'cooling';
      case ElementTypesEnum.LightDO:
        return 'light-do';
      case ElementTypesEnum.LightAO:
        return 'light-ao';
      case ElementTypesEnum.AsRelay:
        return 'as-relay';
      default:
        return '';
    }
  }

  export function getWebElementApiString(elementType: ElementTypesEnum): string {
    switch (elementType) {
      case ElementTypesEnum.IndoorTemperature:
        return 'indoor-temperature-sensor';
      case ElementTypesEnum.OutdoorTemperature:
        return 'outdoor-temperature-sensor';
      case ElementTypesEnum.IndoorHumidity:
        return 'indoor-humidity-sensor';
      case ElementTypesEnum.OutdoorHumidity:
        return 'outdoor-humidity-sensor';
      case ElementTypesEnum.CO2:
        return 'co2-sensor';
      case ElementTypesEnum.StaticPressure:
        return 'static-pressure-sensor';
      case ElementTypesEnum.WaterMeter:
        return 'water-meter-sensor';
      case ElementTypesEnum.AuxiliaryInput:
        return 'auxiliary-input-sensor';
      case ElementTypesEnum.Cooling:
        return 'cooling';
      case ElementTypesEnum.LightDO:
        return 'light-do';
      case ElementTypesEnum.LightAO:
        return 'light-ao';
      case ElementTypesEnum.AsRelay:
        return 'as-relay';
      default:
        return '';
    }
  }

  export function getConnectionType(elementType: ElementTypesEnum): ConnectionTypeEnum {
    switch (elementType) {
      case ElementTypesEnum.IndoorTemperature:
      case ElementTypesEnum.OutdoorTemperature:
      case ElementTypesEnum.IndoorHumidity:
      case ElementTypesEnum.OutdoorHumidity:
      case ElementTypesEnum.LightingSensor:
      case ElementTypesEnum.Ammonia:
      case ElementTypesEnum.CO2:
      case ElementTypesEnum.StaticPressure:
      case ElementTypesEnum.WaterPressure:
        return ConnectionTypeEnum.AnalogInput;
      case ElementTypesEnum.HeatingAO:
      case ElementTypesEnum.LightAO:
      case ElementTypesEnum.VentilationAO:
        return ConnectionTypeEnum.AnalogOutput;
      case ElementTypesEnum.LightDI:
      case ElementTypesEnum.AirSpeed:
      case ElementTypesEnum.GasMeter:
      case ElementTypesEnum.PowerMeter:
      case ElementTypesEnum.AuxiliaryInput:
      case ElementTypesEnum.CrossAuger:
      case ElementTypesEnum.FeederLine:
      case ElementTypesEnum.WaterMeter:
      case ElementTypesEnum.MainWaterMeter:
      case ElementTypesEnum.TunnelDoorDiscrete:
        return ConnectionTypeEnum.DigitalInput;
      case ElementTypesEnum.Cooling:
      case ElementTypesEnum.HeatingDO:
      case ElementTypesEnum.SprinklersAndFoggers:
      case ElementTypesEnum.LightDO:
      case ElementTypesEnum.VentilationDO:
      case ElementTypesEnum.AsRelay:
        return ConnectionTypeEnum.DigitalOutput;
      default:
        return ConnectionTypeEnum.Unknown;
    }
  }
}
