import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INSTALLATION_FEATURE_KEY } from './installation.reducers';
import { InstallationState } from './installation.state';
import {
  ElementTypesEnum,
  InstallationElementGroupsEnum,
  TemperatureUnitEnum,
  AirPressureUnitEnum,
} from '@livestock/shared/enums';
import {
  EventCodeEnum,
  selectWebSocketEvents,
} from '@livestock/web-sockets';
import { selectActiveControllerID, selectControllerInfo } from '@livestock/controllers';
import { ElementEventInfo } from '@livestock/installation/interfaces';
import { IControllerView } from '@livestock/controllers/interfaces';
import { UnitsHelper, ConvertHelper } from '@livestock/shared/utils';

export const getInstallationState =
  createFeatureSelector<InstallationState>(INSTALLATION_FEATURE_KEY);

export const selectElements = createSelector(
  getInstallationState,
  ({ elements }) => elements,
);

export const selectElementsByGroup = createSelector(
  selectElements,
  (elements) => (elementGroupType: InstallationElementGroupsEnum) => {
    switch (elementGroupType) {
      case InstallationElementGroupsEnum.Sensors:
        return elements.filter(el => ElementTypesEnum.getSensorElements().includes(el.elementType));
      case InstallationElementGroupsEnum.Devices:
        return elements.filter(el => ElementTypesEnum.getDeviceElements().includes(el.elementType));
      // TODO: add same for devices, alarms and scales
      default:
        return [];
    }
  },
);

export const selectIfAnyElementConnected = createSelector(
  selectElements,
  (elements) => (elementType: ElementTypesEnum) => {
    const element = elements.find(x => x.elementType === elementType);
    return element.connectedCount > 0;
  },
);

export const selectIsLoading = createSelector(
  getInstallationState,
  ({ isLoading }) => isLoading,
);

export const selectCurrentElementType = createSelector(
  getInstallationState,
  ({ currentElementType }) => currentElementType,
);

export const selectCurrentElementSetup = createSelector(
  getInstallationState,
  ({ currentElementSetup }) => currentElementSetup,
);

export const selectElementEventInfo = createSelector(
  selectWebSocketEvents,
  selectActiveControllerID,
  selectControllerInfo,
  ({ events }: any, activeControllerID: number | null, controllerInfo: IControllerView) =>
    (elementType: ElementTypesEnum, elementNumber: number, isDeviceApp: boolean): ElementEventInfo => {
      const elementEvents = events
        .filter((e) => e.ec === EventCodeEnum.Element
          && e.controlUnitID === activeControllerID
          && e.dataEvent?.elementType === elementType
          && e.dataEvent?.number === elementNumber);

      if (elementEvents?.length === 0) {
        return null;
      }

      const event = elementEvents[elementEvents.length - 1].dataEvent;
      let value = event.value;

      switch (elementType) {
        case ElementTypesEnum.IndoorTemperature:
        case ElementTypesEnum.OutdoorTemperature: {
          value = !isDeviceApp
            ? event.value / 10
            : event.value;
          const tempUnit = UnitsHelper.getTemperatureUnit(controllerInfo);
          value = tempUnit === TemperatureUnitEnum.Fahrenheit
            ? ConvertHelper.celsiusToFahrenheit(value, 1)
            : value;
        }
          break;
        case ElementTypesEnum.StaticPressure: {
          const pressureUnit = UnitsHelper.getPressureUnit(controllerInfo);
          if (pressureUnit !== AirPressureUnitEnum.Pascal) {
            const conversionFunction = ConvertHelper.getAirConversionMethod(AirPressureUnitEnum.Pascal, pressureUnit);
            value = conversionFunction(value, 0);
          }
        }
          break;
      }

      return {
        number: event.number,
        elementType: event.elementType,
        status: event.status,
        value,
        testTime: event.testTime,
      };
    });
