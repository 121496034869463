import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'lv-farm-info',
  templateUrl: './farm-info.component.html',
  styleUrls: ['./farm-info.component.scss'],
})
export class FarmInfoComponent implements OnInit, OnDestroy {
  sub$ = new Subscription();
  farmID: number;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.activatedRoute.params.pipe(
      ).subscribe(({ farmID }) => {
        this.farmID = +farmID;
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
