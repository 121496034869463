import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@livestock/ui';
import { ActivatedRouteService, MenuService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';

@Component({
  standalone: true,
  selector: 'ls-display-top-menu-quick-start',
  templateUrl: 'display-top-menu-quick-start.component.html',
  imports: [
    RouterLink,
    CommonModule,
    SvgIconComponent,
    QaTagsDirective,
    MemoizeFuncPipe,
  ],
  styleUrls: ['display-top-menu-quick-start.component.scss'],
})
export class DisplayTopMenuQuickStartComponent {
  constructor(
    public menuService: MenuService,
    public activatedRouteService: ActivatedRouteService,
    public router: Router,
  ) {
  }

  // TODO: temp solution for qa;
  reset(): void {
    location.reload();
  }

}
