<div
  class="button button-{{ type }} {{ cssClass }} ph-{{ paddingH }}"
  [style.height.px]="height">
  <ls-svg-icon
    *ngIf="iconPath"
    [qaTags]="qaTags"
    [ngClass]="iconPosition === ButtonIconPositionEnum.LEFT ? 'icon-left' : 'icon-right'"
    [path]="iconPath">
  </ls-svg-icon>
  <ls-svg-icon
    *ngIf="iconWizard === ButtonIconWizardEnum.BACK"
    [qaTags]="qaTags"
    [path]="IconsEnum.LEFT_ARROW"
    class="back">
  </ls-svg-icon>
  <ls-svg-icon
    *ngIf="iconWizard === ButtonIconWizardEnum.NEXT"
    [qaTags]="qaTags"
    [path]="IconsEnum.RIGHT_ARROW"
    class="next">
  </ls-svg-icon>

  <ng-content></ng-content>
</div>
