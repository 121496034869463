import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, forkJoin, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as currentControllerActions from '../current-controller.actions';
import {
  selectActiveControllerID,
  selectControllerLanguage,
  selectCurrentControllerLengthUnit, selectCurrentControllerTemperatureUnit,
} from '../current-controller.selectors';
import { Router } from '@angular/router';
import { PlatformService } from '@livestock/shared/services';
import { PageTypeEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import { MatDialog } from '@angular/material/dialog';
import { setFlashMessage } from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';
import {
  ControllerEditModeService,
  ControllerLanguageEnum,
  ControllerSettingsService, ICard, IEditMode, ILastEdited,
  INetworkSettingsView,
} from '@livestock/controllers';
import { ConvertHelper, TimeUtils } from '@livestock/shared/utils';
import { TypedAction } from '@ngrx/store/src/models';

@Injectable()
export class SettingsEffects {

  getLastEditedSettingsInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getLastEditedSettingsInfo),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ pageType }, controllerID]) => {
        return this.controllerEditModeService.getLastEdited(controllerID as number, pageType).pipe(
          map((lastEdited) =>
            currentControllerActions.getLastEditedSettingsInfoSuccess({ lastEdited })),
          catchError((error) => of(currentControllerActions.getLastEditedSettingsInfoError({ payload: error }))),
        );
      }),
    ),
  );

  getGeneralSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getControllerGeneralSettings),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_, controllerID]) => {
        return (this.platformService.isDeviceApp ? forkJoin([
          this.controllerSettingsService.getGeneralSettings(controllerID as number),
          this.controllerEditModeService.getLastEdited(controllerID as number, PageTypeEnum.UnitsSettings),
          of(null),
          of(null),
        ]) : forkJoin([
          this.controllerSettingsService.getGeneralSettings(controllerID as number),
          this.controllerEditModeService.getLastEdited(controllerID as number, PageTypeEnum.UnitsSettings),
          this.controllerEditModeService.getEditMode(controllerID as number, PageTypeEnum.UnitsSettings),
          this.controllerEditModeService.getCnfStatus(controllerID as number, PageTypeEnum.UnitsSettings),
        ])).pipe(
          map(([generalSettings, lastEdited, editMode, cnfStatus]) =>
            currentControllerActions.getControllerGeneralSettingsSuccess({
              generalSettings,
              lastEdited,
              editMode,
              cnfStatus,
            })),
          catchError((error) => of(currentControllerActions.getControllerGeneralSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getGeneralSettingsOnly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getControllerGeneralSettingsOnly),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_, controllerID]) => {
        return this.controllerSettingsService.getGeneralSettings(controllerID as number).pipe(
          map((generalSettings) =>
            currentControllerActions.getControllerGeneralSettingsOnlySuccess({ generalSettings })),
          catchError((error) => of(currentControllerActions.getControllerGeneralSettingsOnlyError({ payload: error }))),
        );
      }),
    ),
  );

  updateGeneralSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.updateControllerGeneralSettings),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ generalSettings, unitsDetails }, controllerID]) => {
        return this.controllerSettingsService.updateGeneralSettings(controllerID as number, generalSettings, unitsDetails).pipe(
          switchMap(() => {
            const actions: TypedAction<any>[] = [
              currentControllerActions.updateControllerGeneralSettingsSuccess({
                pageType: PageTypeEnum.UnitsSettings,
                generalSettings,
                unitsDetails,
              }),
              currentControllerActions.getLastEditedSettingsInfo({
                pageType: PageTypeEnum.UnitsSettings,
              }),
            ];

            if (!this.platformService.isDeviceApp) {
              actions.push(setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.UnitsSettingsWereSuccessfullyUpdated',
                },
              }));
            }
            return actions;
          }),
          catchError((error) => of(currentControllerActions.updateControllerGeneralSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getHouseSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getControllerHouseSizesSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentControllerLengthUnit),
      ),
      switchMap(([_, controllerID, lengthUnit]) => {
        return (this.platformService.isDeviceApp
          ? forkJoin([
            this.controllerSettingsService.getHouseSizesSettings(controllerID as number as number, lengthUnit),
            this.controllerEditModeService.getLastEdited(controllerID as number as number, PageTypeEnum.HouseSizesSettings),
            of(null),
            of(null),
          ])
          : forkJoin([
            this.controllerSettingsService.getHouseSizesSettings(controllerID as number as number, lengthUnit),
            this.controllerEditModeService.getLastEdited(controllerID as number as number, PageTypeEnum.HouseSizesSettings),
            this.controllerEditModeService.getEditMode(controllerID as number as number, PageTypeEnum.HouseSizesSettings),
            this.controllerEditModeService.getCnfStatus(controllerID as number as number, PageTypeEnum.HouseSizesSettings),
          ])).pipe(
          map(([houseSettings, lastEdited, editMode, cnfStatus]) =>
            currentControllerActions.getControllerHouseSizesSettingsSuccess({
              houseSettings,
              lastEdited,
              editMode,
              cnfStatus,
            })),
          catchError((error) => of(currentControllerActions.getControllerHouseSizesSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  updateHouseSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.updateControllerHouseSizesSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentControllerLengthUnit),
      ),
      switchMap(([{ houseSettings, prevHouseNumber }, controllerID, lengthUnit]) => {
        return this.controllerSettingsService.updateHouseSizesSettings(controllerID as number as number, houseSettings, lengthUnit).pipe(
          switchMap(() => {
            const actions: TypedAction<any>[] = [
              currentControllerActions.updateControllerHouseSizesSettingsSuccess({
                pageType: PageTypeEnum.HouseSizesSettings,
                houseSettings,
                prevHouseNumber,
              }),
              currentControllerActions.getLastEditedSettingsInfo({
                pageType: PageTypeEnum.HouseSizesSettings,
              }),
            ];

            if (!this.platformService.isDeviceApp) {
              actions.push(setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.HouseSettingsWereSuccessfullyUpdated',
                },
              }));
            }

            return actions;
          }),
          catchError((error) => of(currentControllerActions.updateControllerHouseSizesSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getDateTimeSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getControllerDateTimeSettings),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_, controllerID]) => {

        return (this.platformService.isDeviceApp ? forkJoin([
          this.controllerSettingsService.getDateTimeSettings(controllerID as number),
          this.controllerEditModeService.getLastEdited(controllerID as number, PageTypeEnum.DateTimeSettings),
          of(null),
          of(null),
        ]) : forkJoin([
          this.controllerSettingsService.getDateTimeSettings(controllerID as number),
          this.controllerEditModeService.getLastEdited(controllerID as number, PageTypeEnum.DateTimeSettings),
          this.controllerEditModeService.getEditMode(controllerID as number, PageTypeEnum.DateTimeSettings),
          this.controllerEditModeService.getCnfStatus(controllerID as number, PageTypeEnum.DateTimeSettings),
        ])).pipe(
          map(([dateTimeSettings, lastEdited, editMode, cnfStatus]) =>
            currentControllerActions.getControllerDateTimeSettingsSuccess({
              dateTimeSettings,
              lastEdited,
              editMode,
              cnfStatus,
            })),
          catchError((error) => of(currentControllerActions.getControllerDateTimeSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getControllerDateTimeSettingsOnly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getControllerDateTimeSettingsOnly),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([, controllerID]) => {
        return this.controllerSettingsService.getDateTimeSettings(controllerID).pipe(
          map((dateTimeSettings) =>
            currentControllerActions.getControllerDateTimeSettingsOnlySuccess({ dateTimeSettings })),
          catchError((error) => of(currentControllerActions.getControllerDateTimeSettingsOnlyError({ payload: error }))),
        );
      }),
    ),
  );

  updateDateTimeSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.updateControllerDateTimeSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectControllerLanguage),
      ),
      switchMap(([{ dateTimeSettings }, controllerID, language]) => {
        const date = language === ControllerLanguageEnum.EngUS
          ? dateTimeSettings.date
          : TimeUtils.usualDateFormatToUSADateFormat(dateTimeSettings.date);

        const updatedDateTimeSettings = {
          ...dateTimeSettings,
          date,
        };

        return this.controllerSettingsService.updateDateTimeSettings(controllerID as number, updatedDateTimeSettings).pipe(
          switchMap(() => {
            const actions: TypedAction<any>[] = [
              currentControllerActions.updateControllerDateTimeSettingsSuccess({
                pageType: PageTypeEnum.DateTimeSettings,
                dateTimeSettings,
              }),
              currentControllerActions.getLastEditedSettingsInfo({
                pageType: PageTypeEnum.DateTimeSettings,
              }),
              currentControllerActions.getControllerDateTimeSettingsOnly(),
            ];

            if (!this.platformService.isDeviceApp) {
              actions.push(setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.DateTimeSettingsWereSuccessfullyUpdated',
                },
              }));
            }

            return actions;
          }),
          catchError((error) => of(currentControllerActions.updateControllerDateTimeSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getNetworkSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getCurrentControllerNetworkSettings),
      switchMap(({ controllerID }) => {
        return this.controllerSettingsService.getNetworkSettings(controllerID).pipe(
          map((networkSettings: INetworkSettingsView) =>
            currentControllerActions.getCurrentControllerNetworkSettingsSuccess({ networkSettings })),
          catchError((error) => of(currentControllerActions.getCurrentControllerNetworkSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getFlockSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getCurrentControllerFlockSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentControllerTemperatureUnit),
      ),
      switchMap(([_, controllerID, tempUnit]) => {
        return (this.platformService.isDeviceApp ? forkJoin([
          this.controllerSettingsService.getFlockSettings(controllerID as number),
          this.controllerEditModeService.getLastEdited(controllerID as number, PageTypeEnum.FlockSettings),
          of(null),
          of(null),
          of(tempUnit),
        ]) : forkJoin([
          this.controllerSettingsService.getFlockSettings(controllerID as number),
          this.controllerEditModeService.getLastEdited(controllerID as number, PageTypeEnum.FlockSettings),
          this.controllerEditModeService.getEditMode(controllerID as number, PageTypeEnum.FlockSettings),
          this.controllerEditModeService.getCnfStatus(controllerID as number, PageTypeEnum.FlockSettings),
          of(tempUnit),
        ])).pipe(
          map(([flockSettings, lastEdited, editMode, cnfStatus, tempUnit]) =>
            currentControllerActions.getCurrentControllerFlockSettingsSuccess({
              flockSettings: {
                ...flockSettings,
                targetTemperature: tempUnit === TemperatureUnitEnum.Celsius
                  ? flockSettings.targetTemperature
                  : ConvertHelper.celsiusToFahrenheit(flockSettings.targetTemperature, 1),
              },
              lastEdited,
              editMode,
              cnfStatus,
            })),
          catchError((error) => of(currentControllerActions.getCurrentControllerFlockSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  updateFlockSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.updateCurrentControllerFlockSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentControllerTemperatureUnit),
      ),
      switchMap(([{ flockSettings }, controllerID, tempUnit]) => {
        const updatedFlockSettings = {
          ...flockSettings,
          targetTemperature: tempUnit === TemperatureUnitEnum.Celsius
            ? flockSettings.targetTemperature
            : ConvertHelper.fahrenheitToCelsius(flockSettings.targetTemperature, 1),
        };

        return this.controllerSettingsService.updateFlockSettings(controllerID as number, updatedFlockSettings).pipe(
          switchMap(() => {
            const actions: TypedAction<any>[] = [
              currentControllerActions.updateCurrentControllerFlockSettingsSuccess({
                pageType: PageTypeEnum.FlockSettings,
                flockSettings,
              }),
              currentControllerActions.getLastEditedSettingsInfo({
                pageType: PageTypeEnum.FlockSettings,
              }),
            ];

            if (!this.platformService.isDeviceApp) {
              actions.push(setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.FlockSettingsWereSuccessfullyUpdated',
                },
              }));
            }

            return actions;
          }),
          catchError((error) => of(currentControllerActions.updateCurrentControllerFlockSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  createFlock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.createNewFlock),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentControllerTemperatureUnit),
      ),
      switchMap(([{ flockSettings }, controllerID, tempUnit]) => {
        const updatedFlockSettings = {
          ...flockSettings,
          targetTemperature: tempUnit === TemperatureUnitEnum.Celsius
            ? flockSettings.targetTemperature
            : ConvertHelper.fahrenheitToCelsius(flockSettings.targetTemperature, 1),
        };

        return this.controllerSettingsService.createNewFlock(controllerID as number, updatedFlockSettings).pipe(
          switchMap(() => {
            const actions: TypedAction<any>[] = [
              currentControllerActions.createNewFlockSuccess({
                pageType: PageTypeEnum.FlockSettings,
                flockSettings,
              }),
              currentControllerActions.getLastEditedSettingsInfo({
                pageType: PageTypeEnum.FlockSettings,
              }),
            ];

            if (!this.platformService.isDeviceApp) {
              actions.push(setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.NewFlockWasSuccessfullyCreated',
                },
              }));
            }

            return actions;
          }),
          catchError((error) => of(currentControllerActions.createNewFlockError({ payload: error }))),
        );
      }),
    ),
  );

  getCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getCards),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_, controllerID]) => {
        const editModeMockup: IEditMode = { isEditModeDisable: false, user: null };

        return (this.platformService.isDeviceApp
          ? forkJoin([
            // this.controllerSettingsService.getCards(),
            of([] as ICard[]),
            this.controllerEditModeService.getLastEdited(controllerID as number, PageTypeEnum.Installation),
            of(null as IEditMode),
          ])
          : forkJoin([
            // this.controllerSettingsService.getCards(),
            of([] as ICard[]),
            // this.controllerEditModeService.getLastEdited(controllerID as number, PageTypeEnum.Installation),
            of(null as ILastEdited),
            of(editModeMockup as IEditMode),
            // this.controllerEditModeService.getEditMode(controllerID as number, PageTypeEnum.Installation),
          ])).pipe(
          map(([cards, lastEdited, editMode]: [ICard[], ILastEdited, IEditMode]) =>
            currentControllerActions.getCardsSuccess({
              cards,
              lastEdited,
              editMode,
            })),
          catchError((error) => of(currentControllerActions.getCardsError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private router: Router,
    private platformService: PlatformService,
    private dialog: MatDialog,
    private actions$: Actions,
    private controllerSettingsService: ControllerSettingsService,
    private controllerEditModeService: ControllerEditModeService,
  ) {
  }
}
