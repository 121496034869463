<div
  [class.input-disabled]="isDisabled"
  class="main-input-container position-relative w-100-percent">
  <div
    (click)="focusInput()"
    [ngClass]="{
      'ls-input__disabled': forceDisabled || isDisabled
    }"
    class="ls-input {{ lsInputClassList }}">
    <ng-content select="[leftIcon]"></ng-content>
    <div
      [class.ph-8]="!noWrapperPadding"
      [style.justify-content]="inputWrapperContentAlign"
      class="input-wrapper">
      <!--important for translating placeholder; value == null not working here-->
      <input
        (blur)="onBlur()"
        (focusout)="onFocusOutEvent()"
        #input
        [disabled]="forceDisabled || isDisabled"
        [style.text-align]="textAlign"
        [style.font-weight]="fontWeight"
        [style.min-width]="inputMinWidth"
        [ngClass]="{
                    'no-border': noBorder,
                    'font-weight-depended': fontWeightDependingOnFocus,
                    'full-width': fullWidth,
                    'no-paddings': noPaddings,
                    'no-max-width': noMaxWidth,
                    'rtl': languageService.isRtl,
                    }"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [style.color]="textColor"
        class="{{ inputClassList }}"
        type="text" />

      <label
        [class.input-label_filled]="!!value || value === 0 || placeholder"
        [class.input-label_filled--imidiate]="disableAnimation"
        class="input-label d-flex align-center">
        {{ label | translate }}

        <ng-container *ngIf="labelIcon">
          <ls-svg-icon
            [path]="labelIcon"
            class="ml-2"></ls-svg-icon>
        </ng-container>
      </label>

      <span
        #content
        [ngClass]="{ icon__rtl: isRtl, icon: isContent }"
        [style.left.px]="languageService.isRtl ? 0 : valueLengthPx + inputPadding + extraPadding"
        [style.right.px]="!languageService.isRtl ? 0 : valueLengthPx + inputPadding + extraPadding"
        class="position-absolute suffix w-mc pointer-events-none">
        <ng-content select="[suffix]"></ng-content>
      </span>

      <!--for value length calculation-->
      <span
        *ngIf="isContent && viewInitiated"
        #valueLengthSpan
        class="position-absolute value-raw">
        {{ inputRef.nativeElement.value || placeholder }}
      </span>
    </div>
    <ng-content select="[rightIcon]"></ng-content>
  </div>
  <div class="description">
    <ng-content select="[description]"></ng-content>
  </div>
</div>
