<div
  [qaTags]="'qa-stp-networkStatus'"
  class="network-status">
  <div class="network-status__step">
    <ls-svg-icon
      [path]="IconsEnum.CONTROLLER"
      class="network-status__icon network-status__icon--success"></ls-svg-icon>
    <p class="network-status__text">ID 9087578</p>
  </div>

  <div
    [class.network-status__connection--error]="firstLineError"
    class="network-status__connection">
    <ls-svg-icon [path]="firstLineError ? IconsEnum.CROSS : IconsEnum.CHECK"></ls-svg-icon>
  </div>

  <div class="network-status__step">
    <ls-svg-icon
      [path]="secondIcon"
      [class.network-status__icon--success]="secondIcon !== IconsEnum.NO_NETWORK"
      class="network-status__icon"></ls-svg-icon>
    <p class="network-status__text">
      {{ secondIconText }}
    </p>
  </div>

  <div
    [class.network-status__connection--error]="secondLineError"
    class="network-status__connection">
    <ls-svg-icon [path]="secondLineError ? IconsEnum.CROSS : IconsEnum.CHECK"></ls-svg-icon>
  </div>

  <div class="network-status__step">
    <ls-svg-icon
      [path]="thirdIcon"
      [class.network-status__icon--success]="!secondLineError"
      class="network-status__icon"></ls-svg-icon>
    <p class="network-status__text">
      {{
        (secondLineError ? "Controller.NetworkSettings.NoInternet" : "Controller.NetworkSettings.Connected") | translate
      }}
    </p>
  </div>
</div>
