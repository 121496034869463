import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService, PlatformService } from '@livestock/shared/services';
import { GlobalConstants } from '@livestock/shared/constants';
import { StorageItem } from '@livestock/shared/enums';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(private platformService: PlatformService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.platformService.isDeviceApp
      ? GlobalConstants.InfiniteTokenForDevice
      : LocalStorageService.getStorageItem(StorageItem.Token);

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(req);
  }
}
