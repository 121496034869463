import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ControllerMenuComponent } from '../controller-menu/controller-menu.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    TranslateModule,
  ],
  selector: 'lv-controller-menu-mobile',
  templateUrl: './controller-menu-mobile.component.html',
  styleUrls: ['./controller-menu-mobile.component.scss'],
})
export class ControllerMenuMobileComponent extends ControllerMenuComponent {
  @Output() showPage = new EventEmitter();

  override goToPage(route: string): void {
    super.goToPage(route);
    this.showPage.emit();
  }
}
