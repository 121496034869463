import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { LanguageService } from '@livestock/shared/services';
import { ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { ThemeEnum } from '@livestock/ui';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { ToggleIcon, ToggleOption } from './interface';

@Component({
  selector: 'lv-dual-toggle',
  templateUrl: './dual-toggle.component.html',
  styleUrls: ['./dual-toggle.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonToggleModule, SvgIconComponent, MemoizeFuncPipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DualToggleComponent),
      multi: true,
    },
  ],
})
export class DualToggleComponent implements ControlValueAccessor {
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() disabled: boolean = false;
  @Input() theme: ThemeEnum = ThemeEnum.Default;
  @Input({ required: true }) firstOption: ToggleOption;
  @Input({ required: true }) secondOption: ToggleOption;
  @Input() toggleIcon: ToggleIcon;
  @Input() optionsTitlePosition: 'before' | 'both' | 'after' = 'both';

  @Input() set value(value: any) {
    this.isChecked = value === this.secondOption?.value;
    this.onChange(value);
  }

  // for cases when there is no formControlValue
  @Input() set notFormValue(notFormValue: any) {
    this.isChecked = notFormValue === this.secondOption?.value;
  }

  @Output() change = new EventEmitter<any>();

  isChecked: boolean;

  IconsEnum: typeof IconsEnum = IconsEnum;
  readonly MIN_OPTION_WIDTH: string = '27px';

  constructor(public languageService: LanguageService) {
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  toggleOption(): void {
    if (!this.disabled) {
      const selectedValue = this.isChecked ? this.firstOption.value : this.secondOption.value;
      this.isChecked = selectedValue === this.secondOption.value;

      this.onChange(selectedValue);
      this.change.emit(selectedValue);
    }
  }

  getIconColorByTheme(theme: ThemeEnum): string {
    switch (theme) {
      case ThemeEnum.Default:
        return ColorsEnum.White;
      case ThemeEnum.Dark:
        return ColorsEnum.MonoDark;
      case ThemeEnum.Main:
        return ColorsEnum.MainBlue;
      case ThemeEnum.Idle:
        return ColorsEnum.White;
    }
  }

  getIconPath(isChecked: boolean): ToggleIcon {
    return (isChecked ? this.secondOption?.toggleIcon : this.firstOption?.toggleIcon) || this.toggleIcon;
  }

  getTheme(isChecked: boolean): ThemeEnum {
    return (isChecked ? this.secondOption?.theme : this.firstOption?.theme) || this.theme;
  }

  getSelectedOptionTitle(isChecked: boolean): string {
    return isChecked ? this.secondOption?.title : this.firstOption?.title;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

