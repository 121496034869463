import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogsService, LanguageService, LocalStorageService, PlatformService } from '@livestock/shared/services';
import { EnumPipe, EnumToArrayPipe } from '@livestock/shared/pipes';
import {
  DualToggleComponent,
  InputIntegerComponent,
  LoadingGalconComponent,
  RadioListComponent,
  SvgIconComponent,
  ThemeEnum,
} from '@livestock/ui';
import { IGeneralSettingsView } from '@livestock/controllers/interfaces';
import { ControllerLanguageEnum, ControllerUnitEnum } from '@livestock/controllers/enums';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { Store } from '@ngrx/store';
import { setControllerLanguage } from '../../+state/current-controller/current-controller.actions';
import { ColorsEnum, IconsEnum, StorageItem } from '@livestock/shared/enums';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { CustomUnitsListComponent } from '../custom-units-list/custom-units-list.component';
import { GlobalConstants } from '@livestock/shared/constants';
import { fadeInOut } from '@livestock/shared/animations';
import { setIsFormEditing } from '@livestock/forms';
import { BirdTypeEnum } from '../../../../../flock/src/lib/enums';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    EnumPipe,
    QaTagsDirective,
    InputIntegerComponent,
    MatMenuModule,
    DualToggleComponent,
    EnumToArrayPipe,
    MatRadioModule,
    SvgIconComponent,
    RadioListComponent,
    CustomUnitsListComponent,
    LoadingGalconComponent,
  ],
  selector: 'ls-controller-general-settings-form',
  templateUrl: './general-settings-form.component.html',
  styleUrls: ['./general-settings-form.component.scss'],
  animations: [fadeInOut],
})
export class ControllerGeneralSettingsFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() isQuickStart: boolean;
  @Input() isDisabled: boolean = false;

  @Input() set generalSettings(generalSettings: IGeneralSettingsView) {
    if (generalSettings) {
      this.updateFormValues(generalSettings);
    }
  }

  @Output() changed = new EventEmitter();
  // subs
  sub$ = new Subscription();
  form: FormGroup = new FormGroup(
    {
      controllerID: new FormControl(),
      language: new FormControl<ControllerLanguageEnum>(
        Number(LocalStorageService.getStorageItem(StorageItem.CurrentLanguage) || ControllerLanguageEnum.EngUS),
        [Validators.required],
      ),
      units: new FormControl<ControllerUnitEnum>(ControllerUnitEnum.Imperial, [Validators.required]),
      birdType: new FormControl(BirdTypeEnum.Broilers, [Validators.required]),
      unitsDetails: new FormControl(GlobalConstants.DefaultUnitsDetailsImperial),
    },
  );
  unitsControl: FormControl<ControllerUnitEnum> = this.form.controls['units'] as FormControl<ControllerUnitEnum>;
  initialFormValue: IGeneralSettingsView;
  menuItem: 'language' | 'units' | 'birdType' = null;

  readonly languagesList = [
    { value: ControllerLanguageEnum.EngUS, title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.EngUS) },
    {
      value: ControllerLanguageEnum.Spanish,
      title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.Spanish),
    },
    {
      value: ControllerLanguageEnum.Portuguese,
      title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.Portuguese),
    },
  ];
  readonly birdTypeList = [
    { value: BirdTypeEnum.Broilers, title: BirdTypeEnum.toTranslateKey(BirdTypeEnum.Broilers) },
    { value: BirdTypeEnum.BreedersR, title: BirdTypeEnum.toTranslateKey(BirdTypeEnum.BreedersR), disabled: true },
    { value: BirdTypeEnum.BreedersP, title: BirdTypeEnum.toTranslateKey(BirdTypeEnum.BreedersP), disabled: true },
    { value: BirdTypeEnum.AllInAll, title: BirdTypeEnum.toTranslateKey(BirdTypeEnum.AllInAll), disabled: true },
    { value: BirdTypeEnum.Commercial, title: BirdTypeEnum.toTranslateKey(BirdTypeEnum.Commercial), disabled: true },
  ];
  initialUnitsList = [
    { value: ControllerUnitEnum.Metric, title: ControllerUnitEnum.toTranslateKey(ControllerUnitEnum.Metric) },
    { value: ControllerUnitEnum.Imperial, title: ControllerUnitEnum.toTranslateKey(ControllerUnitEnum.Imperial) },
  ];
  unitsList = [...this.initialUnitsList];

  customUnitOption = {
    title: ControllerUnitEnum.toTranslateKey(ControllerUnitEnum.Custom),
    value: ControllerUnitEnum.Custom,
  };

  ColorsEnum = ColorsEnum;
  ControllerLanguageEnum = ControllerLanguageEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ControllerUnitEnum = ControllerUnitEnum;
  ThemeEnum = ThemeEnum;

  constructor(
    public platformService: PlatformService,
    public languageService: LanguageService,
    private dialog: MatDialog,
    private dialogsService: DialogsService,
    private store: Store,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled']?.currentValue) {
      this.menuItem = null;
    }
  }

  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged())
        .subscribe(() => {
          this.changed.emit({
            formValues: this.form.getRawValue(),
            isValid: this.form.valid,
            isChanged: this.initialFormValue && JSON.stringify(this.initialFormValue) !== JSON.stringify(this.form.value),
          });
        }),
    );
    this.sub$.add(this.form.controls['unitsDetails'].valueChanges.pipe(wasChanged()).subscribe(unitsDetails => this.unitsDetailsChange(unitsDetails)));
    this.sub$.add(this.form.controls['language'].valueChanges.pipe(wasChanged()).subscribe(language => this.languageChanged(language)));

    if (
      this.form.controls['units'].value === ControllerUnitEnum.Custom &&
      !this.unitsList.some(unit => unit.value === ControllerUnitEnum.Custom)
    ) {
      this.unitsList = this.platformService.isMobileVersion
        ? [this.customUnitOption]
        : [...this.initialUnitsList, this.customUnitOption];
    }

    this.form.controls['units'].valueChanges.pipe(wasChanged()).subscribe(i => {
      if (i === ControllerUnitEnum.Imperial) {
        this.form.controls['unitsDetails']?.setValue(GlobalConstants.DefaultUnitsDetailsImperial, { emitEvent: false });
        this.unitsList = [...this.initialUnitsList];
      } else if (i === ControllerUnitEnum.Metric) {
        this.form.controls['unitsDetails']?.setValue(GlobalConstants.DefaultUnitsDetailsMetric, { emitEvent: false });
        this.unitsList = [...this.initialUnitsList];
      }
    });
  }

  menuClicked(menuItemIndex: typeof this.menuItem): void {
    this.menuItem = menuItemIndex;
    if (
      this.menuItem !== 'units' &&
      this.unitsList.some(unit => unit.value === ControllerUnitEnum.Custom)
      && !(this.form.controls['units'].value === ControllerUnitEnum.Custom)
    ) {
      this.unitsList = [...this.initialUnitsList];
    }
  }

  languageChanged(language: ControllerLanguageEnum): void {
    this.form.controls['language'].setValue(language);
    this.store.dispatch(setControllerLanguage({ controllerLanguage: language }));

    // imperial is default for EN-US
    const units = language === ControllerLanguageEnum.EngUS
      ? ControllerUnitEnum.Imperial
      : ControllerUnitEnum.Metric;
    this.form.controls['units'].setValue(units);

    if (units === ControllerUnitEnum.Imperial) {
      this.form.controls['unitsDetails']?.setValue(GlobalConstants.DefaultUnitsDetailsImperial);
    } else if (units === ControllerUnitEnum.Metric) {
      this.form.controls['unitsDetails']?.setValue(GlobalConstants.DefaultUnitsDetailsMetric);
    }
  }

  unitsDetailsChange(unitsDetails): void {
    if (unitsDetails == null) return;

    if (JSON.stringify(unitsDetails) === JSON.stringify(GlobalConstants.DefaultUnitsDetailsImperial)) {
      this.form.controls['units'].setValue(ControllerUnitEnum.Imperial, { emitEvent: false });
      this.unitsList = [...this.initialUnitsList];
    } else if (JSON.stringify(unitsDetails) === JSON.stringify(GlobalConstants.DefaultUnitsDetailsMetric)) {
      this.form.controls['units'].setValue(ControllerUnitEnum.Metric, { emitEvent: false });
      this.unitsList = [...this.initialUnitsList];
    } else {
      this.form.controls['units'].setValue(ControllerUnitEnum.Custom, { emitEvent: false });
      if (!this.unitsList.some(unit => unit.value === ControllerUnitEnum.Custom)) {
        this.unitsList = this.platformService.isMobileVersion
          ? [this.customUnitOption]
          : [...this.initialUnitsList, this.customUnitOption];
      }
    }
  }

  updateFormValues(generalSettings: IGeneralSettingsView): void {
    if (JSON.stringify(generalSettings) !== JSON.stringify(this.form.value)) {
      this.form.patchValue(generalSettings);
      if (this.form.controls['units'].value === ControllerUnitEnum.Imperial) {
        this.form.controls['unitsDetails'].setValue(GlobalConstants.DefaultUnitsDetailsImperial, { emitEvent: false });
      } else if (this.form.controls['units'].value === ControllerUnitEnum.Metric) {
        this.form.controls['unitsDetails'].setValue(GlobalConstants.DefaultUnitsDetailsMetric, { emitEvent: false });
      }
      this.initialFormValue = this.form.getRawValue();
      this.store.dispatch(setIsFormEditing({ isEditing: false }));
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  protected readonly queueMicrotask = queueMicrotask;
}
