import * as moment from 'moment';
import { AmPmEnum } from '../../controllers/src/lib/enums/date-time/am-pm.enum';

export class TimeUtils {
  static BackendValueToMoment(val: number | string): moment.Moment {
    if (!val) return moment();
    const time = `${val}`.padStart(4, '0');
    const date = moment(time, 'HHmm');
    return date;
  }

  static MomentToBackendValue(val: number | moment.Moment): number {
    return Number(moment(val).format('HHmm'));
  }

  // 10:11PM => 22:11
  static AMPMTimeTo24hFormat(time: string, AMPM: AmPmEnum): string {
    let hours = time.slice(0, 2);
    const minutes = time.slice(-2);

    if (AMPM === AmPmEnum.AM && hours === '12') hours = '00';

    if (AMPM === AmPmEnum.PM && +hours < 12) {
      hours = `${+hours + 12}`;
    }

    return `${hours}:${minutes}`;
  }

  // 22:11 => 10:11PM
  static TwentyFourHoursFormatToAMPMTime(time: string, AMPM: AmPmEnum): string {
    let hours = time.slice(0, 2);
    const minutes = time.slice(-2);

    if (AMPM === AmPmEnum.AM && hours === '00') hours = '12';

    if (AMPM === AmPmEnum.PM && +hours >= 13) {
      hours = `0${+hours - 12}`.slice(-2);
    }

    return `${hours}:${minutes}`;
  }

  // mm.dd.yyyy => dd.mm.yyyy
  static usaDateFormatToUsualDateFormat(date: string): string {
    const month = date.slice(0, 2);
    const day = date.slice(3, 5);
    const year = date.slice(6);

    return `${day}.${month}.${year}`;
  }

  // dd.mm.yyyy => mm.dd.yyyy
  static usualDateFormatToUSADateFormat(date: string): string {
    const month = date.slice(3, 5);
    const day = date.slice(0, 2);
    const year = date.slice(6);

    return `${month}.${day}.${year}`;
  }
}
