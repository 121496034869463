import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  IconsEnum,
  ButtonTypeEnum,
  ColorsEnum,
  OffOnEnum,
} from '@livestock/shared/enums';
import {
  DualToggleWithLabelComponent,
  LVInputComponent,
  LVInputDecimalWithLabelComponent,
  SvgIconComponent,
  ThemeEnum,
  SimpleDropdownComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, Validators, ControlContainer, FormGroup, FormControl } from '@angular/forms';
import { BasicElementComponent, IGetOrUpdateElement, InstallationConstants } from '@livestock/installation';
import { ElementEventInfo } from '@livestock/installation/interfaces';
import { EnumPipe } from '@livestock/shared/pipes';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    EnumPipe,
    SvgIconComponent,
    LVInputComponent,
    DualToggleWithLabelComponent,
    LVInputDecimalWithLabelComponent,
    SimpleDropdownComponent,
  ],
  selector: 'lv-auxiliary-input-setup',
  templateUrl: './auxiliary-input.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class AuxiliaryInputSetupComponent extends BasicElementComponent implements OnInit {
  @Input() elementEvent: ElementEventInfo;
  @Output() delete: EventEmitter<void> = new EventEmitter();

  // enums
  OffOnEnum = OffOnEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ThemeEnum = ThemeEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  ColorsEnum = ColorsEnum;
  InstallationConstants = InstallationConstants;

  override ngOnInit(): void {
    const disabled = !this.isEditMode;
    this.parentFormGroup.addControl(this.formGroupName,
      new FormGroup({
        elementID: new FormControl<number>({ value: null, disabled }),
        number: new FormControl<number>({ value: 1, disabled }),
        sensorName: new FormControl<string>({ value: 'Auxiliary Sensor - 01', disabled }, [Validators.required]),
        sensorOn: new FormControl<boolean>({ value: true, disabled }),
        operation: new FormControl<boolean>({ value: InstallationConstants.OperationNormallyOpen, disabled }),
      }));
    super.ngOnInit();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      elementID: setupData.elementID,
      sensorName: setupData.sensorName,
      sensorOn: setupData.sensorOn,
      operation: setupData.operation,
    });
    this.parentFormGroup.updateValueAndValidity();
  }

  uninstall(): void {
    this.delete.emit();
  }
}
