import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  ControllerDateTimeSettingsFormComponent,
} from '../../ui/date-time-settings-form/date-time-settings-form.component';
import { Store } from '@ngrx/store';
import { GlobalConstants, TimeZonesEnum } from '@livestock/shared/constants';
import { ButtonTypeEnum, ColorsEnum, IconsEnum, PageTypeEnum } from '@livestock/shared/enums';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { EnumPipe, StyleTextPartPipe } from '@livestock/shared/pipes';
import {
  DualToggleComponent,
  LoadingGalconComponent,
  LvInputDateComponent,
  LvInputTimeComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AutoDateTimeTypeEnum,
  ControllerLanguageEnum,
  HoursFormatTypeEnum,
} from '@livestock/controllers/enums';
import {
  selectControllerLanguage,
  selectCurrentControllerDateTimeSettings,
} from '../../+state/current-controller/current-controller.selectors';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';
import { IDateTimeSettingsView } from '../../interfaces/date-time/date-time-settings-view.interface';
import { PlatformService } from '@livestock/shared/services';
import {
  getControllerDateTimeSettings,
  updateControllerDateTimeSettings,
  updateControllerDateTimeSettingsSuccess,
} from '../../+state/current-controller/current-controller.actions';
import { INameId } from '../../../../../shared/interfaces/id-name.interface';
import { ControllerGeneralSettingsFormComponent } from '../../ui/general-settings-form/general-settings-form.component';
import { SettingsWrapperComponent } from '../settings-wrapper/settings-wrapper.component';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsService, setIsFormEditing } from '@livestock/forms';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ControllerDateTimeSettingsFormComponent,
    MatAutocompleteModule,
    MatOptionModule,
    ReactiveFormsModule,
    StyleTextPartPipe,
    SvgIconComponent,
    DualToggleComponent,
    EnumPipe,
    LvInputDateComponent,
    LvInputTimeComponent,
    LoadingGalconComponent,
    ControllerGeneralSettingsFormComponent,
    SettingsWrapperComponent,
    QaTagsDirective,
  ],
  selector: 'lv-date-time-settings',
  templateUrl: './date-time-settings.component.html',
  styleUrls: ['./date-time-settings.component.scss'],
})
export class DateTimeSettingsComponent implements OnInit {
  platformService: PlatformService = inject(PlatformService);
  private store: Store = inject(Store);
  private actions$: Actions = inject(Actions);
  private formsService: FormsService = inject(FormsService);
  private destroyRef: DestroyRef = inject(DestroyRef);

  filteredTimeZones: Observable<INameId[]>;
  language$: Observable<ControllerLanguageEnum> = this.store.select(selectControllerLanguage);

  // vars
  form = new FormGroup({
    controllerID: new FormControl<number>(null),
    autoDateTimeType: new FormControl(AutoDateTimeTypeEnum.ByNetwork),
    hoursFormat: new FormControl(HoursFormatTypeEnum.AmPm),
    date: new FormControl<string>('', Validators.required),
    time: new FormControl<string>('', Validators.required),
    timeZoneID: new FormControl<{ id: TimeZonesEnum, name: string }>(null, Validators.required),
  });
  timeZonePlaceholder = 'LoginScanned.Timezone';
  timeZoneInputInFocus: boolean;
  timeZones = TimeZonesEnum.toList();
  toHighlight: string;
  isDisabled: boolean;
  dateTimeSettings: IDateTimeSettingsView;
  isEditMode: boolean = false;
  AutoDateTimeTypeEnum = AutoDateTimeTypeEnum;
  isSuccess: boolean = false;
  isChanged: boolean = false;
  initialTimeZoneID: number;

  // enums
  TimeZonesEnum = TimeZonesEnum;
  ColorsEnum = ColorsEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  HoursFormatTypeEnum = HoursFormatTypeEnum;
  ControllerLanguageEnum = ControllerLanguageEnum;
  GlobalConstants = GlobalConstants;
  PageTypeEnum = PageTypeEnum;
  ButtonTypeEnum = ButtonTypeEnum;

  constructor() {
    this.actions$.pipe(ofType(updateControllerDateTimeSettingsSuccess), takeUntilDestroyed()).subscribe(() => {
      this.isSuccess = true;
      this.isChanged = false;
      if (!this.platformService.isDeviceApp) {
        this.initialTimeZoneID = this.form.value.timeZoneID.id;
      }

      setTimeout(() => {
        this.isSuccess = false;
        this.isEditMode = false;
        this.store.dispatch(setIsFormEditing({ isEditing: false }));
      }, 2000);
    });
  }

  ngOnInit(): void {
    this.store.dispatch(getControllerDateTimeSettings());

    this.filteredTimeZones = this.form.controls.timeZoneID.valueChanges.pipe(
      startWith(''),
      map(value => this.filterTimezones(value || '')),
      takeUntilDestroyed(this.destroyRef),
    );

    this.store.select(selectCurrentControllerDateTimeSettings).pipe(
      wasChangedAndNotNull(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((dateTimeSettings) => {
      this.dateTimeSettings = dateTimeSettings;

      if (!this.platformService.isDeviceApp && !this.initialTimeZoneID) {
        this.initialTimeZoneID = dateTimeSettings.timeZoneID;
      }

      if (dateTimeSettings.autoDateTimeType === AutoDateTimeTypeEnum.ByNetwork) {
        this.form.controls.date.disable();
        this.form.controls.time.disable();
      }

      if (this.platformService.isDeviceApp) {
        this.form.controls.timeZoneID.disable();
      }

      this.form.patchValue({
        date: dateTimeSettings.date,
        time: dateTimeSettings.time,
        autoDateTimeType: dateTimeSettings.autoDateTimeType ?? AutoDateTimeTypeEnum.Manually,
        timeZoneID: this.platformService.isDeviceApp
          ? null
          : {
            id: dateTimeSettings.timeZoneID,
            name: TimeZonesEnum.toTranslateKey(dateTimeSettings.timeZoneID),
          },
        controllerID: dateTimeSettings.controllerID,
        hoursFormat: dateTimeSettings.hoursFormat,
      });
    });

    this.form.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((formValues) => {
      this.isChanged = this.initialTimeZoneID !== formValues.timeZoneID?.id
        || this.dateTimeSettings.hoursFormat !== formValues.hoursFormat;

      this.store.dispatch(setIsFormEditing({ isEditing: this.isChanged }));
    });
  }

  private resetFormEditing(): void {
    this.store.dispatch(setIsFormEditing({ isEditing: false }));
    this.form.controls.timeZoneID.setValue({
      ...this.form.value.timeZoneID,
      id: this.initialTimeZoneID,
      name: TimeZonesEnum.toTranslateKey(this.initialTimeZoneID),
    });
    this.form.controls.hoursFormat.setValue(this.dateTimeSettings.hoursFormat);
    this.form.updateValueAndValidity();
  }

  onReload(): void {
    this.resetFormEditing();
    this.store.dispatch(getControllerDateTimeSettings());
    this.isEditMode = false;
  }

  toggleDisabled(isForceToggle?: boolean): void {
    if (isForceToggle) {
      this.isEditMode = !this.isEditMode;
      this.resetFormEditing();
    } else if (this.isEditMode && this.isChanged) {
      this.formsService.confirmFormLeaving()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(confirm => {
          if (confirm) {
            this.isEditMode = false;
            this.resetFormEditing();
          }
        });
    } else {
      this.isEditMode = !this.isEditMode;
    }
  }

  displayTimeZoneFn(timezone: INameId): string {
    return timezone?.name || '';
  }

  onFocusOutTimeZoneInput(): void {
    this.timeZoneInputInFocus = false;
    this.timeZonePlaceholder = 'LoginScanned.Timezone';
  }

  onFocusInTimeZoneInput(): void {
    this.timeZoneInputInFocus = true;
    this.timeZonePlaceholder = '';
  }

  saveSettings(): void {
    this.store.dispatch(updateControllerDateTimeSettings({
      dateTimeSettings: {
        ...this.dateTimeSettings,
        hoursFormat: this.form.value.hoursFormat,
        timeZoneID: this.form.value.timeZoneID?.id,
      },
    }));
  }

  private filterTimezones(value: string | INameId): INameId[] {
    const filterValue = typeof value === 'string'
      ? this.normalizeValue(value)
      : this.normalizeValue(value.name);
    this.toHighlight = filterValue;
    return this.timeZones.filter(zone => this.normalizeValue(zone.name).includes(filterValue));
  }

  private normalizeValue(value: string): string {
    return value.toLowerCase().trim();
  }
}
