<ng-container *ngIf="!(isLoading$ | async); else loading">
  <div
    *ngIf="platformService.isMobileVersion"
    [class.mb-56]="currStep === AddControllerStepsEnum.SelectTimeZones"
    [class.mb-28]="platformService.isMobileApp && currStep === AddControllerStepsEnum.EnterConnectionNumber"
    class="d-flex align-center mt-18 align-self-baseline gap-8">
    <ls-svg-icon
      (click)="goBack()"
      [path]="IconsEnum.GO_BACK_ARROW"></ls-svg-icon>

    <div class="color-f5">
      <p class="mb-4">
        <span class="color-grey"> {{ "AddController.Farms" | translate }}</span> / {{ activeFarm?.name }}
      </p>
      <h2 class="fz-24">
        {{ "AddController.AddNewHouse" | translate }}
      </h2>
    </div>
  </div>
  <div
    [class.add-controller-wrapper__timezone]="currStep === AddControllerStepsEnum.SelectTimeZones"
    class="add-controller-wrapper">
    <ng-container *ngIf="currStep === AddControllerStepsEnum.EnterConnectionNumber">
      <ng-container *ngIf="platformService.isMobileApp">
        <svg
          width="0"
          height="0">
          <mask
            id="mask"
            width="100%"
            height="100%">
            <!-- FOR QR-CODE IMAGE-MASK in libs/layout/src/lib/layout-mobile/layout-mobile.component.scss .mobile-qrcode-mask -->
            <rect
              width="250"
              height="250"
              x="100"
              y="180"
              rx="20"
              ry="20"
              fill="#fff" />
          </mask>
        </svg>
        <span
          *ngIf="isQRCodeScannerOpen"
          class="d-block mt-18 fz-14 text-center"
          >{{ "AddController.ScanQRCodeProvidedInController" | translate }}</span
        >
        <ls-button
          *ngIf="!isQRCodeScannerOpen"
          (click)="openQRCodeScanner()"
          [qaTags]="'qa-btn-scan-code'"
          class="mt-28 h-48 btn-scan"
          type="primary">
          {{ "AddController.StartQRCodeScan" | translate }}
        </ls-button>
      </ng-container>
      <h1
        [class.color-main-brightred]="!!getControllerTicketError"
        [ngClass]="platformService.isMobileApp ? 'mt-18 mb-18 text-center' : 'mb-28'"
        class="fz-24">
        <ng-container *ngIf="!getControllerTicketError; else connectionNumberErrorTemplate">
          <ng-container *ngIf="!platformService.isMobileApp; else mobileTitleTemplate">
            {{ "AddController.AddControllerTo" | translate }} <br />
            {{ activeFarm?.name }}
          </ng-container>
          <ng-template #mobileTitleTemplate>{{ "AddController.Or" | translate | lowercase }}</ng-template>
        </ng-container>
        <ng-template #connectionNumberErrorTemplate>
          {{ "AddController.SomethingWentWrong" | translate }}
        </ng-template>
      </h1>
      <lv-verification-code
        (click)="stopScan()"
        (submitForm)="confirmCode(connectionNumber, platformService.isMobileApp)"
        (ngModelChange)="getControllerTicketError = null"
        [focusedByDefault]="!platformService.isMobileApp"
        [submitFormOnLastDigitChange]="platformService.isMobileApp"
        [qaTags]="'qa-txt-connection-number'"
        [(ngModel)]="connectionNumber"
        class="mb-8">
      </lv-verification-code>
      <span class="fz-14 color-f5">
        {{ "AddController.Enter4DigitNumber" | translate }}
      </span>
      <ls-button
        *ngIf="!platformService.isMobileApp"
        (click)="confirmCode(connectionNumber, false)"
        [class.disabled]="connectionNumber?.length < GlobalConstants.CONNECTION_NUMBER_LENGTH"
        [qaTags]="'qa-btn-confirm-code'"
        class="mt-28 h-48"
        type="primary">
        {{ "AddController.ConfirmCode" | translate }}
      </ls-button>
    </ng-container>

    <ng-container *ngIf="currStep === AddControllerStepsEnum.SelectTimeZones">
      <ng-container *ngIf="controllerTicket$ | async as controller">
        <div
          *ngIf="activeFarm?.existingHouseNumbers?.includes(controller.houseNumber); else successHouseNumberTemplate">
          <ls-svg-icon [path]="IconsEnum.SAD_SMILE"> </ls-svg-icon>
          <div class="mt-16 fz-32 line-height-118 color-mono-bg">
            {{ "AddController.House" | translate }}
            <span class="color-brightorange">{{ controller.houseNumber }}</span>
            {{ "AddController.WrongHouseNumberMessage" | translate : { farmName: activeFarm?.name } }}
          </div>
          <div class="fz-18 mt-28">{{ "AddController.ChooseAnotherNumber" | translate }}</div>
          <div class="d-flex mt-8">
            <div
              [class.field-invalid]="
                [houseNumber, activeFarm?.existingHouseNumbers] | memoizeFunc : isInvalidHouseNumber
              "
              class="field">
              <ls-svg-icon
                [color]="
                  [houseNumber, houseNumberInputInFocus, activeFarm?.existingHouseNumbers]
                    | memoizeFunc : getControllerNumberIconColor
                "
                [path]="IconsEnum.CONTROLLER_NUMBER"></ls-svg-icon>

              <ls-input-integer
                (onFocusIn)="onFocusInHouseNumberInput()"
                (onFocusOut)="onFocusOutHouseNumberInput()"
                [placeholder]="houseNumberPlaceholder | translate"
                [class.ng-invalid]="
                  [houseNumber, activeFarm?.existingHouseNumbers] | memoizeFunc : isInvalidHouseNumber
                "
                [min]="GlobalConstants.MIN_HOUSE_NUMBER"
                [max]="GlobalConstants.MAX_HOUSE_NUMBER"
                [(ngModel)]="houseNumber"
                class="field-number">
              </ls-input-integer>
            </div>
            <ls-button
              (click)="saveNewHouseNumber(houseNumber)"
              [class.disabled]="[houseNumber, activeFarm?.existingHouseNumbers] | memoizeFunc : isInvalidHouseNumber"
              class="save-button ml-18 h-56">
              {{ "AddController.Save" | translate }}
            </ls-button>
          </div>
          <div class="fz-14 color-red mt-8">
            <span *ngIf="houseNumber != null && activeFarm?.existingHouseNumbers?.includes(houseNumber)">
              {{ "AddController.HouseNumberWasAlreadyTakenWithValue" | translate : { houseNumber: houseNumber } }}
            </span>
            <span *ngIf="houseNumber != null && houseNumber > GlobalConstants.MAX_HOUSE_NUMBER">
              {{ "AddController.MaximumNumberIs999" | translate }}
            </span>
            <span *ngIf="houseNumber == null">
              {{ "AddController.EnterHouseNumber" | translate }}
            </span>
          </div>
        </div>

        <ng-template #successHouseNumberTemplate>
          <ls-svg-icon [path]="IconsEnum.FUNNY_SMILE"> </ls-svg-icon>
          <div class="mt-16 fz-32 line-height-118 color-mono-bg">
            {{ "AddController.House" | translate }} <span class="color-brightgreen">{{ controller.houseNumber }}</span>
            {{ "AddController.SuccessHouseNumberMessage" | translate : { farmName: activeFarm?.name } }}
          </div>
          <ng-container [ngTemplateOutlet]="selectTimeZoneTemplate"></ng-container>
          <ls-button
            *ngIf="timeZoneControl?.value?.id"
            (click)="assign(activeFarm, controller, timeZoneControl.value.id)"
            class="mt-18 h-48 d-flex justify-center">
            {{ "AddController.AddDevice" | translate }}
            <ls-svg-icon
              [path]="IconsEnum.CONTROLLER_ADD"
              [width]="'22px'"
              [height]="'18px'"
              class="d-flex align-center mh-10" />
          </ls-button>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #selectTimeZoneTemplate>
  <div class="fz-18 mt-28">{{ "AddController.ChooseControllersTimezone" | translate }}</div>
  <div class="field mt-8">
    <ls-svg-icon
      [color]="timeZoneInputInFocus ? ColorsEnum.MainDarkBlue : ColorsEnum.MonoDark"
      [path]="IconsEnum.TIMEZONE">
    </ls-svg-icon>

    <input
      (focusin)="onFocusInTimeZoneInput()"
      (focusout)="onFocusOutTimeZoneInput()"
      #timeZoneInput
      [placeholder]="timeZonePlaceholder | translate"
      [class.color-darkblue]="!timeZoneInputInFocus"
      [formControl]="timeZoneControl"
      [matAutocomplete]="auto"
      type="text"
      class="field-timezone" />
  </div>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayTimeZoneFn">
    <mat-option
      *ngFor="let timezone of filteredTimeZones | async"
      [value]="timezone">
      <span
        [innerHTML]="TimeZonesEnum.toTranslateKey(timezone.id) | styleTextPart : toHighlight : 'highlighted'"></span>
    </mat-option>
  </mat-autocomplete>
</ng-template>

<ng-template #loading>
  <div class="h-100-percent d-flex align-center justify-center">
    <lv-loading-galcon></lv-loading-galcon>
  </div>
</ng-template>
