import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { CloudConnectionEnum } from '@livestock/controllers/enums';
import {
  IAllStatuses,
  IInternetConnectionStatus,
  ILanSettings,
  IModemCredentials,
  IWifiConnection,
  IWifiConnectionList,
} from '@livestock/controllers/interfaces';
import { baseUrl, deviceControllerUrl, deviceUrl } from './basic-api-url.constants';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private http: HttpClient = inject(HttpClient);

  getLanSettings(controllerID: number): Observable<ILanSettings> {
    return this.http.get<{
      isActive: boolean;
      ip: string;
      mac: string;
    }>(`${baseUrl}/${controllerID}/settings/network/ethernet`).pipe(map(settings => {
      return {
        lan: Number(settings.isActive),
        lanIp: settings.ip,
        lanMac: settings.mac,
      };
    }));
  }

  updateLanSettings(controllerID: number, isActive: boolean): Observable<ILanSettings> {
    return this.http.put<{
      isActive: boolean;
      ip: string;
      mac: string;
    }>(`${deviceUrl(controllerID)}/network/ethernet`, {
      isActive,
    }).pipe(map(settings => {
      return {
        lan: Number(settings.isActive),
        lanIp: settings.ip,
        lanMac: settings.mac,
      };
    }));
  }

  getNetworkStatus(controllerID: number): Observable<IAllStatuses> {
    return this.http.get<IAllStatuses>(`${deviceUrl(controllerID)}/network/status`);
  }

  getWifiList(controllerID: number): Observable<IWifiConnectionList> {
    return this.http.get<IWifiConnectionList>(`${deviceUrl(controllerID)}/network/wifi-list`);
  }

  connectWifi(controllerID: number, selectedWifi: IWifiConnection): Observable<IInternetConnectionStatus> {
    selectedWifi = { ...selectedWifi, password: selectedWifi?.password || '' };
    const { signal: _signal, isProtected: _isProtected, ...wifi } = selectedWifi;
    return this.http.put<any>(`${deviceUrl(controllerID)}/network/wifi/connect`, wifi);
  }

  disconnectWifi(controllerID: number): Observable<void> {
    return this.http.put<any>(`${deviceUrl(controllerID)}/network/wifi/disconnect`, {});
  }

  connectModem(controllerID: number, modemData: IModemCredentials): Observable<IInternetConnectionStatus> {
    modemData = {
      ...modemData,
      modemPassword: modemData?.modemPassword || '',
      modemUser: modemData?.modemUser || '',
    };
    return this.http.put<any>(`${deviceUrl(controllerID)}/network/cellular/connect`, modemData);
  }

  disconnectModem(controllerID: number): Observable<void> {
    return this.http.put<any>(`${deviceUrl(controllerID)}/network/cellular/disconnect`, {});
  }

  approveCloudConnection(controllerID: number): Observable<void> {
    return this.http.put<any>(`${deviceControllerUrl(controllerID)}/cloud-connection`, { connection: CloudConnectionEnum.Accept });
  }

  rejectCloudConnection(controllerID: number): Observable<void> {
    return this.http.put<any>(`${deviceControllerUrl(controllerID)}/cloud-connection`, { connection: CloudConnectionEnum.Reject });
  }

  disconnectCloudConnection(controllerID: number): Observable<void> {
    return this.http.put<any>(`${deviceControllerUrl(controllerID)}/cloud-connection`, { connection: CloudConnectionEnum.Disconnect });
  }
}
