import { AbstractControl, ValidationErrors } from '@angular/forms';

export const PasswordValidator = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (!value) {
    return null;
  }
  const passwordValid = /^.*(?=.{8,})(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@\[\]^_`{|}~])([a-zA-Z0-9!"#$%&'()*+,\-.\/:;<=>?@\[\]^_`{|}~]+)$/.test(value);

  return passwordValid ? null : { insecurePassword: true };
};
