import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingGalconComponent, SvgIconComponent } from '@livestock/ui';
import {
  ButtonTypeEnum,
  ColorsEnum,
  ElementTypesEnum,
  IconsEnum,
  InstallationElementGroupsEnum,
  PageTypeEnum,
} from '@livestock/shared/enums';
import { EnumPipe, EnumToArrayPipe } from '@livestock/shared/pipes';
import { getCards, selectSettingsAreLoading, SettingsWrapperComponent } from '@livestock/controllers';
import { ElementsListComponent } from './elements-list/elements-list.component';
import { Store } from '@ngrx/store';
import {
  createElementSetup,
  deleteElementSetupSuccess,
  getElementSetup,
  runElementEvents,
  setActiveElementType,
} from '../+state/installation.actions';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ElementWrapperComponent,
  IElement,
  selectElementsByGroup,
  selectIfAnyElementConnected,
} from '@livestock/installation';
import { FormsService, setIsFormEditing } from '@livestock/forms';
import { Observable } from 'rxjs';
import { PlatformService, RealTimeEventsRecallService } from '@livestock/shared/services';
import { IConfirmDialogData } from '@livestock/shared/interfaces';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LoadingGalconComponent,
    SvgIconComponent,
    EnumToArrayPipe,
    EnumPipe,
    SettingsWrapperComponent,
    ElementsListComponent,
    ElementWrapperComponent,
  ],
  selector: 'lv-installation',
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.scss'],
})
export class InstallationComponent implements OnInit, OnDestroy {
  platformService: PlatformService = inject(PlatformService);
  recallService: RealTimeEventsRecallService = inject(RealTimeEventsRecallService);
  private store: Store = inject(Store);
  private actions$: Actions = inject(Actions);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private formsService: FormsService = inject(FormsService);

  // subs
  cardsAreLoading$: Observable<boolean> = this.store.select(selectSettingsAreLoading);
  getElements$: Observable<(elementGroup: InstallationElementGroupsEnum) => IElement[]> = this.store.select(selectElementsByGroup);
  isAnyElementConnected$: Observable<(elementType: ElementTypesEnum) => boolean> = this.store.select(selectIfAnyElementConnected);

  // vars
  activeElementType: ElementTypesEnum;
  activeElementIndex: number;
  selectedElementGroup: InstallationElementGroupsEnum | null;
  settingsMode: boolean;
  isEditMode: boolean;
  isChanged: boolean;
  isLastElementSelected: boolean;

  // enums
  PageTypeEnum = PageTypeEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  InstallationElementGroupsEnum = InstallationElementGroupsEnum;
  ElementTypesEnum = ElementTypesEnum;
  ColorsEnum = ColorsEnum;

  // constants
  confirmDialogData: Partial<IConfirmDialogData> = {
    confirmLabelKey: 'Buttons.LeaveAnyway',
    confirmButtonType: ButtonTypeEnum.SECONDARY,
    cancelLabelKey: 'Buttons.StayOnPage',
    cancelButtonType: ButtonTypeEnum.PRIMARY,
    reverseOrder: true,
    panelClass: [],
    backdropClass: [],
  };

  ngOnInit(): void {
    //mockup
    this.store.dispatch(getCards());

    this.actions$.pipe(
      ofType(deleteElementSetupSuccess),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.settingsMode = false;
    });
  }

  selectElementGroup(elementGroup: InstallationElementGroupsEnum): void {
    this.activeElementType = null;
    this.store.dispatch(setActiveElementType({ elementType: null }));

    if (this.selectedElementGroup === elementGroup) {
      this.selectedElementGroup = null;
      return;
    }

    this.selectedElementGroup = elementGroup;
  }

  setActiveElement({ activeElementType, activeElementIndex, isLastElementSelected }): void {
    this.activeElementType = activeElementType;
    this.activeElementIndex = activeElementIndex;
    this.isLastElementSelected = isLastElementSelected;
    this.isChanged = false;
    this.store.dispatch(setActiveElementType({ elementType: activeElementType }));
  }

  toggleSettingsMode(): void {
    if (this.settingsMode && this.isChanged) {
      this.formsService.confirmFormLeaving(this.confirmDialogData)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(confirm => {
          if (confirm) {
            this.resetFormEditing();
            this.settingsMode = !this.settingsMode;
          }
        });
      return;
    }

    if (!this.settingsMode) {
      this.store.dispatch(getElementSetup());
      this.store.dispatch(runElementEvents({ numbers: [1] }));
      this.recallService.setAdditionalAction(runElementEvents({ numbers: [1] }));
    }

    this.settingsMode = !this.settingsMode;
  }

  addElementToConnection(): void {
    if (!this.activeElementType) return;

    this.store.dispatch(createElementSetup({
      elementNumber: 1,
      elementType: this.activeElementType,
      connectionNumber: ElementTypesEnum.getConnectionNumber(this.activeElementType),
      cardNumber: ElementTypesEnum.getCardNumber(this.activeElementType),
    }));
  }

  isFormChanged(isChanged: boolean): void {
    this.isChanged = isChanged;
  }

  toggleDisabled(isForceToggle?: boolean): void {
    if (isForceToggle) {
      this.isEditMode = !this.isEditMode;
      this.resetFormEditing();
    } else if (this.isEditMode && this.isChanged) {
      this.formsService.confirmFormLeaving(this.confirmDialogData)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(confirm => {
          if (confirm) {
            this.isEditMode = false;
            this.resetFormEditing();
          }
        });
    } else {
      this.isEditMode = !this.isEditMode;
    }
  }

  private resetFormEditing(): void {
    this.store.dispatch(setIsFormEditing({ isEditing: false }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(setActiveElementType({ elementType: null }));
    this.recallService.clearAdditionalAction();
  }
}
