<ng-container *ngIf="!flowIsFinished; else finishedFlowMessage">
  <ng-container *ngIf="currentStep$ | async as currStep">
    <div class="container pt-0">
      <div class="steps justify-center d-flex flex-column gap-40">
        <ng-container *ngFor="let step of steps">
          <div
            [qaTags]="'qa-menu-qsStep' + step.tab"
            class="position-relative wizard-btn">
            <lv-button-wizard
              (click)="onStepClick(step.tab)"
              [iconPath]="step.icon"
              [type]="step.type"
              class="position-relative" />

            <div
              *ngIf="step.tab !== QuickStartStepsEnum.DateTimeSettings"
              [ngClass]="{
                blue: steps[step.tab]?.couldBeVisited && steps[step.tab]?.type !== ButtonWizardTypeEnum.OFF,
                error: steps[step.tab]?.type === ButtonWizardTypeEnum.ERROR
              }"
              class="line"></div>
          </div>
        </ng-container>
      </div>

      <div class="content-container d-flex flex-column pl-48 pr-28 h-100-percent pt-56">
        <div class="title-container mb-56">
          <h3
            [qaTags]="'qa-lbl-stepTitle'"
            class="fz-24 fw-600">
            {{ currStep | memoizeFunc : getStepTitle.bind(this) | translate }}
          </h3>

          <lv-current-network-status
            *ngIf="currStep === QuickStartStepsEnum.NetworkSettings"
            (isOnline)="isOnline = $event"
            [isLoading]="isNetworkLoading$ | async"></lv-current-network-status>
        </div>

        <ng-container *ngIf="currStep === QuickStartStepsEnum.GeneralSettings">
          <ls-controller-general-settings-form
            (changed)="changedGeneralSettings($event)"
            [generalSettings]="generalSettings"
            [isQuickStart]="true" />
        </ng-container>

        <ng-container *ngIf="currStep === QuickStartStepsEnum.NetworkSettings">
          <ls-network-settings-form
            (changed)="changedNetworkSettings($event)"
            (onConnectToWifi)="connectWifi($event)"
            (onWifiDisconnect)="disconnectWifi()"
            (onScanWifi)="scanWifiNetworks()"
            (onConnectModem)="connectModem($event)"
            (onDisconnectModem)="disconnectModem()"
            [isLoading]="isLoading$ | async"
            [networkSettings]="networkSettings" />
        </ng-container>

        <ng-container *ngIf="currStep === QuickStartStepsEnum.DateTimeSettings">
          <lv-controller-date-time-settings-form
            (changed)="changedDateTimeSettings($event)"
            [isLoading]="isLoading$ | async"
            [dateTimeSettings]="dateTimeSettings" />
        </ng-container>

        <ng-container *ngIf="currStep === QuickStartStepsEnum.CloudSettings">
          <lv-controller-cloud-connection-form
            (onDisconnect)="disconnectCloud()"
            (getTicketQrCodeInfoFromDevice)="getTicketQrCodeInfoFromDevice()"
            [isLoading]="isLoading$ | async"
            [ticketQrCodeInfo]="ticketQrCodeInfo$ | async"
            [ticketQrCodeError]="ticketQrCodeError$ | async"
            [canDisconnect]="canDisconnectCloud"
            [cloudConnectionRequest]="cloudConnectionRequest" />
        </ng-container>

        <div
          *ngIf="!(assigningToFarmInProgress$ | async)"
          [class.actions__general-settings]="currStep === QuickStartStepsEnum.GeneralSettings"
          class="d-flex mt-30 mb-28 actions">
          <lv-button
            *ngIf="currStep > QuickStartStepsEnum.GeneralSettings"
            (click)="back()"
            [iconWizard]="ButtonIconWizardEnum.BACK"
            [class.disabled]="(connectionInProgress$ | async) || (isLoading$ | async)"
            [qaTags]="'qa-btn-back'"
            class="mr-auto w-97">
            {{ "Controller.QuickStart.Back" | translate }}
          </lv-button>

          <!--Temporary for QA needs-->
          <div class="d-flex flex-column ml-auto">
            <label>Device logic ON/OFF</label>
            <ls-toggle
              (change)="toggleDeviceLogic($event)"
              [value]="false" />
          </div>

          <lv-button
            *ngIf="currStep < QuickStartStepsEnum.DateTimeSettings"
            (click)="next()"
            [type]="
              steps[currStep - 1].type === ButtonWizardTypeEnum.ERROR ||
              (currStep === QuickStartStepsEnum.DateTimeSettings && !dateTimeSettingsValid) ||
              (currStep === QuickStartStepsEnum.NetworkSettings &&
                (networkSettings | memoizeFunc : someNetworkSettingsAreOn.bind(this)) &&
                !isOnline) ||
              (isLoading$ | async)
                ? ButtonTypeEnum.DISABLED
                : ButtonTypeEnum.PRIMARY
            "
            [class.disabled]="
              steps[currStep - 1].type === ButtonWizardTypeEnum.ERROR ||
              (currStep === QuickStartStepsEnum.NetworkSettings &&
                (networkSettings | memoizeFunc : someNetworkSettingsAreOn.bind(this)) &&
                !isOnline) ||
              (isLoading$ | async)
            "
            [iconWizard]="ButtonIconWizardEnum.NEXT"
            [qaTags]="'qa-btn-next'"
            class="w-110 ml-20">
            {{ "Controller.QuickStart.Next" | translate }}
          </lv-button>

          <lv-button
            *ngIf="currStep === QuickStartStepsEnum.DateTimeSettings"
            (click)="goToDashboard(true)"
            [ngClass]="{
              disabled: !dateTimeSettingsValid || anyStepInError(),
            }"
            [type]="!dateTimeSettingsValid || anyStepInError() ? ButtonTypeEnum.DISABLED : ButtonTypeEnum.PRIMARY"
            [iconWizard]="ButtonIconWizardEnum.NEXT"
            [qaTags]="'qa-btn-finish'">
            {{ "Controller.QuickStart.Finish" | translate }}
          </lv-button>
        </div>
      </div>
      <div class="footer d-flex justify-center align-center">
        <span class="mr-2">{{ "Controller.QuickStart.PoweredBy" | translate }}</span>
        <img src="/assets/images/small-logo.png" />
        <img src="/assets/images/galcon-logo.png" />
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #finishedFlowMessage>
  <div class="finished-flow text-center fz-24">
    <ls-svg-icon
      [hasMargin]="true"
      class="d-block mx-auto mb-10 w-mc"
      path="quick-start/info"></ls-svg-icon>
    <div class="mb-60">
      <p>{{ "Controller.QuickStart.TheSetupIsIncomplete" | translate }}</p>
      <p>{{ "Controller.QuickStart.YouCanSkipThisStepAndCompleteItLater" | translate }}</p>
    </div>

    <div class="d-flex justify-between align-center">
      <ls-button
        (click)="goToDashboard(false)"
        [qaTags]="'qa-btn-go-to-dashboard'"
        type="secondary">
        {{ "Controller.QuickStart.GoToDashboard" | translate }}
      </ls-button>

      <ls-button
        (click)="goToInstallation()"
        [qaTags]="'qa-btn-continue-with-installation-wrapper'"
        type="secondary">
        {{ "Controller.QuickStart.ContinueWithInstallation" | translate }}
      </ls-button>
    </div>
  </div>
</ng-template>
