import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { LanguageService } from '@livestock/shared/services';
import { SvgIconComponent } from '@livestock/ui';
import { IconsEnum } from '@livestock/shared/enums';

@Component({
  selector: 'lv-toggle-new',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonToggleModule, SvgIconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleNewComponent),
      multi: true,
    },
  ],
})
export class ToggleNewComponent implements ControlValueAccessor {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() disabled: boolean = false;
  @Input() onTitle: string = 'On';
  @Input() offTitle: string = 'Off';

  @Input() set value(isChecked: boolean) {
    if (!this.disabled) {
      this.isChecked = isChecked;
      this.onChange(isChecked);
    }
  }

  @Output() change = new EventEmitter<boolean>();

  isChecked: boolean;

  IconsEnum = IconsEnum;

  constructor(public languageService: LanguageService) {
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleChecked(value?: boolean): void {
    if (!this.disabled) {
      if (value != null) {
        this.isChecked = value;
      } else {
        this.isChecked = !this.isChecked;
      }
      this.onChange(this.isChecked);
      this.change.emit(this.isChecked);
    }
  }
}

