import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageTypeEnum } from '@livestock/shared/enums';
import { Store } from '@ngrx/store';
import {
  CloudConnectionForm,
  disconnectCloud,
  getTicketQrCodeInfoFromDevice,
  ICloudConnectionRequest,
  selectCloudConnectionRequest,
  selectIsLoading,
  selectTicketQrCodeError,
  selectTicketQrCodeInfo,
} from '@livestock/controllers';
import { Observable, Subscription } from 'rxjs';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';
import { SettingsHeaderComponent } from '../settings-header/settings-header.component';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, CloudConnectionForm, SettingsHeaderComponent],
  selector: 'lv-cloud-settings',
  templateUrl: './cloud-settings.component.html',
  styleUrls: ['./cloud-settings.component.scss'],
})
export class CloudSettingsComponent implements OnInit, OnDestroy {

  private store: Store = inject(Store);

  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  ticketQrCodeInfo$ = this.store.select(selectTicketQrCodeInfo);
  ticketQrCodeError$ = this.store.select(selectTicketQrCodeError);
  cloudConnectionRequest$: Observable<ICloudConnectionRequest> = this.store.select(selectCloudConnectionRequest).pipe(wasChangedAndNotNull());

  canDisconnect: boolean = false;

  PageTypeEnum = PageTypeEnum;

  ngOnInit(): void {
    this.store.dispatch(getTicketQrCodeInfoFromDevice());
    this.sub$.add(
      this.cloudConnectionRequest$.subscribe((value) => {
        const timePassedFromConnection = value?.connectedTime ? new Date().getTime() - value.connectedTime.getTime() : null;
        if (timePassedFromConnection === null || timePassedFromConnection >= 15000) {
          this.canDisconnect = true;
        } else {
          this.canDisconnect = false;

          setTimeout(() => {
            this.canDisconnect = true;
          }, 15000 - timePassedFromConnection);
        }
      }),
    );
  }

  disconnectCloud(): void {
    this.store.dispatch(disconnectCloud());
  }

  getTicketQrCodeInfoFromDevice(): void {
    this.store.dispatch(getTicketQrCodeInfoFromDevice());
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
