import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { SvgIconComponent } from '@livestock/ui';
import { IControllerView, selectActiveControllerID } from '@livestock/controllers';
import { ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { PlatformService } from '@livestock/shared/services';
import { EnumPipe } from '@livestock/shared/pipes';
import { FarmControllerStatusEnum } from '../../../../../farms/src/lib/enum/farm-controller-status.enum';
import { IController } from '../../../../../farms/src/lib/interfaces/controller.interface';

@Component({
  selector: 'lv-controller-indicators',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
    EnumPipe,
  ],
  templateUrl: './controller-indicators.component.html',
  styleUrls: ['./controller-indicators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControllerIndicatorsComponent {
  @Input() controller: IController | IControllerView;

  private store: Store = inject(Store);

  public platformService: PlatformService = inject(PlatformService);

  // vars
  activeControllerID$: Observable<number> = this.store.select(selectActiveControllerID);

  // enums
  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum: typeof ColorsEnum = ColorsEnum;
  FarmControllerStatusEnum: typeof FarmControllerStatusEnum = FarmControllerStatusEnum;
}
