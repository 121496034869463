import { IWebSocketResponse } from '@livestock/web-sockets';
import { createAction, props } from '@ngrx/store';
import { PageTypeEnum, SectorTypeEnum } from '@livestock/shared/enums';

export const addWebSocketEvents = createAction(
    '[Web Sockets] Add WebSocket event [...]',
    props<{ payload: IWebSocketResponse[] }>(),
);

export const clearWebSocketEvents = createAction(
    '[Web Sockets] Clear WebSocketEvents [...]',
);

export const filterSyncAndCnfStatusEventsByControllerID = createAction(
    '[Web Sockets] Filter Sync And Cnf Status Events By ControllerID [...]',
    props<{ controllerID: number; sectorTypes: SectorTypeEnum[] }>(),
);

export const filterCloudConnectionAcceptEventsByControllerID = createAction(
    '[Web Sockets] Filter Cloud Connection Accept Events By ControllerID [...]',
    props<{ controllerID: number; }>(),
);

export const filterRefreshPageEvents= createAction(
    '[Web Sockets] Filter Sync And Cnf Status Events By ControllerID [...]',
    props<{ controllerID: number; page: PageTypeEnum; }>(),
);