import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { google } from 'google-maps';
import { ButtonTypeEnum, ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { LanguageService } from '@livestock/shared/services';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';

@Component({
  template: '',
  standalone: true,
})
export class AbstractFarmFormComponent implements AfterViewInit, OnDestroy {
  @ViewChild('map') mapElement: ElementRef;
  @ViewChild('addressInput') addressInput: ElementRef;
  @ViewChild('nameInput') nameInput: ElementRef;
  @ViewChild('pseudoLoading') pseudoLoading: ElementRef;

  sub$ = new Subscription();

  farmNamePlaceholder = 'Farms.FarmName';
  farmAddressPlaceholder = 'Farms.FarmLocation';
  fieldNameInputInFocus: boolean;
  fieldAddressInputInFocus: boolean;
  locationMapIsOpen: boolean;
  showLoading: boolean = true;

  //enums
  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;
  ButtonTypeEnum = ButtonTypeEnum;

  //Google Map properties
  map: google.maps.Map;
  geocoder: google.maps.Geocoder;
  mapProperties: any;

  languageService: LanguageService = inject(LanguageService);
  protected formBuilder: FormBuilder = inject(FormBuilder);
  protected store: Store = inject(Store);
  protected actions$: Actions = inject(Actions);
  protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  form = this.formBuilder.group({
    name: ['', [Validators.required, Validators.maxLength(50)]],
    address: ['', [Validators.required]],
    latitude: [<number>null, [Validators.required]],
    longitude: [<number>null, [Validators.required]],
  });

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showLoading = false;
      this.cdr.detectChanges();
    }, 1000);
  }

  setupMap(latitude: number, longitude: number, zoom: number = 10): void {
    this.geocoder = new google.maps.Geocoder();

    this.mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      disableDefaultUI: true,
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
    this.map.addListener('dragend', () => this.getAddressAndLatLng());
    this.map.addListener('zoom_changed', () => this.getAddressAndLatLng());

    this.listenToMapSearchBox();
  }

  listenToMapSearchBox(): void {
    const searchBoxWrapper: HTMLElement = document.querySelector('.field-map') as HTMLElement;
    const searchBoxInput: HTMLInputElement = searchBoxWrapper.querySelector('input') as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(searchBoxInput);
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(searchBoxWrapper);

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      if (places.length == 0) {
        return;
      }

      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry) {
          alert('Returned place contains no geometry');
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
          return;
        }

        bounds.extend(place.geometry.location);
      });
      this.map.fitBounds(bounds);
      this.getAddressAndLatLng();
    });
  }

  private getAddressAndLatLng(): void {
    const bounds = this.map.getBounds();
    const [latitudeKey, longitudeKey] = Object.keys(bounds);

    const latitude = (bounds[latitudeKey].lo + bounds[latitudeKey].hi) / 2;
    const longitude = (bounds[longitudeKey].lo + bounds[longitudeKey].hi) / 2;
    const myLatLng = new google.maps.LatLng(latitude, longitude);
    this.geocoder.geocode({ location: myLatLng }, (results) => {
      const address = results?.[0].formatted_address;
      this.form.patchValue({
        address,
        latitude,
        longitude,
      });
      this.form.updateValueAndValidity();
    });
  }

  onFocusInFarmNameInput(): void {
    if (this.form.disabled) {
      return;
    }

    this.fieldNameInputInFocus = true;
    this.farmNamePlaceholder = '';
    setTimeout(() => {
      if (this.form.value.name.length) {
        this.nameInput.nativeElement.focus();
        this.nameInput.nativeElement.setSelectionRange(99, 99);
      }
    });
  }

  onFocusOutFarmNameInput(): void {
    this.fieldNameInputInFocus = false;
    this.farmNamePlaceholder = 'Farms.FarmName';
    this.nameInput.nativeElement.blur();
  }

  onFocusInFarmLocationInput(): void {
    if (this.form.disabled) {
      return;
    }

    this.locationMapIsOpen = true;
    this.fieldAddressInputInFocus = true;
    this.farmAddressPlaceholder = '';
    setTimeout(() => {
      if (this.form.value.address.length) {
        this.addressInput.nativeElement.focus();
        this.addressInput.nativeElement.setSelectionRange(99, 99);
      }
    });
  }

  onFocusOutFarmLocationInput(closeMapOnBlur?: boolean): void {
    if (closeMapOnBlur) {
      this.locationMapIsOpen = false;
    }

    this.fieldAddressInputInFocus = false;
    this.farmAddressPlaceholder = 'Farms.FarmLocation';
    this.addressInput.nativeElement.blur();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
