import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer';

export const selectAuthState =
  createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const selectIsLoading = createSelector(
  selectAuthState,
  ({ isLoginProcess }) => isLoginProcess,
);

export const selectWasLoggedIn = createSelector(
  selectAuthState,
  ({ wasLoggedIn }) => wasLoggedIn,
);
