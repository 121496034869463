import { trigger, transition, style, animate } from '@angular/animations';

export const slideInRight = trigger('slideInRight', [
  transition(':enter', [style({ right: -280, opacity: 0 }), animate('300ms', style({ right: 0, opacity: 1 }))]),
  transition(':leave', [style({ right:  0, opacity: 1 }), animate('300ms', style({ right: -280, opacity: 0 }))]),
]);

export const slideInRightWeb = trigger('slideInRightWeb', [
  transition(':enter', [style({ right: -160, opacity: 0 }), animate('300ms', style({ right: 28, opacity: 1 }))]),
  transition(':leave', [style({ right:  28, opacity: 1 }), animate('300ms', style({ right: -160, opacity: 0 }))]),
]);