<div
  *ngIf="!isLoading; else loading"
  [formGroup]="form"
  class="">
  <div class="field-row d-flex align-center gap-8 mb-4">
    <div class="ls-input ls-input-autoDateTimeType field">
      <div class="input-wrapper">
        <input
          [qaTags]="'qa-inp-autoDateTimeType'"
          [value]="form.value.autoDateTimeType | enum : 'auto-date-time-type' | translate"
          disabled />
        <label class="input-label input-label_filled">
          {{ "Controller.DateTimeSettings.DateAndTime" | translate }}
        </label>
      </div>
    </div>

    <div
      [class.disabled]="isDisabled"
      class="ls-input ls-input-hoursFormat field">
      <div class="input-wrapper">
        <lv-dual-toggle
          [qaTags]="'qa-tog-hoursFormat'"
          [disabled]="isDisabled"
          [firstOption]="{
            title: HoursFormatTypeEnum.HoursFormat24 | enum : 'controller-hours-format',
            value: HoursFormatTypeEnum.HoursFormat24
          }"
          [secondOption]="{
            title: HoursFormatTypeEnum.AmPm | enum : 'controller-hours-format',
            value: HoursFormatTypeEnum.AmPm
          }"
          formControlName="hoursFormat">
        </lv-dual-toggle>

        <label class="input-label input-label_filled">
          {{ "Controller.DateTimeSettings.TimeFormat" | translate }}
        </label>
      </div>
    </div>
  </div>

  <div class="fz-12 mb-22">
    <span>{{ "Controller.DateTimeSettings.AutoOptionIsOnlyPossibleAfterConnectionToTheCloud" | translate }}</span>
  </div>

  <div class="field-row d-flex align-center gap-8">
    <div class="field">
      <lv-input-date
        (onFocusOutWithError)="updateValidity()"
        [qaTags]="'qa-inp-date'"
        [isDisabled]="isDisabled || form.value.autoDateTimeType === AutoDateTimeTypeEnum.ByNetwork"
        [label]="'Controller.DateTimeSettings.DateSetting'"
        [isImperial]="(language$ | async) === ControllerLanguageEnum.EngUS"
        [placeholder]="
          (language$ | async) === ControllerLanguageEnum.EngUS
            ? GlobalConstants.USA_Date_placeholder
            : GlobalConstants.EU_Date_placeholder
        "
        formControlName="date"
        class="w-input-113">
        <ls-svg-icon
          [path]="IconsEnum.DATE_ICON"
          leftIcon
          class="mr-8 w-18">
        </ls-svg-icon>
      </lv-input-date>
    </div>

    <div class="field">
      <lv-input-time
        (onFocusOutWithError)="updateValidity()"
        [qaTags]="'qa-inp-time'"
        [isDisabled]="isDisabled || form.value.autoDateTimeType === AutoDateTimeTypeEnum.ByNetwork"
        [label]="'Controller.DateTimeSettings.TimeSetting'"
        [AMPM]="form.value.hoursFormat === HoursFormatTypeEnum.AmPm"
        [placeholder]="
          form.value.hoursFormat === HoursFormatTypeEnum.AmPm
            ? GlobalConstants.Time_hhmmAMPM_placeholder
            : GlobalConstants.Time_hhmm_placeholder
        "
        formControlName="time"
        class="w-input-63">
        <ls-svg-icon
          [path]="IconsEnum.TIME_ICON"
          leftIcon
          class="mr-8 w-18">
        </ls-svg-icon>
      </lv-input-time>
    </div>
  </div>
</div>

<ng-template #loading>
  <lv-loading-galcon></lv-loading-galcon>
</ng-template>
