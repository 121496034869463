<div class="d-flex flex-row general-settings">
  <ng-container *ngIf="form">
    <!-- TODO: Check this ngIf when mobile settings are developed -->
    <div
      [formGroup]="form"
      class="menu d-flex flex-row gap-20">
      <section class="d-flex flex-column gap-20 titles">
        <div
          *ngIf="isQuickStart"
          (click)="menuClicked('language')"
          [qaTags]="'qa-menu-languageOn'"
          [ngClass]="{
          'form-field__active connect connect-separator': menuItem === 'language',
          disabled: isDisabled,
          }"
          class="form-field justify-unset">
          <div class="form-field__title">{{ "Controller.GeneralSettings.Language" | translate }}</div>
          <div class="form-field__value d-flex flex-row justify-between">
            <span [ngClass]="{ highlight: menuItem !== 'language' }">{{
              this.form.value.language | enum : "controller-language" | translate
            }}</span>
            <ls-svg-icon
              [color]="menuItem === 'language' ? ColorsEnum.MainBlue : ColorsEnum.MonoGrey"
              [path]="IconsEnum.EXPAND_IN_CIRCLE"
              class="field-toggle" />
          </div>
        </div>

        <div
          (click)="menuClicked('units')"
          [qaTags]="'qa-menu-unitsOn'"
          [ngClass]="{
            'units-settings-field': !isQuickStart,
            'form-field__active connect connect-separator': menuItem === 'units' && !isDisabled,
            'pointer-events-none': isDisabled,
            'units-mobile': platformService.isMobileVersion,
          }"
          class="form-field justify-unset">
          <div class="form-field__title">{{ "Controller.GeneralSettings.Units" | translate }}</div>
          <div class="form-field__value">
            <div class="units-controls d-flex flex-row justify-between">
              <lv-radio-list
                [formControlName]="'units'"
                [list]="unitsList"
                direction="horizontal"
                size="medium" />
              <ls-svg-icon
                [color]="menuItem === 'units' ? ColorsEnum.MainBlue : ColorsEnum.MonoGrey"
                [path]="IconsEnum.EXPAND_IN_CIRCLE"
                class="field-toggle" />
            </div>

            <ng-container *ngIf="platformService.isMobileVersion">
              <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
            </ng-container>
          </div>
        </div>

        <div
          *ngIf="isQuickStart"
          (click)="menuClicked('birdType')"
          [qaTags]="'qa-menu-birdTypeOn'"
          [ngClass]="{
            'form-field__active connect connect-separator': menuItem === 'birdType',
            disabled: !isQuickStart || isDisabled
          }"
          class="form-field justify-unset">
          <div class="form-field__title">{{ "Controller.GeneralSettings.TypeOfBird" | translate }}</div>
          <div class="form-field__value d-flex flex-row justify-between">
            <span [ngClass]="{ highlight: menuItem !== 'birdType' }">{{
              this.form.value.birdType | enum : "controller-bird-type" | translate
            }}</span>
            <ls-svg-icon
              [color]="menuItem === 'birdType' ? ColorsEnum.MainBlue : ColorsEnum.MonoGrey"
              [path]="IconsEnum.EXPAND_IN_CIRCLE"
              class="field-toggle" />
          </div>
        </div>
      </section>

      <ng-container *ngIf="!platformService.isMobileVersion">
        <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
      </ng-container>

      <ng-template #menuItemContent>
        <section
          *ngIf="menuItem"
          [ngClass]="{
            'p-15': menuItem !== 'units',
            'top-0': !isQuickStart && menuItem === 'units'
          }"
          class="d-flex flex-column gap-20 settings {{ menuItem }}">
          <div
            *ngIf="menuItem === 'language'"
            [@fadeInOut]>
            <lv-radio-list
              [formControlName]="'language'"
              [list]="languagesList"
              direction="vertical"
              size="small" />
          </div>

          <div
            *ngIf="menuItem === 'units'"
            [@fadeInOut]>
            <ls-custom-units-list
              [formControlName]="'unitsDetails'"
              [unitsList]="initialUnitsList"
              [unitsControl]="unitsControl" />
          </div>

          <div
            *ngIf="menuItem === 'birdType'"
            [@fadeInOut]>
            <lv-radio-list
              [formControlName]="'birdType'"
              [list]="birdTypeList"
              direction="vertical"
              size="small" />
          </div>
        </section>
      </ng-template>
    </div>
  </ng-container>
</div>
