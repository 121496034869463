import { IEditUnitModeObj } from '../../interfaces';

export enum IlluminanceUnitEnum {
  Luxs,
  FootCandle,
}

export namespace IlluminanceUnitEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case IlluminanceUnitEnum.Luxs:
        return 'Controller.GeneralSettings.CustomUnitsDialog.IlluminanceEnum.Lux';
      case IlluminanceUnitEnum.FootCandle:
        return 'Controller.GeneralSettings.CustomUnitsDialog.IlluminanceEnum.FootCandle';
      default:
        return value.toString();
    }
  }

  export function isMetricUnit(value: IlluminanceUnitEnum): boolean {
    return value === IlluminanceUnitEnum.Luxs;
  }

  export function toEditUnitModeObj(): IEditUnitModeObj {
    return {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.Illuminance',
      controlName: 'illuminance',
      enumName: IlluminanceUnitEnum,
      enumStr: 'illuminance-unit-enum',
    };
  }
}
