import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';
import { NativeElementInjectorDirective } from '../../native-element.directive';
import { InputIntegerComponent, SvgIconComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    QaTagsDirective,
    NativeElementInjectorDirective,

    InputIntegerComponent,
    TranslateModule,
    SvgIconComponent,
  ],
  selector: 'lv-input-integer-with-label',
  templateUrl: 'lv-input-integer-with-label.component.html',
  styleUrls: [
    '../input-integer.component.scss',
    'lv-input-integer-with-label.component.scss',
  ],
})

export class LVInputIntegerWithLabelComponent extends InputIntegerComponent {
  @Input() label = '';
  @Input() fullWidth: boolean;
  @Input() labelIcon: string;
  @Input() fontWeight: number;
  @Input() fontWeightDependingOnFocus: boolean;
  @Input() noWrapperPadding: boolean;
  @Input() disableAnimation: boolean;
  @Input() textAlign: 'left' | 'right' | 'center' = 'left';
  @Input() inputWrapperContentAlign: 'center' | 'start' | 'end' = 'start';
  @Input() inputMinWidth: string;

  focusInput(): void {
    this.inputRef.nativeElement.focus();
  }
}
