import { Injectable } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Injectable({
  providedIn: 'root',
})
export class SwiperService {
  config: SwiperOptions;
  swiperIsActive: boolean = true;
}
