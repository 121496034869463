import { SortingEnum } from '@livestock/shared/enums';

export class SortValuesUtil {
  static sortValues<T>(a: T, b: T, sortingKey: string, sortBy: SortingEnum = SortingEnum.ASC): number {

    if (typeof a[sortingKey] === 'string') {

      if (a[sortingKey] == null) return 1;
      if (b[sortingKey] == null) return -1;

      return sortBy === SortingEnum.ASC
        ? a[sortingKey]?.toLowerCase().localeCompare(b[sortingKey].toLowerCase())
        : b[sortingKey]?.toLowerCase().localeCompare(a[sortingKey].toLowerCase());
    }

    return sortBy === SortingEnum.ASC
      ? a[sortingKey] - b[sortingKey]
      : b[sortingKey] - a[sortingKey];
  }
}
