export class VirtualKeyboardConstants {
  static NumericArrayTop: number[] = [1, 2, 3, 4, 5];
  static NumericArrayBottom: number[] = [6, 7, 8, 9, 0];

  static DefaultRangeLabel = 'Keyboard.RangeWithValues';
  static BirdWeightRangeLabel = 'Keyboard.BirdWeightRangeWithValues';

  static LettersFirstRow = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'];
  static LettersSecondRow = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'];
  static LettersThirdRow = ['z', 'x', 'c', 'v', 'b', 'n', 'm'];
  static NumbersFirstRow = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  static SymbolsSecondRow = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')'];
  static SymbolsThirdRow = ['+', '=', '-', '_', ',', '.', '/'];
}
