import { ControllerUnitEnum } from '@livestock/controllers/enums';
import { IControllerView } from '@livestock/controllers/interfaces';
import { IGetOrUpdateElement } from '@livestock/installation/interfaces';
import { AirPressureUnitEnum, ElementTypesEnum, TemperatureUnitEnum, VolumeUnitEnum, WeightUnitEnum } from '@livestock/shared/enums';
import { ConvertHelper } from '@livestock/shared/utils';

export class UnitsHelper {
  static getTemperatureUnit(controllerInfo: IControllerView): TemperatureUnitEnum {
    switch (controllerInfo?.units) {
      case ControllerUnitEnum.Metric:
        return TemperatureUnitEnum.Celsius;
      case ControllerUnitEnum.Imperial:
        return TemperatureUnitEnum.Fahrenheit;
      default:
        return controllerInfo?.unitsDetails?.temperature;
    }
  }

  static getWeightUnit(controllerInfo: IControllerView): WeightUnitEnum {
    switch (controllerInfo?.units) {
      case ControllerUnitEnum.Metric:
        return WeightUnitEnum.Kilogram;
      case ControllerUnitEnum.Imperial:
        return WeightUnitEnum.Pound;
      default:
        return controllerInfo?.unitsDetails?.weight;
    }
  }

  static getPressureUnit(controllerInfo: IControllerView): AirPressureUnitEnum {
    switch (controllerInfo?.units) {
      case ControllerUnitEnum.Metric:
        return AirPressureUnitEnum.Pascal;
      case ControllerUnitEnum.Imperial:
        return AirPressureUnitEnum.InchesOfWater;
      default:
        return controllerInfo?.unitsDetails?.airPressure;
    }
  }

  static getVolumeUnit(controllerInfo: IControllerView): VolumeUnitEnum {
    switch (controllerInfo?.units) {
      case ControllerUnitEnum.Metric:
        return VolumeUnitEnum.Litre;
      case ControllerUnitEnum.Imperial:
        return VolumeUnitEnum.Gallon;
      default:
        return controllerInfo?.unitsDetails?.volume;
    }
  }

  static recalculateElementSetupToImperial(
    elementSetup: IGetOrUpdateElement,
    elementType: ElementTypesEnum,
    controllerInfo: IControllerView,
  ): IGetOrUpdateElement {
    switch (elementType) {
      case ElementTypesEnum.IndoorTemperature:
      case ElementTypesEnum.OutdoorTemperature: {
        const tempUnit = UnitsHelper.getTemperatureUnit(controllerInfo);
        return {
          ...elementSetup,
          offset: tempUnit === TemperatureUnitEnum.Fahrenheit
            ? ConvertHelper.celsiusToFahrenheit(elementSetup.offset, 1)
            : elementSetup.offset,
        };
      }
      case ElementTypesEnum.WaterMeter: {
        const volumeUnit = UnitsHelper.getVolumeUnit(controllerInfo);
        return {
          ...elementSetup,
          quantity: volumeUnit === VolumeUnitEnum.Gallon
            ? ConvertHelper.litreToGallon(elementSetup.quantity, 1)
            : elementSetup.quantity,
        };
      }

      default:
        return elementSetup;
    }
  }

  static recalculateElementSetupToMetric(
    elementSetup: IGetOrUpdateElement,
    elementType: ElementTypesEnum,
    controllerInfo: IControllerView,
  ): IGetOrUpdateElement {
    switch (elementType) {
      case ElementTypesEnum.IndoorTemperature:
      case ElementTypesEnum.OutdoorTemperature: {
        const tempUnit = UnitsHelper.getTemperatureUnit(controllerInfo);
        return {
          ...elementSetup,
          offset: tempUnit === TemperatureUnitEnum.Fahrenheit
            ? ConvertHelper.fahrenheitToCelsius(elementSetup.offset, 1)
            : elementSetup.offset,
        };
      }
      case ElementTypesEnum.WaterMeter: {
        const volumeUnit = UnitsHelper.getVolumeUnit(controllerInfo);
        return {
          ...elementSetup,
          quantity: volumeUnit === VolumeUnitEnum.Gallon
            ? ConvertHelper.gallonToLitre(elementSetup.quantity, 1)
            : elementSetup.quantity,
        };
      }

      default:
        return elementSetup;
    }
  }
}