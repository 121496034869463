<div class="d-flex flex-column h-100-percent">
  <lv-settings-header
    (toggleDisabled)="onToggleDisabled()"
    [isEditMode]="isEditMode"
    [section]="'ControllerMenu.Sections.BasicSettings' | translate"
    [title]="'Controller.QuickStart.Network' | translate"
    [isEditable]="platformService.isDeviceApp"
    [class.pointer-events-none]="isLoading$ | async" />

  <div
    *ngIf="platformService.isDeviceApp"
    class="d-flex justify-center mb-40">
    <lv-current-network-status [isLoading]="isNetworkLoading$ | async" />
  </div>
  <ls-network-settings-form
    *ngIf="networkSettings$ | async as networkSettings"
    (changed)="changedNetworkSettings($event)"
    (onConnectToWifi)="connectWifi($event)"
    (onWifiDisconnect)="disconnectWifi()"
    (onScanWifi)="scanWifiNetworks()"
    (onConnectModem)="connectModem($event)"
    (onDisconnectModem)="disconnectModem()"
    [isLoading]="isLoading$ | async"
    [networkSettings]="networkSettings"
    [class.pointer-events-none]="!platformService.isDeviceApp || !isEditMode" />
</div>
