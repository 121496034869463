import {
  AfterViewInit,
  Component,
  ElementRef, HostListener,
  Input, OnDestroy, OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgControl, NgModel } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { GlobalConstants } from '@livestock/shared/constants';
import { PlatformService } from '@livestock/shared/services';
import { SleepUtils } from '@livestock/shared/utils';
import { NativeElementInjectorDirective } from '../native-element.directive';

@Component({
  selector: 'ls-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: [
    '../input-integer/input-integer.component.scss',
    './input-text.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    QaTagsDirective,
    NativeElementInjectorDirective,
  ],
  providers: [
    NgModel,
  ],
})
export class InputTextComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('input') inputRef: ElementRef;
  @Input() fieldWithKeyboard = false;
  @Input() formControlName: string;
  @Input() placeholder = '';
  @Input() minLength: number = GlobalConstants.MinLength;
  @Input() maxLength: number = GlobalConstants.MaxLength;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() centered: boolean;
  @Input() inputType: 'text' | 'password' = 'text';
  sub$ = new Subscription();
  value: string;

  @HostListener('input', ['$event'])
  onInput(e: InputEvent): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    this.value = target.value;
    this.updateInputValue();
    this.valueChange();
  }

  @HostListener('focusin', ['$event'])
  onInputClick(): void {
    setTimeout(() => {
      if (!this.value) {
        this.inputRef.nativeElement.selectionStart = 0;
        this.inputRef.nativeElement.selectionEnd = 0;
        return;
      }
      this.inputRef.nativeElement.selectionStart = 999;
      this.inputRef.nativeElement.selectionEnd = 999;
    });
  }

  // hide keyboard if clicked outside input
  @HostListener('focusout')
  async unsetFormControl(): Promise<void> {
    await SleepUtils.sleep(100);
  }

  constructor(
    private store: Store,
    private platformService: PlatformService,
    private control: NgControl,
    @Optional()
    private model: NgModel,
  ) {
    control.valueAccessor = this;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.updateInputValue();
  }

  updateInputValue(): void {
    if (!this.inputRef || this.value == null) return;
    this.inputRef.nativeElement.value = this.value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  valueChange(value?): void {
    const newValue = value || this.value;
    this.onChange(newValue);
  }

  writeValue(value: string): void {
    if (this.value === value) return;
    this.value = value;
    this.updateInputValue();
  }

  setValue(event): void {
    this.onChange(event.target.value);
  }

  registerOnChange(
    onChange: (value: number | string) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private onTouched = (): void => {
  };
  private onChange: (
    value: number | string,
  ) => void = () => {
  };
}
