import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../controls/button/button.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ls-page-404',
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonComponent, RouterLink],
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
})
export class Page404Component {
}
