import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, lastValueFrom, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as currentControllerActions from '../current-controller.actions';
import { IControllerView } from '../../../interfaces/controller/controller-view.interface';
import {
  selectActiveControllerID,
  selectControllerInfo,
} from '../current-controller.selectors';
import { Router } from '@angular/router';
import { AppRoutes } from '@livestock/shared/routes';
import { LocalStorageService, PlatformService } from '@livestock/shared/services';
import { AlignmentEnum, DialogButtonEnum, StorageItem } from '@livestock/shared/enums';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '@livestock/ui';
import { ControllerInfoService } from '../../../services/controller-info.service';

@Injectable()
export class ControllerInfoEffects {
  getControllerInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getControllerInfo),
      withLatestFrom(this.store.select(selectControllerInfo)),
      switchMap(([{ controllerID }, controllerView]) => {
        /* don't make redundant requests if we already have info about current controller in store */
        if (controllerView?.controllerID === controllerID) {
          return EMPTY;
        }

        return this.controllerInfoService.getController(controllerID).pipe(
          map((controllerInfo: IControllerView) => currentControllerActions.getControllerInfoSuccess({ controllerInfo })),
          catchError((error) => {
            if (!this.platformService.isDeviceApp) {
              const activeFarmID = LocalStorageService.getStorageItem(StorageItem.ActiveFarmID);
              this.router.navigateByUrl(activeFarmID ? `${AppRoutes.FARM}/${activeFarmID}` : AppRoutes.FARMS);
            }
            return of(currentControllerActions.getControllerInfoError({ payload: error }));
          }),
        );
      }),
    ),
  );

  disconnectController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.disconnectController),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) => {
        return this.controllerInfoService.disconnectController(controllerID).pipe(
          map(() => {
            if (!this.platformService.isDeviceApp) {
              this.showControllerWasDisconnectedMessage();
            }

            return currentControllerActions.disconnectControllerSuccess();
          }),
          catchError((error) => of(currentControllerActions.disconnectControllerError({ payload: error }))),
        );
      }),
    ),
  );

  resendControllerPageCnf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.resendControllerPageCnf),
      map(action => action.page),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([page, controllerID]) => {
        return this.controllerInfoService.resendControllerPageCnf(controllerID, page).pipe(
          map(() =>
            currentControllerActions.resendControllerPageCnfSuccess()),
          catchError((error) => of(currentControllerActions.resendControllerPageCnfError({ payload: error }))),
        );
      }),
    ),
  );

  async showControllerWasDisconnectedMessage(): Promise<void> {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '350px',
      disableClose: true,
    });
    dialogRef.componentInstance.setup(
      'FlashMessages.ControllerWasDisconnected',
      'FlashMessages.ClickOKToNavigateToMainPage',
      [DialogButtonEnum.OK],
      'flash/success',
      true,
      true,
      AlignmentEnum.CENTER,
      AlignmentEnum.CENTER,
      false,
    );

    const res = await lastValueFrom(dialogRef.afterClosed());
    if (res === DialogButtonEnum.OK) {
      const activeFarmID = LocalStorageService.getStorageItem(StorageItem.ActiveFarmID);
      this.router.navigateByUrl(activeFarmID ? `${AppRoutes.FARM}/${activeFarmID}` : AppRoutes.FARMS);
    }
  }

  constructor(
    private store: Store,
    private router: Router,
    private platformService: PlatformService,
    private dialog: MatDialog,
    private actions$: Actions,
    private controllerInfoService: ControllerInfoService,
  ) {
  }
}
