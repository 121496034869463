import { createSelector } from '@ngrx/store';

// TODO: Fix direct importing files from libs;
import { getCurrentControllerState } from '../common/current-controller-main.selector';

export const selectSettingsAreLoading = createSelector(
  getCurrentControllerState,
  ({ isLoading }) => isLoading,
);

export const selectControllerGeneralSettings = createSelector(
  getCurrentControllerState,
  ({ generalSettings }) => generalSettings,
);

export const selectCurrentControllerDateTimeSettings = createSelector(
  getCurrentControllerState,
  ({ dateTimeSettings }) => dateTimeSettings,
);

export const selectCurrentControllerHouseSettings = createSelector(
  getCurrentControllerState,
  ({ houseSettings }) => houseSettings,
);

export const selectCurrentControllerNetworkSettings = createSelector(
  getCurrentControllerState,
  ({ networkSettings }) => networkSettings,
);
