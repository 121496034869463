import { AbstractControl, ValidatorFn } from '@angular/forms';

export const greaterThanValidator = (fieldName: string, targetErrorToRemoveIfValid: string | null = null, couldBeEqual: boolean = false): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const targetControl = control?.parent?.get(fieldName);
    if (!targetControl) {
      return null;
    }

    const target = targetControl.value;
    const current = control.value;
    const errorCondition = couldBeEqual
      ? current < target
      : current <= target;

    if (errorCondition) {
      return {
        gt: {
          min: couldBeEqual ? target : `${target + 1}`,
        },
      };
    }

    if (targetErrorToRemoveIfValid && targetControl?.errors && targetControl.errors?.[targetErrorToRemoveIfValid]) {
      delete targetControl.errors[targetErrorToRemoveIfValid];
      targetControl.setErrors(Object.keys(targetControl.errors).length === 0 ? null : targetControl.errors);
      targetControl.updateValueAndValidity();
    }
    return null;
  };
};
