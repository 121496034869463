import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { QuickStartStatusEnum } from '@livestock/controllers/enums';
import { ITicketQrCodeInfo } from '@livestock/controllers/interfaces';

// general settings
export const createGeneralSettingsViaDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Create General Settings Via Device [...]',
);

export const createGeneralSettingsViaDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Create General Settings Via Device [SUCCESS]',
);

export const createGeneralSettingsViaDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Create General Settings Via Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// date and time settings
export const createDateTimeSettingsViaDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Create DateTime Settings Via Device [...]',
  props<{ isFinish: boolean }>(),
);

export const createDateTimeSettingsViaDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Create DateTime Settings Via Device [SUCCESS]',
  props<{ isFinish: boolean }>(),
);

export const createDateTimeSettingsViaDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Create DateTime Settings Via Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// network settings
export const createNetworkSettingsViaDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Create Network Settings Via Device [...]',
);

export const createNetworkSettingsViaDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Create Network Settings Via Device [SUCCESS]',
);

export const createNetworkSettingsViaDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Create Network Settings Via Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// quick start status
export const getQuickstartStatus = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Quickstart Status [...]',
);

export const getQuickstartStatusSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Quickstart Status [SUCCESS]',
);

export const getQuickstartStatusError = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Quickstart Status [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setQuickstartStatusStep = createAction(
  '[UPSERT CONTROLLER DEVICE] Set Quickstart Status Step [...]',
  props<{ step: QuickStartStatusEnum }>(),
);

export const setQuickstartStatusStepSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Set Quickstart Status Step [SUCCESS]',
);

export const setQuickstartStatusStepError = createAction(
  '[UPSERT CONTROLLER DEVICE] Set Quickstart Status Step [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// controller serial number
export const getControllerSerialNumberDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Controller Serial Number Device [...]',
);

export const getControllerSerialNumberDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Controller Serial Number Device [SUCCESS]',
  props<{ serialNumber: string }>(),
);

export const getControllerSerialNumberDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Controller Serial Number Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// ticket info
export const getTicketQrCodeInfoFromDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Ticket QR Code Info from controller [...]',
);

export const getTicketQrCodeInfoFromDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Ticket QR Code Info controller [SUCCESS]',
  props<{ ticketQrCodeInfo: ITicketQrCodeInfo }>(),
);

export const getTicketQrCodeInfoFromDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Ticket QR Code Info from controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// network connection
export const setNetworkConnectionInProgress = createAction(
  '[UPSERT CONTROLLER] Set Network Connection In Progress [...]',
  props<{ connectionInProgress: boolean }>(),
);

// cloud connection request
export const setCloudConnectionRequest = createAction(
  '[UPSERT CONTROLLER] Cloud Connection Request',
  props<{ farm: string; email: string; showPopup: boolean; }>(),
);

export const approveCloudConnectionRequest = createAction(
  '[UPSERT CONTROLLER] Approve Cloud Connection Request [...]',
);

export const approveCloudConnectionRequestSuccess = createAction(
  '[UPSERT CONTROLLER] Approve Cloud Connection Request [SUCCESS]',
);

export const approveCloudConnectionRequestError = createAction(
  '[UPSERT CONTROLLER] Approve Cloud Connection Request [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const rejectCloudConnectionRequest = createAction(
  '[UPSERT CONTROLLER] Reject Cloud Connection Request [...]',
);

export const rejectCloudConnectionRequestSuccess = createAction(
  '[UPSERT CONTROLLER] Reject Cloud Connection Request [SUCCESS]',
);

export const rejectCloudConnectionRequestError = createAction(
  '[UPSERT CONTROLLER] Reject Cloud Connection Request [ERROR]',
);

export const disconnectCloud = createAction(
  '[UPSERT CONTROLLER] Disconnect Cloud [...]',
);

export const disconnectCloudSuccess = createAction(
  '[UPSERT CONTROLLER] Disconnect Cloud [SUCCESS]',
);

export const disconnectCloudError = createAction(
  '[UPSERT CONTROLLER] Disconnect Cloud [ERROR]',
);
