export enum QuickStartStepsEnum {
  GeneralSettings = 1,
  //HouseSettings = 2,
  NetworkSettings = 2,
  CloudSettings = 3,
  DateTimeSettings = 4,
  // FlockSettings = 6,
  // HouseModeSettings = 7,
  // ConnectionsSettings = 8,
}
