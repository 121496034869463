import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LayoutState } from './layout.state';

export const LAYOUT_FEATURE_KEY = 'layout';

export const selectLayoutState =
    createFeatureSelector<LayoutState>(LAYOUT_FEATURE_KEY);

export const selectMobileViewMode = createSelector(selectLayoutState,
    ({ mobileViewMode }) => mobileViewMode);

export const selectIsDialogOpened = createSelector(selectLayoutState,
    ({ isDialogOpened }) => isDialogOpened);