<div
  [class.wrapper-active]="editMode"
  class="wrapper wrapper-size-{{ size }}">
  <label
    [class.label-active]="editMode"
    class="label">
    {{ label }}
  </label>

  <div
    (click)="toggleEditMode()"
    [class.current-value__active]="editMode"
    class="current-value pointer fw-500 color-darkblue ph-18 d-flex align-center justify-between">
    <div class="d-flex align-center">
      <ng-content></ng-content>
      <ls-svg-icon
        *ngIf="showTick && editMode"
        [path]="IconsEnum.DROP_DOWN_TICK"
        class="mr-5">
      </ls-svg-icon>
      <span class="value">
        {{ value != null ? (value | enum : enumString | translate) : (placeholder | translate) }}
      </span>
    </div>

    <ls-svg-icon
      [path]="IconsEnum.ARROW_SELECT"
      [color]="editMode ? ColorsEnum.MainDarkBlue : ColorsEnum.MonoDark"
      [class.rotate-180]="editMode"></ls-svg-icon>
  </div>

  <div
    *ngIf="editMode"
    class="values-list">
    <ng-container *ngFor="let option of enum | enumToArray : filterEnumFn : sortEnum; last as last">
      <div
        *ngIf="value !== option"
        (click)="selectOption(option)"
        [class.border-radius-4]="last"
        class="d-flex align-center option pointer">
        <ls-svg-icon
          *ngIf="showTick"
          [path]="IconsEnum.DROP_DOWN_CIRCLE"
          class="mr-5">
        </ls-svg-icon>
        {{ option | enum : enumString | translate }}
      </div>
    </ng-container>
  </div>
</div>
