import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { selectCloudConnectionRequest, setCloudConnectionRequest } from '@livestock/controllers';
import { ICloudConnectionRequest } from '@livestock/controllers/interfaces';
import { SseEventTypeEnum } from '@livestock/shared/enums';
import { SSEEvent, SSEEventConnectionRequest } from '@livestock/shared/interfaces';
import { displayActions } from '../+state/display.actions';
import { setFlashMessage } from '@livestock/notifications';

@Injectable({
  providedIn: 'root',
})
export class AppDisplayClientSseServiceHandler {

  constructor(
    private store: Store,
  ) {
  }

  async handle(event: SSEEvent): Promise<void> {
    switch (event?.type) {
      case SseEventTypeEnum.CONNECTION_REQUEST:
        this.handleConnectionRequest(event);
        break;
      case SseEventTypeEnum.RPC_TAB_RELOAD:
        this.store.dispatch(displayActions.reloadRequired(true));
        break;
      case SseEventTypeEnum.RPC_UPDATE_AVAILABLE:
        this.store.dispatch(displayActions.newVersionAvailable(event.data.version));
        break;
      case SseEventTypeEnum.RPC_FIRMWARE_AVAILABLE:
        this.store.dispatch(displayActions.newFirmwareAvailable(event.data.version));
        break;
      case SseEventTypeEnum.BACKEND_STATE:
        this.store.dispatch(displayActions.backendConnection(event.data));
        break;
      case SseEventTypeEnum.CONTROLLER_VERSION:
        this.store.dispatch(displayActions.controllerVersion(event.data));
        break;
      case SseEventTypeEnum.RPC_NOTIFICATION:
        this.store.dispatch(
          setFlashMessage({
            flashMessage: {
              flashType: event.data.type,
              message: event.data.msg,
            },
          }),
        );
        break;
      default:
        console.error(`${AppDisplayClientSseServiceHandler.name}: Unsupported event type`, event);
        break;
    }
  }

  async handleConnectionRequest(event: SSEEventConnectionRequest): Promise<void> {
    const cloudConnection: ICloudConnectionRequest = await firstValueFrom(this.store.select(selectCloudConnectionRequest));

    if (!cloudConnection?.inProgress && !cloudConnection?.isConnected) {
      this.store.dispatch(setCloudConnectionRequest({
        farm: event.data.farm,
        email: event.data.email,
        showPopup: true,
      }));

    }
  }
}
