import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet, NavigationEnd } from '@angular/router';
import { firstValueFrom, Observable, Subscription, filter, switchMap, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { IconsEnum, StorageItem } from '@livestock/shared/enums';
import { FarmsListComponent } from '../../../../farms/src/lib/components/farms-list/farms-list.component';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { SvgIconComponent } from '@livestock/ui';
import { TopMenuComponent } from '@livestock/menu';
import { selectActiveFarmID, getFarmById, getFarmControllers, selectFarmControllerByID } from '@livestock/farms';
import {
  ActivatedRouteService,
  MenuService,
  PlatformService,
} from '@livestock/shared/services';
import { AuthService } from '@livestock/auth';
import {
  CurrentUserInfoComponent,
  selectCurrentUserInitials,
  selectUserMenuIsActive,
  toggleUserMenu,
} from '@livestock/current-user';
import { selectActiveControllerID, selectedControllerIsActive } from '@livestock/controllers';
import { LocalStorageService } from '@livestock/shared/services';
import { MobileViewModeEnum, setMobileViewMode } from '@livestock/layout';
import { AppRoutes } from '@livestock/shared/routes';

@Component({
  selector: 'lv-layout',
  standalone: true,
  imports: [
    CommonModule,
    FarmsListComponent,
    MemoizeFuncPipe,
    RouterOutlet,
    SvgIconComponent,
    TopMenuComponent,
    CurrentUserInfoComponent,
    TranslateModule,
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  sub$ = new Subscription();
  activeFarmID$: Observable<number> = this.store.select(selectActiveFarmID);
  currentUserInitials$: Observable<string> = this.store.select(selectCurrentUserInitials);
  userMenuIsActive$: Observable<boolean> = this.store.select(selectUserMenuIsActive);
  selectedControllerIsActive$: Observable<boolean> = this.store.select(selectedControllerIsActive);
  activeControllerID$: Observable<number> = this.store.select(selectActiveControllerID);
  isOnline$: Observable<boolean> = this.activeControllerID$.pipe(
    switchMap((id: number) =>
      this.store.select(selectFarmControllerByID(id)).pipe(
        map((controller) => controller?.connectionStatus),
      ),
    ),
  );

  IconsEnum: typeof IconsEnum = IconsEnum;

  constructor(
    public router: Router,
    public menuService: MenuService,
    public activatedRoute: ActivatedRoute,
    public store: Store,
    public authService: AuthService,
    public activatedRouteService: ActivatedRouteService,
    public platformService: PlatformService,
  ) {
  }

  ngOnInit(): void {
    this.getFarmControllersInfo(this.router.url);
    /*activatedRoute not working outside router-outlet*/
    this.sub$.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe((route) => {
        if (route['url'].includes(AppRoutes.CREATE_FARM) || route['url'].includes(AppRoutes.SCANNED_LOGIN) || route['url'].includes('controller')) {
          this.store.dispatch(setMobileViewMode({ mobileViewMode: MobileViewModeEnum.Lobby }));
        }

        if (route['url'].includes(AppRoutes.FARMS)) {
          this.store.dispatch(setMobileViewMode({ mobileViewMode: MobileViewModeEnum.Farms }));
        }

        this.getFarmControllersInfo(route['url']);
      }),
    );
  }

  getFarmControllersInfo(currentUrl: string): void {
    /* /farm/999/.... => 999 */
    const isFarmRoute = currentUrl.includes('/farm/');
    const farmID = (isFarmRoute && currentUrl.split('/')[2]) || LocalStorageService.getStorageItem(StorageItem.ActiveFarmID);

    if (farmID && this.activatedRouteService.canShowLobby(currentUrl)) {
      this.store.dispatch(getFarmById({ farmID: +farmID }));
      this.store.dispatch(getFarmControllers({ farmID: +farmID }));
    }
  }

  async toggleUserMenu(): Promise<void> {
    const userMenuIsActive = await firstValueFrom(this.userMenuIsActive$);
    this.store.dispatch(toggleUserMenu({ userMenuIsActive: !userMenuIsActive }));
  }

  goToMainPage(): void {
    this.router.navigate(['/']);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
