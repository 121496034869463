export enum ButtonTypeEnum {
  PRIMARY = 'primary',
  BLACK = 'black',
  SECONDARY = 'secondary',
  SECONDARY_GREEN = 'secondary_green',
  DISABLED = 'disabled',
  DISABLED_TRANSPARENT = 'disabled_transparent',
  LINK = 'link',
  ALERT = 'alert',
  WARNING = 'warning',
}
