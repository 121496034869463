import {
  ControllerMenuPagesEnum,
  IControllerPageItem,
} from '@livestock/menu';
import { IconsEnum } from '@livestock/shared/enums';

export const logsReportsSectionPages: IControllerPageItem[] = [
  {
    id: ControllerMenuPagesEnum.AlarmSettings,
    title: 'ControllerMenu.Pages.AlarmSettings',
    icon: IconsEnum.ALARM_SETTINGS,
    route: null,
    qaTags: 'qa-mnu-alarmSettings',
  },
  {
    id: ControllerMenuPagesEnum.GeneralLogs,
    title: 'ControllerMenu.Pages.GeneralLogs',
    icon: IconsEnum.GENERAL_LOGS,
    route: null,
    qaTags: 'qa-mnu-generalLogs',
  },
  {
    id: ControllerMenuPagesEnum.DataLogs,
    title: 'ControllerMenu.Pages.DataLogs',
    icon: IconsEnum.DATA_LOGS,
    route: null,
    qaTags: 'qa-mnu-dataLogs',
  },
  {
    id: ControllerMenuPagesEnum.Reports,
    title: 'ControllerMenu.Pages.Reports',
    icon: IconsEnum.REPORTS,
    route: null,
    qaTags: 'qa-mnu-reports',
  },
];
