import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { InputTextComponent } from '../input-text/input-text.component';
import { LanguageService } from '@livestock/shared/services';

@Component({
  selector: 'ls-search',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SvgIconComponent, InputTextComponent],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true,
    },
  ],
})
export class SearchComponent implements ControlValueAccessor, OnInit {

  @Input() placeholder = 'Search';
  @Input() showCloseBtn = false;
  @Input() fieldWithKeyboard = true;
  @Input() style: 'light' | 'dark' = 'dark';
  @Output() clearSearch = new EventEmitter();
  @ViewChild('input') content: InputTextComponent;

  defaultControl = new FormControl();

  private _value;

  get value(): any {
    return this._value;
  }

  @Input()
  set value(val) {
    this._value = val;
    this.onChange(this._value);
  };

  onChange: any = (): void => {
  };

  onTouch: any = () => {
  };

  constructor(
    public languageService: LanguageService,
  ) { }

  ngOnInit(): void {
    this.defaultControl.valueChanges.subscribe(i => {
      this.value = i;
    });
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  clear(): void {
    this.writeValue(null);
    this.content.inputRef.nativeElement.value = '';
    this.clearSearch.emit();
  }
}
