import { AbstractControl, ValidatorFn } from '@angular/forms';

export const notInListValidator = (list: (number | string)[]): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const { value } = control;

    if (list.includes(value)) {
      return {
        valueIsAlreadyTaken: true,
      };
    }

    return null;
  };
};
