import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { SlimButtonComponent } from '@livestock/ui';
import { ButtonTypeEnum } from '@livestock/shared/enums';

@Component({
  selector: 'ls-connection-request-dialog',
  imports: [
    CommonModule,
    TranslateModule,
    QaTagsDirective,
    MemoizeFuncPipe,
    SlimButtonComponent,
  ],
  templateUrl: './connection-request-dialog.component.html',
  styleUrls: ['./connection-request-dialog.component.scss'],
  standalone: true,
})
export class ConnectionRequestDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConnectionRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { farm: string; email: string; },
  ) {
  }

  ButtonTypeEnum = ButtonTypeEnum;

  close(status: boolean): void {
    this.dialogRef.close(status);
  }
}
