export const ControllerRoutes = {
  Lobby: 'lobby',
  UnitsSettings: 'units-settings',
  HouseSettings: 'house-settings',
  NetworkSettings: 'network-settings',
  CloudSettings: 'cloud-settings',
  DateTimeSettings: 'date-time-settings',
  HouseModeSettings: 'house-mode-settings',
  Installation: 'installation',
  Flock: 'flock',
  FlockSettings: 'flock-settings',
  FlockWeight: 'flock-settings-weight',
  FlockChart: 'flock-settings-chart',
};
