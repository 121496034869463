<div
  *ngIf="isLoading"
  class="loading">
  <div class="loading__background"></div>
  <lv-loading-galcon />
  <span
    *ngIf="settings?.connectionInProgress"
    class="mt-18 mono-dark fz-24">
    {{ "Controller.NetworkSettings.ConnectingToNetwork" | translate }}
  </span>
</div>

<div class="d-flex flex-row">
  <div
    [formGroup]="form"
    class="menu d-flex flex-row gap-20">
    <section class="d-flex flex-column gap-20 titles">
      <div
        (click)="menuClicked('lan')"
        [qaTags]="'qa-menu-lan'"
        [ngClass]="{ 'form-field__active connect connect-separator': menuItem === 'lan' && form.value?.[menuItem], disabled: isDisabled }"
        class="form-field pointer justify-unset">
        <div
          *ngIf="menuItem === 'lan'"
          class="form-field__title">
          {{ "Controller.NetworkSettings.Ethernet" | translate }}
        </div>
        <div class="form-field__value d-flex flex-row justify-between">
          <div class="d-flex flex-row align-center">
            <ng-container *ngIf="!form.controls['lanIp'].value || lanError">
              <ls-svg-icon
                *ngIf="lanError"
                [path]="IconsEnum.NETWORK_LAN_NOT_CONNECTED"
                class="mr-8" />
              <span *ngIf="!(form.value.lan && menuItem === 'lan')">{{
                "Controller.NetworkSettings.Ethernet" | translate
              }}</span>
            </ng-container>
            <ng-container *ngIf="form.controls['lan'].value && form.controls['lanIp'].value">
              <ls-svg-icon
                [path]="IconsEnum.NETWORK_IP"
                class="mr-8" />
              {{ form.controls["lanIp"].value }}
            </ng-container>
          </div>
          <lv-toggle-new formControlName="lan"> </lv-toggle-new>
        </div>
      </div>

      <div
        (click)="menuClicked('wifi')"
        [qaTags]="'qa-menu-wifi'"
        [ngClass]="{ 'form-field__active connect connect-separator': menuItem === 'wifi' && (form.value?.[menuItem] || settings?.wifiLoading), disabled: isDisabled }"
        class="form-field pointer justify-unset">
        <div
          *ngIf="menuItem === 'wifi'"
          class="form-field__title">
          {{ "Controller.NetworkSettings.WifiNetwork" | translate }}
        </div>
        <div class="form-field__value d-flex flex-row justify-between">
          <div class="d-flex flex-row align-center">
            <ng-container *ngIf="!(settings?.wifiIp || selectedWifi)">
              <span *ngIf="!(form.value.wifi && menuItem === 'wifi')">{{
                "Controller.NetworkSettings.Wifi" | translate
              }}</span>
            </ng-container>
            <ng-container *ngIf="settings?.wifiIp || selectedWifi">
              <span class="d-flex gap-8 align-center">
                <ls-svg-icon
                  *ngIf="selectedWifi || settings?.wifiIp"
                  [path]="
                    wifiError
                      ? IconsEnum.NETWORK_WIFI_NOT_CONNECTED
                      : settings?.wifiIp && !(changeWifiNetwork && selectedWifi)
                      ? (settings?.wifiSignalLevel | memoizeFunc : getSignalLevelIcon.bind(this))
                      : IconsEnum.NETWORK_WIFI_SECURED
                  " />
                <span *ngIf="!changeWifiNetwork">
                  {{ form.value.wifiName || selectedWifi?.name || "Controller.NetworkSettings.Wifi" | translate }}
                </span>
                <span *ngIf="changeWifiNetwork">
                  {{
                    (selectedWifi ? selectedWifi?.name : form.value.wifiName) || "Controller.NetworkSettings.Wifi"
                      | translate
                  }}
                </span>
              </span>
            </ng-container>
          </div>
          <lv-toggle-new formControlName="wifi"></lv-toggle-new>
        </div>
      </div>

      <div
        (click)="menuClicked('modem')"
        [qaTags]="'qa-menu-modem'"
        [ngClass]="{ 'form-field__active connect connect-separator': menuItem === 'modem' && form.value?.[menuItem], disabled: isDisabled }"
        class="form-field pointer justify-unset">
        <div
          *ngIf="menuItem === 'modem'"
          class="form-field__title">
          {{ "Controller.NetworkSettings.CellularNetwork" | translate }}
        </div>
        <div class="form-field__value d-flex flex-row justify-between">
          <div class="d-flex flex-row align-center">
            <span
              *ngIf="!(form.value.modem && menuItem === 'modem') && !settings?.modemIp"
              class="d-flex">
              <ls-svg-icon
                *ngIf="modemError"
                [path]="IconsEnum.NETWORK_CELLULAR_NO_CONNECTION"
                class="mr-8" />
              {{ "Controller.NetworkSettings.Cellular" | translate }}
            </span>

            <span
              *ngIf="settings?.modemIp"
              class="d-flex">
              <ls-svg-icon
                [path]="getCellularIcon(settings?.modemSignal)"
                class="mr-8" />
              {{ settings?.modemName }}
            </span>

            <span
              *ngIf="modemError && menuItem === 'modem'"
              class="d-flex">
              <ls-svg-icon
                [path]="IconsEnum.NETWORK_CELLULAR_NO_CONNECTION"
                class="mr-8" />
            </span>
          </div>
          <lv-toggle-new formControlName="modem"></lv-toggle-new>
        </div>
      </div>
      <div
        *ngIf="form.value?.wifi + form.value?.lan + form.value?.modem > 1"
        class="disclaimer d-flex flex-row gap-10">
        <ls-svg-icon [path]="IconsEnum.WARNING" />
        <span class="fz-14 mono-dark"> {{ "Controller.NetworkSettings.Priority" | translate }} </span>
      </div>
    </section>
    <section
      *ngIf="menuItem && (settings.wifiLoading || form.value?.[menuItem])"
      [@fadeInOut]
      class="d-flex flex-column gap-20 p-15 settings {{ menuItem }}"
      [ngClass]="{
        short: selectedWifi && (!settings?.wifiIp || changeWifiNetwork),
        padded: settings?.wifiIp && !changeWifiNetwork,
        'modem-settings': !settings?.modemIp
      }">
      <div
        *ngIf="menuItem === 'lan'"
        [@fadeInOut]
        class="d-flex flex-column pt-28 pb-28">
        <div class="ethernet-block">
          <div class="ethernet-block__title">
            {{ "Controller.NetworkSettings.IPAddress" | translate }}
          </div>
          <div class="ethernet-block__value">
            {{ form.controls["lanIp"].value }}
          </div>
        </div>
        <div class="ethernet-block mt-18">
          <div class="ethernet-block__title">
            {{ "Controller.NetworkSettings.MAC" | translate }}
          </div>
          <div class="ethernet-block__value">
            {{ form.controls["lanMac"].value }}
          </div>
        </div>
      </div>

      <div
        *ngIf="menuItem === 'wifi'"
        [@fadeInOut]
        class="d-flex flex-column gap-18 mono-dark">
        <span
          *ngIf="!selectedWifi && !settings?.wifiIp"
          class="container-title">
          {{ "Controller.NetworkSettings.AvailableNetworks" | translate }}
        </span>

        <ng-container *ngIf="!settings?.wifiIp || changeWifiNetwork">
          <div
            *ngIf="settings.wifiLoading"
            class="wifi-block__loading">
            <span>
              <lv-loading-galcon [size]="80" />
            </span>
            <span class="mt-18">{{ "Controller.NetworkSettings.SearchingForAvailableNetworks" | translate }}</span>
          </div>
          <div
            *ngIf="!selectedWifi && !settings.wifiLoading"
            class="wifi-block__list">
            <lv-radio-list
              (change)="wifiSelected($event)"
              [list]="wifiConnections"
              direction="vertical" />
          </div>

          <div
            *ngIf="selectedWifi"
            class="mt-10 position-relative">
            <lv-input
              (inputFocus)="password['focused'] = true"
              (inputBlur)="password['focused'] = false"
              #password
              [formControl]="passwordControl"
              [label]="'Auth.Login.Password'"
              [qaTags]="'qa-txt-password'"
              [type]="passwordInputToggle ? 'text' : 'password'"
              [autocomplete]="'new-password'"
              class="d-block">
              <ls-svg-icon
                [color]="
                  passwordControl.hasError('wrongPassword')
                    ? ColorsEnum.MainRed
                    : !!password['focused']
                    ? ColorsEnum.MainBlue
                    : ColorsEnum.MonoDark
                "
                [path]="IconsEnum.PASSWORD"
                leftIcon>
              </ls-svg-icon>
              <ls-svg-icon
                (click)="passwordInputToggle = !passwordInputToggle"
                [path]="passwordInputToggle ? IconsEnum.EYE_CLOSED : IconsEnum.EYE"
                class="pointer"
                rightIcon>
              </ls-svg-icon>
              <ng-container description>
                <div
                  *ngIf="passwordControl.hasError('wrongPassword')"
                  class="fz-12 fw-600 w-mc color-error">
                  {{ "FormErrors.MakeSureThePasswordIsCorrect" | translate }}
                </div>
              </ng-container>
            </lv-input>

            <div class="d-flex align-center mt-18">
              <ls-svg-icon
                (click)="onWifiNetworkChange(false)"
                [path]="IconsEnum.NETWORK_CHANGE_CONNECTION"
                class="mr-8" />
              <span
                (click)="onWifiNetworkChange(false)"
                class="fz-12 mono-dark"
                >{{ "Controller.NetworkSettings.ChangeNetwork" | translate }}</span
              >
              <lv-button
                (click)="connectToWifi()"
                [class.pointer-events-none]="!selectedWifi?.password?.length"
                [type]="selectedWifi?.password?.length ? ButtonTypeEnum.PRIMARY : ButtonTypeEnum.DISABLED"
                class="w-97 ml-auto">
                {{ "Controller.NetworkSettings.Connect" | translate }}
              </lv-button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="settings?.wifiIp && !changeWifiNetwork">
          <div
            *ngIf="settings.wifiLoading"
            class="wifi-block__loading">
            <lv-loading-galcon
              [size]="80"
              class="mb-18" />
          </div>
          <ng-container *ngIf="!settings.wifiLoading">
            <div class="wifi-block mt-28">
              <div class="wifi-block__title">
                {{ "Controller.NetworkSettings.IPAddress" | translate }}
              </div>
              <div class="wifi-block__value">
                {{ settings?.wifiIp }}
              </div>
            </div>
            <div class="wifi-block">
              <div class="wifi-block__title">
                {{ "Controller.NetworkSettings.MAC" | translate }}
              </div>
              <div class="wifi-block__value">
                {{ settings?.wifiMac }}
              </div>
            </div>
            <div
              (click)="onWifiNetworkChange()"
              class="d-flex gap-8 ml-auto align-center">
              <ls-svg-icon
                [path]="IconsEnum.NETWORK_CHANGE_CONNECTION"
                class="mr-8" />
              <span class="fz-12 mono-dark">{{ "Controller.NetworkSettings.ChangeNetwork" | translate }}</span>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div
        *ngIf="menuItem === 'modem'"
        [@fadeInOut]
        class="d-flex flex-column gap-18 mono-dark">
        <div
          *ngIf="settings?.cellularLoading"
          class="modem-block__loading">
          <lv-loading-galcon
            [size]="80"
            class="mb-18" />
        </div>
        <ng-container *ngIf="settings?.modemIp != null && !settings?.cellularLoading && !changeCellularNetwork">
          <ng-container *ngIf="!settings.cellularLoading">
            <div class="modem-block mt-10">
              <div class="modem-block__title">
                {{ "Controller.NetworkSettings.IPAddress" | translate }}
              </div>
              <div class="modem-block__value">
                {{ settings?.modemIp }}
              </div>
            </div>
            <div
              (click)="disconnectModem()"
              class="d-flex gap-8 align-center">
              <ls-svg-icon
                [path]="IconsEnum.NETWORK_CELLULAR_SETTINGS"
                class="mr-8" />
              <span class="fz-12 mono-dark">{{ "Controller.NetworkSettings.NetworkSettings" | translate }}</span>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(settings?.modemIp == null || changeCellularNetwork) && !settings?.cellularLoading">
          <lv-input
            (inputFocus)="modemApn['focused'] = true"
            (inputBlur)="modemApn['focused'] = false"
            #modemApn
            [formControl]="modemApnControl"
            [label]="'Controller.NetworkSettings.ModemApn'"
            [qaTags]="'qa-txt-modemApn'"
            [type]="'text'"
            [autocomplete]="'off'"
            [ngClass]="{ 'label-bg-white': !modemApn['focused'], 'bg-f9': modemApn['focused'] }"
            class="d-block full-width">
            <ls-svg-icon
              [path]="IconsEnum.NETWORK_CELLULAR_MODEM"
              class="mr-8"
              leftIcon>
            </ls-svg-icon>
          </lv-input>
          <lv-input
            (inputFocus)="modemUser['focused'] = true"
            (inputBlur)="modemUser['focused'] = false"
            #modemUser
            [formControl]="modemUserControl"
            [label]="'Controller.NetworkSettings.ModemUser'"
            [qaTags]="'qa-txt-modemUser'"
            [type]="'text'"
            [autocomplete]="'nope'"
            [ngClass]="{ 'label-bg-white': !modemUser['focused'], 'bg-f9': modemUser['focused'] }"
            class="d-block full-width">
            <ls-svg-icon
              [path]="IconsEnum.USER"
              class="mr-8"
              leftIcon>
            </ls-svg-icon>
          </lv-input>
          <lv-input
            (inputFocus)="modemPassword['focused'] = true"
            (inputBlur)="modemPassword['focused'] = false"
            #modemPassword
            [formControl]="modemPasswordControl"
            [label]="'Auth.Login.Password'"
            [qaTags]="'qa-txt-password'"
            [type]="modemPasswordInputToggle ? 'text' : 'password'"
            [autocomplete]="'nope'"
            [ngClass]="{ 'label-bg-white': !modemPassword['focused'], 'bg-f9': modemPassword['focused'] }"
            class="d-block full-width">
            <ls-svg-icon
              [color]="
                [modemPasswordControl.hasError('wrongPassword'), !!modemPassword['focused']]
                  | memoizeFunc : getPasswordIconColor.bind(this)
              "
              [path]="IconsEnum.PASSWORD"
              leftIcon>
            </ls-svg-icon>
            <ls-svg-icon
              (click)="modemPasswordInputToggle = !modemPasswordInputToggle"
              [path]="modemPasswordInputToggle ? IconsEnum.EYE_CLOSED : IconsEnum.EYE"
              class="pointer"
              rightIcon>
            </ls-svg-icon>
            <ng-container description>
              <div
                *ngIf="modemPasswordControl.hasError('wrongPassword')"
                class="fz-12 fw-600 w-mc color-error">
                {{ "FormErrors.MakeSureThePasswordIsCorrect" | translate }}
              </div>
            </ng-container>
          </lv-input>
          <lv-button
            (click)="connectModem()"
            [type]="!modemApnControl.value?.length ? ButtonTypeEnum.DISABLED : ButtonTypeEnum.PRIMARY"
            [class.disabled]="!modemApnControl.value?.length"
            class="w-97 ml-auto">
            {{ "Controller.NetworkSettings.Connect" | translate }}
          </lv-button>
        </ng-container>
      </div>
    </section>
  </div>
</div>
