import { Route } from '@angular/router';
import { AppRoutes } from '@livestock/shared/routes';
import {
  UnitsSettingsComponent,
  CloudSettingsComponent,
  controllerResolver,
  DateTimeSettingsComponent,
  generalSettingsOnlyResolver,
  HouseSettingsComponent,
  NetworkSettingsComponent,
} from '@livestock/controllers';
import { ControllerRoutes } from '../../../../libs/controllers/src/lib/constants/controller.routes';
import { UnsavedChangesGuard } from '@livestock/forms';
import { LobbyComponent } from '@livestock/lobby';
import { InstallationComponent } from '@livestock/installation';
import { FlockChartComponent, FlockSettingsComponent, FlockWeightComponent } from '@livestock/flock';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () =>
      import('@livestock/controllers').then(
        com => com.QuickStartWebComponent,
      ),
  },
  {
    path: AppRoutes.DASHBOARD,
    loadComponent: () =>
      import('@livestock/dashboard').then(
        (com) => com.DashboardComponent,
      ),
    resolve: { isLoaded: controllerResolver },
  },
  {
    path: AppRoutes.LANDING,
    loadComponent: () =>
      import('@livestock/landing').then(
        com => com.LandingPageComponent,
      ),
  },
  {
    path: AppRoutes.CONTROLLER_ID,
    resolve: { isLoaded: controllerResolver },
    children: [
      {
        path: ControllerRoutes.Lobby,
        component: LobbyComponent,
      },
      {
        path: ControllerRoutes.UnitsSettings,
        component: UnitsSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: ControllerRoutes.HouseSettings,
        component: HouseSettingsComponent,
        resolve: { isLoaded: generalSettingsOnlyResolver },
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: ControllerRoutes.NetworkSettings,
        component: NetworkSettingsComponent,
      },
      {
        path: ControllerRoutes.CloudSettings,
        component: CloudSettingsComponent,
      },
      {
        path: ControllerRoutes.DateTimeSettings,
        component: DateTimeSettingsComponent,
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: ControllerRoutes.Installation,
        component: InstallationComponent,
      },
      {
        path: ControllerRoutes.Flock,
        children: [
          {
            path: ControllerRoutes.FlockSettings,
            component: FlockSettingsComponent,
            canDeactivate: [UnsavedChangesGuard],
          },
          {
            path: ControllerRoutes.FlockWeight,
            component: FlockWeightComponent,
            canDeactivate: [UnsavedChangesGuard],
          },
          {
            path: ControllerRoutes.FlockChart,
            component: FlockChartComponent,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    loadComponent: () => import('@livestock/ui').then(com => com.Page404Component),
  },
];
