import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { ICreateFarm } from '../interfaces/create-farm.interface';
import { IFarmInfo } from '../interfaces/farm-info.interface';
import { IGetControllers } from '../interfaces/get-controllers.interface';
import { IGetFarms } from '../interfaces/get-farms.interface';
import { IUpdateFarm } from '../interfaces/update-farm.interface';
import { IGetUsers, IInviteUser, IUser } from '@livestock/shared/interfaces';
import { IController } from '../interfaces/controller.interface';
import {
  ICreateControllerByConnectionNumberView,
} from '../../../../controllers/src/lib/interfaces/controller/create-controller-by-connection-number-view.interface';

const baseUrl = environment.apiUrl;
const farmUrl = `${baseUrl}/farm`;

@Injectable({
  providedIn: 'root',
})
export class FarmsService {
  constructor(private http: HttpClient) {
  }

  getFarms(): Observable<IGetFarms> {
    return this.http.get<IGetFarms>(farmUrl);
  }

  getFarmById(farmID: number): Observable<IFarmInfo> {
    return this.http.get<IFarmInfo>(`${farmUrl}/${farmID}`);
  }

  createFarm(farm: ICreateFarm): Observable<IFarmInfo> {
    return this.http.post<IFarmInfo>(farmUrl, farm);
  }

  updateFarmById(farmID: number, farm: IUpdateFarm): Observable<IFarmInfo> {
    return this.http.put<IFarmInfo>(`${farmUrl}/${farmID}`, farm);
  }

  deleteFarmById(farmID: number): Observable<boolean> {
    return this.http.delete<boolean>(`${farmUrl}/${farmID}`);
  }

  getFarmControllers(farmID: number): Observable<IGetControllers> {
    return this.http.get<IGetControllers>(`${farmUrl}/${farmID}/controller`);
  }

  createControllerByConnectionNumber(farmID: number, requestView: ICreateControllerByConnectionNumberView): Observable<IController> {
    return this.http.post<IController>(`${farmUrl}/${farmID}/controller`, requestView);
  }

  getFarmUsers(farmID: number): Observable<IGetUsers> {
    return this.http.get<IGetUsers>(`${farmUrl}/${farmID}/user`);
  }

  inviteFarmUser(farmID: number, view: IInviteUser): Observable<IUser> {
    return this.http.post<IUser>(`${farmUrl}/${farmID}/user/invite`, view);
  }

  resendInviteFarmUser(farmID: number, ticketID: string, view: IInviteUser): Observable<IUser> {
    return this.http.post<IUser>(`${farmUrl}/${farmID}/user/invite/resend/${ticketID}`, view);
  }

  deleteFarmUser(farmID: number, email: string): Observable<boolean> {
    return this.http.delete<boolean>(`${farmUrl}/${farmID}/user/email/${email}`);
  }

  updateUser(farmID: number, user: IUser): Observable<boolean> {
    return this.http.put<boolean>(
      `${farmUrl}/${farmID}/user/${user.userID}`,
      user,
    );
  }
}
