export enum AmPmEnum {
  AM,
  PM,
}

export namespace AmPmEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case AmPmEnum.AM:
        return 'Controller.DateTimeSettings.HoursFormatTypeEnum.AM';
      case AmPmEnum.PM:
        return 'Controller.DateTimeSettings.HoursFormatTypeEnum.PM';
      default:
        return value.toString();
    }
  }
}
