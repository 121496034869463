import { IEditUnitModeObj } from '../../interfaces';

export enum WaterMainPressureUnitEnum {
  Bar,
  PSI,
}

export namespace WaterMainPressureUnitEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case WaterMainPressureUnitEnum.Bar:
        return 'Controller.GeneralSettings.CustomUnitsDialog.WaterMainPressureEnum.Bar';
      case WaterMainPressureUnitEnum.PSI:
        return 'Controller.GeneralSettings.CustomUnitsDialog.WaterMainPressureEnum.PSI';
      default:
        return value.toString();
    }
  }

  export function isMetricUnit(value: WaterMainPressureUnitEnum): boolean {
    return value === WaterMainPressureUnitEnum.Bar;
  }

  export function toEditUnitModeObj(): IEditUnitModeObj {
    return {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.WaterMainPressure',
      controlName: 'waterMainPressure',
      enumName: WaterMainPressureUnitEnum,
      enumStr: 'water-main-pressure-unit-enum',
    };
  }
}
