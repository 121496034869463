import { inject } from '@angular/core';
import { Observable, of, switchMap, tap } from 'rxjs';

import { Store } from '@ngrx/store';
import { selectIsFormEditing, FormsService, setIsFormEditing } from '@livestock/forms';

export const UnsavedChangesGuard = (): Observable<boolean> => {
  const store: Store = inject(Store);
  const formService: FormsService = inject(FormsService);

  return store.select(selectIsFormEditing).pipe(
    switchMap(isEditing => isEditing ? formService.confirmFormLeaving().pipe(
      tap(confirm => {
        if (confirm) {
          store.dispatch(setIsFormEditing({ isEditing: false }));
        }
      }),
    ) : of(true)),
  );
};
