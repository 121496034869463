import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@livestock/ui';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { ControllerMenuComponent, TopMenuComponent } from '@livestock/menu';

@Component({
  standalone: true,
  selector: 'ls-display-top-menu',
  templateUrl: 'display-top-menu.component.html',
  imports: [
    RouterLink,
    CommonModule,
    SvgIconComponent,
    QaTagsDirective,
    MemoizeFuncPipe,
    TranslateModule,
    ControllerMenuComponent,
  ],
  styleUrls: ['display-top-menu.component.scss'],
})
export class DisplayTopMenuComponent extends TopMenuComponent {
}
