import { Injectable } from '@angular/core';
import { environment } from '../../../../shared/environments';
import { BehaviorSubject, bufferTime, fromEvent } from 'rxjs';
import { WebSocketsConstants } from '../constants/web-sockets.constants';
import * as webSocketActions from '../+state/web-sockets.actions';
import { Store } from '@ngrx/store';
import { IWebSocketResponse } from '../interfaces/web-socket-response.interface';

const WS_CONNECTION_MAX_RETRIES = 5;

@Injectable({ providedIn: 'root' })
export class WebSocketsService {
  private ws: WebSocket;
  events$: BehaviorSubject<any> = new BehaviorSubject(null);
  isActive: boolean = false;
  _lastMessageTimestamp = 0;
  _lastPingSentTimestamp = 0;
  _connectionRetryCounter = WS_CONNECTION_MAX_RETRIES;

  constructor(
    private store: Store,
  ) {
  }

  async connect(): Promise<void> {
    if (this._connectionRetryCounter < 0) {
      console.error('MAX WS CONNECTION RETRIES REACHED');
      return;
    }
    this._connectionRetryCounter -= 1;
    this.ws = new WebSocket(environment.wssUrl);
    if (this.ws == null) return;
    this.ws.onclose = (ev) => {
      console.log(`wss close:`, ev);
      this.ws = null;
      setTimeout(() => {
        console.log(`WS CONNECTION RETRIES LEFT: ${this._connectionRetryCounter}`);
        this.connect();
      }, 500);
    };
    this.ws.onerror = (ev) => {
      console.error(`wss error:`, ev);
      return Promise.reject(ev);
    };
    this.ws.onopen = () => {
      console.log('ws connected');
      this._connectionRetryCounter = WS_CONNECTION_MAX_RETRIES;
      this.ws.onmessage = (event) => {
        this._lastMessageTimestamp = Date.now();
        this.events$.next(event);
        return Promise.resolve(true);
        // console.log(event);
      };

      this.onEvents();

      // this.roomsJoin(['LIVESTOCK_001', 'GALILEO_210547', 'GALILEO_210551']);
      // fromEvent(this.ws, 'message').subscribe((x: MessageEvent) => {
      //   console.log(33, JSON.parse(x.data));
      // });
      // this.pingLoop();
    };
  }

  sendMessage(value: string): void {
    if (this.ws === null) {
      console.error('websocket is not connected!');
    }
    if (this.ws?.readyState !== WebSocket.OPEN) {
      setTimeout(() => this.sendMessage(value), 100);
      return;
    }
    this.ws.send(value);
  }

  roomsJoin(rooms: string[]): void {
    console.log('roomsJoin', this.ws?.readyState);
    this.sendMessage(`join:${rooms.join(',')}`);
  }

  roomsLeft(rooms: string[]): void {
    console.log('roomsLeft', this.ws?.readyState);
    this.sendMessage(`leave:${rooms.join(',')}`);
  }

  roomsLeaveAll(): void {
    console.log('roomsLeaveAll', this.ws?.readyState);
    this.sendMessage(`leaveall`);
  }

  pingLoop(): void {
    if (Date.now() - this._lastMessageTimestamp > 120000) {
      console.log();
      this.ws.send(`ping`);
      this._lastPingSentTimestamp = Date.now();
    }
    setTimeout(() => this.pingLoop(), 1000);
  }

  private onEvents(): void {
    fromEvent(this.ws, 'message')
      .pipe(
        bufferTime(WebSocketsConstants.WebSocketEventsBufferTime),
      )
      .subscribe({
        next: (response: MessageEvent[]) => {
          if (response.length === 0) return;
          const data = response.map((x) => {
            return JSON.parse(x.data);
          });
          console.log('message: ', data);

          const payload = data
            .filter(ev => !!ev.DataEvent)
            .map(ev => {
              const eventInfoKeys = Object.keys(ev);
              let eventInfoIndex = eventInfoKeys.length;
              const eventInfo = {};
              while (eventInfoIndex--) {
                const key = eventInfoKeys[eventInfoIndex];
                if (key === 'DataEvent') continue;

                const newKey = key.length > 2
                  ? `${key[0].toLowerCase()}${key.slice(1)}`
                  : key.toLowerCase();

                eventInfo[newKey] = ev[key];
              }

              const dataEventKeys = Object.keys(ev.DataEvent);
              let dataEventIndex = dataEventKeys.length;
              const dataEvent = {};
              while (dataEventIndex--) {
                const key = dataEventKeys[dataEventIndex];
                const newKey = key.length > 2
                  ? `${key[0].toLowerCase()}${key.slice(1)}`
                  : key.toLowerCase();

                dataEvent[newKey] = ev.DataEvent[key];
              }

              return {
                ...eventInfo,
                dataEvent,
              };
            });

          this.store.dispatch(webSocketActions.addWebSocketEvents({
            payload: payload as IWebSocketResponse[],
          }));
        },
        error: (error) => console.error(error),
        complete: () => console.warn(`WebSockets completed.`),
      });
  }
}
