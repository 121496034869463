import { inject, Injectable } from '@angular/core';
import { interval, Subscription, distinctUntilChanged } from 'rxjs';
import { GlobalConstants } from '@livestock/shared/constants';
import { Store } from '@ngrx/store';
import { selectActiveControllerID } from '@livestock/controllers';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class RealTimeEventsRecallService {
  private store: Store = inject(Store);
  private platformService: PlatformService = inject(PlatformService);

  private intervalSource = interval(this.platformService.isDeviceApp
    ? GlobalConstants.RecallEventsIntervalDevice
    : GlobalConstants.RecallEventsInterval,
  );
  private intervalSubscription: Subscription;
  private additionalAction: { type: string };
  private secondAdditionalAction: { type: string };

  constructor() {
    this.store.select(selectActiveControllerID).pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    ).subscribe((controllerID: number) => {
      if (controllerID) {
        this.initInterval();
      } else {
        this.clearInterval();
      }
    });
  }

  setAdditionalAction(action: { type: string }): void {
    this.additionalAction = action;
  }

  setSecondAdditionalAction(action: { type: string }): void {
    this.secondAdditionalAction = action;
  }

  clearAdditionalAction(): void {
    this.additionalAction = null;
  }

  clearSecondAdditionalAction(): void {
    this.secondAdditionalAction = null;
  }

  private initInterval(): void {
    this.clearInterval();

    this.intervalSubscription = this.intervalSource.subscribe(async () => {
      this.recallEvents();
    });
  }

  private clearInterval(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  private recallEvents(): void {
    if (this.additionalAction) {
      this.store.dispatch(this.additionalAction);
    }

    if (this.secondAdditionalAction) {
      this.store.dispatch(this.secondAdditionalAction);
    }
  }
}
