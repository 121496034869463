export class StringUtils {
  static insertAt(src: string, onIdx: number, value: string, step: number = 1): string {
    return `${src.substr(0, onIdx)}${value}${src.substr(onIdx + step)}`;
  }

  static areAllWhitespaces(string: string): boolean {
    return string.replace(/\s/g, '').length === 0;
  }

  static getInitials(fullName: string): string {
    const firstLetters = fullName?.match(/\b(\w)/g);
    if (!firstLetters) {
      return '';
    }
    if (firstLetters.length === 1) {
      return `${firstLetters[0].toUpperCase()}`;
    }
    return `${firstLetters[0].toUpperCase()}${firstLetters[firstLetters.length - 1].toUpperCase()}`;
  }
}
