import { IconsQuickStartEnum } from './icons-quick-start.enum';
import { IconsCommonEnum } from './icons-common.enum';
import { IconsNetworkEnum } from './icons-network.enum';
import { IconsHouseEnum } from './icons-house.enum';
import { IconsAuthEnum } from './icons-auth.enum';
import { IconsFlockEnum } from './icons-flock.enum';
import { IconsFarmsEnum } from './icons-farms.enum';
import { IconsMenuEnum } from './icons-menu.enum';
import { IconsConnectionEnum } from './icons-connection.enum';
import { IconsKeyboardEnum } from './icons-keyboard.enum';
import { IconsInstallationEnum } from './icons-installation.enum';

export const IconsEnum = {
  ...IconsQuickStartEnum,
  ...IconsCommonEnum,
  ...IconsNetworkEnum,
  ...IconsHouseEnum,
  ...IconsAuthEnum,
  ...IconsFlockEnum,
  ...IconsFarmsEnum,
  ...IconsMenuEnum,
  ...IconsConnectionEnum,
  ...IconsKeyboardEnum,
  ...IconsInstallationEnum,
};
