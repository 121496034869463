<div
  *ngIf="parentFormGroup.get(formGroupName) as form"
  [formGroupName]="formGroupName">
  <div
    [ngClass]="platformService.isDeviceApp ? 'justify-between' : 'justify-end'"
    class="d-flex align-center w-100-percent h-28">
    <div
      *ngIf="platformService.isDeviceApp"
      (click)="uninstall()"
      class="d-flex align-center pointer">
      <ls-svg-icon [path]="IconsEnum.UNINSTALL_ELEMENT"> </ls-svg-icon>
      <span class="fz-12 fw-500 mh-8">{{ "Installation.Uninstall" | translate }}</span>
    </div>
    <div class="d-flex align-center">
      <ng-container *ngIf="!isTestMode; else testModeTemplate">
        <div
          [ngClass]="elementEvent?.status === OffOnEnum.On ? 'circle__on' : 'circle__off'"
          class="circle mh-4"></div>
        <div class="d-flex">
          <span
            [ngClass]="elementEvent?.status === OffOnEnum.On ? 'color-darkblue' : 'color-black'"
            class="fz-16 fw-600">
            {{
              elementEvent?.status != null
                ? (elementEvent?.status | enum : "off-on-enum" | translate | uppercase)
                : ("Installation.Off" | translate | uppercase)
            }}
          </span>
        </div>
      </ng-container>
      <ng-template #testModeTemplate>
        <ls-svg-icon
          [path]="IconsEnum.TEST_STATUS"
          class="mh-4">
        </ls-svg-icon>
        <span class="fw-600 color-darkgreen">{{ "Installation.Testing" | translate }}</span>
      </ng-template>
    </div>
  </div>

  <!--FORM-->
  <lv-input
    [label]="'Installation.DeviceName'"
    [qaTags]="'qa-txt-device-name'"
    [autocomplete]="'nope'"
    formControlName="deviceName"
    type="text"
    class="full-width mt-28 label-bg-white">
  </lv-input>

  <div class="d-flex justify-between mt-18 gap-8">
    <lv-input-integer-with-label
      (onFocusIn)="minVoltage['focused'] = true"
      (onFocusOut)="minVoltage['focused'] = false"
      #minVoltage
      [min]="InstallationConstants.VoltMin"
      [max]="InstallationConstants.VoltMax"
      [noWrapperPadding]="true"
      [label]="'Installation.MinVolt'"
      class="h-48 align-center"
      formControlName="minVoltage">
    </lv-input-integer-with-label>

    <lv-input-integer-with-label
      (onFocusIn)="maxVoltage['focused'] = true"
      (onFocusOut)="maxVoltage['focused'] = false"
      #maxVoltage
      [min]="InstallationConstants.VoltMin"
      [max]="InstallationConstants.VoltMax"
      [noWrapperPadding]="true"
      [label]="'Installation.MaxVolt'"
      class="h-48 align-center"
      formControlName="maxVoltage">
    </lv-input-integer-with-label>
  </div>

  <span
    *ngIf="errorMessage"
    class="error fz-10">
    {{ errorMessage }}
  </span>

  <lv-button
    *ngIf="!isTestMode"
    (click)="runTest()"
    [type]="!isEditMode ? ButtonTypeEnum.DISABLED : ButtonTypeEnum.SECONDARY_GREEN"
    [class.pointer-events-none]="!isEditMode"
    [height]="56"
    class="w-100-percent mt-18">
    <span>{{ "Installation.RunTest" | translate }}</span>
  </lv-button>

  <div
    *ngIf="isTestMode"
    class="test-stop-btn-with-timer mt-18">
    <div class="timer">
      <span class="fz-32 fw-600">{{ elementEvent?.testTime }}</span>
      <span class="fz-12 mh-1">{{ "Installation.Sec" | translate }}</span>
    </div>
    <div
      (click)="stopTest()"
      class="stop-btn">
      <div class="stop-btn__square"></div>
      <span class="fz-12 mt-4">{{ "Installation.Stop" | translate }}</span>
    </div>
  </div>
</div>
