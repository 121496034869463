export const AppRoutes = {
  AUTH: 'auth',
  LOGIN: 'login',
  VALIDATE_INVITE: 'validate/invite',
  CREATE_FARM: 'create-farm',
  FARM_SETTINGS: 'farm-settings',
  CONTROLLER: 'controller',
  DASHBOARD: 'dashboard',
  FARM: 'farm',
  FARMS: 'farms',
  FARMS_MANAGER: 'farms-manager',
  QUICK_START: 'quick-start',
  SCANNED_LOGIN: 'scanned-login',
  USERS: 'users',
  INSTALLATION: 'components',
  FORGOT_PASSWORD: 'forgot-password',
  LANDING: 'landing',
  CREATE_CONTROLLER: 'create-controller',
  CONTROLLER_ID: 'controller/:controllerID',

  // for dev use, remove for prod
  UI_PLAYGROUND: 'ui-playground',
};
