import { Component, EventEmitter, forwardRef, inject, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '@livestock/shared/services';
import { SvgIconComponent } from '@livestock/ui';
import { ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { EnumPipe, EnumToArrayPipe } from '@livestock/shared/pipes';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@Component({
  selector: 'lv-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, SvgIconComponent, EnumToArrayPipe, EnumPipe, NgClickOutsideDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleDropdownComponent),
      multi: true,
    },
  ],
})
export class SimpleDropdownComponent implements ControlValueAccessor {
  // inject
  languageService = inject(LanguageService);

  @Input({ required: true }) enum: any;
  @Input({ required: true }) enumString: string;
  @Input() label: string;
  @Input() placeholder: string = 'Controls.SelectOption';
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() filterEnumFn: (item: number) => boolean;
  @Input() sortEnum: string[];
  @Input() showTick = false;
  @Input() editMode = false;
  @Output() change = new EventEmitter<number>();

  // vars
  disabled: boolean;
  value: number;

  // enums
  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  selectOption(option: number): void {
    this.writeValue(option);
    this.onChange(option);
    this.change.emit(option);
    this.toggleEditMode();
  }
}

