import { VirtualKeyboardModesEnum } from '@livestock/ui';
import { createAction, props } from '@ngrx/store';
import { IMinMax } from '@livestock/shared/interfaces';
import { AmPmEnum } from '../../../../../../controllers/src/lib/enums/date-time/am-pm.enum';

export const setVirtualKeyboardMode = createAction(
  '[VIRTUAL KEYBOARD] Set Virtual Keyboard Mode',
  props<{ mode: VirtualKeyboardModesEnum }>(),
);

export const setVirtualKeyboardElementUuid = createAction(
  '[VIRTUAL KEYBOARD] Set Virtual Keyboard Element UUID',
  props<{ elementUuid: string, ranges?: IMinMax, labelForRanges?: string }>(),
);

export const setVirtualKeyboardSymbol = createAction(
  '[VIRTUAL KEYBOARD] Set Virtual Keyboard Symbol',
  props<{ symbol: string | number }>(),
);

export const setVirtualKeyboardAMPM = createAction(
  '[VIRTUAL KEYBOARD] Set Virtual Keyboard AM/PM',
  props<{ AMPM: AmPmEnum }>(),
);

export const setVirtualKeyboardAMPMDisabled = createAction(
  '[VIRTUAL KEYBOARD] Set Virtual Keyboard Disabled AM/PM',
  props<{ AMPMDisabled: boolean }>(),
);

export const clearVirtualKeyboardElementUuid = createAction(
  '[VIRTUAL KEYBOARD] Clear Virtual Keyboard Element UUID',
);

export const setVirtualKeyboardRanges = createAction(
  '[VIRTUAL KEYBOARD] Set Virtual Keyboard Ranges',
  props<{ ranges: IMinMax, labelForRanges: string }>(),
);
