import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LvInputDateComponent } from '../controls/lv-input-date/lv-input-date.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LvInputTimeComponent } from '../controls/lv-input-time/lv-input-time.component';
import { SlimButtonComponent } from '../controls/button-slim/button-slim.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import {
  AirFlowUnitEnum,
  ButtonTypeEnum,
  ChickenBrandWeight,
  ColorsEnum,
  FarmUserRolesEnum,
  IconsEnum,
} from '@livestock/shared/enums';
import { GlobalConstants } from '@livestock/shared/constants';
import {
  LVInputIntegerWithLabelComponent,
} from '../controls/input-integer/with-label/lv-input-integer-with-label.component';
import {
  LVInputDecimalWithLabelComponent,
} from '../controls/input-decimal/with-label/lv-input-decimal-with-label.component';
import { SimpleDropdownComponent } from '../controls/simple-dropdown/simple-dropdown.component';
import { LvInputWithSaveBtnComponent } from '../controls/lv-input-with-save-btn/lv-input-with-save-btn.component';
import { DualToggleComponent } from '../controls/dual-toggle/dual-toggle.component';
import { IntegerIncrDecrComponent } from '../controls/integer-incr-decr/integer-incr-decr.component';
import { ArrowsConnectComponent } from '../elements/arrows-connect/arrows-connect.component';
import { ArrowsConnectEnum } from '../enums/arrows-connect.enum';
import { ControllerLanguageEnum } from '@livestock/controllers/enums';
import { RadioListWithLabelComponent } from '@livestock/ui';
import { IRadioListItem } from '../controls/radio-list/radio-list-item.interface';

@Component({
  selector: 'lv-playground',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, LvInputTimeComponent,
    LvInputDateComponent, SlimButtonComponent, SvgIconComponent, LVInputIntegerWithLabelComponent,
    LVInputDecimalWithLabelComponent, SimpleDropdownComponent, LvInputWithSaveBtnComponent,
    DualToggleComponent, IntegerIncrDecrComponent, ArrowsConnectComponent,
    RadioListWithLabelComponent],
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss'],
})
export class PlaygroundComponent {
  form = new FormGroup({
    time: new FormControl<string>(''),
    date: new FormControl<string>(''),
    intLabel: new FormControl<number>(5),
    decLabel: new FormControl<number>(8.1),
    dropdown: new FormControl<ChickenBrandWeight>(ChickenBrandWeight.COBB_500),
    dropdownFlat: new FormControl<FarmUserRolesEnum>(FarmUserRolesEnum.Owner),
    saveBtn: new FormControl<string>('save me'),
    dualToggle: new FormControl<AirFlowUnitEnum>(AirFlowUnitEnum.CubicFeetPerHour),
    role: new FormControl<FarmUserRolesEnum>(FarmUserRolesEnum.Viewer),
  });

  readonly roleList: IRadioListItem[] = [
    {
      value: FarmUserRolesEnum.Viewer,
      title: FarmUserRolesEnum.toTranslateKey(FarmUserRolesEnum.Viewer),
      cssClass: 'fw-500',
    },
    {
      value: FarmUserRolesEnum.Editor,
      title: FarmUserRolesEnum.toTranslateKey(FarmUserRolesEnum.Editor),
    },
    {
      value: FarmUserRolesEnum.Admin,
      title: FarmUserRolesEnum.toTranslateKey(FarmUserRolesEnum.Admin),
    },
    {
      value: FarmUserRolesEnum.Owner,
      title: FarmUserRolesEnum.toTranslateKey(FarmUserRolesEnum.Owner),
    },
  ];

  ColorsEnum = ColorsEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  GlobalConstants = GlobalConstants;
  ChickenBrandWeight = ChickenBrandWeight;
  FarmUserRolesEnum = FarmUserRolesEnum;
  AirFlowUnitEnum = AirFlowUnitEnum;
  ArrowsConnectEnum = ArrowsConnectEnum;

  changeRole(role: ControllerLanguageEnum): void {
    this.roleList.forEach(roleOption => {
      if (roleOption.value === role) {
        roleOption['cssClass'] = 'fw-500 color-darkblue';
      } else {
        roleOption['cssClass'] = undefined;
      }
    });
  }
}
