export enum ControllerMenuSectionsEnum {
  Control,
  Flock,
  LogsReports,
  System,
  BasicSettings,
}

export namespace ControllerMenuSectionsEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case ControllerMenuSectionsEnum.Control:
        return 'ControllerMenu.Sections.Control';
      case ControllerMenuSectionsEnum.Flock:
        return 'ControllerMenu.Sections.Flock';
      case ControllerMenuSectionsEnum.LogsReports:
        return 'ControllerMenu.Sections.LogsReports';
      case ControllerMenuSectionsEnum.System:
        return 'ControllerMenu.Sections.System';
      case ControllerMenuSectionsEnum.BasicSettings:
        return 'ControllerMenu.Sections.BasicSettings';
      default:
        return value.toString();
    }
  }
}
