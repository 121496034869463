import { NetworkConnectionStatusEnum } from '@livestock/controllers/enums';

export interface IWifiSettings {
  wifi: number;
  wifiName: string;
  wifiPassword: string;
  wifiAutoConnect: boolean;
  connectionResult?: NetworkConnectionStatusEnum;
  wifiIp?: string;
  wifiMac?: string;
  wifiSignalLevel?: number;
}
