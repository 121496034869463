import { ControllerRoutes } from '@livestock/controllers/constants';
import {
  ControllerMenuPagesEnum,
  IControllerPageItem,
} from '@livestock/menu';
import { IconsEnum } from '@livestock/shared/enums';

export const systemSectionPages: IControllerPageItem[] = [
  {
    id: ControllerMenuPagesEnum.Users,
    title: 'ControllerMenu.Pages.Users',
    icon: IconsEnum.USERS,
    route: null,
    qaTags: 'qa-mnu-users',
  },
  {
    id: ControllerMenuPagesEnum.Hardware,
    title: 'ControllerMenu.Pages.Hardware',
    icon: IconsEnum.HARDWARE,
    route: ControllerRoutes.Installation,
    qaTags: 'qa-mnu-hardware',
  },
  {
    id: ControllerMenuPagesEnum.TempMapping,
    title: 'ControllerMenu.Pages.TempMapping',
    icon: IconsEnum.TEMP_MAPPING,
    route: null,
    qaTags: 'qa-mnu-tempMapping',
  },
  {
    id: ControllerMenuPagesEnum.SaveRestore,
    title: 'ControllerMenu.Pages.SaveRestore',
    icon: IconsEnum.SAVE_RESTORE,
    route: null,
    qaTags: 'qa-mnu-saveRestore',
  },
  {
    id: ControllerMenuPagesEnum.About,
    title: 'ControllerMenu.Pages.About',
    icon: IconsEnum.ABOUT,
    route: null,
    qaTags: 'qa-mnu-about',
  },
];
