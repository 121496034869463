import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent, ToggleNewComponent, LoadingGalconComponent, RadioListComponent, LVInputComponent, SlimButtonComponent, InputTextComponent } from '@livestock/ui';
import { IModemCredentials, INetworkSettingsView, IWifiConnection } from '@livestock/controllers/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { ButtonTypeEnum, ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { fadeInOut } from '@livestock/shared/animations';
import { IRadioListItem } from 'libs/ui/src/lib/controls/radio-list/radio-list-item.interface';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { Actions } from '@ngrx/effects';
import { Subscription, filter } from 'rxjs';
import { connectModemError, connectModemSuccess, connectWifiError, connectWifiSuccess, disconnectModem, updateLanSettingsError, updateLanSettingsSuccess } from '../../+state/upsert-controller/upsert-controller.actions';

@Component({
  selector: 'ls-network-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SvgIconComponent,
    QaTagsDirective,
    ToggleNewComponent,
    LoadingGalconComponent,
    RadioListComponent,
    MemoizeFuncPipe,
    LVInputComponent,
    SlimButtonComponent,
    InputTextComponent,
  ],
  templateUrl: './network-settings-form.component.html',
  styleUrls: ['./network-settings-form.component.scss'],
  animations: [fadeInOut],
})
export class ControllerNetworkSettingsFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('password') passwordRef: ElementRef;

  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;

  @Input() set networkSettings(networkSettings: INetworkSettingsView) {
    if (networkSettings) {
      this.updateFormValues(networkSettings);
      this.settings = networkSettings;
      this.wifiConnections = this.parseWifiConnections(this.settings?.wifiConnectionsList);
    }
  }
  @Output() changed = new EventEmitter();
  @Output() onConnectToWifi = new EventEmitter<IWifiConnection>();
  @Output() onWifiDisconnect = new EventEmitter<void>();
  @Output() onConnectModem = new EventEmitter<IModemCredentials>();
  @Output() onDisconnectModem = new EventEmitter<any>();
  @Output() onScanWifi = new EventEmitter<void>();

  sub$ = new Subscription();

  //vars
  settings: INetworkSettingsView;
  form = new FormGroup(
    {
      controllerID: new FormControl<number>(null),
      modem: new FormControl(0),
      wifi: new FormControl(0),
      lan: new FormControl(0),
      lanIp: new FormControl(''),
      lanMac: new FormControl(''),
      modemAPN: new FormControl(''),
      modemUser: new FormControl(''),
      modemPassword: new FormControl(''),
      modemIp: new FormControl(''),
      modemMac: new FormControl(''),
      wifiName: new FormControl(''),
      wifiPassword: new FormControl(''),
      wifiAutoConnect: new FormControl<boolean>(false),
    },
  );
  menuItem: 'lan' | 'wifi' | 'modem' = null;
  selectedWifi: IWifiConnection = null;
  passwordInputToggle: boolean = false;
  passwordControl = new FormControl(null);
  modemApnControl = new FormControl(null);
  modemUserControl = new FormControl(null);
  modemPasswordInputToggle: boolean = false;
  modemPasswordControl = new FormControl(null);
  testControl = new FormControl(null);
  lanError = false;
  wifiError = false;
  modemError = false;
  changeWifiNetwork: boolean = false;
  changeCellularNetwork: boolean = false;
  wifiConnections: IRadioListItem[] = [];

  IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;
  ButtonTypeEnum = ButtonTypeEnum;

  constructor(
    public languageService: LanguageService,
    public platformService: PlatformService,
    public actions: Actions,
  ) {
  }

  ngOnInit(): void {
    this.passwordControl.valueChanges.subscribe((i) => this.wifiPasswordChanged(i));

    this.sub$.add(
      this.actions.pipe(
        filter(i => i.type === connectWifiError.type),
      ).subscribe((event) => {
        this.wifiError = true;
        if (((event as any).payload.error === 'WifiWrongPassword')) {
          this.passwordControl.setValue(null);
          this.passwordControl.setErrors({ wrongPassword: true });
        }
      }),
    );

    this.sub$.add(
      this.actions.pipe(
        filter(i => i.type === connectWifiSuccess.type),
      ).subscribe(() => {
        this.changeWifiNetwork = false;
      }),
    );

    this.sub$.add(
      this.actions.pipe(
        filter(i => i.type === connectModemSuccess.type),
      ).subscribe(() => {
        this.changeCellularNetwork = false;
        this.modemApnControl.setValue('');
        this.modemPasswordControl.setValue('');
        this.modemUserControl.setValue('');
        this.modemError = false;
      }),
    );

    this.sub$.add(
      this.actions.pipe(
        filter(i => i.type === connectModemError.type),
      ).subscribe(() => {
        this.modemError = true;
      }),
    );

    this.sub$.add(
      this.actions.pipe(
        filter(i => i.type === disconnectModem.type),
      ).subscribe(() => {
        this.modemError = false;
      }),
    );

    this.sub$.add(
      this.actions.pipe(
        filter(i => i.type === updateLanSettingsError.type && ((i as any).payload.error === 'EthernetError')),
      ).subscribe(() => {
        if (this.form.controls.lan.value) {
          this.lanError = true;
        }
      }),
    );

    this.sub$.add(
      this.actions.pipe(
        filter(i => i.type === updateLanSettingsSuccess.type),
      ).subscribe(() => {
        this.lanError = false;
      }),
    );

    this.sub$.add(
      this.form.valueChanges.subscribe((formValues) => {
        this.changed.emit({
          formValues: {
            ...formValues,
            lan: Number(formValues.lan),
            wifi: Number(formValues.wifi),
            modem: Number(formValues.modem),
          },
          isValid: this.form.valid,
        });
        if (!formValues.wifi) {
          this.selectedWifi = null;
        }
      }),
    );
  }

  ngAfterViewInit(): void {
    if (!this.form.value.lan) {
      this.menuClicked('wifi');
    }
  }

  updateFormValues(networkSettings: INetworkSettingsView): void {
    this.form.patchValue(networkSettings);
  }

  menuClicked(menuItemIndex: typeof this.menuItem): void {
    this.menuItem = menuItemIndex;
    if (this.menuItem !== 'wifi') {
      this.selectedWifi = null;
      this.changeWifiNetwork = false;
    }
    if (this.menuItem !== 'modem') {
      this.changeCellularNetwork = false;
      this.modemApnControl.setValue('');
      this.modemPasswordControl.setValue('');
      this.modemUserControl.setValue('');
    }
  }

  wifiSelected(selectedWifi: IWifiConnection): void {
    this.wifiError = false;

    if (selectedWifi.name === this.settings.wifiName) {
      this.changeWifiNetwork = false;
      this.selectedWifi = null;
      return;
    }

    if (!selectedWifi?.isProtected) {
      this.onConnectToWifi.emit(selectedWifi);
      return;
    }
    this.selectedWifi = selectedWifi;
  }

  parseWifiConnections(connections: typeof this.networkSettings.wifiConnectionsList): IRadioListItem[] {
    return connections?.map(connection => {
      const icon: IRadioListItem['icon'] = connection?.isProtected ? IconsEnum.NETWORK_WIFI_SECURED : this.getSignalLevelIcon(connection?.signal);

      return {
        title: `${connection.name} ${connection.band}`,
        value: connection,
        icon,
      };
    });
  }

  wifiPasswordChanged(password: string): void {
    if (!this.selectedWifi) return;
    this.selectedWifi = { ...this.selectedWifi, password };
  }

  connectToWifi(): void {
    this.wifiError = false;
    this.onConnectToWifi.emit(this.selectedWifi);
  }

  getCellularIcon(signal: number): string {
    return [IconsEnum.CELL_LOW,
    IconsEnum.CELL_MID,
    IconsEnum.CELL_HIGH,
    IconsEnum.CELL_FULL][signal];
  }

  onWifiNetworkChange(changeConnected: boolean = true): void {
    this.onScanWifi.emit();
    if (changeConnected) {
      this.changeWifiNetwork = true;
    }
    this.selectedWifi = null;
    this.passwordControl.setValue(null);
  }

  connectModem(): void {
    this.onConnectModem.emit({
      modemApn: this.modemApnControl.value,
      modemUser: this.modemUserControl.value,
      modemPassword: this.modemPasswordControl.value,
    });
  }

  disconnectModem(): void {
    this.changeCellularNetwork = true;
    this.modemApnControl.setValue('');
    this.modemPasswordControl.setValue('');
    this.modemUserControl.setValue('');
  }

  getSignalLevelIcon(signal: number): typeof IconsEnum[keyof typeof IconsEnum] {
    switch (signal) {
      case 0:
      case 1:
        return IconsEnum.NETWORK_WIFI_CONNECTED_WEAK;
      case 2:
        return IconsEnum.NETWORK_WIFI_CONNECTED_MEDIUM;
      default:
        return IconsEnum.NETWORK_WIFI_CONNECTED_FULL;
    }
  }

  getPasswordIconColor([hasError, isFocused]: [boolean, boolean]): ColorsEnum {
    if (hasError) {
      return ColorsEnum.MainRed;
    }
    return isFocused ? ColorsEnum.MainBlue : ColorsEnum.MonoDark;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
