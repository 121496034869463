export interface ITicketQrCodeInfo {
  cloudConnectionInfo: {
    userName: string;
    farmName: string;
  };

  qrCodeData: {
    ticketID: string;
    connectionNumber: string;
  };
}
