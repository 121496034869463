import { CONTROLLER_MENU_KEY } from './controller-menu.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ControllerMenuState } from './controller-menu.state';

export const selectControllerMenuState =
  createFeatureSelector<ControllerMenuState>(CONTROLLER_MENU_KEY);

export const selectIsMenuOpened = createSelector(selectControllerMenuState,
  ({ menuIsOpened }) => menuIsOpened);

export const selectActiveMenuSection = createSelector(selectControllerMenuState,
  ({ activeMenuSection }) => activeMenuSection);

export const selectActivePage = createSelector(selectControllerMenuState,
  ({ activePage }) => activePage);
