export class PasswordGeneratorUtils {
    static generatePassword(passwordLength: number): string {
        const numberChars = '0123456789';
        const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
        const specialSymbols = '!#$%&()*+,-./:;<=>?@[\]^_{|}~';
        const allChars = numberChars + upperChars + lowerChars + specialSymbols;
        let randPasswordArray = Array(passwordLength);
        randPasswordArray[0] = numberChars;
        randPasswordArray[1] = upperChars;
        randPasswordArray[2] = lowerChars;
        randPasswordArray[3] = specialSymbols;
        randPasswordArray = randPasswordArray.fill(allChars, 4);
        return this.shuffleArray(randPasswordArray.map((x) => { return x[Math.floor(Math.random() * x.length)]; })).join('');
    }

    static shuffleArray(array: string[]): string[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }
}