import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  ControllerHouseSizesSettingsFormComponent,
} from '../../ui/house-sizes-settings-form/house-sizes-settings-form.component';
import { Store } from '@ngrx/store';
import {
  getControllerHouseSizesSettings,
  updateControllerHouseSizesSettings,
  updateControllerHouseSizesSettingsSuccess,
} from '../../+state/current-controller/current-controller.actions';
import { IHouseSizesView } from '../../interfaces/house-sizes/house-sizes-view.interface';
import { Observable } from 'rxjs';
import { LengthUnitEnum, PageTypeEnum } from '@livestock/shared/enums';
import {
  selectCurrentControllerHouseSettings, selectCurrentControllerLengthUnit,
} from '../../+state/current-controller/current-controller.selectors';
import { ControllerGeneralSettingsFormComponent } from '../../ui/general-settings-form/general-settings-form.component';
import { SettingsWrapperComponent } from '../settings-wrapper/settings-wrapper.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsService, setIsFormEditing } from '@livestock/forms';
import { Actions, ofType } from '@ngrx/effects';
import { LoadingGalconComponent } from '@livestock/ui';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ControllerHouseSizesSettingsFormComponent,
    ControllerGeneralSettingsFormComponent,
    SettingsWrapperComponent,
    LoadingGalconComponent,
  ],
  selector: 'lv-house-settings',
  templateUrl: './house-settings.component.html',
  styleUrls: ['./house-settings.component.scss'],
})
export class HouseSettingsComponent implements OnInit {
  @ViewChild(ControllerHouseSizesSettingsFormComponent) formComponent: ControllerHouseSizesSettingsFormComponent;

  private store: Store = inject(Store);
  private actions$: Actions = inject(Actions);
  private formsService: FormsService = inject(FormsService);
  private destroyRef: DestroyRef = inject(DestroyRef);

  houseSettings$: Observable<IHouseSizesView> = this.store.select(selectCurrentControllerHouseSettings);
  lengthUnit$: Observable<LengthUnitEnum> = this.store.select(selectCurrentControllerLengthUnit);

  // vars
  isEditMode: boolean = false;
  isSuccess: boolean = false;
  isChanged: boolean = false;
  initialHouseNumber: number;
  formValue: IHouseSizesView;

  PageTypeEnum = PageTypeEnum;

  constructor() {
    this.actions$.pipe(ofType(updateControllerHouseSizesSettingsSuccess), takeUntilDestroyed()).subscribe(() => {
      this.isSuccess = true;
      setTimeout(() => {
        this.formComponent.initialFormValue = this.formComponent.form.getRawValue();
        this.isSuccess = false;
        this.isChanged = false;
        this.isEditMode = false;
        this.store.dispatch(setIsFormEditing({ isEditing: false }));
      }, 2000);
    });
  }

  ngOnInit(): void {
    this.store.dispatch(getControllerHouseSizesSettings());
  }

  private resetFormEditing(): void {
    this.store.dispatch(setIsFormEditing({ isEditing: false }));
    this.formComponent.form.setValue(this.formComponent.initialFormValue);
  }

  onReload(): void {
    this.resetFormEditing();
    this.store.dispatch(getControllerHouseSizesSettings());
    this.isEditMode = false;
  }

  toggleDisabled(isForceToggle?: boolean): void {
    if (isForceToggle) {
      this.isEditMode = !this.isEditMode;
      this.resetFormEditing();
    } else if (this.isEditMode && this.isChanged) {
      this.formsService.confirmFormLeaving()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(confirm => {
          if (confirm) {
            this.isEditMode = false;
            this.resetFormEditing();
          }
        });
      return;
    }

    this.isEditMode = !this.isEditMode;
  }

  onChange(data: { formValues: IHouseSizesView, isChanged: boolean, initialHouseNumber: number }): void {
    this.formValue = data.formValues;
    this.isChanged = data.isChanged;
    this.initialHouseNumber = data.initialHouseNumber;
    this.store.dispatch(setIsFormEditing({ isEditing: this.isChanged }));
  }

  onSave(): void {
    this.store.dispatch(updateControllerHouseSizesSettings({
      houseSettings: {
        ...this.formValue,
      },
      prevHouseNumber: this.initialHouseNumber,
    }));
  }
}
