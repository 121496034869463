import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { ErrorModel } from '../models';

@Injectable({ providedIn: 'root' })
export class ErrorService {

  errorOccurred = new BehaviorSubject<any>(null);
  errorPageDisplayed = new BehaviorSubject<boolean>(false);
  errorSendMessage = new BehaviorSubject<any>(null);

  private errorPage = '/lobby';
  private previousUrl: any;

  constructor(
    private router: Router,
  ) {

    this.router.events
      .subscribe((e: any) => {
        if (e instanceof NavigationEnd && e.url !== this.errorPage) {
          this.previousUrl = e.url;
        }
        if (e instanceof NavigationCancel) {
          if (e.url === this.errorPage) {
            setTimeout(() => this.router.navigate(['']), 500);
          }
        }
      });
  }

  goToErrorPage(errorModel: any): void {
    if (!errorModel) {
      errorModel = new ErrorModel();
    }
    errorModel.previousPage = this.previousUrl || '';
    errorModel.isVisited = false;
    this.errorOccurred.next(errorModel);

    this.router.navigate([this.errorPage]);
  }
}
