import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lv-flock-weight',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './flock-weight.component.html',
  styleUrls: ['./flock-weight.component.scss'],
})
export class FlockWeightComponent {
}
