import { ControllerMenuSectionsEnum, IControllerMenuItem } from '@livestock/menu';
import { IconsEnum } from '@livestock/shared/enums';

export const controllerMenuSections: IControllerMenuItem[] = [
  {
    id: ControllerMenuSectionsEnum.Control,
    title: 'ControllerMenu.Sections.Control',
    icon: IconsEnum.CONTROL_SECTION,
    qaTags: 'qa-mnu-controlSection',
  },
  {
    id: ControllerMenuSectionsEnum.Flock,
    title: 'ControllerMenu.Sections.Flock',
    icon: IconsEnum.FLOCK,
    qaTags: 'qa-mnu-flock',
  },
  {
    id: ControllerMenuSectionsEnum.LogsReports,
    title: 'ControllerMenu.Sections.LogsReports',
    icon: IconsEnum.LOGS,
    qaTags: 'qa-mnu-logsReports',
  },

  {
    id: ControllerMenuSectionsEnum.System,
    title: 'ControllerMenu.Sections.System',
    icon: IconsEnum.SYSTEM,
    qaTags: 'qa-mnu-system',
  },
  {
    id: ControllerMenuSectionsEnum.BasicSettings,
    title: 'ControllerMenu.Sections.BasicSettings',
    icon: IconsEnum.MANDATORY_SETTINGS,
    qaTags: 'qa-mnu-lobby-mandatorySettings',
  },
];
