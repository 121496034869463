import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { ToggleType } from './toggle-type';
import { LanguageService } from '@livestock/shared/services';

@Component({
  selector: 'ls-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonToggleModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() size: 'small' | 'meduim' | 'large' = 'small';
  @Input() disabled: boolean = false;
  @Input() onTitle: string = 'On';
  @Input() offTitle: string = 'Off';
  @Input() type: ToggleType = ToggleType.Slide;

  @Input() set value(isChecked: boolean) {
    if (!this.disabled) {
      this.isChecked = isChecked;
      this.onChange(isChecked);
    }
  }

  @Output() change = new EventEmitter<boolean>();

  isChecked: boolean;
  ToggleType = ToggleType;

  constructor(public languageService: LanguageService) {
  }

  public onChange: any = () => {
  };

  public onTouch: any = () => {
  };

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public toggleChecked(value?: boolean): void {
    if (!this.disabled) {
      if (value != null) {
        this.isChecked = value;
      } else {
        this.isChecked = !this.isChecked;
      }
      this.onChange(this.isChecked);
      this.change.emit(this.isChecked);
    }
  }
}

