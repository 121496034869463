import { IBuildInfo } from '@livestock/shared/interfaces';
import { createActionGroup } from '@ngrx/store';
import { DisplayStateBackend, DisplayStateController } from './display.state';

export const displayActions = createActionGroup({
  source: 'Display',
  events: {
    backendConnection: (backendConnection: DisplayStateBackend) => ({ backendConnection }),
    controllerVersion: (controllerVersion: DisplayStateController) => ({ controllerVersion }),
    newVersionAvailable: (version: string) => ({ version }),
    newFirmwareAvailable: (version: string) => ({ version }),
    notificationMessage: (notificationMessage: string | null) => ({ notificationMessage }),
    reloadRequired: (reloadRequired: boolean) => ({ reloadRequired }),
    readyToInstall: (readyToInstall: boolean) => ({ readyToInstall }),
    readyToFirmware: (readyToFirmware: boolean) => ({ readyToFirmware }),
    buildInfo: (buildInfo: IBuildInfo) => ({ buildInfo }),
  },
});

