<ng-container [ngSwitch]="selectedElementGroup">
  <ng-container *ngSwitchCase="InstallationElementGroupsEnum.Alarms">3</ng-container>
  <ng-container *ngSwitchCase="InstallationElementGroupsEnum.Scales">4</ng-container>

  <ng-container *ngSwitchDefault>
    <div
      #container
      class="elements-list__container">
      <div
        [class.elements-list__wrapper-with-scroll]="hasScroll"
        class="elements-list__wrapper">
        <div
          [class.border-top-none]="activeElementIndex === 0"
          class="elements-list">
          <label
            [class.elements-list__label-gradient]="activeElementIndex === 0"
            class="elements-list__label fz-12 fw-500">
            {{ "Controller.Installation.Type" | translate }}
          </label>

          <div
            *ngFor="let element of elements; index as index"
            (click)="setActiveElementEmit(element.elementType, index)"
            [ngClass]="{
              'elements-item__active': element.elementType === activeElementType,
              'elements-item__active-first': activeElementIndex === 0,
              'elements-item__active-last': activeElementIndex === elements.length - 1,
              'border-bottom-none': activeElementIndex === index + 1
            }"
            class="elements-item d-flex align-center pointer">
            <div
              *ngIf="activeElementIndex === 0"
              class="position-absolute hide-border-part"></div>

            <div class="elements-item__icon">
              <ls-svg-icon
                [path]="ElementTypesEnum.getSvgPath(element.elementType)"
                [color]="element.elementType === activeElementType ? ColorsEnum.MainDarkBlue : ColorsEnum.Black" />
            </div>

            <span class="mh-8 flex-grow-1 elements-item__name">
              {{ element.elementType | enum : "installation-elements" | translate }}
            </span>

            <p class="fz-12 color-grey fw-600 elements-item__count d-flex">
              <span>{{ element.connectedCount }}</span>
              /
              <span>{{ element.maxConnectionCount }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
