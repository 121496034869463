<div class="d-flex flex-column h-100-percent">
  <lv-settings-header
    [section]="'ControllerMenu.Sections.BasicSettings' | translate"
    [title]="'Controller.CloudSettings.CloudSettings' | translate"
    [isEditable]="false" />

  <lv-controller-cloud-connection-form
    (onDisconnect)="disconnectCloud()"
    (getTicketQrCodeInfoFromDevice)="getTicketQrCodeInfoFromDevice()"
    [isLoading]="isLoading$ | async"
    [ticketQrCodeInfo]="ticketQrCodeInfo$ | async"
    [ticketQrCodeError]="ticketQrCodeError$ | async"
    [canDisconnect]="canDisconnect"
    [cloudConnectionRequest]="cloudConnectionRequest$ | async" />
</div>
