<div class="container">
  <div
    [class.farms-header-wrapper__border-none]="
      (mode === MobileViewModeEnum.Lobby &&
        !(router.url | memoizeFunc : activatedRouteService.isCreateFarmOrScannedLoginPage)) ||
      isEditFarmMode ||
      (userMenuIsActive$ | async)
    "
    class="farms-header-wrapper">
    <div class="farms-header">
      <img
        (click)="goToMainPage()"
        src="/assets/images/titan-medium-logo.svg"
        width="58"
        height="16"
        alt="logo" />

      <div class="profile-block d-flex flex-row align-center">
        <div
          (click)="toggleUserMenu()"
          [class.profile-opened]="userMenuIsActive$ | async"
          class="profile pointer">
          {{ currentUserInitials$ | async }}
          <ls-svg-icon
            [class.rotate-180]="userMenuIsActive$ | async"
            [path]="IconsEnum.ARROW_DOWN_WHITE"
            class="mh-4" />
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper">
    <ls-farms-list
      *ngIf="!(userMenuIsActive$ | async)"
      (goToLobby)="changeViewMode(MobileViewModeEnum.Lobby, false)"
      (setIsEditFarmMode)="setIsEditFarmMode($event)"
      [class.d-none__forced]="mode === MobileViewModeEnum.Lobby">
    </ls-farms-list>

    <ng-container *ngIf="userMenuIsActive$ | async">
      <div class="current-user__popup">
        <div class="current-user__wrapper">
          <lv-current-user-info (closeUserPopup)="toggleUserMenu()"></lv-current-user-info>
        </div>
      </div>
    </ng-container>

    <div
      [class.d-none__forced]="mode === MobileViewModeEnum.Farms"
      [class.bg-transparent]="router.url | memoizeFunc : activatedRouteService.showTransparentMobileLobby"
      class="current-page__wrapper">
      <div
        *ngIf="
          !(router.url | memoizeFunc : activatedRouteService.showTransparentMobileLobby) &&
          !(router.url | memoizeFunc : activatedRouteService.onFarmSettings)
        "
        class="current-page__header">
        <lv-controller-indicators
          *ngIf="showControllerIndicators && controllerInfo$ | async as controller"
          [controller]="controller" />
      </div>

      <div class="current-page__content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div
    *ngIf="menuIsOpened$ | async"
    class="mobile-menu">
    <lv-controller-menu-mobile (showPage)="showPage()"></lv-controller-menu-mobile>
  </div>

  <div
    [class.footer__disabled]="
      (router.url | memoizeFunc : activatedRouteService.showTransparentMobileLobby) ||
      (router.url | memoizeFunc : activatedRouteService.onFarmSettings) ||
      (userMenuIsActive$ | async) ||
      (isDialogOpened$ | async)
    "
    class="footer">
    <div
      (click)="changeViewMode(MobileViewModeEnum.Farms, false)"
      [class.active]="mode === MobileViewModeEnum.Farms && !(menuIsOpened$ | async)"
      class="footer-button">
      <ls-svg-icon [path]="IconsEnum.MOBILE_FARMS"></ls-svg-icon>
    </div>

    <div
      (click)="changeViewMode(MobileViewModeEnum.Lobby)"
      [ngClass]="{
        disabled: !(activeControllerID$ | async),
        active: mode === MobileViewModeEnum.Lobby && (activeControllerID$ | async) && (router.url | memoizeFunc : activatedRouteService.isLobby),
      }"
      class="footer-button">
      <ls-svg-icon [path]="IconsEnum.MOBILE_LOBBY"></ls-svg-icon>
    </div>

    <div
      (click)="changeViewMode(MobileViewModeEnum.Menu)"
      [ngClass]="{
        disabled: !(selectedControllerIsActive$ | async),
        active: menuIsOpened$ | async,
      }"
      class="footer-button menu">
      <ls-svg-icon [path]="(menuIsOpened$ | async) ? IconsEnum.MOBILE_MENU_OPEN : IconsEnum.MOBILE_MENU"></ls-svg-icon>
    </div>
  </div>
</div>
