export const isClickOnElement = (cssClass: string, event?: Event): boolean => {
  let element: HTMLElement = event?.target as HTMLElement;

  while (element) {
    if (element.classList?.contains(cssClass)) {
      return true;
    }

    element = element.parentElement;
  }

  return false;
};
