<div
  *ngIf="farm$ | async as farm"
  class="wrapper m-18 p-28 d-flex flex-column">
  <div class="mb-46">
    <h2 class="fz-24 fw-600 d-flex align-center">
      <lv-button
        [iconPath]="IconsEnum.LEFT_ARROW_CIRCLE"
        routerLink="/{{ AppRoutes.FARM }}/{{ farm.farmID }}"
        [type]="ButtonTypeEnum.BLACK"
        cssClass="fz-14">
        {{ farm.name }}
      </lv-button>
      <span class="ml-18">
        {{ (showNewInvitation ? "Farms.FarmUpdate.UserInvitation" : "Farms.FarmUpdate.FarmSettings") | translate }}
      </span>
    </h2>
  </div>

  <div class="d-flex flex-grow-1 settings">
    <ng-container *ngIf="!showNewInvitation; else invitationTemplate">
      <div class="pr-56">
        <h3 class="mb-18 fz-21 fw-600">{{ "Farms.FarmUpdate.FarmDetails" | translate }}</h3>
        <lv-update-farm
          (deleted)="onDelete()"
          [isMapAlwaysOpen]="true"
          [isInviteButtonVisible]="isInvitationAvailable$ | async"
          [currentUserAccessRole]="currentUserAccessRole$ | async"
          [farm]="farm">
        </lv-update-farm>
      </div>
      <div class="pl-56 settings__users">
        <h3 class="mb-18 fz-21 fw-600">{{ "Farms.FarmUpdate.FarmUsers" | translate }}</h3>
        <lv-farm-users-list
          [currentUser]="currentUser$ | async"
          [currentUserAccessRole]="currentUserAccessRole$ | async"
          [users]="users$ | async">
        </lv-farm-users-list>
      </div>
    </ng-container>
    <ng-template #invitationTemplate>
      <div class="user-invitation">
        <lv-farm-user-details
          [mode]="UserDetailsModeEnum.NewInvitation"
          [currentUserRole]="(currentUser$ | async).role"
          [currenUserAccessRole]="currentUserAccessRole$ | async">
        </lv-farm-user-details>
      </div>
    </ng-template>
  </div>
</div>
