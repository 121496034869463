export enum CommonUserRolesEnum {
  User = 0,
  Support = 1,
  God = 2,
}

export namespace CommonUserRolesEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case CommonUserRolesEnum.User:
        return 'Farms.CommonUserRoles.User';
      case CommonUserRolesEnum.Support:
        return 'Farms.CommonUserRoles.Support';
      case CommonUserRolesEnum.God:
        return 'Farms.CommonUserRoles.God';
      default:
        return value.toString();
    }
  }
}
