import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    console.log('CHUNK ERROR HANDLER');

    if (chunkFailedMessage.test(error.message)) {
      console.log('chunk failed handler');
      //window.location.reload();
    }
  }
}
