import { Component, Input } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { ButtonWizardTypeEnum, ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';

@Component({
  selector: 'lv-button-wizard',
  templateUrl: './button-wizard.component.html',
  styleUrls: ['./button-wizard.component.scss'],
  standalone: true,
  imports: [
    QaTagsDirective,
    CommonModule,
    SvgIconComponent,
  ],
})

export class ButtonWizardComponent {
  @Input() type: ButtonWizardTypeEnum = ButtonWizardTypeEnum.ON;
  @Input() iconPath: string;

  ColorsEnum = ColorsEnum;
  ButtonWizardTypeEnum = ButtonWizardTypeEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
}
