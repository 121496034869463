import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IInvitedUser, IUser } from '@livestock/shared/interfaces';
import { IFarmInfo } from '../interfaces/farm-info.interface';
import { ICreateFarm } from '../interfaces/create-farm.interface';
import { IUpdateFarm } from '../interfaces/update-farm.interface';
import { IController } from '../interfaces/controller.interface';
import { IFarm } from '../interfaces/farm.interface';
import { FarmCreationMode, IGetAddUserToFarmTicketView, UserDetailsModeEnum } from '@livestock/farms';

export const getFarms = createAction(
  '[FARMS] Get farms [...]',
);

export const getFarmsSuccess = createAction(
  '[FARMS] Get farms [SUCCESS]',
  props<{ farms: IFarm[] }>(),
);

export const getFarmsError = createAction(
  '[FARMS] Get farms [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const getFarmById = createAction(
  '[FARMS] Get farm by id [...]',
  props<{ farmID: number; }>(),
);

export const getFarmByIdSuccess = createAction(
  '[FARMS] Get farm by id [SUCCESS]',
  props<{ farm: IFarmInfo; }>(),
);

export const getFarmByIdError = createAction(
  '[FARMS] Get farm by id [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const createFarm = createAction(
  '[FARMS] Create farm [...]',
  props<{ farm: ICreateFarm; creationMode: FarmCreationMode; }>(),
);

export const createFarmSuccess = createAction(
  '[FARMS] Create farm [SUCCESS]',
  props<{ farm: IFarmInfo; creationMode: FarmCreationMode }>(),
);

export const createFarmError = createAction(
  '[FARMS] Create farm [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const updateFarmById = createAction(
  '[FARMS] Update farm by id [...]',
  props<{ farmID: number; farm: IUpdateFarm; }>(),
);

export const updateFarmByIdSuccess = createAction(
  '[FARMS] Update farm by id [SUCCESS]',
  props<{ farmID: number; farm: IUpdateFarm; }>(),
);

export const updateFarmByIdError = createAction(
  '[FARMS] Update farm by id [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const deleteFarmById = createAction(
  '[FARMS] Delete farm by id [...]',
  props<{ farmID: number; }>(),
);

export const deleteFarmByIdSuccess = createAction(
  '[FARMS] Delete farm by id [SUCCESS]',
  props<{ farmID: number; }>(),
);

export const deleteFarmByIdError = createAction(
  '[FARMS] Delete farm by id [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const setActiveFarm = createAction(
  '[FARMS] Set Active Farm [...]',
  props<{ farmID: number; }>(),
);

export const resetActiveFarm = createAction(
  '[FARMS] Reset Active Farm [...]',
);

export const setFarmWasCreatedOrDeleted = createAction(
  '[FARMS] Farm Was Created Or Deleted [...]',
  props<{ farmWasCreatedOrDeleted: boolean; }>(),
);

/*USERS*/
export const getFarmUsers = createAction(
  '[FARMS] Get farm users [...]',
  props<{ farmID: number; }>(),
);

export const getFarmUsersSuccess = createAction(
  '[FARMS] Get farm users [SUCCESS]',
  props<{ farmID: number; users: IUser[] }>(),
);

export const getFarmUsersError = createAction(
  '[FARMS] Get farm users [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const setUserDetailsMode = createAction(
  '[FARMS] Set Show Invite New Farm User Form [...]',
  props<{ userDetailsMode: UserDetailsModeEnum; }>(),
);

export const inviteFarmUser = createAction(
  '[FARMS] Invite farm user [...]',
  props<{ invitedUser: IInvitedUser; }>(),
);

export const inviteFarmUserSuccess = createAction(
  '[FARMS] Invite farm user [SUCCESS]',
  props<{ farmID: number; user: IUser; }>(),
);

export const inviteFarmUserError = createAction(
  '[FARMS] Invite farm user [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const resendInviteFarmUser = createAction(
  '[FARMS] Resend Invite farm user [...]',
  props<{ ticketID: string; invitedUser: IInvitedUser; }>(),
);

export const resendInviteFarmUserSuccess = createAction(
  '[FARMS] Resend Invite farm user [SUCCESS]',
  props<{ farmID: number; user: IUser; }>(),
);

export const resendInviteFarmUserError = createAction(
  '[FARMS] Resend Invite farm user [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const deleteFarmUser = createAction(
  '[FARMS] Delete farm user [...]',
  props<{ farmID: number; email: string; }>(),
);

export const deleteFarmUserSuccess = createAction(
  '[FARMS] Delete farm user [SUCCESS]',
  props<{ farmID: number; email: string; }>(),
);

export const deleteFarmUserError = createAction(
  '[FARMS] Delete farm user [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const updateUser = createAction(
  '[FARMS] Update user [...]',
  props<{ user: IUser }>(),
);

export const updateUserSuccess = createAction(
  '[FARMS] Update user [SUCCESS]',
  props<{ user: IUser }>(),
);

export const updateUserError = createAction(
  '[FARMS] Update user [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

/*HOUSES / CONTROLLERS*/
export const getFarmControllers = createAction(
  '[FARMS] Get farm controllers [...]',
  props<{ farmID: number; }>(),
);

export const getFarmControllersSuccess = createAction(
  '[FARMS] Get farm controllers [SUCCESS]',
  props<{ farmID: number; controllers: IController[]; }>(),
);

export const getFarmControllersError = createAction(
  '[FARMS] Get farm controllers [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

///////////
/*TICKETS*/

export const setAddUserToFarmTicketView = createAction(
  '[TICKETS] Set Add User To Farm Ticket View [...]',
  props<{ addUserToFarmTicketView: IGetAddUserToFarmTicketView; }>(),
);

export const acceptExistingUserToFarm = createAction(
  '[TICKETS] Accept Existing User To Farm [...]',
  props<{ ticketID: string; }>(),
);

export const acceptExistingUserToFarmSuccess = createAction(
  '[TICKETS] Accept Existing User To Farm [SUCCESS]',
);

export const acceptExistingUserToFarmError = createAction(
  '[TICKETS] Accept Existing User To Farm [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const deleteControllerFromFarm = createAction(
  '[FARMS] Delete controller [...]',
  props<{ controllerID: number }>(),
);

export const deleteControllerFromFarmSuccess = createAction(
  '[FARMS] Delete controller [SUCCESS]',
  props<{ controllerID: number }>(),
);

export const deleteControllerFromFarmError = createAction(
  '[FARMS] Delete controller [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

// only from the store
export const softDeleteControllerFromTheFarm = createAction(
  '[FARMS] Soft Delete Controller From The Farm [...]',
  props<{ controllerID: number }>(),
);

export const setControllerAsAccepted = createAction(
  '[FARMS] Set Controller As Accepted [...]',
  props<{ controllerID: number }>(),
);

export const setControllerOnlineStatus = createAction(
  '[FARMS] Set controller online status [...]',
  props<{ controllerID: number; isOnline: boolean; }>(),
);
