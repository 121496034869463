<ng-container *ngIf="childGroupName; else noElement">
  <div
    [formGroup]="parentForm"
    class="d-flex flex-column w-100-percent h-100-percent">
    <div
      *ngIf="!(isLoading$ | async); else loading"
      class="main-content">
      <ng-container [ngSwitch]="currentElementType">
        <ng-container *ngSwitchCase="ElementTypesEnum.IndoorTemperature">
          <lv-indoor-temperature-setup
            (delete)="delete()"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-indoor-temperature-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.OutdoorTemperature">
          <lv-outdoor-temperature-setup
            (delete)="delete()"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-outdoor-temperature-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.IndoorHumidity">
          <lv-indoor-humidity-setup
            (delete)="delete()"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-indoor-humidity-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.OutdoorHumidity">
          <lv-outdoor-humidity-setup
            (delete)="delete()"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-outdoor-humidity-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.CO2">
          <lv-co2-setup
            (delete)="delete()"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-co2-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.Ammonia">Ammonia</ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.StaticPressure">
          <lv-static-pressure-setup
            (delete)="delete()"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-static-pressure-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.WaterMeter">
          <lv-water-meter-setup
            (delete)="delete()"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-water-meter-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.AuxiliaryInput">
          <lv-auxiliary-input-setup
            (delete)="delete()"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-auxiliary-input-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.Cooling">
          <lv-cooling-setup
            (delete)="delete()"
            (changeTestMode)="changeTestMode($event)"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-cooling-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.LightDO">
          <lv-light-do-setup
            (delete)="delete()"
            (changeTestMode)="changeTestMode($event)"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-light-do-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.LightAO">
          <lv-light-ao-setup
            (delete)="delete()"
            (changeTestMode)="changeTestMode($event)"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-light-ao-setup>
        </ng-container>

        <ng-container *ngSwitchCase="ElementTypesEnum.AsRelay">
          <lv-as-relay-do-setup
            (delete)="delete()"
            (isChanged)="isChanged($event)"
            (isValid)="isValid($event)"
            [elementEvent]="(elementEvent$ | async)(currentElementType, 1, platformService.isDeviceApp)"
            [isEditMode]="isEditMode"
            [formGroupName]="childGroupName">
          </lv-as-relay-do-setup>
        </ng-container>
      </ng-container>
    </div>

    <div class="footer">
      <lv-button
        *ngIf="!isSavedSuccessfully"
        (click)="save()"
        [class.pointer-events-none]="
          [isFormValid, isFormChanged, isEditMode, isTestMode] | memoizeFunc : disableSaveButton
        "
        [type]="
          ([isFormValid, isFormChanged, isEditMode, isTestMode, isLoading$ | async] | memoizeFunc : disableSaveButton)
            ? ButtonTypeEnum.DISABLED
            : ButtonTypeEnum.PRIMARY
        "
        [height]="56"
        class="save-btn w-100-percent">
        <span>{{ "Installation.Save" | translate }}</span>
      </lv-button>

      <div
        *ngIf="isSavedSuccessfully"
        class="h-56 d-flex align-center justify-center ph-28 pv-18 fw-500">
        <ls-svg-icon
          [path]="IconsEnum.CHECKMARK_GREEN"
          class="mr-8"></ls-svg-icon>
        {{ "Buttons.Saved" | translate }}
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noElement>
  <div>not implemented yet</div>
</ng-template>

<ng-template #loading>
  <div class="h-100-percent w-100-percent d-flex align-center justify-center">
    <lv-loading-galcon></lv-loading-galcon>
  </div>
</ng-template>
