import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class SearchMobileService {
  isSearchOpened$ = new BehaviorSubject(false);
  searchResult = new FormControl('');

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(() => this.searchResult.value.length > 0 && this.isSearchOpened$.value),
      filter((event) => event instanceof NavigationEnd),
      startWith(this.router),
    ).subscribe(() => {
      this.isSearchOpened$.next(false);
      this.searchResult.setValue('');
    });

    this.isSearchOpened$.subscribe(isOpened => {
      if (!isOpened) {
        this.searchResult.setValue('');
      }
    });
  }

}
