import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'styleTextPart',
  standalone: true,
})
export class StyleTextPartPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string, textPart: string, className: string = 'highlighted'): SafeHtml {
    const pattern = textPart
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter(t => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');
    const match = value.match(regex);
    if (!match) {
      return value;
    }
    return textPart
      ? this.sanitizer.bypassSecurityTrustHtml(value.replace(regex, match => `<span class="${className}">${match}</span>`))
      : value;
  }
}
