import { AbstractControl, ValidationErrors } from '@angular/forms';

export const getMinMaxConstraintsFromControl = (control: AbstractControl): [number | null, number | null] => {
    let min: number;
    let max: number;
    if (!control?.validator) return [min, max];

    try {
        const validationErrorsMin: ValidationErrors | null = control.validator({...control, value: -Infinity} as AbstractControl);
        const validationErrorsMax: ValidationErrors | null = control.validator({...control, value: Infinity} as AbstractControl);
        min = validationErrorsMin?.['min']?.['min'];
        max = validationErrorsMax?.['max']?.['max'];
    } catch (error) {
        console.error(`${getMinMaxConstraintsFromControl.name}: Cannot extract min,max constraints from control`, control);
    }

    return [min, max];
};