<lv-settings-wrapper
  (toggleDisabled)="toggleDisabled($event)"
  (save)="saveSettings()"
  (onReload)="onReload()"
  section="{{ 'ControllerMenu.Sections.BasicSettings' | translate }}"
  title="{{ 'Controller.QuickStart.DateTime' | translate }}"
  [page]="PageTypeEnum.DateTimeSettings"
  [isSuccess]="isSuccess"
  [isEditMode]="isEditMode"
  [isValid]="platformService.isDeviceApp ? form?.valid : !!form?.value.timeZoneID?.id"
  [isChanged]="isChanged">
  <ng-container *ngIf="dateTimeSettings; else loading">
    <div [formGroup]="form">
      <div class="field-row d-flex align-center gap-8 mb-18">
        <div class="ls-input ls-input-autoDateTimeType field">
          <div class="input-wrapper">
            <input
              [value]="form.value.autoDateTimeType | enum : 'auto-date-time-type' | translate"
              disabled />
            <label class="input-label input-label_filled">
              {{ "Controller.DateTimeSettings.DateAndTime" | translate }}
            </label>
          </div>
        </div>

        <div
          [class.pointer-events-none]="!isEditMode"
          class="ls-input ls-input-hoursFormat field">
          <div class="input-wrapper">
            <lv-dual-toggle
              [class.pointer-events-none]="!isEditMode"
              [optionsTitlePosition]="platformService.isMobileVersion ? 'after' : 'both'"
              [firstOption]="{
                title: HoursFormatTypeEnum.HoursFormat24 | enum : 'controller-hours-format',
                value: HoursFormatTypeEnum.HoursFormat24
              }"
              [secondOption]="{
                title: HoursFormatTypeEnum.AmPm | enum : 'controller-hours-format',
                value: HoursFormatTypeEnum.AmPm
              }"
              formControlName="hoursFormat">
            </lv-dual-toggle>

            <label class="input-label input-label_filled">
              {{ "Controller.DateTimeSettings.TimeFormat" | translate }}
            </label>
          </div>
        </div>
      </div>

      <div class="field-row d-flex align-center gap-8 mb-18 flex-wrap">
        <div class="field field-date">
          <lv-input-date
            [class.pointer-events-none]="!isEditMode"
            [isDisabled]="form.value.autoDateTimeType === AutoDateTimeTypeEnum.ByNetwork"
            [label]="'Controller.DateTimeSettings.DateSetting'"
            [isImperial]="(language$ | async) === ControllerLanguageEnum.EngUS"
            [placeholder]="
              (language$ | async) === ControllerLanguageEnum.EngUS
                ? GlobalConstants.USA_Date_placeholder
                : GlobalConstants.EU_Date_placeholder
            "
            formControlName="date"
            class="w-100-percent">
            <ls-svg-icon
              [path]="IconsEnum.DATE_ICON"
              leftIcon
              class="mr-8 w-18">
            </ls-svg-icon>
          </lv-input-date>
        </div>

        <div class="field field-time">
          <lv-input-time
            [class.pointer-events-none]="!isEditMode"
            [isDisabled]="form.value.autoDateTimeType === AutoDateTimeTypeEnum.ByNetwork"
            [label]="'Controller.DateTimeSettings.TimeSetting'"
            [AMPM]="form.value.hoursFormat === HoursFormatTypeEnum.AmPm"
            [placeholder]="
              form.value.hoursFormat === HoursFormatTypeEnum.AmPm
                ? GlobalConstants.Time_hhmmAMPM_placeholder
                : GlobalConstants.Time_hhmm_placeholder
            "
            formControlName="time"
            class="w-100-percent w-input-63">
            <ls-svg-icon
              [path]="IconsEnum.TIME_ICON"
              leftIcon
              class="mr-8 w-18">
            </ls-svg-icon>
          </lv-input-time>
        </div>
      </div>

      <ng-container *ngIf="!platformService.isDeviceApp">
        <div
          [class.pointer-events-none]="!isEditMode"
          class="field field-timezone">
          <label class="field-timezone__label">
            {{ "LoginScanned.Timezone" | translate }}
          </label>

          <ls-svg-icon
            [color]="timeZoneInputInFocus ? ColorsEnum.MainDarkBlue : ColorsEnum.MonoDark"
            [path]="IconsEnum.TIMEZONE">
          </ls-svg-icon>

          <input
            (focusin)="onFocusInTimeZoneInput()"
            (focusout)="onFocusOutTimeZoneInput()"
            [placeholder]="timeZonePlaceholder | translate"
            [class.color-darkblue]="!timeZoneInputInFocus"
            [matAutocomplete]="auto"
            formControlName="timeZoneID"
            type="text"
            class="field-timezone__input ml-8" />
        </div>

        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayTimeZoneFn">
          <mat-option
            *ngFor="let timezone of filteredTimeZones | async"
            [value]="timezone">
            <span
              [innerHTML]="
                TimeZonesEnum.toTranslateKey(timezone.id) | styleTextPart : toHighlight : 'highlighted'
              "></span>
          </mat-option>
        </mat-autocomplete>

        <span
          *ngIf="!form.value.timeZoneID.id && !timeZoneInputInFocus"
          class="fz-12 color-error">
          {{ "Controller.DateTimeSettings.PleaseChooseCorrectTimezone" | translate }}
        </span>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="h-100-percent w-100-percent d-flex align-center justify-center">
      <lv-loading-galcon></lv-loading-galcon>
    </div>
  </ng-template>
</lv-settings-wrapper>
