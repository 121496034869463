import { Component, DestroyRef, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { ButtonTypeEnum, PageTypeEnum } from '@livestock/shared/enums';
import { FormsService, setIsFormEditing } from '@livestock/forms';
import {
  selectControllerGeneralSettings, selectSettingsAreLoading,
  updateControllerGeneralSettings,
  updateControllerGeneralSettingsSuccess,
} from '@livestock/controllers';
import { ControllerGeneralSettingsFormComponent } from '../../ui/general-settings-form/general-settings-form.component';
import { IGeneralSettingsView } from '../../interfaces/basic/general-settings-view.interface';
import { SettingsWrapperComponent } from '../settings-wrapper/settings-wrapper.component';
import { getControllerGeneralSettings } from '@livestock/controllers';
import { LoadingGalconComponent } from '@livestock/ui';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ControllerGeneralSettingsFormComponent,
    TranslateModule,
    SettingsWrapperComponent,
    LoadingGalconComponent,
  ],
  selector: 'lv-units-settings',
  templateUrl: './units-settings.component.html',
  styleUrls: ['./units-settings.component.scss'],
})
export class UnitsSettingsComponent {
  @ViewChild(ControllerGeneralSettingsFormComponent) formComponent: ControllerGeneralSettingsFormComponent;

  private store: Store = inject(Store);
  private actions$: Actions = inject(Actions);
  private formsService: FormsService = inject(FormsService);
  private destroyRef: DestroyRef = inject(DestroyRef);

  // subs
  generalSettings$: Observable<IGeneralSettingsView> = this.store.select(selectControllerGeneralSettings).pipe(wasChangedAndNotNull());
  isLoading$: Observable<boolean> = this.store.select(selectSettingsAreLoading);

  // vars
  isEditMode: boolean = false;
  isSuccess: boolean = false;
  isChanged: boolean = false;
  formValue: IGeneralSettingsView;

  // enums
  PageTypeEnum = PageTypeEnum;
  ButtonTypeEnum = ButtonTypeEnum;

  constructor() {
    this.store.dispatch(getControllerGeneralSettings());
    this.actions$.pipe(ofType(updateControllerGeneralSettingsSuccess), takeUntilDestroyed()).subscribe(() => {
      this.isSuccess = true;
      setTimeout(() => {
        this.formComponent.initialFormValue = this.formComponent.form.getRawValue();
        this.isSuccess = false;
        this.isChanged = false;
        this.isEditMode = false;
        this.store.dispatch(setIsFormEditing({ isEditing: false }));
      }, 2000);
    });
  }

  private resetFormEditing(): void {
    this.store.dispatch(setIsFormEditing({ isEditing: false }));
    this.formComponent.form.setValue(this.formComponent.initialFormValue);
  }

  onReload(): void {
    this.resetFormEditing();
    this.store.dispatch(getControllerGeneralSettings());
    this.isEditMode = false;
  }

  toggleDisabled(isForceToggle?: boolean): void {
    if (isForceToggle) {
      this.isEditMode = !this.isEditMode;
      this.resetFormEditing();
    } else if (this.isEditMode && this.isChanged) {
      this.formsService.confirmFormLeaving()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(confirm => {
          if (confirm) {
            this.isEditMode = false;
            this.resetFormEditing();
          }
        });
    } else {
      this.isEditMode = !this.isEditMode;
    }
  }

  onChange(data: { formValues: IGeneralSettingsView, isChanged: boolean }): void {
    this.formValue = data.formValues;
    this.isChanged = data.isChanged;
    this.store.dispatch(setIsFormEditing({ isEditing: this.isChanged }));
  }

  onSave(): void {
    this.store.dispatch(updateControllerGeneralSettings({
      generalSettings: {
        ...this.formValue,
      },
      unitsDetails: this.formValue.unitsDetails,
    }));
  }
}
