/* ec === 0 */
export enum SocketEventControllerTypeEnum {
  ConnectionStatus = 1,
  Sync = 2,
  ConnectionType = 3,
  CnfStatus = 4,
  RefreshAlarmStatus = 5,
  CloudConnectionAccept = 6,
  QuickStartStatus = 7,
  RefreshPage = 8,
}
