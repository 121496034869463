import { CurrentControllerState } from '../current-controller.state';
import { LocalStorageService } from '@livestock/shared/services';
import { StorageItem } from '@livestock/shared/enums';

export const initialState: CurrentControllerState = {
  isLoading: false,
  controllerInfo: null,
  activeControllerID: LocalStorageService.getStorageItem(StorageItem.ActiveControllerID)
    ? +LocalStorageService.getStorageItem(StorageItem.ActiveControllerID)
    : null,
  page: null,
  editModeInfo: null,
  lastEditedInfo: null,
  cnfStatus: null,
  controllerLanguage: null,
  generalSettings: null,
  dateTimeSettings: null,
  houseSettings: null,
  networkSettings: null,
  cards: [],
};
