import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe, StyleTextPartPipe } from '@livestock/shared/pipes';
import { Store } from '@ngrx/store';
import { selectActiveFarm, IFarm } from '@livestock/farms';
import { VerificationCodeComponent, ButtonComponent, SvgIconComponent, LoadingGalconComponent, InputIntegerComponent } from '@livestock/ui';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { AddControllerStepsEnum } from '@livestock/controllers/enums';
import { IconsEnum, ColorsEnum } from '@livestock/shared/enums';
import { PlatformService } from '@livestock/shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { wasChangedAndNotNull, wasChanged } from '@livestock/shared/rxjs-operators';
import { Subscription, Observable, startWith, map, withLatestFrom } from 'rxjs';
import { TimeZonesEnum, GlobalConstants } from '@livestock/shared/constants';
import { MatAutocompleteModule, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { AppRoutes } from '@livestock/shared/routes';
import {
  clearIsControllerCreatedOrExecuted,
  executeTicketController,
  getControllerTicketInfoByConnectionNumber,
  getControllerTicketInfoByConnectionNumberSuccess,
  selectControllerTicketByConnectionNumber,
  selectGetControllerTicketError,
  selectIsControllerTicketExecuted,
  selectIsLoading,
  setValidControllerTicketHouseNumber,
  IGetAddControllerByConnectionNumberTicketView,
} from '@livestock/parring-process';
import { Actions, ofType } from '@ngrx/effects';
import { setMobileViewMode, MobileViewModeEnum } from '@livestock/layout';
import { INameId } from '../../../../../shared/interfaces/id-name.interface';
import { setFlashMessage } from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';
import {
  BarcodeScanner,
} from '@capacitor-mlkit/barcode-scanning';

@Component({
  selector: 'lv-create-controller',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    TranslateModule,
    QaTagsDirective,
    MemoizeFuncPipe,
    ButtonComponent,
    SvgIconComponent,
    VerificationCodeComponent,
    StyleTextPartPipe,
    LoadingGalconComponent,
    InputIntegerComponent,
  ],
  templateUrl: './create-controller.component.html',
  styleUrls: ['./create-controller.component.scss'],
  standalone: true,
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: ['autcomplete-timezone-overlay-pane', 'add-controller'] },
    },
  ],
})
export class CreateControllerComponent implements OnInit, OnDestroy, AfterViewInit {
  // subs
  sub$ = new Subscription();
  controllerTicket$ = this.store.select(selectControllerTicketByConnectionNumber);
  isLoading$ = this.store.select(selectIsLoading);
  // vars
  currStep = AddControllerStepsEnum.EnterConnectionNumber;
  getControllerTicketError: string;
  activeFarm: IFarm;
  connectionNumber: string;
  timeZoneControl = new FormControl<INameId>(null);
  timeZones = TimeZonesEnum.toList();
  filteredTimeZones: Observable<INameId[]>;
  houseNumber: number = 0;
  houseNumberPlaceholder = 'AddController.HouseNumber';
  houseNumberInputInFocus: boolean;
  timeZonePlaceholder = 'AddController.Timezone';
  timeZoneInputInFocus: boolean;
  toHighlight: string;
  // enums
  AddControllerStepsEnum = AddControllerStepsEnum;
  TimeZonesEnum = TimeZonesEnum;
  IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;
  GlobalConstants = GlobalConstants;
  isQRCodeScannerOpen = false;

  constructor(
    public platformService: PlatformService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private actions$: Actions,
  ) {
  }
  ngAfterViewInit(): void {
    if (this.platformService.isMobileApp) {
      this.openQRCodeScanner();
    }
  }

  async openQRCodeScanner(): Promise<void> {
    this.isQRCodeScannerOpen = true;
    document.querySelector('body')?.classList.add('bg_transparent');
    document.querySelector('lv-layout-mobile .container')?.classList.add('mobile-qrcode-mask');
    await BarcodeScanner.addListener(
      'barcodeScanned',
      async result => {
        console.log(result.barcode);
        if (result.barcode == null) return;
        const { search } = new URL(result.barcode.displayValue);
        const params = new URLSearchParams(search);

        if (!params.get('connectionNumber')) {
          return;
        }

        this.connectionNumber = params.get('connectionNumber');
        this.getControllerTicketError = null;
        await this.stopScan();
      },
    );
    await BarcodeScanner.startScan();
  }

  async stopScan(): Promise<void> {
    if (this.isQRCodeScannerOpen === false) return;
    console.log('stopScan');
    document.querySelector('body')?.classList.remove('bg_transparent');
    document.querySelector('lv-layout-mobile .container')?.classList.remove('mobile-qrcode-mask');
    this.isQRCodeScannerOpen = false;
    await BarcodeScanner.stopScan();
  }

  ngOnInit(): void {
    this.filteredTimeZones = this.timeZoneControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterTimezones(value || '')),
    );

    this.sub$.add(
      this.actions$.pipe(ofType(getControllerTicketInfoByConnectionNumberSuccess))
        .subscribe(() => {
          this.currStep = this.currStep + 1;
        }),
    );

    this.sub$.add(
      this.store.select(selectGetControllerTicketError)
        .subscribe((getControllerTicketError) => {
          if (getControllerTicketError) {
            this.connectionNumber = '';
          }
          this.getControllerTicketError = getControllerTicketError;
        }),
    );

    this.sub$.add(
      this.store.select(selectIsControllerTicketExecuted).pipe(
        wasChanged(),
        withLatestFrom(this.controllerTicket$),
      ).subscribe(([{ isControllerTicketExecuted, creatingControllerError }, controllerTicket]) => {
        if (creatingControllerError?.includes(GlobalConstants.HouseNumberAlreadyExistsError)) {
          this.activeFarm = {
            ...this.activeFarm,
            existingHouseNumbers: [
              ...this.activeFarm.existingHouseNumbers,
              controllerTicket.houseNumber,
            ],
          };
          return;
        }

        if (isControllerTicketExecuted) {
          setTimeout(() => {
            this.store.dispatch(
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.AcceptedControllerMessage',
                  additionalInfo: {
                    houseNumber: controllerTicket.houseNumber,
                    farmName: this.activeFarm.name,
                    serialNumber: controllerTicket.sn,
                  },
                },
              }),
            );
          });
          this.store.dispatch(clearIsControllerCreatedOrExecuted());
          this.store.dispatch(setMobileViewMode({ mobileViewMode: MobileViewModeEnum.Farms }));
          this.router.navigate(['farm/' + this.activeFarm?.farmID]);
        }
      }),
    );

    this.sub$.add(
      this.store.select(selectActiveFarm).pipe(wasChangedAndNotNull())
        .subscribe(activeFarm => {
          this.activeFarm = activeFarm;
          if (activeFarm?.existingHouseNumbers) {
            this.houseNumber = this.getMinCorrectHouseNumber(activeFarm?.existingHouseNumbers);
          }
        }),
    );
  }

  goBack(): void {
    this.store.dispatch(setMobileViewMode({ mobileViewMode: MobileViewModeEnum.Farms }));
    this.router.navigate([`${AppRoutes.FARM}/${this.activeFarm.farmID}`]);
  }

  displayTimeZoneFn(timezone: INameId): string {
    return timezone?.name || '';
  }

  onFocusOutHouseNumberInput(): void {
    this.houseNumberInputInFocus = false;
    this.houseNumberPlaceholder = 'AddController.HouseNumber';
  }

  onFocusInHouseNumberInput(): void {
    this.houseNumberInputInFocus = true;
    this.houseNumberPlaceholder = '';
  }

  onFocusOutTimeZoneInput(): void {
    this.timeZoneInputInFocus = false;
    this.timeZonePlaceholder = 'AddController.Timezone';
  }

  onFocusInTimeZoneInput(): void {
    this.timeZoneInputInFocus = true;
    this.timeZonePlaceholder = '';
  }

  getControllerNumberIconColor(
    [houseNumber, houseNumberInputInFocus, existingHouseNumbers]: [number, boolean, number[]],
  ): string {
    return (!houseNumber || existingHouseNumbers?.includes(houseNumber) || houseNumber > GlobalConstants.MAX_HOUSE_NUMBER)
      ? ColorsEnum.MainRed
      : houseNumberInputInFocus
        ? ColorsEnum.MainDarkBlue
        : ColorsEnum.MonoDark;
  }

  isInvalidHouseNumber([houseNumber, existingHouseNumbers]: [number, number[]]): boolean {
    return !houseNumber ||
    existingHouseNumbers?.includes(houseNumber) ||
    houseNumber > GlobalConstants.MAX_HOUSE_NUMBER;
  }

  getMinCorrectHouseNumber(existingHouseNumbers: number[]): number {
    const integers = Array.from({ length: existingHouseNumbers.length + 1 }, (_, index) => index + 1);
    return Math.min(...integers.filter(x => x > 0 && !existingHouseNumbers.filter(x => x > 0).includes(x)));
  }

  saveNewHouseNumber(houseNumber: number): void {
    if (!houseNumber ||
      houseNumber > GlobalConstants.MAX_HOUSE_NUMBER ||
      this.activeFarm?.existingHouseNumbers?.includes(houseNumber)
    ) {
      return;
    }

    this.store.dispatch(setValidControllerTicketHouseNumber({ houseNumber }));
  }

  confirmCode(connectionNumber: string, waitASecond = false): void {
    if (connectionNumber?.length < GlobalConstants.CONNECTION_NUMBER_LENGTH) {
      return;
    }
    if (waitASecond) {
      setTimeout(() => {
        this.store.dispatch(getControllerTicketInfoByConnectionNumber({ connectionNumber }));
      }, 1000);
      return;
    }

    this.store.dispatch(getControllerTicketInfoByConnectionNumber({ connectionNumber }));
  }

  assign(selected: IFarm, controller: IGetAddControllerByConnectionNumberTicketView, timeZoneID: number): void {
    if (!selected || !timeZoneID || !controller) return;

    this.store.dispatch(executeTicketController({
      requestedView: {
        farmID: selected.farmID,
        timeZoneID,
        houseNumber: controller.houseNumber,
      },
      ticketID: controller.ticketID,
    }));
  }

  async ngOnDestroy(): Promise<void> {
    this.store.dispatch(clearIsControllerCreatedOrExecuted());
    this.sub$.unsubscribe();
    await this.stopScan();
  }

  private filterTimezones(value: string | INameId): INameId[] {
    const filterValue = typeof value === 'string'
      ? this.normalizeValue(value)
      : this.normalizeValue(value.name);
    this.toHighlight = filterValue;
    return this.timeZones.filter(zone => this.normalizeValue(zone.name).includes(filterValue));
  }

  private normalizeValue(value: string): string {
    return value.toLowerCase().trim();
  }
}
