import { ControllerUnitEnum, HoursFormatTypeEnum } from '@livestock/controllers/enums';
import { IUnitsDetails } from '../basic/units-details.interface';
import { TimeZonesEnum } from '@livestock/shared/constants';
import { FarmControllerStatusEnum } from '../../../../../farms/src/lib/enum/farm-controller-status.enum';

export interface IControllerView {
  controllerID: number;
  sn: string;
  connectedDate: number;
  connectedByUserID: number;
  connectedByUserEmail: string;
  farmID: number;
  farmName: string;
  units: ControllerUnitEnum;
  unitsDetails: IUnitsDetails;
  hoursFormat: HoursFormatTypeEnum;
  houseNumber: number;
  timeZoneID: TimeZonesEnum;
  isDefaultFlock: boolean;
  connectionStatus: boolean;
  // TODO: amend field names during integration with real data
  day?: number;
  temp?: number;
  humidity?: number;
  score?: number;
  name?: string;
  status?: FarmControllerStatusEnum;
  connected?: boolean;
}
