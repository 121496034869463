import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ElementEventInfo, IElement, IGetOrUpdateElement } from '../interfaces';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { IElementSetupRunTestView } from '@livestock/installation/interfaces';

export const getAllElements = createAction(
  '[INSTALLATION] Get all elements [...]',
);

export const getAllElementsSuccess = createAction(
  '[INSTALLATION] Get all elements [SUCCESS]',
  props<{ elements: IElement[] }>(),
);

export const getAllElementsError = createAction(
  '[INSTALLATION] Get all elements [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setActiveElementType = createAction(
  '[INSTALLATION] Set Active Element Type',
  props<{ elementType: ElementTypesEnum }>(),
);

export const createElementSetup = createAction(
  '[INSTALLATION] Create element setup [...]',
  props<{
    cardNumber: number,
    connectionNumber: number,
    elementNumber: number,
    elementType: ElementTypesEnum,
  }>(),
);

export const createElementSetupSuccess = createAction(
  '[INSTALLATION] Create element setup [SUCCESS]',
  props<{ elementSetup: any, elementType: ElementTypesEnum }>(),
);

export const createElementSetupError = createAction(
  '[INSTALLATION] Create element setup [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const getElementSetup = createAction(
  '[INSTALLATION] Get element setup [...]',
);

export const getElementSetupSuccess = createAction(
  '[INSTALLATION] Get element setup [SUCCESS]',
  props<{ elementSetup: IGetOrUpdateElement }>(),
);

export const getElementSetupError = createAction(
  '[INSTALLATION] Get element setup [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateElementSetup = createAction(
  '[INSTALLATION] Update element setup [...]',
  props<{ elementSetup: IGetOrUpdateElement }>(),
);

export const updateElementSetupSuccess = createAction(
  '[INSTALLATION] Update element setup [SUCCESS]',
  props<{ elementSetup: IGetOrUpdateElement }>(),
);

export const updateElementSetupError = createAction(
  '[INSTALLATION] Update element setup [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const deleteElementSetup = createAction(
  '[INSTALLATION] Delete element setup [...]',
);

export const deleteElementSetupSuccess = createAction(
  '[INSTALLATION] Delete element setup [SUCCESS]',
  props<{ elementType: ElementTypesEnum }>(),
);

export const deleteElementSetupError = createAction(
  '[INSTALLATION] Delete element setup [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const runElementEvents = createAction(
  '[INSTALLATION] Run Element Events [...]',
  props<{ numbers: number[] }>(),
);

export const runElementEventsSuccess = createAction(
  '[INSTALLATION] Run Element Events [SUCCESS]',
);

export const runElementEventsError = createAction(
  '[INSTALLATION] Run Element Events [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const runElementEventsViaDeviceSuccess = createAction(
  '[INSTALLATION] Run Element Events Via Device [SUCCESS]',
  props<{ elementInfo: ElementEventInfo, elementType: ElementTypesEnum }>(),
);

export const runElementEventsViaDeviceError = createAction(
  '[INSTALLATION] Run Element Events Via Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const runElementTests = createAction(
  '[INSTALLATION] Run Element Tests [...]',
  props<{
    elementID: number;
    response: IElementSetupRunTestView;
  }>(),
);

export const runElementTestsSuccess = createAction(
  '[INSTALLATION] Run Element Tests [SUCCESS]',
);

export const runElementTestsError = createAction(
  '[INSTALLATION] Run Element Tests [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);
