import { ControllerLanguageEnum } from '../../enums/controller/controller-language.enum';
import { ControllerUnitEnum } from '../../enums/controller/controller-unit.enum';
import { HoursFormatTypeEnum } from '../../enums/date-time/hours-format-type.enum';
import { BirdTypeEnum } from '../../../../../flock/src/lib/enums';

export interface ICreateControllerView {
  sn: string;
  connectedByUserID: number;
  farmID: number;
  language: ControllerLanguageEnum;
  units: ControllerUnitEnum;
  houseNumber: number;
  birdType: BirdTypeEnum;
  timeZone: string;
  manuallyDateTime?: string;
  hoursFormat: HoursFormatTypeEnum;
  modem: number;
  wifi: number;
  lan: number;
}
