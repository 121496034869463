export enum InOutdoorTemperatureStatusEnum {
  Idle = 0,
  SensorOK = 1,
  Detached = 2,
  OutOfRange = 3,
  IOCom = 4,
  SensorNum = 5,
}

export namespace InOutdoorTemperatureStatusEnum {

  export function getErrorStatuses(): InOutdoorTemperatureStatusEnum[] {
    return [
      InOutdoorTemperatureStatusEnum.OutOfRange,
    ];
  }

  export function getEmptyValueStatuses(): InOutdoorTemperatureStatusEnum[] {
    return [
      InOutdoorTemperatureStatusEnum.Idle,
      InOutdoorTemperatureStatusEnum.Detached,
      InOutdoorTemperatureStatusEnum.IOCom,
      InOutdoorTemperatureStatusEnum.SensorNum,
    ];
  }

  export function toTranslateKey(value: InOutdoorTemperatureStatusEnum): string {
    switch (value) {
      case InOutdoorTemperatureStatusEnum.Idle:
        return 'Installation.IndoorTemperature.StatusEnum.Idle';
      case InOutdoorTemperatureStatusEnum.SensorOK:
        return 'Installation.IndoorTemperature.StatusEnum.SensorOK';
      case InOutdoorTemperatureStatusEnum.Detached:
        return 'Installation.IndoorTemperature.StatusEnum.Detached';
      case InOutdoorTemperatureStatusEnum.OutOfRange:
        return 'Installation.IndoorTemperature.StatusEnum.OutOfRange';
      case InOutdoorTemperatureStatusEnum.IOCom:
        return 'Installation.IndoorTemperature.StatusEnum.IOCom';
      case InOutdoorTemperatureStatusEnum.SensorNum:
        return 'Installation.IndoorTemperature.StatusEnum.SensorNum';
      default:
        return (value as unknown).toString();
    }
  }
}