import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import * as farmActions from './farms.actions';
import * as farmSelectors from './farms.selectors';
import { selectActiveFarmID } from './farms.selectors';
import { Store } from '@ngrx/store';
import { ICreateFarm } from '../interfaces/create-farm.interface';
import { IFarmInfo } from '../interfaces/farm-info.interface';
import { IUpdateFarm } from '../interfaces/update-farm.interface';
import { FarmsService } from '../services/farms.service';
import { IInvitedUser, IUser } from '@livestock/shared/interfaces';
import {
  setFlashMessage,
} from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';
import { Router } from '@angular/router';
import { AppRoutes } from '@livestock/shared/routes';
import { LocalStorageService } from '@livestock/shared/services';
import { StorageItem } from '@livestock/shared/enums';
import { ControllerInfoService, selectActiveControllerID, setActiveControllerID } from '@livestock/controllers';
import { FarmCreationMode, TicketFarmsService } from '@livestock/farms';
import { IWebSocketResponse, SocketEventConnectionStateEnum } from '@livestock/web-sockets';

@Injectable()
export class FarmsEffects {
  getFarms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.getFarms),
      withLatestFrom(this.store.select(farmSelectors.selectFarms)),
      filter(([_, farms]) => farms == null || farms?.length == 0),
      exhaustMap(() => {
        return this.farmsService.getFarms().pipe(
          map(({ farms }) =>
            farmActions.getFarmsSuccess({ farms }),
          ),
          catchError((error) =>
            of(farmActions.getFarmsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getFarmById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.getFarmById),
      map((action) => action.farmID),
      switchMap((farmID: number) => {
        return this.farmsService.getFarmById(farmID).pipe(
          map((farm) => farmActions.getFarmByIdSuccess({ farm })),
          catchError((error) =>
            of(farmActions.getFarmByIdError({ payload: error })),
          ),
        );
      }),
    ),
  );

  createFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.createFarm),
      map((action): [ICreateFarm, FarmCreationMode] => [action.farm, action.creationMode]),
      switchMap(([farm, creationMode]: [ICreateFarm, FarmCreationMode]) => {
        return this.farmsService.createFarm(farm).pipe(
          map((farm: IFarmInfo) => farmActions.createFarmSuccess({ farm, creationMode })),
          catchError((error) =>
            of(farmActions.createFarmError({ payload: error })),
          ),
        );
      }),
    ),
  );

  updateFarmById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.updateFarmById),
      map((action): [number, IUpdateFarm] => [action.farmID, action.farm]),
      switchMap(([farmID, farm]: [number, IUpdateFarm]) => {
        return this.farmsService.updateFarmById(farmID, farm).pipe(
          switchMap(_ => {
            return [
              farmActions.updateFarmByIdSuccess({ farmID, farm }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.FarmWasSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.updateFarmByIdError({ payload: error })),
          ),
        );
      }),
    ),
  );

  deleteFarmById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.deleteFarmById),
      map((action) => action.farmID),
      switchMap((farmID: number) => {
        return this.farmsService.deleteFarmById(farmID).pipe(
          switchMap(() => {
            return [
              farmActions.deleteFarmByIdSuccess({ farmID }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.FarmWasSuccessfullyDeleted',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.deleteFarmByIdError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getFarmControllers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.getFarmControllers),
      map(action => action.farmID),
      switchMap((farmID: number) => {
        return this.farmsService.getFarmControllers(farmID).pipe(
          switchMap(({ controllers }) => {
            /* selected */
            const controllerID: number = controllers.some(c => c.controllerID === +LocalStorageService.getStorageItem(StorageItem.ActiveControllerID))
              ? +LocalStorageService.getStorageItem(StorageItem.ActiveControllerID)
              : controllers[0]?.controllerID;

            return [
              farmActions.getFarmControllersSuccess({ farmID, controllers }),
              setActiveControllerID({ controllerID }),
            ];
          }),
          catchError((error) => {
            this.router.navigate([AppRoutes.FARMS]);
            return of(farmActions.getFarmControllersError({ payload: error }));
          }),
        );
      }),
    ),
  );

  deleteFarmController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.deleteControllerFromFarm),
      map(deleteControllerFromFarm => deleteControllerFromFarm.controllerID),
      switchMap(controllerID => {
        return this.controllerInfoService.deleteController(controllerID).pipe(
          switchMap(() => [
            farmActions.deleteControllerFromFarmSuccess({ controllerID }),
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'FlashMessages.ControllerDeleteSuccess',
              },
            }),
          ]),
          catchError(error => {
            return of(farmActions.deleteControllerFromFarmError({ payload: error }));
          }),
        );
      }),
    ),
  );

  // updates only the store
  updateFarmControllerOnlineStatus$ = createEffect(() =>
    this.store.select(farmSelectors.selectControllerConnectionStatusEvents).pipe(
      filter((events: IWebSocketResponse[]) => events?.length > 0),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      map(([onlineStatusUpdate, controllerID]: [IWebSocketResponse[], number]) => {
        return farmActions.setControllerOnlineStatus({
          controllerID,
          isOnline: onlineStatusUpdate[0]?.dataEvent?.connectionStatus === SocketEventConnectionStateEnum.Online,
        });
      }),
    ),
  );

  getFarmUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.getFarmUsers),
      map((action) => action.farmID),
      switchMap((farmID: number) => {
        return this.farmsService.getFarmUsers(farmID).pipe(
          map(({ users }) =>
            farmActions.getFarmUsersSuccess({ farmID, users }),
          ),
          catchError((error) => {
            this.router.navigate([AppRoutes.FARMS]);
            return of(farmActions.getFarmUsersError({ payload: error }));
          }),
        );
      }),
    ),
  );

  inviteFarmUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.inviteFarmUser),
      map((action) => action.invitedUser),
      withLatestFrom(this.store.select(selectActiveFarmID)),
      switchMap(([invitedUser, farmID]: [IInvitedUser, number]) => {
        return this.farmsService.inviteFarmUser(farmID, invitedUser).pipe(
          switchMap((user: IUser) => {
            return [
              farmActions.inviteFarmUserSuccess({ farmID, user }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.UserWasSuccessfullyInvited',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.inviteFarmUserError({ payload: error })),
          ),
        );
      }),
    ),
  );

  resendInviteFarmUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.resendInviteFarmUser),
      withLatestFrom(this.store.select(selectActiveFarmID)),
      switchMap(([{ ticketID, invitedUser }, activeFarmID]: [any, number]) => {
        const farmID = activeFarmID || +LocalStorageService.getStorageItem(StorageItem.ActiveFarmID);
        return this.farmsService.resendInviteFarmUser(farmID, ticketID, invitedUser).pipe(
          switchMap((user: IUser) => {
            return [
              farmActions.resendInviteFarmUserSuccess({ farmID, user }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.TheInvitationWasSuccessfullyResent',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.inviteFarmUserError({ payload: error })),
          ),
        );
      }),
    ),
  );

  deleteFarmUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.deleteFarmUser),
      map((action): [number, string] => [action.farmID, action.email]),
      switchMap(([farmID, email]: [number, string]) => {
        return this.farmsService.deleteFarmUser(farmID, email).pipe(
          switchMap(() => {
            return [
              farmActions.deleteFarmUserSuccess({ farmID, email }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.UserWasSuccessfullyDeleted',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.deleteFarmUserError({ payload: error })),
          ),
        );
      }),
    ),
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.updateUser),
      map((action) => action.user),
      withLatestFrom(this.store.select(farmSelectors.selectActiveFarmID)),
      switchMap(([user, farmID]) => {
        return this.farmsService.updateUser(farmID, user).pipe(
          switchMap(() => {
            return [
              farmActions.updateUserSuccess({ user }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.UserWasSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.deleteFarmUserError({ payload: error })),
          ),
        );
      }),
    ),
  );

  /*Tickets*/
  acceptExistingUserToFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.acceptExistingUserToFarm),
      map((action) => action.ticketID),
      switchMap((ticketID: string) => {
        return this.ticketFarmsService.acceptExistingUserToFarm(ticketID).pipe(
          map(() => farmActions.acceptExistingUserToFarmSuccess()),
          catchError((error) =>
            of(farmActions.acceptExistingUserToFarmError({ payload: error })),
          ),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions,
    private farmsService: FarmsService,
    private controllerInfoService: ControllerInfoService,
    private ticketFarmsService: TicketFarmsService,
  ) {
  }
}
