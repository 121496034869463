import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlockState } from './flock.state';
import { FLOCK_FEATURE_KEY } from './flock.reducer';

export const getFlockState =
  createFeatureSelector<FlockState>(FLOCK_FEATURE_KEY);

export const selectIsLoading = createSelector(
  getFlockState,
  ({ isLoading }) => isLoading,
);

export const selectFlockSettings = createSelector(
  getFlockState,
  ({ flockSettings }) => flockSettings,
);

export const selectNextAvailableFlockNumber = createSelector(
  selectFlockSettings,
  (flockSettings) => flockSettings?.flockNumber + 1,
);
