export enum IconsFlockEnum {
  MALE = 'flock/male',
  FEMALE = 'flock/female',
  MALE_BIG = 'flock/male-big',
  FEMALE_BIG = 'flock/female-big',
  DOUBLE_CHECKMARK = 'flock/double-checkmark',
  ADD_DAY = 'flock/add-day',
  ARROW_SELECT = 'flock/arrow-select',
  ADJUST_INDICATOR = 'flock/adjust-indicator',
  ARROWS_UP_DOWN = 'flock/restore-flock',
  FLOCK_GRAPH = 'flock/flock-graph',
  FLOCK_WEIGHTS = 'flock/flock-weights',
  FLOCK_SETTINGS = 'flock/flock-settings',
}
