import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { controllerMenuSections } from '../../constants/controller-menu-sections';
import { IControllerMenuItem } from '../../interfaces/controller-menu-item.interface';
import { SvgIconComponent } from '@livestock/ui';
import { ColorsEnum } from '@livestock/shared/enums';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { filter, Observable, startWith, Subscription } from 'rxjs';
import { ControllerMenuSectionsEnum } from '../../enums/controller-menu-sections.enum';
import { selectActiveMenuSection, selectActivePage } from '../../+state/controller-menu.selectors';
import { ControllerMenuPagesEnum } from '../../enums/controller-menu-pages.enum';
import { basicSectionPages } from '../../constants/mandatory-section-pages';
import { changeControllerPage, changeMenuSection, toggleMenuIsOpened } from '../../+state/controller-menu.actions';
import { NavigationEnd, Router } from '@angular/router';
import { IControllerPageItem } from '../../interfaces/controller-page-item.interface';
import { fadeInOutFast } from '@livestock/shared/animations';
import { PlatformService } from '@livestock/shared/services';
import { ControllerMenuMobileComponent } from '../controller-menu-mobile/controller-menu-mobile.component';
import { selectActiveControllerID } from '@livestock/controllers';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { QaTagsDirective } from '@livestock/shared/directives';
import { controlSectionPages } from '../../constants/control-section-pages';
import { flockSectionPages } from '../../constants/flock-section-pages';
import { logsReportsSectionPages } from '../../constants/logs-reports-section-pages';
import { systemSectionPages } from '../../constants/system-section-pages';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    TranslateModule,
    ControllerMenuMobileComponent,
    QaTagsDirective,
  ],
  selector: 'lv-controller-menu',
  templateUrl: './controller-menu.component.html',
  styleUrls: ['./controller-menu.component.scss'],
  animations: [fadeInOutFast],
})
export class ControllerMenuComponent implements OnInit, OnDestroy {
  //injects
  platformService: PlatformService = inject(PlatformService);
  private store: Store = inject(Store);
  private router: Router = inject(Router);

  // vars
  activeControllerID: number;

  //subs
  sub$ = new Subscription();
  activeMenuSection$: Observable<ControllerMenuSectionsEnum> = this.store.select(selectActiveMenuSection);
  activePage$: Observable<ControllerMenuPagesEnum> = this.store.select(selectActivePage);

  //constants
  sectionChildren: Record<ControllerMenuSectionsEnum, IControllerPageItem[]> = {
    [ControllerMenuSectionsEnum.Control]: controlSectionPages,
    [ControllerMenuSectionsEnum.Flock]: flockSectionPages,
    [ControllerMenuSectionsEnum.LogsReports]: logsReportsSectionPages,
    [ControllerMenuSectionsEnum.System]: systemSectionPages,
    [ControllerMenuSectionsEnum.BasicSettings]: basicSectionPages,
  };

  controllerMenuSections: IControllerMenuItem[] = controllerMenuSections;
  disabledMenuSections: ControllerMenuSectionsEnum[] = [];

  //enums
  ColorsEnum = ColorsEnum;
  ControllerMenuSectionsEnum = ControllerMenuSectionsEnum;

  ngOnInit(): void {
    this.sub$.add(
      this.router.events.pipe(
        startWith(this.router),
        filter(event => event instanceof NavigationEnd || event instanceof Router),
      ).subscribe((event) => {
        /* /controller/999/units-settings => units-settings */
        const url = event['url']
          .split('/')
          .slice(3)
          .join('/');

        const activeMenuSection: ControllerMenuSectionsEnum = ControllerMenuPagesEnum.getSectionOfRoute(url);
        const activePage: ControllerMenuPagesEnum = ControllerMenuPagesEnum.routeToEnum(url);
        this.store.dispatch(changeMenuSection({ activeMenuSection }));
        this.store.dispatch(changeControllerPage({ activePage }));
      }),
    );

    this.sub$.add(
      this.store.select(selectActiveControllerID).pipe(
        wasChanged(),
      ).subscribe((controllerID) => {
        this.activeControllerID = controllerID;
      }),
    );
  }

  changeMenuSection(activeMenuSection: ControllerMenuSectionsEnum): void {
    this.store.dispatch(changeMenuSection({ activeMenuSection }));
  }

  // mockup
  goToPage(route: string): void {
    this.router.navigate([`controller/${this.activeControllerID}/${route}`]);
    this.closeMenu();
  }

  closeMenu(): void {
    this.store.dispatch(toggleMenuIsOpened({ menuIsOpened: false }));
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  onScrollSections(wheelEvent: WheelEvent): void {
    const scrollContainer: HTMLElement = wheelEvent.currentTarget as HTMLElement;

    wheelEvent.preventDefault();
    scrollContainer.scrollLeft += +wheelEvent.deltaY;
  }
}
