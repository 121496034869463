import { Action, createReducer, on } from '@ngrx/store';
import * as farmActions from './farms.actions';
import { IUser } from '@livestock/shared/interfaces';
import * as upsertControllerActions
  from '../../../../controllers/src/lib/+state/upsert-controller/upsert-controller.actions';
import * as currentControllerActions
  from '../../../../controllers/src/lib/+state/current-controller/current-controller.actions';
import { LocalStorageService } from '@livestock/shared/services';
import { StorageItem } from '@livestock/shared/enums';
import { IController } from '../interfaces/controller.interface';
import { FarmsState } from './farms.state';
import { CloudConnectionEnum } from '@livestock/controllers/enums';
import * as parringProcessActions from '../../../../parring-process/src/lib/+state/parring-process.actions';
import { IFarm } from '../interfaces/farm.interface';
import { FarmCreationMode } from '../enum/farm-creation-mode.enum';
import * as moment from 'moment';
import { GlobalConstants } from '@livestock/shared/constants';

export const FARMS_FEATURE_KEY = 'farms';

export const initialState: FarmsState = {
  isLoading: false,
  areUsersLoading: false,
  farms: [],
  activeFarm: null,
  users: [],
  controllers: [],
  addUserToFarmTicketView: null,
  farmWasCreatedOrDeleted: false,
  userDetailsMode: null,
};

const reducer = createReducer(
  initialState,
  on(
    farmActions.getFarmById,
    farmActions.createFarm,
    farmActions.updateFarmById,
    farmActions.deleteFarmById,
    farmActions.getFarmControllers,
    farmActions.deleteControllerFromFarm,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    farmActions.getFarmsError,
    farmActions.getFarmByIdError,
    farmActions.createFarmError,
    farmActions.updateFarmByIdError,
    farmActions.deleteFarmByIdError,
    farmActions.getFarmControllersError,
    farmActions.deleteControllerFromFarmError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(
    farmActions.getFarms, (state) => {
      return {
        ...state,
        isLoading: !state.farms?.length,
      };
    },
  ),
  on(farmActions.getFarmsSuccess,
    (state, { farms }) => {
      let activeFarm = state.activeFarm;
      if (activeFarm) {
        const activeFarmFromList = farms.find(x => x.farmID === state.activeFarm?.farmID);
        activeFarm = {
          ...state.activeFarm,
          existingHouseNumbers: activeFarmFromList?.existingHouseNumbers,
        };
      }

      return {
        ...state,
        farms: farms.map(farm => {
          return {
            ...farm,
            existingHouseNumbers: Array.from(new Set(farm.existingHouseNumbers)).sort((a, b) => a - b),
          };
        }),
        activeFarm,
        isLoading: false,
      };
    }),
  on(farmActions.getFarmByIdSuccess, (state, { farm }) => {
    const farms = [...state.farms];
    const activeFarmFromList = farms.find(x => x.farmID === farm?.farmID);
    LocalStorageService.setStorageItem(StorageItem.ActiveFarmID, farm?.farmID);
    return {
      ...state,
      activeFarm: {
        ...farm,
        existingHouseNumbers: activeFarmFromList?.existingHouseNumbers,
      },
      isLoading: false,
    };
  }),
  on(farmActions.createFarmSuccess, (state, { farm, creationMode }) => {
    return {
      ...state,
      farms: [...state.farms, {
        ...farm,
        existingHouseNumbers: farm.existingHouseNumbers || [],
      }],
      activeFarm: null,
      farmWasCreatedOrDeleted: creationMode === FarmCreationMode.Default,
      isLoading: false,
    };
  }),
  on(farmActions.updateFarmByIdSuccess, (state, { farmID, farm }) => {
    const farms = [...state.farms];
    const index = farms.findIndex(farm => farm.farmID === farmID);
    let updatedFarm = { ...state.activeFarm };

    if (index !== -1) {
      updatedFarm = {
        ...updatedFarm,
        ...farm,
      };
      farms[index] = {
        ...farms[index],
        name: updatedFarm.name,
        address: updatedFarm.address,
      };
    }

    return {
      ...state,
      farms,
      activeFarm: updatedFarm,
      isLoading: false,
    };
  }),
  on(farmActions.deleteFarmByIdSuccess, (state, { farmID }) => {
    const farms = [...state.farms].filter(farm => farm.farmID !== farmID);
    LocalStorageService.setStorageItem(StorageItem.ActiveFarmID, farms?.[0]?.farmID);

    return {
      ...state,
      farms,
      isLoading: false,
    };
  }),
  on(farmActions.deleteControllerFromFarmSuccess,
    farmActions.softDeleteControllerFromTheFarm, (state, { controllerID }) => {
      if (!state.activeFarm) {
        return state;
      }

      const farms: IFarm[] = JSON.parse(JSON.stringify(state.farms));
      const selectedFarmIndex: number = farms.findIndex(farm => farm.farmID === state.activeFarm.farmID);

      if (selectedFarmIndex !== -1) {
        const deletedControllerHouseNumber = state.controllers.find(x => x.controllerID === controllerID)?.houseNumber;
        farms[selectedFarmIndex].existingHouseNumbers =
          farms[selectedFarmIndex].existingHouseNumbers.filter(x => x != deletedControllerHouseNumber);
      }
      return {
        ...state,
        farms,
        activeFarm: {
          ...state.activeFarm,
          controllersCount: state.activeFarm.controllersCount - 1,
        },
        controllers: state.controllers.filter(controller => controller.controllerID !== controllerID),
        isLoading: false,
      };
    }),
  on(farmActions.setControllerAsAccepted, (state, { controllerID }) => {
    const controllers = [...state.controllers];
    const index = controllers.findIndex(c => c.controllerID == controllerID);

    if (index !== -1) {
      controllers[index] = {
        ...controllers[index],
        cloudConnectionAccept: CloudConnectionEnum.Accept,
      };
    }
    return {
      ...state,
      controllers,
    };
  }),
  on(farmActions.setControllerOnlineStatus, (state, { controllerID, isOnline: connectionStatus }) => {
    const controllers = [...state.controllers];
    const activeIndex: number = controllers.findIndex(controller => controller.controllerID === controllerID);

    if (activeIndex !== -1) {
      controllers[activeIndex] = { ...controllers[activeIndex], connectionStatus };
    }

    return {
      ...state,
      controllers,
    };
  }),

  /*USERS*/
  on(
    farmActions.getFarmUsers,
    farmActions.updateUser,
    farmActions.deleteFarmUser,
    farmActions.inviteFarmUser,
    farmActions.resendInviteFarmUser,
    (state) => {
      return {
        ...state,
        areUsersLoading: true,
      };
    },
  ),
  on(
    farmActions.getFarmUsersError,
    farmActions.updateUserError,
    farmActions.deleteFarmUserError,
    farmActions.inviteFarmUserError,
    farmActions.resendInviteFarmUserSuccess,
    (state) => {
      return {
        ...state,
        areUsersLoading: false,
      };
    },
  ),
  on(farmActions.getFarmUsersSuccess, (state, { users }) => {
    return {
      ...state,
      users: users.map((user) => {
        if (user.userID == null) {
          user = JSON.parse(JSON.stringify(user));
          user.invitationSentDate = moment.utc(user.invitationSentDate).local().format(GlobalConstants.DateFormat); // to local time
          user.resendDate = moment.utc(user.resendDate).local().format(GlobalConstants.DateFormat);
        }
        return user;
      }),
      areUsersLoading: false,
    };
  }),
  on(farmActions.updateUserSuccess, (state, { user }) => {
    const users = [...state.users];
    const index = users.findIndex(u => user.userID === u.userID);
    if (index !== -1) {
      users[index] = {
        ...users[index],
        ...user,
      };
    }

    return {
      ...state,
      users,
      areUsersLoading: false,
      userDetailsMode: null,
    };
  }),
  on(farmActions.resetActiveFarm, (state) => {
    return {
      ...state,
      activeFarm: null,
    };
  }),
  on(farmActions.setFarmWasCreatedOrDeleted, (state, { farmWasCreatedOrDeleted }) => {
    return {
      ...state,
      farmWasCreatedOrDeleted,
    };
  }),
  on(farmActions.deleteFarmUserSuccess, (state, { email }) => {
    return {
      ...state,
      users: state.users.filter(user => user.email !== email),
      areUsersLoading: false,
      userDetailsMode: null,
    };
  }),
  on(
    farmActions.setUserDetailsMode, (state, { userDetailsMode }) => {
      return {
        ...state,
        userDetailsMode,
      };
    },
  ),
  on(farmActions.inviteFarmUserSuccess, (state, { user }) => {
    const users = [...state.users];
    const userIndex = users.findIndex(u => u.email === user.email);

    if (userIndex !== -1) {
      users[userIndex] = {
        ...users[userIndex],
        ...user,
        invitationSentDate: moment.utc(user.invitationSentDate).local().format(GlobalConstants.DateFormat),
        resendDate: moment.utc(user.resendDate).local().format(GlobalConstants.DateFormat),
      };

      return {
        ...state,
        users,
        activeFarm: {
          ...state.activeFarm,
          usersCount: state.activeFarm?.usersCount + 1,
        },
        areUsersLoading: false,
        userDetailsMode: null,
      };
    }

    const modifiedUser: IUser = {
      ...user,
      tableID: users.length + 1,
      invitationSentDate: moment.utc(user.invitationSentDate).local().format(GlobalConstants.DateFormat),
      resendDate: moment.utc(user.resendDate).local().format(GlobalConstants.DateFormat),
    };

    return {
      ...state,
      users: [...users, modifiedUser],
      activeFarm: {
        ...state.activeFarm,
        usersCount: state.activeFarm?.usersCount + 1,
      },
      areUsersLoading: false,
      userDetailsMode: null,
    };
  }),
  on(farmActions.resendInviteFarmUserSuccess, (state, { user }) => {
    const users = [...state.users];
    const index = users.findIndex(u => u.email === user.email);

    if (index !== -1) {
      users[index] = {
        ...users[index],
        ...user,
        invitationSentDate: moment.utc(user.invitationSentDate).local().format(GlobalConstants.DateFormat),
        resendDate: moment.utc(user.resendDate).local().format(GlobalConstants.DateFormat),
      };
    }

    return {
      ...state,
      users,
      areUsersLoading: false,
      userDetailsMode: null,
    };
  }),

  //tickets
  on(farmActions.setAddUserToFarmTicketView, (state, { addUserToFarmTicketView }) => {
    return {
      ...state,
      addUserToFarmTicketView,
    };
  }),
  on(
    farmActions.acceptExistingUserToFarmSuccess,
    farmActions.acceptExistingUserToFarmError,
    (state) => {
      return {
        ...state,
        addUserToFarmTicketView: null,
      };
    }),
  // controllers
  on(farmActions.getFarmControllersSuccess, (state, { controllers }) => {
    return {
      ...state,
      controllers,
      isLoading: false,
    };
  }),
  on(parringProcessActions.executeTicketControllerSuccess, (state, { requestedView, isReconnect }) => {
    // TODO Now I don't know how to hundle this case
    if (isReconnect) {
      return state;
    }

    const farms: IFarm[] = JSON.parse(JSON.stringify(state.farms));
    const selectedFarmIndex: number = farms.findIndex(farm => farm.farmID === requestedView.farmID);

    if (selectedFarmIndex !== -1) {
      farms[selectedFarmIndex].existingHouseNumbers = farms[selectedFarmIndex].existingHouseNumbers
        ? [...farms[selectedFarmIndex].existingHouseNumbers, requestedView.houseNumber].sort((a, b) => a - b)
        : [requestedView.houseNumber];
    }

    return {
      ...state,
      farms,
    };
  }),
  on(upsertControllerActions.updateGeneralSettingsSuccess, (state, { generalSettings }) => {
    const { controllerID } = generalSettings;
    const controllers = [...state.controllers] as IController[];
    const index = controllers.findIndex(c => c.controllerID == controllerID);

    if (index !== -1) {
      controllers[index] = {
        ...controllers[index],
        controllerID,
      };
    }
    return {
      ...state,
      controllers,
      isLoading: false,
    };
  }),
  on(currentControllerActions.updateControllerHouseSizesSettingsSuccess, (state, {
    houseSettings,
    prevHouseNumber,
  }) => {
    const farms: IFarm[] = JSON.parse(JSON.stringify(state.farms));
    const controllers: IController[] = JSON.parse(JSON.stringify(state.controllers));
    const selectedFarmIndex: number = farms.findIndex(farm => farm.farmID === state.activeFarm.farmID);
    const currentControllerIndex: number = controllers.findIndex(controller => controller.controllerID === +LocalStorageService.getStorageItem(StorageItem.ActiveControllerID));

    if (selectedFarmIndex !== -1) {
      farms[selectedFarmIndex].existingHouseNumbers = [
        ...farms[selectedFarmIndex].existingHouseNumbers.filter(num => num !== prevHouseNumber),
        houseSettings.houseNumber,
      ];
    }

    if (currentControllerIndex !== -1) {
      controllers[currentControllerIndex].houseNumber = houseSettings.houseNumber;
    }

    return {
      ...state,
      activeFarm: {
        ...state.activeFarm,
        existingHouseNumbers: [
          ...(state.activeFarm.existingHouseNumbers || []).filter(num => num !== prevHouseNumber),
          houseSettings.houseNumber,
        ],
      },
      farms,
      controllers,
    };
  }),
);

export function farmsReducer(state: FarmsState | undefined, action: Action): any {
  return reducer(state, action);
}
