import { Validators } from '@angular/forms';
import { ButtonTypeEnum } from '../enums';

export enum SelectDialogCssClass {
  DEFAULT = 'default',
  MOBILE = 'mobile'
}

export interface ISelectDialogOptions {
  fieldTitle?: string;
  translateFieldTitle?: boolean;
  validators?: Validators[];
  list?: any[];
  enum?: any;
  visibleEnumItems?: any[];
  enumString?: string;
  value?: any;
  additionalInfo?: string;
  bindValue?: string;
  bindLabel?: string;
  bindSubLabel?: string;
  searchable?: boolean;
  dialogStyle?: SelectDialogCssClass;
}

export interface IConfirmDialogData {
  title: string,
  description: string,
  type: 'danger' | 'default'
  confirmLabelKey?: string;
  cancelLabelKey?: string;
  confirmButtonType?: ButtonTypeEnum;
  cancelButtonType?: ButtonTypeEnum;
  showNotReversibleWarning?: boolean;
  panelClass?: string[];
  backdropClass?: string[];
  reverseOrder?: boolean;
}
