export enum SortingEnum {
  ASC,
  DESC,
}

export namespace SortingEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case SortingEnum.ASC:
        return 'SortingEnum.ASC';
      case SortingEnum.DESC:
        return 'SortingEnum.DESC';
      default:
        return value.toString();
    }
  }

  export function toTranslateKeyAZ(value: any): string {
    switch (value) {
      case SortingEnum.ASC:
        return 'SortingEnum.AZ';
      case SortingEnum.DESC:
        return 'SortingEnum.ZA';
      default:
        return value.toString();
    }
  }
}
