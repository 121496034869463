<div
  [class.input-disabled]="isDisabled"
  class="main-input-container position-relative {{ wrapperClassList }}">
  <div
    (click)="focusInput()"
    [class.right-button-exist]="isRightButtonExist"
    [class.right-icon-exist]="isRightIconExist"
    class="ls-input">
    <ng-content select="[leftIcon]"></ng-content>

    <div class="input-wrapper">
      <input
        (ngModelChange)="onModelChange($event)"
        (focus)="inputFocus.emit()"
        (blur)="inputBlur.emit()"
        #input
        [autofocus]="autofocus"
        [disabled]="isDisabled"
        [maxlength]="maxlength"
        [ngModel]="value"
        [pattern]="pattern"
        [placeholder]="placeholder || ''"
        [qaTags]="qaTags"
        [type]="type"
        [autocomplete]="autocomplete"
        class="text-{{ textPosition }}"
        spellcheck="false" />
      <label
        [class.input-label_filled]="value != null && value !== ''"
        class="input-label">
        {{ label | translate }}
        <ng-container *ngIf="labelFieldType">
          - <span class="input-label_field-type">{{ labelFieldType | translate }}</span>
        </ng-container>
      </label>
    </div>

    <div
      #rightIcon
      [class.right-icon]="isRightIconExist">
      <ng-content select="[rightIcon]"></ng-content>
    </div>
    <div
      #rightButton
      [class.right-button]="isRightButtonExist">
      <ng-content select="[rightButton]"></ng-content>
    </div>
  </div>
  <div class="description">
    <ng-content select="[description]"></ng-content>
  </div>
</div>
