<div class="popup d-flex flex-column gap-8">
  <h1>{{ "Controller.QuickStart.CloudConnection" | translate }}</h1>

  <p>
    <!-- <span>{{ data?.email }}</span>
    {{ "Controller.QuickStart.from" | translate }}
    <span>{{ data?.farm }}</span>
    {{ "Controller.QuickStart.wantsToConnect" | translate }} -->

    {{ "Controller.QuickStart.ThisDeviceWasSuccessfullyConnected" | translate }}
    <span>{{ data?.farm }}</span>
    {{ "Controller.QuickStart.by" | translate }}
    <span>{{ data?.email }}</span>
  </p>

  <section class="d-flex flex-row justify-end mt-10">
    <lv-button
      (click)="close(true)"
      [type]="ButtonTypeEnum.PRIMARY"
      [qaTags]="'qa-btn-accept'">
      {{ "Buttons.OK" | translate }}
    </lv-button>

    <!-- <lv-button
      (click)="close(false)"
      [type]="ButtonTypeEnum.WARNING"
      [qaTags]="'qa-btn-reject'">
      {{ "Buttons.Reject" | translate }}
    </lv-button> -->
  </section>
</div>
