export class InstallationConstants {
  static MinOutIndoorTempOffsetCelsius = -10;
  static MinOutIndoorTempOffsetFahrenheit = 14;
  static MaxOutIndoorTempOffsetCelsius = 10;
  static MaxOutIndoorTempOffsetFahrenheit = 50;
  static MinCO2Offset = -500;
  static MaxCO2Offset = 500;
  static MinOutIndoorHumOffset = -10;
  static MaxOutIndoorHumOffset = 10;
  static MinWaterMeterQuantity = 0;
  static MaxWaterMeterQuantity = 999;
  static OperationNormallyOpen = false;
  static OperationNormallyClose = true;

  static MinTestTime = 30;
  static MaxTestTime = 360;

  static VoltMin = 0;
  static VoltMax = 10;
}
