export enum FarmControllerStatusEnum {
    NewHouse = 1,
    FullHouse = 2,
    Disconnected = 3,
    WaitingForApproval = 4,
}

export namespace FarmControllerStatusEnum {
    export function toTranslateKey(value: any): string {
      switch (value) {
        case FarmControllerStatusEnum.NewHouse:
          return 'FarmController.Status.NewHouse';
        case FarmControllerStatusEnum.FullHouse:
          return 'FarmController.Status.FullHouse';
        case FarmControllerStatusEnum.Disconnected:
          return 'FarmController.Status.Disconnected';
        case FarmControllerStatusEnum.WaitingForApproval:
          return 'FarmController.Status.WaitingForApproval';
        default:
          return value.toString();
      }
    }
  }
