import { Component } from '@angular/core';
import { QuickStartContainerComponent } from '../quick-start-container/quick-start-container.component';

@Component({
  standalone: true,
  selector: 'ls-web-quick-start',
  imports: [
    QuickStartContainerComponent,
  ],
  templateUrl: './quick-start.component.html',
  styleUrls: ['./quick-start.component.scss'],
})
export class QuickStartWebComponent {
}
