import { QuickStartStepsEnum } from '@livestock/controllers/enums';
import { ButtonWizardTypeEnum, IconsEnum } from '@livestock/shared/enums';

export interface QuickStartStepType {
  title: string;
  icon: typeof IconsEnum[keyof typeof IconsEnum];
  type: ButtonWizardTypeEnum;
  tab: QuickStartStepsEnum;
  couldBeVisited?: boolean;
}
