import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { FARMS_FEATURE_KEY } from './farms.reducer';
import { FarmUserRolesEnum } from '@livestock/shared/enums';
import { IUser } from '@livestock/shared/interfaces';
import { FarmsState } from './farms.state';
import { CloudConnectionEnum, QuickStartStatusTypeEnum } from '@livestock/controllers/enums';
import {
  EventCodeEnum,
  SocketEventControllerTypeEnum,
  selectWebSocketEvents,
} from '@livestock/web-sockets';
import { IWebSocketResponse } from '@livestock/web-sockets';
import { FarmControllerStatusEnum } from '../enum/farm-controller-status.enum';
import { IController } from '../interfaces/controller.interface';

// Lookup the 'Farms' feature state managed by NgRx
export const selectFarmsState =
  createFeatureSelector<FarmsState>(FARMS_FEATURE_KEY);

export const selectIsLoading = createSelector(selectFarmsState,
  ({ isLoading }) => isLoading);

export const selectAreUsersLoading = createSelector(selectFarmsState,
  ({ areUsersLoading }) => areUsersLoading);

export const selectFarms = createSelector(selectFarmsState,
  ({ farms }) => farms);

export const selectFirstFarm = createSelector(selectFarms,
  (farms) => farms[0]);

export const selectActiveFarm = createSelector(selectFarmsState,
  (state) => state?.activeFarm);

export const selectActiveFarmName = createSelector(selectActiveFarm,
  (activeFarm) => activeFarm?.name);

export const selectActiveFarmNameSliced = createSelector(selectActiveFarm,
  (activeFarm) => activeFarm?.name && (activeFarm?.name.length > 25 ? activeFarm?.name.slice(0, 25) + '...' : activeFarm?.name));

export const selectActiveFarmID = createSelector(selectActiveFarm,
  (activeFarm) => activeFarm?.farmID);

export const selectExistingHouseNumbers = createSelector(
  selectFarms,
  selectActiveFarmID,
  (farms, farmID) => farms.find(farm => farm.farmID === farmID)?.existingHouseNumbers,
);

export const selectNextAvailableHouseNumber = createSelector(
  selectExistingHouseNumbers,
  (existingHouseNumbers) => {
    if (!existingHouseNumbers) return 1;

    let nextAvailableNumber = 1;
    for (let i = 1; i < 1000; i++) {
      if (existingHouseNumbers.includes(i)) {
        continue;
      }

      nextAvailableNumber = i;
      break;
    }

    return nextAvailableNumber;
  },
);

export const selectFarmUsersById = (farmID: number): MemoizedSelector<FarmsState, IUser[] | undefined> => createSelector(selectFarmsState,
  ({ users }): any => users.filter(user => user.farmID === farmID));

export const selectFarmUserRoleByUserID = (userID: number | undefined): MemoizedSelector<FarmsState, FarmUserRolesEnum> => createSelector(selectFarmsState,
  ({ users, activeFarm }) => {
    return users.find(user => user.userID === userID && activeFarm?.farmID === user.farmID)?.accessesRole;
  });

export const selectAddUserToFarmTicketView = createSelector(selectFarmsState,
  ({ addUserToFarmTicketView }) => addUserToFarmTicketView);

export const selectActiveFarmUsers = createSelector(selectFarmsState,
  ({ users }) => users);

export const selectFarmControllers = createSelector(selectFarmsState,
  (state) => state?.controllers);

export function selectFarmControllerByID(controllerID: number): MemoizedSelector<object, IController, (s1: FarmsState) => IController> {
  return createSelector(selectFarmsState,
    ({ controllers }): IController => {
      return controllers?.find(controller => controller.controllerID === controllerID);
    },
  );
}

export const selectUserDetailsMode = createSelector(selectFarmsState,
  ({ userDetailsMode }) => userDetailsMode);

export const selectControllerConnectionStatusEvents = createSelector(
  selectWebSocketEvents,
  ({ events }) => {
    return events
      .filter((e) => e.ec === EventCodeEnum.Controller
        && e.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.ConnectionStatus);
  });

export const selectControllerQuickStartStatusEvents = createSelector(
  selectWebSocketEvents,
  ({ events }) => {
    return events
      .filter((e) => e.ec === EventCodeEnum.Controller
        && e.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.QuickStartStatus);
  });

export const selectControllerCloudConnectionAcceptEvents = createSelector(
  selectWebSocketEvents,
  ({ events }) => {
    return events
      .filter((e) => e.ec === EventCodeEnum.Controller
        && e.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.CloudConnectionAccept);
  });

export const selectControllers = createSelector(
  selectFarmControllers,
  selectControllerConnectionStatusEvents,
  selectControllerQuickStartStatusEvents,
  selectControllerCloudConnectionAcceptEvents,
  (controllers, connectionStatusEvents, quickStartStatusEvents, cloudConnectionAcceptEvents) => {
    return controllers?.map((controller) => {
      const connectionStatusEvent = connectionStatusEvents?.find(x => x.controlUnitID === controller.controllerID);
      const quickStartStatusEvent = quickStartStatusEvents?.find(x => x.controlUnitID === controller.controllerID);
      const cloudConnectionAcceptEvent = cloudConnectionAcceptEvents?.find(x => x.controlUnitID === controller.controllerID);
      // Will be use normal data in future
      return {
        ...controller,
        day: 4,
        temp: 28.4,
        humidity: 71,
        score: 5.1,
        name: `House ${controller.houseNumber}`,
        status: getControllerStatus(controller, connectionStatusEvent, quickStartStatusEvent, cloudConnectionAcceptEvent),
        connected: connectionStatusEvent?.dataEvent?.connectionStatus,
      };
    });
  });

export const selectControllersExist = createSelector(
  selectControllers,
  (controllers) => controllers.length > 0,
);

export const selectFarmWasCreatedOrDeleted = createSelector(selectFarmsState,
  ({ farmWasCreatedOrDeleted }) => farmWasCreatedOrDeleted);

export const selectAcceptedController = createSelector(
  selectWebSocketEvents,
  selectFarmControllers,
  ({ events }, controllers) => {
    const acceptedControllersEvents = events
      .filter((e) => e.ec === EventCodeEnum.Controller
        && e.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.CloudConnectionAccept
        && e.dataEvent?.cloudConnectionAccept === CloudConnectionEnum.Accept);
    if (acceptedControllersEvents?.length === 0) {
      return null;
    }
    return controllers.find(x => !x.cloudConnectionAccept &&
      acceptedControllersEvents.some(c => c.controlUnitID == x.controllerID));
  });

export const selectRejectedController = createSelector(
  selectWebSocketEvents,
  selectFarmControllers,
  ({ events }, controllers) => {
    const rejectedControllersEvents = events
      .filter((e) => e.ec === EventCodeEnum.Controller
        && e.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.CloudConnectionAccept
        && e.dataEvent?.cloudConnectionAccept === CloudConnectionEnum.Reject);
    if (rejectedControllersEvents?.length === 0) {
      return null;
    }
    return controllers.find(x => !x.cloudConnectionAccept &&
      rejectedControllersEvents.some(c => c.controlUnitID == x.controllerID));
  });

export const selectDisconnectedController = createSelector(
  selectWebSocketEvents,
  selectFarmControllers,
  ({ events }, controllers) => {
    const disconnectedControllersEvents = events
      .filter((e) => e.ec === EventCodeEnum.Controller
        && e.dataEvent?.controllerEventType === SocketEventControllerTypeEnum.CloudConnectionAccept
        && e.dataEvent?.cloudConnectionAccept === CloudConnectionEnum.Disconnect);
    if (disconnectedControllersEvents?.length === 0) {
      return null;
    }
    return controllers.find(x => disconnectedControllersEvents.some(c => c.controlUnitID == x.controllerID));
  });

function getControllerStatus(
  controller: IController,
  connectionStatusEvent: IWebSocketResponse,
  quickStartStatusEvent: IWebSocketResponse,
  cloudConnectionAcceptEvent: IWebSocketResponse,
): FarmControllerStatusEnum {
  if (controller.cloudConnectionAccept !== CloudConnectionEnum.Accept &&
    cloudConnectionAcceptEvent?.dataEvent?.cloudConnectionAccept !== CloudConnectionEnum.Accept
  ) {
    // TODO WaitingForApproval Status will be used in future versions
    // return FarmControllerStatusEnum.WaitingForApproval;
    return FarmControllerStatusEnum.NewHouse;
  }

  // TODO we ignore missing event for now (first condition will be removed in future)
  if (connectionStatusEvent?.dataEvent?.connectionStatus != null &&
    !connectionStatusEvent?.dataEvent?.connectionStatus) {
    return FarmControllerStatusEnum.Disconnected;
  }

  if (quickStartStatusEvent?.dataEvent?.quickStartStatus != null) {
    return !quickStartStatusEvent.dataEvent.quickStartStatus
      ? FarmControllerStatusEnum.NewHouse
      : FarmControllerStatusEnum.FullHouse;
  }

  return controller.quickStartStatus === QuickStartStatusTypeEnum.NewHouse
    ? FarmControllerStatusEnum.NewHouse
    : FarmControllerStatusEnum.FullHouse;
}
