import { CloudConnectionEnum, QuickStartStatusTypeEnum } from '@livestock/controllers/enums';
import { FarmControllerStatusEnum } from '../enum/farm-controller-status.enum';

export interface IController {
  controllerID: number;
  houseNumber: number;
  sn: string;
  cloudConnectionAccept: CloudConnectionEnum;
  quickStartStatus: QuickStartStatusTypeEnum;
  // TODO: ammend field names during integration with real data
  day?: number;
  temp?: number;
  humidity?: number;
  score?: number;
  name?: string;
  status?: FarmControllerStatusEnum;
  connectionStatus?: boolean;
}
