import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { GlobalConstants } from '@livestock/shared/constants';
import { Router } from '@angular/router';

export const AuthGuard = async (): Promise<boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const canActivate = await authService.canActivate();

  if (canActivate) {
    return true;
  }

  console.log(
    '%cTOKEN EXPIRED OR NOT FOUND!',
    'color: red; font-size: x-large',
  );
  return router.navigate([GlobalConstants.authLoginPage]);
};
