<div class="overflow-hidden d-flex gap-2">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="16"
    viewBox="0 0 16 32"
    fill="none">
    <path
      [attr.stroke]="leftArrowColor"
      d="M14 24L8 30M8 30L2 24M8 30V2"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>

  <svg
    [class.arrow-animation]="arrowsType === ArrowsConnectEnum.ConnectionInProgress"
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="16"
    viewBox="0 0 16 32"
    fill="none">
    <path
      [attr.stroke]="rightArrowColor"
      d="M2 8L8 2M8 2L14 8M8 2V30"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</div>
