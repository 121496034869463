import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { errFade } from '@livestock/shared/animations';
import { QaTagsDirective } from '@livestock/shared/directives';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { NativeElementInjectorDirective } from '../native-element.directive';
import {
  setVirtualKeyboardElementUuid,
  setVirtualKeyboardMode, setVirtualKeyboardSymbol,
} from '../virtual-keyboard/+state/virtual-keyboard.actions';
import { Store } from '@ngrx/store';
import { VirtualKeyboardModesEnum } from '../virtual-keyboard/virtual-keyboard-modes.enum';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import {
  selectVirtualKeyboardElementUUID,
  selectVirtualKeyboardSymbolAndUUID,
} from '../virtual-keyboard/+state/virtual-keyboard.selectors';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { VirtualKeyboardButtonsEnum } from '../virtual-keyboard/virtual-keyboard-buttons.enum';
import { GlobalConstants } from '@livestock/shared/constants';

@Component({
  selector: 'lv-input',
  templateUrl: './lv-input.component.html',
  styleUrls: ['./lv-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    QaTagsDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    TranslateModule,
    NativeElementInjectorDirective,
  ],
  animations: [
    errFade,
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => LVInputComponent),
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => LVInputComponent),
  },
    provideNgxMask(),
  ],
})
export class LVInputComponent implements ControlValueAccessor, Validator, OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('input') inputRef: ElementRef;
  @ViewChild('rightButton', { static: false }) rightButton: ElementRef = new ElementRef(null);
  @ViewChild('rightIcon', { static: false }) rightIcon: ElementRef = new ElementRef(null);

  @Input() formControlName: string = '';
  @Input() label: string = '';
  @Input() labelFieldType: string;
  @Input() placeholder: string = '';
  @Input() type: 'text' | 'password' | 'email' = 'text';
  @Input() autocomplete: string;
  @Input() maxlength: number = GlobalConstants.MaxLength;
  @Input() textPosition: 'left' | 'center' | 'right' = 'left';
  @Input() autofocus: boolean = false;
  @Input() pattern: string = '';
  @Input() options: number[] = [];
  @Input() isDisabled: boolean = false;
  @Input() qaTags: string;
  @Input() hideTopLabel: boolean;
  @Input() wrapperClassList: string = '';

  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() inputFocus: EventEmitter<any> = new EventEmitter();
  @Output() inputBlur: EventEmitter<any> = new EventEmitter();

  sub$ = new Subscription();

  control: AbstractControl = new FormControl();
  filteredOptions: number[] = [];
  isRightButtonExist = false;
  isRightIconExist = false;

  private _value: any;

  @HostListener('focusin', ['$event'])
  onInputClick(): void {
    if (this.platformService.isDeviceApp) {
      this.store.dispatch(setVirtualKeyboardElementUuid({
        elementUuid: this.inputRef.nativeElement.getAttribute('uuid'),
      }));

      this.store.dispatch(setVirtualKeyboardMode({ mode: VirtualKeyboardModesEnum.Full }));
    }

    if (this.inputRef.nativeElement.value.length) {
      setTimeout(() => {
        this.inputRef.nativeElement.type = 'text';
        this.inputRef.nativeElement.setSelectionRange(999, 999);
        this.inputRef.nativeElement.type = this.type;
      });
    }
  }

  @HostListener('focusout')
  async unsetFormControl(): Promise<void> {
    const activeFormControl = await firstValueFrom(this.store.select(selectVirtualKeyboardElementUUID));
    if (activeFormControl === this.inputRef.nativeElement.getAttribute('uuid')) {
      this.store.dispatch(setVirtualKeyboardElementUuid({
        elementUuid: null,
      }));
    }
  }

  constructor(
    public languageService: LanguageService,
    private cdr: ChangeDetectorRef,
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
    private store: Store,
    private platformService: PlatformService,
  ) {
  }

  @Input('value')
  set value(value: string) {
    this._value = value;
  }

  get value(): string {
    return this._value;
  }

  get isRtl(): boolean {
    return this.languageService.isRtl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['value']) {
      this.writeValue(this._value);
    }
  }

  ngOnInit(): void {
    // virtual keyboard logic
    if (this.platformService.isDeviceApp) {
      this.sub$.add(
        this.store.select(selectVirtualKeyboardElementUUID).pipe(
          wasChanged(),
          filter(res => res == null),
        ).subscribe(() => {
          this.inputRef?.nativeElement.blur();
        }),
      );

      this.sub$.add(
        this.store.select(selectVirtualKeyboardSymbolAndUUID).pipe(
          wasChanged(),
          filter(({ symbol, elementUuid }) => {
            return symbol != null && elementUuid === this.inputRef?.nativeElement.getAttribute('uuid');
          }),
        ).subscribe(({ symbol }) => {
          if (symbol === VirtualKeyboardButtonsEnum.DEL) {
            this.removeSymbol();
            this.store.dispatch(setVirtualKeyboardSymbol({ symbol: null }));
            return;
          }

          this.printSymbol(symbol as string);
          this.store.dispatch(setVirtualKeyboardSymbol({ symbol: null }));
        }),
      );
    }

    if (this.formControlName && this.controlContainer?.control) {
      this.control = this.controlContainer.control.get(this.formControlName) as AbstractControl;

      if (this.control) {
        this.isDisabled = this.isDisabled || this.control.disabled;

        this.control.statusChanges.subscribe(() => {
          this.isDisabled = this.control.disabled;
        });
      }
    }

    this.filterOptions(this.value);
  }

  ngAfterViewInit(): void {
    this.isRightButtonExist = this.rightButton?.nativeElement?.children?.length > 0;
    this.isRightIconExist = this.rightIcon?.nativeElement?.children?.length > 0;
    this.cdr.detectChanges();
  }

  removeSymbol(): void {
    const { value, selectionStart } = this.inputRef.nativeElement;
    const currentValue = value.toString().split('');
    currentValue.splice(selectionStart - 1, 1);
    this.propagateChange(currentValue.join(''));
    this.onModelChange(currentValue.join(''));
    this.inputRef.nativeElement.value = currentValue.join('');

    setTimeout(() => this.inputRef.nativeElement.setSelectionRange(selectionStart - 1, selectionStart - 1));
  }

  printSymbol(symbol: string): void {
    const { value, selectionStart } = this.inputRef.nativeElement;
    const currentValue = value.toString().split('');
    currentValue.splice(selectionStart, 0, symbol);
    this.propagateChange(currentValue.join(''));
    this.onModelChange(currentValue.join(''));
    this.inputRef.nativeElement.value = currentValue.join('');

    setTimeout(() => this.inputRef.nativeElement.setSelectionRange(selectionStart + 1, selectionStart + 1));
  }

  writeValue(value: any): void {
    this._value = value;
  }

  propagateChange: (value: any) => void = () => {
  };

  _onTouched = (): void => {
  };

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => object): void {
    this._onTouched = fn;
  }

  focusInput(): void {
    this.inputRef.nativeElement.focus();
  }

  validate(): ValidationErrors | null {
    return null;
  }

  onModelChange(e: string): void {
    this.value = e;
    this.propagateChange(e);
    this.onChange.emit(this.value);
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  private filterOptions(start: string): void {
    this.filteredOptions = this.options.filter(option => String(option).startsWith(start || ''));
  }
}
