import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EnumPipe } from '@livestock/shared/pipes';
import { SvgIconComponent } from '@livestock/ui';
import { ColorsEnum, ElementTypesEnum, InstallationElementGroupsEnum } from '@livestock/shared/enums';
import { IElement } from '@livestock/installation/interfaces';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    EnumPipe,
    SvgIconComponent,
  ],
  selector: 'lv-installation-elements-list',
  templateUrl: './elements-list.component.html',
  styleUrls: ['./elements-list.component.scss'],
})
export class ElementsListComponent implements AfterViewInit {
  @ViewChild('container') container: ElementRef;

  @Input() selectedElementGroup: InstallationElementGroupsEnum;
  @Input() elements: IElement[];
  @Output() setActiveElement = new EventEmitter<{
    activeElementType: ElementTypesEnum,
    activeElementIndex: number,
    isLastElementSelected: boolean,
  }>();

  // vars
  hasScroll: boolean;
  activeElementType: ElementTypesEnum;
  activeElementIndex: number;

  // enums
  InstallationElementGroupsEnum = InstallationElementGroupsEnum;
  ElementTypesEnum = ElementTypesEnum;
  ColorsEnum = ColorsEnum;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.hasScroll = this.container.nativeElement.scrollHeight > this.container.nativeElement.offsetHeight;
    });
  }

  setActiveElementEmit(activeElementType: ElementTypesEnum, activeElementIndex: number): void {
    this.activeElementType = activeElementType;
    this.activeElementIndex = activeElementIndex;
    this.setActiveElement.emit({
      activeElementType,
      activeElementIndex,
      isLastElementSelected: activeElementIndex === this.elements.length - 1,
    });
  }
}
