export enum ControllerUnitEnum {
  Metric,
  Imperial,
  Custom,
}

export namespace ControllerUnitEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case ControllerUnitEnum.Metric:
        return 'Controller.GeneralSettings.UnitsDialog.UnitEnum.Metric';
      case ControllerUnitEnum.Imperial:
        return 'Controller.GeneralSettings.UnitsDialog.UnitEnum.Imperial';
      case ControllerUnitEnum.Custom:
        return 'Controller.GeneralSettings.UnitsDialog.UnitEnum.Custom';
      default:
        return value.toString();
    }
  }
}
