export enum SectorTypeEnum {
  QuickStartGeneral = 1,
  QuickStartHouseSizes = 2,
  QuickStartDateTime = 3,
  QuickStartCustomUnits = 4,
  QuickStartCloud = 5,
  Card = 6,
  Connection = 7,
  FlockSettings = 8,
  FlockWeightsSettings = 9,
  FlockBirdWeight = 10,
  IndoorTemperatureSensor = 12,
  OutdoorTemperatureSensor = 13,
  IndoorHumiditySensor = 14,
  OutdoorHumiditySensor = 15,
  IndoorTemperatureSensorName = 16,
  OutdoorTemperatureSensorName = 17,
  IndoorHumiditySensorName = 18,
  OutdoorHumiditySensorName = 19,
  FlockStatus = 20,
}
