<div class="d-flex gap-18 flex-wrap wrapper mb-18">
  <ng-container *ngFor="let section of controllerMenuSections">
    <div
      (click)="changeMenuSection(section.id)"
      [ngClass]="{
        'menu-section__disabled': disabledMenuSections.includes(section.id),
        'menu-section__active': (activeMenuSection$ | async) === section.id
      }"
      class="menu-section">
      <div class="d-flex align-center w-100-percent menu-section__title p-18">
        <ls-svg-icon [path]="section.icon"></ls-svg-icon>
        <span class="ml-8 fz-14 fw-500">{{ section.title | translate }}</span>
      </div>

      <ng-container *ngIf="(activeMenuSection$ | async) === section.id">
        <div class="d-flex flex-wrap gap-8 menu-pages menu-pages__active pl-18">
          <div
            *ngFor="let page of sectionChildren[section.id]"
            (click)="goToPage(page.route)"
            [ngClass]="{
              'menu-page__active': (activePage$ | async) === page.id
            }"
            class="menu-page d-flex flex-column justify-center align-center">
            <ls-svg-icon [path]="page.icon"></ls-svg-icon>
            <span class="mt-8 fz-10">{{ page.title | translate }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
