import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';
import {
  getControllerGeneralSettingsOnly,
  selectControllerGeneralSettings,
} from '@livestock/controllers';

export const generalSettingsOnlyResolver: ResolveFn<boolean> =
  async () => {
    const store = inject(Store);

    const generalSettings = await firstValueFrom(store.select(selectControllerGeneralSettings));

    if (generalSettings?.controllerID == null) {
      store.dispatch(getControllerGeneralSettingsOnly());

      await firstValueFrom(store.select(selectControllerGeneralSettings).pipe(
        filter(info => info != null), // <- waiting until data is present
      ));
    }

    return true;
  };
