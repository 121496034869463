import { createSelector } from '@ngrx/store';

// TODO: Fix direct importing files from libs;
import { getCurrentControllerState } from '../common/current-controller-main.selector';

export const selectLastEditedInfo = createSelector(
  getCurrentControllerState,
  ({ lastEditedInfo }) => lastEditedInfo,
);

export const selectEditModeInfo = createSelector(
  getCurrentControllerState,
  ({ editModeInfo }) => editModeInfo,
);

