<div class="container">
  <div class="content-wrap">
    <div class="wrapper">
      <div class="form-wrapper">
        <div class="d-flex flex-row justify-between align-center">
          <div class="small-logo">
            <img
              *ngIf="showTopLogo"
              src="/assets/images/plascon-logo.png" />
          </div>
          <div
            (click)="openLanguagesMenu()"
            #toggleMenuLanguages
            class="language-button pointer">
            <ls-svg-icon
              [path]="IconsEnum.EARTH"
              class="mr-8">
            </ls-svg-icon>
            {{ language | enum : "controller-language" | translate }}
          </div>
          <div
            *ngIf="showLanguages"
            #menuLanguages
            [@slideInRight]
            class="menu-languages">
            <div class="menu-languages_header">
              <div class="d-flex flex-row align-center">
                <ls-svg-icon
                  [path]="IconsEnum.EARTH_BLACK"
                  class="mr-8">
                </ls-svg-icon>
                <span>{{ "Auth.Login.Language" | translate }}</span>
              </div>
              <ls-svg-icon
                (click)="closeLanguagesMenu()"
                [path]="IconsEnum.CLOSE"
                class="pointer">
              </ls-svg-icon>
            </div>
            <lv-radio-list
              (change)="changeLanguage($event)"
              [value]="language"
              [list]="languagesList"
              direction="vertical"
              size="small" />
          </div>
        </div>

        <div
          *ngIf="!showTopLogo"
          class="header">
          <div class="logo">
            <span>{{ "Auth.Welcome" | translate }}</span>
            <img
              class="mt-8"
              src="/assets/images/plascon-big-logo.png" />
          </div>
        </div>
        <div class="body">
          <router-outlet></router-outlet>
        </div>

        <div class="footer">
          <div
            *ngIf="
              !(router.url | memoizeFunc : activatedRouteService.isForgotPasswordPage) &&
              !(router.url | memoizeFunc : activatedRouteService.isSignUpPage)
            "
            class="policy-wrap">
            <span
              [qaTags]="'qa-lnk-terms'"
              class="footer-text fz-12">
              {{ "Livestock.TermsaAndConditions" | translate }}
            </span>
            <div class="divider"></div>
            <span
              [qaTags]="'qa-lnk-policy'"
              class="footer-text fz-12">
              {{ "Livestock.PrivacyPolicy" | translate }}
            </span>
          </div>
          <span class="footer-title fz-10">
            {{ "Livestock.PoweredBy" | translate | lowercase }}
            <img src="/assets/images/galcon-full-logo.png" />
          </span>
          <ng-container *ngIf="this.buildInfoService.json$ | async as data">
            <span class="version fz-12">
              v{{ data.version }}{{ this.buildInfoService.environmentPrefix }} {{ data.release }} {{ data.branch }}#{{
                data.commit
              }}</span
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
