import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  PageTypeEnum,
} from '@livestock/shared/enums';
import {
  IEditMode,
  IGetCNFStatusView,
  ILastEdited,
} from '@livestock/controllers/interfaces';
import { PlatformService } from '@livestock/shared/services';
import { baseUrl, pureDeviceControllerUrl } from './basic-api-url.constants';

@Injectable({
  providedIn: 'root',
})
export class ControllerEditModeService {
  private http: HttpClient = inject(HttpClient);
  private platformService: PlatformService = inject(PlatformService);

  getLastEdited(controllerID: number, page: PageTypeEnum): Observable<ILastEdited> {
    return this.platformService.isDeviceApp
      ? this.http.get<ILastEdited>(`${pureDeviceControllerUrl}/page/${page}/last-edited`)
      : this.http.get<ILastEdited>(`${baseUrl}/${controllerID}/page/${page}/last-edited`);
  }

  getEditMode(controllerID: number, page: PageTypeEnum): Observable<IEditMode> {
    return this.http.get<IEditMode>(`${baseUrl}/${controllerID}/page/${page}/edit-mode`);
  }

  createEditMode(controllerID: number, page: PageTypeEnum): Observable<IEditMode> {
    return this.http.post<IEditMode>(`${baseUrl}/${controllerID}/page/${page}/edit-mode`, null);
  }

  updateEditMode(controllerID: number, page: PageTypeEnum): Observable<IEditMode> {
    return this.http.put<IEditMode>(`${baseUrl}/${controllerID}/page/${page}/edit-mode`, null);
  }

  deleteEditMode(controllerID: number, page: PageTypeEnum): Observable<IEditMode> {
    return this.http.delete<IEditMode>(`${baseUrl}/${controllerID}/page/${page}/edit-mode`);
  }

  getCnfStatus(controllerID: number, page: PageTypeEnum): Observable<IGetCNFStatusView> {
    return this.http.get<IGetCNFStatusView>(`${baseUrl}/${controllerID}/page/${page}/cnf-status`);
  }
}
