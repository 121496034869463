import {
  ControllerMenuPagesEnum,
  IControllerPageItem,
} from '@livestock/menu';
import { IconsEnum } from '@livestock/shared/enums';
import { ControllerRoutes } from '../../../../controllers/src/lib/constants/controller.routes';

export const basicSectionPages: IControllerPageItem[] = [
  {
    id: ControllerMenuPagesEnum.UnitsSettings,
    title: 'ControllerMenu.Pages.Units',
    icon: IconsEnum.UNITS,
    route: ControllerRoutes.UnitsSettings,
    qaTags: 'qa-mnu-unitsSettings',
  },
  {
    id: ControllerMenuPagesEnum.HouseSettings,
    title: 'ControllerMenu.Pages.HouseSettings',
    icon: IconsEnum.HOUSE_SETTINGS,
    route: ControllerRoutes.HouseSettings,
    qaTags: 'qa-mnu-houseSettings',
  },
  {
    id: ControllerMenuPagesEnum.NetworkSettings,
    title: 'ControllerMenu.Pages.Network',
    icon: IconsEnum.NETWORK,
    route: ControllerRoutes.NetworkSettings,
    qaTags: 'qa-mnu-networkSettings',
  },
  {
    id: ControllerMenuPagesEnum.CloudSettings,
    title: 'ControllerMenu.Pages.Cloud',
    icon: IconsEnum.CLOUD,
    route: ControllerRoutes.CloudSettings,
    qaTags: 'qa-mnu-cloudSettings',
  },
  {
    id: ControllerMenuPagesEnum.DateTimeSettings,
    title: 'ControllerMenu.Pages.DateTime',
    icon: IconsEnum.DATE_TIME,
    route: ControllerRoutes.DateTimeSettings,
    qaTags: 'qa-mnu-dateTimeSettings',
  },
];
