import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PARRING_PROCESS_FEATURE_KEY, ParringProcessState } from './parring-process.reducer';

export const selectParringProcessState =
  createFeatureSelector<ParringProcessState>(PARRING_PROCESS_FEATURE_KEY);

export const selectIsLoading = createSelector(selectParringProcessState,
  (state) => state.isLoading,
);

export const selectTicketId = createSelector(selectParringProcessState,
  (state) => state?.ticketId);

export const selectControllerInfo = createSelector(selectParringProcessState,
  (state) => state?.ticketView);

export const isScannedLoginInProgress = createSelector(selectParringProcessState,
  (state) => !!state?.ticketId);

export const isTicketReconnect = createSelector(selectParringProcessState,
  (state) => state?.isReconnect);

export const selectControllerTicketByConnectionNumber = createSelector(
  selectParringProcessState,
  (state) => {
    return state.controllerTicketByConnectionNumber;
  },
);

export const selectGetControllerTicketError = createSelector(
  selectParringProcessState,
  (state) => {
    return state.getControllerTicketError;
  },
);

export const selectIsControllerTicketExecuted = createSelector(
  selectParringProcessState,
  (state) => {
    return {
      isControllerTicketExecuted: state?.isControllerTicketExecuted,
      creatingControllerError: state?.creatingControllerError,
    };
  },
);
