import { Component, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, map, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { PlatformService } from '@livestock/shared/services';
import { SvgIconComponent } from '@livestock/ui';
import { IconsEnum } from '@livestock/shared/enums';
import {
  INetworkSettingsView,
  ControllerNetworkSettingsFormComponent,
  selectIsLoading,
  selectCurrentControllerNetworkSettings,
  getCurrentControllerNetworkSettings,
  selectNetworkSettings,
  getNetworkStatus,
  selectCellularIsLoading,
  updateLanSettings,
  getWifiList,
  disconnectModem,
  connectWifi,
  IWifiConnection,
  disconnectWifi,
  IModemCredentials,
  connectModem,
  setModemState,
} from '@livestock/controllers';
import { SettingsHeaderComponent } from '../settings-header/settings-header.component';
import { CurrentNetworkStatusComponent } from '../../ui/network-settings-form/current-network-status/current-network-status.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
    ControllerNetworkSettingsFormComponent,
    SettingsHeaderComponent,
    CurrentNetworkStatusComponent,
  ],
  selector: 'lv-network-settings',
  templateUrl: './network-settings.component.html',
  styleUrls: ['./network-settings.component.scss'],
})
export class NetworkSettingsComponent {
  @ViewChild(ControllerNetworkSettingsFormComponent) formComponent: ControllerNetworkSettingsFormComponent;

  private store: Store = inject(Store);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  platformService: PlatformService = inject(PlatformService);

  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  isNetworkLoading$: Observable<boolean> = combineLatest([
    this.store.select(selectIsLoading),
    this.store.select(selectCellularIsLoading),
  ]).pipe(
    map(([value1, value2]) => value1 || value2),
  );
  networkSettings$: Observable<INetworkSettingsView> = this.platformService.isDeviceApp
    ? this.store.select(selectNetworkSettings)
    : this.store.select(selectCurrentControllerNetworkSettings);

  isEditMode: boolean = false;

  IconsEnum = IconsEnum;

  constructor() {
    this.activatedRoute.params
    .pipe(
      takeUntilDestroyed(),
    )
    .subscribe(({ controllerID }) => {
      this.store.dispatch(
        this.platformService.isDeviceApp
          ? getNetworkStatus({ overrideSettingsState: true })
          : getCurrentControllerNetworkSettings({ controllerID }),
      );
    });
  }

  connectModem(modemData: IModemCredentials): void {
    this.store.dispatch(connectModem({ modemData }));
  }

  disconnectModem(): void {
    this.store.dispatch(disconnectModem());
  }

  connectWifi(wifiData: IWifiConnection): void {
    this.store.dispatch(connectWifi({ wifiData }));
  }

  disconnectWifi(): void {
    this.store.dispatch(disconnectWifi());
  }

  scanWifiNetworks(): void {
    this.store.dispatch(getWifiList());
  }

  async changedNetworkSettings(event: { formValues: INetworkSettingsView; isValid: boolean }): Promise<void> {
    const networkSettings = await firstValueFrom(this.networkSettings$);
    if (event.isValid) {
      if (event.formValues.lan !== networkSettings.lan) {
        if (this.platformService.isDeviceApp) {
          this.store.dispatch(updateLanSettings({ lan: event.formValues.lan }));
        }
      }
      if (event.formValues.wifi !== networkSettings.wifi) {
        if (this.platformService.isDeviceApp) {
          this.store.dispatch(!event.formValues.wifi ? disconnectWifi() : getWifiList());
        }
      }
      if (event.formValues.modem !== networkSettings.modem) {
        if (this.platformService.isDeviceApp && !event.formValues.modem) {
          this.disconnectModem();
        }
        else {
          this.store.dispatch(setModemState({ state: event.formValues.modem }));
        }
      }
    }
  }

  onToggleDisabled(): void {
    this.isEditMode = !this.isEditMode;
    if (!this.isEditMode) {
      this.formComponent.menuItem = null;
    }
  }
}
