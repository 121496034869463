import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

  static getStorageItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  static removeStorageItem(key: string): void {
    localStorage.removeItem(key);
  }

  static setStorageItem(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  static removeAllExcept(keysToKeep?: string[]): void {
    for (const key in localStorage) {
      if (!keysToKeep?.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  }

  static removeStorageItems(keys: string[]): void {
    keys.forEach(key => LocalStorageService.removeStorageItem(key));
  }
}
