<main
  *ngIf="farm$ | async as farm"
  class="wrapper h-100-percent">
  <ng-container *ngIf="activeSettingsSection === FarmSettingsMobileSections.FarmEdit">
    <div class="d-flex align-center mb-18">
      <div class="mr-18">
        <ls-svg-icon
          (click)="activeSettingsSection = FarmSettingsMobileSections.FarmUsers"
          [path]="IconsEnum.CONTROLLER_SETTINGS_BACK_WHITE"></ls-svg-icon>
      </div>
      <div class="w-100-percent d-flex align-center justify-between">
        <div class="fw-500 d-flex flex-column pt-1">
          <span class="farm-edit-title fz-10 fw-500 section color-darkgrey">
            {{ farm.name }}
          </span>
          <span class="fz-21 fw-600 d-block title">
            {{ "Farms.FarmUpdate.FarmDetails" | translate }}
          </span>
        </div>
        <ls-svg-icon
          *ngIf="!farm.controllersCount && farm.usersCount <= 1 && !updateFarmComponent?.isSubmitVisible"
          (click)="onFarmDelete()"
          [path]="IconsEnum.BASKET_IN_CIRCLE"
          class="pointer"></ls-svg-icon>
      </div>
    </div>
    <div class="h-100-percent pb-28">
      <lv-update-farm
        (deleted)="onDelete()"
        (onUserInvite)="activeSettingsSection = FarmSettingsMobileSections.FarmUserEdit"
        [isMapAlwaysOpen]="true"
        [isInviteButtonVisible]="isInvitationAvailable$ | async"
        [currentUserAccessRole]="currentUserAccessRole$ | async"
        [farm]="farm">
      </lv-update-farm>
    </div>
  </ng-container>

  <ng-container *ngIf="activeSettingsSection === FarmSettingsMobileSections.FarmUserEdit">
    <lv-farm-user-details
      (onGoBack)="onUserDetailsBackPress()"
      [user]="selectedUser"
      [mode]="userDetailsMode"
      [currentUserRole]="(currentUser$ | async).role"
      [currenUserAccessRole]="currentUserAccessRole$ | async">
    </lv-farm-user-details>
  </ng-container>

  <ng-container *ngIf="activeSettingsSection === FarmSettingsMobileSections.FarmUsers">
    <header class="mb-18">
      <div class="content ph-10 w-100-percent d-flex justify-between">
        <span class="fz-14 fw-400">
          {{ farm.name }}
        </span>
      </div>

      <div class="actions d-flex gap-18">
        <div class="active d-flex align-center justify-center">
          <ls-svg-icon
            (click)="navigateToFarmsPage(farm)"
            [color]="ColorsEnum.White"
            [path]="IconsEnum.QS_COG" />
        </div>
        <div class="d-flex align-center justify-center">
          <ls-svg-icon
            [color]="ColorsEnum.GreyDisabled"
            [path]="IconsEnum.ADD_CONTROLLER" />
        </div>
      </div>
    </header>

    <section class="farm-info d-flex flex-row justify-between align-center mb-18">
      <div class="d-flex flex-column">
        <span class="farm-title fz-24 fw-600">{{ farm.name }}</span>
        <span class="fz-16 fw-500">
          {{ farm.latitude | number : COORDINATES_FORMAT }}, {{ farm.longitude | number : COORDINATES_FORMAT }}
        </span>
      </div>
      <ls-svg-icon
        (click)="activeSettingsSection = FarmSettingsMobileSections.FarmEdit"
        [path]="IconsEnum.EDIT_IN_CIRCLE" />
    </section>

    <section class="users-list">
      <span class="d-flex flex-row gap-10 ph-8 mb-8">
        <ls-svg-icon [path]="IconsEnum.USERS" />
        <h3 class="fz-14 fw-500">{{ "Farms.FarmUpdate.FarmUsers" | translate }}</h3>
      </span>
      <div class="users-list__wrapper">
        <lv-farm-users-list
          (onUserSelect)="onUserSelect($event)"
          [currentUser]="currentUser$ | async"
          [currentUserAccessRole]="currentUserAccessRole$ | async"
          [users]="users$ | async" />
      </div>
    </section>
  </ng-container>
</main>
