<div
  [formGroup]="form"
  class="mb-20">
  <h2 class="mb-10">Inputs</h2>

  <div class="d-flex gap-10 flex-wrap">
    <div class="w-200">
      <lv-input-time
        [placeholder]="GlobalConstants.Time_hhmm_placeholder"
        formControlName="time"></lv-input-time>
    </div>

    <div class="w-200">
      <lv-input-date formControlName="date"></lv-input-date>
    </div>

    <div class="w-200">
      <lv-input-integer-with-label
        label="Installation.StaticPressure.SensorType"
        formControlName="intLabel"></lv-input-integer-with-label>
    </div>

    <div class="w-200">
      <lv-input-decimal-with-label
        label="Installation.StaticPressure.SensorType"
        formControlName="decLabel"></lv-input-decimal-with-label>
    </div>

    <div class="w-200">
      <lv-input-decimal-with-label
        label="Installation.StaticPressure.SensorType"
        formControlName="decLabel"
        class="label-bg-white"></lv-input-decimal-with-label>
    </div>

    <div class="w-200">
      <lv-simple-dropdown
        [enum]="ChickenBrandWeight"
        [enumString]="'chicken-brand-weight'"
        formControlName="dropdown"></lv-simple-dropdown>
    </div>

    <div class="w-200">
      <lv-input-with-save-btn formControlName="saveBtn"></lv-input-with-save-btn>
    </div>

    <div class="w-200">
      <lv-dual-toggle
        [firstOption]="{
          title: 'Celsius',
          value: AirFlowUnitEnum.CubicMeterPerHour
        }"
        [secondOption]="{
        title: 'Fahrenheit',
        value: AirFlowUnitEnum.CubicFeetPerHour,
      }"
        formControlName="dualToggle"></lv-dual-toggle>
    </div>
  </div>

  <lv-radio-list-with-label
    (change)="changeRole($event)"
    [list]="roleList"
    [label]="'Farms.UserInvitation.Role'"
    formControlName="role"
    direction="vertical"
    size="large" />
</div>

<hr />

<div class="buttons">
  <h2 class="mb-10">Buttons</h2>

  <div class="d-flex align-center gap-10 flex-wrap">
    <lv-button
      [type]="ButtonTypeEnum.PRIMARY"
      class="w-130">
      <span>Primary</span>
    </lv-button>

    <lv-button
      [type]="ButtonTypeEnum.SECONDARY"
      class="w-130">
      <span>Secondary</span>
    </lv-button>

    <lv-button
      [type]="ButtonTypeEnum.DISABLED"
      class="w-130">
      <span>Disabled</span>
    </lv-button>

    <lv-button
      [type]="ButtonTypeEnum.DISABLED_TRANSPARENT"
      class="w-130">
      <span>Disabled transparent</span>
    </lv-button>

    <lv-button
      [type]="ButtonTypeEnum.LINK"
      class="w-130">
      <span>Link</span>
    </lv-button>

    <lv-button
      [type]="ButtonTypeEnum.ALERT"
      class="w-130">
      <span>Alert</span>
    </lv-button>

    <lv-button
      [type]="ButtonTypeEnum.WARNING"
      class="w-130">
      <span>Warning</span>
    </lv-button>
  </div>
</div>

<hr />

<div class="elements">
  <h2 class="mb-10">Elements</h2>

  <div class="d-flex align-center gap-10 flex-wrap">
    <lv-arrows-connect></lv-arrows-connect>
    <lv-arrows-connect [arrowsType]="ArrowsConnectEnum.ConnectionError"></lv-arrows-connect>
    <lv-arrows-connect [arrowsType]="ArrowsConnectEnum.Default"></lv-arrows-connect>

    <lv-button [type]="ButtonTypeEnum.PRIMARY">
      <lv-arrows-connect [arrowsType]="ArrowsConnectEnum.Default"></lv-arrows-connect>
      <span>Save</span>
    </lv-button>
  </div>
</div>
