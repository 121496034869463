import { Component, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  CreateFarmComponent,
  createFarmSuccess,
  getFarms,
  IFarm,
  selectFarms,
} from '@livestock/farms';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import {
  ButtonComponent,
  LoadingGalconComponent,
  SvgIconComponent,
} from '@livestock/ui';
import {
  ScannedLoginStepsEnum,
  selectControllerInfo,
  selectGetControllerTicketError,
  selectTicketId,
} from '@livestock/parring-process';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { combineLatest, Observable, Subscription, startWith, withLatestFrom } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutes } from '@livestock/shared/routes';
import {
  clearIsControllerCreatedOrExecuted,
  executeTicketController,
  selectIsControllerTicketExecuted, selectIsLoading,
} from '@livestock/parring-process';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { GlobalConstants, TimeZonesEnum } from '@livestock/shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { logOut } from '@livestock/auth';
import { PlatformService, LanguageService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';
import { EnumPipe, EnumToArrayPipe, MemoizeFuncPipe, StyleTextPartPipe } from '@livestock/shared/pipes';
import { FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { setValidControllerHouseNumber } from '../../+state/parring-process.actions';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule } from '@angular/material/autocomplete';
import { StringUtils } from '@livestock/shared/utils';
import { ICurrentUserView } from '@livestock/current-user';
import { setFlashMessage } from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';
import { setMobileViewMode, MobileViewModeEnum } from '@livestock/layout';
import { INameId } from '../../../../../shared/interfaces/id-name.interface';
import { FarmCreationMode } from '@livestock/farms';
import { InputIntegerComponent } from '@livestock/ui';

@Component({
  selector: 'ls-scanned-login',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatAutocompleteModule,
    ButtonComponent,
    SvgIconComponent,
    CreateFarmComponent,
    TranslateModule,
    LoadingGalconComponent,
    QaTagsDirective,
    EnumPipe,
    EnumToArrayPipe,
    MemoizeFuncPipe,
    StyleTextPartPipe,
    InputIntegerComponent,
  ],
  templateUrl: './scanned-login.component.html',
  styleUrls: ['./scanned-login.component.scss'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'autcomplete-timezone-overlay-pane' },
    },
  ],
})
export class ScannedLoginComponent implements OnInit, OnDestroy {
  @ViewChild('numberInput') houseNumberInput: ElementRef;
  @ViewChild('timeZoneInput') timeZoneInput: ElementRef;
  //subs
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  farms$ = this.store.select(selectFarms);
  user$ = this.http.get<ICurrentUserView>(`${environment.apiUrl}/user/current`).subscribe((i: ICurrentUserView) => {
    this.userInitials = StringUtils.getInitials(i.fullName);
    this.user = i;
  });
  controller$ = combineLatest([
    this.store.select(selectTicketId),
    this.store.select(selectControllerInfo),
  ]).pipe(
    tap(([tickedId]) => {
      this.tickedId = tickedId;
      if (!tickedId) {
        this.goToFarms();
      }
    }),
    map(([_, controllerInfo]) => controllerInfo),
  );

  myFarmsFiltered$ = this.store.select(selectFarms).pipe(
    map((farms: IFarm[]) => {
      return farms?.filter(farm => farm.ownerUserID === this.user.userID);
    }),
  );

  otherFarmsFiltered$ = this.store.select(selectFarms).pipe(
    map((farms: IFarm[]) => {
      return farms?.filter(farm => farm.ownerUserID !== this.user.userID);
    }),
  );

  //vars
  user: ICurrentUserView;
  userInitials: string;
  currStep = ScannedLoginStepsEnum.ScannedDeviceInfo;
  selectedFarm: IFarm | null = null;
  selectFarmStepMode: 'select' | 'create' = 'select';
  tickedId: string | null = null;
  timeZoneControl = new FormControl<INameId>(null);
  timeZones = TimeZonesEnum.toList();
  filteredTimeZones: Observable<INameId[]>;
  houseNumber: number;
  houseNumberPlaceholder = 'LoginScanned.HouseNumber';
  houseNumberInputInFocus: boolean;
  timeZonePlaceholder = 'LoginScanned.Timezone';
  timeZoneInputInFocus: boolean;
  toHighlight: string;
  afterFarmCreation: boolean;
  getControllerTicketError: string;
  //enums
  ColorsEnum = ColorsEnum;
  IconsEnum = IconsEnum;
  ScannedLoginStepsEnum = ScannedLoginStepsEnum;
  TimeZonesEnum = TimeZonesEnum;
  FarmCreationMode = FarmCreationMode;
  GlobalConstants = GlobalConstants;

  constructor(
    public router: Router,
    public actions: Actions,
    public platformService: PlatformService,
    public languageService: LanguageService,
    private store: Store,
    private http: HttpClient,
    private dialogRef: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(getFarms());

    this.filteredTimeZones = this.timeZoneControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterTimezones(value || '')),
    );

    this.sub$.add(
      this.actions
        .pipe(
          ofType(createFarmSuccess),
        ).subscribe((action) => {
        this.selectFarmFromList(action.farm);
      }),
    );

    this.sub$.add(
      this.store.select(selectGetControllerTicketError)
        .subscribe((getControllerTicketError) => {
          this.getControllerTicketError = getControllerTicketError;
        }),
    );

    this.sub$.add(
      this.store.select(selectIsControllerTicketExecuted).pipe(
        wasChanged(),
        withLatestFrom(this.controller$),
      ).subscribe(([{ isControllerTicketExecuted, creatingControllerError }, controller]) => {
        if (creatingControllerError?.includes(GlobalConstants.HouseNumberAlreadyExistsError)) {
          this.selectedFarm.existingHouseNumbers = [
            ...this.selectedFarm.existingHouseNumbers,
            controller.houseNumber,
          ];
          return;
        }

        if (isControllerTicketExecuted) {
          setTimeout(() => {
            this.store.dispatch(
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.AcceptedControllerMessage',
                  additionalInfo: {
                    houseNumber: controller.houseNumber,
                    farmName: this.selectedFarm.name,
                    serialNumber: controller.sn,
                  },
                },
              }),
            );
          });
          this.store.dispatch(clearIsControllerCreatedOrExecuted());
          this.store.dispatch(setMobileViewMode({ mobileViewMode: MobileViewModeEnum.Farms }));
          this.router.navigate(['farm/' + this.selectedFarm?.farmID]);
        }
      }),
    );
  }

  displayTimeZoneFn(timezone: INameId): string {
    return timezone?.name || '';
  }

  goToFarms(): void {
    this.router.navigate([AppRoutes.FARMS]);
  }

  get isRtl(): boolean {
    return this.languageService.isRtl;
  }

  onFocusOutHouseNumberInput(): void {
    this.houseNumberInputInFocus = false;
    this.houseNumberPlaceholder = 'LoginScanned.HouseNumber';
  }

  onFocusInHouseNumberInput(): void {
    this.houseNumberInputInFocus = true;
    this.houseNumberPlaceholder = '';
  }

  onFocusOutTimeZoneInput(): void {
    this.timeZoneInputInFocus = false;
    this.timeZonePlaceholder = 'LoginScanned.Timezone';
  }

  onFocusInTimeZoneInput(): void {
    this.timeZoneInputInFocus = true;
    this.timeZonePlaceholder = '';
  }

  getControllerNumberIconColor(
    [houseNumber, houseNumberInputInFocus, existingHouseNumbers]: [number, boolean, number[]],
  ): string {
    return (!houseNumber || existingHouseNumbers?.includes(houseNumber) || houseNumber > GlobalConstants.MAX_HOUSE_NUMBER)
      ? ColorsEnum.MainRed
      : houseNumberInputInFocus
        ? ColorsEnum.MainDarkBlue
        : ColorsEnum.MonoDark;
  }

  isInvalidHouseNumber([houseNumber, existingHouseNumbers]: [number, number[]]): boolean {
    return !houseNumber ||
    existingHouseNumbers?.includes(houseNumber) ||
    houseNumber > GlobalConstants.MAX_HOUSE_NUMBER;
  }

  saveNewHouseNumber(houseNumber: number): void {
    if (!houseNumber ||
      houseNumber > GlobalConstants.MAX_HOUSE_NUMBER ||
      this.selectedFarm?.existingHouseNumbers?.includes(houseNumber)
    ) {
      return;
    }

    this.store.dispatch(setValidControllerHouseNumber({ houseNumber }));
  }

  assign(selected: IFarm, houseNumber: number, timeZoneID: number): void {
    if (!selected || !timeZoneID) return;

    this.store.dispatch(executeTicketController({
      requestedView: {
        farmID: selected.farmID,
        timeZoneID,
        houseNumber,
      },
      ticketID: this.tickedId,
    }));
  }

  selectFarmFromList(farm: IFarm): void {
    this.selectedFarm = farm;

    if (farm?.existingHouseNumbers) {
      this.houseNumber = this.getMinCorrectHouseNumber(farm.existingHouseNumbers);
    }
  }

  getMinCorrectHouseNumber(existingHouseNumbers: number[]): number {
    const integers = Array.from({ length: existingHouseNumbers.length + 1 }, (_, index) => index + 1);
    return Math.min(...integers.filter(x => x > 0 && !existingHouseNumbers.filter(x => x > 0).includes(x)));
  }

  farmWasCreated(): void {
    this.afterFarmCreation = true;
    this.selectFarmStepMode = 'select';
  }

  logout(): void {
    this.dialogRef.closeAll();
    this.store.dispatch(logOut());
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private filterTimezones(value: string | INameId): INameId[] {
    const filterValue = typeof value === 'string'
      ? this.normalizeValue(value)
      : this.normalizeValue(value.name);
    this.toHighlight = filterValue;
    return this.timeZones.filter(zone => this.normalizeValue(zone.name).includes(filterValue));
  }

  private normalizeValue(value: string): string {
    return value.toLowerCase().trim();
  }
}
