import {
  ControllerMenuPagesEnum,
  IControllerPageItem,
} from '@livestock/menu';
import { IconsEnum } from '@livestock/shared/enums';

export const controlSectionPages: IControllerPageItem[] = [
  {
    id: ControllerMenuPagesEnum.UnitsSettings,
    title: 'ControllerMenu.Pages.Temperature',
    icon: IconsEnum.TEMPERATURE,
    route: null,
    qaTags: 'qa-mnu-temperature',
  },
  {
    id: ControllerMenuPagesEnum.Ventilation,
    title: 'ControllerMenu.Pages.Ventilation',
    icon: IconsEnum.VENTILATION,
    route: null,
    qaTags: 'qa-mnu-ventilation',
  },
  {
    id: ControllerMenuPagesEnum.StaticPressure,
    title: 'ControllerMenu.Pages.StaticPressure',
    icon: IconsEnum.STATIC_PRESSURE,
    route: null,
    qaTags: 'qa-mnu-staticPressure',
  },
  {
    id: ControllerMenuPagesEnum.Cooling,
    title: 'ControllerMenu.Pages.Cooling',
    icon: IconsEnum.COOLING,
    route: null,
    qaTags: 'qa-mnu-cooling',
  },
  {
    id: ControllerMenuPagesEnum.Heating,
    title: 'ControllerMenu.Pages.Heating',
    icon: IconsEnum.HEATING,
    route: null,
    qaTags: 'qa-mnu-heating',
  },
  {
    id: ControllerMenuPagesEnum.AirTreatment,
    title: 'ControllerMenu.Pages.AirTreatment',
    icon: IconsEnum.AIR_TREATMENT,
    route: null,
    qaTags: 'qa-mnu-airTreatment',
  },
  {
    id: ControllerMenuPagesEnum.WaterFeed,
    title: 'ControllerMenu.Pages.WaterFeed',
    icon: IconsEnum.WATER_FEED,
    route: null,
    qaTags: 'qa-mnu-waterFeed',
  },
  {
    id: ControllerMenuPagesEnum.Light,
    title: 'ControllerMenu.Pages.Light',
    icon: IconsEnum.LIGHT,
    route: null,
    qaTags: 'qa-mnu-light',
  },
  {
    id: ControllerMenuPagesEnum.TimersAux,
    title: 'ControllerMenu.Pages.TimersAux',
    icon: IconsEnum.TIMERS_AUX,
    route: null,
    qaTags: 'qa-mnu-timersAux',
  },
];
