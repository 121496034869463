import { GlobalConstants } from '@livestock/shared/constants';
import { QuickStartStatusEnum } from '@livestock/controllers/enums';
import { environment } from '@livestock/shared/environments';

export const baseUrl = `${environment.apiUrl}/controller`;
export const pureDeviceControllerUrl = 'http://localhost:3000/api';
export const deviceControllerUrl = (controllerID: number | string): string => `http://localhost:3000/api/controller/${controllerID}`;
export const deviceUrl = (controllerID: number): string => `http://localhost:3000/api/controller/${controllerID}/settings`;
export const deviceUrlFlock = (controllerID: number): string => `http://localhost:3000/api/controller/${controllerID}/flock`;
export const deviceQuickStartUrl = (step: QuickStartStatusEnum | string): string => `http://localhost:3000/api/controller/quickstart/status/${step}`;
export const defaultControllerID = GlobalConstants.HardCodedControllerIDForDevice;
