import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AlignmentEnum, DialogButtonEnum } from '@livestock/shared/enums';
import { DialogButton } from '@livestock/shared/classes';
import { ButtonComponent } from '../../controls/button/button.component';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';

@Component({
  selector: 'ls-message-dialog',
  imports: [
    CommonModule,
    TranslateModule,
    ButtonComponent,
    SvgIconComponent,
    QaTagsDirective,
    MemoizeFuncPipe,
  ],
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
  standalone: true,
})
export class MessageDialogComponent {
  title = 'Livestock.LivestockWarningMessage';
  message = 'Livestock.SomethingWentWrong';
  buttons: DialogButton[];
  iconPath: string;
  isMessageShouldBeTranslated: boolean;
  isTitleShouldBeTranslated: boolean;
  alignText: AlignmentEnum;
  alignButtons: AlignmentEnum;
  AlignmentEnum = AlignmentEnum;
  showCloseIcon: boolean;

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    private sanitizer: DomSanitizer,
  ) {
  }

  setup(
    title: string,
    message: string,
    buttons: DialogButtonEnum[],
    iconPath: string = null,
    isMessageShouldBeTranslated = true,
    isTitleShouldBeTranslated = true,
    alignText = AlignmentEnum.CENTER,
    alignButtons = AlignmentEnum.CENTER,
    showCloseIcon = true,
  ): void {
    this.title = title || this.title;
    this.message = message || this.message;
    this.buttons = this.getButtons(buttons);
    this.iconPath = iconPath;
    this.isMessageShouldBeTranslated = isMessageShouldBeTranslated;
    this.isTitleShouldBeTranslated = isTitleShouldBeTranslated;
    this.alignText = alignText;
    this.alignButtons = alignButtons;
    this.showCloseIcon = showCloseIcon;
  }

  close(ev: DialogButton): void {
    this.dialogRef.close(ev.type);
  }

  private getButtons(buttons: DialogButtonEnum[]): DialogButton[] {
    return buttons.map(b => new DialogButton(b));
  }

  getInnerHTML(text: string): SafeHtml {
    const modifiedText = text.split(' ')
      .map(word => {
        if (Number.isInteger(+word) || Number.isInteger(+word.replace(/,/g, ''))) {
          return `<span class="fw-500">${word}</span>`;
        }

        if (word.includes('@')) {
          return `<span class="fw-500">${word}</span>`;
        }

        return word;
      }).join(' ');

    return this.sanitizer.bypassSecurityTrustHtml(modifiedText);
  }
}
