export interface ISecurityTicketRequest {
  email: string;
}

export interface ISecurityTicketRes {
  email: string;
  ticketID: string;
}

export interface IVerfifyCodeRequest {
  ticketID: string;
  code: string;
}
