import { createAction, props } from '@ngrx/store';
import { IFlashMessage } from '../interfaces/flash-message.interface';

export const setFlashMessage = createAction(
  '[FLASH MESSAGE] Set Message [...]',
  props<{ flashMessage: IFlashMessage }>(),
);

export const removeFlashMessage = createAction(
  '[FLASH MESSAGE] Remove Message [...]',
);
