import { Pipe, PipeTransform } from '@angular/core';
import { ControllerLanguageEnum } from '../../controllers/src/lib/enums/controller/controller-language.enum';
import { ControllerUnitEnum } from '../../controllers/src/lib/enums/controller/controller-unit.enum';
import { HoursFormatTypeEnum } from '../../controllers/src/lib/enums/date-time/hours-format-type.enum';
import {
  AirFlowUnitEnum,
  AirPressureUnitEnum,
  AirSpeedUnitEnum,
  ChickenBrandWeight,
  ElementTypesEnum,
  FarmUserRolesEnum,
  IlluminanceUnitEnum,
  LengthUnitEnum,
  SortingEnum,
  TemperatureUnitEnum,
  VolumeUnitEnum,
  WaterLinePressureUnitEnum,
  WaterMainPressureUnitEnum,
  WeightUnitEnum,
  InstallationElementGroupsEnum,
} from '../enums';
import { AutoDateTimeTypeEnum } from '../../controllers/src/lib/enums/date-time/auto-date-time-type.enum';
import { UserStatusesEnum } from '../enums/users/user-statuses.enum';
import {
  VentilationWorkingModeEnum,
} from '../../controllers/src/lib/enums';
import { AmPmEnum } from '../../controllers/src/lib/enums/date-time/am-pm.enum';
import { CommonUserRolesEnum } from '../enums/users/common-user-roles.enum';
import { FarmControllerStatusEnum } from '../../farms/src/lib/enum/farm-controller-status.enum';
import {
  InOutdoorTemperatureStatusEnum,
} from '../../installation/src/lib/enums/indoor-temperature/indoor-temperature-status.enum';
import { ElementLocationTypeEnum } from '../enums/element-location-type.enum';
import { OffOnEnum } from '../enums/off-on.enum';
import { StagingEnum, WeightMethodEnum, HouseModeEnum, BirdTypeEnum } from '../../flock/src/lib/enums';

@Pipe({
  name: 'enum',
  standalone: true,
})
export class EnumPipe implements PipeTransform {
  // tslint:disable-next-line: cyclomatic-complexity
  transform(value: any, enumTitle: string): string {
    if (value == null) {
      return '';
    }

    switch (enumTitle) {
      case 'controller-language':
        return ControllerLanguageEnum.toTranslateKey(value);
      case 'controller-bird-type':
        return BirdTypeEnum.toTranslateKey(value);
      case 'controller-unit':
        return ControllerUnitEnum.toTranslateKey(value);
      case 'controller-hours-format':
        return HoursFormatTypeEnum.toTranslateKey(value);
      case 'auto-date-time-type':
        return AutoDateTimeTypeEnum.toTranslateKey(value);
      case 'temperature-unit-enum':
        return TemperatureUnitEnum.toTranslateKey(value);
      case 'temperature-unit-short-enum':
        return TemperatureUnitEnum.toTranslateKey(value, true);
      case 'air-flow-unit-enum':
        return AirFlowUnitEnum.toTranslateKey(value);
      case 'air-pressure-unit-enum':
        return AirPressureUnitEnum.toTranslateKey(value);
      case 'air-speed-unit-enum':
        return AirSpeedUnitEnum.toTranslateKey(value);
      case 'illuminance-unit-enum':
        return IlluminanceUnitEnum.toTranslateKey(value);
      case 'length-unit-enum':
        return LengthUnitEnum.toTranslateKey(value);
      case 'volume-unit-enum':
        return VolumeUnitEnum.toTranslateKey(value);
      case 'water-line-pressure-unit-enum':
        return WaterLinePressureUnitEnum.toTranslateKey(value);
      case 'water-main-pressure-unit-enum':
        return WaterMainPressureUnitEnum.toTranslateKey(value);
      case 'weight-unit-enum':
        return WeightUnitEnum.toTranslateKey(value);
      case 'weight-unit-short-enum':
        return WeightUnitEnum.toShortTranslateKey(value);
      case 'farm-user-roles':
        return FarmUserRolesEnum.toTranslateKey(value);
      case 'common-user-roles':
        return CommonUserRolesEnum.toTranslateKey(value);
      case 'user-status':
        return UserStatusesEnum.toTranslateKey(value);
      case 'sorting-enum':
        return SortingEnum.toTranslateKey(value);
      case 'sorting-enum-az':
        return SortingEnum.toTranslateKeyAZ(value);
      case 'staging-enum':
        return StagingEnum.toTranslateKey(value);
      case 'weight-method-enum':
        return WeightMethodEnum.toTranslateKey(value);
      case 'ventilation-working-mode-enum':
        return VentilationWorkingModeEnum.toTranslateKey(value);
      case 'house-mode-enum':
        return HouseModeEnum.toTranslateKey(value);
      case 'chicken-brand-weight':
        return ChickenBrandWeight.toTranslateKey(value);
      case 'am-pm':
        return AmPmEnum.toTranslateKey(value);
      case 'farm-controller-status':
        return FarmControllerStatusEnum.toTranslateKey(value);
      case 'installation-elements-list':
        return InstallationElementGroupsEnum.toTranslateKey(value);
      case 'installation-elements':
        return ElementTypesEnum.toTranslateKey(value);
      case 'in-out-door-temperature-status':
        return InOutdoorTemperatureStatusEnum.toTranslateKey(value);
      case 'element-location-type':
        return ElementLocationTypeEnum.toTranslateKey(value);
      case 'off-on-enum':
        return OffOnEnum.toTranslateKey(value);
      default:
        return value.toString();
    }
  }
}
