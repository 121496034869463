import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NativeElementInjectorDirective } from '../native-element.directive';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { InputIntegerComponent } from '../input-integer/input-integer.component';

@Component({
  selector: 'lv-value-slider',
  templateUrl: './value-slider.component.html',
  styleUrls: ['./value-slider.component.scss'],
  standalone: true,
  imports: [
    QaTagsDirective,
    CommonModule,
    SvgIconComponent,
    MatSliderModule,
    TranslateModule,
    NativeElementInjectorDirective,
    MemoizeFuncPipe,
    FormsModule,
    ReactiveFormsModule,
    InputIntegerComponent,
  ],
})

export class ValueSliderComponent implements ControlValueAccessor {
  @ViewChild('valueTick') valueTick: ElementRef;
  @Input() value: number;
  @Input() step: number = 1;
  @Input() min: number;
  @Input() max: number;
  @Input() sliderWidth: number = 200;
  @Input() tickTheme: 'blue' | 'yellow' = 'blue';
  @Output() change = new EventEmitter<number>();

  TICKS_COUNT = 11;
  TICKS_GAP_PX = 20;
  TICK_MARGIN_PX = -2;

  inputValue: number;
  inputInFocus: boolean;
  form: FormGroup;

  constructor(@Optional() private control: NgControl) {
    control.valueAccessor = this;
  }

  @HostListener('input', ['$event'])
  onRangeChanged(): void {
    if (this.inputInFocus) return;
    this.form.controls['value'].setValue(this.valueTick.nativeElement.value);
    this.valueChange(this.valueTick.nativeElement.value);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      value: new FormControl<number>(this.value, [
        Validators.min(this.min),
        Validators.max(this.max),
      ]),
    });

    this.form.valueChanges.subscribe(({ value }) => {
      this.valueChange(value);
    });
  }

  valueChange(value: number): void {
    this.value = value;
    this.onChange(value);
    this.change.emit(value);
  }

  writeValue(value: number): void {
    if (JSON.stringify(this.value) === JSON.stringify(value)) return;

    this.value = value;
    this.inputValue = value;
  }

  registerOnChange(
    onChange: (value: number) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  private onTouched = (): void => {
  };

  private onChange: (
    value: number,
  ) => void = () => {
  };
}
