export enum IconsMenuEnum {
  FARM = 'menu/farm',
  BELL = 'menu/bell',
  CLOUD_CONNECT = 'menu/cloud-connect',
  MANDATORY_SETTINGS = 'menu/mandatory-settings',
  LOBBY = 'menu/lobby',
  FLOCK = 'menu/flock',
  LOGS = 'menu/logs',
  SYSTEM = 'menu/system',
  CONTROL_SECTION = 'menu/control-section',
  MOBILE_MENU = 'menu/mobile-menu',
  MOBILE_MENU_OPEN = 'menu/mobile-menu-open',
  MOBILE_LOBBY = 'menu/mobile-lobby',
  MOBILE_FARMS = 'menu/mobile-farms',
  MENU = 'menu/menu',
  MENU_OPEN = 'menu/menu-open',
  CLOUD = 'menu/cloud',
  DATE_TIME = 'menu/date-time',
  HOUSE_SETTINGS = 'menu/house-settings',
  NETWORK = 'menu/network',
  UNITS = 'menu/units',
  TEMPERATURE = 'menu/temperature',
  VENTILATION = 'menu/ventilation',
  STATIC_PRESSURE = 'menu/static-pressure',
  COOLING = 'menu/cooling',
  HEATING = 'menu/heating',
  AIR_TREATMENT = 'menu/air-treatment',
  WATER_FEED = 'menu/water-feed',
  LIGHT = 'menu/light',
  TIMERS_AUX = 'menu/timers-aux',
  FLOCK_SETTINGS = 'menu/flock-settings',
  GROWTH_TABLE = 'menu/growth-table',
  GROWTH_CHART = 'menu/growth-chart',
  BIRD_MANAGEMENT = 'menu/bird-management',
  SILO_MANAGEMENT = 'menu/silo-management',
  ALARM_SETTINGS = 'menu/alarm-settings',
  GENERAL_LOGS = 'menu/general-logs',
  DATA_LOGS = 'menu/data-logs',
  REPORTS = 'menu/reports',
  USERS = 'menu/users',
  HARDWARE = 'menu/hardware',
  TEMP_MAPPING = 'menu/temp-mapping',
  SAVE_RESTORE = 'menu/save-restore',
  ABOUT = 'menu/about',
}
