import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  ButtonWizardComponent,
  InputIntegerComponent,
  LoadingGalconComponent,
  SlimButtonComponent,
  SvgIconComponent,
  ToggleComponent,
} from '@livestock/ui';
import { Store } from '@ngrx/store';
import {
  clearUpsertControllerState,
  connectModem,
  connectWifi,
  createDateTimeSettingsViaDevice,
  createGeneralSettingsViaDevice,
  disconnectCloud,
  disconnectModem,
  disconnectWifi,
  getDateTimeSettings,
  getTicketQrCodeInfoFromDevice,
  getWifiList,
  goToNextStep,
  goToStep,
  setDateTimeSettings,
  setGeneralSettings,
  setModemState,
  setQuickstartStatusStep,
  updateLanSettings,
} from '../../+state/upsert-controller/upsert-controller.actions';
import { IGeneralSettingsView } from '../../interfaces/basic/general-settings-view.interface';
import { IDateTimeSettingsView } from '../../interfaces/date-time/date-time-settings-view.interface';
import { INetworkSettingsView } from '../../interfaces/network/network-settings-view.interface';
import {
  selectAssigningToFarmInProgress,
  selectCellularIsLoading,
  selectCloudConnectionRequest,
  selectCurrentStep,
  selectDateTimeSettings,
  selectGeneralSettings,
  selectIsControllerAssignedWithFarm,
  selectIsLoading,
  selectLengthUnit,
  selectNetworkConnectionInProgress,
  selectNetworkSettings,
  selectTicketQrCodeError,
  selectTicketQrCodeInfo,
  selectWeightUnit,
} from '../../+state/upsert-controller/upsert-controller.selectors';
import { combineLatest, firstValueFrom, map, Observable, Subscription, withLatestFrom } from 'rxjs';
import { IHouseSizesView } from '../../interfaces/house-sizes/house-sizes-view.interface';
import {
  ControllerLanguageEnum,
  ControllerUnitEnum,
  QuickStartStatusEnum,
  QuickStartStepsEnum,
} from '@livestock/controllers/enums';
import { ControllerGeneralSettingsFormComponent } from '../../ui/general-settings-form/general-settings-form.component';
import {
  ControllerDateTimeSettingsFormComponent,
} from '../../ui/date-time-settings-form/date-time-settings-form.component';
import { ControllerSettingsStubFormComponent } from '../../ui/settings-stub-form/settings-stub-form.component';
import {
  ControllerHouseSizesSettingsFormComponent,
} from '../../ui/house-sizes-settings-form/house-sizes-settings-form.component';
import { CloudPageComponent } from '../../ui/cloud-page/cloud-page.component';
import {
  ButtonIconWizardEnum,
  ButtonTypeEnum,
  ButtonWizardTypeEnum,
  IconsEnum,
  LengthUnitEnum,
  StorageItem,
  TemperatureUnitEnum,
  WeightUnitEnum,
} from '@livestock/shared/enums';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutes } from '@livestock/shared/routes';
import { Router } from '@angular/router';
import { LocalStorageService, PlatformService } from '@livestock/shared/services';
import { GlobalConstants } from '@livestock/shared/constants';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';
import {
  ICloudConnectionRequest,
  IModemCredentials,
  IWifiConnection,
  QuickStartStepType,
} from '@livestock/controllers/interfaces';
import { EnumPipe, MemoizeFuncPipe } from '@livestock/shared/pipes';
import { ControllerNetworkSettingsFormComponent } from '../../ui/network-settings-form/network-settings-form.component';
import {
  CurrentNetworkStatusComponent,
} from '../../ui/network-settings-form/current-network-status/current-network-status.component';
import { CloudConnectionForm } from '../../ui/cloud-connection-form/cloud-connection-form.component';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ControllerGeneralSettingsFormComponent,
    ControllerDateTimeSettingsFormComponent,
    ControllerSettingsStubFormComponent,
    ControllerHouseSizesSettingsFormComponent,
    ControllerNetworkSettingsFormComponent,
    CloudPageComponent,
    TranslateModule,
    SvgIconComponent,
    ToggleComponent,
    MemoizeFuncPipe,
    InputIntegerComponent,
    ButtonWizardComponent,
    SlimButtonComponent,
    LoadingGalconComponent,
    CurrentNetworkStatusComponent,
    CloudConnectionForm,
    EnumPipe,
    QaTagsDirective,
  ],
  selector: 'ls-quick-start-container',
  templateUrl: './quick-start-container.component.html',
  styleUrls: ['./quick-start-container.component.scss'],
})
export class QuickStartContainerComponent implements OnInit, OnDestroy {
  // components variables
  generalSettings: IGeneralSettingsView;
  dateTimeSettings: IDateTimeSettingsView;
  networkSettings: INetworkSettingsView;
  cloudConnectionRequest: ICloudConnectionRequest;
  canDisconnectCloud: boolean = false;

  defaultFootHouseSettings: IHouseSizesView = {
    houseWidth: GlobalConstants.MinFootWidthHeightLength,
    houseLength: GlobalConstants.MinFootWidthHeightLength,
    houseHeight: GlobalConstants.MinFootWidthHeightLength,
    houseRoofHeight: GlobalConstants.MinFootWidthHeightLength,
    houseNumber: 1,
  };
  defaultMeterHouseSettings: IHouseSizesView = {
    houseWidth: GlobalConstants.MinMeterWidthHeightLength,
    houseLength: GlobalConstants.MinMeterWidthHeightLength,
    houseHeight: GlobalConstants.MinMeterWidthHeightLength,
    houseRoofHeight: GlobalConstants.MinMeterWidthHeightLength,
    houseNumber: 1,
  };

  flowIsFinished: boolean;
  deviceLogic: boolean = false;
  isOnline: boolean;
  steps: QuickStartStepType[] = [
    {
      title: 'Controller.QuickStart.General',
      icon: IconsEnum.QS_COG,
      type: ButtonWizardTypeEnum.ON,
      tab: QuickStartStepsEnum.GeneralSettings,
      couldBeVisited: true,
    },
    {
      title: 'Controller.QuickStart.Network',
      icon: IconsEnum.QS_NETWORK,
      type: ButtonWizardTypeEnum.OFF,
      tab: QuickStartStepsEnum.NetworkSettings,
    },
    {
      title: 'Controller.QuickStart.Cloud',
      icon: IconsEnum.QS_CLOUD,
      type: ButtonWizardTypeEnum.OFF,
      tab: QuickStartStepsEnum.CloudSettings,
    },
    {
      title: 'Controller.QuickStart.DateTime',
      icon: IconsEnum.QS_DATE_TIME,
      type: ButtonWizardTypeEnum.OFF,
      tab: QuickStartStepsEnum.DateTimeSettings,
    },
  ];
  readySteps: QuickStartStepsEnum[] = [];

  houseSizesValid: boolean = false;
  houseModesValid: boolean = false;
  dateTimeSettingsValid: boolean = false;
  flockSettingsValid: boolean = true;
  flockWeightValid: boolean = true;

  // subs
  sub$ = new Subscription();
  lengthUnit$: Observable<LengthUnitEnum> = this.store.select(selectLengthUnit);
  weightUnit$: Observable<WeightUnitEnum> = this.store.select(selectWeightUnit);
  ticketQrCodeInfo$ = this.store.select(selectTicketQrCodeInfo);
  ticketQrCodeError$ = this.store.select(selectTicketQrCodeError);
  isControllerAssignedWithFarm$ = this.store.select(
    selectIsControllerAssignedWithFarm,
  );
  currentStep$: Observable<QuickStartStepsEnum> = this.store.select(selectCurrentStep);
  assigningToFarmInProgress$: Observable<boolean> = this.store.select(selectAssigningToFarmInProgress);
  connectionInProgress$: Observable<boolean> = this.store.select(selectNetworkConnectionInProgress);
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  isNetworkLoading$: Observable<boolean> = combineLatest([
      this.isLoading$,
      this.store.select(selectCellularIsLoading),
    ],
  ).pipe(
    map(([value1, value2]) => value1 || value2),
  );

  // enums
  QuickStartStepsEnum = QuickStartStepsEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ButtonWizardTypeEnum = ButtonWizardTypeEnum;
  ButtonIconWizardEnum = ButtonIconWizardEnum;
  ButtonTypeEnum = ButtonTypeEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private router: Router,
  ) {
    LocalStorageService.setStorageItem(StorageItem.CurrentLanguage, ControllerLanguageEnum.EngUS);
  }

  ngOnInit(): void {
    this.store.dispatch(clearUpsertControllerState());

    this.sub$.add(
      this.store
        .select(selectGeneralSettings)
        .pipe(wasChangedAndNotNull())
        .subscribe((generalSettings) => {
          const { _actualUnits, ...settings } = generalSettings;
          this.generalSettings = settings;
        }),
    );

    this.sub$.add(
      this.store
        .select(selectDateTimeSettings)
        .pipe(wasChangedAndNotNull())
        .subscribe((dateTimeSettings) => {
          this.dateTimeSettings = dateTimeSettings;
        }),
    );

    this.sub$.add(
      this.store
        .select(selectNetworkSettings)
        .pipe(
          withLatestFrom(this.currentStep$),
          wasChangedAndNotNull(),
        )
        .subscribe(([networkSettings, currentStep]) => {
          this.networkSettings = networkSettings;
          if (currentStep === QuickStartStepsEnum.NetworkSettings) {
            const someNetworkSettingsAreOn = this.someNetworkSettingsAreOn(this.networkSettings);
            const cloudStep: QuickStartStepType = this.steps.find(step => step.tab === QuickStartStepsEnum.CloudSettings);
            cloudStep.couldBeVisited = someNetworkSettingsAreOn;
            cloudStep.type = someNetworkSettingsAreOn ? cloudStep.type : ButtonWizardTypeEnum.OFF;
          }
        }),
    );

    this.sub$.add(
      this.store.select(selectCloudConnectionRequest)
        .pipe(wasChangedAndNotNull())
        .subscribe((cloudConnectionRequest) => {
          this.cloudConnectionRequest = cloudConnectionRequest;
          const timePassedFromConnection = this.cloudConnectionRequest?.connectedTime ? new Date().getTime() - this.cloudConnectionRequest.connectedTime.getTime() : null;
          if (timePassedFromConnection === null || timePassedFromConnection >= GlobalConstants.CLOUD_CONNECTION_COOLDOWN) {
            this.canDisconnectCloud = true;
          } else {
            this.canDisconnectCloud = false;

            setTimeout(() => {
              this.canDisconnectCloud = true;
            }, GlobalConstants.CLOUD_CONNECTION_COOLDOWN - timePassedFromConnection);
          }
        }),
    );
  }

  async onStepClick(tab: QuickStartStepsEnum): Promise<void> {
    const currentStep = await firstValueFrom(this.currentStep$);
    if (currentStep === tab) return;

    const step = this.steps.find(step => step.tab === tab);

    if (step?.couldBeVisited && step.type !== ButtonWizardTypeEnum.OFF) {
      const currentStepInfo = this.steps.find(step => step.tab === currentStep);
      currentStepInfo.type = currentStepInfo.type === ButtonWizardTypeEnum.ERROR ? ButtonWizardTypeEnum.ERROR : ButtonWizardTypeEnum.DONE;

      if (currentStepInfo.type !== ButtonWizardTypeEnum.ERROR && this.readySteps.includes(currentStep)) {
        this.handleCurrentStep(currentStep, false);
      }
      this.goToStep(tab);
    }
  }

  getStepTitle(tab: QuickStartStepsEnum): string {
    const currentStep = this.steps.find(step => step.tab === tab);
    return currentStep?.title;
  }

  goToStep(tab: QuickStartStepsEnum): void {
    const currentStep = this.steps.find(step => step.tab === tab);
    if (currentStep.type !== ButtonWizardTypeEnum.ERROR) currentStep.type = ButtonWizardTypeEnum.ON;
    this.store.dispatch(goToStep({ payload: tab }));
  }

  changedGeneralSettings(event: { formValues: any; isValid: boolean }): void {
    if (!event.isValid) {
      this.steps.find(step => step.tab === QuickStartStepsEnum.GeneralSettings).type = ButtonWizardTypeEnum.ERROR;
    }
    this.store.dispatch(
      setGeneralSettings({ generalSettings: event.formValues }),
    );
  }

  // TODO: maybe refactor in 0.18.3+, but behaviour is a little bit different here
  async changedDateTimeSettings(event: { formValues: any; isValid: boolean, dateTimeIsDirty: boolean }): Promise<void> {
    const currStep = await firstValueFrom(this.currentStep$);
    const dateTimeSettingsStep = this.steps.find(step => step.tab === QuickStartStepsEnum.DateTimeSettings);

    this.dateTimeSettingsValid = event.isValid;

    if (!event.isValid && !event.dateTimeIsDirty) {
      dateTimeSettingsStep.type = ButtonWizardTypeEnum.ERROR;
    }

    if (event.isValid && currStep === QuickStartStepsEnum.DateTimeSettings) {
      dateTimeSettingsStep.type = ButtonWizardTypeEnum.ON;
    }

    if (event.isValid && currStep !== QuickStartStepsEnum.DateTimeSettings) {
      dateTimeSettingsStep.type = ButtonWizardTypeEnum.DONE;
    }

    this.store.dispatch(setDateTimeSettings({ dateTimeSettings: event.formValues }));
  }

  changedNetworkSettings(event: { formValues: INetworkSettingsView; isValid: boolean }): void {
    if (event.isValid) {
      if (event.formValues.lan !== this.networkSettings.lan) {
        if (this.platformService.isDeviceApp && this.deviceLogic) {
          this.store.dispatch(updateLanSettings({ lan: event.formValues.lan }));
        }
      }
      if (event.formValues.wifi !== this.networkSettings.wifi) {
        if (this.platformService.isDeviceApp && this.deviceLogic) {
          this.store.dispatch(!event.formValues.wifi ? disconnectWifi() : getWifiList());
        }
      }
      if (event.formValues.modem !== this.networkSettings.modem) {
        if (this.platformService.isDeviceApp && !event.formValues.modem) {
          this.disconnectModem();
        } else {
          this.store.dispatch(setModemState({ state: event.formValues.modem }));
        }
      }
    }
  }

  connectWifi(wifiData: IWifiConnection): void {
    this.store.dispatch(connectWifi({ wifiData }));
  }

  disconnectWifi(): void {
    this.store.dispatch(disconnectWifi());
  }

  scanWifiNetworks(): void {
    this.store.dispatch(getWifiList());
  }

  connectModem(modemData: IModemCredentials): void {
    this.store.dispatch(connectModem({ modemData }));
  }

  disconnectModem(): void {
    this.store.dispatch(disconnectModem());
  }

  disconnectCloud(): void {
    this.store.dispatch(disconnectCloud());
  }

  async updateStepStatus(stepToMatch: QuickStartStepsEnum, validPropName: string, isValid: boolean, validityCondition: () => boolean): Promise<void> {
    const currStep = await firstValueFrom(this.currentStep$);
    const step = this.steps.find(step => step.tab === stepToMatch);
    this[validPropName] = isValid;

    if (!this[validPropName]) step.type = ButtonWizardTypeEnum.ERROR;
    if (validityCondition() && currStep === step.tab) {
      step.type = ButtonWizardTypeEnum.ON;
    }
    if (validityCondition() && currStep !== step.tab) {
      step.type = ButtonWizardTypeEnum.DONE;
    }
  }

  handleCurrentStep(currentStep: QuickStartStepsEnum, getQrCode: boolean = true): void {
    switch (currentStep) {
      case QuickStartStepsEnum.GeneralSettings:
        this.store.dispatch(createGeneralSettingsViaDevice());
        break;
      case QuickStartStepsEnum.NetworkSettings:
        if (getQrCode && this.someNetworkSettingsAreOn(this.networkSettings)) {
          this.store.dispatch(getTicketQrCodeInfoFromDevice());
        }
        break;
      case QuickStartStepsEnum.CloudSettings:
        this.store.dispatch(getDateTimeSettings());
        break;
      case QuickStartStepsEnum.DateTimeSettings:
        this.store.dispatch(createDateTimeSettingsViaDevice({ isFinish: false }));
        break;
      default:
        break;
    }
  }

  getTicketQrCodeInfoFromDevice(): void {
    this.store.dispatch(getTicketQrCodeInfoFromDevice());
  }

  async back(): Promise<void> {
    const currStep = await firstValueFrom(this.currentStep$);
    const currentStepIndex = this.steps.findIndex(step => step.tab === currStep);

    if (this.steps[currentStepIndex].type !== ButtonWizardTypeEnum.ERROR && this.readySteps.includes(currStep)) {
      this.handleCurrentStep(currStep, false);
    }

    if (currentStepIndex > -1 && this.steps[currentStepIndex].type !== ButtonWizardTypeEnum.ERROR) {
      this.steps[currentStepIndex].type = ButtonWizardTypeEnum.DONE;
    }

    const prevStep: QuickStartStepType = this.steps[currentStepIndex - 1].couldBeVisited
      ? this.steps[currentStepIndex - 1]
      : this.steps?.[currentStepIndex - 2] || this.steps[0];

    prevStep.type = prevStep.type === ButtonWizardTypeEnum.ERROR ? ButtonWizardTypeEnum.ERROR : ButtonWizardTypeEnum.ON;
    this.store.dispatch(goToStep({ payload: prevStep.tab }));
  }

  async next(): Promise<void> {
    const currentStep = await firstValueFrom(this.currentStep$);

    if (this.platformService.isDeviceApp && this.deviceLogic) {
      this.handleCurrentStep(currentStep);
    }

    const currentStepIndex = this.steps.findIndex(step => step.tab === currentStep);
    if (currentStepIndex === -1) return;

    let nextStep: QuickStartStepType = this.steps[currentStepIndex + 1];

    const shouldSkipStep = this.shouldSkipStep(currentStep);
    if (shouldSkipStep) {
      this.readySteps = this.readySteps.filter(step => step !== nextStep.tab);
      nextStep = this.steps[currentStepIndex + 2];
      this.steps[currentStepIndex + 1].couldBeVisited = false;
      this.steps[currentStepIndex + 1].type = ButtonWizardTypeEnum.OFF;
    }

    if (nextStep) {
      nextStep.couldBeVisited = true;
      nextStep.type = nextStep.type === ButtonWizardTypeEnum.ERROR ? ButtonWizardTypeEnum.ERROR : ButtonWizardTypeEnum.ON;
    }

    this.steps[currentStepIndex].type = ButtonWizardTypeEnum.DONE;

    this.readySteps.push(currentStep);
    if (shouldSkipStep) {
      this.store.dispatch(goToStep({ payload: nextStep.tab }));
      return;
    }
    this.store.dispatch(goToNextStep());
  }

  // if user clicks FINISH on Date&Time Settings step
  async finish(): Promise<void> {
    const currStep = await firstValueFrom(this.currentStep$);
    if (currStep !== QuickStartStepsEnum.DateTimeSettings) return;

    this.store.dispatch(createDateTimeSettingsViaDevice({ isFinish: true }));
    this.store.dispatch(setQuickstartStatusStep({ step: QuickStartStatusEnum.Done }));
  }

  goToDashboard(needToSetStatus?: boolean): void {
    this.router.navigateByUrl(AppRoutes.DASHBOARD);
    if (needToSetStatus) {
      this.store.dispatch(setQuickstartStatusStep({ step: QuickStartStatusEnum.Done }));
    }
  }

  goToInstallation(): void {
    this.router.navigateByUrl(AppRoutes.INSTALLATION);
  }

  toggleDeviceLogic(deviceLogic: boolean): void {
    this.deviceLogic = deviceLogic;
  }

  areNetworkSettingsValid(networkSettings: INetworkSettingsView): boolean {
    return !!networkSettings.lan
      || !!networkSettings.wifi && !!networkSettings.wifiName && !!networkSettings.wifiPassword
      || !!networkSettings.modem && !!networkSettings.modemApn && !!networkSettings.modemUser && !!networkSettings.modemPassword;
  }

  anyStepInError(): boolean {
    return this.steps.some(it => it.type === ButtonWizardTypeEnum.ERROR);
  }

  getCurrentLengthUnit(generalSettings: IGeneralSettingsView): LengthUnitEnum {
    return (
      generalSettings.units === ControllerUnitEnum.Imperial ||
      (generalSettings.units === ControllerUnitEnum.Custom &&
        generalSettings.unitsDetails.length === LengthUnitEnum.Foot)
    ) ? LengthUnitEnum.Foot : LengthUnitEnum.Meter;
  }

  getCurrentTemperatureUnit(generalSettings: IGeneralSettingsView): TemperatureUnitEnum {
    switch (generalSettings.units) {
      case ControllerUnitEnum.Metric:
        return TemperatureUnitEnum.Celsius;
      case ControllerUnitEnum.Imperial:
        return TemperatureUnitEnum.Fahrenheit;
      case ControllerUnitEnum.Custom:
      default:
        return generalSettings.unitsDetails?.temperature;
    }
  }

  someNetworkSettingsAreOn(networkSettings: INetworkSettingsView): boolean {
    return Boolean(networkSettings.lan) || Boolean(networkSettings.modem) || Boolean(networkSettings.wifi);
  }

  shouldSkipStep(stepIndex: QuickStartStepsEnum): boolean {
    return (stepIndex === QuickStartStepsEnum.NetworkSettings &&
      !this.someNetworkSettingsAreOn(this.networkSettings));
  }

  ngOnDestroy(): void {
    LocalStorageService.setStorageItem(StorageItem.CurrentLanguage, ControllerLanguageEnum.EngUS);
    this.sub$.unsubscribe();
  }
}
