export const loadingSvgPaths = [
  'M200.494 106.918C200.588 105.552 199.492 104.41 198.122 104.41H125.936C123.475 104.41 122.602 107.668 124.733' +
  ' 108.899L187.265 145.001C188.451 145.686 189.971 145.245 190.573 144.016C196.12 132.686 199.592 120.155 200.494 106.918Z',
  'M183.642 155.995C184.409 154.859 184.031 153.319 182.844 152.634L120.323 116.537C118.192 115.307 115.807' +
  ' 117.692 117.037 119.823L153.134 182.344C153.819 183.531 155.358 183.909 156.495 183.142C167.193 175.923 176.423 166.693 183.642 155.995Z',
  'M144.516 190.073C145.745 189.471 146.186 187.951 145.501 186.765L109.399 124.233C108.168 122.102 104.91 122.975' +
  ' 104.91 125.436V197.622C104.91 198.992 106.052 200.088 107.418 199.994C120.655 199.092 133.186 195.62 144.516 190.073Z',
  'M93.5816 199.994C94.9478 200.088 96.0899 198.992 96.0899 197.622V125.436C96.0899 122.975 92.8317 122.102' +
  ' 91.6012 124.233L55.4985 186.765C54.8139 187.951 55.2545 189.471 56.4843 190.073C67.8143 195.62 80.3451 199.092 93.5816 199.994Z',
  'M44.5051 183.142C45.6415 183.909 47.1808 183.531 47.8663 182.344L83.9626 119.823C85.1932 117.692 82.808 115.307' +
  ' 80.6767 116.537L18.156 152.634C16.9687 153.319 16.5913 154.858 17.3581 155.995C24.5773 166.693 33.8069 175.923 44.5051 183.142Z',
  'M10.4272 144.016C11.0292 145.245 12.5492 145.686 13.735 145.001L76.2665 108.899C78.3979 107.668 77.5248 104.41' +
  ' 75.0637 104.41H2.87768C1.50832 104.41 0.412389 105.552 0.505528 106.918C1.40792 120.155 4.88015 132.686 10.4272 144.016Z',
  'M13.735 54.9986C12.5492 54.3139 11.0293 54.7546 10.4272 55.9844C4.88015 67.3144 1.40792 79.8451 0.505529' +
  ' 93.0816C0.41239 94.4478 1.50833 95.5899 2.87768 95.5899H75.0638C77.5248 95.5899 78.3979 92.3317 76.2665 91.1012L13.735 54.9986Z',
  'M47.8663 17.656C47.1808 16.4687 45.6415 16.0913 44.5051 16.8582C33.8069 24.0774 24.5773 33.307 17.3581' +
  ' 44.0051C16.5913 45.1415 16.9687 46.6809 18.156 47.3664L80.6766 83.4627C82.808 84.6932 85.1932 82.308 83.9626 80.1767L47.8663 17.656Z',
  'M96.0899 2.37768C96.0899 1.00832 94.9478 -0.0876114 93.5816 0.00552795C80.3451 0.907921 67.8143 4.38016 56.4843' +
  ' 9.92719C55.2545 10.5293 54.8139 12.0492 55.4985 13.235L91.6012 75.7666C92.8317 77.898 96.0899 77.025 96.0899 74.5639V2.37768Z',
  'M145.501 13.235C146.186 12.0492 145.745 10.5292 144.516 9.92716C133.186 4.38015 120.655 0.907919 107.418' +
  ' 0.00552794C106.052 -0.0876114 104.91 1.00833 104.91 2.37768V74.5638C104.91 77.0248 108.168 77.8979 109.399 75.7665L145.501 13.235Z',
  'M182.844 47.3663C184.031 46.6808 184.409 45.1415 183.642 44.0051C176.423 33.3069 167.193 24.0773 156.495' +
  ' 16.8581C155.358 16.0913 153.819 16.4687 153.134 17.656L117.037 80.1766C115.807 82.308 118.192 84.6932 120.323 83.4626L182.844 47.3663Z',
  'M198.122 95.5899C199.492 95.5899 200.588 94.4478 200.494 93.0816C199.592 79.8451 196.12 67.3144 190.573' +
  ' 55.9843C189.971 54.7545 188.451 54.3139 187.265 54.9985L124.733 91.1012C122.602 92.3317 123.475 95.5899 125.936 95.5899H198.122Z',
];
