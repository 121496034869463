export enum ColorsEnum {
  Primary = '#00A9A2',
  Black = '#051324',
  White = '#fff',
  PrimaryDisabled = '#047772',
  Default = '#616172',
  DefaultDisabled = '#D9D9D9',
  ColorD0 = '#D0D0DD80',
  Color90 = '#9090a0',
  BlueDefault = '#0EAAD9',
  BlueDark = '#2C73A6',
  Yellow = '#FFDF3A',
  YellowLight = '#ffec89',

  // new colors
  MonoGrey = '#A4A4A4',
  MonoDark = '#090804',
  MainBlue = '#00B0F0',
  MainGreen = '#23924F',
  MainDarkBlue = '#0078A4',
  MainDarkRed = '#A8191A',
  MainDarkGreen = '#09652F',
  MonoLight = '#F9F9F9',
  MainRed = '#D41F21',
  MonoBg = '#F5F9FF',
  GreyDisabled = '#C6C6C6',
  MainBrown = '#7E5C5C',
  MonoDarkGrey = '#505050',
  MainBrightorange = '#505050',
}
