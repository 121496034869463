<div
  [class.rtl]="languageService.isRtl"
  class="ls-search">
  <ls-input-text
    #input
    [class.ls-search-input--dark]="style === 'dark'"
    [class.ls-search-input--light]="style === 'light'"
    [fieldWithKeyboard]="fieldWithKeyboard"
    [formControl]="defaultControl"
    [placeholder]="'Search'"
    class="ls-search-input fz-20"
    ngDefaultControl />
  <button
    *ngIf="value"
    (click)="clear()"
    [class.show-close]="showCloseBtn"
    class="ls-search-clear"
    type="button">
    <ls-svg-icon
      [hasMargin]="true"
      [path]="style === 'light' ? 'common/cross' : 'common/cross-light'"></ls-svg-icon>
  </button>
</div>
