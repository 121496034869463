<div
  *ngIf="!(isLoading$ | async); else loading"
  class="d-flex h-100-percent flex-column justify-between">
  <div class="user">
    <div class="user-info d-flex align-center justify-between pb-18">
      <div class="d-flex align-center">
        <div class="user-initials flex-shrink-0">
          <span>{{ currentUserInitials$ | async | uppercase }}</span>
        </div>

        <h6 class="ml-8 user-name fz-18 fw-600 word-break">
          {{ currentUserFullName$ | async }}
        </h6>
      </div>

      <button
        *ngIf="!resetPasswordMode"
        (click)="logOut()"
        class="user-logout flex-shrink-0 ml-8">
        {{ "User.LogOut" | translate }}
      </button>
    </div>

    <ng-container *ngIf="!resetPasswordMode; else resetPassword">
      <div
        [formGroup]="form"
        class="form pt-18">
        <div class="mb-24">
          <lv-simple-dropdown
            [label]="'User.InterfaceLanguage' | translate"
            [enumString]="'controller-language'"
            [enum]="ControllerLanguageEnumShortList"
            formControlName="language">
          </lv-simple-dropdown>
        </div>

        <div class="mb-24">
          <lv-input
            [label]="'User.FullName'"
            [wrapperClassList]="'w-100-percent'"
            formControlName="fullName"></lv-input>

          <span
            *ngIf="form.get('fullName').dirty && form.get('fullName').invalid"
            class="validation-error">
            {{ "FormErrors.ThisFieldIsRequired" | translate }}
          </span>
        </div>

        <div class="mb-24">
          <lv-input
            [label]="'User.email'"
            [wrapperClassList]="'w-100-percent'"
            formControlName="email"></lv-input>
        </div>

        <div class="mb-18">
          <lv-input
            [label]="'User.PhoneNumber'"
            [wrapperClassList]="'w-100-percent'"
            formControlName="mobile"></lv-input>

          <span
            *ngIf="form.get('mobile').dirty && form.hasError('invalidPhoneNumber', 'mobile')"
            class="validation-error">
            {{ "Auth.SignUp.FormatInvalidUsePlusAndNumbers" | translate }}
          </span>
        </div>

        <div
          (click)="toggleResetPasswordMode()"
          class="reset-password mb-18">
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.87083 6.47107H5.00211M10 6.47107H10.1313M14.9964 6.47107H15.1277M9.4375 11.5336H3C1.89543 11.5336 1 10.6381 1 9.53357V3.40857C1 2.304 1.89543 1.40857 3 1.40857H17C18.1046 1.40857 19 2.304 19 3.40857V6.47107M13.198 10.4977L14.5011 9.84612C15.4513 9.37105 16.5697 9.37105 17.5198 9.84612L18.823 10.4977C18.823 10.4977 18.823 12.5508 18.823 13.6336C18.823 14.7165 17.8221 15.4474 16.0105 16.5915C14.1989 15.4474 13.198 14.4821 13.198 13.6336V10.4977Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>

          <span class="ml-10 fz-14">{{ "User.ResetPassword" | translate }}</span>
        </div>
      </div>
    </ng-container>

    <ng-template #resetPassword>
      <h3 class="mt-18 mb-8 fz-24">{{ "Auth.ForgotPassword.ChooseNewPassword" | translate }}</h3>
      <h4 class="mb-18 fz-12 line-height-12">{{ "Auth.GoodPasswordMessage" | translate }}</h4>

      <!--Copied from sign-up component-->
      <div
        [formGroup]="passwordForm"
        class="mt-18 mb-18 position-relative">
        <lv-input
          (inputFocus)="password['focused'] = true"
          (inputBlur)="password['focused'] = false"
          #password
          [placeholder]="'Auth.Login.Password' | translate"
          [qaTags]="'qa-txt-new-passwd'"
          [type]="'text'"
          [autocomplete]="'nope'"
          formControlName="password"
          class="d-block change-password-input">
          <ls-svg-icon
            [path]="IconsEnum.PASSWORD"
            [color]="
              passwordForm.get('password').dirty && passwordForm.get('password').invalid
                ? ColorsEnum.MainRed
                : !!password['focused']
                ? ColorsEnum.MainBlue
                : ColorsEnum.MonoDark
            "
            class="mr-8"
            leftIcon>
          </ls-svg-icon>

          <div
            *ngIf="passwordForm.get('password').valid"
            (click)="copyPassword($event)"
            class="copy-button pointer"
            rightIcon>
            <ls-svg-icon
              [path]="IconsEnum.COPY"
              class="mb-3">
            </ls-svg-icon>
            <span>{{ "Auth.Copy" | translate }}</span>
          </div>

          <div
            (click)="generatePassword($event)"
            class="generate-button"
            rightButton>
            <ls-svg-icon
              [path]="IconsEnum.GENERATE"
              class="pointer mb-4">
            </ls-svg-icon>
            <span>{{ "Auth.Generate" | translate }}</span>
          </div>
        </lv-input>

        <div
          *ngIf="
            passwordForm.get('password').valid &&
            passwordForm.get('password').value.length >= GlobalConstants.STRONG_PASSWORD_LENGTH
          "
          class="password-description strong-password">
          <div>
            <span>{{ "Auth.Strong" | translate }} </span>
            <span>{{ "Auth.PasswordGoodJob" | translate | lowercase }}</span>
          </div>
          <div class="password-visualization">
            <div class="green"></div>
            <div class="green ml-4 mr-4"></div>
            <div class="green"></div>
          </div>
        </div>

        <div
          *ngIf="
            passwordForm.get('password').valid &&
            passwordForm.get('password').value.length < GlobalConstants.STRONG_PASSWORD_LENGTH
          "
          class="password-description good-password">
          <div>
            <span>{{ "Auth.Good" | translate }} </span>
            <span>{{ "Auth.PasswordGoodEnough" | translate | lowercase }}</span>
          </div>
          <div class="password-visualization">
            <div class="orange"></div>
            <div class="orange ml-4 mr-4"></div>
            <div class="gray"></div>
          </div>
        </div>

        <div
          *ngIf="passwordForm.get('password').dirty && passwordForm.hasError('insecurePassword', 'password')"
          class="password-description week-password">
          <span>{{ "Auth.WeekPasswordNotGoodEnough" | translate }}</span>
          <div class="password-visualization">
            <div class="red"></div>
            <div class="gray ml-4 mr-4"></div>
            <div class="gray"></div>
          </div>
        </div>
      </div>

      <lv-button
        *ngIf="passwordForm.get('password').valid"
        (click)="saveNewPassword()"
        [type]="ButtonTypeEnum.PRIMARY"
        [height]="48"
        class="mb-18">
        <ls-svg-icon
          [path]="IconsEnum.RESET"
          [color]="ColorsEnum.MonoDark"></ls-svg-icon>
        <span [style.letter-spacing.px]="0.28">{{ "User.SaveNewPassword" | translate }}</span>
      </lv-button>
    </ng-template>
  </div>

  <div
    *ngIf="!resetPasswordMode && form.dirty && formWasChanged"
    class="buttons d-flex align-center justify-between pb-10">
    <div class="fw-500 cancel-button pl-28">
      <span
        (click)="closeUserPopupEmit()"
        class="pointer text-underline">
        {{ "Buttons.Cancel" | translate }}
      </span>
    </div>

    <lv-button
      (click)="updateCurrentUser()"
      [class.disabled]="form.invalid"
      [type]="ButtonTypeEnum.PRIMARY"
      [height]="48">
      <span>{{ "User.SaveChanges" | translate }}</span>
    </lv-button>
  </div>

  <div
    *ngIf="resetPasswordMode"
    class="buttons d-flex align-center justify-center mb-10">
    <div class="fw-500 cancel-button">
      <span
        (click)="toggleResetPasswordMode()"
        class="pointer text-underline">
        {{ "Buttons.Cancel" | translate }}
      </span>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="h-100-percent w-100-percent d-flex align-center justify-center">
    <lv-loading-galcon></lv-loading-galcon>
  </div>
</ng-template>
