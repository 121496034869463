import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import {
  InputDecimalComponent,
  InputIntegerComponent,
  LVInputComponent, LVInputDecimalWithLabelComponent,
  LVInputIntegerWithLabelComponent,
  selectVirtualKeyboardIsActive,
  SvgIconComponent,
} from '@livestock/ui';
import { IHouseSizesView } from '../../interfaces/house-sizes/house-sizes-view.interface';
import { ColorsEnum, IconsEnum, LengthUnitEnum } from '@livestock/shared/enums';
import { GlobalConstants } from '@livestock/shared/constants';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { Observable, Subscription } from 'rxjs';
import { wasChanged, wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';
import { greaterThanValidator, lessThanValidator } from '@livestock/shared/validators';
import { Store } from '@ngrx/store';
import { selectExistingHouseNumbers, selectNextAvailableHouseNumber } from '@livestock/farms';
import { notInListValidator } from '../../../../../shared/validators/not-in-list.validator';
import { Actions, ofType } from '@ngrx/effects';
import { updateControllerHouseSizesSettingsSuccess } from '../../+state/current-controller/settings/settings.actions';

@Component({
  selector: 'lv-house-sizes-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SvgIconComponent,
    QaTagsDirective,
    InputDecimalComponent,
    ErrorFieldDirective,
    LVInputIntegerWithLabelComponent,
    InputIntegerComponent,
    LVInputComponent,
    LVInputDecimalWithLabelComponent,
  ],
  templateUrl: './house-sizes-settings-form.component.html',
  styleUrls: ['./house-sizes-settings-form.component.scss'],
})
export class ControllerHouseSizesSettingsFormComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean = false;
  initialFormValue: IHouseSizesView;

  @Input() set houseSizesSettings(houseSizesSettings: IHouseSizesView) {
    if (houseSizesSettings) {
      this.updateFormValues(houseSizesSettings);
    }
  }

  @Output() changed = new EventEmitter();

  sub$ = new Subscription();
  isKeyboardActive$: Observable<boolean> = this.store.select(selectVirtualKeyboardIsActive);
  existingHouseNumbers$: Observable<number[]> = this.store.select(selectExistingHouseNumbers);
  nextAvailableHouseNumber$: Observable<number> = this.store.select(selectNextAvailableHouseNumber);

  HouseNumberMin = 1;
  HouseNumberMax = 999;
  form = new FormGroup(
    {
      houseNumber: new FormControl<number>(1,
        [Validators.min(this.HouseNumberMin), Validators.max(this.HouseNumberMax)],
      ),
      houseWidth: new FormControl(GlobalConstants.MinMeterWidthHeightLength),
      houseLength: new FormControl(GlobalConstants.MinMeterWidthHeightLength),
      houseHeight: new FormControl(GlobalConstants.MinMeterWidthHeightLength),
      houseRoofHeight: new FormControl(GlobalConstants.MinMeterWidthHeightLength),
    },
  );

  LengthUnitEnum = LengthUnitEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;
  inputWidth = 70;
  inputWidthForHouseLength = 85;

  //vars
  minValue: number;
  maxWidthHeightValue: number;
  maxLengthValue: number;
  accuracy: number = 1;
  houseNumberRangesAreVisible: boolean;

  //vars mobile
  houseHeightInFocus: boolean;
  houseRoofHeightInFocus: boolean;
  houseWidthInFocus: boolean;
  houseLengthInFocus: boolean;

  private _lengthUnit: LengthUnitEnum;

  get lengthUnit(): LengthUnitEnum {
    return this._lengthUnit;
  }

  @Input() set lengthUnit(lengthUnit: LengthUnitEnum) {
    this._lengthUnit = lengthUnit;
    this.setMaxValidators(lengthUnit);
  }

  constructor(
    public languageService: LanguageService,
    public platformService: PlatformService,
    private store: Store,
    private actions$: Actions,
  ) {
  }

  ngOnInit(): void {
    this.initialFormValue = this.form.getRawValue();

    this.sub$.add(
      this.actions$.pipe(
        ofType(updateControllerHouseSizesSettingsSuccess),
      ).subscribe(() => {
        this.initialFormValue = this.form.getRawValue();
      }),
    );

    this.sub$.add(
      this.existingHouseNumbers$.pipe(
        wasChangedAndNotNull(),
      ).subscribe(existingHouseNumbers => {
        setTimeout(() => {
          const usedHouseNumbers = existingHouseNumbers.filter(num => num !== this.initialFormValue.houseNumber);

          this.form.controls['houseNumber'].addValidators([
              Validators.min(this.HouseNumberMin),
              Validators.max(this.HouseNumberMax),
              notInListValidator(usedHouseNumbers),
            ],
          );
          this.form.controls['houseNumber'].updateValueAndValidity();
        });
      }),
    );

    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged())
        .subscribe((formValues) => {
          this.setMaxValidators(this.lengthUnit);

          this.changed.emit({
            formValues,
            isValid: this.form.valid,
            isChanged: JSON.stringify(this.initialFormValue) !== JSON.stringify(this.form.value),
            initialHouseNumber: this.initialFormValue.houseNumber,
          });
        }),
    );
  }

  setMaxValidators(lengthUnit: LengthUnitEnum): void {
    const isValidBeforeSettingsValidators = this.form.valid;
    const controls = ['houseWidth', 'houseLength', 'houseHeight', 'houseRoofHeight'];
    this.maxLengthValue = lengthUnit === LengthUnitEnum.Foot
      ? GlobalConstants.MaxFootLength
      : GlobalConstants.MaxMeterLength;
    this.maxWidthHeightValue = lengthUnit === LengthUnitEnum.Foot
      ? GlobalConstants.MaxFootWidthAndHeight
      : GlobalConstants.MaxMeterWidthAndHeight;
    this.minValue = lengthUnit === LengthUnitEnum.Foot
      ? GlobalConstants.MinFootWidthHeightLength
      : GlobalConstants.MinMeterWidthHeightLength;

    controls.forEach(controlName => {
      this.form.controls[controlName].clearValidators();
    });

    //height
    this.form.controls['houseHeight'].addValidators([
        Validators.min(this.minValue),
        Validators.max(this.maxWidthHeightValue),
        lessThanValidator('houseRoofHeight', null, true),
      ],
    );

    this.form.controls['houseRoofHeight'].addValidators([
        Validators.min(this.minValue),
        Validators.max(this.maxWidthHeightValue),
        greaterThanValidator('houseHeight', null, true),
      ],
    );

    //width && length
    this.form.controls['houseWidth'].addValidators([
        Validators.min(this.minValue),
        Validators.max(this.maxWidthHeightValue),
        lessThanValidator('houseLength', null, true),
      ],
    );

    this.form.controls['houseLength'].addValidators([
        Validators.min(this.minValue),
        Validators.max(this.maxLengthValue),
        greaterThanValidator('houseWidth', null, true),
      ],
    );

    controls.forEach(controlName => {
      this.form.controls[controlName].updateValueAndValidity();
    });

    if (isValidBeforeSettingsValidators != this.form.valid) {
      this.changed.emit({
        formValues: this.form.value,
        isValid: this.form.valid,
      });
    }
  }

  updateFormValues(houseSizesSettings: IHouseSizesView): void {
    this.form.patchValue(houseSizesSettings);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
