import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ofType } from '@ngrx/effects';

import { ButtonComponent, LoadingGalconComponent, SlimButtonComponent, SvgIconComponent } from '@livestock/ui';
import { AbstractFarmFormComponent } from '../abstract-farm-form.component';
import {
  deleteFarmById,
  deleteFarmByIdSuccess,
  IFarmInfo,
  setUserDetailsMode,
  updateFarmById,
  updateFarmByIdSuccess,
  UserDetailsModeEnum,
} from '@livestock/farms';
import { FarmUsersListComponent } from '../farm-users-list/farm-users-list.component';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { FarmUserRolesEnum } from '@livestock/shared/enums';
import { DialogsService, PlatformService } from '@livestock/shared/services';

@Component({
  selector: 'lv-update-farm',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SlimButtonComponent, SvgIconComponent, TranslateModule, ButtonComponent, NgClickOutsideDirective, FarmUsersListComponent, LoadingGalconComponent],
  templateUrl: './update-farm.component.html',
  styleUrls: ['./update-farm.component.scss'],
})
export class UpdateFarmComponent extends AbstractFarmFormComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() farm: IFarmInfo;
  @Input() isInviteButtonVisible!: boolean;
  @Input() currentUserAccessRole: FarmUserRolesEnum;
  @Input() isMapAlwaysOpen: boolean = false;

  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() onUserInvite: EventEmitter<void> = new EventEmitter<void>();

  isSubmitVisible: boolean;

  FarmUserRolesEnum = FarmUserRolesEnum;

  constructor(
    public platformService: PlatformService,
    private readonly dialogsService: DialogsService,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setFormValue();
    this.locationMapIsOpen = this.isMapAlwaysOpen;
    if (this.currentUserAccessRole === FarmUserRolesEnum.Viewer) {
      this.form.disable();
    }

    this.sub$.add(this.actions$.pipe(ofType(deleteFarmByIdSuccess))
      .subscribe(() => this.deleted.emit()));
    this.sub$.add(this.actions$.pipe(ofType(updateFarmByIdSuccess)).subscribe(() => {
      this.onFocusOutFarmNameInput();
      this.onFocusOutFarmLocationInput(!this.isMapAlwaysOpen);
      this.isSubmitVisible = false;
    }));
    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged())
        .subscribe((value: Partial<IFarmInfo>) => {
          this.isSubmitVisible =
            this.form.valid && (value.name !== this.farm.name || value.address !== this.farm.address);
        }),
    );
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.setupMap(this.farm.latitude, this.farm.longitude, 17);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['currentUserAccessRole']?.currentValue != null) {
      const isDisabled = [FarmUserRolesEnum.Viewer, null].includes(changes?.['currentUserAccessRole']?.currentValue);
      this.form[isDisabled ? 'disable' : 'enable']();
    }
  }

  onDeleteFarm(): void {
    this.sub$.add(this.dialogsService.confirm({
      title: this.translateService.instant('Farms.FarmUpdate.DeleteFarm'),
      description: this.translateService.instant('Farms.FarmUpdate.ThisActionWillDeleteFarm', [this.farm.name]),
      type: 'danger',
      showNotReversibleWarning: true,
      confirmLabelKey: 'Buttons.Delete',
    }).subscribe((confirm: boolean) => confirm && this.onDeleteFarmConfirm()));
  }

  onDeleteFarmConfirm(): void {
    this.store.dispatch(deleteFarmById({ farmID: this.farm.farmID }));
  }

  onCancelNameEdit(): void {
    if (this.fieldNameInputInFocus) {
      this.onFocusOutFarmNameInput();
    }
  }

  onCancelAddressEdit(): void {
    if (this.fieldAddressInputInFocus) {
      this.onFocusOutFarmLocationInput(!this.isMapAlwaysOpen);
    }
  }

  onInviteUser(): void {
    if (!this.isSubmitVisible) {
      this.store.dispatch(setUserDetailsMode({ userDetailsMode: UserDetailsModeEnum.NewInvitation }));
      this.onUserInvite.emit();
    }
  }

  onSave(): void {
    if (this.form.valid) {
      this.store.dispatch(updateFarmById({
        farmID: this.farm.farmID,
        farm: { ...this.farm, ...this.form.value },
      }));
    }
  }

  onAbort(): void {
    this.setFormValue();
  }

  private setFormValue(): void {
    this.form.patchValue({
      name: this.farm.name,
      address: this.farm.address,
      latitude: this.farm.latitude,
      longitude: this.farm.longitude,
    });
  }
}
