import { CommonUserRolesEnum } from '../../../../shared/enums/users/common-user-roles.enum';
import { ControllerLanguageEnum } from '@livestock/controllers';

export interface ICurrentUserView {
  userID: number;
  fullName: string;
  role: CommonUserRolesEnum;
  email: string;
  mobile: string;
  language: ControllerLanguageEnum;
}
