import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as currentControllerActions from '../current-controller.actions';
import {
  selectActiveControllerID,
} from '../current-controller.selectors';
import { Router } from '@angular/router';
import { PlatformService } from '@livestock/shared/services';
import { MatDialog } from '@angular/material/dialog';
import { ControllerEditModeService } from '../../../services/controller-edit-mode.service';

@Injectable()
export class EditModeEffects {
  getEditMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.getEditMode),
      map(action => action.page),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([page, controllerID]) => {
        return this.controllerEditModeService.getEditMode(controllerID, page).pipe(
          map((response) =>
            currentControllerActions.getEditModeSuccess({ payload: response })),
          catchError((error) => of(currentControllerActions.getEditModeError({ payload: error }))),
        );
      }),
    ),
  );

  createEditMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.createEditMode),
      map(action => action.page),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([page, controllerID]) => {
        return this.controllerEditModeService.createEditMode(controllerID, page).pipe(
          map(() =>
            currentControllerActions.createEditModeSuccess()),
          catchError((error) => of(currentControllerActions.createEditModeError({ payload: error, page }))),
        );
      }),
    ),
  );

  updateEditMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.updateEditMode),
      map(action => action.page),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([page, controllerID]) => {
        return this.controllerEditModeService.updateEditMode(controllerID, page).pipe(
          map(() =>
            currentControllerActions.updateEditModeSuccess()),
          catchError((error) => of(currentControllerActions.updateEditModeError({ payload: error }))),
        );
      }),
    ),
  );

  deleteEditMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentControllerActions.deleteEditMode),
      map(action => action.page),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([page, controllerID]) => {
        return this.controllerEditModeService.deleteEditMode(controllerID, page).pipe(
          map(() =>
            currentControllerActions.deleteEditModeSuccess()),
          catchError((error) => of(currentControllerActions.deleteEditModeError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private router: Router,
    private platformService: PlatformService,
    private dialog: MatDialog,
    private actions$: Actions,
    private controllerEditModeService: ControllerEditModeService,
  ) {
  }
}
