import { ICreateFarm } from './create-farm.interface';
import { IUser } from '@livestock/shared/interfaces';

export interface IFarmInfo extends ICreateFarm {
  farmID: number;
  name: string;
  controllersCount: number;
  usersCount: number;
  latitude: number;
  longitude: number;
  address: string;
  existingHouseNumbers: number[];
  screenshotURL?: string;
  users?: IUser[];
  controllers?: any[];
}
