<div class="dialog pv-28 ph-18">
  <h2 class="{{ data.type }} fw-700 line-height-1">{{ data.title }}</h2>
  <div
    [innerHTML]="data.description"
    class="pt-8 fw-600 line-height-12"></div>
  <div
    *ngIf="data.showNotReversibleWarning"
    class="pt-8 hint">
    {{ "Dialogs.ThisActionIsNotReversible" | translate }}
  </div>
  <div
    [class.flex-row-reverse]="data.reverseOrder"
    class="d-flex justify-between flex-wrap buttons-wrapper gap-4 pt-18">
    <lv-button
      (click)="onClose(true)"
      [type]="data.confirmButtonType || defaultConfirmButtonType"
      [class.mb-18-mobile]="!data.reverseOrder"
      cssClass="confirm-button">
      {{ data.confirmLabelKey || defaultConfirmButtonLabelKey | translate }}
    </lv-button>

    <lv-button
      (click)="onClose(false)"
      [type]="data.cancelButtonType || defaultCancelButtonType"
      [class.mb-18-mobile]="data.reverseOrder"
      cssClass="confirm-button">
      {{ data.cancelLabelKey || defaultCancelButtonLabelKey | translate }}
    </lv-button>
  </div>
</div>
