<lv-settings-wrapper
  (toggleDisabled)="toggleDisabled($event)"
  (save)="onSave()"
  (onReload)="onReload()"
  section="{{ 'Flock.Flock' | translate }}"
  title="{{ 'Flock.FlockSettings' | translate }}"
  [page]="PageTypeEnum.FlockSettings"
  [isSuccess]="isSuccess"
  [isValid]="formComponent?.form?.valid"
  [isEditMode]="isEditMode"
  [isChanged]="isChanged">
  <lv-flock-settings-form
    *ngIf="!(isLoading$ | async); else loading"
    (changed)="onChange($event)"
    [isEditMode]="isEditMode"></lv-flock-settings-form>

  <ng-template #loading>
    <div class="h-100-percent w-100-percent d-flex align-center justify-center">
      <lv-loading-galcon></lv-loading-galcon>
    </div>
  </ng-template>
</lv-settings-wrapper>
