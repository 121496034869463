<lv-settings-wrapper
  (toggleDisabled)="toggleDisabled($event)"
  [page]="PageTypeEnum.Installation"
  [isEditMode]="isEditMode"
  section="{{ 'ControllerMenu.Sections.BasicSettings' | translate }}"
  title="{{ 'Controller.QuickStart.Installation' | translate }}"
  [isValid]="true">
  <div
    *ngIf="!(cardsAreLoading$ | async); else loading"
    [class.setting-mode]="settingsMode"
    class="h-100-percent d-flex gap-18 installation-container">
    <div class="elements">
      <ng-container *ngFor="let elementGroup of InstallationElementGroupsEnum | enumToArray">
        <div
          *ngIf="selectedElementGroup == null || selectedElementGroup === elementGroup"
          (click)="selectElementGroup(elementGroup)"
          [class.element-group__active]="selectedElementGroup === elementGroup"
          class="element-group d-flex align-center justify-between p-18 mb-8 pointer">
          <span class="fw-600">
            {{ elementGroup | enum : "installation-elements-list" | translate }}
          </span>

          <ls-svg-icon
            [path]="IconsEnum.ARROW_SELECT"
            [class.rotate-180]="selectedElementGroup === elementGroup"></ls-svg-icon>
        </div>
      </ng-container>

      <lv-installation-elements-list
        *ngIf="selectedElementGroup != null"
        (setActiveElement)="setActiveElement($event)"
        [elements]="(getElements$ | async)(selectedElementGroup)"
        [selectedElementGroup]="selectedElementGroup"></lv-installation-elements-list>
    </div>

    <div
      [class.border-bottom-left-none]="isLastElementSelected"
      class="content flex-grow-1">
      <h2 class="fz-56 mb-28">Cards Screen</h2>

      <ng-container *ngIf="activeElementType">
        <ls-svg-icon
          *ngIf="platformService.isDeviceApp && !settingsMode"
          (click)="addElementToConnection()"
          [class.pointer-events-none]="!isEditMode || (isAnyElementConnected$ | async)(activeElementType)"
          [color]="
            isEditMode && !(isAnyElementConnected$ | async)(activeElementType)
              ? ColorsEnum.MainBlue
              : ColorsEnum.GreyDisabled
          "
          [path]="IconsEnum.ADD_ELEMENT_PLUS"></ls-svg-icon>

        <div
          *ngIf="(isAnyElementConnected$ | async)(activeElementType); else noConnectedElements"
          (click)="toggleSettingsMode()"
          class="mockup-btn mt-28 pointer">
          <ls-svg-icon
            *ngIf="settingsMode"
            [path]="IconsEnum.LEFT_ARROW"
            class="mr-10"></ls-svg-icon>
          <span>{{ ElementTypesEnum.toTranslateKey(activeElementType) | translate }} 1 - Settings</span>
        </div>
      </ng-container>
    </div>

    <div
      *ngIf="settingsMode"
      class="settings">
      <lv-element-wrapper
        (unsetEditMode)="isEditMode = false"
        (isChangedEvent)="isFormChanged($event)"
        [isEditMode]="isEditMode"
        [currentElementType]="activeElementType">
      </lv-element-wrapper>
    </div>
  </div>

  <ng-template #loading>
    <div class="h-100-percent w-100-percent d-flex align-center justify-center">
      <lv-loading-galcon></lv-loading-galcon>
    </div>
  </ng-template>

  <ng-template #noConnectedElements>
    <div class="mt-28">
      {{ "Installation.NoConnectedElements" | translate }}
    </div>
  </ng-template>
</lv-settings-wrapper>
