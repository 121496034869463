import {
  AirFlowUnitEnum,
  AirPressureUnitEnum,
  AirSpeedUnitEnum,
  IlluminanceUnitEnum,
  LengthUnitEnum,
  TemperatureUnitEnum,
  VolumeUnitEnum,
  WaterLinePressureUnitEnum,
  WaterMainPressureUnitEnum,
  WeightUnitEnum,
} from '@livestock/shared/enums';

export interface IUnitsDetails {
  temperature: TemperatureUnitEnum,
  airFlow: AirFlowUnitEnum,
  airSpeed: AirSpeedUnitEnum,
  airPressure: AirPressureUnitEnum,
  waterMainPressure: WaterMainPressureUnitEnum,
  waterLinePressure: WaterLinePressureUnitEnum,
  weight: WeightUnitEnum;
  volume: VolumeUnitEnum;
  length: LengthUnitEnum;
  illuminance: IlluminanceUnitEnum;
  controllerID?: number;
}
