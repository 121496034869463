import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { interval, map, Observable, startWith, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';

import { SlimButtonComponent } from '@livestock/ui';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonTypeEnum } from '@livestock/shared/enums';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SlimButtonComponent,
  ],
  selector: 'lv-inactivity-warning-modal',
  templateUrl: './inactivity-warning-modal.component.html',
  styleUrls: ['./inactivity-warning-modal.component.scss'],
  providers: [DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InactivityWarningModalComponent {
  dialogRef: MatDialogRef<InactivityWarningModalComponent> = inject(MatDialogRef);
  decimalPipe: DecimalPipe = inject(DecimalPipe);

  private minutes: number = 5;
  private seconds: number = 1;

  timer$: Observable<string>;

  readonly ButtonTypeEnum = ButtonTypeEnum;

  constructor() {
    this.timer$ = interval(1000).pipe(
      startWith(0),
      takeUntilDestroyed(),
      tap(() => this.updateCountdown()),
      map(() => (`${this.minutes}:${this.decimalPipe.transform(this.seconds, '2.0')}`)),
    );
  }

  continueSession(): void {
    this.dialogRef.close({ isActive: true });
  }

  private updateCountdown(): void {
    if (!this.minutes && !this.seconds) {
      this.dialogRef.close({ isActive: false });
    } else if (!this.seconds) {
      this.minutes--;
      this.seconds = 59;
    } else {
      this.seconds--;
    }
  }
}
