<div
  *ngIf="parentFormGroup.get(formGroupName) as form"
  [formGroupName]="formGroupName">
  <div
    [ngClass]="platformService.isDeviceApp ? 'justify-between' : 'justify-end'"
    class="d-flex align-center w-100-percent h-28">
    <div
      *ngIf="platformService.isDeviceApp"
      (click)="uninstall()"
      class="d-flex align-center pointer">
      <ls-svg-icon [path]="IconsEnum.UNINSTALL_ELEMENT"> </ls-svg-icon>
      <span class="fz-12 fw-500 mh-8">{{ "Installation.Uninstall" | translate }}</span>
    </div>
    <div class="d-flex align-center">
      <ng-container *ngIf="!isTestMode; else testModeTemplate">
        <div
          [ngClass]="elementEvent?.status === OffOnEnum.On ? 'circle__on' : 'circle__off'"
          class="circle mh-4"></div>
        <div class="d-flex">
          <span
            [ngClass]="elementEvent?.status === OffOnEnum.On ? 'color-darkblue' : 'color-black'"
            class="fz-16 fw-600">
            {{
              elementEvent?.status != null
                ? (elementEvent?.status | enum : "off-on-enum" | translate | uppercase)
                : ("Installation.Off" | translate | uppercase)
            }}
          </span>
        </div>
      </ng-container>
      <ng-template #testModeTemplate>
        <ls-svg-icon
          [path]="IconsEnum.TEST_STATUS"
          class="mh-4">
        </ls-svg-icon>
        <span class="fw-600 color-darkgreen">{{ "Installation.Testing" | translate }}</span>
      </ng-template>
    </div>
  </div>

  <!--FORM-->
  <lv-input
    [label]="'Installation.Cooling.DeviceName'"
    [qaTags]="'qa-txt-device-name'"
    [autocomplete]="'nope'"
    formControlName="deviceName"
    type="text"
    class="full-width mt-28 label-bg-white">
  </lv-input>

  <lv-dual-toggle-with-label
    [optionsTitlePosition]="
      form['controls'].operation.value === ElementOperationEnum.NormallyClosed ? 'after' : 'before'
    "
    [firstOption]="{
      title: 'Installation.NormallyOpen',
      value: InstallationConstants.OperationNormallyOpen,
      theme: ThemeEnum.Default,
    }"
    [secondOption]="{
      title: 'Installation.NormallyClosed',
      value: InstallationConstants.OperationNormallyClose,
      theme: ThemeEnum.Default,
    }"
    [label]="'Installation.Cooling.Operation'"
    class="flex-1 mt-18 label-bg-white"
    formControlName="operation">
  </lv-dual-toggle-with-label>

  <lv-button
    *ngIf="!isTestMode"
    (click)="runTest()"
    [type]="!isEditMode ? ButtonTypeEnum.DISABLED : ButtonTypeEnum.SECONDARY_GREEN"
    [class.pointer-events-none]="!isEditMode"
    [height]="56"
    class="w-100-percent mt-18">
    <span>{{ "Installation.RunTest" | translate }}</span>
  </lv-button>

  <div
    *ngIf="isTestMode"
    class="test-stop-btn-with-timer mt-18">
    <div class="timer">
      <span class="fz-32 fw-600">{{ elementEvent?.testTime }}</span>
      <span class="fz-12 mh-1">{{ "Installation.Sec" | translate }}</span>
    </div>
    <div
      (click)="stopTest()"
      class="stop-btn">
      <div class="stop-btn__square"></div>
      <span class="fz-12 mt-4">{{ "Installation.Stop" | translate }}</span>
    </div>
  </div>
</div>
