import { Action, createReducer, on } from '@ngrx/store';
import { ControllerMenuState } from './controller-menu.state';
import * as controllerMenuActions from './controller-menu.actions';
import { ControllerMenuSectionsEnum } from '../enums/controller-menu-sections.enum';

export const CONTROLLER_MENU_KEY = 'controllerMenu';

export const initialState: ControllerMenuState = {
  menuIsOpened: false,
  activeMenuSection: ControllerMenuSectionsEnum.Control,
  activePage: null,
};

const reducer = createReducer(
  initialState,
  on(controllerMenuActions.toggleMenuIsOpened, (state, { menuIsOpened }) => {
    return {
      ...state,
      menuIsOpened,
    };
  }),
  on(controllerMenuActions.changeMenuSection, (state, { activeMenuSection }) => {
    return {
      ...state,
      activeMenuSection,
    };
  }),
  on(controllerMenuActions.changeControllerPage, (state, { activePage }) => {
    return {
      ...state,
      activePage,
    };
  }),
  on(controllerMenuActions.resetMenuItems, (state) => {
    return {
      ...state,
      menuIsOpened: false,
      activeMenuSection: ControllerMenuSectionsEnum.Control,
      activePage: null,
    };
  }),
);

export function controllerMenuReducer(state: ControllerMenuState | undefined, action: Action): any {
  return reducer(state, action);
}
