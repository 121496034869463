import { CommonModule } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonComponent, SvgIconComponent, LVInputComponent } from '@livestock/ui';
import { Subscription, filter, first, map, delay, firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as authActions from '../../+state/auth.actions';
import { AuthState } from '../../+state/auth.reducer';
import { getAddControllerTicketView } from '@livestock/parring-process';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { removeFlashMessage, setFlashMessage } from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';
import {
  EmailValidator,
  RequiredTrimValidator,
} from '@livestock/shared/validators';
import { LocalStorageService, PlatformService } from '@livestock/shared/services';
import { Capacitor } from '@capacitor/core';
import { BiometryService } from '../../services/biometry.service';
import { IconsEnum, ColorsEnum, StorageItem } from '@livestock/shared/enums';
import { BiometricAuthError, Credentials } from 'capacitor-native-biometric';
import { selectWasLoggedIn } from '../../+state/auth.selectors';
import { ViewChild, ElementRef } from '@angular/core';
import { ControllerLanguageEnum } from '@livestock/controllers';
import { ILoginData } from '../../interfaces/login-data.interface';

@Component({
  selector: 'ls-login',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    ButtonComponent,
    TranslateModule,
    QaTagsDirective,
    SvgIconComponent,
    LVInputComponent,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('password') passwordRef: ElementRef;
  readonly sub$ = new Subscription();

  form: UntypedFormGroup = new UntypedFormGroup({});
  passwordInputToggle: boolean = false;
  isNative: boolean = false;
  isCredentialsStoredWithBiometry: boolean = false;
  userCancelledBiometry: boolean = false;
  wasLoggedIn: boolean = false;
  validateForm = false;

  IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;

  constructor(
    public platformService: PlatformService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AuthState>,
    private biometryService: BiometryService,
  ) {
    this.isNative = Capacitor.isNativePlatform();
  }

  ngOnInit(): void {
    const isAuthenticated = LocalStorageService.getStorageItem(StorageItem.Token) != null;
    if (isAuthenticated) {
      this.store.dispatch(authActions.logOut());
    }

    this.activatedRoute.queryParams
      .pipe(
        map((i) => i['ticketId']),
        filter((ticketId) => ticketId),
        first(),
        delay(500),
      )
      .subscribe((ticketId) => {
        const isReconnect = this.activatedRoute.snapshot.queryParams['isReconnect'];
        this.store.dispatch(getAddControllerTicketView({ ticketId, isReconnect }));
      });

    this.initForm();

    this.setIsCredentialsStoredWithBiometry();
  }

  private async initForm(): Promise<void> {
    this.form = new FormGroup({
      login: new FormControl('', [
        Validators.required,
        RequiredTrimValidator,
        EmailValidator,
      ]),
      password: new FormControl('', [RequiredTrimValidator]),
    });
    this.sub$.add(
      this.form.valueChanges.subscribe(() => {
        this.validateForm = false;
      }),
    );
  }

  navigateTo(route: string): void {
    this.router.navigate([`auth/${route}`]);
  }

  focusPassword(): void {
    this.passwordRef['inputRef'].nativeElement.focus();
  }

  login(): void {
    if (this.form.valid) {
      this.store.dispatch(removeFlashMessage());
      const { _language, ...data } = this.form.value;
      const payload: ILoginData = {
        ...data,
        language: LocalStorageService.getStorageItem(StorageItem.CurrentLanguage) != null
          ? +LocalStorageService.getStorageItem(StorageItem.CurrentLanguage)
          : ControllerLanguageEnum.EngUS,
      };
      this.store.dispatch(authActions.login({ payload }));
      return;
    }

    this.validateForm = true;
  }

  async getBiometricAuthentication(): Promise<void> {
    let credentials: Credentials;
    try {
      credentials = await this.biometryService.getCredentialsWithBiometry();
    } catch (error) {
      if (error?.['code'] == BiometricAuthError.USER_CANCEL) {
        this.userCancelledBiometry = true;
      }
    }

    if (!credentials) {
      if (!this.userCancelledBiometry) {
        this.store.dispatch(
          setFlashMessage({
            flashMessage: {
              flashType: FlashMessageTypeEnum.Error,
              message: 'FlashMessages.BiometryGetCredsFail',
            },
          }),
        );
      }
      return;
    }

    this.form.get('login').patchValue(credentials.username);
    this.form.get('password').patchValue(credentials.password);
    this.form.updateValueAndValidity();
    this.login();
  }

  private async setIsCredentialsStoredWithBiometry(): Promise<void> {
    this.isCredentialsStoredWithBiometry = await this.biometryService.isBiometrySetup();
    this.wasLoggedIn = await firstValueFrom(this.store.select(selectWasLoggedIn));

    if (this.isCredentialsStoredWithBiometry && !this.wasLoggedIn) {
      this.getBiometricAuthentication();
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
