import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import {
  IGeneralSettingsView,
  IDateTimeSettingsView,
  INetworkSettingsView,
  IHouseSizesView,
  IGeneralSettingsViewNoUnits,
  IUnitsDetails, ICard,
} from '@livestock/controllers/interfaces';
import {
  LengthUnitEnum,
} from '@livestock/shared/enums';
import {
  IInternetConnectionStatus,
} from '@livestock/controllers/interfaces';
import { PlatformService } from '@livestock/shared/services';
import { ConvertHelper } from '@livestock/shared/utils';
import { baseUrl, deviceUrl, pureDeviceControllerUrl } from './basic-api-url.constants';
import { ControllerUnitEnum } from '../enums';
import { environment } from '@livestock/shared/environments';
import { IFlockSettingsView } from '@livestock/flock/models';

@Injectable({
  providedIn: 'root',
})
export class ControllerSettingsService {
  private http: HttpClient = inject(HttpClient);
  private platformService: PlatformService = inject(PlatformService);

  /*SETTINGS*/
  getGeneralSettings(controllerID: number): Observable<IGeneralSettingsView> {
    return this.http.get<IGeneralSettingsView>(this.platformService.isDeviceApp
      ? `${deviceUrl(controllerID)}/general`
      : `${baseUrl}/${controllerID}/settings/general`);
  }

  updateGeneralSettings(controllerID: number, requestView: IGeneralSettingsViewNoUnits, unitsDetails: IUnitsDetails): Observable<void> {
    return this.http.put<void>(
      this.platformService.isDeviceApp
        ? `${deviceUrl(controllerID)}/general`
        : `${baseUrl}/${controllerID}/settings/general`,
      {
        ...requestView,
        unitsDetails: requestView['units'] === ControllerUnitEnum.Custom ? unitsDetails : null,
      },
    );
  }

  getHouseSizesSettings(controllerID: number, lengthUnit: LengthUnitEnum): Observable<IHouseSizesView> {
    const url = this.platformService.isDeviceApp
      ? `${deviceUrl(controllerID)}/house-sizes`
      : `${baseUrl}/${controllerID}/settings/house-sizes`;

    return this.http.get<IHouseSizesView>(url).pipe(map(
      settings => ({
        ...settings,
        houseHeight: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.metersToFeet(settings.houseHeight, 1) : settings.houseHeight,
        houseLength: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.metersToFeet(settings.houseLength, 1) : settings.houseLength,
        houseRoofHeight: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.metersToFeet(settings.houseRoofHeight, 1) : settings.houseRoofHeight,
        houseWidth: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.metersToFeet(settings.houseWidth, 1) : settings.houseWidth,
      }),
    ));
  }

  updateHouseSizesSettings(controllerID: number, requestView: IHouseSizesView, lengthUnit: LengthUnitEnum): Observable<void> {
    const url = this.platformService.isDeviceApp
      ? `${deviceUrl(controllerID)}/house-sizes`
      : `${baseUrl}/${controllerID}/settings/house-sizes`;

    requestView = {
      ...requestView,
      houseHeight: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.feetToMeters(requestView.houseHeight, 1) : requestView.houseHeight,
      houseLength: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.feetToMeters(requestView.houseLength, 1) : requestView.houseLength,
      houseRoofHeight: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.feetToMeters(requestView.houseRoofHeight, 1) : requestView.houseRoofHeight,
      houseWidth: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.feetToMeters(requestView.houseWidth, 1) : requestView.houseWidth,
    };
    return this.http.put<void>(url, requestView);
  }

  getDateTimeSettings(controllerID: number): Observable<IDateTimeSettingsView> {
    return this.platformService.isDeviceApp
      ? this.http.get<IDateTimeSettingsView>(`${deviceUrl(controllerID)}/date-time`)
      : this.http.get<IDateTimeSettingsView>(`${baseUrl}/${controllerID}/settings/date-time`);
  }

  updateDateTimeSettings(controllerID: number, requestView: IDateTimeSettingsView): Observable<void> {
    return this.platformService.isDeviceApp
      ? this.http.put<void>(`${deviceUrl(controllerID)}/date-time`, requestView)
      : this.http.put<void>(`${baseUrl}/${controllerID}/settings/date-time`, requestView);
  }

  getNetworkSettings(controllerID: number): Observable<INetworkSettingsView> {
    return this.http.get<INetworkSettingsView>(`${baseUrl}/${controllerID}/settings/network`);
  }

  updateNetworkSettings(controllerID: number, requestView: INetworkSettingsView): Observable<IInternetConnectionStatus> {
    return this.http.put<IInternetConnectionStatus>(`${baseUrl}/${controllerID}/settings/network`, requestView);
  }

  getFlockSettings(controllerID: number): Observable<IFlockSettingsView> {
    const url = this.platformService.isDeviceApp
      ? `${deviceUrl(controllerID)}/flock/settings`
      : `${baseUrl}/${controllerID}/flock/settings`;

    return this.http.get<IFlockSettingsView>(url);
  }

  updateFlockSettings(controllerID: number, requestView: IFlockSettingsView): Observable<void> {
    return this.platformService.isDeviceApp
      ? this.http.put<void>(`${deviceUrl(controllerID)}/flock/settings`, requestView)
      : this.http.put<void>(`${baseUrl}/${controllerID}/flock/settings`, requestView);
  }

  createNewFlock(controllerID: number, requestView: IFlockSettingsView): Observable<void> {
    return this.platformService.isDeviceApp
      ? this.http.put<void>(`${deviceUrl(controllerID)}/flock`, requestView)
      : this.http.put<void>(`${baseUrl}/${controllerID}/flock`, { flockSettings: requestView });
  }

  getCards(): Observable<ICard[]> {
    return this.http.get<ICard[]>(`${this.platformService.isDeviceApp ? pureDeviceControllerUrl : environment.apiUrl}/cards`);
  }
}
