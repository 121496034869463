import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef, inject,
  Input, OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl, ControlContainer,
  ControlValueAccessor, FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { QaTagsDirective } from '@livestock/shared/directives';
import { LanguageService } from '@livestock/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { NativeElementInjectorDirective } from '../native-element.directive';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { SlimButtonComponent } from '../button-slim/button-slim.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@Component({
  selector: 'lv-input-with-save-btn',
  templateUrl: './lv-input-with-save-btn.component.html',
  styleUrls: ['./lv-input-with-save-btn.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    QaTagsDirective,
    TranslateModule,
    NativeElementInjectorDirective,
    SvgIconComponent,
    SlimButtonComponent,
    NgClickOutsideDirective,
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => LvInputWithSaveBtnComponent),
  }],
})
export class LvInputWithSaveBtnComponent implements ControlValueAccessor, OnInit {
  @ViewChild('input') inputRef: ElementRef;

  @Input() type: 'text' | 'password' | 'email' = 'text';
  @Input() placeholder: string = '';
  @Input() formControlName: string = '';
  @Output() change = new EventEmitter<string>();
  @Output() onSaveValue = new EventEmitter<string>();

  // inject
  languageService: LanguageService = inject(LanguageService);
  private controlContainer: ControlContainer = inject(ControlContainer);

  // vars
  value: string;
  initialValue: string;
  inputIsFocused: boolean;
  isDisabled: boolean;
  control: AbstractControl = new FormControl();

  //enums
  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;

  get isRtl(): boolean {
    return this.languageService.isRtl;
  }

  ngOnInit(): void {
    if (this.formControlName && this.controlContainer?.control) {
      this.control = this.controlContainer.control.get(this.formControlName) as AbstractControl;

      if (this.control) {
        this.isDisabled = this.isDisabled || this.control.disabled;

        this.control.statusChanges.subscribe(() => {
          this.isDisabled = this.control.disabled;
        });
      }
    }
  }

  restoreInitValue(): void {
    this.inputIsFocused = false;
    this.value = this.initialValue;
    this.onChange(this.value);
    this.change.emit(this.value);
  }

  focusInput(): void {
    setTimeout(() => {
      this.inputRef.nativeElement.focus();
      this.inputIsFocused = true;
    });
  }

  saveValue(): void {
    if (this.control.invalid) {
      this.focusInput();
      return;
    }

    this.inputIsFocused = false;
    if (this.value === this.initialValue) return;

    this.initialValue = this.value;
    this.onChange(this.value);
    this.onSaveValue.emit(this.value);
  }

  updateInputValue(value): void {
    setTimeout(() => {
      if (!this.inputRef?.nativeElement) return;
      this.inputRef.nativeElement.value = value;
    });
  }

  onModelChange(value: string): void {
    this.value = value;
    this.onChange(value);
    this.change.emit(value);
  }

  writeValue(value: string): void {
    this.initialValue = value;
    this.value = value;
    this.updateInputValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };
}
