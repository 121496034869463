import {
  Component,
  Renderer2,
  Output,
  EventEmitter,
  OnInit, OnDestroy,
  AfterViewChecked,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Input } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';

import { PlatformService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';
import {
  ButtonComponent,
  LoadingGalconComponent,
  LVInputComponent,
  SlimButtonComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { GlobalConstants } from '@livestock/shared/constants';
import { AppRoutes } from '@livestock/shared/routes';
import { setFlashMessage } from '@livestock/notifications';
import { FlashMessageTypeEnum } from '@livestock/notifications/enums';
import { createFarm, setFarmWasCreatedOrDeleted } from '../../+state/farms.actions';
import { selectFarmWasCreatedOrDeleted } from '../../+state/farms.selectors';
import { AbstractFarmFormComponent } from '../abstract-farm-form.component';
import { FarmCreationMode } from '@livestock/farms';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    QaTagsDirective,
    ButtonComponent,
    MemoizeFuncPipe,
    SvgIconComponent,
    LVInputComponent,
    ReactiveFormsModule,
    SlimButtonComponent,
    RouterLink,
    LoadingGalconComponent,
  ],
  selector: 'ls-create-farm',
  templateUrl: './create-farm.component.html',
  styleUrls: ['./create-farm.component.scss'],
})
export class CreateFarmComponent extends AbstractFarmFormComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() creationMode: FarmCreationMode = FarmCreationMode.Default;

  @Output() closeComponent = new EventEmitter();

  //vars
  googleMapsInitiated = false;
  isCancellable: boolean = true;

  constructor(
    public platformService: PlatformService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    // hide map if address is empty and clicked outside map wrapper
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.composedPath().indexOf(this.mapElement?.nativeElement) === -1 && !this.form.value.address.length) {
        this.locationMapIsOpen = false;
      }
    });
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectFarmWasCreatedOrDeleted).pipe(
        filter(res => !!res),
      ).subscribe(() => {
        this.store.dispatch(setFarmWasCreatedOrDeleted({ farmWasCreatedOrDeleted: false }));
        this.goToFarms(true);
        setTimeout(() => {
          this.store.dispatch(setFlashMessage({
            flashMessage: {
              flashType: FlashMessageTypeEnum.Success,
              message: 'FlashMessages.FarmWasSuccessfullyCreated',
            },
          }));
        });
      }),
    );
    this.route.queryParams.subscribe(params => {
      this.isCancellable = params?.['isFirstFarm'] == 'false';
    });
  }

  // workaround - waiting Google Maps script to be initiated
  ngAfterViewChecked(): void {
    if (this.googleMapsInitiated || !google) return;
    this.googleMapsInitiated = true;
    this.setupMap(GlobalConstants.DefaultLatitude, GlobalConstants.DefaultLongitude);
  }

  goToFarms(farmWasCreated: boolean = false): void {
    if (this.creationMode === FarmCreationMode.Scanned) {
      this.closeComponent.emit();
      return;
    }

    const queryParams = farmWasCreated
      ? { farmWasCreated: true }
      : {};

    this.router.navigate([AppRoutes.FARMS], {
      queryParams: { ...queryParams },
    });
  }

  saveFarm(): void {
    this.store.dispatch(createFarm({
      farm: {
        ...this.form.getRawValue(),
      },
      creationMode: this.creationMode,
    }));

    this.closeComponent.emit();
  }
}
