import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorsEnum } from '@livestock/shared/enums';
import { ArrowsConnectEnum } from '../../enums/arrows-connect.enum';

@Component({
  selector: 'lv-arrows-connect',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './arrows-connect.component.html',
  styleUrls: ['./arrows-connect.component.scss'],
})
export class ArrowsConnectComponent {
  @Input() set arrowsType(arrowsType: ArrowsConnectEnum) {
    this._arrowsType = arrowsType;
  }

  ArrowsConnectEnum = ArrowsConnectEnum;

  private _arrowsType: ArrowsConnectEnum = ArrowsConnectEnum.ConnectionInProgress;

  get arrowsType(): ArrowsConnectEnum {
    return this._arrowsType;
  }

  get leftArrowColor(): ColorsEnum {
    switch (this.arrowsType) {
      case ArrowsConnectEnum.ConnectionInProgress: {
        return ColorsEnum.MonoDarkGrey;
      }
      case ArrowsConnectEnum.ConnectionError: {
        return ColorsEnum.MainDarkRed;
      }
      default: {
        return ColorsEnum.MonoDark;
      }
    }
  }

  get rightArrowColor(): ColorsEnum {
    switch (this.arrowsType) {
      case ArrowsConnectEnum.ConnectionInProgress: {
        return ColorsEnum.MainGreen;
      }
      case ArrowsConnectEnum.ConnectionError: {
        return ColorsEnum.MainDarkRed;
      }
      default: {
        return ColorsEnum.MonoDark;
      }
    }
  }
}
