import { createAction, props } from '@ngrx/store';
import { ControllerMenuSectionsEnum } from '../enums/controller-menu-sections.enum';
import { ControllerMenuPagesEnum } from '../enums/controller-menu-pages.enum';

export const toggleMenuIsOpened = createAction(
  '[CONTROLLER MENU] Toggle menu is opened',
  props<{ menuIsOpened: boolean }>(),
);

export const changeMenuSection = createAction(
  '[CONTROLLER MENU] Change menu section',
  props<{ activeMenuSection: ControllerMenuSectionsEnum }>(),
);

export const changeControllerPage = createAction(
  '[CONTROLLER MENU] Change controller page',
  props<{ activePage: ControllerMenuPagesEnum }>(),
);

export const resetMenuItems = createAction(
  '[CONTROLLER MENU] Reset menu items',
);
