export enum IconsFarmsEnum {
  FARM_LOCATION = 'farms/farm-location',
  FARM_NAME = 'farms/farm-name',
  FIELD_EDIT = 'farms/farm-name-edit',
  LOCATION_MARKER = 'farms/location-marker',
  ADD_CONTROLLER = 'farms/add-controller',
  USERS = 'farms/users',
  ADD_USER = 'farms/add-user',
  ADD_USER_DISABLED = 'farms/add-user-disabled',
  USER_INVITATION = 'farms/user-invitation',
  GO_BACK = 'farms/go-back',
  BASKET_IN_CIRCLE = 'farms/basket-in-circle',
  BASKET = 'farms/basket',
  USER_PERMISSIONS ='farms/user-permissions',
  TICK_IN_CIRCLE = 'farms/tick-in-circle',
  RESEND_USER = 'farms/resend-user',
}
