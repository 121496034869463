import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import {
  DualToggleComponent,
  LVInputIntegerWithLabelComponent,
  LvInputTimeComponent,
  SlimButtonComponent, SvgIconComponent,
  ThemeEnum,
} from '@livestock/ui';
import { ButtonTypeEnum, IconsEnum } from '@livestock/shared/enums';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HouseModeEnum, StagingEnum } from '../../enums';
import { FlockConstants } from '../../constants';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectNextAvailableFlockNumber } from '../../+state/flock.selectors';
import { createNewFlock, HoursFormatTypeEnum, selectCurrentControllerHoursFormat } from '@livestock/controllers';

@Component({
  selector: 'lv-new-flock-dialog',
  imports: [CommonModule, TranslateModule, SlimButtonComponent, ReactiveFormsModule, LVInputIntegerWithLabelComponent, DualToggleComponent, LvInputTimeComponent, SvgIconComponent],
  templateUrl: './new-flock-dialog.component.html',
  styleUrls: ['./new-flock-dialog.component.scss'],
  standalone: true,
})
export class NewFlockDialogComponent implements OnInit, OnDestroy {
  private store: Store = inject(Store);

  sub$ = new Subscription();
  hoursFormat$: Observable<HoursFormatTypeEnum> = this.store.select(selectCurrentControllerHoursFormat);

  form = new FormGroup({
    flockNumber: new FormControl<number>({ value: 1, disabled: true }),
    houseMode: new FormControl<HouseModeEnum>(HouseModeEnum.Growing),
    growthDay: new FormControl<number>(-4, [
      Validators.min(FlockConstants.GrowthDayMin),
      Validators.max(FlockConstants.GrowthDayMax),
    ]),
    separateMaleAndFemale: new FormControl<boolean>(false),
    initialNumberOfBirds: new FormControl<number>(0, [
      Validators.min(FlockConstants.InitialNumberOfBirdsMin),
      Validators.max(FlockConstants.InitialNumberOfBirdsMax),
    ]),
    maleInitialNumberOfBirds: new FormControl<number>(0, [
      Validators.min(FlockConstants.MaleInitialNumberOfBirdsMin),
      Validators.max(FlockConstants.MaleInitialNumberOfBirdsMax),
    ]),
    femaleInitialNumberOfBirds: new FormControl<number>(0, [
      Validators.min(FlockConstants.FemaleInitialNumberOfBirdsMin),
      Validators.max(FlockConstants.FemaleInitialNumberOfBirdsMax),
    ]),
    staging: new FormControl<StagingEnum>(StagingEnum.FullHouse),
    growStartDayTime: new FormControl<number>(0),
    targetTemperature: new FormControl<number>(25),
    enableTemperatureAlarm: new FormControl<boolean>(false),
    ventilationLevel: new FormControl<number>(0, [
      Validators.min(FlockConstants.MinVentilation),
      Validators.max(FlockConstants.MaxVentilation),
    ]),
  });
  isConfirmed: boolean;
  maxNumberPerGenderMessageIsShown: boolean;
  maxNumberOfBirdsMessageIsShown: boolean;
  growthDayFocused: boolean;

  ButtonTypeEnum = ButtonTypeEnum;
  FlockConstants = FlockConstants;
  HouseModeEnum = HouseModeEnum;
  ThemeEnum = ThemeEnum;
  StagingEnum = StagingEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;

  constructor(private dialogRef: MatDialogRef<NewFlockDialogComponent>) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectNextAvailableFlockNumber).subscribe(flockNumber => {
        this.form.controls.flockNumber.setValue(flockNumber);
      }),
    );
  }

  showMaxNumberPerGenderMessage(isShowed: boolean): void {
    this.maxNumberPerGenderMessageIsShown = isShowed;
  }

  showMaxNumberOfBirdsMessage(isShowed: boolean): void {
    this.maxNumberOfBirdsMessageIsShown = isShowed;
  }

  startNewFlock(): void {
    this.store.dispatch(createNewFlock({ flockSettings: this.form.getRawValue() }));
    this.closePopup();
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  protected readonly HoursFormatTypeEnum = HoursFormatTypeEnum;
}
