import {
  ControllerLanguageEnum,
  ControllerUnitEnum,
} from '@livestock/controllers/enums';
import { BirdTypeEnum } from '@livestock/flock/models';

export interface IGeneralSettingsViewNoUnits {
  language: ControllerLanguageEnum;
  actualUnits: ControllerUnitEnum;
  birdType: BirdTypeEnum;
  controllerID?: number;
}
