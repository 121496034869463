export enum WeightMethodEnum {
  Predefined = 0,
  OwnCurve = 1,
}

export namespace WeightMethodEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case WeightMethodEnum.Predefined:
        return 'Flock.BirdWeight.WeightMethodEnum.Predefined';
      case WeightMethodEnum.OwnCurve:
        return 'Flock.BirdWeight.WeightMethodEnum.OwnCurve';
      default:
        return value.toString();
    }
    ;
  }
}
