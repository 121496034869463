import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray',
  standalone: true,
})
export class EnumToArrayPipe implements PipeTransform {
  transform(value, filterEnumFn?: (item: number) => boolean, sortEnum?: string[]): any[] {
    const enumArray: number[] = Object.keys(value)
      .filter(key => !isNaN(Number(key)))
      .map(key => +key);

    let filteredArray =  filterEnumFn ? enumArray.filter(filterEnumFn) : enumArray;

    if (sortEnum?.length) {
      filteredArray = filteredArray.sort((a, b) => {
        const keyA = value[a];
        const keyB = value[b];
        return sortEnum.indexOf(keyA) - sortEnum.indexOf(keyB);
      });
    }

    return filteredArray;
  }
}
