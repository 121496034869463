import { Action, createReducer, on } from '@ngrx/store';
import {
  AutoDateTimeTypeEnum,
  ControllerLanguageEnum,
  ControllerUnitEnum,
  HoursFormatTypeEnum,
  NetworkConnectionTypesEnum,
  QuickStartStepsEnum,
} from '@livestock/controllers/enums';
import * as upsertControllerActions from './upsert-controller.actions';
import { UpsertControllerState } from './upsert-controller.state';
import { BirdTypeEnum } from '@livestock/flock/models';
import { GlobalConstants } from '@livestock/shared/constants';
import * as moment from 'moment';
import { TimeUtils } from '@livestock/shared/utils';
import { LengthUnitEnum, StorageItem } from '@livestock/shared/enums';
import { LocalStorageService } from '@livestock/shared/services';
import { reconnectControllerSuccess } from '../current-controller/current-controller.actions';

export const UPSERT_CONTROLLER_FEATURE_KEY = 'upsertController';

const language = +LocalStorageService.getStorageItem(StorageItem.CurrentLanguage) || ControllerLanguageEnum.EngUS;

export const initialState: UpsertControllerState = {
  isLoading: false,
  controllerInfo: null,
  currentStep: QuickStartStepsEnum.GeneralSettings,

  generalSettings: {
    language,
    units: language === ControllerLanguageEnum.EngUS
      ? ControllerUnitEnum.Imperial
      : ControllerUnitEnum.Metric,
    actualUnits: language === ControllerLanguageEnum.EngUS
      ? ControllerUnitEnum.Imperial
      : ControllerUnitEnum.Metric,
    birdType: BirdTypeEnum.Broilers,
    unitsDetails: language === ControllerLanguageEnum.EngUS ? GlobalConstants.DefaultUnitsDetailsImperial : GlobalConstants.DefaultUnitsDetailsMetric,
  },
  networkSettings: {
    wifi: 0,
    wifiName: null,
    wifiPassword: '1',
    wifiAutoConnect: false,
    lan: 0,
    lanIp: '',
    lanMac: '',
    modem: 0,
    modemApn: '',
    modemPassword: '',
    modemUser: '',
    connectionInProgress: false,
    modemSignal: 0,
  },
  networkStatus: {
    connectionSettings: {
      lan: {
        isActive: false,
      },
      wifi: {
        isActive: false,
      },
      modem: {
        isActive: false,
      },
    },
    currentConnection: NetworkConnectionTypesEnum.None,
  },
  dateTimeSettings: {
    timeZoneID: null,
    hoursFormat: language === ControllerLanguageEnum.EngUS
      ? HoursFormatTypeEnum.AmPm
      : HoursFormatTypeEnum.HoursFormat24,
  },
  isControllerAssignedWithFarm: false,
};

const reducer = createReducer(
  initialState,
  on(
    upsertControllerActions.getGeneralSettings,
    upsertControllerActions.getDateTimeSettings,
    upsertControllerActions.createController,
    upsertControllerActions.updateController,
    upsertControllerActions.updateGeneralSettings,
    upsertControllerActions.updateHouseSizesSettings,
    upsertControllerActions.deleteController,
    upsertControllerActions.getTicketQrCodeInfoFromDevice,
    upsertControllerActions.getLanSettings,
    upsertControllerActions.updateLanSettings,
    upsertControllerActions.connectWifi,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    upsertControllerActions.getWifiList,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          wifi: 1,
          wifiLoading: true,
        },
      };
    },
  ),
  on(
    upsertControllerActions.getGeneralSettingsError,
    upsertControllerActions.getDateTimeSettingsError,
    upsertControllerActions.createControllerError,
    upsertControllerActions.updateControllerError,
    upsertControllerActions.updateGeneralSettingsError,
    upsertControllerActions.updateHouseSizesSettingsError,
    upsertControllerActions.deleteControllerError,
    upsertControllerActions.updateControllerSuccess,
    upsertControllerActions.updateGeneralSettingsSuccess,
    upsertControllerActions.updateHouseSizesSettingsSuccess,
    upsertControllerActions.getLanSettingsError,
    upsertControllerActions.updateLanSettingsError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(upsertControllerActions.setGeneralSettings, (state, { generalSettings }) => {
    const customUnit = generalSettings?.unitsDetails?.length;
    let actualUnits: ControllerUnitEnum = state.generalSettings.actualUnits;
    if (generalSettings.units !== state.generalSettings.units) {
      // If units are different from the state, update actualUnits based on the new units
      actualUnits =
        generalSettings.units !== ControllerUnitEnum.Custom ? generalSettings.units :
          generalSettings.unitsDetails.length === LengthUnitEnum.Foot ? ControllerUnitEnum.Imperial : ControllerUnitEnum.Metric;
    } else if (
      // Handle custom units change, (treat customUnit as a number because 2 enums are different but theyre values overlap - [0,1])
      generalSettings.units === ControllerUnitEnum.Custom
      && [LengthUnitEnum.Meter, LengthUnitEnum.Foot].includes(customUnit)
      && (customUnit as number) !== actualUnits
    ) {
      actualUnits = customUnit === LengthUnitEnum.Meter ? ControllerUnitEnum.Metric : ControllerUnitEnum.Imperial;
    }

    const date = state.dateTimeSettings.date;
    const currentLanguage: ControllerLanguageEnum = state.generalSettings.language;
    const day = currentLanguage === ControllerLanguageEnum.EngUS ? date?.slice(3, 5) : date?.slice(0, 2);
    const month = currentLanguage === ControllerLanguageEnum.EngUS ? date?.slice(0, 2) : date?.slice(3, 5);
    const year = date?.slice(6);
    const dateFormatWasChangedToImperial = currentLanguage !== ControllerLanguageEnum.EngUS && generalSettings.language === ControllerLanguageEnum.EngUS;
    const dateFormatWasChangedToMetric = currentLanguage === ControllerLanguageEnum.EngUS && generalSettings.language !== ControllerLanguageEnum.EngUS;
    let newDate = date;
    if (dateFormatWasChangedToImperial) {
      newDate = date ? `${month}.${day}.${year}` : '';
    } else if (dateFormatWasChangedToMetric) {
      newDate = date ? `${day}.${month}.${year}` : '';
    }

    return {
      ...state,
      generalSettings: {
        ...generalSettings,
        actualUnits,
      },
      dateTimeSettings: {
        ...state.dateTimeSettings,
        date: newDate,
        hoursFormat: generalSettings.language === ControllerLanguageEnum.EngUS
          ? HoursFormatTypeEnum.AmPm
          : HoursFormatTypeEnum.HoursFormat24,
      },
      isLoading: false,
    };
  }),
  on(upsertControllerActions.getGeneralSettingsSuccess, (state, { generalSettings }) => {
    return {
      ...state,
      generalSettings: {
        ...generalSettings,
      },
      isLoading: false,
    };
  }),
  on(
    upsertControllerActions.setDateTimeSettings,
    (state, { dateTimeSettings }) => {
      return {
        ...state,
        dateTimeSettings: {
          ...dateTimeSettings,
          manuallyDateTime:
            typeof dateTimeSettings?.manuallyDateTime === 'string' || dateTimeSettings?.manuallyDateTime instanceof String
              ? moment(dateTimeSettings.manuallyDateTime, GlobalConstants.DateFormat)
              : dateTimeSettings.manuallyDateTime,
        },
      };
    },
  ),
  on(
    upsertControllerActions.updateLanSettings,
    (state, { lan }) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          lan,
          connectionInProgress: Boolean(lan),
        },
      };
    },
  ),
  on(
    upsertControllerActions.getLanSettingsSuccess,
    upsertControllerActions.updateLanSettingsSuccess,
    (state, { lanIp, lanMac }) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          lanIp,
          lanMac,
          connectionInProgress: false,
        },
        isLoading: false,
      };
    },
  ),
  on(
    upsertControllerActions.getLanSettingsError,
    upsertControllerActions.updateLanSettingsError,
    (state, _action) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          lanIp: null,
          lanMac: null,
          connectionInProgress: false,
        },
      };
    },
  ),
  on(
    upsertControllerActions.getWifiListSuccess,
    (state, { connections }) => {

      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          wifiConnectionsList: connections,
          wifiLoading: false,
        },
      };
    },
  ),
  on(
    upsertControllerActions.getWifiListError,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          wifiLoading: false,
        },
      };
    },
  ),
  on(
    upsertControllerActions.clearWifiList,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          wifi: 0,
          wifiConnectionsList: [],
          wifiLoading: false,
          wifiIp: null,
          wifiMac: null,
          wifiName: null,
        },
      };
    },
  ),
  on(
    upsertControllerActions.connectWifi,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          connectionInProgress: true,
        },
      };
    },
  ),
  on(
    upsertControllerActions.connectWifiSuccess,
    (state, { wifiConnectionResponse, wifiNetwork, name }) => {
      return {
        ...state,
        isLoading: false,
        networkSettings: {
          ...state.networkSettings,
          wifiIp: wifiConnectionResponse.ip,
          wifiMac: wifiConnectionResponse.mac,
          wifiName: name,
          wifiSignalLevel: wifiNetwork.signal,
          connectionInProgress: false,
        },
      };
    },
  ),
  on(
    upsertControllerActions.connectWifiError,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          wifiIp: null,
          wifiMac: null,
          wifiLoading: false,
          connectionInProgress: false,
        },
        isLoading: false,
      };
    },
  ),
  on(
    upsertControllerActions.disconnectWifi,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          wifiLoading: true,
          wifi: 0,
        },
      };
    },
  ),
  on(
    upsertControllerActions.disconnectWifiSuccess,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          wifiIp: null,
          wifiName: null,
          wifiMac: null,
          wifiLoading: false,
          wifiConnectionsList: [],
        },
      };
    },
  ),
  on(upsertControllerActions.disconnectWifiError,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          wifiLoading: false,
        },
      };
    },
  ),
  on(upsertControllerActions.setModemState,
    (state, action) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          modem: action.state,
        },
      };
    },
  ),
  on(upsertControllerActions.connectModem,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          cellularLoading: true,
          connectionInProgress: true,
          modem: 1,
        },
      };
    },
  ),
  on(upsertControllerActions.connectModemSuccess,
    (state, { modemConnectionResponse }) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          modemIp: modemConnectionResponse.ip,
          modemMac: modemConnectionResponse.mac,
          modemApn: null,
          modemUser: null,
          modemPassword: null,
          cellularLoading: false,
          connectionInProgress: false,
        },
      };
    },
  ),
  on(upsertControllerActions.connectModemError,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          cellularLoading: false,
          modemIp: '',
          modemMac: '',
          connectionInProgress: false,
        },
      };
    },
  ),
  on(upsertControllerActions.disconnectModem,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          cellularLoading: true,
          modem: 0,
          modemApn: null,
          modemUser: null,
          modemPassword: null,
        },
      };
    },
  ),
  on(upsertControllerActions.disconnectModemSuccess,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          cellularLoading: false,
          modemIp: null,
          modemMac: null,
          modemName: null,
          modemApn: null,
          modemUser: null,
          modemPassword: null,
        },
      };
    },
  ),
  on(upsertControllerActions.disconnectModemError,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          cellularLoading: false,
        },
      };
    },
  ),
  on(
    upsertControllerActions.getNetworkStatus,
    (state, { overrideSettingsState }) => {
      if (overrideSettingsState) {
        return {
          ...state,
          isLoading: true,
        };
      }
      return state;
    },
  ),
  on(
    upsertControllerActions.getNetworkStatusError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(
    upsertControllerActions.getNetworkStatusSuccess,
    (state, { networkStatus, overrideSettingsState }) => {
      let newState = {
        ...state,
        networkStatus,
        networkSettings: {
          ...state.networkSettings,
          modemName: networkStatus?.modem?.name,
          modemSignal: networkStatus?.modem?.signal,
        },
      };
      if (overrideSettingsState) {
        const lanActive: boolean = networkStatus?.connectionSettings?.lan?.isActive;
        const wifiActive: boolean = networkStatus?.connectionSettings?.wifi?.isActive;
        const modemActive: boolean = networkStatus?.connectionSettings?.modem?.isActive;
        const modemSignal: number = networkStatus?.modem?.signal;
        newState = {
          ...newState,
          networkSettings: {
            ...state.networkSettings,
            lan: lanActive ? 1 : 0,
            lanIp: lanActive ? networkStatus?.connectionSettings.lan.ip : null,
            lanMac: lanActive ? networkStatus?.connectionSettings.lan.mac : null,
            wifi: wifiActive ? 1 : 0,
            wifiIp: wifiActive ? networkStatus?.connectionSettings.wifi.ip : null,
            wifiMac: wifiActive ? networkStatus?.connectionSettings.wifi.mac : null,
            wifiName: wifiActive ? networkStatus?.wifi?.name : null,
            wifiSignalLevel: wifiActive ? networkStatus?.wifi?.signal : null,
            modem: modemActive ? 1 : 0,
            modemIp: modemActive ? networkStatus?.connectionSettings.modem.ip : null,
            modemSignal: modemActive ? modemSignal : null,
            modemMac: modemActive ? networkStatus?.connectionSettings.modem.mac : null,
            modemName: modemActive ? networkStatus?.modem.name : null,
          },
          isLoading: false,
        };
      }

      return newState;
    },
  ),
  on(upsertControllerActions.setIsControllerAssignedWithFarm, (state, { isControllerAssignedWithFarm }) => {
    return {
      ...state,
      isControllerAssignedWithFarm,
    };
  }),
  on(upsertControllerActions.clearUpsertControllerState, (state) => {
    return {
      ...state,
      ...initialState,
    };
  }),
  on(upsertControllerActions.goToNextStep, (state) => {
    if (state.currentStep === QuickStartStepsEnum.DateTimeSettings) {
      return state;
    }

    return {
      ...state,
      currentStep: state.currentStep + 1,
    };
  }),
  on(upsertControllerActions.goToStep, (state, { payload }) => {
    return {
      ...state,
      currentStep: payload ?? state.currentStep,
    };
  }),
  on(upsertControllerActions.goToPrevStep, (state) => {
    if (state.currentStep === QuickStartStepsEnum.GeneralSettings) {
      return state;
    }

    return {
      ...state,
      currentStep: state.currentStep - 1,
    };
  }),
  on(upsertControllerActions.goToDateTimeSettingsStep, (state) => {
    return {
      ...state,
      currentStep: QuickStartStepsEnum.DateTimeSettings,
    };
  }),
  on(upsertControllerActions.getControllerSerialNumberDeviceSuccess, (state, { serialNumber }) => {
    return {
      ...state,
      deviceControllerSerialNumber: serialNumber,
    };
  }),
  on(upsertControllerActions.setNetworkConnectionInProgress, (state, { connectionInProgress }) => {
    return {
      ...state,
      networkConnectionInProgress: connectionInProgress,
    };
  }),
  on(upsertControllerActions.getTicketQrCodeInfoFromDeviceSuccess, (state, { ticketQrCodeInfo }) => {
    return {
      ...state,
      ticketQrCodeInfo,
      cloudConnectionRequest: {
        isConnected: ticketQrCodeInfo?.cloudConnectionInfo != null,
        email: ticketQrCodeInfo?.cloudConnectionInfo?.userName || null,
        farm: ticketQrCodeInfo?.cloudConnectionInfo?.farmName || null,
        inProgress: false,
      },
      isLoading: false,
    };
  }),
  on(upsertControllerActions.getTicketQrCodeInfoFromDeviceSuccess, (state, { ticketQrCodeInfo }) => {
    return {
      ...state,
      ticketQrCodeInfo,
      ticketQrCodeError: false,
      isLoading: false,
    };
  }),
  on(upsertControllerActions.getTicketQrCodeInfoFromDeviceError, (state) => {
    return {
      ...state,
      ticketQrCodeInfo: null,
      ticketQrCodeError: true,
      isLoading: false,
    };
  }),
  on(reconnectControllerSuccess, (state, { ticketQrCodeInfo }) => {
    return {
      ...state,
      ticketQrCodeInfo,
    };
  }),
  on(upsertControllerActions.setCloudConnectionRequest, (state, { farm, email }) => {
    return {
      ...state,
      cloudConnectionRequest: {
        ...state.cloudConnectionRequest,
        inProgress: true,
        email,
        farm,
      },
    };
  }),
  on(
    upsertControllerActions.approveCloudConnectionRequestSuccess,
    (state) => {
      return {
        ...state,
        cloudConnectionRequest: {
          ...state.cloudConnectionRequest,
          inProgress: false,
          isConnected: true,
          connectedTime: new Date(),
        },
      };
    }),
  on(
    upsertControllerActions.approveCloudConnectionRequestError,
    (state) => {
      return {
        ...state,
        cloudConnectionRequest: {
          inProgress: false,
          email: null,
          farm: null,
          isConnected: false,
        },
      };
    }),
  on(
    upsertControllerActions.disconnectCloud,
    (state) => {
      return {
        ...state,
        isLoading: true,
        cloudConnectionRequest: {
          ...state.cloudConnectionRequest,
          disconnectInProgress: true,
        },
      };
    },
  ),
  on(
    upsertControllerActions.disconnectCloudSuccess,
    (state) => {
      return {
        ...state,
        isLoading: false,
        cloudConnectionRequest: {
          ...state.cloudConnectionRequest,
          disconnectInProgress: false,
        },
        dateTimeSettings: {
          ...state.dateTimeSettings,
          autoDateTimeType: AutoDateTimeTypeEnum.Manually,
        },
      };
    },
  ),
  on(
    upsertControllerActions.disconnectCloudError,
    (state) => {
      return {
        ...state,
        isLoading: false,
        cloudConnectionRequest: {
          ...state.cloudConnectionRequest,
          disconnectInProgress: false,
        },
      };
    },
  ),
  on(
    upsertControllerActions.getDateTimeSettingsSuccess,
    (state, { dateTimeSettings }) => {
      let date = dateTimeSettings.date;
      const currentLanguage = state.generalSettings.language;

      if (currentLanguage !== ControllerLanguageEnum.EngUS) {
        date = TimeUtils.usaDateFormatToUsualDateFormat(dateTimeSettings.date);
      }

      return {
        ...state,
        dateTimeSettings: {
          ...dateTimeSettings,
          date,
        },
        isLoading: false,
      };
    },
  ),
);

export function upsertControllerReducer(state: UpsertControllerState | undefined, action: Action): any {
  return reducer(state, action);
}
