import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { Store } from '@ngrx/store';

import { ButtonComponent, LoadingGalconComponent, SlimButtonComponent, SvgIconComponent } from '@livestock/ui';
import { getFarmById, getFarmUsers } from '../../+state/farms.actions';
import { IFarmInfo } from '../../interfaces/farm-info.interface';
import {
  FarmUserDetailsComponent,
  selectActiveFarm,
  selectActiveFarmUsers,
  selectUserDetailsMode,
  UserDetailsModeEnum,
} from '@livestock/farms';
import { UpdateFarmComponent } from '../update-farm/update-farm.component';
import { FarmUsersListComponent } from '../farm-users-list/farm-users-list.component';
import {
  ButtonTypeEnum,
  CommonUserRolesEnum,
  FarmUserRolesEnum,
  IconsEnum,
  UserStatusesEnum,
} from '@livestock/shared/enums';
import { AppRoutes } from '@livestock/shared/routes';
import { IUser } from '@livestock/shared/interfaces';
import { ICurrentUserView, selectCurrentUser } from '@livestock/current-user';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { StringUtils } from '@livestock/shared/utils';

@Component({
  selector: 'lv-farm-settings',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SlimButtonComponent,
    SvgIconComponent,
    TranslateModule,
    ButtonComponent,
    NgClickOutsideDirective,
    UpdateFarmComponent,
    FarmUsersListComponent,
    RouterLink,
    FarmUserDetailsComponent,
    LoadingGalconComponent,
  ],
  templateUrl: './farm-settings.component.html',
  styleUrls: ['./farm-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FarmSettingsComponent {
  store: Store = inject(Store);
  router: Router = inject(Router);
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  // observables
  farm$: Observable<IFarmInfo>;
  users$: Observable<IUser[]>;
  currentUser$: Observable<ICurrentUserView>;
  currentUserAccessRole$: Observable<FarmUserRolesEnum>;
  isInvitationAvailable$: Observable<boolean>;
  showNewInvitation = false;

  // enums
  ButtonTypeEnum = ButtonTypeEnum;
  IconsEnum = IconsEnum;
  AppRoutes = AppRoutes;
  UserDetailsModeEnum = UserDetailsModeEnum;

  constructor() {
    const farmID: number = +this.activatedRoute.snapshot.params['farmID'];

    this.store.dispatch(getFarmById({ farmID }));
    this.store.dispatch(getFarmUsers({ farmID }));

    this.farm$ = this.store.select(selectActiveFarm);
    this.users$ = this.store.select(selectActiveFarmUsers).pipe(
      map(users => this.sortUsers(users)),
    );
    this.currentUser$ = this.store.select(selectCurrentUser);
    this.currentUserAccessRole$ = this.users$
      .pipe(
        withLatestFrom(this.currentUser$),
        map(([users, currentUser]) =>
          users.find(user => user.userID === currentUser.userID)?.accessesRole,
        ),
      );
    this.isInvitationAvailable$ = this.currentUserAccessRole$.pipe(
      withLatestFrom(this.currentUser$),
      map(([role, currentUser]) =>
        [FarmUserRolesEnum.Owner, FarmUserRolesEnum.Admin].includes(role) || currentUser.role === CommonUserRolesEnum.God,
      ),
    );

    this.store.select(selectUserDetailsMode).pipe(
      wasChanged(),
      takeUntilDestroyed(),
    ).subscribe((userDetailsMode: UserDetailsModeEnum) => {
      this.showNewInvitation = userDetailsMode === UserDetailsModeEnum.NewInvitation;
    });
  }

  private sortUsers(users: IUser[]): IUser[] {
    return [...users]?.sort((userA, userB) => {

      if (userA.status !== UserStatusesEnum.Active && userB.status === UserStatusesEnum.Active) {
        return -1;
      }
      if (userA.status === UserStatusesEnum.Active && userB.status !== UserStatusesEnum.Active) {
        return 1;
      }

      const initialsA = StringUtils.getInitials(userA.fullName).toUpperCase();
      const initialsB = StringUtils.getInitials(userB.fullName).toUpperCase();

      return initialsA.localeCompare(initialsB);
    });
  }

  onDelete(): void {
    this.router.navigate([AppRoutes.FARMS]);
  }
}
