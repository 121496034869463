<div class="container">
  <div class="wrapper">
    <div
      [ngClass]="{
        'mx-auto': !(router.url | memoizeFunc : activatedRouteService.canShowLobby),
        'w-360':
          (router.url | memoizeFunc : activatedRouteService.isFarmCreationPage) ||
          (!(activeFarmID$ | async) && !(router.url | memoizeFunc : activatedRouteService.isFarmSettingsPage)),
        'w-378':
          !(router.url | memoizeFunc : activatedRouteService.isFarmCreationPage) &&
          !(router.url | memoizeFunc : activatedRouteService.isFarmSettingsPage) &&
          (activeFarmID$ | async),
        'flex-grow-1': router.url | memoizeFunc : activatedRouteService.isFarmSettingsPage
      }"
      class="farms-wrapper">
      <!---->
      <!---->
      <!--HEADER WITH LOGO-->
      <div
        *ngIf="!(router.url | memoizeFunc : activatedRouteService.isFarmSettingsPage)"
        class="farms-header">
        <img
          src="/assets/images/titan-medium-logo.svg"
          width="88"
          height="24"
          alt="logo" />
        <div
          [class.mr-18]="
            !(router.url | memoizeFunc : activatedRouteService.isFarmCreationPage) && (activeFarmID$ | async)
          "
          class="profile-block d-flex flex-row align-center">
          <div
            (click)="toggleUserMenu()"
            [class.profile-opened]="userMenuIsActive$ | async"
            class="profile pointer">
            {{ currentUserInitials$ | async }}
            <ls-svg-icon
              [class.rotate-180]="userMenuIsActive$ | async"
              [path]="IconsEnum.ARROW_DOWN_WHITE"
              class="mh-4" />
          </div>
        </div>
      </div>

      <!---->
      <!---->
      <!--FARM-LIST OR POPUP CONTENT (LIKE USER MENU)-->
      <ls-farms-list
        *ngIf="
          !platformService.isDeviceApp && (router.url | memoizeFunc : activatedRouteService.canShowFarmsList);
          else templateWithoutLobby
        "
        [isFarmHomepage]="router.url | memoizeFunc : activatedRouteService.isFarmsHomepage">
      </ls-farms-list>

      <ng-container *ngIf="userMenuIsActive$ | async">
        <div class="current-user__popup">
          <div class="current-user__wrapper">
            <lv-current-user-info (closeUserPopup)="toggleUserMenu()"></lv-current-user-info>
          </div>
        </div>
      </ng-container>
    </div>

    <!---->
    <!---->
    <!--LOBBY WITH ALL ROUTES AND PAGE CONTENT-->
    <ng-container *ngIf="router.url | memoizeFunc : activatedRouteService.canShowLobby">
      <div
        [class.d-none]="
          !(activeFarmID$ | async) && (router.url | memoizeFunc : activatedRouteService.isFarmsEmptyHomepage)
        "
        [class.lobby-wrapper__web]="!platformService.isDeviceApp"
        class="lobby-wrapper">
        <ls-top-menu
          *ngIf="!(router.url | memoizeFunc : activatedRouteService.showTransparentLobby)"
          [isOnline]="isOnline$ | async" />
        <div
          [class.lobby__transparent]="router.url | memoizeFunc : activatedRouteService.showTransparentLobby"
          class="lobby">
          <router-outlet></router-outlet>
        </div>
      </div>
    </ng-container>

    <ng-template #templateWithoutLobby>
      <router-outlet></router-outlet>
    </ng-template>
  </div>
</div>
