import { Component, EventEmitter, forwardRef, Output, ViewChild, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';
import { GlobalConstants } from '@livestock/shared/constants';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        QaTagsDirective,
    ],
    selector: 'lv-verification-code',
    templateUrl: 'verification-code.component.html',
    styleUrls: ['verification-code.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VerificationCodeComponent),
            multi: true,
        },
    ],
})

export class VerificationCodeComponent implements ControlValueAccessor {
    @ViewChild('input1') input1Ref: ElementRef;
    @ViewChild('input2') input2Ref: ElementRef;
    @ViewChild('input3') input3Ref: ElementRef;
    @ViewChild('input4') input4Ref: ElementRef;
    @Input() focusedByDefault = true;
    @Input() submitFormOnLastDigitChange = false;
    @Output() change = new EventEmitter<string>;
    @Output() submitForm = new EventEmitter<void>;
    value: string;

    @HostListener('paste', ['$event'])
    paste(event: any): void {
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text');
        this.input1Ref.nativeElement.value = pastedText.charAt(0);
        this.input2Ref.nativeElement.value = pastedText.charAt(1);
        this.input3Ref.nativeElement.value = pastedText.charAt(2);
        this.input4Ref.nativeElement.value = pastedText.charAt(3);

        this.value = this.generateValue();
        this.valueChange(this.value);
    }

    sendCode(): void {
        if (this.value.length === GlobalConstants.VERIFICATION_CODE_LENGTH) {
            this.submitForm.emit();
        }
    }

    setCode(event: any, step: number): void {
        this.value = this.generateValue();
        this.valueChange(this.value);
        if (event.data != null && step < 4) {
            const nextElement = this[`input${step + 1}Ref`].nativeElement;
            nextElement.focus();
        }

        if (this.submitFormOnLastDigitChange) {
            this.sendCode();
        }
    }

    generateValue(): string {
        return this.input1Ref.nativeElement.value.concat(
            this.input2Ref.nativeElement.value,
            this.input3Ref.nativeElement.value,
            this.input4Ref.nativeElement.value,
        );
    }

    valueChange(value?): void {
        this.value = value;
        this.onChange(this.value);
    }

    writeValue(value: string): void {
        this.value = value;
        if (!value && !!this.input1Ref) {
            this.input1Ref.nativeElement.value = '';
            this.input2Ref.nativeElement.value = '';
            this.input3Ref.nativeElement.value = '';
            this.input4Ref.nativeElement.value = '';

            if (this.focusedByDefault) {
                this.input1Ref.nativeElement.focus();
            }
        }

        if (this.value) {
            this.input1Ref.nativeElement.value = this.value.charAt(0);
            this.input2Ref.nativeElement.value = this.value.charAt(1);
            this.input3Ref.nativeElement.value = this.value.charAt(2);
            this.input4Ref.nativeElement.value = this.value.charAt(3);

            if (this.submitFormOnLastDigitChange) {
                this.sendCode();
            }
        }
    }

    registerOnChange(
        onChange: (value: string) => void,
    ): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    private onTouched = (): void => {
    };
    private onChange: (
        value: string,
    ) => void = () => {
    };
}

