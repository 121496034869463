<div
  [class.rtl]="languageService.isRtl"
  [ngClass]="'toggle-size-' + size"
  class="toggle-switch">
  <label
    *ngIf="type === ToggleType.Slide"
    (click)="toggleChecked()"
    [ngClass]="{ checked: isChecked, disabled: disabled }">
    <span>
      {{ (isChecked ? onTitle : offTitle) | translate }}
    </span>
  </label>
  <mat-button-toggle-group
    *ngIf="type === ToggleType.Buttons"
    [value]="isChecked">
    <mat-button-toggle
      (change)="toggleChecked(false)"
      [ngClass]="{ 'disabled-state': !isChecked }"
      [value]="false"
      disableRipple
      >{{ offTitle | translate }}</mat-button-toggle
    >
    <mat-button-toggle
      (change)="toggleChecked(true)"
      [value]="true"
      disableRipple
      >{{ onTitle | translate }}</mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
