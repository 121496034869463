import { ControllerRoutes } from '../../../../controllers/src/lib/constants/controller.routes';
import { ControllerMenuSectionsEnum } from './controller-menu-sections.enum';

export enum ControllerMenuPagesEnum {
  UnitsSettings,
  HouseSettings,
  NetworkSettings,
  CloudSettings,
  DateTimeSettings,
  HouseModeSettings,
  Installation,
  Temperature,
  Ventilation,
  StaticPressure,
  Cooling,
  Heating,
  AirTreatment,
  WaterFeed,
  Light,
  TimersAux,
  FlockSettings,
  GrowthTable,
  GrowthChart,
  BirdManagement,
  SiloManagement,
  AlarmSettings,
  GeneralLogs,
  DataLogs,
  Reports,
  Users,
  Hardware,
  TempMapping,
  SaveRestore,
  About,
}

export namespace ControllerMenuPagesEnum {
  export function enumToStringRoute(value: ControllerMenuPagesEnum): string {
    switch (value) {
      case ControllerMenuPagesEnum.UnitsSettings:
        return ControllerRoutes.UnitsSettings;
      case ControllerMenuPagesEnum.HouseSettings:
        return ControllerRoutes.HouseSettings;
      case ControllerMenuPagesEnum.NetworkSettings:
        return ControllerRoutes.NetworkSettings;
      case ControllerMenuPagesEnum.CloudSettings:
        return ControllerRoutes.CloudSettings;
      case ControllerMenuPagesEnum.DateTimeSettings:
        return ControllerRoutes.DateTimeSettings;
      case ControllerMenuPagesEnum.FlockSettings:
        return ControllerRoutes.FlockSettings;
      case ControllerMenuPagesEnum.HouseModeSettings:
        return ControllerRoutes.HouseModeSettings;
      case ControllerMenuPagesEnum.Installation:
        return ControllerRoutes.Installation;
      default:
        return value as unknown as string;
    }
  }

  export function routeToEnum(value: string): ControllerMenuPagesEnum {
    switch (value) {
      case ControllerRoutes.UnitsSettings:
        return ControllerMenuPagesEnum.UnitsSettings;
      case ControllerRoutes.HouseSettings:
        return ControllerMenuPagesEnum.HouseSettings;
      case ControllerRoutes.NetworkSettings:
        return ControllerMenuPagesEnum.NetworkSettings;
      case ControllerRoutes.CloudSettings:
        return ControllerMenuPagesEnum.CloudSettings;
      case ControllerRoutes.DateTimeSettings:
        return ControllerMenuPagesEnum.DateTimeSettings;
      case ControllerRoutes.FlockSettings:
        return ControllerMenuPagesEnum.FlockSettings;
      case ControllerRoutes.HouseModeSettings:
        return ControllerMenuPagesEnum.HouseModeSettings;
      case ControllerRoutes.Installation:
        return ControllerMenuPagesEnum.Installation;
      default:
        return null;
    }
  }

  export function getSectionOfRoute(value: string): ControllerMenuSectionsEnum {
    switch (value) {
      case ControllerRoutes.UnitsSettings:
      case ControllerRoutes.HouseSettings:
      case ControllerRoutes.NetworkSettings:
      case ControllerRoutes.CloudSettings:
      case ControllerRoutes.DateTimeSettings:
      case ControllerRoutes.FlockSettings:
      case ControllerRoutes.HouseModeSettings:
      case ControllerRoutes.Installation:
        return ControllerMenuSectionsEnum.BasicSettings;
      default:
        return null;
    }
  }
}
