<div
  [class.rtl]="languageService.isRtl"
  [formGroup]="form"
  class="house w-mc position-relative">
  <div class="house-number mb-0 mb-15-md">
    <lv-input-integer-with-label
      (onFocusIn)="houseNumberRangesAreVisible = true"
      (onFocusOut)="houseNumberRangesAreVisible = false"
      [class.disabled]="isDisabled || platformService.isDeviceApp"
      [forceDisabled]="isDisabled || platformService.isDeviceApp"
      [textColor]="ColorsEnum.MainDarkBlue"
      [min]="HouseNumberMin"
      [max]="HouseNumberMax"
      [fontWeight]="500"
      [disableAnimation]="true"
      [noWrapperPadding]="true"
      [inputClassList]="platformService.isMobileVersion ? '' : 'w-68-forced'"
      [label]="'Controller.QuickStart.HouseNumber'"
      [qaTags]="'qa-input-houseNumber'"
      formControlName="houseNumber"></lv-input-integer-with-label>

    <div
      *ngIf="!platformService.isDeviceApp"
      class="fz-12 d-flex flex-column position-absolute house-number__info">
      <p
        *ngIf="!form.controls.houseNumber.errors?.['valueIsAlreadyTaken'] && (houseNumberRangesAreVisible || form.controls.houseNumber.invalid)"
        [class.color-error]="form.controls.houseNumber.invalid">
        {{ "Inputs.AddNumberBetween" | translate }}
        <span class="fw-600">{{ HouseNumberMin }}-{{ HouseNumberMax }}</span>
      </p>

      <p
        *ngIf="form.controls.houseNumber.errors?.['valueIsAlreadyTaken']"
        [class.color-error]="form.controls.houseNumber.invalid">
        {{ "Controller.HouseSizesSettings.ThisNumberIsAlreadyTakenNextAvailableIs" | translate }}
        <span class="fw-600">{{ nextAvailableHouseNumber$ | async }} </span>
      </p>
    </div>
  </div>

  <ng-container *ngIf="!platformService.isMobileVersion; else mobileTemplate">
    <div class="house-icons position-relative">
      <ls-svg-icon [path]="IconsEnum.HOUSE_DEVICE"></ls-svg-icon>

      <!--height-roof-->
      <div class="arrows-height-roof position-absolute">
        <ls-svg-icon
          [path]="IconsEnum.ARROW_TOP"
          [color]="ColorsEnum.Black"
          class="arrows-height-roof__top"></ls-svg-icon>

        <div class="position-absolute height-roof-input d-flex flex-column">
          <div class="d-flex align-center justify-start">
            <ls-input-decimal
              [class.disabled]="isDisabled"
              [fontWeight]="500"
              [accuracy]="accuracy"
              [blueBorder]="true"
              [max]="maxWidthHeightValue"
              [min]="minValue"
              [inputWidthPx]="inputWidth"
              [qaTags]="'qa-input-houseRoofHeight'"
              [class.hide-error]="!form.controls.houseRoofHeight.errors?.['min'] && !form.controls.houseRoofHeight.errors?.['max']"
              class="mr-3 mr-1"
              formControlName="houseRoofHeight">
              <ng-container description>
                <div class="fz-12 fw-600 w-mc show-on-focus">
                  <ng-container
                    [ngTemplateOutlet]="ranges"
                    [ngTemplateOutletContext]="{
                        maxValue: maxWidthHeightValue,
                        hasError: form.controls.houseRoofHeight.errors?.['min'] || form.controls.houseRoofHeight.errors?.['max']
                     }">
                  </ng-container>

                  <ls-svg-icon
                    [color]="ColorsEnum.Black"
                    [path]="IconsEnum.ARROW_BOTTOM_SMALL"
                    class="arrows-height-roof__bottom arrows-height-roof__bottom-validation"></ls-svg-icon>
                </div>

                <div class="hide-on-focus">
                  <ls-svg-icon
                    [path]="IconsEnum.ARROW_BOTTOM"
                    class="arrows-height-roof__bottom"></ls-svg-icon>
                </div>
              </ng-container>
            </ls-input-decimal>
            <ng-container
              *ngTemplateOutlet="unit; context: { isValid: form.controls.houseRoofHeight.valid }"></ng-container>
          </div>
        </div>
      </div>

      <!--height-->
      <div class="arrows-height position-absolute">
        <ls-svg-icon
          [color]="ColorsEnum.Black"
          [path]="IconsEnum.ARROW_TOP_SMALL"
          class="arrows-height__top"></ls-svg-icon>

        <div class="position-absolute height-input d-flex flex-column">
          <div class="d-flex align-center justify-start">
            <ls-input-decimal
              [class.disabled]="isDisabled"
              [inputWidthPx]="inputWidth"
              [fontWeight]="500"
              [accuracy]="accuracy"
              [blueBorder]="true"
              [max]="maxWidthHeightValue"
              [min]="minValue"
              [qaTags]="'qa-input-houseHeight'"
              [class.hide-error]="!form.controls.houseHeight.errors?.['min'] && !form.controls.houseHeight.errors?.['max']"
              class="mr-3 mr-1"
              formControlName="houseHeight">
              <ng-container description>
                <div class="fz-12 fw-600 w-mc show-on-focus">
                  <ng-container
                    [ngTemplateOutlet]="ranges"
                    [ngTemplateOutletContext]="{
                    maxValue: maxWidthHeightValue,
                    hasError: form.controls.houseHeight.errors?.['min'] || form.controls.houseHeight.errors?.['max']
                     }">
                  </ng-container>

                  <ls-svg-icon
                    [color]="ColorsEnum.Black"
                    [path]="IconsEnum.ARROW_BOTTOM_SMALL"
                    class="arrows-height__bottom arrows-height__bottom-validation"></ls-svg-icon>
                </div>

                <div class="hide-on-focus">
                  <ls-svg-icon
                    [path]="IconsEnum.ARROW_BOTTOM"
                    class="arrows-height__bottom"></ls-svg-icon>
                </div>
              </ng-container>
            </ls-input-decimal>
            <ng-container
              *ngTemplateOutlet="unit; context: { isValid: form.controls.houseHeight.valid }"></ng-container>
          </div>
        </div>
      </div>

      <!--width-->
      <div class="d-flex align-center mt-15 position-relative">
        <ls-svg-icon
          [color]="ColorsEnum.Black"
          [path]="IconsEnum.ARROW_LEFT"></ls-svg-icon>

        <div class="width-input d-flex flex-column mh-2">
          <div class="d-flex align-center justify-start">
            <ls-input-decimal
              [class.disabled]="isDisabled"
              [inputWidthPx]="inputWidth"
              [fontWeight]="500"
              [accuracy]="accuracy"
              [blueBorder]="true"
              [fieldWithKeyboard]="true"
              [max]="maxWidthHeightValue"
              [min]="minValue"
              [qaTags]="'qa-txt-houseWidth'"
              [class.hide-error]="!form.controls.houseWidth.errors?.['min'] && !form.controls.houseWidth.errors?.['max']"
              class="mr-3 mr-1"
              formControlName="houseWidth">
              <ng-container description>
                <div class="fz-12 fw-600 w-mc show-on-focus">
                  <ng-container
                    [ngTemplateOutlet]="ranges"
                    [ngTemplateOutletContext]="{
                    maxValue: maxWidthHeightValue,
                    hasError: form.controls.houseWidth.errors?.['min'] || form.controls.houseWidth.errors?.['max']
                     }">
                  </ng-container>
                </div>
              </ng-container>
            </ls-input-decimal>
            <ng-container *ngTemplateOutlet="unit; context: { isValid: form.controls.houseWidth.valid }"></ng-container>
          </div>
        </div>

        <ls-svg-icon
          [color]="ColorsEnum.Black"
          [path]="IconsEnum.ARROW_RIGHT"></ls-svg-icon>
      </div>

      <!--length-->
      <div class="arrows-length position-absolute">
        <ls-svg-icon
          [color]="ColorsEnum.Black"
          [path]="IconsEnum.ARROW_RIGHT_TOP"
          class="arrows-length__top"></ls-svg-icon>
        <ls-svg-icon
          [color]="ColorsEnum.Black"
          [path]="IconsEnum.ARROW_LEFT_BOTTOM"
          class="arrows-length__bottom"></ls-svg-icon>

        <div class="position-absolute length-input d-flex flex-column">
          <div class="d-flex align-center justify-start">
            <ls-input-decimal
              [class.disabled]="isDisabled"
              [inputWidthPx]="inputWidthForHouseLength"
              [fontWeight]="500"
              [accuracy]="accuracy"
              [blueBorder]="true"
              [max]="maxLengthValue"
              [min]="minValue"
              [qaTags]="'qa-txt-houseLength'"
              [class.hide-error]="!form.controls.houseLength.errors?.['min'] && !form.controls.houseLength.errors?.['max']"
              class="mr-3 mr-1"
              formControlName="houseLength">
              <ng-container description>
                <div class="fz-12 fw-600 w-mc show-on-focus">
                  <ng-container
                    [ngTemplateOutlet]="ranges"
                    [ngTemplateOutletContext]="{
                     maxValue: maxLengthValue,
                     hasError: form.controls.houseLength.errors?.['min'] || form.controls.houseLength.errors?.['max']
                     }">
                  </ng-container>
                </div>
              </ng-container>
            </ls-input-decimal>
            <ng-container
              *ngTemplateOutlet="unit; context: { isValid: form.controls.houseLength.valid }"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div
  [class.bottom-error__active]="!platformService.isMobileVersion && (isKeyboardActive$ | async)"
  class="bottom-error d-flex flex-column align-center fw-500 color-red fz-14-md">
  <span *ngIf="form.controls.houseLength.errors?.['gt'] || form.controls.houseWidth.errors?.['lt']">
    {{ "Controller.QuickStart.LengthCanNotBeLowerThanTheWidth" | translate }}
  </span>

  <span *ngIf="form.controls.houseRoofHeight.errors?.['gt'] || form.controls.houseHeight.errors?.['lt']">
    {{ "Controller.QuickStart.RoofHeightCanNotBeLowerThanTheWallHeight" | translate }}
  </span>
</div>

<ng-template #mobileTemplate>
  <div
    [formGroup]="form"
    class="form">
    <div class="d-flex justify-between mb-20">
      <div>
        <lv-input-decimal-with-label
          (onFocusIn)="houseHeightInFocus = true"
          (onFocusOut)="houseHeightInFocus = false"
          [fontWeight]="500"
          [max]="maxWidthHeightValue"
          [min]="minValue"
          [accuracy]="accuracy"
          [label]="'Controller.QuickStart.WallHeightWH'"
          [class.disabled]="isDisabled"
          [qaTags]="'qa-input-houseHeight'"
          class="w-input-100"
          formControlName="houseHeight">
          <ng-container
            *ngTemplateOutlet="unit; context: { isValid: form.controls.houseLength.valid }"
            suffix></ng-container>
        </lv-input-decimal-with-label>

        <ng-container
          *ngIf="houseHeightInFocus || form.controls.houseHeight.errors?.['min'] || form.controls.houseHeight.errors?.['max']"
          [ngTemplateOutlet]="ranges"
          [ngTemplateOutletContext]="{
              maxValue: maxWidthHeightValue,
              hasError: form.controls.houseHeight.errors?.['min'] || form.controls.houseHeight.errors?.['max']
           }">
        </ng-container>
      </div>

      <div>
        <lv-input-decimal-with-label
          (onFocusIn)="houseRoofHeightInFocus = true"
          (onFocusOut)="houseRoofHeightInFocus = false"
          [fontWeight]="500"
          [max]="maxWidthHeightValue"
          [min]="minValue"
          [accuracy]="accuracy"
          [label]="'Controller.QuickStart.RoofHeightRH'"
          [class.disabled]="isDisabled"
          [qaTags]="'qa-input-houseRoofHeight'"
          class="w-input-100"
          formControlName="houseRoofHeight">
          <ng-container
            *ngTemplateOutlet="unit; context: { isValid: form.controls.houseRoofHeight.valid }"
            suffix></ng-container>
        </lv-input-decimal-with-label>

        <ng-container
          *ngIf="houseRoofHeightInFocus || form.controls.houseRoofHeight.errors?.['min'] || form.controls.houseRoofHeight.errors?.['max']"
          [ngTemplateOutlet]="ranges"
          [ngTemplateOutletContext]="{
              maxValue: maxWidthHeightValue,
              hasError: form.controls.houseRoofHeight.errors?.['min'] || form.controls.houseRoofHeight.errors?.['max']
           }">
        </ng-container>
      </div>
    </div>

    <div class="d-flex justify-between mb-18">
      <div>
        <lv-input-decimal-with-label
          (onFocusIn)="houseWidthInFocus = true"
          (onFocusOut)="houseWidthInFocus = false"
          [fontWeight]="500"
          [accuracy]="accuracy"
          [max]="maxWidthHeightValue"
          [min]="minValue"
          [label]="'Controller.QuickStart.WidthW'"
          [class.disabled]="isDisabled"
          class="w-input-100"
          formControlName="houseWidth">
          <ng-container
            *ngTemplateOutlet="unit; context: { isValid: form.controls.houseWidth.valid }"
            suffix></ng-container>
        </lv-input-decimal-with-label>

        <ng-container
          *ngIf="houseWidthInFocus || form.controls.houseWidth.errors?.['min'] || form.controls.houseWidth.errors?.['max']"
          [ngTemplateOutlet]="ranges"
          [ngTemplateOutletContext]="{
              maxValue: maxWidthHeightValue,
              hasError: form.controls.houseWidth.errors?.['min'] || form.controls.houseWidth.errors?.['max']
           }">
        </ng-container>
      </div>

      <div class="">
        <lv-input-decimal-with-label
          (onFocusIn)="houseLengthInFocus = true"
          (onFocusOut)="houseLengthInFocus = false"
          [fontWeight]="500"
          [max]="maxLengthValue"
          [min]="minValue"
          [accuracy]="accuracy"
          [label]="'Controller.QuickStart.LengthL'"
          [class.disabled]="isDisabled"
          class="w-input-100"
          formControlName="houseLength">
          <ng-container
            *ngTemplateOutlet="unit; context: { isValid: form.controls.houseLength.valid }"
            suffix></ng-container>
        </lv-input-decimal-with-label>

        <ng-container
          *ngIf="houseLengthInFocus || form.controls.houseLength.errors?.['min'] || form.controls.houseLength.errors?.['max']"
          [ngTemplateOutlet]="ranges"
          [ngTemplateOutletContext]="{
              maxValue: maxLengthValue,
              hasError: form.controls.houseLength.errors?.['min'] || form.controls.houseLength.errors?.['max']
           }">
        </ng-container>
      </div>
    </div>
  </div>

  <img
    src="/assets/icons/house/house-mobile.png"
    alt="house" />
</ng-template>

<ng-template
  #unit
  let-isValid="isValid">
  <span
    [class.color-error]="!platformService.isMobileVersion && !isValid"
    class="fw-500">
    {{
      (lengthUnit === LengthUnitEnum.Meter ? "Controller.HouseSizesSettings.m" : "Controller.HouseSizesSettings.ft")
        | translate
    }}
  </span>
</ng-template>

<ng-template
  #ranges
  let-hasError="hasError"
  let-maxValue="maxValue">
  <div
    [ngClass]="hasError ? 'color-error' : 'text-mono-dark'"
    class="fz-10-md fz-12 mt-1 fw-600 mt-4-md">
    <span class="fw-400">{{ "Inputs.Range" | translate }}</span> {{ minValue.toFixed(accuracy) }} -
    {{ maxValue.toFixed(accuracy) }}
  </div>
</ng-template>
