import { IEditUnitModeObj } from '../../interfaces';

export enum VolumeUnitEnum {
  Litre,
  Gallon,
}

export namespace VolumeUnitEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case VolumeUnitEnum.Litre:
        return 'Controller.GeneralSettings.CustomUnitsDialog.VolumeEnum.Litre';
      case VolumeUnitEnum.Gallon:
        return 'Controller.GeneralSettings.CustomUnitsDialog.VolumeEnum.Gallon';
      default:
        return value.toString();
    }
  }

  export function isMetricUnit(value: VolumeUnitEnum): boolean {
    return value === VolumeUnitEnum.Litre;
  }

  export function toEditUnitModeObj(): IEditUnitModeObj {
    return {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.Volume',
      controlName: 'volume',
      enumName: VolumeUnitEnum,
      enumStr: 'volume-unit-enum',
    };
  }
}
