<lv-settings-wrapper
  (toggleDisabled)="toggleDisabled($event)"
  (back)="goBack()"
  [page]="PageTypeEnum.Installation"
  [isEditMode]="isEditMode"
  section="{{ 'ControllerMenu.Sections.BasicSettings' | translate }}"
  title="{{ 'Controller.QuickStart.Installation' | translate }}"
  [isValid]="true">
  <div
    *ngIf="!(cardsAreLoading$ | async); else loading"
    class="h-100-percent d-flex gap-18 installation-container">
    <div
      *ngIf="!settingsMode"
      class="elements">
      <ng-container *ngFor="let elementGroup of InstallationElementGroupsEnum | enumToArray">
        <div
          *ngIf="selectedElementGroup == null || selectedElementGroup === elementGroup"
          (click)="selectElementGroup(elementGroup)"
          [class.element-group__active]="selectedElementGroup === elementGroup"
          class="element-group d-flex align-center justify-between p-18 mb-8 pointer">
          <span class="fw-600">
            {{ elementGroup | enum : "installation-elements-list" | translate }}
          </span>

          <ls-svg-icon
            [path]="IconsEnum.ARROW_SELECT"
            [class.rotate-180]="selectedElementGroup === elementGroup"></ls-svg-icon>
        </div>
      </ng-container>

      <lv-installation-elements-list
        *ngIf="selectedElementGroup != null"
        (setActiveElement)="setActiveElement($event)"
        [elements]="(getElements$ | async)(selectedElementGroup)"
        [selectedElementGroup]="selectedElementGroup"></lv-installation-elements-list>
    </div>

    <div
      *ngIf="settingsMode"
      class="settings">
      <lv-element-wrapper
        *ngIf="(isAnyElementConnected$ | async)(activeElementType); else noConnectedElements"
        (unsetEditMode)="isEditMode = false"
        (isChangedEvent)="isFormChanged($event)"
        [isEditMode]="isEditMode"
        [currentElementType]="activeElementType">
      </lv-element-wrapper>
    </div>
  </div>

  <ng-template #loading>
    <div class="h-100-percent w-100-percent d-flex align-center justify-center">
      <lv-loading-galcon></lv-loading-galcon>
    </div>
  </ng-template>

  <ng-template #noConnectedElements>
    <div class="mt-18">
      {{ "Installation.NoConnectedElements" | translate }}
    </div>
  </ng-template>
</lv-settings-wrapper>
