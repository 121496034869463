import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { NativeElementInjectorDirective } from '../native-element.directive';
import { ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { ControllerLanguageEnum } from '@livestock/controllers';
import { QaTagsDirective } from '@livestock/shared/directives';
import { SvgIconComponent } from '@livestock/ui';
import { IRadioListItem } from './radio-list-item.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    QaTagsDirective,
    NativeElementInjectorDirective,
    SvgIconComponent,
    MatRadioModule,
    TranslateModule,
  ],
  selector: 'lv-radio-list',
  templateUrl: 'radio-list.component.html',
  styleUrls: ['radio-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioListComponent),
      multi: true,
    },
  ],
})

export class RadioListComponent implements ControlValueAccessor {
  @Input() value: any;
  @Input() list: IRadioListItem[] = [];
  @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;
  ControllerLanguageEnum = ControllerLanguageEnum;

  writeValue(value: any): void {
    if (JSON.stringify(value) !== JSON.stringify(this.value)) {
      this.value = value;
      this.change.emit(value);
      this.onChange(value);
    }
  }

  registerOnChange(
    onChange: (value: number | string) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  private onTouched = (): void => {
  };
  private onChange: (
    value: number | string,
  ) => void = () => {
  };
}

