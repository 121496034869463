import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DISPLAY_FEATURE_KEY } from './display.reducer';
import { DisplayState } from './display.state';

export const selectFeatDisplay =
  createFeatureSelector<DisplayState>(DISPLAY_FEATURE_KEY);

export const selectNewVersionAvailable = createSelector(
  selectFeatDisplay,
  (state) => state.newVersionAvailable,
);

export const selectNewFirmwareAvailable = createSelector(
  selectFeatDisplay,
  (state) => state.newFirmwareAvailable,
);

export const selectReloadRequired = createSelector(
  selectFeatDisplay,
  (state) => state.reloadRequired,
);

export const selectReadyToInstall = createSelector(
  selectFeatDisplay,
  (state) => state.readyToInstall,
);

export const selectReadyToFirmware = createSelector(
  selectFeatDisplay,
  (state) => state.readyToFirmware,
);

export const selectBackendConnection = createSelector(
  selectFeatDisplay,
  (state) => state.backendConnection,
);

export const selectIsNetworkManagerConnected = createSelector(
  selectFeatDisplay,
  (state) => state.backendConnection?.isNetworkManagerConnected,
);

export const selectControllerVersion = createSelector(
  selectFeatDisplay,
  (state) => state.controllerVersion,
);

export const selectNotificationMessage = createSelector(
  selectFeatDisplay,
  (state) => state.notificationMessage,
);
