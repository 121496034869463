export enum UserStatusesEnum {
  Active,
  Pending,
  Expired,
  Declined,
}

export namespace UserStatusesEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case UserStatusesEnum.Active:
        return 'Farms.Users.UserStatusesEnum.Active';
      case UserStatusesEnum.Pending:
        return 'Farms.Users.UserStatusesEnum.Pending';
      case UserStatusesEnum.Expired:
        return 'Farms.Users.UserStatusesEnum.Expired';
      case UserStatusesEnum.Declined:
        return 'Farms.Users.UserStatusesEnum.Declined';
      default:
        return value.toString();
    };
  }
}
