export enum BirdTypeEnum {
  Broilers,
  BreedersR,
  BreedersP,
  AllInAll,
  Commercial,
}

export namespace BirdTypeEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case BirdTypeEnum.Broilers:
        return 'Controller.GeneralSettings.BirdsTypeDialog.BirdTypeEnum.Broilers';
      case BirdTypeEnum.BreedersR:
        return 'Controller.GeneralSettings.BirdsTypeDialog.BirdTypeEnum.BreedersRearingPullets';
      case BirdTypeEnum.BreedersP:
        return 'Controller.GeneralSettings.BirdsTypeDialog.BirdTypeEnum.BroilersProduction';
      case BirdTypeEnum.AllInAll:
        return 'Controller.GeneralSettings.BirdsTypeDialog.BirdTypeEnum.AllInAllOutBreeders';
      case BirdTypeEnum.Commercial:
        return 'Controller.GeneralSettings.BirdsTypeDialog.BirdTypeEnum.CommercialLayers';
      default:
        return value.toString();
    }
  }
}
