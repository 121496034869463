<div
  [ngClass]="{ ios: platformService.isNativeIos, 'map-open': locationMapIsOpen || isMapAlwaysOpen }"
  class="wrapper d-flex flex-column justify-between">
  <div
    *ngIf="showLoading"
    class="m-auto pseudo-loading">
    <lv-loading-galcon></lv-loading-galcon>
  </div>

  <div class="content h-100-percent d-flex flex-column">
    <form
      [formGroup]="form"
      class="d-flex flex-column">
      <div class="position-relative mb-18">
        <label
          for="name"
          class="fz-12 fw-500 ph-8">
          {{ "Farms.FarmName" | translate }}
        </label>
        <div
          (focusin)="onFocusInFarmNameInput()"
          (clickOutside)="onCancelNameEdit()"
          [class.editable]="fieldNameInputInFocus"
          [class.disabled]="form.disabled"
          class="field d-flex justify-between"
          tabindex="0">
          <input
            #nameInput
            [class.color-darkblue]="!fieldNameInputInFocus"
            id="name"
            class="field-name"
            type="text"
            maxlength="50"
            formControlName="name" />
        </div>
      </div>

      <div
        (focusin)="onFocusInFarmLocationInput()"
        (clickOutside)="onCancelAddressEdit()"
        class="map position-relative"
        tabindex="0">
        <label
          for="address"
          class="fz-12 fw-500 ph-8"
          >{{ "Farms.FarmLocation" | translate }}</label
        >
        <div
          [ngClass]="{
          'field-disabled': fieldNameInputInFocus,
          'field-map-focused': fieldAddressInputInFocus,
          'disabled': form.disabled,
        }"
          [class.editable]="fieldAddressInputInFocus"
          class="field field-map justify-between">
          <input
            #addressInput
            [class.disabled]="form.disabled"
            [class.color-darkblue]="!fieldAddressInputInFocus"
            id="address"
            class="field-location"
            type="text"
            formControlName="address" />
        </div>

        <div
          #map
          [class.hide-map]="!locationMapIsOpen"
          [class.disabled]="form.disabled"
          class="map-area"></div>
      </div>

      <div
        *ngIf="isInviteButtonVisible"
        class="mt-28 d-block">
        <lv-button
          (click)="onInviteUser()"
          [type]="isSubmitVisible ? ButtonTypeEnum.DISABLED : ButtonTypeEnum.PRIMARY"
          [iconPath]="isSubmitVisible ? IconsEnum.ADD_USER_DISABLED : IconsEnum.ADD_USER"
          cssClass="fz-14">
          {{ "Farms.FarmUpdate.InviteUser" | translate }}
        </lv-button>
      </div>
    </form>

    <div class="d-flex mt-auto">
      <lv-button
        *ngIf="
          !farm.controllersCount &&
          !isSubmitVisible &&
          !platformService.isMobileApp &&
          [FarmUserRolesEnum.Owner, FarmUserRolesEnum.Admin].includes(currentUserAccessRole)
        "
        (click)="onDeleteFarm()"
        [iconPath]="IconsEnum.BASKET"
        [type]="ButtonTypeEnum.LINK"
        cssClass="fz-14">
        {{ "Farms.FarmUpdate.DeleteFarm" | translate }}
      </lv-button>

      <ng-container *ngIf="isSubmitVisible">
        <lv-button
          (click)="onAbort()"
          [type]="ButtonTypeEnum.LINK">
          <span class="text-underline fw-500 color-darkgrey">{{ "Buttons.Cancel" | translate }}</span>
        </lv-button>

        <lv-button
          (click)="onSave()"
          [type]="ButtonTypeEnum.PRIMARY"
          class="flex-grow-1 ml-18">
          {{ "Farms.FarmUpdate.SaveChanges" | translate }}
        </lv-button>
      </ng-container>
    </div>
  </div>
</div>
