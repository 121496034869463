<div
  *ngIf="parentFormGroup.get(formGroupName) as form"
  [formGroupName]="formGroupName">
  <div
    [ngClass]="platformService.isDeviceApp ? 'justify-between' : 'justify-end'"
    class="d-flex align-center w-100-percent h-28">
    <div
      *ngIf="platformService.isDeviceApp"
      (click)="uninstall()"
      class="d-flex align-center pointer">
      <ls-svg-icon [path]="IconsEnum.UNINSTALL_ELEMENT"> </ls-svg-icon>
      <span class="fz-12 fw-500 mh-8">{{ "Installation.Uninstall" | translate }}</span>
    </div>
    <div class="d-flex align-center">
      <ng-container *ngIf="form['controls'].sensorOn.value">
        <div class="mh-8">
          <ls-svg-icon
            [path]="IconsEnum.AUXILIARY_INPUT"
            [color]="elementEvent?.status === OffOnEnum.Off ? ColorsEnum.MainDarkRed : ColorsEnum.MainDarkBlue">
          </ls-svg-icon>
        </div>

        <div class="d-flex">
          <span
            [ngClass]="elementEvent?.status === OffOnEnum.Off ? 'main-darkred' : 'color-darkblue'"
            class="fz-16 fw-600">
            {{ elementEvent?.status | enum : "off-on-enum" | translate | uppercase }}
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="!form['controls'].sensorOn.value">
        <div class="mh-8">
          <ls-svg-icon [path]="IconsEnum.AUXILIARY_INPUT"></ls-svg-icon>
        </div>

        <div class="d-flex align-end">
          <span class="fz-16 fw-800">{{ "Installation.Off" | translate | uppercase }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <!--FORM-->
  <lv-input
    [label]="'Installation.AuxiliaryInput.SensorName'"
    [qaTags]="'qa-txt-sensor-name'"
    [autocomplete]="'nope'"
    formControlName="sensorName"
    type="text"
    class="full-width mt-28 label-bg-white">
  </lv-input>

  <div class="d-flex justify-between mt-18 w-50-percent">
    <lv-dual-toggle-with-label
      [optionsTitlePosition]="form['controls'].sensorOn.value ? 'before' : 'after'"
      [firstOption]="{
        title: 'Buttons.Yes',
        value: true,
        theme: ThemeEnum.Main,
        toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
      }"
      [secondOption]="{
        title: 'Installation.Off',
        value: false,
        theme: ThemeEnum.Dark,
        toggleIcon: IconsEnum.CROSS_SMALL_BLACK
      }"
      [label]="'Installation.AuxiliaryInput.SensorOn'"
      class="flex-1 label-bg-white"
      formControlName="sensorOn">
    </lv-dual-toggle-with-label>
  </div>

  <lv-dual-toggle-with-label
    [optionsTitlePosition]="
      form['controls'].operation.value === InstallationConstants.OperationNormallyClose ? 'after' : 'before'
    "
    [firstOption]="{
      title: 'Installation.NormallyOpen',
      value: InstallationConstants.OperationNormallyOpen,
      theme: ThemeEnum.Default,
    }"
    [secondOption]="{
      title: 'Installation.NormallyClosed',
      value: InstallationConstants.OperationNormallyClose,
      theme: ThemeEnum.Default,
    }"
    [label]="'Installation.AuxiliaryInput.Operation'"
    class="flex-1 mt-18 label-bg-white"
    formControlName="operation">
  </lv-dual-toggle-with-label>
</div>
