import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  IconsEnum,
  ButtonTypeEnum,
  ColorsEnum,
  OffOnEnum,
  ElementOperationEnum,
  ElementTypesEnum,
} from '@livestock/shared/enums';
import {
  DualToggleWithLabelComponent,
  LVInputComponent,
  LVInputDecimalWithLabelComponent,
  SvgIconComponent,
  ThemeEnum,
  SimpleDropdownComponent,
  SlimButtonComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, Validators, ControlContainer, FormGroup, FormControl } from '@angular/forms';
import { BasicElementComponent, IGetOrUpdateElement, InstallationConstants, runElementTests, TestingElementDialogComponent } from '@livestock/installation';
import { ElementEventInfo, IElementSetupRunTestView } from '@livestock/installation/interfaces';
import { EnumPipe } from '@livestock/shared/pipes';
import { MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    EnumPipe,
    SvgIconComponent,
    LVInputComponent,
    DualToggleWithLabelComponent,
    LVInputDecimalWithLabelComponent,
    SimpleDropdownComponent,
    SlimButtonComponent,
  ],
  selector: 'lv-cooling-setup',
  templateUrl: './cooling.component.html',
  styleUrls: ['../basic-element/basic-element.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class CoolingSetupComponent extends BasicElementComponent implements OnInit {
  @Input() set elementEvent(elementEvent: ElementEventInfo) {
    this._elementEvent = elementEvent;
    if (elementEvent?.testTime === 0) {
      this.isTestMode = false;
      this.changeTestMode.emit(false);
      this.cdr.detectChanges();
    }
  };
  @Output() delete: EventEmitter<void> = new EventEmitter();
  @Output() changeTestMode: EventEmitter<boolean> = new EventEmitter();

  //vars
  private _elementEvent: ElementEventInfo;
  isTestMode = false;

  // enums
  OffOnEnum = OffOnEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ThemeEnum = ThemeEnum;
  ElementOperationEnum = ElementOperationEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  ColorsEnum = ColorsEnum;
  InstallationConstants = InstallationConstants;

  get elementEvent(): ElementEventInfo {
    return this._elementEvent;
  }

  override ngOnInit(): void {
    const disabled = !this.isEditMode;
    this.parentFormGroup.addControl(this.formGroupName,
      new FormGroup({
        elementID: new FormControl<number>({ value: null, disabled }),
        number: new FormControl<number>({ value: 1, disabled }),
        deviceName: new FormControl<string>({ value: 'Cooling - 01', disabled }, [Validators.required]),
        operation: new FormControl<boolean>({ value: InstallationConstants.OperationNormallyOpen, disabled }),
      }));
    super.ngOnInit();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      elementID: setupData.elementID,
      deviceName: setupData.deviceName,
      operation: setupData.operation,
    });
    this.parentFormGroup.updateValueAndValidity();
  }

  uninstall(): void {
    this.delete.emit();
  }

  async runTest(): Promise<void> {
    const dialogRef: MatDialogRef<TestingElementDialogComponent> = this.dialog.open(TestingElementDialogComponent, {
      data: {
        elementType: ElementTypesEnum.Cooling,
      },
      disableClose: true,
      panelClass: `testing-element-dialog__panel`,
      backdropClass: 'testing-element-dialog__backdrop',
    });

    const result: IElementSetupRunTestView = await lastValueFrom(dialogRef.afterClosed());
    if (result != null) {
      this.store.dispatch(runElementTests({
        elementID: this.parentFormGroup.get(this.formGroupName).value.elementID,
        response: result,
      }));
      this.isTestMode = true;
      this.changeTestMode.emit(true);
      this.elementEvent = {
        ...this.elementEvent,
        testTime: result.testTime,
      };
      this.cdr.detectChanges();
    }
  }

  stopTest(): void {
    this.store.dispatch(runElementTests({
      elementID: this.parentFormGroup.get(this.formGroupName).value.elementID,
      response: { testTime: 0 },
    }));
    this.isTestMode = false;
    this.changeTestMode.emit(false);
    this.cdr.detectChanges();
  }
}
